import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import Alert from "../../../components/common/Alert/AlertDialog"
import { MeetingRoomForm } from "../../../components/Forms/Room"
import { ImageUpload, DialogLoading } from "../../../components"
import * as actions from "../../../redux/actions"

export default function EditMeetingRoom() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const meetingRoom = useSelector((state) => state.meetingRoom)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [uploadedImage, setUploadedImage] = useState([])
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)

  const { t } = useTranslation()
  const { register, handleSubmit, watch, control, setValue } = useForm({})
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    dispatch(actions.getOneMeetingRoom(params.id))
      .then(() => {})
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [])

  const handleEditMeetingRoom = (data) => {
    setLoadingDialogOpen(true)
    const preUploadImage = _.map(uploadedImage, (each) => ({ image: each?.data_url }))
    const payload = {
      ...data,
      images: [...meetingRoom?.images, ...preUploadImage],
    }
    console.log("Payload meeting room", payload)
    dispatch(actions.editOneMeetingRoom(params.id, payload))
      .then(() => {
        setIsLoading(true)
        setLoadingDialogOpen(false)
        history.goBack()
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }

  useEffect(() => {
    if (_.isArray(meetingRoom?.price_list)) {
      setValue("price_list", meetingRoom?.price_list)
    }
    setValue("use_slot_price", meetingRoom?.use_slot_price)
    setIsLoading(true)
    return () => {}
  }, [meetingRoom])

  if (!isLoading) {
    return (
      <div>
        <SpinnerLoading />
      </div>
    )
  }

  const renderModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-sans">
          อัพโหลดรูปภาพ
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <ImageUpload images={uploadedImage} setImages={setUploadedImage} maxNumber={5} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="green">
            {t("general.save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  return (
    <Layout categoryTitle={t("roomtype.title")} title={"แก้ไขห้องประชุม"}>
      {renderModal()}
      <DialogLoading isLoading={loadingDialogOpen} />
      <Alert errMessage={errMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full min-h-screen overflow-x-auto">
        <form onSubmit={handleSubmit(handleEditMeetingRoom)}>
          <MeetingRoomForm
            register={register}
            defaultValue={meetingRoom}
            watch={watch}
            control={control}
            imageOnCreated={uploadedImage}
            setAddModalOpen={onOpen}
            handleDeletePhoto={isOpen}
          />
          <Button isFullWidth colorScheme="purple" type="submit">
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
