import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"

// components
import { Button, useToast } from "@chakra-ui/react"
import _ from "lodash"
import { SpinnerLoading, DialogLoading } from "../../../components/Loading"
import SystemDataTemplate from "../../../template/SystemInfoTemplate.json"
import * as actions from "../../../redux/actions"
import { SystemInfoForm } from "../../../components/Forms/Management"
import Layout from "../../../components/layout/GeneralLayout"

export default function ManagementSystem() {
  const dispatch = useDispatch()
  const systemInfo = useSelector((state) => state.system)

  const [isLoading, setIsLoading] = useState(false)
  const [isDialogLoadingOn, setIsDialogLoadingOn] = useState(false)

  const toast = useToast()

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  const uploadBootstrapTemplate = () => {
    // console.log(SystemDataTemplate);
    dispatch(actions.systemInfoPost(SystemDataTemplate))
  }

  const onSubmit = async (data, e) => {
    console.log("Data", data)
    console.log("updated logo", data.logo)
    const confirm = window.confirm("ยีนยันการอัพเดทข้อมูล")
    if (confirm) {
      setIsDialogLoadingOn(true)
      e.preventDefault()
      if (_.isArray(data.logo)) {
        // eslint-disable-next-line no-param-reassign
        data.logo = { image: data?.logo?.[0]?.data_url, alt: "Hotel Logo" }
      }

      // On Old Version Clear as null
      if (typeof data.logo === "string") {
        // eslint-disable-next-line no-param-reassign
        data.logo = null
      }

      dispatch(actions.systemInfoPut(data))
        .then(() => {
          setIsDialogLoadingOn(false)
          toast({ status: "success", title: "แก้ไขข้อมูลระบบสำเร็จ" })
        })
        .catch((error) => {
          console.error(error)
          alert(error?.message)
          setIsDialogLoadingOn(false)
        })
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการระบบ" title="จัดการข้อมูลระบบ">
      <DialogLoading isLoading={isDialogLoadingOn} title="กำลังปรับปรุงข้อมูลระบบ" />
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="p-4 w-full">
        {systemInfo && systemInfo.name !== undefined ? (
          <>
            <SystemInfoForm systemInfo={systemInfo} onSubmit={onSubmit} />
          </>
        ) : (
          <>
            <div className=" grid place-content-center place-items-center w-full p-5 ">
              <div className="box-border  border border-gray-500 w-full p-12 text-center  rounded-lg ">
                <h2 className=" text-gray-700 text-xl font-sans font-semibold">
                  คุณยังไม่มีข้อมูลระบบ
                </h2>
                <p className="text-lg font-sans ">ต้องการเริ่มต้นตั้งค่าระบบจากแม่แบบหรือไม่</p>
                <Button colorScheme="blue" onClick={() => uploadBootstrapTemplate()}>
                  ยืนยัน
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}
