/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, useToast } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import _ from "lodash"
import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"

export default function ArrangeFrontpageComponent() {
  const frontpageComponent = useSelector((state) => state.frontpageComponent)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [orderedComponent, setOrderedComponent] = useState([])
  const dispatch = useDispatch()

  const toast = useToast()
  const history = useHistory()

  const fetchingFrontpageComponents = () => {
    dispatch(actions.getAllFrontpageComponent({ page: 1, size: 1000, query: {} }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }

  useEffect(() => {
    fetchingFrontpageComponents()
    return () => {}
  }, [])

  useEffect(() => {
    if (!_.isEmpty(frontpageComponent?.arr)) {
      setOrderedComponent(
        _.map(frontpageComponent?.arr, (each, index) => ({ ...each, order: index })),
      )
    }

    return () => {}
  }, [frontpageComponent])

  // Reference https://codesandbox.io/s/k260nyxq9v?file=/index.js:373-567
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleSentArrangement = async () => {
    try {
      const payload = _.map(orderedComponent, (each, index) => ({ ...each, order: index }))
      await dispatch(actions.editOneFrontpageComponent("arrange", { arr: payload }))
      fetchingFrontpageComponents()
      toast({ status: "success", title: "จัดเรียงใหม่สำเร็จ" })
    } catch (error) {
      setIsError(true)
      setErrMessage(`ไม่สามารถแก้ไขส่วนประกอบหน้าเว็บไซต์ได้ ${error?.message}`)
    }
  }

  const handleDragEnd = (result) => {
    if (!result?.destination) {
      return
    }

    const newOrdered = reorder(orderedComponent, result?.source?.index, result?.destination?.index)
    setOrderedComponent(newOrdered)
  }

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="จัดเรียงส่วนประกอบของหน้าเว็บไซต์" preview>
      <div className="flex gap-2 px-4 justify-between">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
        <Button colorScheme="green" onClick={() => handleSentArrangement()}>
          บันทึก
        </Button>
      </div>

      <div className="p-4 w-full overflow-x-auto min-h-screen">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="fpc-dragable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {_.map(orderedComponent, (eachField, index) => (
                  <Draggable key={eachField?._id} draggableId={eachField?._id} index={index}>
                    {(dragableProvided) => (
                      <div
                        className="my-2 lg:w-1/2"
                        ref={dragableProvided.innerRef}
                        {...dragableProvided.draggableProps}
                        {...dragableProvided.dragHandleProps}
                      >
                        <div className="border rounded-md p-4 border-gray-400">
                          <h3 className="text-xl font-semibold">
                            {eachField?.translation?.th?.name}
                          </h3>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Layout>
  )
}
