import React from "react"
import { useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import _ from "lodash"
import moment from "moment"
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import { CRMType } from "../../../config/constant"

export default function CRMList({ crmList, customerId, isLoading, setIsLoading }) {
  const dispatch = useDispatch()

  const handleDeleteCustomer = (id) => {
    const confirm = window.confirm("ยืนยันการลบข้อมูลนี้")
    if (confirm) {
      dispatch(actions.deleteOneRelationship(id)).then(() => {
        dispatch(actions.getAllRelationships(customerId)).then(() => {
          setIsLoading(true)
        })
      })
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <div className="w-full grid grid-cols-2 lg:grid-cols-3">
      {_.map(crmList, (eachRelationship, index) => (
        <div className="bg-white rounded-lg shadow-lg m-2 p-4" key={index}>
          <div className="flex justify-between">
            <h1 className="font-bold pb-2 self-center">
              {CRMType[eachRelationship?.title]?.description || eachRelationship?.title}
            </h1>
            <div className="flex gap-2">
              <Link to={`/crm/show/edit/${eachRelationship?.id}`}>
                <Button size="sm" colorScheme="yellow">
                  <i className="fas fa-pencil-alt"></i>
                </Button>
              </Link>
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => {
                  handleDeleteCustomer(eachRelationship?.id)
                }}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </div>
          </div>
          <div>
            <h1 className=" py-2">{eachRelationship?.message}</h1>
          </div>

          <div className="pt-2">
            <h1 className="text-sm text-gray-600">
              บันทึกเมื่อ {moment(eachRelationship?.createdAt).format("D MMM YYYY")}
            </h1>
          </div>
        </div>
      ))}
    </div>
  )
}
