import api from "../../../config/api"

import {
  BOOKING_ALL,
  BOOKING_GET,
  BOOKING_CREATE,
  BOOKING_UPDATE,
  BOOKING_DELETE,
  BOOKING_RESET,
} from "../types"

// faker.locale = "th"

export const getAllBooking =
  ({
    page = 1,
    size = 10,
    start = "",
    end = "",
    name = "",
    startDate = "",
    endDate = "",
    orderByField = "start",
    orderBy = "asc",
    roomType = "",
    asPointOfTime = "",
    status = "",
    paymentStatus = "",
    limitFirst = "",
  }) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/booking/?page=${page}&size=${size}&name=${name}&start=${start}&end=${end}&startDate=${startDate}&endDate=${endDate}&roomType=${roomType}&orderByField=${orderByField}&orderBy=${orderBy}&asPointOfTime=${asPointOfTime}&status=${status}&paymentStatus=${paymentStatus}&limitFirst=${limitFirst}`,
      )
      .then((res) => {
        console.log("Request Server to Get All Booking according to the query")
        if (res.data) {
          dispatch({ type: BOOKING_ALL, payload: res.data })
        } else {
          dispatch({ type: BOOKING_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All Booking", err)
        dispatch({ type: BOOKING_ALL, payload: null })
      })
  }

// start กับ end ใช้กรองกับ วัน Checkin และ วัน Checkout ส่วน StartDate กับ End Date ใช้กับ CreatedAt
// date สำหรับ Query จุดของเวลา เช่นการจองในวันนั้นๆ
export const getAllBookingAndLog =
  ({
    page = 1,
    size = 10,
    start = "",
    end = "",
    name = "",
    startDate = "",
    endDate = "",
    orderByField = "start",
    orderBy = "asc",
    roomType = "",
    date = "",
    asPointOfTime = "",
    unwindBooking = "",
    checkin = "",
    filterOutRoomType = "",
    onReport = "",
  }) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/booking/with-logs/?page=${page}&size=${size}&name=${name}&start=${start}&end=${end}&startDate=${startDate}&endDate=${endDate}&roomType=${roomType}&orderByField=${orderByField}&orderBy=${orderBy}&date=${date}&asPointOfTime=${asPointOfTime}&unwindBooking=${unwindBooking}&checkin=${checkin}&filterOutRoomType=${filterOutRoomType}&onReport=${onReport}`,
      )
      .then((res) => {
        console.log("Request Server to Get All Booking according to the query")
        if (res.data) {
          dispatch({ type: BOOKING_ALL, payload: res.data })
        } else {
          dispatch({ type: BOOKING_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All Booking", err)
        dispatch({ type: BOOKING_ALL, payload: null })
      })
  }
export const getOneBooking = (id) => async (dispatch) => {
  api
    .get(`${process.env.REACT_APP_API_URL}/booking/${id}`)
    .then((res) => {
      console.log("Request Server to Get One Booking")
      if (res.data) {
        dispatch({ type: BOOKING_GET, payload: res.data })
      } else {
        dispatch({ type: BOOKING_GET, payload: null })
      }
    })
    .catch((err) => {
      dispatch({ type: BOOKING_GET, payload: null })

      throw new Error(`ไม่สามารถสร้างการจองได้  ${err?.response?.data?.error?.message}`)
    })
}

export const createOneBooking = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/booking/`, payload)
    .then((data) => {
      console.log("Request Server to Create New Booking")
      dispatch({ type: BOOKING_CREATE, payload: { ...(data.data || {}), createNew: true } })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถสร้างการจองได้  ${err?.response?.data?.error?.message}`)
    })
}

export const editOneBooking = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/booking/${id}`, payload)
    .then(() => {
      console.log("Request Server to edit Booking")
      dispatch({ type: BOOKING_UPDATE })
    })
    .catch((err) => {
      dispatch({ type: BOOKING_UPDATE, payload: null })

      throw new Error(`ไม่สามารถแก้ไขการจองได้  ${err?.response?.data?.error?.message}`)
    })
}

export const submitCheckin = (id, payload) => async (dispatch) => {
  await api.put(`${process.env.REACT_APP_API_URL}/booking/checkin/${id}`, payload).then(() => {
    console.log("Request Server to edit Booking")
    dispatch({ type: BOOKING_UPDATE })
  })
}

export const deleteOneBooking =
  (id, { user } = { user: null }) =>
  async (dispatch) => {
    await api
      .delete(`${process.env.REACT_APP_API_URL}/booking/${id}?user=${user || ""}`)
      .then(() => {
        console.log("Request Server to Delete One Promotion")
        dispatch({ type: BOOKING_DELETE, payload: null })
      })
  }

export const resetBooking = () => async (dispatch) => {
  dispatch({ type: BOOKING_RESET })
}
