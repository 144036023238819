import React from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react"

export default function Alert({ errMessage, isError, handleOnClose, title, html = false }) {
  return (
    <AlertDialog isOpen={isError} onClose={handleOnClose}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader className="font-sans" fontSize="lg">
          {title || "ขออภัย"} <AlertDialogCloseButton />
        </AlertDialogHeader>
        <AlertDialogBody>
          {html ? <div dangerouslySetInnerHTML={{ __html: errMessage }} /> : errMessage?.toString()}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button size="sm" colorScheme="purple" className="font-sans" onClick={handleOnClose}>
            ปิด
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
