/* eslint-disable no-underscore-dangle */
import React from "react"
import { DateTime } from "luxon"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import dayjs from "dayjs"

import Dot from "../common/Dot/dot"
import { selectTailwindStatusColor } from "../../util/colors"
import PrintingPanel from "./PrintingPanel"
import PaymentStatusExplainer from "./PaymentStatusExplainer"
import { BookingSourceBadge } from "../common/Badge"
import OTADataPanel from "./OTADataPanel"
import ChillpayDataPanel from "./ChillpayDataPanel"
import { dayAmount } from "../../util"
import { BookingStatus } from "../../config/constant"

export default function BasicBookingInfoPanel({ bookingInfo, showPrintingPanel = true }) {
  const { t } = useTranslation()
  const system = useSelector((state) => state.system)
  const systemEnableForm = system?.setting?.form || {}
  const bookingDateDisplay = () => (
    <div className="flex flex-wrap font-default">
      <div className="">
        {DateTime.fromISO(bookingInfo?.start)
          .setLocale("th")
          .toLocaleString({ day: "numeric", month: "short", year: "numeric" })}
      </div>
      <div className="mx-2"> - </div>
      <div className="">
        {DateTime.fromISO(bookingInfo?.end)
          .setLocale("th")
          .toLocaleString({ day: "numeric", month: "short", year: "numeric" })}
      </div>
      <div className="mx-2">({dayAmount(bookingInfo?.start, bookingInfo?.end)} คืน)</div>
    </div>
  )

  return (
    <div className="flex flex-wrap mb-2 mt-2">
      <div className="w-full lg:w-1/2">
        <div className="mb-4">
          <h6 className="text-xs font-bold font-sans ">{t("reservation.bookingNumber")}</h6>
          <h6 className="font-semibold ">{bookingInfo?.booking_no}</h6>{" "}
        </div>
        <div className=" mb-4">
          <h6 className="text-xs font-bold font-sans">{t("crm.customer")}</h6>
          <p className="font-semibold font-default ">
            {bookingInfo?.customer?.firstname} {bookingInfo?.customer?.lastname}{" "}
            <Link to={`/crm/show/${bookingInfo?.customer?._id}`}>
              <Button size="xs">
                <i className="fas fa-user" />{" "}
              </Button>
            </Link>
          </p>

          <h6 className="font-semibold text-sm">{bookingInfo?.customer?.tel}</h6>
          <BookingSourceBadge sourceOfBooking={bookingInfo?.booking_source} />

          {showPrintingPanel === true && (
            <PrintingPanel bookingInfo={bookingInfo} {...systemEnableForm} />
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2 mb-2">
        <div className=" w-full mb-3">
          <div className="flex gap-2 items-center">
            <h6 className="text-xs font-bold font-sans">
              {t("reservation.checkin")} - {t("reservation.checkout")}{" "}
            </h6>
          </div>
          {bookingDateDisplay()}
        </div>
        <div className=" w-full mb-3">
          <h6 className="text-xs font-bold font-sans mb-1">{t("reservation.bookingStatus")}</h6>
          <Dot color={selectTailwindStatusColor(bookingInfo?.status)} word={bookingInfo?.status} />
        </div>
        <div className=" w-full mb-3">
          <h6 className="text-xs font-bold font-sans mb-1">จำนวนแขก</h6>
          <div className="flex gap-4 font-default">
            <div>
              <i className="fas fa-user-tie"></i> {bookingInfo?.capacity?.adult || "-"} คน{" "}
            </div>
            <div>
              <i className="fas fa-child"></i> {bookingInfo?.capacity?.child || "-"} คน
            </div>
          </div>
        </div>
        <div className=" w-full  mb-3">
          <h6 className="text-xs font-bold font-sans mb-1">{t("reservation.paymentStatus")}</h6>
          <div className="font-default">
            <PaymentStatusExplainer status={bookingInfo?.paymentStatus} bookingInfo={bookingInfo} />
          </div>
        </div>
        <div>
          {bookingInfo?.ota_attribute?.ota_name && (
            <OTADataPanel otaData={bookingInfo?.ota_attribute} />
          )}
        </div>
        <div className=" w-full  mb-3">
          <h6 className="text-xs font-bold font-sans mb-1">วันที่จอง</h6>
          <div className="font-default">
            {dayjs(bookingInfo?.createdAt).format("DD MMM YYYY เวลา HH:mm")}{" "}
          </div>
        </div>
        {(bookingInfo?.status === BookingStatus.checkedOut ||
          bookingInfo?.status === BookingStatus.success) && (
          <div className=" w-full  mb-3">
            <h6 className="text-xs font-bold font-sans mb-1">วันที่เช็คเอาท์</h6>
            <div className="font-default">
              {bookingInfo?.checkout_date
                ? dayjs(bookingInfo?.checkout_date).format("DD MMM YYYY เวลา HH:mm")
                : "-"}{" "}
            </div>
          </div>
        )}
        {bookingInfo?.payment?.special_attribute?.BankCode && (
          <div className=" w-full  mb-3">
            <h6 className="text-xs font-bold font-sans mb-1">ข้อมูลการชำระเงินจาก Chillpay</h6>
            <div className="font-default">
              <ChillpayDataPanel
                chillpaySpecialAttribute={bookingInfo?.payment?.special_attribute}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
