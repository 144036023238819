/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  GeneralMainLayout as Layout,
  SpinnerLoading,
  WhitePanel,
  Alert,
  FBOrderAddToBookingForm,
} from "../../components"
import * as actions from "../../redux/actions"
import { appConfig as config } from "../../config"

export default function AddFBToBooking() {
  const { t } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const fbOrder = useSelector((state) => state.fbOrder)
  const bookings = useSelector((state) => state.bookings)

  const [isLoading, setIsLoading] = useState(false)

  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")

  const { handleSubmit, control } = useForm({
    menuObject: {},
  })

  const getBooking = () => {
    dispatch(
      actions.getAllBooking({
        page: 1,
        size: config.maxFetchSize,
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  useEffect(() => {
    dispatch(actions.getOneFBOrder(params.id))
      .then(() => {
        getBooking()
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
      })
    return () => {}
  }, [params])

  const handleAddToBooking = (data) => {
    dispatch(
      actions.editOneFBOrder(params.id, {
        booking: data?.booking,
      }),
    )
      .then(() => {
        setIsLoading(false)
        history.goBack()
      })
      .then(() => {})
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  if (!isLoading)
    return (
      <div>
        <SpinnerLoading />
      </div>
    )

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="ลงบิลห้องพัก">
      <Alert errMessage={errMessage} handleOnClose={() => setIsError(false)} isError={isError} />

      <div className="px-4 flex justify-between">
        <Button
          variant="outline"
          colorScheme="purple"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2"></div>
      </div>
      <div className="p-4">
        <div className="my-2">
          <WhitePanel>
            <div className="flex flex-wrap justify-between items-center">
              <div className="w-full lg:w-1/2">
                <div>เลขที่บิล</div>
                <div className="text-lg font-semibold">{fbOrder?.bill_number}</div>
              </div>
              <div className="w-1/2 lg:w-1/4">
                <div>โต๊ะ</div>
                <div className="text-lg font-semibold">{fbOrder?.table?.name}</div>
              </div>
              <div className="w-1/2 lg:w-1/4">
                <div>รายการจองห้อง</div>
                <div className="text-lg font-semibold">{fbOrder?.booking?.booking_no || "-"}</div>
              </div>
            </div>
            <FBOrderAddToBookingForm
              control={control}
              booking={bookings}
              defaultValue={fbOrder?.booking}
            />
            <div className="flex justify-end my-2">
              <Button colorScheme="purple" onClick={handleSubmit(handleAddToBooking)}>
                บันทึก
              </Button>
            </div>
          </WhitePanel>
        </div>
      </div>
    </Layout>
  )
}
