import {
  MENU_CATEGORY_ALL,
  MENU_CATEGORY_CREATE,
  MENU_CATEGORY_DELETE,
  MENU_CATEGORY_GET,
  MENU_CATEGORY_UPDATE,
  MENU_CATEGORY_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const menuCategoryAction = new GeneralReduxActionClass({
  allConst: MENU_CATEGORY_ALL,
  createConst: MENU_CATEGORY_CREATE,
  deleteConst: MENU_CATEGORY_DELETE,
  errorConst: MENU_CATEGORY_ERROR,
  getConst: MENU_CATEGORY_GET,
  putConst: MENU_CATEGORY_UPDATE,
  backendPrefix: "menu-category",
  description: "หมวดหมู่บทความ",
})

export const getAllMenuCategory = (query) => menuCategoryAction.getAll(query)
export const getOneMenuCategory = (id) => menuCategoryAction.getOne(id)
export const createOneMenuCategory = (payload) => menuCategoryAction.createOne(payload)
export const editOneMenuCategory = (id, payload) => menuCategoryAction.editOne(id, payload)
export const deleteOneMenuCategory = (id) => menuCategoryAction.deleteOne(id)
