/* eslint-disable no-underscore-dangle */
import React from "react"
import { Checkbox, Form, Input } from "antd"
import { Controller } from "react-hook-form"

function TableForm({ control, defaultValue, formSize = "large", onMany = false, index = 0 }) {
  return (
    <div>
      <Form layout="vertical" size={formSize}>
        <Form.Item label="ชื่อโต๊ะ" required style={{ marginBottom: "5px" }}>
          <Controller
            control={control}
            name={onMany ? `arr[${index}].name` : "name"}
            defaultValue={defaultValue?.name}
            rules={{ required: true }}
            render={(field) => <Input {...field} required />}
          />
        </Form.Item>

        <Form.Item label="เปิดใช้งาน" style={{ marginBottom: "5px" }}>
          <Controller
            control={control}
            name={onMany ? `arr[${index}].enable` : "enable"}
            defaultValue={defaultValue?.enable || true}
            render={(field) => (
              <Checkbox {...field} defaultChecked={field.value}>
                สั่งอาหารได้
              </Checkbox>
            )}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default TableForm
