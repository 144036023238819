import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"

import { GeneralMainLayout } from "../../../components/layout"
import { SpinnerLoading, OnlineBookingSettingForm, WhitePanel } from "../../../components"
import * as actions from "../../../redux/actions"

function OnlineBookingManagement() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, watch, setValue, control } = useForm()

  useEffect(() => {
    dispatch(actions.systemInfoGet())
      .then(() => {})
      .catch((err) => {
        alert(err?.message)
      })

    return () => {}
  }, [])

  useEffect(() => {
    let timeout
    if (system?._id) {
      setIsLoading(true)
      timeout = setTimeout(() => {
        setValue(
          "setting.onlineBooking.paymentType.transfer",
          system?.setting?.onlineBooking?.paymentType?.transfer || true,
        )
        setValue(
          "setting.onlineBooking.transferInfo.depositPercent",
          system?.setting?.onlineBooking?.transferInfo?.depositPercent,
        )
        setValue(
          "setting.onlineBooking.paymentType.chillpay?.enable",
          system?.setting?.onlineBooking?.paymentType?.chillpay?.enable,
        )
      }, 1000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [system])

  const handleUpdateSetting = (data) => {
    const confirm = window.confirm("ยืนยันการแก้ไข")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.systemInfoPut(data))
        .then(() => {
          dispatch(actions.systemInfoGet()).then(() => {
            setIsLoading(true)
            setValue(
              "setting.onlineBooking.paymentType.transfer",
              system?.setting?.onlineBooking?.paymentType?.transfer || true,
            )
          })
        })
        .catch((err) => {
          console.error("Update Setting Error", err)
          alert(`ไม่สามารถแก้ไขได้เนื่องจาก ${err?.message}`)
        })
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <GeneralMainLayout categoryTitle={t("system.title")} title="ตั้งค่าการจองผ่านเว็บไซต์">
      <div className="flex px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="min-h-screen p-4">
        <form onSubmit={handleSubmit(handleUpdateSetting)}>
          <WhitePanel>
            <OnlineBookingSettingForm
              defaultValue={system}
              register={register}
              watch={watch}
              control={control}
            />
          </WhitePanel>
          <div>
            <Button colorScheme="purple" isFullWidth type="submit">
              {t("general.save")}
            </Button>
          </div>
        </form>
      </div>
    </GeneralMainLayout>
  )
}

export default OnlineBookingManagement
