import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { Alert } from "antd"

import {
  FrontpageHeroExample,
  WhitePanel,
  MainTextExample,
  RoomTypeExample,
  RecentBlogExample,
} from "../../components"
import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import ExampleBookingPanel from "../../assets/img/example-booking-panel.png"

export default function FrontpageManagement() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const info = useSelector((state) => state.information)
  const roomtype = useSelector((state) => state.roomTypes)
  const articles = useSelector((state) => state.articles)

  const onSubmit = (data) => {
    dispatch(actions.editOneInformation(info?.id, data)).then(() => {
      setIsLoading(false)
      dispatch(actions.getInformation()).then(() => {
        setIsLoading(true)
        window.alert("แก้ไขข้อมูลเสร็จสิ้น")
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      dispatch(actions.getAllRoomTypes({}))
      dispatch(actions.getAllArticle({ page: 1, size: 5 }))
      setIsLoading(true)
    })
    return () => {}
  }, [])

  const handleEnable = (key) => {
    dispatch(
      actions.editOneInformation(info?.id, {
        frontpage_component: { ...info?.frontpage_component, [key]: true },
      }),
    ).then(() => {
      setIsLoading(false)
      dispatch(actions.getInformation()).then(() => {
        setIsLoading(true)
      })
    })
  }

  const handleDisable = (key) => {
    dispatch(
      actions.editOneInformation(info?.id, {
        frontpage_component: { ...info?.frontpage_component, [key]: false },
      }),
    ).then(() => {
      setIsLoading(false)
      dispatch(actions.getInformation()).then(() => {
        setIsLoading(true)
      })
    })
  }

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="จัดการส่วนประกอบหน้าเว็บไซต์">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <WhitePanel>
            <div className="flex justify-between">
              <h3 className="my-2 text-xl font-bold">
                ส่วนหัวข้อเว็บไซต์{" "}
                {info?.frontpage_component?.heroHeader ? (
                  ""
                ) : (
                  <i className="fas fa-eye-slash text-gray-500" />
                )}
              </h3>
              {info?.frontpage_component?.heroHeader ? (
                <Button colorScheme="gray" size="sm" onClick={() => handleDisable("heroHeader")}>
                  ซ่อน
                </Button>
              ) : (
                <Button colorScheme="green" size="sm" onClick={() => handleEnable("heroHeader")}>
                  แสดง
                </Button>
              )}
            </div>
            <FrontpageHeroExample info={info} />
          </WhitePanel>
          <WhitePanel>
            <div className="flex justify-between">
              <h3 className="my-2 text-xl font-bold">
                ส่วนข้อมูลโรงแรมเบื้องต้น{" "}
                {info?.frontpage_component?.hotelBrief ? (
                  ""
                ) : (
                  <i className="fas fa-eye-slash text-gray-500" />
                )}
              </h3>
              {info?.frontpage_component?.hotelBrief ? (
                <Button colorScheme="gray" size="sm" onClick={() => handleDisable("hotelBrief")}>
                  ซ่อน
                </Button>
              ) : (
                <Button colorScheme="green" size="sm" onClick={() => handleEnable("hotelBrief")}>
                  แสดง
                </Button>
              )}
            </div>
            <MainTextExample info={info} />
          </WhitePanel>
          <WhitePanel>
            <div className="flex justify-between">
              <h3 className="my-2 text-xl font-bold">
                ส่วนห้องพัก
                {info?.frontpage_component?.hotelRoom ? (
                  ""
                ) : (
                  <i className="fas fa-eye-slash text-gray-500" />
                )}
              </h3>
              {info?.frontpage_component?.hotelRoom ? (
                <Button colorScheme="gray" size="sm" onClick={() => handleDisable("hotelRoom")}>
                  ซ่อน
                </Button>
              ) : (
                <Button colorScheme="green" size="sm" onClick={() => handleEnable("hotelRoom")}>
                  แสดง
                </Button>
              )}
            </div>
            <Alert
              message="การแก้ไขว่าห้องพักใดสามารถโชว์ในหน้าแรกได้บ้าง สามารถทำได้โดยเข้าไปในเมนู จัดการห้องพักและบริหาร จากนั้นแก้ไขข้อมูลห้องพัก เลือกที่ แสดงผล ส่วนภาพที่แสดงผลนั้นจะเลือกจากภาพที่ทำสัญลักษณ์รูปดาวเอาไว้"
              type="info"
              showIcon
            />

            <RoomTypeExample info={info} rooms={roomtype?.arr} />
          </WhitePanel>{" "}
          <WhitePanel>
            <div className="flex justify-between">
              <h3 className="my-2 text-xl font-bold">
                ส่วนของบล็อกข่าวสาร
                {info?.frontpage_component?.recentBlog ? (
                  ""
                ) : (
                  <i className="fas fa-eye-slash text-gray-500" />
                )}
              </h3>
              {info?.frontpage_component?.recentBlog ? (
                <Button colorScheme="gray" size="sm" onClick={() => handleDisable("recentBlog")}>
                  ซ่อน
                </Button>
              ) : (
                <Button colorScheme="green" size="sm" onClick={() => handleEnable("recentBlog")}>
                  แสดง
                </Button>
              )}
            </div>
            <RecentBlogExample info={info} articles={articles?.arr} />
          </WhitePanel>{" "}
          <WhitePanel>
            <div className="flex justify-between">
              <h3 className="my-2 text-xl font-bold">
                ส่วนของการจองห้องพัก
                {info?.frontpage_component?.bookingPanel ? (
                  ""
                ) : (
                  <i className="fas fa-eye-slash text-gray-500" />
                )}
              </h3>
              {info?.frontpage_component?.bookingPanel ? (
                <Button colorScheme="gray" size="sm" onClick={() => handleDisable("bookingPanel")}>
                  ซ่อน
                </Button>
              ) : (
                <Button colorScheme="green" size="sm" onClick={() => handleEnable("bookingPanel")}>
                  แสดง
                </Button>
              )}
            </div>
            <img src={ExampleBookingPanel} alt="booking panel" className="w-full overflow-x-auto" />
          </WhitePanel>
        </form>
      </div>
    </Layout>
  )
}
