import {
  MEETING_ROOM_ORDER_ALL,
  MEETING_ROOM_ORDER_CREATE,
  MEETING_ROOM_ORDER_DELETE,
  MEETING_ROOM_ORDER_GET,
  MEETING_ROOM_ORDER_UPDATE,
  MEETING_ROOM_ORDER_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const meetingRoomOrderActions = new GeneralReduxActionClass({
  allConst: MEETING_ROOM_ORDER_ALL,
  createConst: MEETING_ROOM_ORDER_CREATE,
  deleteConst: MEETING_ROOM_ORDER_DELETE,
  errorConst: MEETING_ROOM_ORDER_ERROR,
  getConst: MEETING_ROOM_ORDER_GET,
  putConst: MEETING_ROOM_ORDER_UPDATE,
  backendPrefix: "meeting-room-order",
  description: "รายการการจองห้องประชุม",
})

export const getAllMeetingRoomOrders = (query) => meetingRoomOrderActions.getAll(query)
export const getOneMeetingRoomOrder = (id) => meetingRoomOrderActions.getOne(id)
export const createOneMeetingRoomOrder = (payload) => meetingRoomOrderActions.createOne(payload)
export const editOneMeetingRoomOrder = (id, payload) => meetingRoomOrderActions.editOne(id, payload)
export const deleteOneMeetingRoomOrder = (id) => meetingRoomOrderActions.deleteOne(id)
