/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"
import { BookingStatus } from "../../constant"

dayjs.extend(buddhistEra)

export default function FBReport(
  transactions,
  date,
  startDate,
  endDate,
  isPointDate,
  information,
  systemInfo,
) {
  console.log("transactions", transactions)
  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายงานการสั่งอาหารและเครื่องดื่ม",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายงานการสั่งอาหารและเครื่องดื่ม /Food and Beverage Report",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      isPointDate
        ? {
            text: [
              { text: "วันที่", bold: true },
              {
                text: `  ${dayjs(date).format("D MMMM BBBB")}  (วันที่ ${dayjs(date).format(
                  "D MMMM BBBB",
                )} เวลา 12.01 - วันที่ ${dayjs(date)
                  .add(1, "day")
                  .format("D MMMM BBBB")} เวลา 11.59 )`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          }
        : {
            text: [
              { text: "จากวันที่", bold: true },
              {
                text: `  ${dayjs(startDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
              { text: "ถึงวันที่", bold: true },
              {
                text: `  ${dayjs(endDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: [25, "*", 90, 60, 70, 70],
          body: [
            [
              { text: "ลำดับที่", bold: true },
              { text: "วันที่", bold: true },
              { text: "เลขที่บิล", bold: true },
              { text: "โต๊ะ", bold: true },
              { text: "รายการจองห้อง", bold: true },
              { text: "ราคา", bold: true },
            ],
            ..._.map(transactions, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${dayjs(eachList?.date).format("D MMM BBBB HH:mm")}`,
                margin: [0, 2],
              },
              {
                text: eachList?.bill_number,
              },
              {
                text: eachList?.table?.name,
              },
              {
                text: "-",
              },
              {
                text: currencyFormatter.format(eachList?.price),
              },
            ]),
            [
              { text: "รวม", colSpan: 5, bold: true },
              {},
              {},
              {},
              {},
              {
                text: currencyFormatter.format(_.sumBy(transactions, "price")),
              },
            ],
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
