import React from "react"

const SliderButtonComponentArea = ({
  register,
  lengthFormName,
  widthFormName,
  curveFormName,
  watch,
}) => (
  <div>
    <label htmlFor="lengthSlider">ความยาว:</label>
    <input
      type="range"
      id="lengthSlider"
      defaultValue={200}
      min={1}
      max={300}
      name={lengthFormName}
      ref={register}
    />
    <span>{watch(lengthFormName)}px</span>
    <br />
    <label htmlFor="widthSlider">ความกว้าง:</label>
    <input type="range" id="widthSlider" min={1} max={450} name={widthFormName} ref={register} />
    <span>{watch(widthFormName)}px</span>
    <br />
    <label htmlFor="CurveCardContentSlider">ความโค้งปุ่ม:</label>
    <input
      type="range"
      id="CurveButtonSlider"
      defaultValue={0}
      min={0}
      max={100}
      name={curveFormName}
      ref={register}
    />
    <span>{watch(curveFormName)}%</span>
  </div>
)

export default SliderButtonComponentArea
