import api from "../../../config/api"

import {
  FACILITY_ALL,
  FACILITY_CREATE,
  FACILITY_DELETE,
  FACILITY_GET,
  FACILITY_PUT,
} from "../types"

// faker.locale = "th"

export const getAllFacility = () => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/facility/`)
    .then((res) => {
      console.log("Request Server to Get All Facility")
      if (res.data) {
        dispatch({ type: FACILITY_ALL, payload: res.data })
      } else {
        dispatch({ type: FACILITY_ALL, payload: null })
      }
    })
    .catch((err) => {
      console.log("Error on Get All Faclity", err)
      dispatch({ type: FACILITY_ALL, payload: null })
      throw new Error(
        `ไม่สามารถดึงข้อมูลสิ่งอำนวยความสะดวกทั่งหมดมาได้  ${err?.response?.data?.error?.message}`,
      )
    })
}

export const getOneFacility = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/facility/${id}`)
    .then((res) => {
      console.log("Request Server to Get One Facility")
      if (res.data) {
        dispatch({ type: FACILITY_GET, payload: res.data })
      } else {
        dispatch({ type: FACILITY_GET, payload: null })
      }
    })
    .catch((err) => {
      throw new Error(
        `ไม่สามารถดึงข้อมูลสิ่งอำนวยความสะดวกมาได้  ${err?.response?.data?.error?.message}`,
      )
    })
}

export const createOneFacility = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/facility/`, payload)
    .then(() => {
      console.log("Request Server to Create New Facility")
      dispatch({ type: FACILITY_CREATE })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถสร้างสิ่งอำนวยความสะดวกได้  ${err?.response?.data?.error?.message}`)
    })
}

export const editOneFacility = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/facility/${id}`, payload)
    .then((res) => {
      console.log("Request Server to edit Facility")
      dispatch({ type: FACILITY_PUT })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถแก้ไขสิ่งอำนวยความสะดวกได้  ${err?.response?.data?.error?.message}`)
    })
}

export const deleteOneFacility = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/facility/${id}`)
    .then((res) => {
      console.log("Request Server to Delete One Promotion")
      dispatch({ type: FACILITY_DELETE, payload: null })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถลบสิ่งอำนวยความสะดวกได้ ${err?.response?.data?.error?.message}`)
    })
}
