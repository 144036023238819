import React from "react"
import { Form, Input, Switch, TimePicker, Button } from "antd"
import { Controller } from "react-hook-form"
import Flag from "country-flag-icons/react/3x2"
import dayjs from "dayjs"

function MenuCategoryForm({ control, defaultValue, watch }) {
  const format = "HH:mm"

  return (
    <div>
      <Form layout="vertical" size="large">
        <Form.Item label="ชื่อหมวดหมู่ภาษาไทย" required>
          <Controller
            control={control}
            name="translation.th.name"
            defaultValue={defaultValue?.translation?.th?.name}
            render={(field) => (
              <Input {...field} addonBefore={<Flag.TH title="Thai" className="w-5" />} />
            )}
          />
        </Form.Item>
        <Form.Item label="ชื่อหมวดหมู่ภาษาอังกฤษ">
          <Controller
            control={control}
            name="translation.en.name"
            defaultValue={defaultValue?.translation?.en?.name}
            render={(field) => (
              <Input {...field} addonBefore={<Flag.GB title="English" className="w-5" />} />
            )}
          />
        </Form.Item>{" "}
        <Form.Item label="เปิดการใช้งานตามช่วงเวลา">
          <Controller
            control={control}
            name="useSpecifyTime"
            defaultValue={defaultValue?.useSpecifyTime}
            render={(field) => (
              <div className="flex gap-2">
                <div>ไม่ใช้</div>
                <Switch {...field} defaultChecked={field.value} />
                <div>ใช้</div>
              </div>
            )}
          />
        </Form.Item>
        {watch("useSpecifyTime") && (
          <Form.Item label="เวลาเริ่ม">
            <Controller
              control={control}
              name="startTime"
              defaultValue={dayjs(defaultValue?.startTime) || dayjs("00:00", format)}
              render={(field) => (
                <TimePicker {...field} defaultValue={dayjs(field.value)} format={format} />
              )}
            />
          </Form.Item>
        )}{" "}
        {watch("useSpecifyTime") && (
          <Form.Item label="เวลาสิ้นสุด">
            <Controller
              control={control}
              name="endTime"
              defaultValue={dayjs(defaultValue?.endTime) || dayjs("00:00", format)}
              render={(field) => (
                <TimePicker {...field} defaultValue={dayjs(field.value)} format={format} />
              )}
            />
          </Form.Item>
        )}
      </Form>
    </div>
  )
}

export default MenuCategoryForm
