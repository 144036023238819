import React, { forwardRef } from "react"
import { FormLabel, Select } from "@chakra-ui/react"
import _ from "lodash"

function Gallery({ register, defaultValue, gallery }) {
  console.log("defaultValue", defaultValue, gallery);
  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-1/2 px-2 py-1">
        <FormLabel fontSize="sm">แกลอรี่ที่เลือก</FormLabel>
        <Select
          size="sm"
          ref={register}
          name="type_attribute.selected_gallery"
          defaultValue={defaultValue?.type_attribute?.selected_gallery?._id}
          placeholder="เลือกแกลอรี่"
        >
          {_.map(gallery?.arr, (each, index) => (
            <option key={index} value={each?.id}>
              {each?.name}{" "}
            </option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default forwardRef(Gallery)
