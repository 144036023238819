/* eslint-disable arrow-body-style */
import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import DayPickerInput from "react-day-picker/DayPickerInput"
import dayjs from "dayjs"
import { DatePicker } from "antd"
import ReactSelect from "react-select"
import { Controller } from "react-hook-form"
import { Button, Checkbox, FormControl, Switch } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { TextArea, TextField } from "../../common/Input"

import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../../util"

const ServiceOrderForm = ({
  register,
  control,
  watch,
  setIsModalCreateCustomerOpen,
  customers,
  showTitleForMeeting = false,
  defaultValue,
  isLoading = true,
  showTimeSpecify = false,
  setValue = () => {},
}) => {
  const { t } = useTranslation()

  const addHourToEnd = (hour) => {
    const preferEndTime = dayjs(watch("start")).add(hour, "hour")
    setValue("end", preferEndTime.toDate())
  }

  const renderDateSelector = () => (
    <div className="flex flex-wrap w-full">
      <div className="w-full lg:w-1/3 px-2">
        <label className="block  text-gray-700 font-semibold text-sm mb-2">
          {t("reservation.startDate")}
        </label>
        <div className="my-1 min-w-min-content border round-sm  shadow-md p-2 flex justify-center">
          <Controller
            name="start"
            control={control}
            render={({ onChange, value }) => (
              <DayPickerInput
                onDayChange={onChange}
                value={value}
                formatDate={(date) => dayjs(date)?.format("D MMM YYYY (ddd)")}
                dayPickerProps={{
                  locale: "th",
                  months: MONTHS,
                  weekdaysLong: WEEKDAYS_LONG,
                  weekdaysShort: WEEKDAYS_SHORT,
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full lg:w-1/3 px-2">
        <label className="block  text-gray-700 font-semibold text-sm mb-2">
          {t("reservation.endDate")}{" "}
        </label>
        {!watch("use_one_day") && (
          <div className="my-1 min-w-min-content border round-sm  shadow-md p-2 flex justify-center">
            <Controller
              name="end"
              control={control}
              render={({ onChange, value }) => (
                <DayPickerInput
                  onDayChange={onChange}
                  value={value}
                  formatDate={(date) => dayjs(date)?.format("D MMM YYYY (ddd)")}
                  dayPickerProps={{
                    locale: "th",
                    months: MONTHS,
                    weekdaysLong: WEEKDAYS_LONG,
                    weekdaysShort: WEEKDAYS_SHORT,
                  }}
                />
              )}
            />
          </div>
        )}
        <div>
          <Checkbox ref={register} name="use_one_day">
            {t("reservation.oneDay")}
          </Checkbox>
        </div>
      </div>
    </div>
  )

  const renderTimeSelctor = () => (
    <div className="flex flex-wrap w-full">
      <div className="w-full lg:w-1/4 px-2">
        <label className="block  text-gray-700 font-semibold text-sm mb-2">วันที่ทำกิจกรรม</label>
        <Controller
          name="start"
          control={control}
          defaultValue={defaultValue?.start}
          render={({ onChange, value }) => (
            <DatePicker
              showTime={{ format: "HH:mm" }}
              value={dayjs(value)}
              format="DD/MM/YYYY HH.mm"
              onChange={(timeValue) => onChange(timeValue?.toDate())}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/4 px-2">
        <label className="block  text-gray-700 font-semibold text-sm mb-2">เวลาสิ้นสุด</label>
        <Controller
          name="end"
          control={control}
          defaultValue={defaultValue?.end}
          render={({ onChange, value }) => (
            <DatePicker
              showTime={{ format: "HH:mm" }}
              value={dayjs(value)}
              format="DD/MM/YYYY HH.mm"
              onChange={(timeValue) => onChange(timeValue?.toDate())}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/3 px-2">
        <label className="block  text-gray-700 font-semibold text-sm mb-2">
          หรือ เลือกเป็นจำนวนชั่วโมง
        </label>
        <div className="flex gap-2">
          <Button size="sm" onClick={() => addHourToEnd(1)}>
            1 ชั่วโมง
          </Button>
          <Button size="sm" onClick={() => addHourToEnd(3)}>
            3 ชั่วโมง
          </Button>
          <Button size="sm" onClick={() => addHourToEnd(4)}>
            4 ชั่วโมง
          </Button>
          <Button size="sm" onClick={() => addHourToEnd(8)}>
            8 ชั่วโมง
          </Button>
        </div>
        <div className="text-sm my-2">
          สิ้นสุดในเวลา {dayjs(watch("end")).format("HH:mm")} คิดเป็นเวลา{" "}
          {dayjs(watch("end")).diff(dayjs(watch("start")), "hour")} ชั่วโมง{" "}
          {dayjs(watch("end")).diff(dayjs(watch("start")), "minute") % 60} นาที
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <div className="flex w-full flex-wrap">
        {showTitleForMeeting && (
          <div className="w-full px-2 my-1">
            <TextField
              label={t("reservation.meetingTitle")}
              name="name"
              placeholder={t("reservation.meetingTitle")}
              register={register}
              type="text"
              defaultValue={defaultValue?.name}
            />
          </div>
        )}
        <div className="relative w-full mb-3 px-2 ">
          <label
            className="block  text-gray-700 text-sm font-semibold mb-2"
            htmlFor="grid-password"
          >
            {t("crm.customer")}
          </label>
          <div className="flex ">
            <div className="w-3/4 lg:w-10/12">
              {isLoading && (
                <Controller
                  name="customer"
                  control={control}
                  defaultValue={_.find(
                    customers,
                    // eslint-disable-next-line no-underscore-dangle
                    (eachCustomer) => eachCustomer?.value === defaultValue?.customer?._id,
                  )}
                  render={(field) => (
                    <ReactSelect
                      placeholder={t("crm.addCustomerName")}
                      options={customers}
                      onChange={field.onChange}
                      defaultValue={field.value}
                    />
                  )}
                />
              )}
            </div>
            <div className="w-1/4 lg:w-2/12  self-center ml-3">
              <Button
                className="self-center"
                type="button"
                colorScheme="purple"
                size="sm"
                onClick={() => setIsModalCreateCustomerOpen(true)}
              >
                {t("crm.addCustomer")}
              </Button>
            </div>
          </div>
        </div>

        {showTimeSpecify && (
          <div className="w-full p-2">
            <FormControl
              display="flex"
              className="gap-2 items-center border rounded-md p-2 justify-center"
            >
              <div>ไม่ระบุเวลา</div>
              <Switch
                ref={register}
                name="time_specify"
                defaultValue={defaultValue?.time_specify}
                defaultChecked={defaultValue?.time_specify}
              >
                ระบุเวลา
              </Switch>
            </FormControl>
          </div>
        )}
        {!watch("time_specify") && renderDateSelector()}
        {watch("time_specify") && renderTimeSelctor()}

        <div className="w-full px-2 mt-2">
          <TextArea
            label={t("reservation.remark")}
            placeholder={t("reservation.remark")}
            name="remark"
            defaultValue={defaultValue?.remark}
            register={register}
            rows={2}
          />
        </div>
      </div>
    </div>
  )
}

ServiceOrderForm.propTypes = {
  register: PropTypes.any,
  control: PropTypes.object,
  defaultValue: PropTypes.object,
}

export default ServiceOrderForm
