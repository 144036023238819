import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import _ from "lodash"

import Layout from "../../components/layout/GeneralLayout"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import { CustomerForm } from "../../components/Forms/CRM"

export default function EditCustomer() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const { register, handleSubmit, watch, setValue } = useForm()
  const [uploadedImage, setUploadedImage] = useState()
  const customers = useSelector((state) => state.customers)

  const onEditCustomer = (data) => {
    const payload = {
      ...data,
      images: _.map(uploadedImage, (eachImage) => ({
        image: eachImage?.data_url,
      })),
    }
    dispatch(actions.editOneCustomer(customers?.id, payload)).then(() => {
      dispatch(actions.getAllCustomers())
      alert("บันทึกการแก้ไขสำเร็จ")
      history.push("/crm")
    })
  }

  useEffect(() => {
    const customersId = params?.id
    dispatch(actions.getOneCustomer(customersId)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params])

  useEffect(() => {
    if (!customers?.arr) {
      setValue("is_legal_entity", customers?.is_legal_entity)
    }

    return () => {}
  }, [customers])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการลูกค้า" title="แก้ไขลูกค้า">
      <div className="flex justify-between px-4 gap-2">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <div className="flex flex-wrap justify-between pb-8">
          <div className="w-full  ">
            <form onSubmit={handleSubmit(onEditCustomer)}>
              <CustomerForm
                register={register}
                defaultValue={customers}
                watch={watch}
                setValue={setValue}
              />
              {/* <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
                <div className="w-full px-4 ">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      รูปบัตรประชาชนด้านหน้า
                    </label>
                    <div className="flex justify-center">
                      <ImageUpload
                        images={uploadedImage}
                        setImages={setUploadedImage}
                        preview_size="250"
                        maxNumber={1}
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </div>{" "} */}
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึกการแก้ไข
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
