import { setStorage, removeStorage } from "../../util/localstorage"
import api from "../api"

export const authMethods = {
  signup: async (username, password, email, role, setErrors, setToken) => {
    api
      .post(`${process.env.REACT_APP_API_URL}/user/register`, {
        username,
        email,
        role,
        password,
      })
      .then(async (res) => {
        const eaccomRemember = {
          id: res.data?.data?.id,
          token: res.data?.accessToken,
        }
        const token = res.data?.accessToken
        await setStorage("token", token)
        await setStorage("eaccom_remember", JSON.stringify(eaccomRemember))

        setToken(window.localStorage.token)
        console.log("Sign up Success")
      })
      .catch((error) => {
        // console.log(err.message);
        window.alert(error)
        setErrors((prev) => [...prev, error])
      })
  },
  signupByHRMS: async (
    username,
    password,
    email,
    firstname,
    lastname,
    role,
    setErrors,
    setToken,
  ) => {
    api
      .post(`${process.env.REACT_APP_API_URL}/user/register`, {
        username,
        email,
        password,
        role,
        firstname,
        lastname,
      })
      .then(async (res) => {
        console.log("Signup with hrms success")
      })
      .catch((error) => {
        // console.log(err.message);
        window.alert(error)
        setErrors(error)
      })
  },
  signin: async (username, password, setErrors, setToken) =>
    // logiing in
    api
      .post(`${process.env.REACT_APP_API_URL}/user/login`, {
        username,
        password,
      })
      .then(async (res) => {
        console.log("Res.data", res.data)
        const eaccomRemember = {
          id: res.data?.data?.id,
          token: res.data?.accessToken,
        }
        const token = res.data?.accessToken
        //   console.log("Token Setting", token)
        await setStorage("token", token)
        await setStorage("eaccom_remember", JSON.stringify(eaccomRemember))
        await setStorage("user_info", JSON.stringify(res.data?.data))
        setToken(window.localStorage.token)
        // console.log("Set Token Success")
        return { user: res.data, error: null }
      })
      .catch((error) => {
        console.log("Singin Error", error)
        setErrors((prev) => [...prev, error])
        return { user: null, error }
      }),
  signout: async (setErrors, setToken) => {
    // signOut is a no argument function but we will send refresh token to them
    // eslint-disable-next-line no-undef
    const refreshToken = localStorage.getItem("refresh_token")
    removeStorage("token")
    removeStorage("eaccom_remember")
    removeStorage("refresh_token")
    removeStorage("user_info")
    api
      .post(`${process.env.REACT_APP_API_URL}/auth/logout/`, { refreshToken })
      .then(async (res) => {
        setToken(null)
        console.log("Sign out Success")
      })
      .catch((error) => {
        console.error(error)
        setErrors((prev) => [...prev, error])
      })
  },
  refreshToken: async (setErrors, setToken) => {
    // signOut is a no argument function but we will send refresh token to them
    // eslint-disable-next-line no-undef
    const refreshToken = localStorage.getItem("refresh_token")
    api
      .post(`${process.env.REACT_APP_API_URL}/auth/refresh-token/`, {
        refreshToken,
      })
      .then(async (res) => {
        console.log("Token Refreshing")
      })
      .catch((error) => {
        window.alert(error)
        setErrors((prev) => [...prev, error])
      })
  },
  forgotenPassword: async (email, setErrors, setToken) => {
    // await auth
    //   .sendPasswordResetEmail(email)
    //   .then((res) => {
    //     // remove the token
    //     removeStorage("eaccom_remember")
    //     // localStorage.removeItem("token");
    //     // set the token back to original state
    //     setToken(null)
    //   })
    //   .catch((err) => {
    //     // there shouldn't every be an error from firebase but just in case
    //     setErrors((prev) => [...prev, err.message])
    //     // whether firebase does the trick or not i want my user to do there thing.
    //     removeStorage("token")
    //     removeStorage("eaccom_remember")

    //     setToken(null)
    //     console.error(err.message)
    //   })
  },
}

export default authMethods
