import { BookingStatus } from "../../config/constant"

export const selectStatusColor = (status) => {
  switch (status) {
    case BookingStatus.book:
      return "#C4B5FD"
    case BookingStatus.confirm:
      return "#7C3AED"
    case BookingStatus.checkedIn:
      return "#7C3AED"
    case BookingStatus.checkedOut:
      return "#7C3AED"
    case BookingStatus.success:
      return "#7C3AED"
    case BookingStatus.pending:
      return "#7C3AED"
    case BookingStatus.cancle:
      return "#F5F3FF"
    default:
      return "#7C3AED"
  }
}

export const selectTailwindStatusColor = (status) => {
  switch (status) {
    case BookingStatus.book:
      return "yellow"
    case BookingStatus.confirm:
      return "pink"
    case BookingStatus.checkedIn:
      return "purple"
    case BookingStatus.checkedOut:
      return "red"
    case BookingStatus.success:
      return "green"
    case BookingStatus.pending:
      return "blue"
    case BookingStatus.cancle:
      return "red"
    default:
      return "blue"
  }
}

export default selectStatusColor
