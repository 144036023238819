import React from "react"
import { useTranslation } from "react-i18next"

import { paymentStatus } from "../../config/constant"

export default function PaymentStatusExplainer({ status, bookingInfo }) {
  const { t } = useTranslation()
  const selectDepositStatus = () => {
    switch (status) {
      case paymentStatus.deposit.depositWait:
        return (
          <div className="text-sm whitespace-no-wrap">
            <i className="fas fa-times mr--500" /> {t("reservation.deposit")}{" "}
          </div>
        )
      case paymentStatus.deposit.depositPay:
        return (
          <div className="text-sm whitespace-no-wrap">
            <i className="fas fa-question mr-1 " /> {t("reservation.deposit")}{" "}
          </div>
        )
      case paymentStatus.deposit.depositConfirm:
        return (
          <div className="text-sm whitespace-no-wrap">
            <i className="fas fa-check mr-1 " /> {t("reservation.deposit")}{" "}
          </div>
        )
      default:
        return (
          <div className="text-sm whitespace-no-wrap">
            <i className="fas fa-check mr-1 " /> {t("reservation.deposit")}{" "}
          </div>
        )
    }
  }

  const selectAllPaymentStatus = () => {
    if (bookingInfo?.payment?.paid_amount >= bookingInfo?.price) {
      return (
        <div className="text-sm whitespace-no-wrap">
          <i className="fas fa-check  mr-1 " /> {t("reservation.fullAmount")}{" "}
        </div>
      )
    }
    return (
      <div className="text-sm whitespace-no-wrap">
        <i className="fas fa-times mr-1 " /> {t("reservation.fullAmount")}{" "}
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      {selectDepositStatus()}
      {selectAllPaymentStatus()}
    </div>
  )
}
