import React from "react"
import moment from "moment"

function OTADataPanel({ otaData }) {
  return (
    <div className="my-2">
      <h6 className="text-xs font-bold font-sans">ข้อมูลการจองผ่าน OTA</h6>
      <ul className="list-inside list-disc text-sm">
        <li>หมายเลขการจอง : {otaData?.booking_id || "-"}</li>
        {otaData?.booking_date && (
          <li>วันที่จอง : {moment(otaData?.booking_date).locale("th").format("D MMM YYYY")}</li>
        )}
        {otaData?.adult_amount && <li>จำนวนผู้ใหญ่ : {otaData?.adult_amount} คน</li>}
        {otaData?.kid_amount ? <li>จำนวนเด็ก : {otaData?.kid_amount} คน</li> : <></>}
        {otaData?.promotion && <li>โปรโมชั่น : {otaData?.promotion} </li>}
        {otaData?.breakfast && <li>รวมอาหารเช้า : {otaData?.breakfast ? "รวม" : "ไม่รวม"} </li>}
      </ul>
    </div>
  )
}

export default OTADataPanel
