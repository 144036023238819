import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
} from "@chakra-ui/react"
import _ from "lodash"

import UploadImage from "../../ImageUpload/ImageUpload"
import * as actions from "../../../redux/actions"
import DialogLoading from "../../Loading/DialogLoading"
import Alert from "../../common/Alert/AlertDialog"

export default function ModalAddPhotoToRoomType({ isOpen, handleOnClose, roomTypeInfo }) {
  const dispatch = useDispatch()
  const [images, setImages] = useState([])
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [isDialogLoadingOpen, setIsDialogLoadingOpen] = useState(false)

  const handleOnUpload = () => {
    setIsDialogLoadingOpen(true)
    const imagePayload = _.map(images, (eachImage) => ({
      image: eachImage.data_url,
      imageType: "roomType",
    }))

    console.log("Image Payload", imagePayload)

    let galleryPayload = roomTypeInfo?.images

    try {
      galleryPayload = _.concat(galleryPayload, imagePayload)
      console.log("Image Payload can push", galleryPayload)
    } catch (error) {
      console.error("Cannot Push Image ", error)
      setErrMessage("ไม่สามารถเพิ่มรูปภาพได้")
      setIsError(true)
    }

    dispatch(actions.editRoomType(roomTypeInfo?.id, { images: galleryPayload }))
      .then(() => {
        dispatch(actions.getRoomType(roomTypeInfo?.id))
          .then(() => {
            handleOnClose()
            setImages([])
          })
          .catch((err) => {
            setErrMessage(err)
            setIsError(true)
          })
          .finally(() => {
            setIsDialogLoadingOpen(false)
          })
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
      .finally(() => {
        setIsDialogLoadingOpen(false)
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay /> <DialogLoading isLoading={isDialogLoadingOpen} title="กำลังอัพโหลดรูปภาพ" />
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage()
        }}
      />
      <ModalContent>
        <ModalHeader className="font-sans" textStyle={{ fontsize: "sm" }}>
          เพิ่มรูปภาพ
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <UploadImage images={images} setImages={setImages} preview_size="100" maxNumber={10} />
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2 font-sans">
            <Button size="sm" colorScheme="green" onClick={handleOnUpload}>
              อัพโหลด
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
