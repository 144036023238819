/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory, Link } from "react-router-dom"
import { Result } from "antd"

import { GeneralMainLayout as Layout, SpinnerLoading, WhitePanel, Alert } from "../../components"
import * as actions from "../../redux/actions"

function MainPage() {
  const params = useParams()
  const table = useSelector((state) => state.table)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const dispatch = useDispatch()

  const handleCreateOrder = () => {
    dispatch(actions.createOneFBOrder({ table: table._id, date: new Date() }))
      .then(() => {
        setIsLoading(false)
        dispatch(actions.getOneTable(params?.id)).then(() => {
          setIsLoading(true)
        })
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
  }

  useEffect(() => {
    dispatch(actions.getOneTable(params?.id))
      .then(() => {})
      .catch((err) => {})
      .finally(() => {
        setIsLoading(true)
      })
    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  if (!table?._id) {
    return (
      <Layout categoryTitle="Mobile Order" title="หน้าแรก">
        <Result
          status="warning"
          title="ไม่พบโต๊ะของท่าน"
          subTitle="โปรดตรวจสอบว่า QR Code ที่ท่านได้สแกนถูกต้องแล้วหรือไม่"
        />
      </Layout>
    )
  }

  return (
    <Layout categoryTitle="Mobile Order" title="หน้าแรก">
      <Alert isError={isError} errMessage={errorMessage} handleOnClose={() => setIsError(false)} />
      <div className="px-4 min-h-screen">
        <WhitePanel>
          <div className="text-center">
            <div className="text-xl font-semibold"> โต๊ะ {table?.name}</div>
            <div className="text-lg">
              {" "}
              เลขออเดอร์{" "}
              {table?.attached_order && !table?.attached_order?.completed
                ? table?.attached_order?.bill_number
                : "-"}
            </div>
          </div>
        </WhitePanel>

        {table?.attached_order && !table?.attached_order?.completed ? (
          <div>
            <div className="my-4">
              <Link to={`/mobile-order/menu/${table?.attached_order?._id}`}>
                <div className=" bg-indigo-600 shadow-lg rounded-lg text-center  py-4 md:py-8 px-4 lg:h-40 ">
                  <i className="fas fa-cart-plus fa-2x md:fa-3x text-white"></i>
                  <p className="text-lg text-white  mt-2 md:mt-4 font-semibold">สั่งอาหาร</p>
                </div>
              </Link>
            </div>
            <div className="my-4">
              <Link to={`/mobile-order/detail/${table?.attached_order?._id}`}>
                <div className=" bg-pink-600 shadow-lg rounded-lg text-center  py-4 md:py-8 px-4 lg:h-40 ">
                  <i className="fas fa-list-alt fa-2x md:fa-3x text-white"></i>
                  <p className="text-lg text-white  mt-2 md:mt-4 font-semibold">รายการที่สั่งไป</p>
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <div className="my-4">
            <div
              className=" bg-indigo-600 shadow-lg rounded-lg text-center  py-4 md:py-8 px-4 lg:h-40 cursor-pointer"
              onClick={handleCreateOrder}
            >
              <i className="fas fa-plus-circle fa-2x md:fa-3x text-white"></i>
              <p className="text-lg text-white  mt-2 md:mt-4 font-semibold">สร้างออเดอร์ใหม่</p>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default MainPage
