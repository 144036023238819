import React, { forwardRef, useState } from "react"
import { useForm } from "react-hook-form"
import { FormLabel, Input, FormControl, Checkbox, Select } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import SliderPaddingPicture from "../../../common/Slider/SliderPaddingPicture"
import SlideshowComponentArea from "../../../common/Slider/SlideshowComponentArea"
import OpacitySlider from "../../../common/Slider/OpacitySlider"
import { motion } from 'framer-motion';
import { Button, Badge } from "@chakra-ui/react"
import SliderType from "../../../common/Slider/SliderType"
import SliderTypeSliderButton from "../../../common/Slider/SliderTypeSliderButton"
import SlideshowTextMobileComponent from "../../../common/Slider/SlideshowTextMobileComponent"
import SliderMobiletextpadding from "../../../common/Slider/SliderMobiletextpadding"
import SliderPaddingSlideshow from "../../../common/Slider/SliderPaddingSlideshow"
import { BlockPicker, SketchPicker } from "react-color"

import { RichTextEditor } from "../../../common/RichTextEditor"
import { Controller } from "react-hook-form"
import { PicturePosition, SlideTextPosition, TextColor } from "../../../../config/constant"

import {
  ComponentWidthSlideShowMobile,
  ComponentHeightSlideShowMobile,
  ButtonType
} from "../../../../config/constant"


function Slideshow({ register, defaultValue, watch, control, activeTab, frontpageComponent,
  handleEditFrontpageComponent = () => { },
  handleDelete }) {

  const [isEditorMode, setIsEditorMode] = useState(false)
  const { handleSubmit, setValue } = useForm({
    defaultValues: frontpageComponent,
    mode: "onChange",
  })


  const { i18n } = useTranslation()

  return (
    <div>
      {activeTab === 'Desktop' && (
        <div>
          <div className="border-2 p-4 pt-4  justify-items-center items-center  border-black font-head flex justify-center">
            จัดการพื้นส่วนประกอบสไลด์โชว
          </div>
          <div className="pb-10 pt-10">
            <div className="flex gap-4 pr-44">
              <div className="flex gap-4 lg:pl-0 pl-0  ">
                <div className="pr-8">
                  <FormLabel fontSize="sm">ข้อความต้องการใส่บน Slide Show</FormLabel>
                </div>
                <div className="w-72">
                  <label
                    className="lg:block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    ภาษาไทย
                  </label>
                  <Controller
                    control={control}
                    name="desktop.Slideshow.translation.th.text_content"
                    defaultValue={defaultValue?.desktop?.Slideshow?.translation?.th?.text_content}
                    render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                  />
                </div>
                <div className="w-72 pb-0">
                  <label
                    className="lg:block flex text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    ภาษาอังกฤษ
                  </label>
                  <Controller
                    control={control}
                    name="desktop.Slideshow.translation.en.text_content"
                    defaultValue={defaultValue?.desktop?.Slideshow?.translation?.en?.text_content}
                    render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                  />
                </div>
                <div className="pl-20">
                  <h6 className="lg:ml-24  ml-0 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                    สีตัวอักษร{" "}
                  </h6>
                  <div className="w-56 flex justify-center">
                    <Controller
                      control={control}
                      name="desktop.Slideshow.text_content.text_color"
                      defaultValue={defaultValue?.desktop?.Slideshow?.text_content?.text_color}
                      render={(field) => (
                        <SketchPicker
                          color={field.value}
                          onChangeComplete={(selectedColor) => {
                            field.onChange(selectedColor.hex)
                          }}
                          width={256}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-6 pt-4">
              <SlideshowComponentArea
                name=""
                register={register}
                defaultValue={defaultValue}
                watch={watch}
              />
            </div>
            <div className="flex relative  justify-between pb-80 pt-3">
              <div className="absolute top-0 pt-4 2xl:left-4 lg:left-0">
                <label className="text-l text-teal-700  items-center font-semibold">ตำแหน่งเริ่มต้น<br></br>ของรูปภาพ</label>
                <select
                  ref={register}
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="ตำแหน่งเริ่มต้นของรูปภาพ"
                  defaultValue={defaultValue?.desktop?.Slideshow?.picture_position}
                  name="desktop.Slideshow.picture_position"
                  size="sm"
                >
                  {_.map(PicturePosition, (each, index) => (
                    <option
                      key={index}
                      value={each.status_code}
                      className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                    >
                      {i18n?.language === "th" ? each.description : each.descriptionEn}
                    </option>
                  ))}
                </select>
              </div>
              <div className="absolute top-0 pt-4 2xl:left-96 lg:left-80">
                <SliderPaddingPicture
                  rightPaddingPicture='desktop.Slideshow.right_padding_picture'
                  leftPaddingPicture='desktop.Slideshow.left_padding_picture'
                  topPaddingPicture='desktop.Slideshow.top_padding_picture'
                  bottomPaddingPicture='desktop.Slideshow.bottom_padding_picture'
                  height_picture='desktop.Slideshow.height_picture'
                  register={register}
                  defaultValue={defaultValue}
                  watch={watch}
                />
              </div>
              <div className="absolute top-0 2xl:right-80 lg:right-96 right-96">
                <div className="absolute top-0 pt-4  right-96">
                  <label className="text-l  text-teal-700 pr-6 flex justify-center pl-4  items-center font-semibold">ตำแหน่งข้อความ</label>
                  <select
                    ref={register}
                    className="block w-full border-2 border-black mt-1 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    placeholder="ตำแหน่งเริ่มต้นของรูปภาพ"
                    defaultValue={defaultValue?.desktop?.Slideshow?.text_content?.slide_show_text_position}
                    name="desktop.Slideshow.text_content.slide_show_text_position"
                    size="sm"
                  >
                    {_.map(SlideTextPosition, (each, index) => (
                      <option
                        key={index}
                        value={each.status_code}
                        className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                      >
                        {i18n?.language === "th" ? each.description : each.descriptionEn}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex absolute  pt-4 top-0 2xl:right-96  lg:right-52 ">
                <div className="2xl:pl-4  lg:pl-0 pt-6">
                  <SliderPaddingSlideshow
                    topPaddingPicture='desktop.Slideshow.text_content.top_padding_picture'
                    bottomPaddingPicture='desktop.Slideshow.text_content.bottom_padding_picture'
                    height_picture='desktop.Slideshow.text_content.height_picture'
                    width_picture='desktop.Slideshow.text_content.width_picture'
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                </div>
                <div className="absolute  pt-4 top-0 2xl:left-96 lg:left-72 lg:pt-12">
                  <OpacitySlider
                    opacityBorder='desktop.Slideshow.text_content.opacityBorder'
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                  <div className="pt-8">
                    <h1 className="text-lg">สีกรอบข้อความ</h1>
                    <Input
                      size="sm"
                      placeholder="โค้ดสี"
                      defaultValue={defaultValue?.desktop?.Slideshow?.text_content?.border_color || '#โค้ดสีกรอบข้อความ'}
                      name="desktop.Slideshow.text_content.border_color"
                      ref={register}
                      className="text-black"
                      style={{
                        backgroundColor: `${watch('desktop.Slideshow.text_content.border_color')}`
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-2 pt-8">
            <FormLabel fontSize="sm">แสดงปุ่มดูแกลอรี่ทั้งหมด</FormLabel>
            <FormControl display="flex">
              <Checkbox
                ref={register}
                defaultChecked={defaultValue?.show_button_slideshow}
                name="show_button_slideshow"
              >
                เปิดใช้
              </Checkbox>
            </FormControl>
            {watch(`show_button_slideshow`) === true &&
              (<div>
                <div className="pt-2">
                  <FormLabel fontSize="sm">ภาษาไทย</FormLabel>
                  <Input
                    ref={register}
                    placeholder="จำนวนรูปภาพ"
                    name="desktop.Slideshow.slide_button.th.text_button"
                    // type="number"
                    size="sm"
                    defaultValue={defaultValue?.desktop?.Slideshow?.slide_button?.th?.text_button || 'กรอกชื่อปุ่ม'}
                    required
                  />
                </div>
                <div className="pt-2 pb-4">
                  <FormLabel fontSize="sm">ภาษาอังกฤษ</FormLabel>
                  <Input
                    ref={register}
                    placeholder="จำนวนรูปภาพ"
                    name="desktop.Slideshow.slide_button.en.text_button"
                    // type="number"
                    size="sm"
                    defaultValue={defaultValue?.desktop?.Slideshow?.slide_button?.en?.text_button || 'กรอกชื่อปุ่ม'}
                    required
                  />
                </div>
                <div className="flex gap-24 pb-20 pt-4">
                  <div>
                    <h1>สีตัวอักษร</h1>
                    <Controller
                      control={control}
                      setValue={watch('desktop?.Slideshow?.slide_button?.text_color')}
                      // defaultValue={defaultValue?.desktop?.video?.button?.button_text_color}
                      name="desktop.Slideshow.slide_button.text_color"
                      render={(field) => (
                        <SketchPicker
                          color={field.value}
                          onChangeComplete={(selectedColor) => {
                            field.onChange(selectedColor.hex)
                          }}
                          width={256}
                        />
                      )}
                    /></div>
                  <div>
                    <div>
                      <h1>สีปุ่ม</h1>
                      <Controller
                        control={control}
                        setValue={watch('desktop?.Slideshow?.slide_button?.button_color')}
                        name="desktop.Slideshow.slide_button.button_color"
                        render={(field) => (
                          <SketchPicker
                            color={field.value}
                            onChangeComplete={(selectedColor) => {
                              field.onChange(selectedColor.hex)
                            }}
                            width={256}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="text-sm font-semibold text-gray-700">รูปแบบปุ่มกด</label>
                    <select
                      ref={register}
                      className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="ความกว้าง"
                      defaultValue={defaultValue?.desktop.Slideshow.slide_button.button_type}
                      name="desktop.Slideshow.slide_button.button_type"
                      size="sm"
                    >
                      {_.map(ButtonType, (each, index) => (
                        <option
                          key={index}
                          value={each.status_code}
                          className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                        >
                          {i18n?.language === "th" ? each.description : each.descriptionEn}
                        </option>
                      ))}
                    </select>
                    {/* <SliderTypeSliderButton
                      watch={watch}
                    /> */}
                  </div>
                </div>
              </div>
              )}
          </div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 px-2 py-1">
              <FormLabel fontSize="sm">จำนวนรูปภาพ</FormLabel>
              <Input
                ref={register}
                placeholder="จำนวนรูปภาพ"
                name="type_attribute.limit"
                type="number"
                size="sm"
                defaultValue={defaultValue?.type_attribute?.limit || 10}
                required
              />
            </div>
          </div>
        </div>
      )}
      {activeTab === 'Mobile' && (
        <div>
          <motion.div
            className="flex pr-80 gap-10 pt-3"
            initial={{ opacity: 0, y: -20 }} // เซ็ตค่าเริ่มต้นก่อนที่คอมโพเนนต์จะแสดง
            animate={{ opacity: 1, y: 0 }} // เซ็ตค่าขณะคอมโพเนนต์กำลังแสดง
            transition={{ duration: 0.5 }} // ตั้งค่าเวลาที่ให้เคลื่อนไหว
          >
            <div className="flex pr-10 gap-10 pt-3"> {/* ปรับขนาดระยะห่าง pr-10 จากเดิมเป็น pr-80 */}
              <div className="w-full lg:w-1/2 px-2 py-1">
                <label className="text-sm font-semibold text-gray-700">ความกว้าง</label> {/* ปรับตัวอักษรให้เป็น font-semibold */}
                <select
                  ref={register}
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="ความกว้าง"
                  defaultValue={defaultValue?.mobile?.Slideshow?.slide_width}
                  name="mobile.Slideshow.slide_width"
                  size="sm"
                >
                  {_.map(ComponentWidthSlideShowMobile, (each, index) => (
                    <option
                      key={index}
                      value={each.status_code}
                      className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                    >
                      {i18n?.language === "th" ? each.description : each.descriptionEn}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full lg:w-1/2 px-2 py-1">
                <label className="text-sm font-semibold text-gray-700">ความสูง</label> {/* ปรับตัวอักษรให้เป็น font-semibold */}
                <select
                  ref={register}
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="ความสูง"
                  defaultValue={defaultValue?.mobile?.Slideshow?.slide_height}
                  name="mobile.Slideshow.slide_height"
                  size="sm"
                >
                  {_.map(ComponentHeightSlideShowMobile, (each, index) => (
                    <option
                      key={index}
                      value={each.status_code}
                      className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                    >
                      {i18n?.language === "th" ? each.description : each.descriptionEn}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </motion.div>
          <div className="flex justify-center gap-6 pt-4 pb-8">
            <div className="w-64">
              <label
                className="lg:block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                ภาษาไทย
              </label>
              <Controller
                control={control}
                name="mobile.Slideshow.translation.th.text_content"
                defaultValue={defaultValue?.mobile?.Slideshow?.translation?.th?.text_content}
                render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
              />
            </div>
            <div className="w-64 pb-0">
              <label
                className="lg:block flex text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                ภาษาอังกฤษ
              </label>
              <Controller
                control={control}
                name="mobile.Slideshow.translation.en.text_content"
                defaultValue={defaultValue?.mobile?.Slideshow?.translation?.en?.text_content}
                render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
              />
            </div>
          </div>
          <div className="pb-6 pt-4">
            <SlideshowTextMobileComponent
              name=""
              register={register}
              defaultValue={defaultValue}
              watch={watch}
            />
          </div>
          <div className="flex relative  justify-between pb-80 pt-3">
            <div className="flex absolute  pt-4 top-0 left-96">
              <div>
                <SliderMobiletextpadding
                  rightPaddingPicture='mobile.Slideshow.text_content.right_padding_picture'
                  leftPaddingPicture='mobile.Slideshow.text_content.left_padding_picture'
                  topPaddingPicture='mobile.Slideshow.text_content.top_padding_picture'
                  bottomPaddingPicture='mobile.Slideshow.text_content.bottom_padding_picture'
                  height_picture='mobile.Slideshow.text_content.height_picture'
                  width_picture='mobile.Slideshow.text_content.width_picture'
                  register={register}
                  defaultValue={defaultValue}
                  watch={watch}
                />
              </div>
              <div className="flex absolute  pt-4 top-0 left-96">
                <OpacitySlider
                  opacityBorder='mobile.Slideshow.text_content.opacityBorder'
                  register={register}
                  defaultValue={defaultValue}
                  watch={watch}
                />
              </div>
            </div>
          </div>
        </div>

      )}

      <div className="w-full lg:w-1/2 px-2 py-1">
        <FormLabel fontSize="sm">เวลาต่อสไลด์ (วินาที)</FormLabel>
        <Input
          ref={register}
          placeholder="เวลาต่อสไลด์"
          name="type_attribute.time_per_slide"
          type="number"
          size="sm"
          defaultValue={defaultValue?.type_attribute?.time_per_slide || 10}
        />
      </div>
    </div>
  )
}

export default forwardRef(Slideshow)
