import React from "react"
import { useTranslation } from "react-i18next"
import { OnlineBookingProgress } from "../../config/constant"
import { DateSelectVerticleForm } from "../Forms"
import { CardProgress, WhitePanel } from "../common/Cards"
import { OnlineBookingBottomPanel } from "../Panels"
import GeneralMainLayout from "./GeneralLayout"

const OnlineBookingLayout = ({
  title,
  checkinDate,
  checkoutDate,
  setCheckedInDate,
  setCheckOutDate,
  selectedRoomType,
  setSelectedRoomType,
  handlePrevious,
  handleNext,
  children,
  selectedRooms = [],
  selectedIndex,
  enableVerticleForm = true,
}) => {
  const { t } = useTranslation()
  return (
    <GeneralMainLayout categoryTitle={t("reservation.onlineBooking")} title={title}>
      <CardProgress stepList={OnlineBookingProgress} selectedIndex={selectedIndex} />
      <div className="flex flex-wrap pb-24 justify-center">
        {enableVerticleForm && (
          <div className="hidden lg:block lg:w-1/4">
            <WhitePanel>
              <DateSelectVerticleForm
                checkinDate={checkinDate}
                checkoutDate={checkoutDate}
                selectedRoomType={selectedRoomType}
                setSelectedRoomType={setSelectedRoomType}
                setCheckinDate={setCheckedInDate}
                setCheckoutDate={setCheckOutDate}
              />
            </WhitePanel>
          </div>
        )}
        <div className="w-full lg:w-3/4 px-2">{children}</div>
      </div>
      {enableVerticleForm && (
        <OnlineBookingBottomPanel
          checkinDate={checkinDate}
          checkoutDate={checkoutDate}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          selectedRooms={selectedRooms}
          selectedIndex={selectedIndex}
        />
      )}
    </GeneralMainLayout>
  )
}

export default OnlineBookingLayout
