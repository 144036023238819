/* eslint-disable import/no-unresolved */
import React, { Suspense, useEffect } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import { Provider } from "react-redux"
import { ChakraProvider, extendTheme } from "@chakra-ui/react"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"
import "dayjs/locale/th"
import locale from "antd/locale/th_TH"
import "@fortawesome/fontawesome-free/css/all.min.css"
// import "./assets/styles/index.css"
import "./assets/styles/print.css"
import "./assets/styles/tailwind.css"

// PrivateRoute Contexts
// AuthProvider Contexts

// Home or Portal Menu
import { ConfigProvider, theme as antdTheme } from "antd"
import Home from "./views/Home"

// layouts
import Auth from "./layouts/Auth.js"
import System from "./layouts/System"
import Rooms from "./layouts/Rooms"
import Reservation from "./layouts/Reservation"
import History from "./layouts/History"
import HotelSite from "./layouts/HotelSite"
import CRM from "./layouts/Customer"
import CheckIn from "./layouts/CheckIn"
import FoodBeverage from "./layouts/FoodBeverage"
import OnlineBookingLayout from "./layouts/OnlineBooking"
import SuperSystemLayout from "./layouts/SuperSystem"
import MobileOrderLayout from "./layouts/MobileOrder"
import InventoryLayout from "./layouts/Inventory"

import "./i18n"
// Mockup API
// import "./mock"

import PrivateRoute from "./contexts/PrivateRoute"
import AuthProvider from "./contexts/AuthContext"
import configureStore from "./redux/configureStore"
import CustomTheme from "./theme"

import { SpinnerLoading } from "./components/Loading"

import HNY2023 from "./components/Modal/Campaign/HNY2023"

dayjs.extend(buddhistEra)

const store = configureStore()
if (process.env.NODE_ENV === "production") {
  console.log = () => {}
}

if (module.hot) {
  module.hot.accept()
}

const theme = extendTheme(CustomTheme)

ReactDOM.render(
  <Router basename="/">
    <Suspense fallback={<SpinnerLoading />}>
      <Provider store={store}>
        <AuthProvider>
          <ChakraProvider theme={theme}>
            <ConfigProvider locale={locale}>
              <div className="font-sans">
                {/* {!window.localStorage.getItem("EA-HSD-2023") && <HNY2023 />} */}
                <Switch>
                  {/* add routes with layouts */}
                  <Route path="/auth" component={Auth} />
                  <Route path="/checkIn" component={CheckIn} />
                  <Route path="/online-booking" component={OnlineBookingLayout} />
                  <Route path="/mobile-order" component={MobileOrderLayout} />
                  {/* PrivateRoute */}
                  <PrivateRoute>
                    <Route exact path="/" component={Home} />
                    <Route path="/system" component={System} />
                    <Route path="/roomtypes" component={Rooms} />
                    <Route path="/reservation" component={Reservation} />
                    <Route path="/history" component={History} />
                    <Route path="/site" component={HotelSite} />
                    <Route path="/crm" component={CRM} />
                    <Route path="/food-beverage" component={FoodBeverage} />
                    <Route path="/super-system" component={SuperSystemLayout} />
                    <Route path="/inventory" component={InventoryLayout} />

                    {/* <Redirect from="*" to="/" /> */}
                  </PrivateRoute>

                  {/* add redirect for NotFound page */}
                  <Redirect from="*" to="/auth" />
                </Switch>
              </div>{" "}
            </ConfigProvider>
          </ChakraProvider>
        </AuthProvider>
      </Provider>{" "}
    </Suspense>
  </Router>,
  document.getElementById("root"),
)
