export const CRMType = {
  PET: {
    status_code: "PET",
    description: "สัตว์เลี้ยง",
    descriptionEn: "Pets",
  },
  LIKE_FOOD: {
    status_code: "LIKE_FOOD",
    description: "อาหารที่ชอบ",
    descriptionEn: "Liked Food",
  },
  DISLIKE_FOOD: {
    status_code: "DISLIKE_FOOD",
    description: "อาหารที่ไม่ชอบ",
    descriptionEn: "Dislike Food",
  },
  ACTIVITY: {
    status_code: "ACTIVITY",
    description: "กิจกรรม",
    descriptionEn: "Activity",
  },
  FAVORITE: {
    status_code: "FAVORITE",
    description: "ความชื่นชอบ",
    descriptionEn: "Favorite",
  },
  TRANSPORTATION: {
    status_code: "TRANSPORTATION",
    description: "การเดินทาง",
    descriptionEn: "Transportation",
  },
  COMMUNICATE: {
    status_code: "COMMUNICATE",
    description: "การพูดคุย",
    descriptionEn: "Communication",
  },
  OTHER: {
    status_code: "OTHER",
    description: "อื่นๆ",
    descriptionEn: "Others",
  },
}

export default CRMType
