/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import THBText from "thai-baht-text"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"

dayjs.extend(buddhistEra)

export default function RoomReport(
  transactions,
  date,
  startDate,
  endDate,
  isPointDate,
  information,
  systemInfo,
) {
  const allPriceAmount = _.map(
    transactions,
    (eachList) =>
      _.find(eachList?.bookings?.list, (each) => each?.room === eachList?._id)?.price *
      dayAmount(eachList?.bookings?.start, eachList?.bookings?.end),
  )

  const bookingList = _.map(transactions, (eachList) => eachList?.bookings)
  const uniqueBookingList = _.uniqBy(bookingList, "_id")
  const allPaidAmount = _.map(uniqueBookingList, (eachBooking) => eachBooking?.payment?.paid_amount)

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายงานห้องพัก",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายงานห้องพัก /Room Report ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      isPointDate
        ? {
            text: [
              { text: "วันที่", bold: true },
              {
                text: `  ${dayjs(date).format("D MMMM BBBB")}  (วันที่ ${dayjs(date).format(
                  "D MMMM BBBB",
                )} เวลา 12.01 - วันที่ ${dayjs(date)
                  .add(1, "day")
                  .format("D MMMM BBBB")} เวลา 11.59 )`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          }
        : {
            text: [
              { text: "จากวันที่", bold: true },
              {
                text: `  ${dayjs(startDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
              { text: "ถึงวันที่", bold: true },
              {
                text: `  ${dayjs(endDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, 40, "*", 60, 40, 50, 50, 40, 40, 30, 30, 30, 40, 40, 40, 40],
          body: [
            [
              { text: "ลำดับที่", bold: true },
              { text: "เลขที่การจอง", bold: true },
              { text: "ชื่อ", bold: true },
              { text: "โทร", bold: true },
              { text: "วันที่ทำการจอง", bold: true },
              { text: "วันที่เข้าพัก", bold: true },
              { text: "วันที่ออก", bold: true },
              { text: "ห้องพัก", bold: true },
              { text: "หมวดหมู่", bold: true },
              { text: "จำนวนคืน", bold: true },
              { text: "เรทราคา", bold: true },
              { text: "ราคา", bold: true },
              { text: "จ่ายล่วงหน้า", bold: true },
              { text: "สถานะ", bold: true },
              { text: "ผู้จัดทำ", bold: true },
              { text: "หมายเหตุ", bold: true },
            ],
            ..._.map(transactions, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${eachList?.bookings?.booking_no || ""}`,
                margin: [0, 2],
              },
              {
                text: `${eachList?.bookings?.customer?.firstname || "-"} ${
                  eachList?.bookings?.customer?.lastname || ""
                }`,
              },
              {
                text: eachList?.bookings?.customer?.tel || "",
              },
              {
                text: dayjs(eachList?.bookings?.createdAt).format("D MMM  HH:mm"),
                fontSize: 9,
              },
              {
                text: `${dayjs(eachList?.bookings?.start).format("dd D MMM BBBB")}`,
              },
              {
                text: `${dayjs(eachList?.bookings?.end).format("dd D MMM BBBB")}`,
              },
              {
                text: eachList?.name || "",
              },
              {
                text: eachList?.type?.translation?.th?.name || eachList?.type?.name || "",
              },
              {
                text: `${dayAmount(eachList?.bookings?.start, eachList?.bookings?.end)} คืน`,
              },
              {
                text: currencyFormatter.format(
                  _.find(eachList?.bookings?.list, (each) => each?.room === eachList?._id)?.price,
                ),
              },
              {
                text: currencyFormatter.format(
                  _.find(eachList?.bookings?.list, (each) => each?.room === eachList?._id)?.price *
                    dayAmount(eachList?.bookings?.start, eachList?.bookings?.end),
                ),
              },
              {
                text: eachList?.bookings?.payment?.paid_amount || "",
              },
              {
                text: eachList?.bookings?.status || "",
                fontSize: 9,
              },
              {
                text: eachList?.bookings?.user?.employee?.firstname || "",
                fontSize: 9,
              },
              {
                text: eachList?.bookings?.remark || "",
                fontSize: 9,
              },
            ]),
            [
              { text: "รวม", colSpan: 10, bold: true },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              { text: currencyFormatter.format(_.sum(allPriceAmount)), colSpan: 2 },
              {},
              {
                text: currencyFormatter.format(_.sum(allPaidAmount)),
                colSpan: 2,
              },
              {},
              { text: "" },
              { text: "" },
            ],
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
