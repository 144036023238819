/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

// components

// views
import CustomerManagement from "../views/CRM/CustomerManagement"
import CreateCustomer from "../views/CRM/CreateCustomer"
import ShowCRM from "../views/CRM/ShowCRM"
import EditCRM from "../views/CRM/Components/EditCRM"
import EditCustomer from "../views/CRM/EditCustomer"
import SpinnerLoading from "../components/Loading/SpinnerLoading"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import MainSidebar from "../components/common/Sidebar/MainSidebar"
import * as actions from "../redux/actions"

import UserRole from "../config/UserRoles"
import { ModuleList } from "../config/constant/ModuleList"
import { CUSTOMER_RELATION_MENU } from "../config/MenuList"

export default function RoomsLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("CustomerLayout : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.access?.[ModuleList.SERVICE_ORDER.status_code] ||
        me?.role === UserRole.SUPER_USER.toString() // Reserved for some system
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <MainSidebar
        menuList={CUSTOMER_RELATION_MENU}
        setIsShowSidebar={setIsShowSidebar}
        isShow={showSidebar}
      />
      <div className=" relative bg-gray-200 lg:ml-64">
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/crm" exact component={() => <CustomerManagement />} />
            <Route path="/crm/create" exact component={() => <CreateCustomer />} />
            <Route path="/crm/show/:id" exact component={() => <ShowCRM />} />
            <Route path="/crm/edit/:id" exact component={() => <EditCustomer />} />
            <Route path="/crm/show/edit/:id" exact component={() => <EditCRM />} />
            <Redirect from="/crm" to="/crm" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
