/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

// components
import { OnlineBookingNavbar } from "../components"
import OnlineBookingProvider from "../contexts/OnlineBookingContext"

// views

import FooterBooking from "../components/common/Footers/FooterBooking"
import * as actions from "../redux/actions"
import ByPassAuthorizeChecking from "../views/CheckIn/ByPassAuthorizeChecking"

import DateSearch from "../views/OnlineBooking/DateSearch"
import RoomSelection from "../views/OnlineBooking/RoomSelection"
import ServiceSelect from "../views/OnlineBooking/ServiceSelect"
import OnlineBookingAddInfo from "../views/OnlineBooking/OnlineBookingAddInfo"
import BookingPayment from "../views/OnlineBooking/BookingPayment"
import BookingSuccess from "../views/OnlineBooking/BookingSuccess"

export default function OnlineBooking() {
  const dispatch = useDispatch()
  const [outerLogin, setIsOuterLogin] = useState(false)
  const { t } = useTranslation()

  console.log("outerLogin", outerLogin)

  useEffect(() => {
    console.log("CustomerLayout : ACTIVE")
    // Login User
    if (window.localStorage.eaccom_remember) {
      const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {
          setIsOuterLogin(false) // Direct To Checkin
          dispatch(actions.getInformation())
        })
        .catch((err) => {
          window.alert(err)
          setIsOuterLogin(true) // Go to Recaptcha
        })
    }

    dispatch(actions.systemInfoGet()).then(() => {})
    return () => {}
  }, [])

  useEffect(() => {
    if (window.localStorage.token) {
      dispatch(actions.getInformation())
        .then(() => {
          setIsOuterLogin(false) // Direct To Checkin
        })
        .catch((err) => {
          setIsOuterLogin(true) // Go to Recaptcha
        })
    }

    return () => {}
  }, [outerLogin])

  if (outerLogin) {
    return (
      <div>
        <OnlineBookingNavbar displaySignOut={false} />
        <ByPassAuthorizeChecking
          setIsOuterLogin={setIsOuterLogin}
          categoryTitle={t("reservation.onlineBooking")}
          title={t("reservation.onlineBooking")}
        />
      </div>
    )
  }
  return (
    <div>
      <OnlineBookingNavbar displaySignOut={false} />
      <div className=" relative bg-gray-200 ">
        <div className="px-4 py-10 lg:px-10 mx-auto min-h-screen my-12 lg:mx-24">
          <OnlineBookingProvider>
            <Switch>
              <Route path="/online-booking" exact component={() => <DateSearch />} />
              <Route path="/online-booking/room-select" exact component={() => <RoomSelection />} />
              <Route
                path="/online-booking/service-select"
                exact
                component={() => <ServiceSelect />}
              />
              <Route
                path="/online-booking/add-info"
                exact
                component={() => <OnlineBookingAddInfo />}
              />
              <Route path="/online-booking/payment" exact component={() => <BookingPayment />} />
              <Route
                path="/online-booking/payment/:id"
                exact
                component={() => <BookingPayment />}
              />
              <Route path="/online-booking/success" exact component={() => <BookingSuccess />} />
              <Redirect from="/online-booking" to="/online-booking" />
            </Switch>
          </OnlineBookingProvider>
        </div>
      </div>
      <FooterBooking />
    </div>
  )
}
