/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, ButtonGroup } from "@chakra-ui/react"
import { Link, useHistory } from "react-router-dom"
import _ from "lodash"
import dayjs from "dayjs"
import "dayjs/locale/th"
import { CSVLink } from "react-csv"
import {
  Input as AntdInput,
  Table as AntdTable,
  Spin,
  Button as AntdButton,
  DatePicker,
  Select,
  Switch,
} from "antd"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/BookingLayout"
import { SelectedRoomList, UnAssignRoomList } from "../../../components/List"
import Dot from "../../../components/common/Dot/dot"
import PaymentStatusExplainer from "../../../components/Panels/PaymentStatusExplainer"
import PaymentModal from "../../../components/Modal/ReservationManagement/ModalPayment"
import { Alert } from "../../../components/common/Alert"
import { selectTailwindStatusColor } from "../../../util/colors"
import { currencyFormatter } from "../../../util"
import { BookingStatus, paymentStatusArray, allPaymentStatusArray } from "../../../config/constant"

import { ActionButtonList } from "../../../components/Buttons/BookingActionButton"

import * as actions from "../../../redux/actions"
import { BookingSourceBadge, BookingTypeBadge, WhitePanel } from "../../../components"

export default function ReservationManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [checkedInDate, setCheckedInDate] = useState()
  const [checkoutDate, setCheckoutDate] = useState()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [isIncludeLog, setIsIncludeLog] = useState(false)
  const [orderByDir, setOrderByDir] = useState("desc")
  const [orderByField, setOrderByField] = useState("booking_no")
  const [selectedBookingInfo, setSelectedBookingInfo] = useState()
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [selectedStatus, setSelectedStatus] = useState()
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState()

  const bookings = useSelector((state) => state.bookings)
  const rooms = useSelector((state) => state.rooms)
  const me = useSelector((state) => state.me)

  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const getAllBooking = () => {
    const bookingQuery = {
      page,
      size,
      start: checkedInDate ? dayjs(checkedInDate).format("YYYY-MM-DD") : "",
      end: checkoutDate ? dayjs(checkoutDate).format("YYYY-MM-DD") : "",
      name,
      startDate: "",
      endDate: "",
      orderBy: orderByDir,
      orderByField,
      status: selectedStatus || "",
      paymentStatus: selectedPaymentStatus || "",
      // limitFirst: true,
    }
    if (isIncludeLog) {
      dispatch(actions.getAllBookingAndLog(bookingQuery))
        .then(() => {
          setIsLoading(true)
        })
        .catch((err) => {
          setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
          setIsAlertOpen(true)
        })
    } else {
      dispatch(actions.getAllBooking(bookingQuery))
        .then(() => {
          setIsLoading(true)
        })
        .catch((err) => {
          setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
          setIsAlertOpen(true)
        })
    }
  }

  useEffect(() => {
    dispatch(actions.getAllRooms())
    return () => {}
  }, [])

  useEffect(() => {
    setIsLoading(false)
    getAllBooking()
    return () => {}
  }, [
    page,
    name,
    size,
    checkedInDate,
    orderByDir,
    selectedStatus,
    selectedPaymentStatus,
    isIncludeLog,
  ])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const handleCreateImage = (payload) => {
    dispatch(actions.createOneImage(payload))
      .then(() => {
        getAllBooking()
      })
      .catch((err) => {
        setIsAlertOpen(true)
        setAlertMessage(err?.message)
      })
  }

  const handleEditBooking = (id, payload) => {
    setIsLoading(false)
    dispatch(actions.editOneBooking(id, { ...payload, user: me?._id }))
      .then(() => {
        getAllBooking()
        dispatch(actions.getCurrentBill())
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  const handleEditLog = (id, payload) => {
    setIsLoading(false)
    dispatch(actions.editOnelog(id, { ...payload, user: me?._id }))
      .then(() => {
        getAllBooking()
        dispatch(actions.getCurrentBill())
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  const handleDeleteBooking = (id) => {
    setIsLoading(false)
    dispatch(actions.deleteOneBooking(id, { user: me?._id }))
      .then(() => {
        getAllBooking()
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  const generateCSVData = () => {
    try {
      const tableHead = [
        [
          t("general.no"),
          t("reservation.bookingNo"),
          t("crm.customer"),
          t("crm.tel"),
          t("reservation.checkinDate"),
          t("reservation.checkoutDate"),
          t("roomtype.room"),
          t("reservation.otherService"),
          t("reservation.totalPrice"),
          t("reservation.bookingStatus"),
          t("reservation.paymentStatus"),
          t("reservation.remark"),
        ],
      ]
      const tableData = _.map(bookings?.arr, (eachBooking, index) => [
        index + 1,
        eachBooking?.booking_no || "",
        `${eachBooking?.customer?.firstname || ""} ${eachBooking?.customer?.lastname || ""}`,
        `${eachBooking?.customer?.tel || ""}`,
        dayjs(eachBooking?.start).locale(i18n.language).format("D MMM"),
        dayjs(eachBooking?.end).locale(i18n.language).format("D MMM"),
        _.map(
          eachBooking?.list,
          (bookingList) =>
            `${bookingList?.room?.tpye?.name} -  ${bookingList?.room?.name || "-"} ${
              bookingList?.room?.name || "-"
            } ราคา ${bookingList?.price || "0"} บาท \n `,
        ),
        _.map(
          eachBooking?.services,
          (eachService) => `${eachService?.name} ราคา  ${eachService?.price} บาท \n`,
        ),
        eachBooking?.price || "0",
        eachBooking?.status || "",
        eachBooking?.paymentStatus || "",
        eachBooking?.remark || "",
      ])
      const resultData = _.concat(tableHead, tableData)
      // console.log("Result Data", resultData)
      return resultData
    } catch (error) {
      console.error(error)
      return []
    }
  }

  const renderSetting = () => (
    <div>
      <div className="flex justify-between flex-wrap px-4">
        <div className="lg:w-1/6">
          <Link to="/reservation/create">
            <Button colorScheme="purple" variant="solid">
              {t("general.add")}
            </Button>
          </Link>
        </div>
        <div className="md:w-1/3 justify-end flex my-2">
          <ButtonGroup isAttached size="sm" variant="outline" className="self-center">
            <Button isActive>รายการ</Button>{" "}
            <Button
              onClick={() => {
                history.push("/reservation/room")
              }}
            >
              ห้อง
            </Button>
            <Button
              onClick={() => {
                history.push("/reservation/calendar")
              }}
            >
              ปฏิทิน
            </Button>
            <Button
              onClick={() => {
                history.push("/reservation/schedule")
              }}
            >
              ตาราง
            </Button>
          </ButtonGroup>{" "}
        </div>
      </div>

      <div className="px-4 mt-2 flex flex-wrap gap-1 items-center justify-between">
        <div className="w-full lg:w-1/2">
          <AntdInput
            placeholder="input search text"
            addonBefore={<i className="fas fa-search" />}
            allowClear
            onChange={(e) => setSearchWord(e.target.value)}
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  )

  const renderModal = () => (
    <div>
      <PaymentModal
        bookingInfo={selectedBookingInfo}
        isOpen={isPaymentModalOpen}
        currentPage={page}
        handleOnClose={() => {
          setIsPaymentModalOpen(false)
          setSelectedBookingInfo()
        }}
        handleEditBooking={handleEditBooking}
        rooms={rooms}
      />
    </div>
  )

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize

    setPage(newPage)
    setSize(pageSize)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderByDir("asc")
    } else {
      setOrderByDir("desc")
    }

    if (sorter?.field === "booking_no") {
      setOrderByField("booking_no")
    }

    if (sorter?.field === "start") {
      setOrderByField("start")
    }

    if (sorter?.field === "end") {
      setOrderByField("end")
    }

    if (sorter?.field === "status") {
      setOrderByField("status")
    }
  }

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  const renderFilter = () => (
    <div className="mt-2 px-4 transition duration-200">
      <WhitePanel>
        <div className="flex flex-wrap items-center">
          <div className="lg:w-1/6 px-2">
            <div className="text-sm font-semibold">วันเช็คอิน</div>
            <div className="my-1">
              <DatePicker
                picker="date"
                value={checkedInDate ? dayjs(checkedInDate) : null}
                format="D/MM/YYYY"
                style={{ width: "100%" }}
                size="middle"
                onChange={(date) => {
                  console.log("Date", date)
                  if (date) {
                    setCheckedInDate(dayjs(date).toDate())
                  } else {
                    setCheckedInDate()
                  }
                }}
              />
            </div>
          </div>
          <div className="lg:w-1/6 px-2">
            <div className="text-sm font-semibold">วันเช็คเอาท์</div>
            <div className="my-1">
              <DatePicker
                picker="date"
                value={checkoutDate ? dayjs(checkoutDate) : null}
                format="D/MM/YYYY"
                size="middle"
                style={{ width: "100%" }}
                onChange={(date) => {
                  console.log("Date", date)
                  if (date) {
                    setCheckoutDate(dayjs(date).toDate())
                  } else {
                    setCheckoutDate()
                  }
                }}
              />
            </div>
          </div>
          <div className="lg:w-1/6 px-2">
            <div className="text-sm font-semibold">สถานะการจอง</div>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setSelectedStatus(value)}
              options={_.map(BookingStatus, (eachStatus) => ({
                label: eachStatus,
                value: eachStatus,
              }))}
              allowClear
              placeholder="เลือกสถานะ"
            />
          </div>
          <div className="lg:w-1/6 px-2">
            <div className="text-sm font-semibold">สถานะการชำระเงิน</div>
            <Select
              style={{ width: "100%" }}
              onChange={(value) => setSelectedPaymentStatus(value)}
              options={_.map([...paymentStatusArray, ...allPaymentStatusArray], (eachStatus) => ({
                label: eachStatus,
                value: eachStatus,
              }))}
              allowClear
              placeholder="เลือกสถานะ"
            />
          </div>
          <div className="lg:w-2/6 px-2 flex justify-end gap-2">
            {isLoading && (
              <CSVLink
                data={generateCSVData()}
                filename={`${dayjs().format("BOOKING-YYYYMMDDHHmm")}.csv`}
              >
                <AntdButton icon={<i className="fas fa-file-csv" />}>CSV</AntdButton>
              </CSVLink>
            )}{" "}
            <Link to="/history">
              <AntdButton icon={<i className="fas fa-file" />}>รายงานสรุป</AntdButton>
            </Link>
            <div className="flex gap-1">
              <Switch onChange={(value) => setIsIncludeLog(value)} checked={isIncludeLog} />
              รวมที่สำเร็จแล้ว
            </div>
          </div>
        </div>
      </WhitePanel>
    </div>
  )

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "Booking No.",
      dataIndex: "booking_no",
      render: (text, record) => record?.booking_no,
      sorter: true,
    },
    {
      title: "ลูกค้า",
      dataIndex: "customer",
      render: (text, eachBooking, index) => (
        <div>
          <div className="whitespace-no-wrap font-semibold ">
            {eachBooking?.customer?.firstname} {eachBooking?.customer?.lastname}
          </div>
          <div className="text-xs mt-2">
            {t("crm.tel")} {eachBooking?.customer?.tel}{" "}
          </div>
          <BookingTypeBadge typeOfBooking={eachBooking.booking_type} key={index} />
          <BookingSourceBadge sourceOfBooking={eachBooking?.booking_source} />
          <div className="flex justify-start mt-1">
            <Link
              to={
                eachBooking?.status === BookingStatus.success
                  ? `/history/view/${eachBooking?.id}`
                  : `/reservation/view/${eachBooking?.id}`
              }
            >
              <AntdButton size="small">{t("general.detail")}</AntdButton>
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "ห้องพัก",
      dataIndex: "list",
      render: (text, eachBooking) => (
        <div className="w-48">
          <SelectedRoomList bookingList={eachBooking} />
          <UnAssignRoomList unAssignBookingList={eachBooking} />
        </div>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "bookings",
      sorter: true,
      render: (text, eachBooking) => (
        <div>
          <Dot word={eachBooking?.status} color={selectTailwindStatusColor(eachBooking.status)} />
          <p className="text-xs font-bold mt-3">{t("reservation.paymentStatus")}</p>
          <PaymentStatusExplainer status={eachBooking?.paymentStatus} bookingInfo={eachBooking} />
        </div>
      ),
    },
    {
      title: "วันที่เข้าพัก",
      dataIndex: "start",
      sorter: true,
      render: (text, record) => `${dayjs(record?.start).format("dd D/MMM")}`,
    },
    {
      title: "วันที่ออก",
      dataIndex: "end",
      sorter: true,

      render: (text, record) => `${dayjs(record?.end).format("dd D/MMM")}`,
    },
    {
      title: "ชำระเงิน",
      dataIndex: "payment",
      render: (text, eachBooking) => (
        <div className="flex flex-col gap-1 ">
          <div className="my-2">รวม {currencyFormatter.format(eachBooking?.price)} บาท</div>
          {eachBooking?.status !== BookingStatus.success && (
            <Link to={`/reservation/payment/${eachBooking?._id}`}>
              <Button size="sm" colorScheme="purple">
                {t("reservation.payment")}
              </Button>
            </Link>
          )}
          {/* eslint-disable-next-line no-underscore-dangle */}
          {eachBooking?.status !== BookingStatus.success && (
            <Link to={`/reservation/add-service/${eachBooking?._id}`}>
              <Button size="sm" width="20" colorScheme="pink">
                {t("reservation.addService")}
              </Button>
            </Link>
          )}
        </div>
      ),
    },
    {
      title: "ดำเนินการ",
      dataIndex: "action",
      render: (text, eachBooking) => (
        <ActionButtonList
          eachBooking={eachBooking}
          page={page}
          handleEditBooking={handleEditBooking}
          handleDeleteBooking={handleDeleteBooking}
          rooms={rooms}
          handleCreateImage={handleCreateImage}
          handleEditLog={handleEditLog}
        />
      ),
    },
    {
      title: "ผู้ดำเนินการ",
      dataIndex: "user",
      render: (text, eachBooking) => (
        <div>
          {eachBooking?.user?.employee?.firstname}{" "}
          {_.truncate(eachBooking?.user?.employee?.lastname, { length: 5 })}
          <div className="text-xs">
            {dayjs(eachBooking?.updatedAt)?.format("D MMM YYYY HH:mm")}{" "}
          </div>
        </div>
      ),
    },
  ]

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.reservationManagement")}>
      {renderAlert()}
      {renderModal()}
      <div className="min-h-screen">
        {renderSetting()}
        {renderFilter()}

        {isLoading ? (
          <div className="px-4">
            <AntdTable
              columns={columns}
              dataSource={bookings?.arr}
              onChange={onChange}
              pagination={{
                current: page,
                pageSize: size,
                total: bookings?.total,
              }}
              scroll={{
                x: "true",
              }}
            />
          </div>
        ) : (
          <div className="flex w-full justify-center">
            <Spin />
          </div>
        )}
      </div>
    </Layout>
  )
}
