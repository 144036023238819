import React from "react"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/BookingLayout"
import CreateRoomListReservation from "./CreateRoomListReservation"

import "react-day-picker/lib/style.css"
import "../../../assets/styles/daypickerselected.css"

export default function CreateReservation() {
  const history = useHistory()
  const { t } = useTranslation()
  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.addReservation")}>
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <CreateRoomListReservation />
      </div>
    </Layout>
  )
}
