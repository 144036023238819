import {
  TABLE_ALL,
  TABLE_CREATE,
  TABLE_DELETE,
  TABLE_GET,
  TABLE_UPDATE,
  TABLE_ERROR,
} from "../../actions/types"

const initialState = {
  table: null,
  isLoading: false,
}
export default function TableReducer(state = initialState, action) {
  switch (action.type) {
    case TABLE_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case TABLE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case TABLE_CREATE:
      return { isLoading: false }
    case TABLE_UPDATE:
      return { isLoading: false }
    case TABLE_DELETE:
      return { isLoading: false }
    case TABLE_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
