import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"

function BypassAuthorizeChecking({ setIsOuterLogin, categoryTitle, title }) {
  const { t } = useTranslation()
  const me = useSelector((state) => state.me)
  const captchaRef = useRef(null)
  const dispatch = useDispatch()
  const params = useParams()

  const onloadTurnstileCallback = () => {
    try {
      turnstile.render("#example-container", {
        sitekey: "0x4AAAAAAAEAxcvhsC8cf5Sr",
        callback(token) {
          console.log(`Challenge Success ${token}`)
          console.log(`params id ${params?.id}`)
          dispatch(
            actions.loginForCustomerWithCaptcha({
              token,
              booking: params?.id,
            }),
          )
        },
      })
    } catch (error) {
      alert(`ระบบไม่สามารถเช็คสิทธิผู้ใช้งานได้ ${error?.message}`)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      onloadTurnstileCallback()
    }, 500)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  useEffect(() => {
    if (me?.token && me?.success) {
      setIsOuterLogin(false)
    }

    return () => {}
  }, [me])

  return (
    <div className="my-20">
      <Layout categoryTitle={categoryTitle} title={title}>
        <div className=" relative bg-gray-200">
          <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
            <div>
              <div
                className="cf-turnstile"
                data-sitekey="0x4AAAAAAAEAxcvhsC8cf5Sr"
                // onLoad={onloadTurnstileCallback}
                ref={captchaRef}
                id="example-container"
              ></div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default BypassAuthorizeChecking
