import React from "react"
import { WhitePanel } from "../../common/Cards"
// eslint-disable-next-line arrow-body-style
const Slideshow = () => {
  return (
    <div>
      <WhitePanel>
        <div className=" bg-gray-100 p-2 rounded-md">
          <div className="flex justify-between py-12 px-3">
            <i className="fas fa-chevron-left text-3xl w-1/12 text-gray-300"></i>
            <i className="fas fa-images text-4xl w-10/12 text-center"></i>
            <i className="fas fa-chevron-right text-3xl w-1/12 text-right text-gray-300"></i>
          </div>
          <div className="flex justify-center">
            <i className="fas fa-ellipsis-h"></i>
          </div>
        </div>
      </WhitePanel>
    </div>
  )
}

export default Slideshow
