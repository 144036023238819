import style1 from "../../assets/header-style/style1.png"
import style2 from "../../assets/header-style/style2.png"
import style3 from "../../assets/header-style/style3.png"
import style4 from "../../assets/header-style/style4.png"
import style5 from "../../assets/header-style/style5.png"
import style6 from "../../assets/header-style/style6.png"
import style7 from "../../assets/header-style/style7.png"
import style8 from "../../assets/header-style/style8.png"
import style9 from "../../assets/header-style/style9.png"

export const HeadingType = {
  STYLE_1: {
    status_code: "STYLE_1",
    description: "รูปแบบที่ 1",
    image: style1,
  },
  STYLE_2: {
    status_code: "STYLE_2",
    description: "รูปแบบที่ 2",
    image: style2,
  },
  STYLE_3: {
    status_code: "STYLE_3",
    description: "รูปแบบที่ 3",
    image: style3,
  },
  STYLE_4: {
    status_code: "STYLE_4",
    description: "รูปแบบที่ 4",
    image: style4,
  },
  STYLE_5: {
    status_code: "STYLE_5",
    description: "รูปแบบที่ 5",
    image: style5,
  },
  STYLE_6: {
    status_code: "STYLE_6",
    description: "รูปแบบที่ 6",
    image: style6,
  },
  STYLE_7: {
    status_code: "STYLE_7",
    description: "รูปแบบที่ 7",
    image: style7,
  },
  STYLE_8: {
    status_code: "STYLE_8",
    description: "รูปแบบที่ 8",
    image: style8,
  },
  STYLE_9: {
    status_code: "STYLE_9",
    description: "รูปแบบที่ 9",
    image: style9,
  },
}

export const TextColor = {
  PRIMARY: { status_code: "PRIMARY", description: "สีหลัก" },
  SECONDARY: {
    status_code: "SECONDARY",
    description: "สีรอง",
  },
  OTHER: {
    status_code: "OTHER",
    description: "อื่นๆ",
  },
}

export default { HeadingType }
