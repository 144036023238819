export const SlideTextPosition = {
  JUSTIFY_LEFT: {
    status_code: "JUSTIFY_LEFT",
    description: "ชิดขอบซ้าย",
    descriptionEn: "justify left",
  },
  LEFT_ONE_THIRD: {
    status_code: "LEFT_ONE_THIRD",
    description: "1/3 จากกลางจอทางซ้าย",
    descriptionEn: "1/3 from the middle of the screen",
  },
  LEFT_ONE_FOUR: {
    status_code: "LEFT_ONE_FOUR",
    description: "1/4 จากกลางจอทางซ้าย",
    descriptionEn: "1/4 from the middle of the screen",
  },
  LEFT_ONE_FIVE: {
    status_code: "LEFT_ONE_FIVE",
    description: "1/5 จากกลางจอทางซ้าย",
    descriptionEn: "1/5 from the middle of the screen",
  },
  LEFT_ONE_SIX: {
    status_code: "LEFT_ONE_SIX",
    description: "1/6 จากกลางจอทางซ้าย",
    descriptionEn: "1/6 from the middle of the screen",
  },
  LEFT_ONE_SEVEN: {
    status_code: "LEFT_ONE_SEVEN",
    description: "1/7 จากกลางจอทางซ้าย",
    descriptionEn: "1/7 from the middle of the screen",
  },
  JUSTIFY_CENTER: {
    status_code: "JUSTIFY_CENTER",
    description: "ตรงกลางจอ",
    descriptionEn: "justify center",
  },
  RIGHT_ONE_THREE: {
    status_code: "RIGHT_ONE_THREE",
    description: "1/3 จากกลางจอทางขวา",
    descriptionEn: "1/3 from the middle of the screen",
  },
  RIGHT_ONE_FOUR: {
    status_code: "RIGHT_ONE_FOUR",
    description: "1/4 จากกลางจอทางขวา",
    descriptionEn: "1/4 from the middle of the screen",
  },
  RIGHT_ONE_FIVE: {
    status_code: "RIGHT_ONE_FIVE",
    description: "1/5 จากกลางจอทางขวา",
    descriptionEn: "1/5 from the middle of the screen",
  },
  RIGHT_ONE_SIX: {
    status_code: "RIGHT_ONE_SIX",
    description: "1/6 จากกลางจอทางขวา",
    descriptionEn: "1/6 from the middle of the screen",
  },
  RIGHT_ONE_SEVEN: {
    status_code: "RIGHT_ONE_SEVEN",
    description: "1/7 จากกลางจอทางขวา",
    descriptionEn: "1/7 from the middle of the screen",
  },
  JUSTIFY_RIGHT: {
    status_code: "JUSTIFY_RIGHT",
    description: "ชิดขอบขวา",
    descriptionEn: "justify right",
  },
}

export default SlideTextPosition
