/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react"
import { Button } from "@chakra-ui/react"
import { useSelector, useDispatch } from "react-redux"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import QRCode from "qrcode"
import { OrderNoVatRecipt, OrderNoVatSlip, ServiceCoupon } from "../../config/pdfmake/template"

// pdfMake.vfs = pdfFonts.pdfMake.vfs

export default function OrderPrintingPanel({
  bookingInfo,
  skipTitle = false,
  noVatRecipt = true,
  orderNoVatSlip = true,
  couponPerService = true,
}) {
  const [isLoading, setIsLoading] = useState(true)

  const information = useSelector((state) => state.information)
  const me = useSelector((state) => state.me)
  const system = useSelector((state) => state.system)
  const { t } = useTranslation()

  const noVatReciptDefinition = OrderNoVatRecipt(
    bookingInfo,
    information,
    system,
    `${me?.employeeData?.firstname}`,
  )
  const noVatSlip = OrderNoVatSlip(
    bookingInfo,
    information,
    system,
    `${me?.employeeData?.firstname}`,
  )

  const serviceCouponGetting = async (bookingData) => {
    let count = 1
    const serviceList = []
    for await (const eachService of bookingData?.service_list) {
      const arrayOfAmount = new Array(eachService?.amount)
      for await (const aa of arrayOfAmount) {
        try {
          const url = await QRCode.toDataURL(`${eachService?.order_no}-${count}`)
          serviceList.push({
            ...eachService,
            order_no: bookingData?.order_no,
            number: count,
            date: bookingData?.start,
            serviceOrder: bookingData,
            qrcode: url,
          })
        } catch (err) {
          console.error("Error", err)
          serviceList.push({
            ...eachService,
            order_no: bookingData?.order_no,
            number: count,
            qrcode: "",
            serviceOrder: bookingData,
            date: bookingData?.start,
          })
        }
        count += 1
      }
    }

    return serviceList
  }

  useEffect(() => {
    serviceCouponGetting(bookingInfo)

    return () => {}
  }, [bookingInfo])

  if (!isLoading) {
    return <div></div>
  }

  return (
    <div>
      {!skipTitle && (
        <h6 className="text-xs font-bold mt-2 font-sans ">{t("reservation.document")}</h6>
      )}
      <div className="flex flex-wrap gap-2 mt-2">
        {noVatRecipt && (
          <Button
            size="sm"
            onClick={() => {
              pdfMake.createPdf(noVatReciptDefinition).open()
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>{t("reservation.receipt")}(No VAT)</span>
          </Button>
        )}{" "}
        {orderNoVatSlip && (
          <Button
            size="sm"
            onClick={() => {
              pdfMake.createPdf(noVatSlip).open()
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>Slip</span>
          </Button>
        )}
        {couponPerService && (
          <Button
            size="sm"
            onClick={async () => {
              const serviceOrderListInCoupon = await serviceCouponGetting(bookingInfo)
              const coupon = ServiceCoupon(serviceOrderListInCoupon, information, system)
              pdfMake.createPdf(coupon).open()
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>คูปองในแต่ละรายการบริการ</span>
          </Button>
        )}
      </div>
    </div>
  )
}
