/* eslint-disable no-underscore-dangle */
import React, { forwardRef } from "react"
import { useFieldArray, Controller } from "react-hook-form"
import { Button, Textarea } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { Select as AntdSelect, Input } from "antd"
import _ from "lodash"
import { WhitePanel } from "../../common/Cards"

function AttachServiceForm({
  control,
  register,
  defaultValue,
  hotelServices,
  setValue,
  watch,
  booking,
}) {
  const { fields, append, remove } = useFieldArray({ control, name: "services" })
  const { t, i18n } = useTranslation()

  return (
    <div>
      <WhitePanel>
        <div className="divide-y space-y-2">
          {_.map(fields, (eachField, index) => (
            <div key={eachField?.id} className="py-2 ">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/3">
                  <label className="block  text-gray-700 text-sm font-semibold mb-2">
                    {t("roomtype.service")}
                  </label>

                  <Controller
                    control={control}
                    name={`services[${index}].service`}
                    defaultValue={defaultValue?.[index]?.service?._id || null}
                    render={(field) => (
                      <AntdSelect
                        {...field}
                        showSearch
                        size="middle"
                        style={{ width: "100%" }}
                        placeholder="เลือกบริการ"
                        optionFilterProp="children"
                        onChange={(value) => {
                          const foundValue = _.find(hotelServices?.arr, { _id: value })
                          // eslint-disable-next-line no-underscore-dangle
                          console.log("Found Value", foundValue)
                          const amount = watch(`services[${index}].amount`)
                          const payload = {
                            service: value,
                            price_per_unit: foundValue?.price,
                            amount: watch(`services[${index}].amount`),
                            price: parseFloat(amount) * parseFloat(foundValue?.price),
                          }
                          setValue(`services[${index}]`, payload)
                          console.log("Service Payload", payload)
                        }}
                        filterOption={(input, option) =>
                          ((option?.translation?.[i18n.language]?.name || option?.name) ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={_.map(hotelServices?.arr, (eachService) => ({
                          ...eachService,
                          label:
                            eachService.translation?.[i18n.language]?.name || eachService?.name,
                          value: eachService._id,
                        }))}
                      />
                    )}
                  />
                </div>

                <div className="lg:w-1/6 px-2 mt-1">
                  <label className="  text-gray-700 text-sm font-semibold ">
                    {t("reservation.price.pricePerUnit")}
                  </label>
                  <Controller
                    control={control}
                    name={`services[${index}].price_per_unit`}
                    defaultValue={defaultValue?.[index]?.price / defaultValue?.[index]?.amount || 1}
                    render={(field) => (
                      <Input
                        placeholder={t("reservation.price.pricePerUnit")}
                        {...field}
                        size="sm"
                        onChange={(event) => {
                          setValue(`services[${index}].price_per_unit`, event.target.value)
                          const amount = watch(`services[${index}].amount`)
                          const service = watch(`services[${index}].service`)
                          setValue(
                            `services[${index}].price`,
                            parseFloat(amount) * parseFloat(event.target.value),
                          )
                          setValue(`services[${index}].service`, service)
                        }}
                      />
                    )}
                  />
                </div>
                <div className="lg:w-1/6 px-2 mt-1">
                  <label className="  text-gray-700 text-sm font-semibold ">
                    {t("general.amount")}
                  </label>
                  <Controller
                    control={control}
                    name={`services[${index}].amount`}
                    defaultValue={defaultValue?.[index]?.amount || 1}
                    render={(field) => (
                      <Input
                        placeholder={t("general.amount")}
                        {...field}
                        size="sm"
                        onChange={(event) => {
                          const pricePerUnit = watch(`services[${index}].price_per_unit`)

                          setValue(`services[${index}]`, {
                            ...watch(`services[${index}]`),
                            amount: event.target.value,
                            price: parseFloat(pricePerUnit) * parseFloat(event.target.value),
                          })
                        }}
                      />
                    )}
                  />
                </div>
                <div className="lg:w-1/6 px-2 mt-1">
                  <label className=" text-gray-700 text-sm font-semibold ">
                    {t("general.price")}
                  </label>
                  <Controller
                    control={control}
                    name={`services[${index}].price`}
                    defaultValue={defaultValue?.[index]?.price || 1}
                    render={(field) => (
                      <Input
                        placeholder={t("reservation.totalPrice")}
                        {...field}
                        size="sm"
                        type="number"
                        defaultValue={defaultValue?.[index]?.price || 1}
                        readOnly
                      />
                    )}
                  />
                </div>
                <div className="lg:w-1/6 px-2 self-center">
                  <Button colorScheme="red" size="sm" onClick={() => remove(index)}>
                    ลบ
                  </Button>
                </div>
              </div>
              {booking && (
                <div className="my-2 lg:w-1/2">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    ลงไปที่ห้อง{" "}
                  </label>
                  <Controller
                    control={control}
                    name={`services[${index}].room`}
                    defaultValue={defaultValue?.[index]?.room?._id || null}
                    render={(field) => {
                      const addedBookingList = [
                        { value: "", label: "ส่วนกลางของการจอง" },
                        ..._.map(booking?.list, (eachList) => ({
                          value: eachList?.room?._id,
                          label: eachList?.room?.name,
                        })),
                      ]
                      return (
                        <AntdSelect
                          {...field}
                          size="middle"
                          placeholder="ส่วนกลางของการจอง"
                          style={{ width: "100%" }}
                          filterOption={(input, option) =>
                            ((option?.translation?.[i18n.language]?.name || option?.name) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={addedBookingList}
                        ></AntdSelect>
                      )
                    }}
                  />
                </div>
              )}
              <div className="my-2 lg:w-1/2">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  {t("reservation.remark")}
                </label>
                <Textarea
                  placeholder={t("reservation.remark")}
                  size="sm"
                  name={`services[${index}].remark`}
                  ref={register}
                  rows={3}
                  defaultValue={defaultValue?.[index]?.remark || ""}
                ></Textarea>
              </div>
            </div>
          ))}
          <div className="my-2">
            <Button colorScheme="purple" variant="outline" size="sm" onClick={() => append({})}>
              {t("general.add")}
            </Button>
          </div>{" "}
        </div>
      </WhitePanel>
    </div>
  )
}

export default forwardRef(AttachServiceForm)
