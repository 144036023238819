import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as UserInfo from 'config/UserInfo'
import { PassportAuth } from '../../contexts/AuthContext'

export default function Register() {
  const { register, handleSubmit, errors } = useForm()
  const { handleSignup } = useContext(PassportAuth)
  const history = useHistory()
  const onSubmit = async (data, e) => {
    if (data.password === data.confirm_password) {
      e.preventDefault()
      console.log(data)
      await handleSignup(data.employee_email, data.password, data)
      history.push('/')
    } else {
      alert('รหัสผ่านไม่ตรงกัน')
    }
  }
  return (
    <>
      <div className='container mx-auto px-4 h-full'>
        <div className='flex content-center items-center justify-center h-full'>
          <div className='w-full lg:w-6/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0'>
              <div className='rounded-t mb-0 px-6 py-6'>
                <div className='text-center mb-3'>
                  <h2 className='text-gray-600 text-sm font-bold'>
                    Sign up with Email
                  </h2>
                </div>
                <hr className='mt-6 border-b-1 border-gray-400' />
              </div>
              <div className='flex-auto px-4 lg:px-10 py-10 pt-0'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h6 className='text-gray-500 text-sm mt-3 mb-6 font-bold uppercase'>
                    User Information
                  </h6>
                  <div className='flex flex-wrap'>
                    <div className='w-full px-4'>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Email address
                        </label>
                        <input
                          name='employee_email'
                          type='email'
                          required
                          ref={register}
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                          placeholder='example@email.com'
                        />
                      </div>
                    </div>
                    <div className='w-full  px-4'>
                      <div className='relative w-full  mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Password
                        </label>
                        <input
                          name='password'
                          type='password'
                          required
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                          placeholder='Password'
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className='w-full  px-4'>
                      <div className='relative w-full  mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Confirm Password
                        </label>
                        <input
                          name='confirm_password'
                          type='password'
                          required
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                          placeholder='Confirm Password'
                          ref={register}
                        />
                      </div>
                    </div>
                    <div className='w-full lg:w-6/12 px-4'>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          First Name
                        </label>
                        <input
                          name='employee_first_name'
                          type='text'
                          required
                          ref={register}
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                          placeholder='First Name'
                        />
                      </div>
                    </div>
                    <div className='w-full lg:w-6/12 px-4'>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Last Name
                        </label>
                        <input
                          name='employee_last_name'
                          type='text'
                          required
                          ref={register}
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                          placeholder='Last Name'
                        />
                      </div>
                    </div>
                    <div className='w-full lg:w-6/12 px-4'>
                      <div className='relative w-full  mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Gender
                        </label>

                        <select
                          name='gender'
                          required
                          ref={register}
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                        >
                          <option value={UserInfo.GENDER.MALE}>
                            {UserInfo.GENDER.MALE}
                          </option>
                          <option value={UserInfo.GENDER.FEMALE}>
                            {UserInfo.GENDER.FEMALE}
                          </option>
                          <option value={UserInfo.GENDER.OTHERS}>
                            {UserInfo.GENDER.OTHERS}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className='w-full lg:w-6/12 px-4'>
                      <div className='relative w-full  mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          BirthDay
                        </label>

                        <input
                          name='date_of_birth'
                          type='date'
                          required
                          ref={register}
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                        />
                      </div>
                    </div>
                    <div className='w-full  px-4'>
                      <div className='relative w-full  mb-3'>
                        <label
                          className='block uppercase text-gray-700 text-xs font-bold mb-2'
                          htmlFor='grid-password'
                        >
                          Address
                        </label>
                        <textarea
                          name='employee_address'
                          required
                          ref={register}
                          rows='5'
                          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                          placeholder='Address '
                        />
                      </div>
                    </div>
                  </div>
                  <div className='text-center mt-6'>
                    <button
                      className='bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150'
                      type='submit'
                    >
                      Create Account
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className='flex flex-wrap mt-6 relative'>
              <div className='w-1/2'></div>
              <div className='w-1/2 text-right'>
                <Link to='/auth/login' className='text-gray-300'>
                  <small>มีบัญชีอยู่แล้ว</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
