export const MeetingRoomTimeSlot = {
  SLOT_MORNING: {
    status_code: "SLOT_MORNING",
    description: "ช่วงเช้า",
    descriptionEn: "Morning",
    icon: "fas fa-cloud-sun",
  },
  SLOT_AFTERNOON: {
    status_code: "SLOT_AFTERNOON",
    description: "ช่วงบ่าย",
    descriptionEn: "Afternoon",
    icon: "fas fa-cloud",
  },
  SLOT_EVENING: {
    status_code: "SLOT_EVENING",
    description: "ช่วงเย็น / ช่วงค่ำ",
    descriptionEn: "Evening",
    icon: "fas fa-cloud-moon",
  },
}

export default MeetingRoomTimeSlot
