import React from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react"
import SpinnerLoading from "./SpinnerLoading"

export default function DialogLoading({ isLoading, title = "Loading" }) {
  return (
    <AlertDialog isOpen={isLoading}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader className="font-sans" fontSize="lg">
          {title}
        </AlertDialogHeader>
        <AlertDialogBody>
          <SpinnerLoading />
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  )
}
