import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import _ from "lodash"
import moment from "moment"

import Spinners from "../../../components/Loading/SpinnerLoading"
import Layout from "../../../components/layout/GeneralLayout"
import Paginator from "../../../components/common/Paginator/NewPagination"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"
import { MeetingRoomTimeSlot } from "../../../config/constant"
import { overallMeetingRoomOrderStatusGenerator } from "../../../util"
import Dot from "../../../components/common/Dot/dot"
import {
  ServiceActionButtonList,
  PaymentButton,
  ServiceButton,
} from "../../../components/Buttons/ServiceOrderActionButton"
import { PaymentStatusExplainer } from "../../../components/Panels"

export default function ServiceOrderManagement() {
  const { t, i18n } = useTranslation()
  const [orderByDir, setOrderByDir] = useState("desc")
  const [orderByField, setOrderByField] = useState("booking_no")
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [page, setPage] = useState(1)
  const serviceOrder = useSelector((state) => state.serviceOrder)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getAllServiceOrder({
        page,
        size: 10,
        query: {
          completed: false,
          notMeetingRoomOrder: true,
          deleted: false,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrorMessage(err?.message)
        setIsError(true)
      })

    return () => {}
  }, [page])

  const handleDeleteOrder = (id) => {
    setIsLoading(false)
    dispatch(actions.editOneServiceOrder(id, { deleted: true }))
      .then(() => {
        dispatch(
          actions.getAllServiceOrder({
            page,
            size: 10,
            query: {
              completed: false,
              deleted: false,
              notMeetingRoomOrder: true,
            },
          }),
        )
          .then(() => {
            setIsLoading(true)
          })
          .catch((err) => {
            setErrorMessage(err?.message)
            setIsError(true)
          })
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsError(true)
      })
  }

  const handleEditOrder = (id, payload) => {
    dispatch(actions.editOneServiceOrder(id, payload))
      .then(() => {
        dispatch(
          actions.getAllServiceOrder({
            page,
            size: 10,
            query: {
              completed: false,
              deleted: false,
              notMeetingRoomOrder: true,
            },
          }),
        )
          .then(() => {
            setIsLoading(true)
          })
          .catch((err) => {
            setErrorMessage(err?.message)
            setIsError(true)
          })
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setIsError(true)
      })
  }

  const chevronDisplay = (field) => {
    if (field === orderByField) {
      if (orderByDir === "asc") {
        return (
          <div className="self-center  mx-3 ">
            <i className="fas fa-chevron-up text-sm" />
          </div>
        )
      }
      return (
        <div className="self-center  mx-3 ">
          <i className="fas fa-chevron-down text-sm" />
        </div>
      )
    }
    return (
      <div className="self-center  mx-3 ">
        <i className="fas fa-chevron-down text-gray-400 text-sm" />
      </div>
    )
  }

  const setSelectedField = (field) => {
    if (orderByField === field) {
      if (orderByDir === "asc") {
        setOrderByDir("desc")
      } else {
        setOrderByDir("asc")
      }
    } else {
      setOrderByField(field)
      setOrderByDir("desc")
    }
  }

  const renderTable = () => (
    <Table colorScheme="facebook" className="overflow-x-auto">
      <Thead backgroundColor="white">
        <Tr>
          <Th>{t("general.no")} </Th>
          <Th
            className="hover:bg-gray-100 cursor-pointer"
            onClick={() => setSelectedField("booking_no")}
          >
            <div className="flex">
              Order No.
              {chevronDisplay("booking_no")}
            </div>
          </Th>
          <Th>{t("crm.customer")} </Th>
          <Th>{t("roomtype.service")} </Th>
          <Th>{t("general.status")} </Th>
          <Th>{t("reservation.payment")} </Th>
          <Th>{t("general.action")} </Th>
          <Th>{t("general.handler")} </Th>
        </Tr>
      </Thead>
      <Tbody>
        {isLoading ? (
          _.map(serviceOrder?.arr, (eachOrder, index) => {
            const meetingRoomStatus = overallMeetingRoomOrderStatusGenerator(eachOrder)
            return (
              <Tr key={index}>
                <Td>{(page - 1) * 10 + index + 1}</Td>
                <Td>{eachOrder?.order_no} </Td>
                <Td>
                  {" "}
                  <div className="whitespace-no-wrap font-semibold font-sans">
                    {eachOrder?.customer?.firstname} {eachOrder?.customer?.lastname}
                  </div>
                  <div className="text-xs mt-2">
                    {t("crm.tel")} {eachOrder?.customer?.tel}{" "}
                  </div>
                  <div className="flex justify-start mt-1">
                    <Link to={`/reservation/service-orders/view/${eachOrder?.id}`}>
                      <div className="text-xs text-blue-700 hover:text-blue-800 cursor-pointer font-bold bg-blue-200 hover:bg-blue-100 rounded-md px-2 ">
                        {t("general.detail")}
                      </div>
                    </Link>
                  </div>
                </Td>

                <Td minWidth="48" className="font-sans">
                  {_.map(eachOrder?.service_list, (eachServiceList, serviceListIndex) => (
                    <li key={`service-${serviceListIndex}`} className="text-sm my-1">
                      {eachServiceList?.service?.name} x {eachServiceList?.amount}
                    </li>
                  ))}
                </Td>
                <Td className="whitespace-no-wrap">
                  <Dot
                    color={meetingRoomStatus?.color}
                    word={
                      i18n.language === "th"
                        ? meetingRoomStatus?.description
                        : meetingRoomStatus?.descriptionEn
                    }
                  />
                  <PaymentStatusExplainer
                    bookingInfo={eachOrder}
                    status={eachOrder?.paymentStatus}
                  />
                </Td>

                <Td>
                  <div className="flex flex-col gap-1">
                    <PaymentButton handleEditOrder={handleEditOrder} orderInfo={eachOrder} />
                    <ServiceButton orderInfo={eachOrder} />
                  </div>
                </Td>
                <Td>
                  <ServiceActionButtonList
                    eachOrder={eachOrder}
                    handleDeleteOrder={handleDeleteOrder}
                    handleEditOrder={handleEditOrder}
                  />
                </Td>
                <Td className="text-sm">
                  {eachOrder?.user?.employee?.firstname}{" "}
                  {_.truncate(eachOrder?.user?.employee?.lastname, { length: 5 })}
                  <div className="text-xs">
                    {moment(eachOrder?.updatedAt)?.format("D MMM YYYY HH:mm")}{" "}
                  </div>
                </Td>
              </Tr>
            )
          })
        ) : (
          <div className="w-full flex justify-center p-10">
            <Spinners size="lg" />
          </div>
        )}
      </Tbody>
    </Table>
  )
  return (
    <Layout
      categoryTitle={t("reservation.title")}
      title={t("reservation.serviceOrderManagement")}
      preview
    >
      <Alert
        errMessage={errorMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
        }}
      />
      <div className="flex justify-between px-4">
        <Link to="create">
          <Button colorScheme="purple">{t("general.add")}</Button>
        </Link>
        <Link to="/reservation/meeting-room-orders">
          <Button size="sm" colorScheme="blackAlpha">
            สลับไปออเดอร์ห้องประชุม
          </Button>
        </Link>
      </div>

      <div className="p-4 w-full overflow-x-auto">{renderTable()}</div>
      <Paginator lastPage={serviceOrder?.lastPage} setPage={setPage} />
    </Layout>
  )
}
