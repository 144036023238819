import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const CardProgress = ({ stepList, selectedIndex = 0 }) => {
  const { i18n } = useTranslation()
  return (
    <div className="w-full flex justify-center overflow-x-auto">
      <div className="pl-96 md:pl-0  my-4 border-b-2 pb-4 ">
        <div className="flex pb-3 w-full">
          {_.map(stepList, (eachStep, index) => (
            <div className="flex" key={index}>
              <div className="flex-1 w-16 mt-8">
                <div
                  className={`w-10 h-10 ${
                    selectedIndex >= eachStep?.index ? " bg-green-500" : "bg-white border-2"
                  } mx-auto rounded-full text-lg text-white flex items-center`}
                >
                  <span
                    className={`${
                      selectedIndex >= eachStep?.index ? "text-white" : "text-gray-500"
                    }  text-center w-full`}
                  >
                    <span className="text-center w-full">{eachStep?.index + 1}</span>
                  </span>
                </div>
                <div className="text-xs mt-4 text-center">
                  {i18n.language === "th" ? eachStep?.description : eachStep?.descriptionEn}
                </div>
              </div>

              <div
                className={`w-20 px-1 align-center items-center align-middle content-center flex ${
                  eachStep?.index === _.size(stepList) - 1 ? "hidden" : ""
                }`}
              >
                <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                  <div
                    className="bg-green-500 text-xs leading-none py-1 text-center text-grey-darkest rounded "
                    style={{
                      width:
                        // eslint-disable-next-line no-nested-ternary
                        selectedIndex >= index ? (selectedIndex === index ? "40%" : "100%") : "0%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CardProgress
