import _ from "lodash"
import {
  ROOM_ALL,
  ROOM_CREATE,
  ROOM_GET,
  ROOM_PUT,
  ROOM_ALL_FROM_ROOMTYPE,
  ROOM_DELETE,
  ROOM_ALL_VACANT,
} from "../../actions/types"
import { bookingAnalysis, arrayBookingAnalysis } from "../../../util"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case ROOM_ALL:
      return {
        ...action.payload,
        rows: undefined,
        arr: _.map(action.payload?.rows, (each) => ({
          ...each,
          bookings: _.isArray(each?.bookings)
            ? arrayBookingAnalysis(each?.bookings)
            : bookingAnalysis(each?.bookings),
        })),
        isLoading: true,
      }
    case ROOM_ALL_FROM_ROOMTYPE:
      return {
        arr: action.payload,
        isLoading: true,
      }
    case ROOM_ALL_VACANT:
      return {
        arr: action?.payload?.vacantRoom,
        ...action?.payload,
        isLoading: true,
      }
    case ROOM_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case ROOM_CREATE:
      return { isLoading: true }
    case ROOM_PUT:
      return { isLoading: true }
    case ROOM_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
