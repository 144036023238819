/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button, ButtonGroup } from "@chakra-ui/react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/BookingLayout"
import {
  SpinnerLoading,
  ModalBookingInfo,
  WhitePanel,
  Alert,
  RoomSchedule,
  Dot,
} from "../../../components"
import * as actions from "../../../redux/actions"
import { appConfig } from "../../../config"

export default function ReservationSchedule() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bookings = useSelector((state) => state.bookings)
  const rooms = useSelector((state) => state.rooms)
  const hotelServices = useSelector((state) => state.hotelService)
  const me = useSelector((state) => state.me)

  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").subtract(7, "day").toISOString(),
  )
  const [displayDay, setDisplayDay] = useState(dayjs().startOf("week").toDate())
  const [selectedBooking, setSelectedBooking] = useState()
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [showCalendarInfoPanel, setShowCalendarInfoPanel] = useState(true)
  const history = useHistory()

  const getAllBookingAndLogs = () => {
    dispatch(
      actions.getAllBookingAndLog({
        start: startDate,
        end: dayjs(startDate).add(2, "month").toISOString(),
        size: appConfig.bookingMaxFetchSize,
        page: 1,
        unwindBooking: true,
      }),
    )
      .then(() => {
        setIsLoading(true)
        setIsModalInfoOpen(false)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || t("general.unknownError"))
        setIsShowAlert(true)
        setIsModalInfoOpen(false)
      })
  }

  useEffect(() => {
    getAllBookingAndLogs()
    return () => {}
  }, [startDate])

  useEffect(() => {
    dispatch(actions.resetBooking())
    dispatch(actions.getAllRoomTypes())
    dispatch(actions.getAllRooms())
    dispatch(actions.getAllServices({ size: appConfig.maxFetchSize, page: 1 }))

    return () => {}
  }, [])

  const handleEditBooking = (id, payload) => {
    dispatch(actions.editOneBooking(id, { ...payload, user: me?._id }))
      .then(() => {
        getAllBookingAndLogs()
        dispatch(actions.getCurrentBill())
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || t("general.unknownError"))
        setIsShowAlert(true)
      })
  }

  const handleEditLog = (id, payload) => {
    dispatch(actions.editOnelog(id, { ...payload, user: me?._id }))
      .then(() => {
        getAllBookingAndLogs()
        dispatch(actions.getCurrentBill())
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || t("general.unknownError"))
        setIsShowAlert(true)
      })
  }

  const handleDeleteBooking = (id) => {
    dispatch(actions.deleteOneBooking(id, { user: me?._id }))
      .then(() => {
        getAllBookingAndLogs()
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsShowAlert(true)
      })
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.reservationSchedule")}>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setIsShowAlert(false)}
        isError={isShowAlert}
      />
      <div className="flex justify-between px-4">
        <Link to="/reservation/create">
          <Button colorScheme="purple" variant="solid">
            {t("general.add")}
          </Button>
        </Link>
        <ButtonGroup isAttached size="sm" variant="outline" className="self-center">
          <Button
            onClick={() => {
              history.push("/reservation/list")
            }}
          >
            รายการ
          </Button>{" "}
          <Button
            onClick={() => {
              history.push("/reservation/room")
            }}
          >
            ห้อง
          </Button>
          <Button
            onClick={() => {
              history.push("/reservation/calendar")
            }}
          >
            ปฏิทิน
          </Button>
          <Button isActive>ตาราง</Button>
        </ButtonGroup>
      </div>
      <ModalBookingInfo
        bookingInfo={selectedBooking}
        handleOnClose={() => {
          setSelectedBooking()
          setIsModalInfoOpen(false)
        }}
        isOpen={isModalInfoOpen}
        handleDeleteBooking={handleDeleteBooking}
        handleEditBooking={handleEditBooking}
        handleEditLog={handleEditLog}
        hotelServices={hotelServices}
        rooms={rooms}
      />
      <div className="p-4 w-full">
        <div className="my-2 flex justify-between ">
          <h2 className="text-2xl font-semibold">
            {dayjs().format("MMMM BBBB")} ( {dayjs().startOf("week").format("D MMM")} -{" "}
            {dayjs().endOf("week").format("D MMM")})
          </h2>

          <div className="flex">
            <ButtonGroup isAttached colorScheme="gray" size="sm">
              <Button
                leftIcon={<i className="fas fa-toggle-on" />}
                onClick={() => {
                  setShowCalendarInfoPanel(!showCalendarInfoPanel)
                }}
              >
                {t("reservation.toggleCalendarInfo")}
              </Button>
              <Button
                leftIcon={<i className="fas fa-chevron-left" />}
                onClick={() => {
                  const lastWeek = dayjs(startDate).subtract(1, "week")
                  const prevDisplayWeek = dayjs(displayDay).subtract(1, "week")
                  setStartDate(lastWeek.toISOString())
                  setDisplayDay(prevDisplayWeek)
                }}
              >
                {t("general.backward")}
              </Button>
              <Button
                rightIcon={<i className="fas fa-chevron-right" />}
                onClick={() => {
                  const nextWeek = dayjs(startDate).add(1, "week")
                  const nextDisplayWeek = dayjs(displayDay).add(1, "week")
                  setStartDate(nextWeek.toISOString())
                  setDisplayDay(nextDisplayWeek)
                }}
              >
                {t("general.forward")}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        {showCalendarInfoPanel && (
          <div>
            <WhitePanel>
              <div className="grid grid-cols-3">
                <Dot color="green" word="ว่าง" />
                <Dot color="yellow" word="จองไว้ ยังไม่เช็คอิน" />
                <Dot color="pink" word="เข้าพักอยู่" />
                <Dot color="orange" word="เตรียมจะเช็คเอาท์" />
                <Dot color="purple" word="เช็คเอาท์แล้ว" />
              </div>
              <div className="my-2">
                <div className="font-semibold text-sm">{t("reservation.calendarSymbol")} </div>
                <div className="text-sm">
                  {t("reservation.symbol")} ✅❎ {t("reservation.frontMeaning")}{" "}
                  <b>{t("reservation.depositPayment")}</b> {t("reservation.backMeaning")}{" "}
                  <b>{t("reservation.fullAmountPayment")}</b>{" "}
                </div>
              </div>
            </WhitePanel>
          </div>
        )}
        <div></div>
        <div className="overflow-x-auto">
          {isLoading ? (
            <RoomSchedule
              bookingArray={bookings?.arr}
              startDate={displayDay}
              roomArray={rooms?.arr}
              setSelectedBooking={(newBooking) => {
                const newBookingWithList = { ...newBooking, list: newBooking?.originalList }
                setSelectedBooking(newBookingWithList)
                setIsModalInfoOpen(true)
              }}
            />
          ) : (
            <div>
              <SpinnerLoading />
            </div>
          )}
        </div>
        <div className="text-xs">
          ดึงข้อมูลย้อนหลังตั้งแต่วันที่ {dayjs(startDate).format("D MMM YYYY HH:mm")} ถึง
          {dayjs(startDate).add(2, "month").format("D MMM YYYY HH:mm ")}
        </div>
      </div>
    </Layout>
  )
}
