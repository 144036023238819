import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@chakra-ui/react"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel, MenuCategoryForm, Alert, SpinnerLoading } from "../../components"

import * as actions from "../../redux/actions"

export default function EditMenuCategory() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const menuCategory = useSelector((state) => state.menuCategory)
  const { control, handleSubmit, watch, setValue } = useForm()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const params = useParams()

  useEffect(() => {
    dispatch(actions.getOneMenuCategory(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [params])

  const handleCreateMenuCategory = (data) => {
    dispatch(actions.editOneMenuCategory(params.id, data))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })
  }

  useEffect(() => {
    if (menuCategory && !menuCategory?.arr) {
      setValue("useSpecifyTime", menuCategory?.useSpecifyTime)
    }
    return () => {}
  }, [menuCategory])

  if (!isLoading) return <SpinnerLoading />

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="แก้ไขหมวดหมู่อาหาร">
      <Alert errMessage={errMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-between flex-wrap px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>

        <div className="w-full md:w-1/4  self-center mt-2"></div>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(handleCreateMenuCategory)}>
          <WhitePanel>
            <MenuCategoryForm control={control} defaultValue={menuCategory} watch={watch} />
          </WhitePanel>
          <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
