import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useParams, useHistory, Link } from "react-router-dom"
import { Button } from "@chakra-ui/react"

import {
  GeneralMainLayout as Layout,
  Alert,
  SpinnerLoading,
  WhitePanel,
  PaymentForm,
  PaymentTransactionTable,
} from "../../../components"
import * as actions from "../../../redux/actions"
import { currencyFormatter } from "../../../util"
import { TransactionType } from "../../../config/constant"

function BookingPayment() {
  const { t } = useTranslation()
  const booking = useSelector((state) => state.bookings)
  const transaction = useSelector((state) => state.transaction)
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)

  useEffect(() => {
    dispatch(actions.getOneBooking(params.id))
      .then(() => {
        dispatch(
          actions.getAllTransaction({
            page,
            size,
            query: {
              booking: params.id,
              transactionType: TransactionType.CREATE_PAYMENT.status_code,
            },
          }),
        )
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.message)
      })

    return () => {}
  }, [params])

  const handleCreatePayment = async (payload) => {
    const confirm = window.confirm("ยืนยันการชำระเงิน")
    if (confirm) {
      setIsLoading(false)
      console.log("Payload", payload)
      dispatch(actions.editOneBooking(booking?.id, payload))
        .then(() => {
          dispatch(
            actions.getAllTransaction({
              page,
              size,
              query: {
                booking: params.id,
                transactionType: TransactionType.CREATE_PAYMENT.status_code,
              },
            }),
          ).then(() => {
            dispatch(actions.getOneBooking(params.id)).then(() => {
              setIsLoading(true)
              history.goBack()
            })
          })
        })
        .catch((err) => {
          setIsError(true)
          setErrorMessage(err.message)
        })
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.paying")}>
      <Alert isError={isError} errMessage={errorMessage} handleOnClose={() => setIsError(false)} />
      <div className="px-4 flex justify-between">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div>
          <Link to={`/reservation/price-calculation/${params.id}`}>
            <Button colorScheme="teal">คำนวณราคา </Button>
          </Link>
        </div>
      </div>
      <div className="p-4">
        {isLoading ? (
          <div className="flex flex-wrap">
            <div className="w-full hidden md:block md:w-1/2">
              <WhitePanel>
                <PaymentTransactionTable
                  page={page}
                  setPage={setPage}
                  setSize={setSize}
                  size={size}
                  transaction={transaction}
                />
              </WhitePanel>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <div>
                <WhitePanel>
                  <div className="p-2">
                    <div>หมายเลข Booking</div>
                    <div className="text-2xl font-semibold">{booking?.booking_no}</div>
                    <hr className="my-2" />
                    <div>ราคา</div>
                    <div className="text-xl font-semibold">
                      {currencyFormatter.format(booking?.price)} บาท
                    </div>
                    <hr className="my-2" />
                    <div className="flex">
                      <div className="w-1/2">
                        <div>ชำระเงินแล้ว</div>
                        <div className="text-xl font-semibold">
                          {currencyFormatter.format(booking?.payment?.paid_amount)} บาท
                        </div>
                      </div>
                      <div className="w-1/2">
                        <div>ค้างชำระ</div>
                        <div
                          className={`text-xl font-semibold ${
                            booking?.price - booking?.payment?.paid_amount > 0 ? "text-red-500" : ""
                          }`}
                        >
                          {currencyFormatter.format(booking?.price - booking?.payment?.paid_amount)}{" "}
                          บาท
                        </div>
                      </div>
                    </div>{" "}
                    <div>สถานะการชำระเงิน</div>
                    <div className="text-xl font-semibold">{booking?.paymentStatus}</div>
                  </div>
                </WhitePanel>
              </div>
              <div className="my-2">
                <WhitePanel>
                  <PaymentForm handleCreatePayment={handleCreatePayment} booking={booking} />
                </WhitePanel>
              </div>
            </div>
          </div>
        ) : (
          <SpinnerLoading />
        )}
      </div>
    </Layout>
  )
}

export default BookingPayment
