import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"

// components
import { Button } from "@chakra-ui/react"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import Layout from "../../../components/layout/GeneralLayout"

export default function NotifyManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const systemInfo = useSelector((state) => state.system)
  const { handleSubmit, register } = useForm()

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  const onEditOuterService = (data) => {
    const confirm = window.confirm("ยืนยันการเปลี่ยนแปลงข้อมูลการตั้งค่า")
    if (confirm) {
      dispatch(actions.systemInfoPut(data)).then(() => {
        dispatch(actions.systemInfoGet())
        window.alert("แก้ไขข้อมูลเสร็จเรียบร้อย")
      })
    }
  }

  return isLoading ? (
    <Layout categoryTitle="จัดการระบบ" title="จัดการการแจ้งเตือน">
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="w-full p-4 ">
        <form onSubmit={handleSubmit(onEditOuterService)}>
          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
            <div className="w-full px-4 ">
              <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
                รับการแจ้งเตือนผ่านทางอีเมล
                <p className="text-xs font-normal">
                  กรอกอีเมลที่ต้องการรับการแจ้งเตือนการจองห้องพัก เพิ่มได้สูงสุด 2 อีเมล
                </p>
              </h6>
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    อีเมลที่ 1
                  </label>
                  <input
                    name="notify.email[0]"
                    type="email"
                    ref={register}
                    defaultValue={systemInfo?.notify?.email?.[0]}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="owner@email.com"
                  />
                </div>

                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    อีเมลที่ 2
                  </label>
                  <input
                    name="notify.email[1]"
                    type="email"
                    ref={register}
                    defaultValue={systemInfo?.notify?.email?.[1]}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="office@email.com"
                  />
                </div>
              </div>

              <br />
            </div>
          </div>
          <br />

          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
            <div className="w-full px-4 ">
              <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
                รับการแจ้งเตือนผ่าน SMS
                <p className="text-xs font-normal">
                  กรอกหมายเลขโทรศัพท์ที่ต้องการรับการแจ้งเตือนการจองห้องพัก เพิ่มได้สูงสุด 4 หมายเลข
                </p>
              </h6>
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    หมายเลขโทรศัพท์ หมายเลขที่ 1
                  </label>
                  <input
                    name="notify.sms[0]"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.notify?.sms?.[0]}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="0936749112"
                  />
                </div>
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    หมายเลขโทรศัพท์ หมายเลขที่ 2
                  </label>
                  <input
                    name="notify.sms[1]"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.notify?.sms?.[1]}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="0936749112"
                  />
                </div>
              </div>{" "}
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  {" "}
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    หมายเลขโทรศัพท์ หมายเลขที่ 3
                  </label>
                  <input
                    name="notify.sms[2]"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.notify?.sms?.[2]}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="093679112"
                  />
                </div>

                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    หมายเลขโทรศัพท์ หมายเลขที่ 4
                  </label>
                  <input
                    name="notify.sms[3]"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.notify?.sms?.[3]}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="0953674912"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
            <div className="w-full px-4 ">
              <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
                รับการแจ้งเตือนผ่าน Line
                <p className="text-xs font-normal">
                  กรอก Notify Token สำหรับการส่งเข้ากลุ่ม Line หรือส่งเข้า Line ส่วนตัว
                </p>
              </h6>
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Notify Token
                  </label>
                  <input
                    name="notify.line"
                    type="password"
                    ref={register}
                    defaultValue={systemInfo?.notify?.line}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="******"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทีก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  ) : (
    <SpinnerLoading />
  )
}
