import React from "react"
import { Form, Input } from "antd"
import { Controller } from "react-hook-form"
import Flags from "country-flag-icons/react/3x2"

const { TextArea } = Input

const PageComposites = ({
  defaultInfo,
  title,
  compositePrefix,
  control,
  brief = true,
  description = false,
}) => (
  <div>
    <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg my-3">
      <div className="w-full px-4 ">
        <h6 className="text-gray-900 text-base my-2 font-bold uppercase font-sans ">{title}</h6>
        <div className="relative w-full mb-3 ">
          <Form.Item label="หัวข้อหลัก ภาษาไทย" required>
            <Controller
              name={`composite.translation.th.${compositePrefix}_header`}
              control={control}
              defaultValue={
                defaultInfo?.composite?.translation?.th?.[`${compositePrefix}_header`] || ""
              }
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.TH title="Thai" className="w-5" />}
                  placeholder="หัวข้อหลัก"
                />
              )}
            />
          </Form.Item>
          <Form.Item label="หัวข้อหลัก ภาษาอังกฤษ">
            <Controller
              name={`composite.translation.en.${compositePrefix}_header`}
              control={control}
              defaultValue={
                defaultInfo?.composite?.translation?.en?.[`${compositePrefix}_header`] || ""
              }
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.GB title="English" className="w-5" />}
                  placeholder="หัวข้อหลัก"
                />
              )}
            />
          </Form.Item>
        </div>
        {brief && (
          <div className="relative w-full mb-3">
            <Form.Item label="หัวข้อรอง ภาษาไทย">
              <Controller
                name={`composite.translation.th.${compositePrefix}_brief`}
                control={control}
                defaultValue={
                  defaultInfo?.composite?.translation?.th?.[`${compositePrefix}_brief`] || ""
                }
                render={(field) => (
                  <TextArea
                    {...field}
                    style={{ width: "100%" }}
                    addonBefore={<Flags.TH title="Thai" className="w-5" />}
                    placeholder="หัวข้อรอง"
                  />
                )}
              />
            </Form.Item>
            <Form.Item label="หัวข้อรอง ภาษาอังกฤษ">
              <Controller
                name={`composite.translation.en.${compositePrefix}_brief`}
                control={control}
                defaultValue={
                  defaultInfo?.composite?.translation?.en?.[`${compositePrefix}_brief`] || ""
                }
                render={(field) => (
                  <TextArea
                    {...field}
                    style={{ width: "100%" }}
                    addonBefore={<Flags.GB title="English" className="w-5" />}
                    placeholder="หัวข้อรอง"
                  />
                )}
              />
            </Form.Item>
          </div>
        )}
        {description && (
          <div className="relative w-full mb-3">
            <Form.Item label="รายละเอียด ภาษาไทย">
              <Controller
                name={`composite.translation.th.${compositePrefix}_description`}
                control={control}
                defaultValue={
                  defaultInfo?.composite?.translation?.th?.[`${compositePrefix}_description`] || ""
                }
                render={(field) => (
                  <TextArea
                    {...field}
                    style={{ width: "100%" }}
                    addonBefore={<Flags.TH title="Thai" className="w-5" />}
                    placeholder="รายละเอียด"
                  />
                )}
              />
            </Form.Item>
            <Form.Item label="รายละเอียด ภาษาอังกฤษ">
              <Controller
                name={`composite.translation.en.${compositePrefix}_description`}
                control={control}
                defaultValue={
                  defaultInfo?.composite?.translation?.en?.[`${compositePrefix}_description`] || ""
                }
                render={(field) => (
                  <TextArea
                    {...field}
                    style={{ width: "100%" }}
                    addonBefore={<Flags.GB title="English" className="w-5" />}
                    placeholder="รายละเอียด"
                  />
                )}
              />
            </Form.Item>
          </div>
        )}
      </div>
    </div>
  </div>
)

export default React.forwardRef(PageComposites)
