import api from "../../../config/api"

import { SERVICE_ALL, SERVICE_CREATE, SERVICE_GET, SERVICE_UPDATE, SERVICE_DELETE } from "../types"

// faker.locale = "th"

export const getAllServices =
  ({ page = 1, size, name = "", onlineAvailable = "" } = {}) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/hotel-service?name=${name}&page=${page}&size=${size}&onlineAvailable=${onlineAvailable}`,
      )
      .then((res) => {
        if (res.data) {
          dispatch({ type: SERVICE_ALL, payload: res.data })
        } else {
          dispatch({ type: SERVICE_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All services", err)
        dispatch({ type: SERVICE_ALL, payload: null })
      })
  }

export const getOneService = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/hotel-service/${id}`).then((res) => {
    console.log("Request Server to Get One service")
    if (res.data) {
      dispatch({ type: SERVICE_GET, payload: res.data })
    } else {
      dispatch({ type: SERVICE_GET, payload: null })
    }
  })
}
export const createOneService = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/hotel-service/`, payload).then(() => {
    console.log("Request Server to Create New service")
    dispatch({ type: SERVICE_CREATE })
  })
}

export const editOneService = (id, payload) => async (dispatch) => {
  await api.put(`${process.env.REACT_APP_API_URL}/hotel-service/${id}`, payload).then(() => {
    console.log("Request Server to edit service")
    dispatch({ type: SERVICE_UPDATE })
  })
}

export const deleteOneservice = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/hotel-service/${id}`).then(() => {
    console.log("Request Server to Delete One service")
    dispatch({ type: SERVICE_DELETE, payload: null })
  })
}
