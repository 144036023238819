import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"

import Layout from "../../../components/layout/GeneralLayout"
import * as actions from "../../../redux/actions"
import { EmployeeForm } from "../../../components/Forms/Management"
import { Alert } from "../../../components/common/Alert"
import { SpinnerLoading, DialogLoading } from "../../../components/Loading"

export default function EditEmployee() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [stateError, setStateErrors] = useState()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState([])
  const params = useParams()
  const employee = useSelector((state) => state.employee)
  const { register, handleSubmit, reset, errors, watch, control } = useForm({
    defaultValues: employee,
  })

  useEffect(() => {
    dispatch(actions.getOneEmployee(params.id)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params])

  const onSubmit = async (data, e) => {
    console.log("data", data)
    e.preventDefault()
    if (data.editImage === true) {
      // eslint-disable-next-line no-param-reassign
      data.image = { image: image?.[0]?.data_url }
    }

    try {
      setIsLoading(false)
      await dispatch(actions.editOneEmployee(params.id, data))
      dispatch(actions.getAllEmployee({ page: 1, size: 10 }))
      history.goBack()
      reset()
    } catch (error) {
      setStateErrors(`แก้ไขพนักงานไม่สำเร็จ ${error.toString()}`)
    } finally {
      setIsLoading(true)
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการระบบ" title="แก้ไขพนักงาน">
      <Alert errMessage={stateError} handleOnClose={() => setIsError(false)} isError={isError} />
      <div className="flex justify-between p-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <EmployeeForm
            errors={errors}
            register={register}
            watch={watch}
            image={image}
            setImage={setImage}
            employee={employee}
            control={control}
          />
          <div className="text-center px-4 gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              แก้ไขพนักงาน
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
