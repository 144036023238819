import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@chakra-ui/react"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel, FBMenuForm, Alert, SpinnerLoading } from "../../components"
import { appConfig } from "../../config"

import * as actions from "../../redux/actions"

export default function CreateMenu() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { control, handleSubmit, watch } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const menuCategory = useSelector((state) => state.menuCategory)

  const handleCreateMenuCategory = (data) => {
    console.log("Data", data)
    dispatch(actions.createOneMenu(data))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })
  }

  useEffect(() => {
    dispatch(actions.getAllMenuCategory({ page: 1, size: appConfig.maxFetchSize }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        console.error(err)
      })

    return () => {}
  }, [])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="สร้างเมนูอาหาร">
      <Alert errMessage={errMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-between flex-wrap px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>

        <div className="w-full md:w-1/4  self-center mt-2"></div>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(handleCreateMenuCategory)}>
          <WhitePanel>
            <FBMenuForm control={control} menuCategory={menuCategory} watch={watch} />
          </WhitePanel>
          <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
