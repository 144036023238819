/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, FormControl, Switch, FormLabel, Badge } from "@chakra-ui/react"
import { Table, Input } from "antd"
import { Link } from "react-router-dom"
import _ from "lodash"
import dayjs from "dayjs"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import { Alert, Confirm } from "../../components/common/Alert"

import * as actions from "../../redux/actions"

export default function FBOrderManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [searchDate, setSearchDate] = useState()
  const [page, setPage] = useState(1)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [size, setSize] = useState(10)
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [isSuccessConfirmOpen, setIsSuccessConfirmOpen] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState("")
  const [isIncludeComplete, setIsIncludeComplete] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const fbOrder = useSelector((state) => state.fbOrder)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getAllFBOrder = () => {
    dispatch(
      actions.getAllFBOrder({
        page,
        size,
        query: {
          completed: isIncludeComplete ? "" : "false",
          name,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }
  const handleDelete = async () => {
    try {
      await dispatch(actions.deleteOneFBOrder(selectedOrder))
      getAllFBOrder()
      setSelectedOrder("")
    } catch (error) {
      setAlertMessage(error?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      setIsAlertOpen(true)
    } finally {
      setIsDeleteConfirmOpen(false)
    }
  }

  const handleSuccess = async () => {
    try {
      await dispatch(actions.editOneFBOrder(selectedOrder, { completed: true }))
      getAllFBOrder()
      setSelectedOrder("")
    } catch (error) {
      setAlertMessage(error?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      setIsAlertOpen(true)
    } finally {
      setIsSuccessConfirmOpen(false)
    }
  }

  useEffect(() => {
    setIsLoading(false)
    getAllFBOrder()
    return () => {}
  }, [page, name, searchDate, isIncludeComplete, size])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const renderSetting = () => (
    <div className="flex justify-between flex-wrap px-4">
      <Link to="/food-beverage/order/create">
        <Button colorScheme="purple" variant="solid">
          เปิดบิล
        </Button>
      </Link>
      <div className="w-full md:w-1/4  self-center mt-2">
        <FormControl display="flex" gridColumnGap={2}>
          <Switch
            isChecked={isIncludeComplete}
            colorScheme="purple"
            onChange={() => setIsIncludeComplete(!isIncludeComplete)}
          />
          <FormLabel
            cursor="pointer"
            fontSize="sm"
            onClick={() => setIsIncludeComplete(!isIncludeComplete)}
          >
            รวมรายการที่สำเร็จ
          </FormLabel>
        </FormControl>
      </div>
    </div>
  )

  const renderDeleteConfirm = () => (
    <Confirm
      title="ยืนยันการลบคำสั่งอาหาร"
      isOpen={isDeleteConfirmOpen}
      body="ยืนยันการลบตำสั่งอาหารและเครื่องดื่มรายการนี้หรือไม่"
      handleOnClose={() => setIsDeleteConfirmOpen(false)}
      handleSuccess={handleDelete}
    />
  )

  const renderSuccessConfirm = () => (
    <Confirm
      title="ยืนยันการเปลี่ยนสถานะ"
      isOpen={isSuccessConfirmOpen}
      body="ยืนยันการเปลี่ยนสถานะการสั่งอาหารนี้เป็น สำเร็จ"
      handleOnClose={() => setIsSuccessConfirmOpen(false)}
      handleSuccess={handleSuccess}
    />
  )

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  const columns = [
    {
      title: "ลำดับที่",
      dataIndex: "list",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "เลขที่บิล",
      dataIndex: "bill_number",
      render: (text, record) => (
        <div>
          <Link
            to={`/food-beverage/order/detail/${record?._id}`}
            className="font-semibold hover:text-gray-500 cursor-pointer"
          >
            {record?.bill_number}
          </Link>
          {record?.completed && <Badge colorScheme="green">สำเร็จ</Badge>}
        </div>
      ),
    },
    {
      title: "โต๊ะ",
      dataIndex: "table",
      render: (text, record) => <div>{record?.table?.name || "-"}</div>,
    },

    {
      title: "รายการจองห้อง",
      dataIndex: "booking",
      render: (text, record) => <div>{record?.booking?.booking_no || "-"}</div>,
    },
    {
      title: "วันที่",
      dataIndex: "table",
      render: (text, record) => dayjs(record?.date).format("D MMM YYYY HH:mm"),
    },
    {
      title: "ราคา",
      dataIndex: "price",
    },
    {
      title: "ดำเนินการ",
      dataIndex: "table",
      render: (text, record) => (
        <div className="flex gap-2">
          <Link to={`/food-beverage/order/detail/${record?._id}`}>
            <Button colorScheme="blue" size="sm">
              รายการ
            </Button>
          </Link>{" "}
          <Button
            colorScheme="green"
            disabled={record?.completed}
            size="sm"
            onClick={() => {
              setSelectedOrder(record?._id)
              setIsSuccessConfirmOpen(true)
            }}
          >
            สำเร็จ
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              setSelectedOrder(record?._id)
              setIsDeleteConfirmOpen(true)
            }}
          >
            ลบ
          </Button>
        </div>
      ),
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize

    setPage(newPage)
    setSize(pageSize)
  }

  const renderSearch = () => (
    <div className="px-4 mt-2 flex flex-wrap gap-1 items-center justify-between">
      <div className="w-full lg:w-1/2">
        <Input
          placeholder="input search text"
          addonBefore={<i className="fas fa-search" />}
          allowClear
          onChange={(e) => setSearchWord(e.target.value)}
          style={{
            width: "100%",
          }}
        />
      </div>
    </div>
  )

  return (
    <Layout
      categoryTitle={t("foodAndBeverage.title")}
      title={t("foodAndBeverage.fbOrderManagement")}
      preview
    >
      {renderAlert()}
      {renderDeleteConfirm()}
      {renderSuccessConfirm()}
      {renderSetting()}
      {renderSearch()}

      <div className="p-4 w-full overflow-x-auto">
        <Table
          columns={columns}
          dataSource={fbOrder?.arr}
          onChange={onChange}
          scroll={{
            x: "true",
          }}
        />
      </div>
    </Layout>
  )
}
