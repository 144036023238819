import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"

import { LookAndFeelForm } from "../../components/Forms/HotelSite"
import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"

export default function HotelSiteMainInfo() {
  const dispatch = useDispatch()
  const toast = useToast()
  const history = useHistory()
  const { register, handleSubmit, control } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const info = useSelector((state) => state.information)
  const [color, setColor] = useState(info.color)
  const [secondColor, setSecondColor] = useState(info?.secondColor)
  const [bgColor, setBgColor] = useState(info?.bgColor)
  // const [images, setImages] = useState([])

  const onSubmit = (data) => {
    dispatch(actions.editOneInformation(info?.id, { ...data, color, secondColor })).then(() => {
      dispatch(actions.getInformation()).then(() => {
        toast({
          title: "แก้ไขข้อมูลสำเร็จ",
          description:
            "แก้ไขข้อมูลเกี่ยวกับโรงแรมและเว็บไซต์โรงแรมสำเร็จ กรุณารอประมาณ 2-3 นาทีจะมีผลในเว็บไซต์",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        dispatch(actions.buildNewWebsite())
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {})
    return () => {}
  }, [])

  useEffect(() => {
    if (info?.color) {
      setColor(info?.color)
      setIsLoading(true)
    }

    return () => {}
  }, [info])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ตกแต่งหน้าตา">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full ">
              <div className=" gap-0 py-3 ">
                <LookAndFeelForm
                  defaultInfo={info}
                  register={register}
                  color={color}
                  setColor={setColor}
                  secondColor={secondColor}
                  setSecondColor={setSecondColor}
                  bgColor={bgColor}
                  setBgColor={setBgColor}
                  control={control}
                />
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
