import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import Alert from "../components/common/Alert/AlertDialog.jsx"
import IndexNavbar from "../components/common/Navbars/IndexNavbar.js"
import Footer from "../components/common/Footers/Footer.js"
import SpinnerComponent from "../components/Loading/SpinnerLoading"
import * as actions from "../redux/actions"
import { MenuPanel, Homepage } from "../components"
import { HomepageType } from "../config/constant"

export default function Home() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()

  const me = useSelector((state) => state.me)
  const system = useSelector((state) => state.system)
  useEffect(() => {
    try {
      const storageRemember = JSON.parse(localStorage.getItem("eaccom_remember"))
      console.log("Storage Remember", storageRemember)
      if (!storageRemember.id) {
        localStorage.removeItem("eaccom_remember")
        setIsError(true)
        setErrMessage("การให่สิทธิการเข้าใช้มึปัญหา กรูณาล็อกอินใหม่")
      }
      dispatch(actions.systemInfoGet()).then(() => {
        dispatch(actions.meGet(storageRemember?.id))
          .then(() => {
            setIsLoading(true)
          })
          .catch((err) => {
            setIsError(true)
            setErrMessage(err)
          })
      })
    } catch (error) {
      setIsError(true)
      setErrMessage("การให่สิทธิการเข้าใช้มึปัญหา")
      console.error(error)
      localStorage.removeItem("eaccom_remember")
    }

    return () => {}
  }, [])

  const renderFrontPage = (frontpageType) => {
    const homepageProp = {
      me,
      system,
    }
    switch (frontpageType) {
      case HomepageType.TYPE_1.status_code:
        return <Homepage.Type1Homepage {...homepageProp} />
      case HomepageType.TYPE_2.status_code:
        return <Homepage.Type2Homepage {...homepageProp} />
      case HomepageType.TYPE_3.status_code:
        return <Homepage.Type3Homepage {...homepageProp} />
      case HomepageType.DIPROM_1.status_code:
        return <Homepage.Diprom1Homepage {...homepageProp} />
      case HomepageType.DIPROM_2.status_code:
        return <Homepage.Diprom2Homepage {...homepageProp} />
      case HomepageType.DIPROM_3.status_code:
        return <Homepage.Diprom3Homepage {...homepageProp} />
      default:
        return <Homepage.Type1Homepage {...homepageProp} />
    }
  }

  if (isError) {
    return (
      <div>
        <Alert
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
          isError={isError}
        />
      </div>
    )
  }

  if (isLoading)
    return (
      <>
        <IndexNavbar fixed />
        <Helmet>
          <title>{system?.name || "E-Accom"} </title>
          {system?.logo && (
            <link rel="icon" sizes="32x32" type="image/png" href={system?.logo?.url} />
          )}
          {system?.logo && (
            <link rel="icon" sizes="16x16" type="image/png" href={system?.logo?.url} />
          )}
          {system?.logo && <link rel="shortcut icon" type="image/png" href={system?.logo?.url} />}
        </Helmet>
        {renderFrontPage(system?.homepage)}
        <Footer />
      </>
    )

  return <SpinnerComponent />
}
