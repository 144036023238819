import React, { useState } from "react"
import { message, Upload, Button } from "antd"

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }

  return isJpgOrPng
}

export default function SingleImageUpload({ image, setImage }) {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true)
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false)
        setImageUrl(url)
        setImage(url)
      })
    }
  }
  const uploadButton = (
    <div className="">
      <Button icon={loading ? <i className="fas fa-spinner" /> : <i className="fas fa-plus" />}>
        Upload
      </Button>
    </div>
  )
  return (
    <div>
      <Upload
        name="avatar"
        listType="picture"
        className="avatar-uploader"
        style={{
          height: "240px",
        }}
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            style={{
              width: "100%",
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  )
}
