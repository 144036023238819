import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import _ from "lodash"

// components
import { Button } from "@chakra-ui/react"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import Layout from "../../components/layout/GeneralLayout"
import { ModuleSelectForm } from "../../components/Forms/Management"
import { WhitePanel } from "../../components"

export default function ModuleManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const systemInfo = useSelector((state) => state.system)
  const { handleSubmit, control, setValue } = useForm()

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {})

    return () => {}
  }, [])

  useEffect(() => {
    if (!_.isEmpty(systemInfo?.available_modules)) {
      setValue("available_modules", systemInfo?.available_modules)
    }
    setIsLoading(true)
  }, [systemInfo])

  const handleEdit = (data) => {
    console.log("Data", { available_modules: data.available_modules })
    dispatch(actions.systemInfoPut({ available_modules: data.available_modules })).then(() => {
      dispatch(actions.systemInfoGet())
      window.alert("แก้ไขข้อมูลเสร็จเรียบร้อย")
    })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการระบบ" title="ตั้งค่าโมดูล">
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="w-full p-4 ">
        <form onSubmit={handleSubmit(handleEdit)}>
          <WhitePanel>
            <ModuleSelectForm control={control} defaultValue={systemInfo} />
          </WhitePanel>
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทีก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
