/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { Table, Button as AntdButton } from "antd"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Alert from "../../components/common/Alert/AlertDialog"
import * as actions from "../../redux/actions"

export default function Promotion() {
  const promotions = useSelector((state) => state.promotions)
  const { i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getAllPromotions(page))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
    return () => {}
  }, [page])

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  const handleDeletePromotion = (promotionId) => {
    const confirm = window.confirm("ยืนยันการลบโปรโมชั่นนี้")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOnePromotion(promotionId))
        .then(() => {
          dispatch(actions.getAllPromotions()).then(() => {
            setIsLoading(true)
          })
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize
    setSize(pageSize)
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "ชื่อ",
      dataIndex: "name",
      render: (text, record) => (
        <div>
          {record?.translation?.[i18n.language]?.name}
          <div className="flex gap-2">
            <Link to={`/site/promotion/view/${record?.id}`} className=" hover:text-gray-600">
              <AntdButton size="small">รายละเอียด</AntdButton>
            </Link>
            {record?.hilight ? (
              <div>
                <AntdButton
                  size="small"
                  onClick={() => {
                    dispatch(actions.editOnePromotion(record?.id, { hilight: false })).then(() => {
                      dispatch(actions.getAllPromotions(page)).then(() => {
                        setIsLoading(true)
                      })
                    })
                  }}
                >
                  <i className="fas fa-star text-yellow-400"></i>
                </AntdButton>
              </div>
            ) : (
              <div>
                <AntdButton
                  size="small"
                  onClick={() => {
                    dispatch(actions.editOnePromotion(record?.id, { hilight: true })).then(() => {
                      dispatch(actions.getAllPromotions(page)).then(() => {
                        setIsLoading(true)
                      })
                    })
                  }}
                >
                  <i className="far fa-star"></i>
                </AntdButton>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "ช่วงเวลา",
      dataIndex: "category",
      render: (text, record) => (
        <div>
          {dayjs(record?.rangetime?.startdate).format("DD MMM YYYY")} -{" "}
          {dayjs(record?.rangetime?.enddate).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "แก้ไขล่าสุด",
      dataIndex: "updatedAt",
      render: (text, record) => dayjs(record?.updatedAt).format("DD MMM YYYY HH:mm") || "-",
    },
    {
      title: "รายละเอียด",
      dataIndex: "active",
      render: (text, record) => (
        <div className="flex gap-2">
          <Link to={`/site/promotion/edit/${record?.id}`}>
            <Button size="sm" colorScheme="yellow">
              แก้ไข
            </Button>
          </Link>
          <Button size="sm" colorScheme="red" onClick={() => handleDeletePromotion(record?.id)}>
            ลบ
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="โปรโมชั่น">
      <div className="flex justify-between px-4">
        <Link to="/site/promotion/create">
          <Button colorScheme="purple">เพิ่ม</Button>
        </Link>
      </div>
      <div className="p-4 w-full overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={promotions?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: promotions?.total,
            }}
          />
        )}
      </div>
    </Layout>
  )
}
