export const FrontPageMenuLink = {
  "/": {
    status_code: "/",
    description: "หน้าแรก",
  },
  "/promotion": {
    status_code: "/promotion",
    description: "โปรโมชั่น",
  },
  "/facility": {
    status_code: "/facility",
    description: "สิ่งอำนวยความสะดวกหรือแฟกซิลิตี้ต่างๆ",
  },
  "/detail-allroom": {
    status_code: "/detail-allroom",
    description: "ห้องพักทั้งหมด",
  },
  "/gallery": {
    status_code: "/gallery",
    description: "แกลอรี่",
  },
  "/map": {
    status_code: "/map",
    description: "แผนที่",
  },
  "/contact": {
    status_code: "/contact",
    description: "ติดต่อเรา",
  },
  "/cart": {
    status_code: "/cart",
    description: "ตะกร้า",
  },
}

export const FrontepageMenuList = {
  HOME: {
    status_code: "HOME",
    link: "/",
    description: "หน้าแรก",
    isSpecialType: false,
  },
  PROMOTION: {
    status_code: "PROMOTION",
    link: "/promotion",
    description: "โปรโมชั่น",
    isSpecialType: false,
  },
  FACILITY: {
    status_code: "FACILITY",
    link: "/facility",
    description: "สิ่งอำนวยความสะดวกหรือแฟกซิลิตี้ต่างๆ",
    isSpecialType: false,
  },
  ROOM: {
    status_code: "ROOM",
    link: "/detail-allroom",
    description: "ห้องพักทั้งหมด",
    isSpecialType: false,
  },
  GALLERY: {
    status_code: "GALLERY",
    link: "/gallery",
    description: "แกลอรี่",
    isSpecialType: false,
  },
  MAP: {
    status_code: "MAP",
    link: "/map",
    description: "แผนที่",
    isSpecialType: false,
  },
  CONTACT: {
    status_code: "CONTACT",
    link: "/contact",
    description: "ติดต่อเรา",
    isSpecialType: false,
  },
  BOOKING: {
    status_code: "BOOKING",
    description: "จองห้องพัก",
    isSpecialType: true,
    useOtherLink: true,
    otherLink: "",
  },
  ABOUT: {
    status_code: "ABOUT",
    link: "/about",
    description: "เกี่ยวกับเรา",
    isSpecialType: false,
  },
  POLICY: {
    status_code: "POLICY",
    link: "/policy",
    description: "นโยบายความเป็นส่วนตัว",
    isSpecialType: false,
  },
  MEETINGROOM: {
    status_code: "MEETINGROOM",
    link: "/meetingroom",
    description: "ห้องประชุม",
    isSpecialType: false,
  },
  ARTICLE: {
    status_code: "ARTICLE",
    link: "/article",
    description: "บทความ",
    isSpecialType: false,
  },
}
export const FrontpageSpecialMenu = {
  LANG_SWITCHER: {
    status_code: "LANG_SWITCHER",
    description: "ปุ่มเปลี่ยนภาษา",
  },
}

export default { FrontPageMenuLink, FrontpageSpecialMenu }
