import React, { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { PassportAuth } from '../../contexts/AuthContext'

export default function Register() {
  const { register, handleSubmit, errors } = useForm()
  const { handleForgottenPassword } = useContext(PassportAuth)
  const history = useHistory()
  const onSubmit = async (data, e) => {
    const confirm = window.confirm(
      `อีเมลที่คุณต้องการเปลี่ยนรหัสผ่าน : ${  data.email}`
    )
    if (confirm) {
      e.preventDefault()
      await handleForgottenPassword(data.email)
      alert(`รีเซ็ตรหัสผ่านสำเร็จ. กรุณาตรวจสอบอีเมลของท่าน`)
      history.push('/auth/login')
    } else {
      alert('รหัสผ่านไม่ตรงกัน')
    }
  }
  return (
    <>
      <div className='container mx-auto px-4 h-full'>
        <div className='flex content-center items-center justify-center h-full'>
          <div className='w-full lg:w-6/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0'>
              <div className='rounded-t mb-0 px-6 py-6'>
                <div className='text-center mb-3'>
                  <h6 className='text-gray-600 text-sm font-bold'>
                    Forgotten Pasword
                  </h6>
                  <hr className='mt-6 border-b-1 border-gray-400' />
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-password'
                    >
                      Email
                    </label>
                    <input
                      name='email'
                      type='email'
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      placeholder='Email'
                      ref={register}
                    />
                  </div>

                  <div className='text-center mt-6'>
                    <button
                      className='bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150'
                      type='submit'
                    >
                      Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className='flex flex-wrap mt-6 relative'>
              <div className='w-1/2'></div>
              <div className='w-1/2 text-right'>
                <Link to='/auth/login' className='text-gray-300'>
                  <small>มีบัญชีอยู่แล้ว</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
