/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import _ from "lodash"

import PolicyInfo from "../../components/Forms/HotelSite/PolicyInfo"
import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import { SpinnerLoading, DialogLoading } from "../../components/Loading"

export default function HotelPolicy() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, control } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState([])
  const info = useSelector((state) => state.information)
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false)

  const onSubmit = (data) => {
    setDialogLoadingOpen(true)
    console.log("Data", data)
    dispatch(
      actions.editOneInformation(info?.id, {
        ...data,
        policyImage: _.isEmpty(image) ? info?.policy?.policyImage?._id : image?.[0],
      }),
    ).then(() => {
      dispatch(actions.getInformation()).then(() => {
        setDialogLoadingOpen(false)
        setImage([])
        window.alert("แก้ไขข้อมูลเสร็จสิ้น")
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="จัดการนโยบายโรงแรม">
      <DialogLoading isLoading={dialogLoadingOpen} title="กำลังอัพโหลดรูปภาพ" />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <PolicyInfo
                register={register}
                defaultInfo={info}
                image={image}
                setImage={setImage}
                control={control}
              />
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
