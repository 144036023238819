import { MENU_ALL, MENU_CREATE, MENU_DELETE, MENU_GET, MENU_UPDATE } from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function MenuReducer(state = initialState, action) {
  switch (action.type) {
    case MENU_ALL:
      return {
        arr: action.payload?.rows,
        total: action?.payload?.total,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case MENU_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case MENU_CREATE:
      return { isLoading: true }
    case MENU_UPDATE:
      return { isLoading: true }
    case MENU_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
