import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import _ from "lodash"

import { GeneralInfo, ContactInfo, MainDescriptionForm } from "../../components/Forms/HotelSite"
import Layout from "../../components/layout/GeneralLayout"
import { Alert } from "../../components/common/Alert"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"

export default function HotelSiteMainInfo() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, control } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState([])
  const [logoImage, setLogoImage] = useState([])
  const info = useSelector((state) => state.information)
  const toast = useToast()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")

  const onSubmit = (data) => {
    console.log("Data", data)
    const payload = {
      ...data,
      translation: {
        th: {
          ...info.translation.th,
          ...data.translation.th,
        },
        en: {
          ...info.translation.en,
          ...data.translation.en,
        },
      },
      composite: {
        ...info.composite,
        translation: {
          th: {
            ...info.composite.translation.th,
            ...data.composite.translation.th,
          },
          en: {
            ...info.composite.translation.en,
            ...data.composite.translation.en,
          },
        },
      },
      logo: !_.isEmpty(logoImage) ? _.first(logoImage) : undefined,
    }

    console.log("Payload", payload)
    dispatch(actions.editOneInformation(info?.id, payload)).then(() => {
      dispatch(actions.getInformation())
        .then(() => {
          toast({
            title: "แก้ไขข้อมูลสำเร็จ",
            description: "แก้ไขข้อมูลเกี่ยวกับโรงแรมและเว็บไซต์โรงแรมสำเร็จ",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        })
        .catch((error) => {
          setErrMessage(error?.message)
          setIsError(true)
        })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ข้อมูลทั่วไปโรงแรม">
      <Alert
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
        isError={isError}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <GeneralInfo
                register={register}
                defaultInfo={info}
                setUploadedImage={setImages}
                uploadedImage={images}
                control={control}
                logoImage={logoImage}
                setLogoImage={setLogoImage}
              />
              <MainDescriptionForm defaultInfo={info} control={control} />
              <ContactInfo register={register} defaultInfo={info} control={control} />
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
