/* eslint-disable no-underscore-dangle */
import React from "react"
import moment from "moment"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Dot from "../common/Dot/dot"
import PaymentStatusExplainer from "./PaymentStatusExplainer"
import { overallMeetingRoomOrderStatusGenerator } from "../../util"

export default function ServiceOrderBasicInfo({ serviceOrderInfo, showPrintingPanel = true }) {
  const { t, i18n } = useTranslation()
  const orderStatus = overallMeetingRoomOrderStatusGenerator(serviceOrderInfo)
  const bookingDateDisplay = () => (
    <div className="flex flex-wrap">
      <div className="">
        {moment(serviceOrderInfo?.start).locale(i18n.language).format("D MMM YYYY")}
      </div>
      {!serviceOrderInfo?.use_one_day && <div className="mx-2"> - </div>}
      <div className="">
        {serviceOrderInfo?.use_one_day ? "" : moment(serviceOrderInfo?.end).format("D MMM YYYY")}
      </div>
    </div>
  )

  return (
    <div className="my-4">
      <div className="flex flex-wrap mb-2 ">
        <div className="w-full lg:w-1/2">
          <div className="mb-4">
            <h6 className="text-xs font-bold font-sans ">{t("reservation.bookingNumber")}</h6>
            <h6 className="font-semibold ">{serviceOrderInfo?.order_no}</h6>{" "}
          </div>
          <div className=" mb-4">
            <h6 className="text-xs font-bold font-sans">{t("crm.customer")}</h6>
            <p className="font-semibold font-sans ">
              {serviceOrderInfo?.customer?.firstname} {serviceOrderInfo?.customer?.lastname}{" "}
              <Link to={`/crm/show/${serviceOrderInfo?.customer?._id}`}>
                <Button size="xs">
                  <i className="fas fa-user" />{" "}
                </Button>
              </Link>
            </p>

            <h6 className="font-semibold text-sm">{serviceOrderInfo?.customer?.tel}</h6>

            {showPrintingPanel === true && <div></div>}
          </div>
        </div>
        <div className="w-full lg:w-1/2 mb-2">
          <div className=" w-full mb-3">
            <div className="flex gap-2 items-center">
              <h6 className="text-xs font-bold font-sans">
                {t("reservation.startDate")} - {t("reservation.endDate")}{" "}
              </h6>
            </div>
            {bookingDateDisplay()}
          </div>
          <div className=" w-full mb-3">
            <h6 className="text-xs font-bold font-sans mb-1">{t("reservation.bookingStatus")}</h6>
            <Dot
              color={orderStatus.color}
              word={i18n?.language === "th" ? orderStatus?.description : orderStatus?.descriptionEn}
            />
          </div>
          <div className=" w-full  mb-3">
            <h6 className="text-xs font-bold font-sans mb-1">{t("reservation.paymentStatus")}</h6>
            <PaymentStatusExplainer
              status={serviceOrderInfo?.paymentStatus}
              bookingInfo={serviceOrderInfo}
            />
          </div>
        </div>
      </div>
      <hr />
    </div>
  )
}
