import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import { getStorage } from "util/localstorage"

export default function PrivateRoute({ children, ...rest }) {
  useEffect(() => () => {}, [])
  return (
    <Route
      {...rest}
      render={({ location }) =>
        getStorage("token") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
