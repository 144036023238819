export const ChillpayPaymentName = {
  creditcard: { name: "VISA / Master Card / JCB", value: "creditcard", logo: "" },
  bank_qrcode: { name: "Thai QR Payment", value: "bank_qrcode", logo: "" },
  payplus_kbank: { name: "Kasikorn Bank (K PLUS)", value: "payplus_kbank", logo: "" },
  internetbank_scb: {
    name: "Siam Commercial Bank (SCB Easy App)",
    value: "internetbank_scb",
    logo: "",
  },
  internetbank_bay: { name: "Krungsri Bank (KMA App)", value: "internetbank_bay", logo: "" },
  internetbank_bbl: {
    name: "Bangkok Bank (Bualuang mBanking)",
    value: "internetbank_bbl",
    logo: "",
  },
  internetbank_ktb: {
    name: "Krungthai Bank (Krungthai Next)",
    value: "internetbank_ktb",
    logo: "",
  },
  epayment_linepay: { name: "Rabbit LINE Pay", value: "epayment_linepay", logo: "" },
  epayment_truemoney: { name: "TrueMoney Wallet", value: "epayment_truemoney", logo: "" },
  epayment_alipay: { name: "Alipay", value: "epayment_alipay", logo: "" },
  epayment_wechatpay: { name: "WeChat Pay", value: "epayment_wechatpay", logo: "" },
}

export const ChillpayPaymentMethod = [
  {
    id: "01",
    name: "Credit / Debit Card",
    methods: [
      { name: "VISA / Master Card / JCB", value: "creditcard", logo: "" },
      { name: "UnionPay", value: "", logo: "" },
    ],
    enable: true,
  },
  {
    id: "06",
    name: "QR Promptpay",
    methods: [{ name: "Thai QR Payment", value: "bank_qrcode", logo: "" }],
  },

  {
    id: "04",
    name: "Mobile Banking",
    methods: [
      { name: "Kasikorn Bank (K PLUS)", value: "payplus_kbank", logo: "" },
      { name: "Siam Commercial Bank (SCB Easy App)", value: "internetbank_scb", logo: "" },
      { name: "Krungsri Bank (KMA App)", value: "internetbank_bay", logo: "" },
      { name: "Bangkok Bank (Bualuang mBanking)", value: "internetbank_bbl", logo: "" },
      { name: "Krungthai Bank (Krungthai Next)", value: "internetbank_ktb", logo: "" },
    ],
    enable: true,
  },
  {
    id: "05",
    name: "Internet Banking",
    methods: [
      { name: "Krungthai Bank", value: "internetbank_ktb", logo: "" },
      { name: "Siam Commercial Bank", value: "internetbank_scb", logo: "" },
      { name: "Bangkok Bank", value: "internetbank_bbl", logo: "" },
      { name: "Krungsri Bank", value: "internetbank_bay", logo: "" },
      { name: "TMB Thanachart Bank", value: "internetbank_tbank", logo: "" },
    ],
    enable: true,
  },
  {
    id: "08",
    name: "E-Wallet",
    methods: [
      { name: "Rabbit LINE Pay", value: "epayment_linepay", logo: "" },
      { name: "TrueMoney Wallet", value: "epayment_truemoney", logo: "" },
      { name: "Alipay", value: "epayment_alipay", logo: "" },
      { name: "WeChat Pay", value: "epayment_wechatpay", logo: "" },
      // { name: "ShopeePay", value: "", logo: "" },
    ],
    enable: true,
  },
]

export default ChillpayPaymentMethod
