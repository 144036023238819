import api from "../../../config/api"
import { SYSTEM_GET, SYSTEM_POST, SYSTEM_PUT, SYSTEM_DEL } from "../types"

export const systemInfoGet = () => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/systems/`).then((res) => {
    console.log("Request Server to Get System Infomation Record")
    if (res.data) {
      console.log("System Info", res.data)
      dispatch({ type: SYSTEM_GET, payload: res.data })
    } else {
      dispatch({ type: SYSTEM_GET, payload: null })
    }
  })
}

export const systemInfoPost = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/systems/`, payload).then((res) => {
    console.log("Request Server to Create System Infomation Record")
    dispatch(systemInfoGet())
  })
}

export const systemInfoPut = (payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/systems/`, payload)
    .then(() => {
      console.log("Request Server to edit System Information Record")
      dispatch(systemInfoGet())
    })
    .catch((error) => {
      throw new Error(
        `ไม่สามารถแก้ไขการตั้งค่าได้ เนื่องจาก  ${error?.response?.data?.error?.message}`,
      )
    })
}
export const systemInfoDelete = (payload) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/systems/${payload}`).then((res) => {
    console.log("Request Server to Delete System Information Record")
    dispatch({ type: SYSTEM_DEL, payload: null })
  })
}
