import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"

// components
import { Button } from "@chakra-ui/react"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import RoomTypeTable from "../../../components/Tables/RoomTypeTable"
import Layout from "../../../components/layout/GeneralLayout"
import Alert from "../../../components/common/Alert/AlertDialog"

export default function RoomTypesManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessasge] = useState()
  const roomTypes = useSelector((state) => state.roomTypes)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(actions.getAllRoomTypes())
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessasge(err)
      })

    return () => {}
  }, [])

  return isLoading ? (
    <Layout categoryTitle={t("roomtype.title")} title={t("roomtype.roomTypeManagement")}>
      <div className="flex justify-between px-4">
        <Link to="/roomtypes/create">
          <Button colorScheme="purple" variant="solid">
            {t("general.add")}
          </Button>
        </Link>
      </div>
      <Alert
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessasge()
        }}
        isError={isError}
      />
      <div className="p-4 w-full min-h-screen">
        {_.size(roomTypes?.arr) > 0 ? (
          <RoomTypeTable roomTypeList={roomTypes?.arr} />
        ) : (
          <div className=" grid place-content-center place-items-center w-full p-5  mb-10">
            {t("roomtype.emptyCategory")}
          </div>
        )}
      </div>
    </Layout>
  ) : (
    <div>
      <SpinnerLoading />
      <Alert
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessasge()
        }}
        isError={isError}
      />
    </div>
  )
}
