import React from "react"
import { Button, useToast } from "@chakra-ui/react"
import JSONPretty from "react-json-pretty"
import WhitePanel from "../common/Cards/WhitePanel"

export default function FrontpagePayloadPanel({ payloadData, handleGeneratePayload }) {
  const toast = useToast()
  return (
    <div>
      <WhitePanel>
        <div className="flex justify-between">
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            Payload สำหรับระบบหน้าบ้าน
          </h6>
          <div className="flex gap-2">
            <Button colorScheme="green" size="sm" onClick={() => handleGeneratePayload()}>
              สร้าง Payload ข้อมูล
            </Button>{" "}
            <Button
              colorScheme="purple"
              size="sm"
              onClick={() => {
                const stringWord = JSON.stringify(payloadData)
                navigator.clipboard.writeText(stringWord)
                toast({
                  title: "คัดลอก Payload ไปยัง Clipboard แล้ว",
                  status: "success",
                })
              }}
            >
              คัดลอก{" "}
            </Button>
          </div>
        </div>
        <div className="my-2">
          <JSONPretty data={payloadData} />
        </div>
      </WhitePanel>
    </div>
  )
}
