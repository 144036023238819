/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { Button, useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"
import dayjs from "dayjs"

import Layout from "../../../components/layout/GeneralLayout"
import { ServiceOrderForm } from "../../../components/Forms/Reservation"
import { WhitePanel } from "../../../components/common/Cards"
import * as actions from "../../../redux/actions"
import CreateOrEditCustomerModal from "../../../components/Modal/CustomerManagement/CreateOrEditCustomerModal"
import { Alert } from "../../../components/common/Alert"
import { MeetingRoomSelector } from "../../../components/Selectors"
import { SpinnerLoading } from "../../../components/Loading"

export default function EditServiceOrder() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const meetingRoom = useSelector((state) => state.meetingRoom)
  const customers = useSelector((state) => state.customers)
  const serviceOrder = useSelector((state) => state.serviceOrder)
  const me = useSelector((state) => state.me)
  const toast = useToast()

  const { register, control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      use_one_day: serviceOrder?.use_one_day,
      start: serviceOrder?.start,
    },
  })

  const [customerOptions, setCustomerOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedMeetingRooms, setSelectedMeetingRooms] = useState([])
  const [rerender, setRerender] = useState(false)

  useEffect(() => {
    const rowsData = _.map(customers?.arr, (eachRow) => ({
      label: `${eachRow.firstname} ${eachRow.lastname}`,
      value: eachRow.id,
    }))
    setCustomerOptions(rowsData)

    return () => {}
  }, [customers])

  const handleCreateCustomer = (data) => {
    dispatch(actions.createOneCustomer(data))
      .then(() => {
        dispatch(actions.getAllCustomers(1, 1000000, ""))
        setIsModalCreateCustomerOpen(false)
      })
      .catch((err) => {
        window.alert("Create Customer Error", err?.message)
      })
  }

  useEffect(() => {
    dispatch(actions.getAllCustomers(1, 1000000, ""))
    return () => {}
  }, [])

  const getAllMeetingRooms = () => {
    dispatch(
      actions.getAllMeetingRoom({
        page: 1,
        size: 1000,
        query: {
          start: dayjs(watch("start")).format("YYYY-MM-DD"),
          end: watch("use_one_day") ? "" : dayjs(watch("end")).format("YYYY-MM-DD"),
          fetchOrder: true,
        },
      }),
    )
      .then(() => {
        setSelectedMeetingRooms([])
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
  }

  const getServiceOrder = () => {
    dispatch(actions.getOneServiceOrder(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((error) => {
        setIsError(true)
        setErrorMessage(error?.message)
      })
  }

  useEffect(() => {
    getServiceOrder()
    return () => {}
  }, [params])

  useEffect(() => {
    if (serviceOrder && !serviceOrder?.arr) {
      setValue("start", dayjs(serviceOrder?.start).toDate())
      setValue("end", dayjs(serviceOrder?.end).toDate())
      setValue("use_one_day", serviceOrder?.use_one_day)
      setValue("time_specify", serviceOrder?.time_specify)
    }
    return () => {}
  }, [serviceOrder])

  useEffect(() => {
    getAllMeetingRooms()
    return () => {}
  }, [watch("start"), watch("end")])

  const handleEditServiceOrder = (data) => {
    const payload = {
      start: dayjs(data.start).toISOString(),
      end: data.use_one_day ? "" : dayjs(data.end).toISOString(),
      use_one_day: data.use_one_day,
      remark: data.remark,
      customer: data.customer?.value,
      time_specify: data.time_specify,
      meeting_room_order: _.map(selectedMeetingRooms, (eachMeetingRoom) => ({
        ...eachMeetingRoom,
        meeting_room: eachMeetingRoom?.meetingRoom,
      })),
      name: data?.name || "",
      user: me?._id,
      createMeetingRoomOrder: true,
    }

    console.log("Adding Payload", payload)

    dispatch(actions.editOneServiceOrder(params.id, payload))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setErrorMessage(err?.message)
        setIsError(true)
      })
  }

  const handleDeleteMeetingRoomorder = async (meetingRoomOrderId) => {
    setIsLoading(false)
    dispatch(actions.deleteOneMeetingRoomOrder(meetingRoomOrderId))
      .then(() => {
        toast({
          status: "success",
          title: "ลบรายการจองสำเร็จ",
          description:
            "กรุณาเพิ่มรายการจองห้องประชุมห้องใหม่ตามที่ท่านต้องการ ถ้าหากรายการจองห้องประชุมหมดลง รายการนี้จะเปลี่ยนเป็นแค่การจองบริการทั่วไปเท่านั้น",
        })
        getServiceOrder()
        getAllMeetingRooms()
      })
      .catch((err) => {
        setErrorMessage(err?.message)
        setIsError(true)
      })
  }

  return (
    <Layout
      categoryTitle={t("reservation.title")}
      title={t("reservation.editServiceOrder")}
      preview
    >
      <Alert errMessage={errorMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-start px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="px-4 my-4">
        <CreateOrEditCustomerModal
          handleCreateCustomer={handleCreateCustomer}
          handleOnClose={() => {
            setIsModalCreateCustomerOpen(false)
          }}
          isOpen={modalCreateCustomerOpen}
        />
        <form onSubmit={handleSubmit(handleEditServiceOrder)}>
          <WhitePanel>
            {isLoading && (
              <ServiceOrderForm
                control={control}
                register={register}
                setValue={setValue}
                watch={watch}
                customers={customerOptions}
                setIsModalCreateCustomerOpen={() => setIsModalCreateCustomerOpen(true)}
                showTitleForMeeting
                defaultValue={serviceOrder}
                isLoading={isLoading}
                showTimeSpecify
              />
            )}
            <div className="my-2">
              {isLoading && (
                <MeetingRoomSelector
                  meetingRoom={meetingRoom}
                  selectedMeetingRooms={selectedMeetingRooms}
                  setSelectedMeetingRooms={setSelectedMeetingRooms}
                  rerender={rerender}
                  setRerender={setRerender}
                  startDate={watch("start")}
                  endDate={watch("end")}
                  isOneDay={watch("use_one_day")}
                  alreadyBooked={serviceOrder?.meeting_room_orders}
                  plusDay
                  handleDeleteMeetingRoomOrder={handleDeleteMeetingRoomorder}
                  selectedServiceOrder={serviceOrder}
                />
              )}
              {!isLoading && <SpinnerLoading />}
            </div>
          </WhitePanel>{" "}
          <div className="text-center  gap-0 py-6 fixed bottom-0 px-5 bg-white w-9/12 lg:w-2/4 flex justify-start shadow-lg rounded-md">
            <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
              {t("general.edit")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
