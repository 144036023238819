import React from "react"
import PropTypes from "prop-types"
import { Alert, Button } from "antd"

import { BookingStatus } from "../../../config/constant"
import DeleteButton from "./DeleteButton"
import CheckedInButton from "./CheckedInButton"
import ConfirmButton from "./ConfirmButton"
import CheckedOutButton from "./CheckedOutButton"
import PaymentButton from "./PaymentButton"
import ServiceButton from "./ServiceButton"
import SuccessButton from "./SuccessButton"
import EditBookingButton from "./EditBookingButton"
import PaymentSlipUploadButton from "./PaymentSlipUploadButton"
import PriceCalculationButton from "./PriceCalculationButton"
import CheckoutCancleButton from "./CheckoutCancleButton"
import EditCheckoutDateButton from "./EditCheckoutDateButton"

export default function ActionButtonList({
  eachBooking,
  place = "table",
  disableAll = false,
  handleEditBooking = () => {},
  handleDeleteBooking = () => {},
  handleEditLog = () => {},
  rooms,
  hotelServices,
  handleCreateImage,
}) {
  const generalHandleProps = {
    bookingInfo: eachBooking,
    handleEditBooking,
    handleCreateImage,
  }
  if (disableAll) return <div></div>
  if (place === "modal") {
    return (
      <div className="flex gap-1 justify-center">
        {eachBooking?.status === BookingStatus.book && <ConfirmButton {...generalHandleProps} />}
        {(eachBooking?.status === BookingStatus.confirm ||
          eachBooking?.status === BookingStatus?.pending) && (
          <CheckedInButton {...generalHandleProps} />
        )}
        {eachBooking?.status !== BookingStatus.book &&
          eachBooking?.status !== BookingStatus.confirm && (
            <CheckedOutButton {...generalHandleProps} />
          )}
        <PaymentButton {...generalHandleProps} rooms={rooms} />
        {eachBooking?.status !== BookingStatus.book && (
          <PaymentSlipUploadButton {...generalHandleProps} />
        )}{" "}
        <ServiceButton {...generalHandleProps} hotelServices={hotelServices} />{" "}
        <SuccessButton {...generalHandleProps} />
        <EditBookingButton bookingInfo={eachBooking} />
        <DeleteButton bookingInfo={eachBooking} handleDeleteBooking={handleDeleteBooking} />
        {eachBooking?.status === BookingStatus.success && (
          <CheckoutCancleButton bookingInfo={eachBooking} handleEditLog={handleEditLog} />
        )}
        {eachBooking?.status === BookingStatus.success && (
          <EditCheckoutDateButton bookingInfo={eachBooking} />
        )}
      </div>
    )
  }

  if (place === "info") {
    return (
      <div className="flex flex-wrap">
        {!localStorage.getItem("DONT_SHOW_SLIP_UPLOAD_CHANGE_NOTIFY") && (
          <div className="w-full">
            <Alert
              message="ระบบอัพโหลดสลิปได้ถูกย้ายไปรวมในการชำระเงินแล้ว เพื่อการจัดการที่ดียิ่งขึ้น"
              showIcon
              type="info"
              closable
              action={
                <Button
                  size="small"
                  onClick={() => {
                    localStorage.setItem("DONT_SHOW_SLIP_UPLOAD_CHANGE_NOTIFY", true)
                  }}
                >
                  ไม่ต้องแสดงอีก
                </Button>
              }
            />
          </div>
        )}
        <div className="w-full lg:w-auto flex gap-1  overflow-x-auto my-1">
          <ConfirmButton {...generalHandleProps} />
          <CheckedInButton {...generalHandleProps} />
          <CheckedOutButton {...generalHandleProps} />
          <SuccessButton {...generalHandleProps} />
        </div>{" "}
        <div className="w-full lg:w-auto flex gap-1  overflow-x-auto my-1">
          <PriceCalculationButton {...generalHandleProps} />
          <PaymentButton {...generalHandleProps} rooms={rooms} />
          {/* <PaymentSlipUploadButton {...generalHandleProps} /> */}
          <ServiceButton {...generalHandleProps} hotelServices={hotelServices} />{" "}
        </div>
        <div className="w-full lg:w-auto flex gap-1  overflow-x-auto my-1">
          <EditBookingButton bookingInfo={eachBooking} />
          <DeleteButton bookingInfo={eachBooking} handleDeleteBooking={handleDeleteBooking} />
          {eachBooking?.status === BookingStatus.success && (
            <CheckoutCancleButton bookingInfo={eachBooking} handleEditLog={handleEditLog} />
          )}{" "}
          {eachBooking?.status === BookingStatus.success && (
            <EditCheckoutDateButton bookingInfo={eachBooking} />
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="flex gap-1  overflow-x-auto my-1">
        <ConfirmButton {...generalHandleProps} />
        <CheckedInButton {...generalHandleProps} />
        <CheckedOutButton {...generalHandleProps} />
      </div>
      <div className="flex gap-1  overflow-x-auto">
        <SuccessButton {...generalHandleProps} />
        <EditBookingButton bookingInfo={eachBooking} />
        <DeleteButton bookingInfo={eachBooking} handleDeleteBooking={handleDeleteBooking} />
      </div>
      {eachBooking?.status === BookingStatus.success && (
        <div className="flex gap-1  overflow-x-auto my-1">
          <CheckoutCancleButton bookingInfo={eachBooking} handleEditLog={handleEditLog} />
          <EditCheckoutDateButton bookingInfo={eachBooking} />
        </div>
      )}
    </div>
  )
}

ActionButtonList.propTypes = {
  eachBooking: PropTypes.object,
  place: PropTypes.string,
  disableAll: PropTypes.bool,
  handleEditBooking: PropTypes.func,
  handleDeleteBooking: PropTypes.func,
  rooms: PropTypes.shape({
    arr: PropTypes.arrayOf(PropTypes.object),
  }),
  hotelServices: PropTypes.shape({
    arr: PropTypes.arrayOf(PropTypes.object),
  }),
}
