/* eslint-disable no-underscore-dangle */
import React, { useState } from "react"
import _ from "lodash"
import { Input } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { Input as AntdInput } from "antd"

export default function SubtypeSelector({ subtype, rooms, register, watch }) {
  const { i18n } = useTranslation()
  const [searchWord, setSearchWord] = useState("")

  const generateEachSubtypeList = (subtypeId, index) => {
    const roomBySubtype = _.groupBy(rooms, "sub_type.id")
    const selectedSubtype = _.find(subtype?.arr, (eachSubtype) => eachSubtype?.id === subtypeId)
    const UnSpecifyRoomReservation = selectedSubtype?.no_room_booking_amount

    const vacantAmount = _.size(roomBySubtype[subtypeId]) - UnSpecifyRoomReservation
    const specifyRoomReservation =
      selectedSubtype?.number_of_room - UnSpecifyRoomReservation - vacantAmount

    return (
      <div key={index} className="w-full lg:w-1/2 p-2">
        <div className="flex flex-wrap bg-gray-200 p-2 rounded-md">
          <h3 className="w-full lg:w-1/2 my-2 text-lg font-bold font-sans">
            {selectedSubtype?.translation?.[i18n?.language]?.name || selectedSubtype?.name}
            <h4 className="font-semibold  ">
              <span className="text-sm">ว่าง </span>
              {vacantAmount} ห้อง
            </h4>
          </h3>
          <div className="my-2 w-full md:w-1/2 flex flex-wrap">
            <div className="w-full md:w-1/3">
              <h5 className="text-sm">จำนวน</h5>
              <h4 className="font-semibold text-sm "> {selectedSubtype?.number_of_room} ห้อง</h4>
            </div>
            <div className="w-full md:w-1/3">
              <h5 className="text-sm">จองระบุห้อง </h5>
              <h4 className="font-semibold text-sm "> {specifyRoomReservation} ห้อง</h4>
            </div>
            <div className="w-full md:w-1/3">
              <h5 className="text-sm">จองไม่ระบุห้อง </h5>
              <h4 className="font-semibold text-sm "> {UnSpecifyRoomReservation} ห้อง</h4>
            </div>
          </div>

          <div className="w-full lg:w-2/3">
            <div className="flex items-center gap-2">
              <div>จอง</div>
              <div className="md:w-2/3">
                <Input
                  name={`no_room_booking[${index}].amount`}
                  backgroundColor="white"
                  borderColor="gray.300"
                  placeholder="จำนวน"
                  min={0}
                  max={vacantAmount}
                  ref={register({
                    max: vacantAmount,
                  })}
                />
              </div>
              <input
                type="hidden"
                ref={register}
                name={`no_room_booking[${index}].roomtype`}
                value={selectedSubtype?.type?._id}
              />{" "}
              <input
                type="hidden"
                ref={register}
                name={`no_room_booking[${index}].subtype`}
                value={selectedSubtype?.id}
              />
              <div>ห้อง</div>
            </div>{" "}
            {parseInt(watch(`no_room_booking[${index}].amount`) || 0, 10) > vacantAmount && (
              <div className="text-red-400 my-2"> เลือกเกินจำนวนห้องว่างที่สามารถเลือกได้ </div>
            )}
          </div>
        </div>

        {/* <div className="my-2 flex flex-wrap">
          {roomByRoomType[roomTypeId] ? (
            _.map(roomByRoomType[roomTypeId], (room, roomTypeIndex) => (
              <div className={`p-2 w-full  lg:w-1/6  bg-white`} key={roomTypeIndex}>
                <div className="bg-gray-100 rounded-md p-2">
                  <h3 className="font-bold font-sans">{room?.name}</h3>
                  <p className="text-sm ">{room?.sub_type?.name}</p>
                  <p className="text-xs ">{room?.ota_reserved && t("roomtype.otaReservation")}</p>
                </div>
              </div>
            ))
          ) : (
            <div className=" p-4 flex justify-center">
              <p>{t("roomtype.emptyCategory")}</p>
            </div>
          )}
        </div> */}
      </div>
    )
  }

  const filterRoomType = _.filter(subtype?.arr, (each) =>
    _.includes(
      _.lowerCase(each?.type?.translation?.th?.name || "") ||
        _.lowerCase(each?.type?.name || "") ||
        _.lowerCase(each?.type?.translation?.en?.name || ""),
      _.lowerCase(searchWord),
    ),
  )

  return (
    <div>
      <div className="flex flex-wrap my-2 ">
        <AntdInput
          placeholder="input search text"
          addonBefore={<i className="fas fa-search" />}
          allowClear
          onChange={(e) => setSearchWord(e.target.value)}
          style={{
            width: 400,
          }}
        />
      </div>
      <div className="mt-4">
        {_.map(_.values(_.groupBy(filterRoomType, "type._id")), (eachType, typeIndex) => (
          <div key={`type-${typeIndex}`}>
            <h3 className="my-2 text-lg font-bold font-sans">
              {eachType?.[0]?.type?.translation?.[i18n.language]?.name ||
                eachType?.[0]?.type?.name ||
                "ไม่ระบุ"}
            </h3>{" "}
            <hr />
            <div className="flex flex-wrap">
              {_.map(eachType, (eachSubType, index) =>
                generateEachSubtypeList(eachSubType?.id, `RT${typeIndex}-SU${index}`),
              )}
            </div>
          </div>
        ))}{" "}
      </div>
    </div>
  )
}
