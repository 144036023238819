import { Checkbox } from "@chakra-ui/react"
import { Controller } from "react-hook-form"
import React from "react"
import { Input, TreeSelect } from "antd"
import _ from "lodash"

import { ChillpayPaymentMethod } from "../../../config/constant"
const { TextArea } = Input

function OnlineBookingSettingForm({ register, defaultValue, control, watch }) {
  const chillPayOnCascader = _.map(ChillpayPaymentMethod, (item) => ({
    title: item.name,
    value: item.id,
    children: _.map(item.methods, (method) => ({
      title: method.name,
      value: method.value,
    })),
  }))

  return (
    <div className="flex flex-wrap">
      <div className="w-full my-1">
        <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
          รูปแบบการจอง
          <p className="text-xs font-normal">อนุญาติให้ลูกค้าทำการจองในรูปแบบใดบ้าง</p>
        </h6>
        <div className="flex flex-col gap-2">
          <Checkbox
            colorScheme="purple"
            ref={register}
            name="setting.onlineBooking.enableType.specify"
            defaultValue={defaultValue?.setting?.onlineBooking?.enableType?.specify}
            defaultChecked={defaultValue?.setting?.onlineBooking?.enableType?.specify}
          >
            แบบระบุห้อง
          </Checkbox>
          <Checkbox
            colorScheme="purple"
            ref={register}
            name="setting.onlineBooking.enableType.unSpecify"
            defaultValue={defaultValue?.setting?.onlineBooking?.enableType?.unSpecify}
            defaultChecked={defaultValue?.setting?.onlineBooking?.enableType?.unSpecify}
          >
            แบบไม่ระบุห้อง
          </Checkbox>
        </div>
      </div>
      <div className="w-full lg:w-1/2 my-4">
        <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
          ตั้งค่าการชำระเงิน
          <p className="text-xs font-normal">รูปแบบการชำระเงินที่รองรับ</p>
        </h6>

        <div className="flex flex-col gap-2">
          <Checkbox
            colorScheme="purple"
            ref={register}
            name="setting.onlineBooking.paymentType.transfer"
            defaultValue={defaultValue?.setting?.onlineBooking?.paymentType?.transfer}
            defaultChecked={defaultValue?.setting?.onlineBooking?.paymentType?.transfer}
          >
            โอนเงินผ่านธนาคาร / PromptPay
          </Checkbox>
          <Checkbox
            colorScheme="purple"
            ref={register}
            name="setting.onlineBooking.paymentType.chillpay.enable"
            defaultValue={defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.enable}
            defaultChecked={defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.enable}
          >
            จ่ายผ่าน ChillPay (Payment Gateway)
          </Checkbox>
        </div>
        {watch("setting.onlineBooking.paymentType.chillpay.enable") && (
          <div className="flex flex-col gap-2">
            <div>
              <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
                การตั้งค่า ChillPay
                <p className="text-xs font-normal">
                  ผู้ใช้งานต้องลงทะเบียนใช้งานกับทาง ChillPay ก่อน
                </p>
              </h6>
            </div>

            <div className="">
              <Controller
                control={control}
                defaultValue={
                  defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.merchantId
                }
                name="setting.onlineBooking.paymentType.chillpay.merchantId"
                render={(field) => (
                  <Input {...field} addonBefore="Merchant ID" placeholder="Merchant ID" />
                )}
              />
            </div>
            <div className="">
              <Controller
                control={control}
                defaultValue={defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.apiKey}
                name="setting.onlineBooking.paymentType.chillpay.apiKey"
                render={(field) => <Input {...field} addonBefore="API Key" placeholder="API Key" />}
              />
            </div>

            <div className="">
              <Controller
                control={control}
                defaultValue={
                  defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.apiSecret
                }
                name="setting.onlineBooking.paymentType.chillpay.apiSecret"
                render={(field) => (
                  <TextArea {...field} addonBefore="MD5 Secret" placeholder="MD5 Secret" rows={3} />
                )}
              />
            </div>
            <div className="font-semibold text-sm my-2">ข้อมูลเพิ่มเติมเกี่ยวกับ Chillpay</div>
            <div className="">
              <Controller
                control={control}
                defaultValue={
                  defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.ipAddress
                }
                name="setting.onlineBooking.paymentType.chillpay.ipAddress"
                render={(field) => (
                  <Input {...field} addonBefore="Machine IP Address" placeholder="IP Address" />
                )}
              />
            </div>
            <div className="">
              <Controller
                control={control}
                defaultValue={
                  defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.endpointURL
                }
                name="setting.onlineBooking.paymentType.chillpay.endpointURL"
                render={(field) => (
                  <Input {...field} addonBefore="Endpoint URL" placeholder="IP Address" />
                )}
              />
            </div>
            <div className="">
              <div className="font-semibold text-sm">ช่องทางการชำระเงินที่อนุญาติ</div>
              <Controller
                control={control}
                defaultValue={
                  defaultValue?.setting?.onlineBooking?.paymentType?.chillpay?.chillpayPaymentMethod
                }
                name="setting.onlineBooking.paymentType.chillpay.chillpayPaymentMethod"
                render={(field) => (
                  <TreeSelect
                    {...field}
                    showSearch
                    style={{ width: "100%" }}
                    value={field.value}
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select"
                    allowClear
                    multiple
                    treeDefaultExpandAll
                    onChange={(value) => field.onChange(value)}
                    treeData={chillPayOnCascader}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>

      <br className="w-full my-4" />
      <div className="w-full my-1 lg:w-1/2">
        <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
          ตั้งค่ามัดจำ
          <p className="text-xs font-normal">ตั้งค่าว่าจะอนุญาติให้ลูกค้าชำระมัดจำหรือไม่</p>
        </h6>
        <div className="flex flex-col gap-2">
          <Controller
            control={control}
            name="setting.onlineBooking.deposit.allowDeposit"
            defaultValue={defaultValue?.setting?.onlineBooking?.deposit?.allowDeposit}
            render={(field) => (
              <Checkbox
                {...field}
                defaultChecked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                colorScheme="purple"
              >
                อนุญาติให้มัดจำ
              </Checkbox>
            )}
          />

          <Checkbox
            colorScheme="purple"
            ref={register}
            name="setting.onlineBooking.deposit.allowPayAtHotel"
            defaultValue={defaultValue?.setting?.onlineBooking?.deposit?.allowPayAtHotel}
            defaultChecked={defaultValue?.setting?.onlineBooking?.deposit?.allowPayAtHotel}
          >
            อนุญาติให้ชำระที่โรงแรม
          </Checkbox>
        </div>
        {watch("setting.onlineBooking.deposit.allowDeposit") && (
          <div className="my-2">
            <div className="text-sm font-semibold">ต้องการให้มัดจำจำนวนร้อยละ</div>
            <div className="my-2">
              <Controller
                control={control}
                defaultValue={defaultValue?.setting?.onlineBooking?.transferInfo?.depositPercent}
                name="setting.onlineBooking.transferInfo.depositPercent"
                render={(field) => (
                  <Input
                    {...field}
                    addonAfter={<i className="fas fa-percent"></i>}
                    style={{ width: "50%" }}
                    placeholder="ร้อยละของราคามัดจำ"
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
      <div className="w-full my-1 lg:w-1/2"></div>
    </div>
  )
}

export default OnlineBookingSettingForm
