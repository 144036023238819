import api from "../../../config/api"

import {
  ROOMTYPE_ALL,
  ROOMTYPE_CREATE,
  ROOMTYPE_DELETE,
  ROOMTYPE_GET,
  ROOMTYPE_PUT,
} from "../types"

// faker.locale = "th"

export const getAllRoomTypes =
  ({
    name = "",
    page = "",
    size = "",
    start = "",
    end = "",
    date = "",
    isShowAllDay = "",
    roomTypeId = "",
    fetchBooking = "",
  } = {}) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/room-type?name=${name}&page=${page}&size=${size}&start=${start}&end=${end}&date=${date}&isShowAllDay=${isShowAllDay}&roomTypeId=${roomTypeId}&fetchBooking=${fetchBooking}`,
      )
      .then((res) => {
        console.log("Request Server to Get All Roomtypes")
        if (res.data) {
          dispatch({ type: ROOMTYPE_ALL, payload: res.data?.rows })
        } else {
          dispatch({ type: ROOMTYPE_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All roomtype", err)
        dispatch({ type: ROOMTYPE_ALL, payload: null })
        throw new Error(
          `ไม่สามารถดึงข้อมูลหมวดหมู่ห้องพักทั้งหมดมาได้  ${err?.response?.data?.error?.message}`,
        )
      })
  }

export const getRoomType = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/room-type/${id}`)
    .then((res) => {
      console.log("Request Server to Get One Roomtypes")
      if (res.data) {
        dispatch({ type: ROOMTYPE_GET, payload: res.data })
      } else {
        dispatch({ type: ROOMTYPE_GET, payload: null })
        throw new Error("ไม่มีข้อมูลห้องพักนี้")
      }
    })
    .catch((err) => {
      throw new Error(
        `ไม่สามารถดึงข้อมูลรูปแบบห้องพักนี้ออกมาได้ ${err?.response?.data?.error?.message}`,
      )
    })
}

export const createRoomType = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/room-type/`, payload)
    .then(async (res) => {
      console.log("Request Server to Create New Room Types")
      dispatch({ type: ROOMTYPE_CREATE, payload: res.data })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถสร้างหมวดหมู่ห้องพักได้ ${err?.response?.data?.error?.message}`)
    })
}

export const editRoomType = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/room-type/${id}`, payload)
    .then(async (res) => {
      console.log("Request Server to edit roomtypes")
      dispatch({ type: ROOMTYPE_PUT })
    })
    .catch((err) => {
      throw new Error(
        `ไม่สามารถแก้ไขข้อมูลหมวดหมู่ห้องพักได้ ${err?.response?.data?.error?.message}`,
      )
    })
}

export const deleteRoomType = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/room-type/${id}`)
    .then((res) => {
      console.log("Request Server to Delete System Information Record")
      dispatch({ type: ROOMTYPE_DELETE, payload: null })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถลบหมวดหมู่ห้องพักได้ ${err?.response?.data?.error?.message}`)
    })
}
