export const FrontpageComponentDisplayType = {
  BUTTON: {
    status_code: "BUTTON",
    description: "ปุ่มกด",
    descriptionEn: "Button",
  },
  VIDEO: {
    status_code: "VIDEO",
    description: "วีดีโอ",
    descriptionEn: "Video",
  },
  GRID_GALLERY: {
    status_code: "GRID_GALLERY",
    description: "แกลอรี่แบบกริด",
    descriptionEn: "Grid Gallery",
  },
  CARD_CONTENT_GALLERY: {
    status_code: "CARD_CONTENT_GALLERY",
    description: "แกลอรี่แบบการ์ด",
    descriptionEn: "Card Content Gallery",
  },
  SLIDESHOW_GALLERY: {
    status_code: "SLIDESHOW_GALLERY",
    description: "แกลอรี่แบบสไลด์โชว์",
    descriptionEn: "Slideshow Gallery",
  },
  LIST_GALLERY_HORIZONTAL: {
    status_code: "LIST_GALLERY_HORIZONTAL",
    description: "แกลอรี่แบบลิตส์ตามแนวนอน",
    descriptionEn: "Horizontal List Gallery",
  },
  LIST_GALLERY_VERTICAL: {
    status_code: "LIST_GALLERY_VERTICAL",
    description: "แกลอรี่แบบลิสต์ตามแนวตั้ง",
    descriptionEn: "Vertical List Gallery",
  },
  RICH_TEXT_CONTENT: {
    status_code: "RICH_TEXT_CONTENT",
    description: "คอนเทนต์ข้อความ",
    descriptionEn: "Text Content",
  },
}

export default FrontpageComponentDisplayType
