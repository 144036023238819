import {
  MATERIAL_ALL,
  MATERIAL_CREATE,
  MATERIAL_DELETE,
  MATERIAL_GET,
  MATERIAL_UPDATE,
  MATERIAL_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const MaterialAction = new GeneralReduxActionClass({
  allConst: MATERIAL_ALL,
  createConst: MATERIAL_CREATE,
  deleteConst: MATERIAL_DELETE,
  errorConst: MATERIAL_ERROR,
  getConst: MATERIAL_GET,
  putConst: MATERIAL_UPDATE,
  backendPrefix: "material",
  description: "วัตถุดิบ",
})

export const getAllMaterial = (query) => MaterialAction.getAll(query)
export const getOneMaterial = (id) => MaterialAction.getOne(id)
export const createOneMaterial = (payload) => MaterialAction.createOne(payload)
export const editOneMaterial = (id, payload) => MaterialAction.editOne(id, payload)
export const deleteOneMaterial = (id) => MaterialAction.deleteOne(id)
