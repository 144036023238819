import React, { useState, useEffect } from "react"
import _ from "lodash"
import dayjs from "dayjs"
// import Chart from "react-apexcharts"
import { useTranslation } from "react-i18next"

export default function DashboardMonitorComponent({
  startDate,
  endDate,
  logs,
  isLoading,
  bookings,
  customers,
  rooms,
}) {
  const [totalPrice, setTotalPrice] = useState()
  const [monthlyIncome, setMonthlyIncome] = useState([])
  const [selectYear, setSelectYear] = useState(dayjs().year())
  console.log("selectYear", selectYear)
  const { t } = useTranslation()

  const handleChange = (value) => {
    console.log("value in han", value)
    setSelectYear(value)
  }

  const calculate = () => {
    if (_.isArray(logs?.arr)) {
      setMonthlyIncome([])
      // Find Total Price
      const totalPriceEachLog = _.sum(
        _.map(logs.arr, (eachLog) => {
          const totalRoomPriceEachList = _.sum(_.map(eachLog.list, (eachList) => eachList?.price))
          const totalServicePriceEachList = _.sum(
            _.map(eachLog.services, (eachService) => eachService?.price),
          )
          return totalRoomPriceEachList + totalServicePriceEachList
        }),
      )
      setTotalPrice(totalPriceEachLog)

      const MonthlyIncomeStack = monthlyIncome

      for (let months = 0; months < 12; months += 1) {
        const IncomeOfMonth = _.map(logs.arr, (eachLog) => {
          if (
            dayjs(eachLog?.createdAt).month() === months &&
            dayjs(eachLog?.createdAt).year() === selectYear
          ) {
            const sumOflist = _.sum(_.map(eachLog?.list, (eachList) => eachList?.price))
            return sumOflist
          }
          return 0
        })

        console.log("IncomeOfMonth", _.sum(IncomeOfMonth))
        MonthlyIncomeStack.push(_.sum(IncomeOfMonth))
      }
      console.log("MonthlyIncomeStack", MonthlyIncomeStack)
      setMonthlyIncome(monthlyIncome)
    }
  }

  useEffect(() => {
    calculate()
    return () => {}
  }, [startDate, endDate, logs, isLoading, selectYear])

  const NewCustomer = () => {
    const customerStack = _.filter(customers?.arr, (eachCustomer) => {
      const date = eachCustomer?.createdAt
      return date >= startDate && date <= endDate
    })
    return _.size(customerStack)
  }

  return (
    <>
      <div className="flex flex-wrap">
        {/* ราคารวม */}
        <div className="w-full md:w-1/2 xl:w-1/3 p-3 ">
          <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg hover:shadow-xl  p-5 py-6  duration-150 ease-in-out transform hover:-translate-y-1 ">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-green-600">
                  <i className="fa fa-wallet fa-2x fa-inverse" />
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h2 className="font-bold uppercase text-gray-600">{t("dashboard.totalIncome")}</h2>
                <p className="font-bold text-3xl">
                  {`${(totalPrice || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}`}{" "}
                  {t("general.baht")}{" "}
                  <span className="text-green-500">
                    {totalPrice === 0 ? (
                      <i className="fas fa-caret-down" />
                    ) : (
                      <i className="fas fa-caret-up" />
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-3">
          <div className="bg-gradient-to-b from-blue-200 to-blue-100 border-b-4 border-blue-500 rounded-lg  p-5 py-6 hover:shadow-xl  duration-150 ease-in-out transform hover:-translate-y-1">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-blue-600">
                  <i className="fas fa-tasks fa-2x fa-inverse" />
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h2 className="font-bold uppercase text-gray-600">
                  {t("reservation.bookingList")}
                </h2>
                <p className="font-bold text-3xl">
                  {bookings?.arr?.length || "0"} {t("dashboard.item")}{" "}
                  <span className="text-blue-600">
                    {bookings?.arr?.length === 0 ? (
                      <i className="fas fa-caret-down" />
                    ) : (
                      <i className="fas fa-caret-up" />
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-3">
          <div className="bg-gradient-to-b from-red-200 to-red-100 border-b-4 border-red-500 rounded-lg  p-5 py-6 hover:shadow-xl  duration-150 ease-in-out transform hover:-translate-y-1">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-red-600">
                  <i className="fas fa-check-circle fa-2x fa-inverse" />
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h2 className="font-bold uppercase text-gray-600">
                  {t("dashboard.completedBooking")}
                </h2>
                <p className="font-bold text-3xl">
                  {logs?.arr?.length || "0"} {t("dashboard.item")}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-3">
          <div className="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg  p-5 py-6 hover:shadow-xl  duration-150 ease-in-out transform hover:-translate-y-1">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-pink-600">
                  <i className="fas fa-users fa-2x fa-inverse" />
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h2 className="font-bold uppercase text-gray-600">{t("dashboard.allCustomer")}</h2>
                <p className="font-bold text-3xl">
                  {customers?.total || "**"} {t("dashboard.person")}{" "}
                  <span className="text-pink-500">
                    <i className="fas fa-exchange-alt" />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-3">
          <div className="bg-gradient-to-b from-yellow-200 to-yellow-100 border-b-4 border-yellow-600 rounded-lg  p-5 py-6 hover:shadow-xl  duration-150 ease-in-out transform hover:-translate-y-1">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-yellow-600">
                  <i className="fas fa-user-plus fa-2x fa-inverse" />
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h2 className="font-bold uppercase text-gray-600">{t("dashboard.newCustomer")}</h2>
                <p className="font-bold text-3xl">
                  {startDate == null ? customers.total : NewCustomer()} {t("dashboard.person")}{" "}
                  <span className="text-yellow-600">
                    {NewCustomer() === 0 ? (
                      <i className="fas fa-caret-down" />
                    ) : (
                      <i className="fas fa-caret-up" />
                    )}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 xl:w-1/3 p-3">
          <div className="bg-gradient-to-b from-indigo-200 to-indigo-100 border-b-4 border-indigo-500 rounded-lg  p-5 py-6 hover:shadow-xl  duration-150 ease-in-out transform hover:-translate-y-1">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-indigo-600">
                  <i className="fas fa-home fa-2x fa-inverse" />
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h2 className="font-bold uppercase text-gray-600">{t("dashboard.allRoom")}</h2>
                <p className="font-bold text-3xl">
                  {rooms?.arr?.length || "**"} {t("dashboard.room")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-end pt-4 p-3">
        <FormControl>
          <InputLabel>เลือกปี</InputLabel>
          <Select
            value={selectYear}
            label="เลือกปี"
            onChange={(e) => {
              console.log("onnnn")
              handleChange(e.target.value)
            }}
          >
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
            <MenuItem value={2020}>2020</MenuItem>
          </Select>
        </FormControl>
      </div> */}
    </>
  )
}
