import React from "react"

const Slider = ({ register, lengthFormName, widthFormName, watch }) => (
  <div>
    <label htmlFor="lengthSlider">ความยาว:</label>
    <input
      type="range"
      id="lengthSlider"
      defaultValue={200}
      min={1}
      max={300}
      name={lengthFormName}
      ref={register}
    />
    <span>{watch(lengthFormName)}px</span>
    <br />
    <label htmlFor="widthSlider">ความกว้าง:</label>
    <input type="range" id="widthSlider" min={1} max={450} name={widthFormName} ref={register} />
    <span>{watch(widthFormName)}px</span>
  </div>
)

export default Slider
