import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import * as actions from "../../../redux/actions"
import Layout from "../../../components/layout/GeneralLayout"
import { facilityJSON } from "../../../config/constant"
import { ImageUpload, RoomTypeForm } from "../../../components"

import Alert from "../../../components/common/Alert/AlertDialog"
import DialogLoading from "../../../components/Loading/DialogLoading"

export default function CreateRoomType() {
  const dispatch = useDispatch()
  const { handleSubmit, register, control, watch, setValue } = useForm()
  const [uploadedImage, setUploadedImage] = useState()
  const [selectedFacility, setSelectedFacility] = useState(facilityJSON)
  const [subTypeStack, setSubtypeStack] = useState([])
  const [rerender, setRerender] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [isShowDialogLoading, setIsShowDialogLoading] = useState(false)

  const history = useHistory()
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onSubmit = (data) => {
    setIsShowDialogLoading(true)
    const payload = {
      ...data,
      images: _.map(uploadedImage, (image) => ({ imageType: "roomTypes", image: image.data_url })),
      facility: selectedFacility,
      room_sub_types: _.map(data?.room_sub_types, (each) => ({
        ...each,
        is_special_type: watch("is_special_type"),
      })),
    }

    console.log(payload)
    dispatch(actions.createRoomType(payload))
      .then(() => {
        dispatch(actions.getAllRoomTypes())
          .then(() => {
            history.push("/roomtypes")
          })
          .catch((err) => {
            setErrMessage(err)
            setIsError(true)
          })
          .finally(() => {
            setIsShowDialogLoading(false)
          })
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
      .finally(() => {
        setIsShowDialogLoading(false)
      })
  }

  useEffect(() => () => {}, [subTypeStack])

  const renderModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-sans">
          อัพโหลดรูปภาพ
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <ImageUpload images={uploadedImage} setImages={setUploadedImage} maxNumber={5} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="green">
            {t("general.save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
  return (
    <Layout categoryTitle={t("roomtype.title")} title={t("roomtype.createRoomType")}>
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      {renderModal()}
      <div className="p-4 w-full">
        <Alert
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
          isError={isError}
        />
        <DialogLoading isLoading={isShowDialogLoading} title="กำลังสร้างรูปแบบห้องพัก" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <RoomTypeForm
            handleDeletePhoto={onOpen}
            register={register}
            rerender={rerender}
            setRerender={setRerender}
            roomType={{}}
            selectedFacility={selectedFacility}
            setSelectedFacility={setSelectedFacility}
            setIsAddRoomModalOpen={onOpen}
            setSubtypeStack={setSubtypeStack}
            subTypeStack={subTypeStack}
            imageOnCreated={uploadedImage}
            control={control}
            watch={watch}
            setValue={setValue}
          />
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              {t("general.add")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
