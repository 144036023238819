import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../redux/actions"

// components
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"
import Sidebar from "../components/common/Sidebar/MainSidebar"

// views
import HistoryManagement from "../views/History/HistoryManagement"
import InfoHistoryReservation from "../views/History/InfoHistoryReservation"
import PaymentTransactions from "../views/History/PaymentTransactions"
import DailyPaymentTransactionList from "../views/History/DailyPaymentTransactionList"
import Dashboard from "../views/History/Dashboard"
import CheckoutReport from "../views/History/CheckoutReport"
import BookingReport from "../views/History/BookingReport"
import RoomReport from "../views/History/RoomReport"
import CheckInReport from "../views/History/CheckinReport"
import InhouseGuest from "../views/History/InhouseGuest"
import RoomTypeReservationSummary from "../views/Reservation/Room/RoomTypeReservationSummary"
import MonthyForecast from "../views/Reservation/Room/MonthyForecast"
import HouseKeeperReport from "../views/History/HouseKeeperReport"
import RoomReservationStatus from "../views/Reservation/Room/RoomReservationStatus"
import IncomingReport from "../views/History/IncomingReport"
import CustomReciptPrint from "../views/History/CustomReciptPrint"
import PaymentReport from "../views/History/PaymentReport"
import BillListReport from "../views/History/BillListReport"
import BillReport from "../views/History/BillReport"
import FoodAndBeverageReport from "../views/History/FoodAndBeverageReport"
import MenuReport from "../views/History/MenuReport"
import EditHistoryCheckoutDate from "../views/History/EditHistoryCheckoutDate"

import UserRole from "../config/UserRoles"
import { ModuleList } from "../config/constant/ModuleList"
import { HISTORY_MENU } from "../config/MenuList"

export default function HistoryLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("HistoryLayout : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.access?.[ModuleList.HISTORY.status_code] ||
        me?.role === UserRole.SUPER_USER.toString() // Reserved for some system
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar menuList={HISTORY_MENU} isShow={showSidebar} setIsShowSidebar={setIsShowSidebar} />
      <div className={` relative bg-gray-200 ${showSidebar ? "lg:ml-64" : ""} `}>
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/history" exact component={() => <Dashboard />} />
            <Route path="/history/dashboard" exact component={() => <Dashboard />} />
            <Route path="/history/management" exact component={() => <HistoryManagement />} />
            <Route path="/history/booking" exact component={() => <BookingReport />} />
            <Route path="/history/room" exact component={() => <RoomReport />} />
            <Route path="/history/view/:id" exact component={() => <InfoHistoryReservation />} />
            <Route path="/history/payment-transaction" exact component={() => <PaymentReport />} />
            <Route
              path="/history/daily-payment-summary"
              exact
              component={() => <DailyPaymentTransactionList />}
            />
            <Route path="/history/checkout-report" exact component={() => <CheckoutReport />} />
            <Route path="/history/checkin-report" exact component={() => <CheckInReport />} />
            <Route
              path="/history/daily-roomtype"
              exact
              component={() => <RoomTypeReservationSummary />}
            />
            <Route path="/history/daily-room" exact component={() => <RoomReservationStatus />} />
            <Route path="/history/monthy-forecast" exact component={() => <MonthyForecast />} />
            <Route path="/history/inhouse-guest" exact component={() => <InhouseGuest />} />
            <Route path="/history/housekeeper" exact component={() => <HouseKeeperReport />} />
            <Route path="/history/housekeeper" exact component={() => <HouseKeeperReport />} />
            <Route path="/history/incoming" exact component={() => <IncomingReport />} />
            <Route path="/history/custom-print/:id" exact component={() => <CustomReciptPrint />} />
            <Route path="/history/bill" exact component={() => <BillListReport />} />
            <Route path="/history/bill/detail/:id" exact component={() => <BillReport />} />
            <Route path="/history/menu" exact component={() => <MenuReport />} />
            <Route
              path="/history/checkout-date/edit/:id"
              exact
              component={() => <EditHistoryCheckoutDate />}
            />
            <Route
              path="/history/food-beverage"
              exact
              component={() => <FoodAndBeverageReport />}
            />
            <Redirect from="/history" to="/history" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
