/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import _ from "lodash"

import Layout from "../../../components/layout/BookingLayout"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import SpinnersLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import { BasicBookingInfoPanel } from "../../../components/Panels"
import { Alert } from "../../../components/common/Alert"
import { AttachServiceForm } from "../../../components/Forms/Reservation"
import { appConfig } from "../../../config"
import { TransactionType } from "../../../config/constant"

export default function AddServiceToReservation() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bookingInfo = useSelector((state) => state.bookings)
  const hotelServices = useSelector((state) => state.hotelService)
  const me = useSelector((state) => state.me)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const { register, control, handleSubmit, setValue, watch } = useForm()

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOneBooking(params?.id))
        .then(() => {
          dispatch(actions.getAllServices({ size: appConfig.maxFetchSize, page: 1 }))
        })
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        })
    }

    return () => {}
  }, [params])

  useEffect(() => {
    if (bookingInfo) {
      const serviceData = _.map(bookingInfo?.services, (eachService) => ({
        ...eachService,
        // eslint-disable-next-line no-underscore-dangle
        service: eachService?.service?._id,
      }))
      console.log("serviceData", serviceData)
      setValue("services", serviceData)
      setIsLoading(true)
    }

    return () => {}
  }, [bookingInfo])

  const handleSubmitService = async (data) => {
    try {
      const payload = {
        services: _.map(data.services, (eachService) => ({
          ...eachService,
          // eslint-disable-next-line no-underscore-dangle
          service_id: eachService?.service,
          room: eachService?.room !== "" ? eachService?.room : null,
        })),
        user: me?._id,
        transaction_type: TransactionType.ADD_SERVICE_BOOKING.status_code,
        transaction_group: TransactionType.ADD_SERVICE_BOOKING.group.status_code,
        info: `ปรับรายการบริการเป็น ${_.map(
          data.services,
          (eachService) => eachService?.service?.name || "",
        )}`,
      }

      console.log("Payload", payload)
      await dispatch(actions.editOneBooking(params.id, payload))
      history.goBack()
    } catch (error) {
      setAlertOpen(true)
      setAlertMessage(`${t("reservation.alertCannotAttachService")} ${error?.message}`)
    }
  }

  if (!isLoading) {
    return <SpinnersLoading />
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.addService")}>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setAlertOpen(false)}
        isError={alertOpen}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <WhitePanel>
          <BasicBookingInfoPanel showPrintingPanel={false} bookingInfo={bookingInfo} />
        </WhitePanel>
        <div className="my-2 w-full">
          <form onSubmit={handleSubmit(handleSubmitService)}>
            <AttachServiceForm
              control={control}
              register={register}
              hotelServices={hotelServices}
              setValue={setValue}
              watch={watch}
              defaultValue={bookingInfo?.services}
              booking={bookingInfo}
            />
            <Button isFullWidth colorScheme="purple" type="submit">
              {t("general.save")}
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  )
}
