import React from "react"
import { Input, DatePicker } from "antd"
import { Controller } from "react-hook-form"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

function PurchaseOrderInfoForm({ control, defaultValue }) {
  const { TextArea } = Input
  const { i18n } = useTranslation()
  return (
    <div className="flex flex-wrap ">
      <div className="w-full md:w-1/4 p-2">
        <label>วันที่สั่งซื้อ</label>
        <Controller
          name="date"
          control={control}
          defaultValue={defaultValue?.date || new Date()}
          render={(field) => (
            <DatePicker
              className="my-2"
              onChange={(date) => field.onChange(dayjs(date).toDate())}
              value={dayjs(field.value)}
              format={"D/MM/YYYY"}
              locale={i18n.language}
              allowClear={false}
              style={{ width: "100%" }}
            />
          )}
        />
      </div>
      <div className="w-full md:w-3/4 p-2">
        <label>หมายเหตุคำสั่งซื้อ</label>
        <Controller
          name="remark"
          control={control}
          defaultValue={defaultValue?.remark}
          render={(field) => (
            <TextArea className="my-2" {...field} placeholder="หมายเหตุคำสั่งซื้อ" />
          )}
        />
      </div>
    </div>
  )
}

export default PurchaseOrderInfoForm
