import FrontpageTemplate1 from "./FrontpageTemplate1.json"

const FrontpageTemplate = [
  {
    name: "Template 1",
    component: FrontpageTemplate1,
  },
]

export default FrontpageTemplate
