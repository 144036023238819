import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"

import { MenuForm } from "../../components"
import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"

export default function EditMenu() {
  const dispatch = useDispatch()
  const history = useHistory()
  const info = useSelector((state) => state.information)
  const toast = useToast()
  const { register, handleSubmit, watch, setValue, control } = useForm({
    defaultValues: {},
  })

  const [isLoading, setIsLoading] = useState(false)
  const [selectmenutype, setSelectmenutype] = useState(false)

  useEffect(() => {
    dispatch(actions.getInformation({ notFilterMenu: true })).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  const onSubmit = (data) => {
    //   console.log("Menu Data", data)
    const payload = { menu: { ...data.menu } }
    console.log("Menu payload", payload)

    dispatch(actions.editOneInformation(info?.id, payload))
      .then(() => {
        toast({
          title: "แก้ไขสำเร็จ",
          description: "การแก้ไขรายการเมนูสำเร็จ",
          status: "success",
        })
        dispatch(actions.getInformation({ notFilterMenu: true })).then(() => {})
      })
      .catch((err) => {
        toast({ status: "error", title: "แก้ไขไม่สำเร็จ", description: err?.message })
      })
  }

  useEffect(() => {
    if (isLoading) {
      setValue("menu.menuList", info?.menu?.menuList)
      console.log("Info.Menu.MenuList", info?.menu?.menuList)
      setValue("menu.menuType", info?.menu?.menuType)
      return () => {}
    }
  }, [info, isLoading])

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ตั้งค่าเมนูหลัก">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <MenuForm
              control={control}
              defaultValue={info}
              register={register}
              setValue={setValue}
              selectmenutype={selectmenutype}
              setSelectmenutype={setSelectmenutype}
              watch={watch}
            />
          ) : (
            <div></div>
          )}
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
