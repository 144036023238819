import React from "react"

import { Link } from "react-router-dom"

// components
import { Button, Alert } from "@chakra-ui/react"

import Layout from "../../../components/layout/GeneralLayout"
import { EmailTest, FindBookingTest, SMSTest } from "../../../components/Forms/Management"

export default function ManagementSystem() {
  return (
    <Layout categoryTitle="จัดการระบบ" title="Developer Sandbox">
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="w-full px-4 ">
        <Alert className="my-4 rounded-md text-sm" colorScheme="yellow">
          <i className="fas fa-info-circle mr-2" /> หน้านี้มีไว้สำหรับนักพัฒนาในการทดสอบระบบเท่านั้น
        </Alert>
        <EmailTest />
        <SMSTest />
        <FindBookingTest />
      </div>
    </Layout>
  )
}
