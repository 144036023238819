/* eslint-disable no-underscore-dangle */
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { Radio, InputNumber, Alert, Button as AntdButton } from "antd"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { SingleImageUpload } from "../../ImageUpload"

import { PaymentMethod, paymentStatus, TransactionType } from "../../../config/constant"

function PaymentForm({ handleCreatePayment, booking }) {
  const { i18n, t } = useTranslation()
  const { control, setValue, watch } = useForm()
  const me = useSelector((state) => state.me)

  const handleHalfPayment = () => {
    const payload = {
      paymentStatus: paymentStatus.deposit.depositConfirm,
      payment: {
        ...booking?.payment,
        paid_amount: booking?.price / 2,
        payment_slip: watch("image") ? { image: watch("image") } : null,
      },
      transaction_type: TransactionType.CREATE_PAYMENT.status_code,
      transaction_group: TransactionType.CREATE_PAYMENT.group.status_code,
      price: booking?.price / 2 - booking?.payment?.paid_amount,
      info: `ทำการชำระเงิน จากเดิมชำระไปแล้ว ${booking?.payment?.paid_amount || 0} บาท ชำระเพิ่ม ${
        booking?.price / 2 - booking?.payment?.paid_amount
      } 
      บาท รวมเป็นรายการนี้ชำระแล้ว ${booking?.price / 2} บาท
      `,
      payment_method: watch("payment_method"),
      user: me?._id,
      item_price: booking?.price / 2 - booking?.payment?.paid_amount,
    }

    handleCreatePayment(payload)
  }

  const handleFullPayment = () => {
    const payload = {
      paymentStatus: paymentStatus.allPayment.allPaymentConfirm,
      payment: {
        ...booking?.payment,
        paid_amount: booking?.price,
        payment_slip: watch("image") ? { image: watch("image") } : null,
      },
      transaction_type: TransactionType.CREATE_PAYMENT.status_code,
      transaction_group: TransactionType.CREATE_PAYMENT.group.status_code,
      price: booking?.price - booking?.payment?.paid_amount,
      info: `ทำการชำระเงิน จากเดิมชำระไปแล้ว ${booking?.payment?.paid_amount || 0} บาท ชำระเพิ่ม ${
        booking?.price - booking?.payment?.paid_amount
      } 
      บาท รวมเป็นรายการนี้ชำระแล้ว ${booking?.price} บาท
      `,
      payment_method: watch("payment_method"),
      user: me?._id,
      item_price: booking?.price - booking?.payment?.paid_amount,
    }
    handleCreatePayment(payload)
  }

  const handleCustomPayment = () => {
    const payload = {
      paymentStatus: watch("status"),
      payment: {
        ...booking?.payment,
        paid_amount: (booking?.payment?.paid_amount || 0) + watch("amount"),
        payment_slip: watch("image") ? { image: watch("image") } : null,
      },
      transaction_type: TransactionType.CREATE_PAYMENT.status_code,
      transaction_group: TransactionType.CREATE_PAYMENT.group.status_code,
      price: booking?.payment?.paid_amount + watch("amount"),
      item_price: watch("amount"),
      info: `ทำการชำระเงิน จากเดิมชำระไปแล้ว ${
        booking?.payment?.paid_amount || 0
      } บาท ชำระเพิ่ม ${watch("amount")}   บาท รวมเป็นรายการนี้ชำระแล้ว ${
        booking?.payment?.paid_amount || 0 + watch("amount")
      } บาท
      `,
      payment_method: watch("payment_method"),
      user: me?._id,
    }
    handleCreatePayment(payload)
  }

  return (
    <div>
      {!localStorage.getItem("DONT_SHOW_NEW_PAYMENT_METHOD") && (
        <div className="w-full">
          <Alert
            message="ระบบรับชำระเงินแบบใหม่"
            showIcon
            type="info"
            closable
            description="เลือกช่องทางการชำระเงิน อัพโหลดสลิป(ถ้ามี) จากนั้นเลือกได้ว่าจะดำเนินการแบบเร็ว คือเลือกชำระเงิน 50% หรือเต็มจำนวนเลย หรือจะเลือกจำนวนเงินเอง และสถานะหลังการชำระเงินเอง จากนั้นกดชำระเงินก็ได้ ข้อมูลการชำระเงินทุกอย่างจะถูกบันทึกเอาไว้ ง่ายต่อการตรวจสอบมากขึ้น"
            action={
              <AntdButton
                size="small"
                onClick={() => {
                  localStorage.setItem("DONT_SHOW_NEW_PAYMENT_METHOD", true)
                }}
              >
                ไม่ต้องแสดงอีก
              </AntdButton>
            }
          />
        </div>
      )}
      <h6 className="font-bold mt-2">{t("reservation.paymentMethod")}</h6>
      <div className="my-2">
        <Controller
          control={control}
          name="payment_method"
          defaultValue={PaymentMethod.BANK_TRANSFER.status_code}
          render={(field) => (
            <Radio.Group
              {...field}
              optionType="button"
              size="large"
              buttonStyle="solid"
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value)
              }}
            >
              {_.map(PaymentMethod, (eachMethod, index) => (
                <Radio.Button
                  value={eachMethod.status_code}
                  key={index}
                  style={{ borderRadius: "0" }}
                >
                  {i18n?.language === "th" ? eachMethod.description : eachMethod.descriptionEN}
                </Radio.Button>
              ))}
            </Radio.Group>
          )}
        />
        <h6 className="font-bold mt-4 my-1">
          หลักฐานการชำระเงิน <span className="font-normal">(ถ้ามี)</span>
        </h6>
        <div>
          <Controller
            control={control}
            name="image"
            render={(field) => <SingleImageUpload image={field.value} setImage={field.onChange} />}
          />
        </div>
        <h6 className="font-bold mt-2">ดำเนินการแบบเร็ว</h6>
        <div className="flex gap-2 my-2">
          <Button
            size="sm"
            height="20"
            variant="outline"
            colorScheme="blue"
            onClick={() => {
              handleHalfPayment()
            }}
            disabled={
              booking?.paymentStatus !== paymentStatus.deposit.depositPay &&
              booking?.paymentStatus !== paymentStatus.deposit.depositWait
            }
          >
            <p className="p-2">
              {" "}
              {t("reservation.payment")} / {t("general.confirm")}
              <br />
              {t("reservation.price.deposit50")}
            </p>
          </Button>{" "}
          <Button
            size="sm"
            height="20"
            colorScheme="blue"
            variant="outline"
            onClick={() => {
              handleFullPayment()
            }}
            disabled={booking?.paymentStatus === paymentStatus.allPayment.allPaymentConfirm}
          >
            <p className="p-2"> {t("reservation.price.payFull")} </p>
          </Button>
        </div>{" "}
        <hr className="my-2 border-blue-400" />
        <h6 className="font-bold mt-4 my-1">ดำเนินการแบบละเอียด</h6>
        <div className="w-full ">
          <Controller
            control={control}
            name="amount"
            render={(field) => (
              <InputNumber
                addonBefore="จำนวนเงิน"
                {...field}
                onChange={(value) => {
                  field.onChange(value)
                  if (booking?.paymentStatus === paymentStatus.deposit.depositConfirm) {
                    if (value + booking?.payment?.paidAmount >= booking?.price) {
                      setValue("status", paymentStatus.allPayment.allPaymentConfirm)
                    } else {
                      setValue("status", paymentStatus.deposit.depositConfirm)
                    }
                  }
                }}
                size="large"
                style={{ width: "100%" }}
              />
            )}
          />{" "}
          <h6 className="font-bold mt-4 my-1">
            สถานะการชำระเงิน <span className="font-normal">(หลังจากชำระเงิน)</span>
          </h6>
          <div className="my-2">
            <Controller
              control={control}
              name="status"
              defaultValue={
                booking?.paymentStatus === paymentStatus.deposit.depositWait
                  ? paymentStatus.deposit.depositWait
                  : paymentStatus.deposit.depositConfirm
              }
              render={(field) => (
                <Radio.Group
                  {...field}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => {
                    field.onChange(e.target.value)
                  }}
                  value={field.value}
                >
                  <Radio.Button
                    value={paymentStatus.deposit.depositWait}
                    style={{ borderRadius: "0" }}
                  >
                    {paymentStatus.deposit.depositWait}
                  </Radio.Button>
                  <Radio.Button
                    value={paymentStatus.deposit.depositConfirm}
                    style={{ borderRadius: "0" }}
                  >
                    {paymentStatus.deposit.depositConfirm}
                  </Radio.Button>
                  <Radio.Button
                    value={paymentStatus.allPayment.allPaymentConfirm}
                    style={{ borderRadius: "0" }}
                  >
                    {paymentStatus.allPayment.allPaymentConfirm}
                  </Radio.Button>
                </Radio.Group>
              )}
            />
          </div>
        </div>
        <div className=" my-2 w-full justify-end flex">
          <Button
            onClick={() => {
              handleCustomPayment()
            }}
            colorScheme="purple"
          >
            ชำระเงิน
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PaymentForm
