import React from "react"
import _ from "lodash"
import { WhitePanel } from "../../common/Cards"

function GridGallery() {
  const arrayOfSix = new Array(6)
  return (
    <div>
      <WhitePanel>
        <div className="grid gap-2 grid-cols-3">
          {_.map(arrayOfSix, (each, index) => (
            <div key={index} className="p-4 flex justify-center bg-gray-100 py-10">
              <div>
                <i className="far fa-image text-4xl"></i>
              </div>
            </div>
          ))}
        </div>
      </WhitePanel>
    </div>
  )
}

export default GridGallery
