import {
  TRANSACTION_ALL,
  TRANSACTION_CREATE,
  TRANSACTION_DELETE,
  TRANSACTION_GET,
  TRANSACTION_UPDATE,
  TRANSACTION_ERROR,
} from "../../actions/types"


const initialState = {
  transaction: null,
  isLoading: false,
}
export default function TransactionReducer(state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        total: action?.payload?.total,
        analyzed: action?.payload?.analyzed,
        summarized: action?.payload?.summarized,
      }
    case TRANSACTION_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case TRANSACTION_CREATE:
      return { isLoading: false }
    case TRANSACTION_UPDATE:
      return { isLoading: false }
    case TRANSACTION_DELETE:
      return { isLoading: false }
    case TRANSACTION_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
