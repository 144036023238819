// common type
export const ME_GET = "ME_GET"
export const ME_RESET = "ME_RESET"

export const USER_ALL = "USER_ALL"
export const USER_GET = "USER_GET"
export const USER_POST = "USER_POST"
export const USER_PUT = "USER_PUT"
export const USER_DEL = "USER_DEL"

export const SYSTEM_GET = "SYSTEM_GET"
export const SYSTEM_POST = "SYSTEM_POST"
export const SYSTEM_PUT = "SYSTEM_PUT"
export const SYSTEM_DEL = "SYSTEM_DEL"

// feature type

export const ROOMTYPE_ALL = "ROOMTYPE_ALL"
export const ROOMTYPE_CREATE = "ROOMTYPE_CREATE"
export const ROOMTYPE_GET = "ROOMTYPE_GET"
export const ROOMTYPE_PUT = "ROOMTYPE_PUT"
export const ROOMTYPE_DELETE = "ROOMTYPE_DELETE"

export const BED_ALL = "BED_ALL"
export const BED_GET = "BED_GET"
export const BED_CREATE = "BED_CREATE"
export const BED_PUT = "BED_PUT"
export const BED_DELETE = "BED_DELETE"

export const SUB_ROOMTYPE_ALL = "SUB_ROOMTYPE_ALL"
export const SUB_ROOMTYPE_GET = "SUB_ROOMTYPE_GET"
export const SUB_ROOMTYPE_FROM_TYPE = "SUB_ROOMTYPE_FROM_TYPE"
export const SUB_ROOMTYPE_CREATE = "SUB_ROOMTYPE_CREATE"
export const SUB_ROOMTYPE_PUT = "SUB_ROOMTYPE_PUT"
export const SUB_ROOMTYPE_DELETE = "SUB_ROOMTYPE_DELETE"
export const SUB_ROOMTYPE_ERROR = "SUB_ROOMTYPE_ERROR"

export const ROOM_ALL = "ROOM_ALL"
export const ROOM_GET = "ROOM_GET"
export const ROOM_ALL_FROM_ROOMTYPE = "ROOM_ALL_FROM_ROOMTYPE"
export const ROOM_ALL_VACANT = "ROOM_ALL_VACANT"
export const ROOM_CREATE = "ROOM_CREATE"
export const ROOM_PUT = "ROOM_PUT"
export const ROOM_DELETE = "ROOM_DELETE"

export const ARTICLE_ALL = "ARTICLE_ALL"
export const ARTICLE_CREATE = "ARTICLE_CREATE"
export const ARTICLE_GET = "ARTICLE_GET"
export const ARTICLE_PUT = "ARTICLE_PUT"
export const ARTICLE_DELETE = "ARTICLE_DELETE"

export const PROMOTION_ALL = "PROMOTION_ALL"
export const PROMOTION_GET = "PROMOTION_GET"
export const PROMOTION_CREATE = "PROMOTION_CREATE"
export const PROMOTION_PUT = "PROMOTION_PUT"
export const PROMOTION_DELETE = "PROMOTION_DELETE"

export const FACILITY_ALL = "FACILITY_ALL"
export const FACILITY_GET = "FACILITY_GET"
export const FACILITY_CREATE = "FACILITY_CREATE"
export const FACILITY_PUT = "FACILITY_PUT"
export const FACILITY_DELETE = "FACILITY_DELETE"

export const INFO_GET = "INFO_GET"
export const INFO_PUT = "INFO_PUT"
export const INFO_CREATE = "INFO_CREATE"

export const BACKGROUND_GALLERY_ALL = "BACKGROUND_GALLERY_ALL"
export const BACKGROUND_GALLERY_GET = "BACKGROUND_GALLERY_GET"
export const BACKGROUND_GALLERY_CREATE = "BACKGROUND_GALLERY_CREATE"
export const BACKGROUND_GALLERY_PUT = "BACKGROUND_GALLERY_PUT"
export const BACKGROUND_GALLERY_DELETE = "BACKGROUND_GALLERY_DELETE"

export const GALLERY_ALL = "GALLERY_ALL"
export const GALLERY_GET = "GALLERY_GET"
export const GALLERY_CREATE = "GALLERY_CREATE"
export const GALLERY_PUT = "GALLERY_PUT"
export const GALLERY_DELETE = "GALLERY_DELETE"

export const PROMPTPAY_ALL = "PROMPTPAY_ALL"
export const PROMPTPAY_GET = "PROMPTPAY_GET"
export const PROMPTPAY_CREATE = "PROMPTPAY_CREATE"
export const PROMPTPAY_PUT = "PROMPTPAY_PUT"
export const PROMPTPAY_DELETE = "PROMPTPAY_DELETE"

export const BOOKING_ALL = "BOOKING_ALL"
export const BOOKING_GET = "BOOKING_GET"
export const BOOKING_CREATE = "BOOKING_CREATE"
export const BOOKING_UPDATE = "BOOKING_UPDATE"
export const BOOKING_DELETE = "BOOKING_DELETE"
export const BOOKING_RESET = "BOOKING_RESET"

export const CUSTOMER_ALL = "CUSTOMER_ALL"
export const CUSTOMER_GET = "CUSTOMER_GET"
export const CUSTOMER_CREATE = "CUSTOMER_CREATE"
export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE"
export const CUSTOMER_DELETE = "CUSTOMER_DELETE"

export const RELATIONSHIP_ALL = "RELATIONSHIP_ALL"
export const RELATIONSHIP_GET = "RELATIONSHIP_GET"
export const RELATIONSHIP_CREATE = "RELATIONSHIP_CREATE"
export const RELATIONSHIP_UPDATE = "RELATIONSHIP_UPDATE"
export const RELATIONSHIP_DELETE = "RELATIONSHIP_DELETE"

export const LOG_ALL = "LOG_ALL"
export const LOG_GET = "LOG_GET"
export const LOG_CREATE = "LOG_CREATE"
export const LOG_UPDATE = "LOG_UPDATE"
export const LOG_DELETE = "LOG_DELETE"

export const SERVICE_ALL = "SERVICE_ALL"
export const SERVICE_GET = "SERVICE_GET"
export const SERVICE_CREATE = "SERVICE_CREATE"
export const SERVICE_UPDATE = "SERVICE_UPDATE"
export const SERVICE_DELETE = "SERVICE_DELETE"

export const EMPLOYEE_ALL = "EMPLOYEE_ALL"
export const EMPLOYEE_GET = "EMPLOYEE_GET"
export const EMPLOYEE_CREATE = "EMPLOYEE_CREATE"
export const EMPLOYEE_UPDATE = "EMPLOYEE_UPDATE"
export const EMPLOYEE_DELETE = "EMPLOYEE_DELETE"
export const EMPLOYEE_ERROR = "EMPLOYEE_ERROR"

export const FB_ORDER_ALL = "FB_ORDER_ALL"
export const FB_ORDER_GET = "FB_ORDER_GET"
export const FB_ORDER_CREATE = "FB_ORDER_CREATE"
export const FB_ORDER_UPDATE = "FB_ORDER_UPDATE"
export const FB_ORDER_DELETE = "FB_ORDER_DELETE"
export const FB_ORDER_ERROR = "FB_ORDER_ERROR"

export const MEETING_ROOM_ALL = "MEETING_ROOM_ALL"
export const MEETING_ROOM_GET = "MEETING_ROOM_GET"
export const MEETING_ROOM_CREATE = "MEETING_ROOM_CREATE"
export const MEETING_ROOM_UPDATE = "MEETING_ROOM_UPDATE"
export const MEETING_ROOM_DELETE = "MEETING_ROOM_DELETE"
export const MEETING_ROOM_ERROR = "MEETING_ROOM_ERROR"

export const TRANSACTION_ALL = "TRANSACTION_ALL"
export const TRANSACTION_GET = "TRANSACTION_GET"
export const TRANSACTION_CREATE = "TRANSACTION_CREATE"
export const TRANSACTION_UPDATE = "TRANSACTION_UPDATE"
export const TRANSACTION_DELETE = "TRANSACTION_DELETE"
export const TRANSACTION_ERROR = "TRANSACTION_ERROR"

export const SERVICE_ORDER_ALL = "SERVICE_ORDER_ALL"
export const SERVICE_ORDER_GET = "SERVICE_ORDER_GET"
export const SERVICE_ORDER_CREATE = "SERVICE_ORDER_CREATE"
export const SERVICE_ORDER_UPDATE = "SERVICE_ORDER_UPDATE"
export const SERVICE_ORDER_DELETE = "SERVICE_ORDER_DELETE"
export const SERVICE_ORDER_ERROR = "SERVICE_ORDER_ERROR"

export const MEETING_ROOM_ORDER_ALL = "MEETING_ROOM_ORDER_ALL"
export const MEETING_ROOM_ORDER_GET = "MEETING_ROOM_ORDER_GET"
export const MEETING_ROOM_ORDER_CREATE = "MEETING_ROOM_ORDER_CREATE"
export const MEETING_ROOM_ORDER_UPDATE = "MEETING_ROOM_ORDER_UPDATE"
export const MEETING_ROOM_ORDER_DELETE = "MEETING_ROOM_ORDER_DELETE"
export const MEETING_ROOM_ORDER_ERROR = "MEETING_ROOM_ORDER_ERROR"

export const FRONTPAGE_COMPONENT_ALL = "FRONTPAGE_COMPONENT_ALL"
export const FRONTPAGE_COMPONENT_GET = "FRONTPAGE_COMPONENT_GET"
export const FRONTPAGE_COMPONENT_CREATE = "FRONTPAGE_COMPONENT_CREATE"
export const FRONTPAGE_COMPONENT_UPDATE = "FRONTPAGE_COMPONENT_UPDATE"
export const FRONTPAGE_COMPONENT_DELETE = "FRONTPAGE_COMPONENT_DELETE"
export const FRONTPAGE_COMPONENT_ERROR = "FRONTPAGE_COMPONENT_ERROR"

export const TAX_INVOICE_ALL = "TAX_INVOICE_ALL"
export const TAX_INVOICE_GET = "TAX_INVOICE_GET"
export const TAX_INVOICE_CREATE = "TAX_INVOICE_CREATE"
export const TAX_INVOICE_UPDATE = "TAX_INVOICE_UPDATE"
export const TAX_INVOICE_DELETE = "TAX_INVOICE_DELETE"
export const TAX_INVOICE_ERROR = "TAX_INVOICE_ERROR"

export const FILE_ALL = "FILE_ALL"
export const FILE_GET = "FILE_GET"
export const FILE_POST = "FILE_POST"
export const FILE_PUT = "FILE_PUT"
export const FILE_DEL = "FILE_DEL"
export const FILE_ERROR = "FILE_ERROR"
export const FILE_LOADING = "FILE_LOADING"

export const IMAGE_ALL = "IMAGE_ALL"
export const IMAGE_GET = "IMAGE_GET"
export const IMAGE_CREATE = "IMAGE_CREATE"
export const IMAGE_UPDATE = "IMAGE_UPDATE"
export const IMAGE_DELETE = "IMAGE_DELETE"
export const IMAGE_ERROR = "IMAGE_ERROR"
export const IMAGE_LOADING = "IMAGE_LOADING"

export const ARTICLE_CATEGORY_ALL = "ARTICLE_CATEGORY_ALL"
export const ARTICLE_CATEGORY_GET = "ARTICLE_CATEGORY_GET"
export const ARTICLE_CATEGORY_CREATE = "ARTICLE_CATEGORY_CREATE"
export const ARTICLE_CATEGORY_UPDATE = "ARTICLE_CATEGORY_UPDATE"
export const ARTICLE_CATEGORY_DELETE = "ARTICLE_CATEGORY_DELETE"
export const ARTICLE_CATEGORY_ERROR = "ARTICLE_CATEGORY_ERROR"
export const ARTICLE_CATEGORY_LOADING = "ARTICLE_CATEGORY_LOADING"

export const CHILL_PAY_GET = "CHILL_PAY_GET"
export const CHILL_PAY_ERROR = "CHILL_PAY_ERROR"

export const BILL_ALL = "BILL_ALL"
export const BILL_GET = "BILL_GET"
export const BILL_CREATE = "BILL_CREATE"
export const BILL_UPDATE = "BILL_UPDATE"
export const BILL_DELETE = "BILL_DELETE"
export const BILL_ERROR = "BILL_ERROR"
export const BILL_LOADING = "BILL_LOADING"

export const MENU_CATEGORY_ALL = "MENU_CATEGORY_ALL"
export const MENU_CATEGORY_GET = "MENU_CATEGORY_GET"
export const MENU_CATEGORY_CREATE = "MENU_CATEGORY_CREATE"
export const MENU_CATEGORY_UPDATE = "MENU_CATEGORY_UPDATE"
export const MENU_CATEGORY_DELETE = "MENU_CATEGORY_DELETE"
export const MENU_CATEGORY_ERROR = "MENU_CATEGORY_ERROR"
export const MENU_CATEGORY_LOADING = "MENU_CATEGORY_LOADING"

export const MENU_ALL = "MENU_ALL"
export const MENU_GET = "MENU_GET"
export const MENU_CREATE = "MENU_CREATE"
export const MENU_UPDATE = "MENU_UPDATE"
export const MENU_DELETE = "MENU_DELETE"
export const MENU_ERROR = "MENU_ERROR"
export const MENU_LOADING = "MENU_LOADING"

export const TABLE_ALL = "TABLE_ALL"
export const TABLE_GET = "TABLE_GET"
export const TABLE_CREATE = "TABLE_CREATE"
export const TABLE_UPDATE = "TABLE_UPDATE"
export const TABLE_DELETE = "TABLE_DELETE"
export const TABLE_ERROR = "TABLE_ERROR"
export const TABLE_LOADING = "TABLE_LOADING"

export const FB_LIST_ALL = "FB_LIST_ALL"
export const FB_LIST_GET = "FB_LIST_GET"
export const FB_LIST_CREATE = "FB_LIST_CREATE"
export const FB_LIST_UPDATE = "FB_LIST_UPDATE"
export const FB_LIST_DELETE = "FB_LIST_DELETE"
export const FB_LIST_ERROR = "FB_LIST_ERROR"
export const FB_LIST_LOADING = "FB_LIST_LOADING"

export const MATERIAL_ALL = "MATERIAL_ALL"
export const MATERIAL_GET = "MATERIAL_GET"
export const MATERIAL_CREATE = "MATERIAL_CREATE"
export const MATERIAL_UPDATE = "MATERIAL_UPDATE"
export const MATERIAL_DELETE = "MATERIAL_DELETE"
export const MATERIAL_ERROR = "MATERIAL_ERROR"
export const MATERIAL_LOADING = "MATERIAL_LOADING"

export const PURCHASE_ORDER_ALL = "PURCHASE_ORDER_ALL"
export const PURCHASE_ORDER_GET = "PURCHASE_ORDER_GET"
export const PURCHASE_ORDER_CREATE = "PURCHASE_ORDER_CREATE"
export const PURCHASE_ORDER_UPDATE = "PURCHASE_ORDER_UPDATE"
export const PURCHASE_ORDER_DELETE = "PURCHASE_ORDER_DELETE"
export const PURCHASE_ORDER_ERROR = "PURCHASE_ORDER_ERROR"
export const PURCHASE_ORDER_LOADING = "PURCHASE_ORDER_LOADING"
