/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

// components
import Sidebar from "../components/common/Sidebar/MainSidebar"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"

// views
import RoomManagement from "../views/room/RoomManagement/RoomManagement"
import CreateRoomType from "../views/room/RoomTypeManagement/CreateRoomType"
import EditRoomInfo from "../views/room/RoomTypeManagement/EditRoomTypes"
import RoomTypeInfo from "../views/room/RoomTypeManagement/RoomTypeInfo"
// import BedManagements from "../views/room/BedManagement/BedManagement"
import RoomTypeManagement from "../views/room/RoomTypeManagement/RoomTypeManagement"
import ServiceManagement from "../views/room/ServiceManagement/ServiceManagement"
import EditService from "../views/room/ServiceManagement/EditService"
import CreateService from "../views/room/ServiceManagement/CreateService"
import MeetingRoomManagement from "../views/room/MeetingRoomManagement/MeetingRoomManagement"
import CreateMeetingRoom from "../views/room/MeetingRoomManagement/CreateMeetingRoom"
import EditMeetingRoom from "../views/room/MeetingRoomManagement/EditMeetingRoom"
import * as actions from "../redux/actions"
import { ModuleList } from "../config/constant/ModuleList"
import { ROOMTYPE_MENU } from "../config/MenuList"

import UserRole from "../config/UserRoles"

export default function RoomsLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("RoomsLayout : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.access?.[ModuleList.ROOM_TYPES.status_code] ||
        me?.role === UserRole.SUPER_USER.toString() // Reserved for some system
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar menuList={ROOMTYPE_MENU} isShow={showSidebar} setIsShowSidebar={setIsShowSidebar} />
      <div className=" relative bg-gray-200 lg:ml-64">
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/roomtypes" exact component={() => <RoomTypeManagement />} />
            <Route path="/roomtypes/create" exact component={() => <CreateRoomType />} />
            {/* <Route path="/roomtypes/beds" exact component={() => <BedManagements />} /> */}
            <Route path="/roomtypes/rooms" exact component={() => <RoomManagement />} />
            <Route path="/roomtypes/view/:id" exact component={() => <RoomTypeInfo />} />
            <Route path="/roomtypes/edit/:id" exact component={() => <EditRoomInfo />} />
            <Route path="/roomtypes/service/" exact component={() => <ServiceManagement />} />
            <Route path="/roomtypes/service/create" exact component={() => <CreateService />} />
            <Route
              path="/roomtypes/meeting-room/"
              exact
              component={() => <MeetingRoomManagement />}
            />
            <Route
              path="/roomtypes/meeting-room/create"
              exact
              component={() => <CreateMeetingRoom />}
            />
            <Route
              path="/roomtypes/meeting-room/edit/:id"
              exact
              component={() => <EditMeetingRoom />}
            />
            <Route path="/roomtypes/service/edit/:id" exact component={() => <EditService />} />
            <Redirect from="/roomtypes" to="/roomtypes" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
