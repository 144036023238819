import api from "../../../config/api"

import { CHILL_PAY_ERROR, CHILL_PAY_GET } from "../types"

export const resetChillPay = () => (dispatch) => {
  dispatch({
    type: CHILL_PAY_GET,
    payload: {},
    ready: false,
  })
}

// eslint-disable-next-line import/prefer-default-export
export const getChillPay =
  ({
    MerchantCode = "M034407",
    OrderNo = "00000001",
    CustomerId = "123456",
    Amount = "10000",
    PhoneNumber = "0889999999",
    Description = "TestPayment",
    ChannelCode = "",
    Currency = "764",
    LangCode = "TH",
    RouteNo = "1",
    IPAddress = "171.102.165.165",
    ApiKey = "BWRymXxfv6h0y2Th9WRFRConoTU30BUJIkdZQdzT1seWvuCPqxSCZ9VPfIUHIdyr",
    TokenFlag = "",
    CreditToken = "",
    CreditMonth = "",
    ShopID = "",
    ProductImageUrl = "",
    CustEmail = "thanachit02185@gmail.com",
    CardType = "",
    // CheckSum = "702fc6356b1261dc8edfcf6ebed8263d",
  }) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/chill-pay?MerchantCode=${MerchantCode}&OrderNo=${OrderNo}&CustomerId=${CustomerId}&Amount=${Amount}&PhoneNumber=${PhoneNumber}&Description=${Description}&ChannelCode=${ChannelCode}&Currency=${Currency}&LangCode=${LangCode}&RouteNo=${RouteNo}&IPAddress=${IPAddress}&ApiKey=${ApiKey}&TokenFlag=${TokenFlag}&CreditToken=${CreditToken}&CreditMonth=${CreditMonth}&ShopID=${ShopID}&ProductImageUrl=${ProductImageUrl}&CustEmail=${CustEmail}&CardType=${CardType}`,
      )
      .then((res) => {
        console.log("Request Server to Get One Bed", res.data)
        if (res.data) {
          dispatch({ type: CHILL_PAY_GET, payload: res.data, ready: true })
        } else {
          dispatch({ type: CHILL_PAY_ERROR, payload: null })
        }
      })
  }
