import React from "react"
import MenuPanel from "../../Panels/MenuPanel"

function Type3Homepage({ me, system }) {
  return (
    <div>
      <section className="container mx-auto items-center  mt-20  min-h-screen font-sans">
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-1/3 ">
            <div className="w-full flex justify-center  items-center md:justify-end">
              <img src={system?.logo?.url} alt="logo" className="h-24" />
            </div>

            <div className="mt-6 w-full text-xl text-center md:text-right font-semibold">
              {system?.systemOwner}
            </div>
            {system?.systemSponsor && (
              <div className="w-full my-1 text-center md:text-right">
                ระบบนี้ได้รับการสนุบสนุนโดย {system?.systemSponsor}
              </div>
            )}
          </div>
          <div className="w-full lg:w-2/3">
            <div className="w-full md:w-3/4 px-4 mr-auto ml-auto">
              <MenuPanel me={me} system={system} cols={2} />
            </div>
          </div>
        </div>
        <div></div>
      </section>
    </div>
  )
}

export default Type3Homepage
