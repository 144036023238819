export const ComponentHeightVideoComponent = {
  ONE_HALF: {
    status_code: "ONE_HALF",
    description: "1 ใน 2 ของหน้าจอ",
    descriptionEn: "1/2 of Screen",
  },
  ONE_THIRD: {
    status_code: "ONE_THIRD",
    description: "1 ใน 3 ของหน้าจอ",
    descriptionEn: "1/3 of Screen",
  },
  TWO_THIRD: {
    status_code: "TWO_THIRD",
    description: "2 ใน 3 ของหน้าจอ",
    descriptionEn: "2/3 of Screen",
  },
  THIRD_FOUR: {
    status_code: "THIRD_FOUR",
    description: "3 ใน 4 ของหน้าจอ",
    descriptionEn: "3/4 of Screen",
  },
  FULL_SCREEN: {
    status_code: "FULL_SCREEN",
    description: "เต็มหน้าจอ",
    descriptionEn: "Full Screen",
  },
  FULL_MOBILE_SCREEN: {
    status_code: "FULL_MOBILE_SCREEN",
    description: "เต็มหน้าจอ Mobile",
    descriptionEn: "Full mobile Screen",
  },
  FULL_IPAD_SCREEN: {
    status_code: "FULL_IPAD_SCREEN",
    description: "เต็มหน้าจอ Screen",
    descriptionEn: "Full Screen",
  },
}

export default ComponentHeightVideoComponent
