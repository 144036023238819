import React from "react"
import MenuPanel from "../../Panels/MenuPanel"

function Type2Homepage({ me, system }) {
  return (
    <div>
      <section className="container mx-auto items-center  mt-20  min-h-screen font-sans">
        <div>
          <div className="flex flex-wrap mx-auto justify-center ">
            <div className="w-full md:w-1/6 flex justify-center items-center">
              <img src={system?.logo?.url} alt="logo" className="h-24" />
            </div>
            <div className="w-full md:w-5/12 flex flex-wrap justify-center">
              <div className="mt-4 w-full text-xl font-semibold">{system?.systemOwner}</div>
              <div className="w-full my-1">ระบบนี้ได้รับการสนุบสนุนโดย {system?.systemSponsor}</div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/4 px-4 mr-auto ml-auto">
          <MenuPanel me={me} system={system} cols={3} />
        </div>
      </section>
    </div>
  )
}

export default Type2Homepage
