import _ from "lodash"
import { DateTime } from "luxon"
import dayjs from "dayjs"

import { integerDivinding } from "./integerDividing"

export const dayAmount = (start, end) =>
  integerDivinding(DateTime.fromISO(end).diff(DateTime.fromISO(start)).as("days"))

export const calculateAllPrice = (eachRow) =>
  (_.sum(
    _.map(
      eachRow?.list,
      (eachList) =>
        (eachList?.price - eachList?.discount) * dayAmount(eachRow?.start, eachRow?.end),
    ),
  ) || 0) +
  (_.sum(
    _.map(
      eachRow?.no_room_booking,
      (eachList) =>
        (eachList?.price - eachList?.discount || 0) * eachList?.amount * eachList?.night,
    ),
  ) || 0) +
  (_.sum(_.map(eachRow?.services, (eachServices) => eachServices?.price)) || 0) +
  (_.sum(_.map(eachRow?.fb_orders, (eachServices) => eachServices?.price)) || 0)

export const calculateFinalDiscount = (eachRow) => {
  if (eachRow?.use_discount_percent) {
    console.log("Use Percent Discount")
    const normalPrice = calculateAllPrice(eachRow)
    const discountAmount = ((eachRow?.final_discount || 0) * normalPrice) / 100
    return discountAmount
  }
  return eachRow?.final_discount || 0
}

export const calculatePriceWithoutDiscount = (eachRow) =>
  (_.sum(
    _.map(eachRow?.list, (eachList) => eachList?.price * dayAmount(eachRow?.start, eachRow?.end)),
  ) || 0) +
  (_.sum(
    _.map(
      eachRow?.no_room_booking,
      (eachList) => eachList?.price * dayAmount(eachRow?.start, eachRow?.end),
    ),
  ) || 0) +
  (_.sum(_.map(eachRow?.services, (eachServices) => eachServices?.price)) || 0) +
  (_.sum(_.map(eachRow?.fb_orders, (eachServices) => eachServices?.price)) || 0)

export const calculateBookingListDiscount = (eachRow) =>
  (_.sum(
    _.map(
      eachRow?.list,
      (eachList) => eachList?.discount * dayAmount(eachRow?.start, eachRow?.end),
    ),
  ) || 0) +
  (_.sum(
    _.map(
      eachRow?.no_room_booking,
      (eachList) => eachList?.discount * dayAmount(eachRow?.start, eachRow?.end),
    ),
  ) || 0)

export const bookingAnalysis = (eachBooking) => ({
  ...eachBooking,
  payment: {
    ...eachBooking?.payment,
    paid_amount: eachBooking?.payment?.paid_amount || 0,
  },
  price: calculateAllPrice(eachBooking) - calculateFinalDiscount(eachBooking),
  priceBeforeDiscount: calculatePriceWithoutDiscount(eachBooking) || 0,
  discountOnBooking: calculateBookingListDiscount(eachBooking),
  total_discount: calculateBookingListDiscount(eachBooking) + calculateFinalDiscount(eachBooking),
})

export const arrayBookingAnalysis = (bookingRows) =>
  _.map(bookingRows, (eachRow) => bookingAnalysis(eachRow))

// คำนวณราคาในแต่ละวันของ Subtype เอา้ไว้ใช้ในการคำนวณราคาของการจองแบบ Unspecify
// ถ้ามีการจองวันเดียว หรือ จองหลายวันแต่ราคาไม่เปลี่ยน จะเป็นราคาของวันเดียว และ Return ออกมาเป็น Number
// แต่หากราคาไม่เท่ากัน จะ Return ออกมาเป็น Array ของราคาของแต่ละวัน
export const calculatePriceOnSubtype = (subtype, checkinDate, checkoutDate) => {
  if (!subtype?.use_price_list) {
    return subtype?.price
  }

  const subtypePriceOnDay = subtype?.price_list
  const checkinDateDay = _.upperCase(dayjs(checkinDate).locale("en").format("ddd"))
  const amountOfBooking = dayAmount(checkinDate, checkoutDate)

  console.log("Checkin Date Day", checkinDateDay, "Amount of Booking", amountOfBooking)
  if (amountOfBooking === 1) {
    return subtypePriceOnDay[checkinDateDay]
  }

  // Find Price of Each Day
  const priceAmountArray = new Array(amountOfBooking)
  const priceOnAllDay = _.map(priceAmountArray, (eachDay, index) => {
    const eachDayDate = _.upperCase(dayjs(checkinDate).add(index, "day").locale("en").format("ddd"))
    return subtypePriceOnDay[eachDayDate]
  })

  return priceOnAllDay
}
