import React from "react"
import _ from "lodash"
import { WhitePanel } from "../../common/Cards"
// eslint-disable-next-line arrow-body-style
const VerticalList = () => {
  const arrayOfThree = new Array(3)
  return (
    <div>
      <WhitePanel>
        <div>
          {_.map(arrayOfThree, (each, index) => (
            <div className="p-4 " key={index}>
              <div className="flex gap-2">
                <div className="bg-gray-100 p-6 rounded-md">
                  <i className="fas fa-photo-video text-2xl"></i>
                </div>
                <div className="pl-4">
                  <h4 className="text-xl font-semibold ">Header</h4>
                  <p>.......................................</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </WhitePanel>
    </div>
  )
}

VerticalList.propTypes = {}

export default VerticalList
