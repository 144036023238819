import React, { forwardRef } from "react"
import _ from "lodash"
import ReactSelect from "react-select"

import { CountryList } from "../../../config/constant"
import ImageUpload from "../../ImageUpload/ImageUpload"

const CheckinForm = ({ register, bookings, setValue, idCardImage, setIdCardImage }) => (
  <div className="w-full px-4 ">
    <label className="block   text-gray-700 text-sm  mb-2" htmlFor="grid-password">
      รายการที่มี * หมายถึง จำเป็นต้องกรอกข้อมูล
    </label>
    <div className="flex gap-2">
      <div className="relative w-full mb-3 ">
        <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
          ชื่อลูกค้า *
        </label>
        <input
          name="firstname"
          type="text"
          required
          ref={register}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="ชื่อลูกค้า"
          defaultValue={
            bookings?.checkin?.firstname
              ? bookings?.checkin?.firstname
              : bookings?.customer?.firstname
          }
        />
      </div>
      <div className="relative w-full mb-3 ">
        <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
          นามสกุลลูกค้า *
        </label>
        <input
          name="lastname"
          type="text"
          ref={register}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="นามสกุลลูกค้า"
          defaultValue={
            bookings?.checkin?.lastname ? bookings?.checkin?.lastname : bookings?.customer?.lastname
          }
        />
      </div>
    </div>
    <div className="relative w-full mb-3 ">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        หมายเลขโทรศัพท์ *
      </label>
      <input
        name="tel"
        type="text"
        ref={register}
        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        placeholder="หมายเลขโทรศัพท์"
        defaultValue={bookings?.checkin?.tel ? bookings?.checkin?.tel : bookings?.customer?.tel}
      />
    </div>
    <div className="relative w-full mb-3 ">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        หมายเลขบัตรประชาชน/หมายเลขหนังสือเดินทาง *
      </label>
      <input
        name="id_card"
        type="text"
        ref={register}
        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        placeholder="หมายเลขบัตรประชาชน"
        defaultValue={
          bookings?.checkin?.id_card ? bookings?.checkin?.id_card : bookings?.customer?.id_card
        }
      />
    </div>
    <div className="relative w-full mb-3 ">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        อีเมล
      </label>
      <input
        name="email"
        type="email"
        ref={register}
        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        placeholder="test@example.com"
        defaultValue={
          bookings?.checkin?.email ? bookings?.checkin?.email : bookings?.customer?.email
        }
      />
    </div>
    <div className="relative w-full mb-3">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        ที่อยู่
      </label>
      <textarea
        name="address"
        type="text"
        ref={register}
        className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        placeholder="ที่อยู่"
        defaultValue={
          bookings?.checkin?.address ? bookings?.checkin?.address : bookings?.customer?.address
        }
      />
    </div>
    <div className="relative w-full mb-3">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        สัญชาติ
      </label>
      <input
        type="hidden"
        ref={register}
        name="nationality"
        defaultValue={bookings?.customer?.nationality}
      />
      <ReactSelect
        placeholder="สัญชาติ"
        name="nationality"
        ref={register}
        options={CountryList}
        defaultValue={_.find(
          CountryList,
          (eachCountry) => eachCountry.value === (bookings?.customer?.nationality || "TH"),
        )}
        onChange={(newValue) => {
          setValue("nationality", newValue?.value)
        }}
      />
    </div>
    <div className="relative w-full mb-3">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        รูปบัตรประชาชนด้านหน้า
      </label>{" "}
      <div className="flex justify-center">
        <img src={bookings?.checkin?.image?.url} className="w-64 my-auto " />
      </div>
      <div className="flex flex-wrap justify-center">
        <div className="justify-center">
          <ImageUpload
            images={idCardImage}
            setImages={setIdCardImage}
            preview_size="250"
            maxNumber={1}
          />
        </div>
      </div>{" "}
    </div>
    <hr className="my-2" />
    <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
      ข้อมูลอื่น ๆ
    </label>
    <div className="flex flex-wrap ">
      <div className="relative w-full lg:w-1/2 mb-3 pr-1">
        <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
          อาชีพ
        </label>{" "}
        <input
          name="occupation"
          ref={register}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="ข้าราชการ"
          defaultValue={
            bookings?.checkin?.occupation
              ? bookings?.checkin?.occupation
              : bookings?.customer?.occupation
          }
        />
      </div>
      <div className="relative w-full lg:w-1/2 mb-3">
        <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
          หมายเลขใบ ตม.6
        </label>{" "}
        <input
          name="tm_number"
          ref={register}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="BP99999"
          defaultValue={bookings?.checkin?.tm_number}
        />
      </div>{" "}
    </div>
    <div className="flex flex-wrap ">
      <div className="relative w-full lg:w-1/2 mb-3 pr-1">
        <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
          จังหวัดที่อาศัยอยู่
        </label>{" "}
        <input
          name="province"
          ref={register}
          className="px-3 py-3  placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="กรุงเทพมหานคร"
          defaultValue={
            bookings?.checkin?.province ? bookings?.checkin?.province : bookings?.customer?.province
          }
        />
      </div>
      <div className="relative w-full lg:w-1/2 mb-3">
        <label className="block  text-gray-700 text-sm font-semibold mb-2">รหัสไปรษณีย์</label>{" "}
        <input
          name="postcode"
          ref={register}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="10000"
          defaultValue={bookings?.checkin?.postcode}
        />
      </div>{" "}
    </div>
    <div className="flex flex-wrap ">
      <div className="relative w-full lg:w-1/2 mb-3 pr-1">
        <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
          ทะเบียนรถ
        </label>{" "}
        <input
          name="car_licence_number"
          ref={register}
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="BP99999"
          defaultValue={
            bookings?.checkin
              ? bookings?.checkin?.car_licence_number
              : bookings?.customer?.car_licence_number
          }
        />
      </div>
      <div className="relative w-full lg:w-1/2 mb-3">
        <label className="block  text-gray-700 text-sm font-semibold mb-2">วัน/เดือน/ปีเกิด</label>{" "}
        <input
          name="birthday"
          ref={register}
          type="date"
          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          placeholder="7/2/1998"
          defaultValue={bookings?.customer?.birthday}
        />
      </div>{" "}
    </div>
    <div className="relative w-full mb-3">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        ประเทศที่อาศัยอยู่
      </label>
      <input
        type="hidden"
        ref={register}
        name="stay_country"
        defaultValue={bookings?.customer?.stay_country}
      />
      <ReactSelect
        placeholder="ประเทศที่อาศัยอยู่"
        name="stay_country"
        ref={register}
        options={CountryList}
        defaultValue={_.find(
          CountryList,
          (eachCountry) => eachCountry.value === (bookings?.customer?.stay_country || "TH"),
        )}
        onChange={(newValue) => {
          setValue("stay_country", newValue?.value)
        }}
      />
    </div>
  </div>
)

export default forwardRef(CheckinForm)
