import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import WhitePanel from "../common/Cards/WhitePanel"
import exportFormJSON from "export-from-json"

export default function InformationBackup({
  information,
  handleRestore = () => {},
  article,
  promotion,
  gallery,
  facility,
  articleCategory,
}) {
  const [file, setFile] = useState()

  const handleExportJSON = () => {
    const jsonData = exportFormJSON({
      data: {
        information,
        article,
        promotion,
        gallery,
        facility,
        articleCategory,
      },
      fileName: `SystemInformation-${new Date().toISOString()}`,
      exportType: "json",
    })
    console.log("JSON Data", jsonData)
  }

  const handleUploadFile = (e) => {
    const fileReader = new FileReader()

    fileReader.readAsText(e.target.files[0], "UTF-8")
    fileReader.onload = (e) => {
      // console.log("Target Result ", e.target.result);
      setFile(JSON.parse(e.target.result))
    }
  }
  return (
    <div>
      <WhitePanel>
        <div className="flex justify-between">
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            Backup หรือ Download ข้อมูลเว็บไซต์
          </h6>
          <div className="flex gap-2">
            <Button colorScheme="green" size="sm" onClick={handleExportJSON}>
              Export ข้อมูล
            </Button>{" "}
          </div>
        </div>{" "}
        <div className="">
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            Restore ข้อมูล หรือนำเข้าจากระบบอื่น
          </h6>
          <div className="flex justify-between">
            <div className="">
              <input type="file" onChange={handleUploadFile} accept=".json" />
            </div>
            <div className="">
              <Button colorScheme="yellow" size="sm" onClick={() => handleRestore(file)}>
                Restore ข้อมูล
              </Button>{" "}
            </div>
          </div>{" "}
        </div>
      </WhitePanel>
    </div>
  )
}
