import React from "react"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"
import Flags from "country-flag-icons/react/3x2"
import { Input, Checkbox, Radio, Select } from "antd"
import _ from "lodash"

import WhitePanel from "../../common/Cards/WhitePanel"

function ServiceForm({ hotelService, control, watch, setValue, roomtypes }) {
  const { t } = useTranslation()
  const { TextArea } = Input
  return (
    <div>
      <WhitePanel>
        <div className="w-full flex flex-wrap  ">
          <div className="w-full lg:w-1/2 pr-2 ">
            <h6 className="text-gray-900 text-base  font-bold  my-2 font-sans ">ข้อมูลเบื้องต้น</h6>
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              ชื่อบริการ *
            </label>
            <Controller
              control={control}
              name="translation.th.name"
              defaultValue={hotelService?.translation?.th?.name || hotelService?.name}
              render={(field) => (
                <Input
                  {...field}
                  addonBefore={<Flags.TH title="Thai" className="w-5" />}
                  required
                  placeholder="ชื่อบริการ"
                  size="large"
                />
              )}
            />

            <label
              className="block  text-gray-700 text-sm font-semibold my-2"
              htmlFor="grid-password"
            >
              {t("general.detail")}
            </label>
            <Controller
              control={control}
              name="translation.th.description"
              defaultValue={hotelService?.translation?.th?.description}
              render={(field) => <TextArea {...field} rows={4} placeholder={t("general.detail")} />}
            />
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2 mt-3"
              htmlFor="grid-password"
            >
              {t("general.name2")}
            </label>
            <Controller
              control={control}
              name="translation.en.name"
              defaultValue={hotelService?.translation?.en?.name || hotelService?.name}
              render={(field) => (
                <Input
                  {...field}
                  addonBefore={<Flags.GB title="English" className="w-5" />}
                  placeholder={"Service Name"}
                  size="large"
                />
              )}
            />

            <label
              className="block  text-gray-700 text-sm font-semibold mb-2 pt-1"
              htmlFor="grid-password"
            >
              {t("general.detail2")}
            </label>
            <Controller
              control={control}
              name="translation.en.description"
              defaultValue={hotelService?.translation?.th?.description}
              render={(field) => <TextArea {...field} rows={4} placeholder={t("general.detail")} />}
            />
          </div>

          <div className="w-full lg:w-1/2 px-2">
            <h6 className="text-gray-900 text-base  font-bold  font-sans my-2 ">ราคา</h6>

            <div className="my-4">
              <label className="block  text-gray-700 text-sm font-semibold my-2">
                ราคาโดยทั่วไป (สามารถปรับได้กรณีเป็นการจองจากหลังบ้าน)
              </label>
              <Controller
                control={control}
                name="price"
                defaultValue={hotelService?.price}
                render={(field) => (
                  <Input {...field} placeholder="ราคา" style={{ width: "70%" }} addonAfter="บาท" />
                )}
              />
            </div>
            <Controller
              control={control}
              name="price_per_night"
              defaultValue={hotelService?.price_per_night}
              render={(field) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                >
                  เป็นราคาต่อคืน
                </Checkbox>
              )}
            />

            <h6 className="text-gray-900 text-base  font-bold  font-sans my-2 ">ตั้งค่าการทำงาน</h6>

            <label
              className="block  text-gray-700 text-sm font-semibold mb-2 pt-1"
              htmlFor="grid-password"
            >
              ปริมาณการให้บริการ
            </label>
            <Controller
              control={control}
              name="unlimit"
              //    defaultValue={hotelService?.unlimit}
              defaultValue={true}
              render={(field) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled
                  onChange={(e) => field.onChange(e.target.checked)}
                >
                  ไม่จำกัด
                </Checkbox>
              )}
            />
            <br />
            <small>ระบบยังไม่พร้อมให้บริการการจำกัดจำนวน</small>
            <div className="my-4">
              <label className="block  text-gray-700 text-sm font-semibold my-2">
                อนุญาติให้จองผ่านทางระบบออนไลน์
              </label>
              <Controller
                control={control}
                name="online_available.mode"
                defaultValue={hotelService?.online_available?.mode}
                render={(field) => (
                  <Radio.Group {...field} onChange={(e) => field.onChange(e.target.value)}>
                    <div className="flex flex-col gap-1">
                      <Radio value="all">อนุญาติทุกประเภทห้องพัก</Radio>
                      <Radio value="some">อนุญาติบางประเภทห้องพัก</Radio>
                      <Radio value="none">ไม่อนุญาติ</Radio>
                    </div>
                  </Radio.Group>
                )}
              />
            </div>
            {watch("online_available.mode") === "some" && (
              <div className="my-2">
                <label className="block  text-gray-700 text-sm font-semibold my-2">
                  หมวดหมู่ห้องพักที่อนุญาติ
                </label>
                <Controller
                  control={control}
                  name="online_available.available_roomtype"
                  defaultValue={hotelService?.online_available?.available_roomtype}
                  render={(field) => (
                    <Select
                      mode="tags"
                      style={{
                        width: "100%",
                      }}
                      placeholder="หมวดหมู่ห้องพักที่อนุญาติ"
                      onChange={(e) => field.onChange(e)}
                      options={_.map(roomtypes, (each) => ({
                        value: each?._id,
                        label: each?.translation?.th?.name || each?.name,
                      }))}
                    />
                  )}
                />
              </div>
            )}
            <div className="my-4">
              <label className="block  text-gray-700 text-sm font-semibold my-2">
                จำกัดจำนวนต่อการจอง
              </label>
              <Controller
                control={control}
                name="limit_per_order_enable"
                defaultValue={hotelService?.limit_per_order_enable}
                render={(field) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  >
                    จำกัด
                  </Checkbox>
                )}
              />
            </div>
            {watch("limit_per_order_enable") && (
              <div className="my-2">
                <Controller
                  control={control}
                  name="limit_per_order"
                  defaultValue={hotelService?.limit_per_order}
                  render={(field) => (
                    <Input
                      {...field}
                      type="number"
                      placeholder="จำนวน"
                      addonAfter="รายการ/จำนวนห้อง/วัน"
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </WhitePanel>
    </div>
  )
}

export default ServiceForm
