import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Table, Thead, Tr, Th, Tbody, Td, Input } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import ModalServiceCreate from "../../../components/Modal/ServiceManagement/ModalServiceCreate"
import Alert from "../../../components/common/Alert/AlertDialog"
import * as actions from "../../../redux/actions"
import Paginator from "../../../components/common/Paginator/NewPagination"

export default function ServiceManagement() {
  const dispatch = useDispatch()
  const hotelServices = useSelector((state) => state.hotelService)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [page, setPage] = useState(1)
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const { t } = useTranslation()

  const serviceOnlineAvailableMode = {
    all: <span>ทุกห้อง</span>,
    some: (
      <span>
        <span className="font-bold">บางห้อง</span>
      </span>
    ),
    none: "ไม่เปิดให้จอง",
  }

  useEffect(() => {
    dispatch(actions.getAllServices({ size: 10, page, name }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
    return () => {}
  }, [page, name])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const handleDeleteService = (serviceId) => {
    const confirm = window.confirm(t("roomtype.confirmRemoveService"))
    if (confirm) {
      dispatch(actions.deleteOneservice(serviceId))
        .then(() => {
          dispatch(actions.getAllServices())
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  if (!isLoading) {
    return (
      <div>
        <SpinnerLoading />
      </div>
    )
  }

  return (
    <Layout categoryTitle={t("roomtype.title")} title={t("roomtype.serviceManagement")}>
      <div className="flex justify-between px-4">
        <Link to="/roomtypes/service/create">
          <Button colorScheme="purple" variant="solid">
            {t("general.add")}
          </Button>
        </Link>
        <div className="w-full lg:w-1/3 my-1">
          <Input
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            placeholder={t("general.search")}
            size="sm"
          />
        </div>
      </div>
      <div className="p-4 w-full min-h-screen overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <ModalServiceCreate
          handleOnClose={() => {
            setIsModalCreateOpen(false)
          }}
          isOpen={isModalCreateOpen}
        />
        <Table variant="simple" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>{t("general.no")}</Th>
              <Th>{t("roomtype.serviceName")} </Th>
              <Th>{t("roomtype.generalPrice")}</Th>
              <Th>เปิดให้จองออนไลน์</Th>
              <Th>{t("general.action")}</Th>
            </Tr>
          </Thead>
          <Tbody className>
            {_.map(hotelServices?.arr, (eachService, index) => (
              <Tr key={index}>
                <Td>{(page - 1) * 10 + index + 1}</Td>
                <Td>
                  <div className="font-semibold">
                    {eachService?.translation?.th?.name || eachService?.name}
                  </div>
                  <div className="text-sm">{eachService?.translation?.en?.name || ""}</div>
                </Td>
                <Td>
                  {eachService?.price}
                  {"  "}
                  {eachService?.price_per_night ? "บาท/คืน" : "บาท"}
                </Td>
                <Td>
                  {serviceOnlineAvailableMode[eachService?.online_available?.mode] || ""}
                  <div className="text-sm my-2">
                    {_.map(eachService?.online_available?.available_roomtype, (eachRoomType) => (
                      <li key={eachRoomType?._id}>
                        {eachRoomType?.translation?.th?.name || eachRoomType?.name}
                      </li>
                    ))}
                  </div>
                </Td>
                <Td>
                  <div className="flex gap-1">
                    <Link to={`/roomtypes/service/edit/${eachService?.id}`}>
                      <Button size="sm" colorScheme="yellow">
                        {t("general.edit")}
                      </Button>
                    </Link>
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => {
                        handleDeleteService(eachService?.id)
                      }}
                    >
                      {t("general.delete")}
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {_.isEmpty(hotelServices?.arr) && (
          <div className=" w-full flex p-4 m-4 my-8 justify-center">
            <p>{t("roomtype.emptyService")}</p>
          </div>
        )}
        <Paginator lastPage={hotelServices?.lastPage} setPage={setPage} />
      </div>
    </Layout>
  )
}
