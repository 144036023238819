import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import dayjs from "dayjs"
import { Button } from "@chakra-ui/react"
import { DatePicker } from "antd"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import { BookingLayout as Layout } from "../../../components/layout"
import * as actions from "../../../redux/actions"
import { appConfig } from "../../../config"
import { SpinnerLoading, RoomTypeForecastTable, WhitePanel } from "../../../components"
import { MonthyForecastReport } from "../../../config/pdfmake/template"

function MonthyForecast() {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const bookings = useSelector((state) => state.bookings)
  const roomTypes = useSelector((state) => state.roomTypes)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const [startDate, setStartDate] = useState(dayjs().startOf("month").toDate())
  const [isLoading, setIsLoading] = useState(false)

  const handlePrintPDF = () => {
    const ReportDefinition = MonthyForecastReport(
      bookings?.arr,
      roomTypes?.arr,
      startDate,
      information,
      system,
    )
    pdfMake.createPdf(ReportDefinition).open()
  }

  useEffect(() => {
    dispatch(
      actions.getAllRoomTypes({
        name: "",
        page: 1,
        size: appConfig.maxFetchSize,
        fetchBooking: true,
      }),
    )

    return () => {}
  }, [])

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getAllBookingAndLog({
        page: 1,
        size: appConfig.bookingMaxFetchSize,
        unwindBooking: "true",
        start: dayjs(startDate).subtract(7, "day").format("YYYY-MM-DD"),
        end: dayjs(startDate).add(1, "month").add(7, "day").format("YYYY-MM-DD"),
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        alert(`ไม่สามารถดึงข้อมูลได้เนื่องจาก ${err?.message}`)
      })

    return () => {}
  }, [startDate])

  return (
    <Layout title="พยากรณ์การจองรายเดือน" categoryTitle={t("reservation.title")} preview>
      <div className="flex justify-between px-4 items-center">
        <Button variant="outline" colorScheme="purple">
          {t("general.back")}
        </Button>
        <div className="my-2 flex items-center">
          <div className="px-2">
            <DatePicker
              picker="month"
              value={dayjs(startDate)}
              onChange={(data) => setStartDate(data.toDate())}
              format={"MMMM YYYY"}
            />
          </div>
          <div>
            <Button
              size="sm"
              leftIcon={<i className="fas fa-chevron-left" />}
              onClick={() => {
                const preferDate = dayjs(startDate).subtract(1, "month").toDate()
                setStartDate(preferDate)
              }}
            >
              เดือนก่อนหน้า
            </Button>
            <Button
              size="sm"
              rightIcon={<i className="fas fa-chevron-right" />}
              onClick={() => {
                const preferDate = dayjs(startDate).add(1, "month").toDate()
                setStartDate(preferDate)
              }}
            >
              เดือนถัดไป
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              leftIcon={<i className="fas fa-file-pdf"></i>}
              onClick={() => handlePrintPDF()}
            >
              PDF
            </Button>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="text-xl my-2 font-semibold">{dayjs(startDate).format("MMMM YYYY")}</div>
        <WhitePanel>
          {isLoading ? (
            <div className="overflow-x-auto">
              <RoomTypeForecastTable
                bookingEvents={bookings?.arr}
                roomtypes={roomTypes?.arr}
                startDate={startDate}
              />
            </div>
          ) : (
            <SpinnerLoading />
          )}
        </WhitePanel>
      </div>
    </Layout>
  )
}

export default MonthyForecast
