/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import _ from "lodash"
import dayjs from "dayjs"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { Table } from "antd"

import Layout from "../../components/layout/GeneralLayout"
import { Alert, Confirm } from "../../components/common/Alert"

import * as actions from "../../redux/actions"

export default function MenuCategoryManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [size, setSize] = useState(10)

  const menuCategory = useSelector((state) => state.menuCategory)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getAllMenuCategory = () => {
    dispatch(
      actions.getAllMenuCategory({
        page,
        size,
        query: {},
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }
  const handleDelete = async () => {
    try {
      await dispatch(actions.deleteOneMenuCategory(selectedCategory))
    } catch (error) {
      setAlertMessage(error?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      setIsAlertOpen(true)
    } finally {
      setIsConfirmOpen(false)
    }
  }

  useEffect(() => {
    setIsLoading(false)
    getAllMenuCategory()
    return () => {}
  }, [page])

  const renderSetting = () => (
    <div className="flex justify-between flex-wrap px-4">
      <Link to="/food-beverage/menu-category/create">
        <Button colorScheme="purple" variant="solid">
          {t("general.add")}
        </Button>
      </Link>
      <div className="w-full md:w-1/4  self-center mt-2"></div>
    </div>
  )

  const renderDeleteConfirm = () => (
    <Confirm
      title="ยืนยันการลบหมวดหมู่"
      isOpen={isConfirmOpen}
      body="โปรดเช็คให้มั่นใจว่าไม่มีเมนูอาหารอยู่ในหมวดหมู่แล้ว"
      handleOnClose={() => setIsConfirmOpen(false)}
      handleSuccess={handleDelete}
    />
  )

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  const columns = [
    {
      title: "ลำดับที่",
      dataIndex: "list",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "ชื่อเมนู",
      dataIndex: "translation",
      render: (text, record) => (
        <div>
          {record?.translation?.th?.name}
          {record?.translation?.en?.name ? ` (${record?.translation?.en?.name})` : ""}
        </div>
      ),
    },
    {
      title: "เวลาที่ให้บริการ",
      dataIndex: "useSpecifyTime",
      render: (text, record) => (
        <div>
          {record?.useSpecifyTime
            ? `${dayjs(record?.startTime).format("HH:mm")} - ${dayjs(record?.endTime).format(
                "HH:mm",
              )}`
            : "ตลอดเวลาที่ร้านให้บริการ"}
        </div>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="flex gap-2">
          {/* eslint-disable-next-line no-underscore-dangle */}
          <Link to={`/food-beverage/menu-category/edit/${record?._id}`}>
            <Button colorScheme="yellow" size="sm">
              {t("general.edit")}
            </Button>
          </Link>

          <Button
            size="sm"
            colorScheme="red"
            onClick={() => {
              setSelectedCategory(record?._id)
              setIsConfirmOpen(true)
            }}
          >
            {t("general.delete")}
          </Button>
        </div>
      ),
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize

    setPage(newPage)
    setSize(pageSize)
    console.log("params", pagination, filters, sorter, extra)
  }

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="หมวดหมู่เมนูอาหาร" preview>
      {renderAlert()}
      {renderDeleteConfirm()}
      {renderSetting()}
      <div className="p-4 w-full overflow-x-auto">
        <Table
          columns={columns}
          dataSource={menuCategory?.arr}
          onChange={onChange}
          pagination={{
            current: page,
            pageSize: size,
            total: menuCategory?.total,
          }}
          scroll={{
            x: "true",
          }}
        />
      </div>
    </Layout>
  )
}
