import React, { forwardRef } from "react"
import { FormControl, FormLabel, Input, Checkbox } from "@chakra-ui/react"


function VerticalList({ register, defaultValue, watch, setValue, activeTab }) {
  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-1/3 px-2 py-1">
        <FormLabel fontSize="sm">แสดงรายละเอียดคำอธิบายของรูปภาพ</FormLabel>
        <FormControl display="flex">
          <div>
            <Checkbox ref={register}
              defaultChecked={defaultValue?.show_content || false}
              name="show_content"
            >
              เปิดใช้
            </Checkbox>
          </div>
        </FormControl>
      </div>

      <div className="w-full lg:w-1/2 px-2 py-1">
        <FormLabel fontSize="sm">ลิมิตของจำนวนรูปภาพ</FormLabel>
        {!watch("type_attribute.unlimit") && (
          <Input
            ref={register}
            placeholder="ลิมิตของจำนวนรูปภาพ"
            name="type_attribute.limit"
            type="number"
            size="sm"
            required
            defaultValue={defaultValue?.type_attribute?.limit || 10}
          />
        )}
        <FormControl display="flex">
          <Checkbox
            ref={register}
            name="type_attribute.unlimit"
            defaultChecked={defaultValue?.type_attribute?.limit === null}
            size="sm"
            onChange={(event) => {
              if (event.target.checked) {
                setValue("type_attribute.limit", null)
                setValue("type_attribute.unlimit", true)
              } else {
                setValue("type_attribute.limit", 10)
                setValue("type_attribute.unlimit", false)
              }
            }}
          >
            ไม่จำกัด
          </Checkbox>
        </FormControl>
      </div>
    </div>
  )
}

export default forwardRef(VerticalList)
