/* eslint-disable no-underscore-dangle */
import React from "react"
import _ from "lodash"

import { WhitePanel } from "../common/Cards"
import { currencyFormatter } from "../../util"
import { PaymentMethod } from "../../config/constant"
import { Dot } from "../common/Dot"

function PaymentSummaryPanel({ transactions, displayOutcome, bill }) {
  const billTotal = bill?.income || 0 - bill?.outcome || 0
  return (
    <WhitePanel>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2">
          <div>
            <div className="text-lg font-semibold">
              {displayOutcome ? "สรุปรายรับ/รายจ่าย" : "สรุปรายรับรวม"}
            </div>
            <div className="font-semibold text-2xl">
              {currencyFormatter.format(transactions?.summarized || 0)} บาท
              {bill && billTotal !== transactions?.summarized && (
                <div>
                  <Dot color="red" word="ไม่ตรงกับข้อมูลรอบบิล"></Dot>
                  <div className="text-sm text-red-500">
                    ต่างกัน {currencyFormatter.format(billTotal - transactions?.summarized)} บาท
                  </div>
                </div>
              )}
            </div>
          </div>
          {displayOutcome && (
            <div className="my-2">
              <div className="text-lg font-semibold">รวมเงินในลิ้นชักปัจจุบัน</div>
              <div className="font-semibold text-2xl">
                {currencyFormatter.format(transactions?.summarized + (bill?.spare_money || 0) || 0)}{" "}
                บาท
              </div>
            </div>
          )}
        </div>
        {displayOutcome && (
          <div className="w-full md:w-1/4">
            {displayOutcome && (
              <div>
                <div className="">รายรับรวม</div>
                <div className="font-semibold text-xl">
                  {currencyFormatter.format(bill?.income || 0)} บาท
                </div>
              </div>
            )}
            {displayOutcome && (
              <div>
                <div className="">รายจ่ายรวม</div>
                <div className="font-semibold text-xl">
                  {currencyFormatter.format(bill?.outcome || 0)} บาท
                </div>
              </div>
            )}
            {displayOutcome && (
              <div>
                <div className="">เงินในลิ้นชักเริ่มต้น</div>
                <div className="font-semibold text-xl">
                  {currencyFormatter.format(bill?.spare_money || 0)} บาท
                </div>
              </div>
            )}
          </div>
        )}
        <div className="w-full md:w-1/4">
          <div className="text-lg font-semibold">สรุปยอดแต่ละช่องทาง</div>
          <div className="ml-2">
            {_.map(transactions?.analyzed, (eachAnalyzed, index) => (
              <li key={index} className="text-base">
                <b>{PaymentMethod[eachAnalyzed?._id]?.description || "ไม่ระบุ"} </b>{" "}
                {currencyFormatter.format(eachAnalyzed?.total || 0)} บาท
              </li>
            ))}
          </div>
        </div>
      </div>
    </WhitePanel>
  )
}

export default PaymentSummaryPanel
