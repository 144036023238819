import api from "../../../config/api"

import { ARTICLE_ALL, ARTICLE_CREATE, ARTICLE_DELETE, ARTICLE_GET, ARTICLE_PUT } from "../types"

// faker.locale = "th"

export const getAllArticle =
  ({ name = "", page = 1, size = "", articleCategory = "" }) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/article/?page=${page}&size=${size}&name=${name}&articleCategory=${articleCategory}`,
      )
      .then((res) => {
        console.log("Request Server to Get All Article")
        if (res.data) {
          dispatch({ type: ARTICLE_ALL, payload: res.data })
        } else {
          dispatch({ type: ARTICLE_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All Article", err)
        dispatch({ type: ARTICLE_ALL, payload: null })
        throw new Error(
          `ไม่สามารถดึงข้อมูลบทความทั่งหมดมาได้  ${err?.response?.data?.error?.message}`,
        )
      })
  }

export const getOneArticle = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/article/${id}`)
    .then((res) => {
      console.log("Request Server to Get One Article")
      if (res.data) {
        dispatch({ type: ARTICLE_GET, payload: res.data })
      } else {
        dispatch({ type: ARTICLE_GET, payload: null })
      }
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถดึงข้อมูลบทความมาได้  ${err?.response?.data?.error?.message}`)
    })
}

export const createOneArticle = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/article/`, payload)
    .then(() => {
      console.log("Request Server to Create New Article")
      dispatch({ type: ARTICLE_CREATE })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถสร้างบทความนี้ได้  ${err?.response?.data?.error?.message}`)
    })
}

export const editOneArticle = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/article/${id}`, payload)
    .then((res) => {
      console.log("Request Server to edit Article")
      dispatch({ type: ARTICLE_PUT })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถแก้ไขบทความนี้ได้  ${err?.response?.data?.error?.message}`)
    })
}

export const deleteOneArticle = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/article/${id}`)
    .then((res) => {
      console.log("Request Server to Delete One Article")
      dispatch({ type: ARTICLE_DELETE, payload: null })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถลบบทความนี้ได้ ${err?.response?.data?.error?.message}`)
    })
}
