import {
  BILL_ALL,
  BILL_CREATE,
  BILL_GET,
  BILL_UPDATE,
  BILL_DELETE,
  BILL_ERROR,
  BILL_LOADING,
} from "../../actions/types"

const initialState = {
  bills: null,
  isLoading: false,
}
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case BILL_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        total: action.payload?.total,
      }
    case BILL_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case BILL_CREATE:
      return { isLoading: false }
    case BILL_UPDATE:
      return { isLoading: false }
    case BILL_DELETE:
      return { isLoading: false }
    case BILL_ERROR:
      return { isLoading: false }
    case BILL_LOADING:
      return { isLoading: false }
    default:
      return state
  }
}
