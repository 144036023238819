import React from "react"
import { useTranslation } from "react-i18next"
import { Input, Select } from "antd"
import { Controller } from "react-hook-form"
import _ from "lodash"

import { CountryList } from "../../../config/constant"

function OnlineBookingInfoForm({ register, control }) {
  const { t, i18n } = useTranslation()
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values
  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 p-2">
        <label className="text-sm font-semibold">{t("crm.nameLastname")}</label>
        <div className="my-2">
          <Controller
            control={control}
            name="customer.firstname"
            render={(field) => (
              <Input
                {...field}
                required
                placeholder={t("crm.firstname")}
                size="large"
                autoComplete="given-name"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-2 self-end ">
        <label className="text-sm font-semibold"> </label>
        <div className="my-2">
          <Controller
            control={control}
            name="customer.lastname"
            render={(field) => (
              <Input
                {...field}
                placeholder={t("crm.lastname")}
                size="large"
                autoComplete="family-name"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-2">
        <label className="text-sm font-semibold">{t("crm.tel")}</label>
        <div className="my-2">
          <Controller
            control={control}
            name="customer.tel"
            render={(field) => (
              <Input
                {...field}
                required
                placeholder={t("crm.tel")}
                size="large"
                autoComplete="tel"
              />
            )}
          />
        </div>
      </div>
      <div className="w-full md:w-1/2 p-2">
        <label className="text-sm font-semibold">{t("crm.nationality")}</label>
        <div className="my-2">
          <Controller
            control={control}
            name="customer.nationality"
            defaultValue={"TH"}
            render={(field) => (
              <Select
                {...field}
                placeholder={t("crm.nationality")}
                size="large"
                style={{
                  width: "100%",
                }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  ((option?.label || option?.thaiName) ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                showSearch
                options={_.map(CountryList, (eachCountry) => ({
                  value: eachCountry?.value,
                  label:
                    i18n.language === "th"
                      ? eachCountry?.thaiName || eachCountry?.label
                      : eachCountry?.label,
                }))}
              ></Select>
            )}
          />
        </div>
      </div>
      <div className="w-full p-2 self-end ">
        <label className="text-sm font-semibold"> {t("general.email")} </label>
        <div className="my-2">
          <Controller
            control={control}
            name="customer.email"
            render={(field) => (
              <Input
                {...field}
                required
                placeholder={t("general.email")}
                size="large"
                autoComplete="email"
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default OnlineBookingInfoForm
