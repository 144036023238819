/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

// components
import Sidebar from "../components/common/Sidebar/MainSidebar"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"

// views
import CreateMaterial from "../views/Inventory/CreateMaterial"
import CreateManyMaterial from "../views/Inventory/CreateManyMaterial"
import EditMaterial from "../views/Inventory/EditMaterial"
import MaterialManagement from "../views/Inventory/MaterialManagement"
import PurchaseOrderManagement from "../views/Inventory/PurchaseOrderManagement"
import CreatePurchaseOrder from "../views/Inventory/CreatePurchaseOrder"
import EditPurchaseOrder from "../views/Inventory/EditPurchaseOrder"
import ViewPurchaseOrder from "../views/Inventory/ViewPurchaseOrder"

import * as actions from "../redux/actions"
import { ModuleList } from "../config/constant/ModuleList"
import { INVENTORY_MENU } from "../config/MenuList"

import UserRole from "../config/UserRoles"

export default function InventoryLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("Inventory : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.access?.[ModuleList.INVENTORY.status_code] ||
        me?.role === UserRole.SUPER_USER.toString() // Reserved for some system
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar menuList={INVENTORY_MENU} isShow={showSidebar} setIsShowSidebar={setIsShowSidebar} />
      <div className=" relative bg-gray-200 lg:ml-64">
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route
              path="/inventory/purchase-order"
              exact
              component={() => <PurchaseOrderManagement />}
            />
            <Route
              path="/inventory/purchase-order/create"
              exact
              component={() => <CreatePurchaseOrder />}
            />{" "}
            <Route
              path="/inventory/purchase-order/edit/:id"
              exact
              component={() => <EditPurchaseOrder />}
            />
            <Route
              path="/inventory/purchase-order/view/:id"
              exact
              component={() => <ViewPurchaseOrder />}
            />
            <Route path="/inventory/material" exact component={() => <MaterialManagement />} />
            <Route path="/inventory/material/create" exact component={() => <CreateMaterial />} />
            <Route
              path="/inventory/material/create-many"
              exact
              component={() => <CreateManyMaterial />}
            />
            <Route path="/inventory/material/edit/:id" exact component={() => <EditMaterial />} />
            <Redirect from="/inventory" to="/inventory/purchase-order" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
