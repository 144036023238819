/*eslint-disable*/
import React, { useContext } from "react"
import { PassportAuth } from "../../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import Flags from "country-flag-icons/react/3x2"
import { useTranslation } from "react-i18next"

// components
import AppLogo from "../../../assets/img/E-Accom.png"
import { Button } from "@chakra-ui/react"
import { systemInfoPost } from "redux/actions"

export default function IndexNavbar({ displaySignOut = true }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)
  const { handleSignout } = useContext(PassportAuth)
  const system = useSelector((state) => state.system)
  const history = useHistory()
  const onSignOut = async () => {
    await handleSignout()
    history.push("/login")
  }
  const { t, i18n } = useTranslation()
  if (system && system.isLoading === true)
    return (
      <>
        <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow">
          <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start font-sans">
              <div className="flex gap-2">
                <Link
                  to="/"
                  className="text-gray-800 text-base font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase"
                >
                  <div className="flex gap-2">
                    <img
                      src={system.logo ? system.logo?.url : AppLogo}
                      className="w-auto align-middle   max-w-50-px"
                    />
                    <span className="self-center">{system.name}</span>
                  </div>
                </Link>
              </div>

              {/* <button
                className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                <i className="fas fa-bars"></i>
              </button> */}
              <div className="lg:hidden flex py-2 px-3 self-center">
                <li className="flex items-center">
                  <div className="flex ">
                    <div
                      className="shadow-sm mr-2 cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("th")
                      }}
                    >
                      <Flags.TH title="Thai" className="w-6" />
                    </div>
                    <div
                      className="shadow-sm mr-2 cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("en")
                      }}
                    >
                      <Flags.GB title="English" className="w-6" />
                    </div>
                  </div>
                </li>
                <li className="flex items-center">
                  {displaySignOut && (
                    <Link to="/auth/login">
                      <Button
                        size="xs"
                        leftIcon={<i className="fas fa-sign-out-alt"></i>}
                        variant="outline"
                        onClick={() => {
                          onSignOut()
                        }}
                      >
                        Sign Out
                      </Button>
                    </Link>
                  )}
                </li>
              </div>
            </div>
            <div
              className={
                "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
                (navbarOpen ? " block" : " hidden")
              }
              id="example-navbar-warning"
            >
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li className="flex items-center">
                  <div className="flex ">
                    <div
                      className="shadow-sm mr-2 cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("th")
                      }}
                    >
                      <Flags.TH title="Thai" className="w-6" />
                    </div>
                    <div
                      className="shadow-sm mr-2 cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("en")
                      }}
                    >
                      <Flags.GB title="English" className="w-6" />
                    </div>
                  </div>
                </li>
                <li className="flex items-center">
                  {displaySignOut && (
                    <Link to="/auth/login">
                      <Button
                        size="xs"
                        leftIcon={<i className="fas fa-sign-out-alt"></i>}
                        variant="outline"
                        onClick={() => {
                          onSignOut()
                        }}
                      >
                        Sign Out
                      </Button>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    )
  else {
    return <></>
  }
}
