/* eslint-disable no-underscore-dangle */
import React from "react"
import { Checkbox, Form, Input, Select, Button } from "antd"
import { Controller, useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import Flag from "country-flag-icons/react/3x2"
import { SingleImageUpload } from "../../ImageUpload"

function MenuForm({
  control,
  defaultValue,
  menuCategory,
  formSize = "large",
  onMany = false,
  index = 0,
  watch,
}) {
  const { i18n } = useTranslation()
  const { fields, append, remove } = useFieldArray({
    name: onMany ? `arr[${index}].options` : "options",
    control,
  })
  const {
    fields: toppingField,
    append: toppingAppend,
    remove: toppingRemove,
  } = useFieldArray({
    name: onMany ? `arr[${index}].toppings` : "toppings",
    control,
  })
  return (
    <div>
      <div className="flex flex-wrap px-4">
        <div className="w-full lg:w-1/3">
          <div className="border flex justify-center m-4 p-4 rounded-md border-dotted">
            <div>
              <div className="text-sm my-2">รูปภาพเมนูอาหาร</div>
              {defaultValue?.image?.url && <img src={defaultValue?.image?.url} className="w-24" />}
              <Controller
                control={control}
                name={onMany ? `arr[${index}].image` : "image"}
                render={(field) => (
                  <SingleImageUpload image={field.value} setImage={field.onChange} />
                )}
              />
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3">
          <Form layout="vertical" size={formSize}>
            <Form.Item label="ชื่อเมนูภาษาไทย" required style={{ marginBottom: "5px" }}>
              <Controller
                control={control}
                name={onMany ? `arr[${index}].translation.th.name` : "translation.th.name"}
                defaultValue={defaultValue?.translation?.th?.name}
                rules={{ required: true }}
                render={(field) => (
                  <Input
                    {...field}
                    required
                    addonBefore={<Flag.TH title="Thai" className="w-5" />}
                  />
                )}
              />
            </Form.Item>
            <Form.Item label="ชื่อเมนูภาษาอังกฤษ" style={{ marginBottom: "5px" }}>
              <Controller
                control={control}
                name={onMany ? `arr[${index}].translation.en.name` : "translation.en.name"}
                defaultValue={defaultValue?.translation?.en?.name}
                render={(field) => (
                  <Input {...field} addonBefore={<Flag.GB title="English" className="w-5" />} />
                )}
              />
            </Form.Item>
            <Form.Item label="ราคา (โดยทั่วไป)" style={{ marginBottom: "5px" }}>
              <Controller
                control={control}
                name={onMany ? `arr[${index}].price` : "price"}
                defaultValue={defaultValue?.price}
                render={(field) => <Input {...field} type="number" addonAfter="บาท" />}
              />
            </Form.Item>
            {menuCategory?.arr && (
              <Form.Item label="หมวดหมู่เมนู" style={{ marginBottom: "5px" }}>
                <Controller
                  control={control}
                  name={onMany ? `arr[${index}].category` : "category"}
                  defaultValue={defaultValue?.category?._id}
                  render={(field) => (
                    <Select
                      showSearch
                      {...field}
                      placeholder="เลือกหมวดหมู่เมนู"
                      optionFilterProp="children"
                      onChange={field.onChange}
                      filterOption={(input, option) =>
                        (option?.translation?.[i18n.language]?.name ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={_.map(menuCategory?.arr, (eachCategory) => ({
                        ...eachCategory,
                        label: eachCategory?.translation?.[i18n.language]?.name,
                        value: eachCategory?._id,
                      }))}
                    />
                  )}
                />
              </Form.Item>
            )}
            <Form.Item label="ลูกค้าสามารถสั่งได้" style={{ marginBottom: "5px" }}>
              <Controller
                control={control}
                name={onMany ? `arr[${index}].enable` : "enable"}
                defaultValue={defaultValue ? defaultValue?.enable : true}
                render={(field) => (
                  <Checkbox
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.checked)
                    }}
                    defaultChecked={field.value}
                  >
                    สั่งอาหารได้
                  </Checkbox>
                )}
              />
            </Form.Item>

            <Form.Item
              label="ออพชั่น"
              style={{ marginBottom: "5px" }}
              tooltip="เป็นตัวเลือกในเมนู เช่น ไก่ ราคาหนึ่ง หมู ราคาหนึ่ง"
            >
              {_.map(fields, (eachField, fieldIndex) => (
                <div className="flex justify-between my-2" key={eachField.id}>
                  <div className="w-3/5">
                    <Controller
                      control={control}
                      name={
                        onMany
                          ? `arr[${index}].options[${fieldIndex}].name`
                          : `options[${fieldIndex}].name`
                      }
                      defaultValue={eachField.name}
                      render={(field) => <Input {...field} placeholder="ชื่อออพชั่น" />}
                    />
                  </div>
                  <div className="px-2 w-1/5">
                    <Controller
                      control={control}
                      name={
                        onMany
                          ? `arr[${index}].options[${fieldIndex}].price`
                          : `options[${fieldIndex}].price`
                      }
                      defaultValue={eachField.price}
                      render={(field) => <Input {...field} placeholder="ราคา" />}
                    />
                  </div>
                  <div className="px-2 w-1/5">
                    <Button onClick={() => remove(fieldIndex)} danger>
                      ลบ
                    </Button>
                  </div>
                </div>
              ))}
              <Button type="dashed" onClick={() => append({})} block>
                + เพิ่มออพชั่น
              </Button>
            </Form.Item>
            <Form.Item
              label="Topping"
              style={{ marginBottom: "5px" }}
              tooltip="รายการที่สามารถเพิ่มได้ แล้วมีราคาเพิ่ม เช่น ไข่ดาว ไข่เจียว"
            >
              {_.map(toppingField, (eachField, fieldIndex) => (
                <div className="flex justify-between my-2" key={eachField.id}>
                  <div className="w-3/5">
                    <Controller
                      control={control}
                      name={
                        onMany
                          ? `arr[${index}].toppings[${fieldIndex}].name`
                          : `toppings[${fieldIndex}].name`
                      }
                      defaultValue={eachField.name}
                      render={(field) => <Input {...field} placeholder="ชื่อ Topping" />}
                    />
                  </div>
                  <div className="px-2 w-1/5">
                    <Controller
                      control={control}
                      name={
                        onMany
                          ? `arr[${index}].toppings[${fieldIndex}].price`
                          : `toppings[${fieldIndex}].price`
                      }
                      defaultValue={eachField.price}
                      render={(field) => <Input {...field} placeholder="ราคา" />}
                    />
                  </div>
                  <div className="px-2 w-1/5">
                    <Button onClick={() => toppingRemove(fieldIndex)} danger>
                      ลบ
                    </Button>
                  </div>
                </div>
              ))}
              <Button type="dashed" onClick={() => toppingAppend({})} block>
                + เพิ่มออพชั่น
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default MenuForm
