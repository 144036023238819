import React, { forwardRef } from "react"
import { Switch, FormControl, FormLabel } from "@chakra-ui/react"
import ReactSelect from "react-select"
import _ from "lodash"

import { CountryList } from "../../../config/constant"

const CustomerForm = ({ register, defaultValue, watch, setValue }) => (
  <div>
    <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
      <div className="w-full px-4 ">
        <div className="flex flex-wrap">
          <div
            className={`relative w-full ${
              watch("is_legal_entity") ? "md:w-f" : "md:w-1/2"
            } mb-3 px-1`}
          >
            <label
              className="block uppercase text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              ชื่อลูกค้า*
            </label>
            <input
              name="firstname"
              type="text"
              required
              defaultValue={defaultValue?.firstname}
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="ชื่อลูกค้า"
            />
          </div>
          {!watch("is_legal_entity") && (
            <div className="relative w-full md:w-1/2 mb-3 px-1">
              <label
                className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                นามสกุลลูกค้า
              </label>
              <input
                name="lastname"
                type="text"
                defaultValue={defaultValue?.lastname}
                ref={register}
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                placeholder="นามสกุลลูกค้า"
              />
            </div>
          )}{" "}
          <div className="relative w-full md:w-1/2 mb-3 ">
            <label
              className="block uppercase text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              ประเภทลูกค้า
            </label>
            <FormControl display="flex" gridGap="2">
              <FormLabel>บุคคลธรรมดา</FormLabel>
              <Switch
                name="is_legal_entity"
                defaultChecked={defaultValue?.is_legal_entity}
                colorScheme="purple"
                ref={register}
              />
              <FormLabel>นิติบุคคล</FormLabel>
            </FormControl>
          </div>
          <div className="relative w-full md:w-1/2 mb-3 ">
            <label
              className="block uppercase text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              หมายเลขโทรศัพท์
            </label>
            <input
              name="tel"
              type="text"
              defaultValue={defaultValue?.tel}
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="หมายเลขโทรศัพท์"
            />
          </div>
          <div className="relative w-full md:w-1/2 mb-3 px-1 ">
            <label
              className="block uppercase text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              หมายเลขประจำตัวประชาชน / นิติบุคคล
            </label>
            <input
              name="id_card"
              type="text"
              defaultValue={defaultValue?.id_card}
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="หมายเลขประจำตัวประชาชน / นิติบุคคล"
            />
          </div>{" "}
          <div className="relative w-full md:w-1/2 px-1 mb-3 ">
            <label
              className="block uppercase text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              อีเมล
            </label>
            <input
              name="email"
              type="email"
              ref={register}
              defaultValue={defaultValue?.email}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="test@example.com"
            />
          </div>
        </div>

        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-sm font-semibold mb-2"
            htmlFor="grid-password"
          >
            ที่อยู่
          </label>
          <textarea
            name="address"
            defaultValue={defaultValue?.address}
            type="text"
            ref={register}
            className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="ที่อยู่"
          />
        </div>
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-sm font-semibold mb-2"
            htmlFor="grid-password"
          >
            สัญชาติ
          </label>
          <input
            type="hidden"
            ref={register}
            name="nationality"
            defaultValue={defaultValue?.nationality}
          />
          <ReactSelect
            placeholder="สัญชาติ"
            name="nationality"
            ref={register}
            options={CountryList}
            defaultValue={_.find(
              CountryList,
              (eachCountry) => eachCountry.value === (defaultValue?.nationality || "TH"),
            )}
            onChange={(newValue) => {
              setValue("nationality", newValue?.value)
            }}
          />
        </div>
      </div>
    </div>{" "}
  </div>
)

export default forwardRef(CustomerForm)
