import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import { BookingStatus } from "../../../config/constant/BookingStatus"
import Confirm from "../../common/Alert/ConfirmDialog"

export default function ConfirmButton({ bookingInfo, handleEditBooking }) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { t } = useTranslation()

  const handleConfirmBooking = () => {
    handleEditBooking(bookingInfo?.id, { status: BookingStatus.confirm })
    setIsConfirmOpen(false)
  }

  return (
    <div>
      <Confirm
        title={t("reservation.confirmBookingStatusChange")}
        body={t("reservation.changeToConfirm")}
        handleOnClose={() => setIsConfirmOpen(false)}
        handleSuccess={handleConfirmBooking}
        isOpen={isConfirmOpen}
      />
      <Button
        size="sm"
        colorScheme="yellow"
        disabled={
          bookingInfo?.status !== BookingStatus.book &&
          bookingInfo?.status !== BookingStatus.pending
        }
        width="20"
        onClick={() => setIsConfirmOpen(true)}
      >
        <i
          className={`fas fa-check mr-2 ${
            bookingInfo?.status === BookingStatus.book ||
            bookingInfo?.status === BookingStatus.pending
              ? `hidden`
              : ``
          }`}
        />{" "}
        {t("general.confirm")}
      </Button>
    </div>
  )
}
