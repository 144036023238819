import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../redux/actions"
import { SUPER_SYSTEM_MENU } from "../config/MenuList"

// components
import Sidebar from "../components/common/Sidebar/MainSidebar"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"

// views
import ModuleManagement from "../views/SuperSystem/ModuleManagement"
import OuterServiceManagement from "../views/SuperSystem/OuterServiceManagement"
import UserRole from "../config/UserRoles"

export default function SuperSystem() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("SuperSystemLayout : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.role === UserRole.SUPER_USER.toString() // Reserved for some system
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar
        menuList={SUPER_SYSTEM_MENU}
        isShow={showSidebar}
        setIsShowSidebar={setIsShowSidebar}
      />
      <div className={`relative  bg-gray-200 ${showSidebar ? "lg:ml-64" : ""} `}>
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/super-system/module" exact component={ModuleManagement} />
            <Route path="/super-system/outerservice" exact component={OuterServiceManagement} />
            <Redirect from="/super-system" to="/super-system/module" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <>
      <SpinnerLoading />
    </>
  )
}
