/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Button as AntdButton, Input, Select } from "antd"
import { Button } from "@chakra-ui/react"

import { GeneralMainLayout as Layout, SpinnerLoading, Alert, WhitePanel } from "../../components"
import * as actions from "../../redux/actions"
import { appConfig } from "../../config"
import { MobileOrderContext } from "../../contexts/MobileOrderContext"
import dayjs from "dayjs"

function FoodMenu() {
  const params = useParams()
  const history = useHistory()
  const fbOrder = useSelector((state) => state.fbOrder)
  const menu = useSelector((state) => state.menu)
  const menuCategory = useSelector((state) => state.menuCategory)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [size, setSize] = useState(25)
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const { tempOrderList, setTempOrderList, color } = useContext(MobileOrderContext)

  const getMenu = () => {
    dispatch(
      actions.getAllMenu({
        page: 1,
        size,
        query: {
          name,
          enable: true,
          category: selectedCategory,
          timeRestriction: true,
          currentTime: dayjs().format("HH:mm"),
        },
      }),
    )
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  useEffect(() => {
    dispatch(actions.getAllMenuCategory({ page: 1, size: appConfig.maxFetchSize }))
    dispatch(actions.getOneFBOrder(params?.id))
      .then(() => {
        getMenu()
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })

    return () => {}
  }, [params])

  useEffect(() => {
    getMenu()

    return () => {}
  }, [size, name, selectedCategory])

  const handleSendOrder = () => {
    const modifiedOrder = _.map(tempOrderList, (eachOrder) => ({
      ...eachOrder,
      order: fbOrder?._id,
    }))
    console.log("Temp Order List", tempOrderList)
    dispatch(actions.createOneFBOrderList({ arr: modifiedOrder, order: fbOrder?._id }))
      .then(() => {
        setTempOrderList([])
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
  }
  if (!isLoading) {
    return <SpinnerLoading />
  }

  const renderOptionPrice = (eachMenu) => {
    const minPrice = _.minBy(eachMenu?.options, "price")
    const maxPrice = _.maxBy(eachMenu?.options, "price")
    if (minPrice?.price === maxPrice?.price) {
      return minPrice?.price
    }
    return `${minPrice?.price} - ${maxPrice?.price}`
  }

  const menuCategoryOptions = [
    { value: "", label: t("foodAndBeverage.allCategory") },
    ..._.map(menuCategory?.arr, (eachMenuCategory) => ({
      value: eachMenuCategory?._id,
      label: eachMenuCategory?.translation?.[i18n.language]?.name,
    })),
  ]
  return (
    <Layout categoryTitle="Mobile Order" title={t("foodAndBeverage.title")}>
      <div className="flex px-4 justify-between">
        <Button
          variant="outline"
          colorScheme="purple"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="w-1/2 lg:w-1/4 flex">
          <Select
            defaultValue=""
            size="large"
            style={{ width: "80%" }}
            onChange={(value) => setSelectedCategory(value)}
            options={menuCategoryOptions}
            className="pr-2"
          />
          <Link to={`/mobile-order/detail/${fbOrder?._id}`}>
            <Button colorScheme="blue">
              <i className="fas fa-clipboard"></i>
            </Button>
          </Link>
        </div>
      </div>
      <Alert isError={isError} errMessage={errorMessage} handleOnClose={() => setIsError(false)} />
      <div className="px-4 py-2 pt-4">
        <Input
          addonBefore={<i className="fas fa-search"></i>}
          onChange={(e) => setSearchWord(e.target.value)}
          placeholder={t("general.search")}
          size="large"
        />
      </div>
      <div className="px-4 py-2 min-h-screen">
        {_.map(menu?.arr, (eachMenu, index) =>
          eachMenu?.runOutOff ? (
            <WhitePanel key={index}>
              <div className="flex">
                <div className="w-1/3 flex justify-center">
                  <img src={eachMenu?.image?.url} className="h-16 rounded-md object-cover" />
                </div>
                <div className="w-2/3 p-2">
                  <div className=" font-semibold">
                    {eachMenu?.translation?.[i18n.language]?.name ||
                      eachMenu?.translation?.th?.name}{" "}
                    {eachMenu?.runOutOff && (
                      <div className="text-sm text-red-500">{t("foodAndBeverage.runOutOff")}</div>
                    )}
                  </div>
                  <div>
                    {!_.isEmpty(eachMenu?.options) ? renderOptionPrice(eachMenu) : eachMenu?.price}{" "}
                    {t("general.baht")}
                  </div>
                </div>
              </div>
            </WhitePanel>
          ) : (
            <Link to={`/mobile-order/menu/detail/${eachMenu?._id}`}>
              <WhitePanel key={index}>
                <div className="flex">
                  <div className="w-1/3 flex justify-center">
                    <img src={eachMenu?.image?.url} className="h-16 rounded-md object-cover" />
                  </div>
                  <div className="w-2/3 p-2">
                    <div className=" font-semibold">
                      {eachMenu?.translation?.[i18n.language]?.name ||
                        eachMenu?.translation?.th?.name}{" "}
                    </div>
                    <div>
                      {!_.isEmpty(eachMenu?.options)
                        ? renderOptionPrice(eachMenu)
                        : eachMenu?.price}{" "}
                      {t("general.baht")}
                    </div>
                  </div>
                </div>
              </WhitePanel>
            </Link>
          ),
        )}
        <div className="flex justify-center mt-4">
          {menu?.total > _.size(menu?.arr) && (
            <AntdButton icon={<i className="fas fa-plus" />} onClick={() => setSize(size + 10)}>
              {t("general.add")}
            </AntdButton>
          )}
        </div>
      </div>
      <div className="w-full fixed flex gap-2 bottom-3">
        <div className="w-3/5">
          <Button
            colorScheme={color}
            onClick={() => handleSendOrder()}
            disabled={_.size(tempOrderList) === 0}
            isFullWidth
            leftIcon={<i className="fas fa-concierge-bell"></i>}
          >
            {t("foodAndBeverage.order")} {_.size(tempOrderList)} {t("foodAndBeverage.selectedItem")}
          </Button>
        </div>
        <div className="w-1/5">
          <Link to={`/mobile-order/temp-order/${params.id}`}>
            <Button colorScheme="pink" isFullWidth leftIcon={<i className="fas fa-list"></i>}>
              {t("foodAndBeverage.view")}
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default FoodMenu
