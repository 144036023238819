/* eslint-disable no-nested-ternary */
import { DateTime } from "luxon"
import dayjs from "dayjs"
import _ from "lodash"
import THBText from "thai-baht-text"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"

dayjs.extend(buddhistEra)
const dateFormat = { day: "numeric", month: "long", year: "numeric" }

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 *
 */
export default function NoVatRecipt({
  bookingInfo,
  information,
  systemInfo,
  cashierName,
  vatEnable = false,
  era = "be",
  displayRoom = false,
  vatStyle = "include",
  customBookNo,
  customBookingNo,
}) {
  const currentDayAmount = dayAmount(bookingInfo?.start, bookingInfo?.end)
  const noRoomBookingList = _.filter(
    bookingInfo?.no_room_booking,
    (each) => each?.amount && each?.amount > 0,
  )

  const price =
    (_.sum(
      _.map(
        bookingInfo?.list,
        (eachList) => (eachList?.price - eachList?.discount) * currentDayAmount,
      ),
    ) +
      (_.sum(
        _.map(
          noRoomBookingList,
          (eachList) => (eachList?.price - eachList?.discount) * currentDayAmount,
        ),
      ) || 0) +
      (_.sum(_.map(bookingInfo?.services, (eachServices) => eachServices?.price)) || 0) +
      (_.sum(_.map(bookingInfo?.fb_orders, (eachServices) => eachServices?.price)) || 0) || 0) -
    bookingInfo?.final_discount

  const priceBeforeFinalDiscount = price + (bookingInfo?.final_discount || 0)

  const blankSpace = [
    {
      text: "",
      border: [false, false, false, false],
      colSpan: 5,
    },
    {},
    {},
    {},
    {},
  ]

  const taxInvoicePart = [
    [
      {
        stack: [
          { text: "ราคาก่อนรวมภาษี / Price Before Vat", margin: [0, 2] },
          { text: "จำนวนภาษีมูลค่าเพิ่ม / Vat 7.00 %", margin: [0, 2] },
        ],
        colSpan: 4,
      },
      {},
      {},
      {},
      {
        stack: [
          {
            text: currencyFormatter.format(price - (price * 7) / 107),
            margin: [0, 2],
            alignment: "right",
          },
          {
            text: currencyFormatter.format((price * 7) / 107),
            margin: [0, 2],
            alignment: "right",
          },
        ],
      },
    ],
    [
      {
        text: `ราคารวมภาษีมูลค่าเพิ่ม / Grand Total ( ${THBText(price)} ) `,
        colSpan: 4,
        bold: true,
        margin: [0, 2],
      },
      {},
      {},
      {},
      {
        text: currencyFormatter.format(price),
        bold: true,
        alignment: "right",
      },
    ],
  ]

  const taxInvoiceExcludePart = [
    [
      {
        stack: [
          { text: "ราคาก่อนรวมภาษี / Price Before Vat", margin: [0, 2] },
          { text: "จำนวนภาษีมูลค่าเพิ่ม / Vat 7.00 %", margin: [0, 2] },
        ],
        colSpan: 4,
      },
      {},
      {},
      {},
      {
        stack: [
          {
            text: currencyFormatter.format(price),
            margin: [0, 2],
            alignment: "right",
          },
          {
            text: currencyFormatter.format((price * 7) / 100),
            margin: [0, 2],
            alignment: "right",
          },
        ],
      },
    ],
    [
      {
        text: `ราคารวมภาษีมูลค่าเพิ่ม / Grand Total ( ${THBText((price * 107) / 100)} ) `,
        colSpan: 4,
        bold: true,
        margin: [0, 2],
      },
      {},
      {},
      {},
      {
        text: currencyFormatter.format((price * 107) / 100),
        bold: true,
        alignment: "right",
      },
    ],
  ]
  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "ใบเสร็จรับเงิน",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: vatEnable
          ? "ใบเสร็จรับเงิน/ใบกำกับภาษี (Recipt/Tax Invoice)"
          : "ใบเสร็จรับเงิน / Reciept ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        columns: [
          {
            text: [
              { text: "วันที่เช็คอิน / Check In : ", bold: true },
              {
                text: `  ${dayjs(bookingInfo?.start).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
          },
          customBookNo
            ? {
                text: [
                  { text: "เล่มที่/เลขที่ :", bold: true },
                  {
                    text: ` ${customBookNo} / ${customBookingNo}`,
                    margin: [5, 0],
                  },
                ],
                margin: [0, 3],
              }
            : {
                text: [
                  { text: vatEnable ? "เล่มที่/เลขที่ :" : "เลขที / No. :", bold: true },
                  {
                    text: vatEnable
                      ? `  1/${bookingInfo?.booking_no}`
                      : `  ${bookingInfo?.booking_no}`,
                    margin: [5, 0],
                  },
                ],
                margin: [0, 3],
              },
        ],
      },
      {
        columns: [
          {
            text: [
              { text: "วันเช็คเอาท์ / Checkout Date : ", bold: true },
              {
                text: `  ${dayjs(bookingInfo?.end).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "วันที่พิมพ์ / Print Date : ", bold: true },
              {
                text: `  ${dayjs(bookingInfo?.updatedAt).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 3, 0, 15],
      },
      {
        text: [
          { text: "ลูกค้า / Customer : ", bold: true },
          {
            text: `  ${bookingInfo?.customer?.firstname || ""}  ${
              bookingInfo?.customer?.lastname || ""
            }`,
            margin: [5, 0],
          },
        ],
        margin: [0, 2],
      },
      {
        text: [
          { text: "ที่อยู่ / Address : ", bold: true },
          {
            text: `  ${bookingInfo?.customer?.address || ""}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 2],
      },
      {
        text: [
          { text: "หมายเลขโทรศัพท์ / Tel :", bold: true },
          {
            text: `  ${bookingInfo?.customer?.tel || "-"}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 2],
      },
      {
        text: [
          {
            text: "เลขประจำตัวผู้เสียภาษี / Tax ID :",
            bold: true,
          },
          {
            text: `  ${bookingInfo?.customer?.id_card || ""}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 2],
      },
      {
        text: "รายการค่าห้องพัก / Booking List",
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, "*", 40, 40, 80],
          body: [
            [
              { text: "ที่\nNo", bold: true, alignment: "center" },
              { text: "สินค้า/ รายละเอียด\nDescription", bold: true, alignment: "center" },
              { text: "หน่วยละ\nPrice", bold: true, alignment: "center" },
              { text: "จำนวน\nQuantity", bold: true, alignment: "center" },
              { text: "ราคา\nAmount", bold: true, alignment: "center" },
            ],
            ..._.map(bookingInfo?.list, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${displayRoom ? `ห้องพัก ${eachList?.room?.name}` : ""} ${
                  eachList?.room?.type?.translation?.th?.name || eachList?.room?.type?.name || ""
                } `,
                margin: [0, 2],
              },
              {
                text: (
                  parseFloat(eachList?.price || 0) - parseFloat(eachList?.discount || 0)
                ).toFixed(2),
                margin: [0, 2],
                alignment: "right",
              },
              {
                text: currentDayAmount,
                alignment: "right",
              },
              {
                text: currencyFormatter.format(
                  (parseFloat(eachList?.price || 0) - parseFloat(eachList?.discount || 0)) *
                    currentDayAmount,
                ),
                margin: [0, 2],
                alignment: "right",
              },
            ]),
            ..._.map(noRoomBookingList, (eachList, index) => [
              { text: index + 1 + _.size(bookingInfo?.list), margin: [0, 2] },
              {
                text: `ห้องพัก ${
                  eachList?.roomtype?.translation?.th?.name || eachList?.roomtype?.name || ""
                }`,
                margin: [0, 2],
              },
              {
                text: currencyFormatter.format(eachList?.price || 0 - eachList?.discount || 0),
                margin: [0, 2],
                alignment: "right",
              },
              {
                text: currentDayAmount,
                alignment: "right",
              },
              {
                text: currencyFormatter.format(
                  parseFloat(eachList?.price || 0 - eachList?.discount || 0) * currentDayAmount,
                ),
                margin: [0, 2],
                alignment: "right",
              },
            ]),
            ..._.map(bookingInfo?.services, (eachList, index) => [
              {
                text: index + 1 + _.size(bookingInfo?.list) + _.size(noRoomBookingList),
                margin: [0, 2],
              },
              {
                text: eachList?.service?.translation?.th?.name || eachList?.service?.name || "",
                margin: [0, 2],
              },
              {
                text: currencyFormatter.format(parseFloat(eachList?.service?.price)),
                margin: [0, 2],
                alignment: "right",
              },
              { text: `${eachList?.amount}`, margin: [0, 2], alignment: "right" },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
              },
            ]),
            ..._.map(bookingInfo?.fb_orders, (eachList, index) => [
              {
                text:
                  index +
                  1 +
                  _.size(bookingInfo?.list) +
                  _.size(noRoomBookingList) +
                  _.size(bookingInfo?.services),
                margin: [0, 2],
              },
              {
                text: `อาหาร หมายเลขออเดอร์ ${eachList?.order_no || ""} (${
                  eachList?.date
                    ? dayjs(eachList?.date).format(era === "be" ? "D MMMM BBBB" : "D/MM/YYYY")
                    : ""
                })`,
                margin: [0, 2],
              },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
              },
              { text: "1", margin: [0, 2], alignment: "right" },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
              },
            ]),
            bookingInfo?.final_discount > 0
              ? [
                  {
                    stack: [
                      { text: "ราคาก่อนรวมส่วนลด / Price Before Discount", margin: [0, 2] },
                      { text: "ส่วนลดท้ายใบเสร็จ / Discount", margin: [0, 2] },
                    ],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    stack: [
                      {
                        text: currencyFormatter.format(priceBeforeFinalDiscount),
                        margin: [0, 2],
                        alignment: "right",
                      },
                      {
                        text: currencyFormatter.format(bookingInfo?.final_discount),
                        margin: [0, 2],
                        alignment: "right",
                      },
                    ],
                  },
                ]
              : blankSpace,
            vatEnable
              ? vatStyle === "include"
                ? taxInvoicePart[0]
                : taxInvoiceExcludePart[0]
              : blankSpace,
            vatEnable
              ? vatStyle === "include"
                ? taxInvoicePart[1]
                : taxInvoiceExcludePart[1]
              : [
                  {
                    text: `รวมเป็นเงิน / Grand Total ( ${THBText(
                      vatStyle === "include" ? price : (price * 107) / 100,
                    )} ) `,
                    colSpan: 4,
                    bold: true,
                    margin: [0, 2],
                  },
                  {},
                  {},
                  {},
                  {
                    text: currencyFormatter.format(
                      vatStyle === "include" ? price : (price * 107) / 100,
                    ),
                    bold: true,
                    alignment: "right",
                  },
                ],
            bookingInfo?.payment?.paid_amount >=
            (vatStyle === "include" ? price : (price * 107) / 100)
              ? blankSpace
              : [
                  {
                    stack: [
                      { text: "มัดจำ / Deposit", margin: [0, 2] },
                      { text: "ค้างชำระ / Remain", margin: [0, 2] },
                    ],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    stack: [
                      {
                        text: currencyFormatter.format(bookingInfo?.payment?.paid_amount),
                        margin: [0, 2],
                        alignment: "right",
                      },
                      {
                        text: currencyFormatter.format(
                          (vatStyle === "include" ? price : (price * 107) / 100) -
                            bookingInfo?.payment?.paid_amount,
                        ),
                        margin: [0, 2],
                        alignment: "right",
                      },
                    ],
                  },
                ],
          ],
        },

        margin: [0, 15, 0, 0],
        fontSize: 10,
      },
      {
        stack: [
          { text: "หมายเหตุ / Remark", bold: true, margin: [0, 10, 0, 0] },
          {
            text: bookingInfo?.remark || "-",
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้รับบริการ / Customer" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: `(........${
                  cashierName || "........................................"
                }........)`,
                margin: [0, 2],
              },
              { text: "ผู้รับเงิน / Cashier" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
