import {
  SUB_ROOMTYPE_ALL,
  SUB_ROOMTYPE_CREATE,
  SUB_ROOMTYPE_DELETE,
  SUB_ROOMTYPE_GET,
  SUB_ROOMTYPE_PUT,
  SUB_ROOMTYPE_FROM_TYPE,
  SUB_ROOMTYPE_ERROR,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SUB_ROOMTYPE_ALL:
      return {
        ...action.payload,
        rows: null,
        arr: action.payload?.rows,
        isLoading: true,
      }
    case SUB_ROOMTYPE_FROM_TYPE:
      return {
        arr: action.payload,
        isLoading: true,
      }
    case SUB_ROOMTYPE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case SUB_ROOMTYPE_CREATE:
      return { isLoading: false }
    case SUB_ROOMTYPE_PUT:
      return { isLoading: false }
    case SUB_ROOMTYPE_DELETE:
      return { isLoading: false }
    case SUB_ROOMTYPE_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
