/* eslint-disable no-underscore-dangle */
import React, { useContext } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Result } from "antd"
import { Button } from "@chakra-ui/react"

import OnlineBookingLayout from "../../components/layout/OnlineBookingLayout"
import { WhitePanel } from "../../components"
import { useQuery } from "../../config"

import { OnlineBookingContext } from "../../contexts/OnlineBookingContext"

function BookingSuccess() {
  const { t } = useTranslation()
  const query = useQuery()
  const dispatch = useDispatch()
  const {
    color,
    specifyBookingList,
    checkinDate,
    checkoutDate,
    setCheckinDate,
    setCheckedoutDate,
    bookingInfo,
  } = useContext(OnlineBookingContext)

  return (
    <OnlineBookingLayout
      title={t("reservation.success")}
      checkinDate={checkinDate}
      checkoutDate={checkoutDate}
      handleNext={() => {}}
      handlePrevious={() => {}}
      //   selectedRoomType={selectedRoomType}
      //   setSelectedRoomType={setSelectedRoomType}
      setCheckOutDate={setCheckedoutDate}
      setCheckedInDate={setCheckinDate}
      selectedRooms={specifyBookingList}
      selectedIndex={5}
      enableVerticleForm={false}
    >
      <WhitePanel>
        <div className="pb-20">
          <Result
            status="success"
            title={<div className="font-sans">{t("reservation.onlineBookingComplete")}</div>}
            //   subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={<Button colorScheme={color}>{t("general.backToHomepage")}</Button>}
          />
        </div>
      </WhitePanel>
    </OnlineBookingLayout>
  )
}

export default BookingSuccess
