import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Button, useToast } from "@chakra-ui/react"

import Layout from "../../../components/layout/GeneralLayout"
import { WhitePanel } from "../../../components/common/Cards"
import { ServiceOrderContainer } from "../../../components/containers"
import { SpinnerLoading } from "../../../components/Loading"
import { Alert } from "../../../components/common/Alert"
import { ServiceActionButtonList } from "../../../components/Buttons/ServiceOrderActionButton"
import * as actions from "../../../redux/actions"

export default function InfoServiceOrder() {
  const { t } = useTranslation()
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const toast = useToast()
  const serviceOrder = useSelector((state) => state.serviceOrder)

  const [isLoading, setIsLoading] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    dispatch(actions.getOneServiceOrder(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((error) => {
        setIsError(true)
        setErrMessage(error?.message)
      })

    return () => {}
  }, [])

  const handleDeleteOrder = (id) => {
    dispatch(actions.editOneServiceOrder(id, { deleted: true }))
      .then(() => {
        dispatch(actions.getOneServiceOrder(params.id))
          .then(() => {
            history.goBack()
            toast({ title: "ลบการจองสำเร็จ", status: "success" })
          })
          .catch((error) => {
            setIsError(true)
            setErrMessage(error?.message)
          })
      })
      .catch((error) => {
        setErrMessage(error.message)
        setIsError(true)
      })
  }

  const handleEditOrder = (id, payload) => {
    dispatch(actions.editOneServiceOrder(id, payload))
      .then(() => {
        dispatch(actions.getOneServiceOrder(params.id))
          .then(() => {
            toast({ title: "แก้ไขการจองออเดอร์สำเร็จ", status: "success" })
          })
          .catch((error) => {
            setIsError(true)
            setErrMessage(error?.message)
          })
      })
      .catch((error) => {
        setErrMessage(error.message)
        setIsError(true)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout
      categoryTitle={t("reservation.title")}
      title={t("reservation.infoServiceReservation")}
      preview
    >
      <Alert isError={isError} errMessage={errMessage} handleOnClose={() => setIsError(false)} />

      <div className="flex justify-start px-4">
        <Button colorScheme="purple" onClick={() => history.goBack()} variant="outline">
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 min-h-screen">
        <WhitePanel>
          <ServiceOrderContainer
            serviceOrderInfo={serviceOrder}
            handleEditServiceOrder={handleEditOrder}
          />
          {!serviceOrder?.completed && !serviceOrder?.deleted && (
            <div className="my-2 w-full">
              <h6 className="text-xs font-bold font-sans">{t("reservation.changeStatus")}</h6>
              <div className="my-2 justify-start flex  ">
                <ServiceActionButtonList
                  eachOrder={serviceOrder}
                  handleDeleteOrder={handleDeleteOrder}
                  handleEditOrder={handleEditOrder}
                  place="info"
                />
              </div>
            </div>
          )}
        </WhitePanel>
      </div>
    </Layout>
  )
}
