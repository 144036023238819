import {
  TAX_INVOICE_ALL,
  TAX_INVOICE_CREATE,
  TAX_INVOICE_DELETE,
  TAX_INVOICE_GET,
  TAX_INVOICE_UPDATE,
  TAX_INVOICE_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const taxInvoiceActions = new GeneralReduxActionClass({
  allConst: TAX_INVOICE_ALL,
  createConst: TAX_INVOICE_CREATE,
  deleteConst: TAX_INVOICE_DELETE,
  errorConst: TAX_INVOICE_ERROR,
  getConst: TAX_INVOICE_GET,
  putConst: TAX_INVOICE_UPDATE,
  backendPrefix: "taxinvoice",
  description: "ใบกำกับภาษี",
})

export const getAllTaxInvoice = (query) => taxInvoiceActions.getAll(query)
export const getOneTaxInvoice = (id) => taxInvoiceActions.getOne(id)
export const createOneTaxInvoice = (payload) => taxInvoiceActions.createOne(payload)
export const editOneTaxInvoice = (id, payload) => taxInvoiceActions.editOne(id, payload)
export const deleteOneTaxInvoice = (id) => taxInvoiceActions.deleteOne(id)
