import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { FormControl, Select, FormLabel, Input, Checkbox } from "@chakra-ui/react"
import { HorizontalType } from "../../../../config/constant"
import SliderPaddingPicture from "../../../common/Slider/SliderPaddingPicture"
import SliderPictureArea from "../../../common/Slider/SliderPictureArea"
import { RichTextEditor } from "../../../common/RichTextEditor"
import { Controller } from "react-hook-form"


import {
  ComponentHeightHorizontal,
  ComponentWidthHorizontal
} from "../../../../config/constant"


function HorizontalList({ register, control, defaultValue, watch, setValue, activeTab }) {
  const { i18n } = useTranslation()

  return (
    <div>
      <div className="flex flex-wrap pt-1">
        <div className="w-full  px-0 py-1">
          {activeTab === 'Desktop' && (
            <div>
              <div className="pb-8">
                <div className="border-2  p-4 pt-2  justify-items-center items-center  border-black font-head flex justify-center">
                  จัดการพื้นทีรูปภาพ
                </div>
              </div>
              <div>
                <div className="pb-10  ">
                  <div className="flex gap-24 pr-40">
                    <div className="pb-12">
                      <SliderPaddingPicture
                        rightPaddingPicture='desktop.Horizontal_Gallery.right_padding_picture'
                        leftPaddingPicture='desktop.Horizontal_Gallery.left_padding_picture'
                        topPaddingPicture='desktop.Horizontal_Gallery.top_padding_picture'
                        bottomPaddingPicture='desktop.Horizontal_Gallery.bottom_padding_picture'
                        height_picture='desktop.Horizontal_Gallery.height_picture'
                        // width_picture='desktop.Horizontal_Gallery.width_picture'
                        register={register}
                        defaultValue={defaultValue}
                        watch={watch}
                      />
                    </div>
                    <div className="flex gap-4 lg:pl-0 pl-0  ">
                      <div className="">
                        <FormLabel fontSize="sm">ข้อความต้องการใส่ในรูปภาพ</FormLabel>
                        {/* <div className="pt-60 text-red-600">** ความกว้างครึ่งส่วนประกอบประมาณ 767 px</div>
                        <div className="pt-0 text-red-400">** ความกว้างเต็มส่วนประกอบประมาณ 1480 px</div>
                        <div className="pt-0 text-blue-600">**ยึดตามหน้าจอ notebook!!</div> */}
                      </div>
                      <div className="w-64 ">
                        <label
                          className="lg:block  text-gray-700 text-sm font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          ภาษาไทย
                        </label>
                        <Controller
                          control={control}
                          name="Horizontal_Gallery.translation.th.text_content"
                          defaultValue={defaultValue?.Horizontal_Gallery?.translation?.th?.text_content}
                          render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                        />
                      </div>
                      <div className="w-64 pb-0">
                        <label
                          className="lg:block flex text-gray-700 text-sm font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          ภาษาอังกฤษ
                        </label>
                        <Controller
                          control={control}
                          name="Horizontal_Gallery.translation.en.text_content"
                          defaultValue={defaultValue?.Horizontal_Gallery?.translation?.en?.text_content}
                          render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                        />
                      </div>

                    </div>
                  </div>
                  <SliderPictureArea
                    name=""
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                </div>
                <FormLabel fontSize="sm">แสดงรายละเอียดคำอธิบายของรูปภาพ</FormLabel>
                <FormControl display="">
                  <Checkbox
                    ref={register}
                    size="sm"
                    name="show_content">
                    เปิดใช้
                  </Checkbox>
                  {watch(`show_content`) === true && (
                    <div>
                      <div className="w-full lg:w-1/2 px-2 py-1">
                        <FormLabel fontSize="sm">รปแบบการจัดวาง</FormLabel>
                        <Select
                          ref={register}
                          placeholder="การจัดวาง"
                          defaultValue={defaultValue?.type_attribute?.horizontal_type}
                          name="type_attribute.horizontal_type"
                          size="sm"
                        >
                          {_.map(HorizontalType, (horizotalType, index) => (
                            <option key={index} value={horizotalType.status_code}>
                              {i18n?.language === "th" ? horizotalType.description : horizotalType.descriptionEn}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )}
                </FormControl>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {activeTab === 'Desktop' && (
          <div className="w-full lg:w-1/2 px-2 py-1">
            <FormLabel fontSize="sm">จำนวนรายการต่อ 1 แถว</FormLabel>
            <Input
              ref={register}
              placeholder="จำนวนรายการต่อ 1 แถว"
              name="desktop.card_content.number_per_row"
              type="number"
              size="sm"
              required
              defaultValue={defaultValue?.desktop?.card_content?.number_per_row || 10}
            />
          </div>
        )}

        {activeTab === 'Mobile' && (
          <div className="w-full lg:w-1/2 px-2 py-1">
            <div className="flex pr-10 gap-10 pt-3"> {/* ปรับขนาดระยะห่าง pr-10 จากเดิมเป็น pr-80 */}
              <div className="w-full lg:w-1/2 px-2 py-1">
                <label className="text-sm font-semibold text-gray-700">ความกว้าง</label> {/* ปรับตัวอักษรให้เป็น font-semibold */}
                <select
                  ref={register}
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="ความกว้าง"
                  defaultValue={defaultValue?.mobile?.width}
                  name="mobile.width"
                  size="sm"
                >
                  {_.map(ComponentWidthHorizontal, (each, index) => (
                    <option
                      key={index}
                      value={each.status_code}
                      className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                    >
                      {i18n?.language === "th" ? each.description : each.descriptionEn}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full lg:w-1/2 px-2 py-1">
                <label className="text-sm font-semibold text-gray-700">ความสูง</label> {/* ปรับตัวอักษรให้เป็น font-semibold */}
                <select
                  ref={register}
                  className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  placeholder="ความสูง"
                  defaultValue={defaultValue?.mobile?.height}
                  name="mobile.height"
                  size="sm"
                >
                  {_.map(ComponentHeightHorizontal, (each, index) => (
                    <option
                      key={index}
                      value={each.status_code}
                      className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                    >
                      {i18n?.language === "th" ? each.description : each.descriptionEn}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <FormLabel fontSize="sm">จำนวนรายการต่อ 1 แถว</FormLabel>
            <Input
              ref={register}
              placeholder="จำนวนรายการต่อ 1 แถว"
              name="mobile.card_content.number_per_row"
              type="number"
              size="sm"
              required
              defaultValue={defaultValue?.mobile?.card_content?.number_per_row || 10}
            />
          </div>
        )}

        <div className="pl-2">
          <FormLabel fontSize="sm" className="pt-4">ลิมิตของจำนวนรูปภาพ</FormLabel>
          {!watch("type_attribute.unlimit") && (
            <Input
              ref={register}
              placeholder="ลิมิตของจำนวนรูปภาพ"
              name="type_attribute.limit"
              type="number"
              size="sm"
              required
              defaultValue={defaultValue?.type_attribute?.limit || 10}
            />
          )}

          <FormControl display="flex">
            <Checkbox
              ref={register}
              name="type_attribute.unlimit"
              defaultChecked={defaultValue?.type_attribute?.limit === null}
              size="sm"
              onChange={(event) => {
                if (event.target.checked) {
                  setValue("type_attribute.limit", null)
                  setValue("type_attribute.unlimit", true)
                } else {
                  setValue("type_attribute.limit", 10)
                  setValue("type_attribute.unlimit", false)
                }
              }}
            >
              ไม่จำกัด
            </Checkbox>
          </FormControl>
        </div>
      </div>
    </div>
  )
}

export default forwardRef(HorizontalList)
