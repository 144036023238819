import React from "react"
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react"
import _ from "lodash"
import { useFieldArray } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { TextArea, TextField } from "../../common/Input"
import { WhitePanel } from "../../common/Cards"

const MeetingRoomForm = ({
  register,
  defaultValue,
  watch,
  control,
  imageOnCreated,
  handleDeletePhoto,
  setAddModalOpen,
}) => {
  const {
    fields: priceListFields,
    append: priceListAppend,
    remove: priceListRemove,
  } = useFieldArray({ control, name: "price_list" })
  const { t } = useTranslation()

  return (
    <WhitePanel>
      <div className="flex flex-wrap">
        <div className="flex flex-wrap w-full lg:w-1/2 px-2">
          <div className="w-full my-1 px-1">
            <TextField
              name="translation.th.name"
              label="ชื่อห้องประชุม *"
              register={register}
              defaultValue={defaultValue?.translation?.th?.name || defaultValue?.name}
              type="text"
              placeholder="ชื่อห้องประชุม"
              required
            />
          </div>
          <div className="w-full my-1 px-1">
            <TextField
              name="translation.en.name"
              label="ชื่อห้องประชุม (ภาษาอังกฤษ)"
              register={register}
              defaultValue={defaultValue?.translation?.en?.name}
              type="text"
              placeholder="Meeting Room Name"
            />
          </div>
          <div className="w-full my-1 px-1">
            <TextArea
              name="translation.th.description"
              label="รายละเอียด"
              register={register}
              defaultValue={defaultValue?.translation?.th?.description}
              type="text"
              placeholder="รายละเอียด"
              rows={4}
            />
          </div>

          <div className="w-full my-1 px-1">
            <TextArea
              name="translation.en.description"
              label="รายละเอียด (ภาษาอังกฤษ)"
              register={register}
              defaultValue={defaultValue?.translation?.en?.description}
              type="text"
              placeholder="Description"
              rows={4}
            />
          </div>
          <div className="w-full lg:w-1/2 my-1 px-1">
            <TextField
              name="capacity"
              label="ความจุ (คน)"
              register={register}
              defaultValue={defaultValue?.capacity}
              type="text"
              placeholder="ความจุ"
            />
          </div>

          <div className="w-full lg:w-1/2 my-1 px-1">
            <TextField
              name="size"
              label="ขนาด"
              register={register}
              defaultValue={defaultValue?.size}
              type="text"
              placeholder="ขนาด"
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-wrap px-2">
          <div className="w-full my-1 px-1">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              การคิดราคา
            </label>
            <FormControl display="flex" gridGap="2">
              <div>คิดราคาเป็นชั่วโมง</div>

              <Switch
                name="use_slot_price"
                defaultValue={defaultValue?.use_slot_price}
                defaultChecked={defaultValue?.use_slot_price}
                ref={register}
              >
                คิดจากราคาเป็นช่วง
              </Switch>
            </FormControl>{" "}
            {watch("use_slot_price") ? (
              <div>
                <FormControl className="my-1">
                  <FormLabel fontSize="sm">ราคาช่วงเวลาเช้า-เที่ยง</FormLabel>
                  <Input
                    size="sm"
                    placeholder="ช่วงเวลาเช้า - เที่ยง"
                    name={`slot_price.slot_morning`}
                    ref={register}
                    defaultValue={defaultValue?.slot_price?.slot_morning}
                  />
                </FormControl>

                <FormControl className="my-1">
                  <FormLabel fontSize="sm">ราคาช่วงเวลาเที่ยง - เย็น</FormLabel>
                  <Input
                    size="sm"
                    placeholder="ช่วงเวลาเที่ยง - เย็น"
                    name={`slot_price.slot_afternoon`}
                    ref={register}
                    defaultValue={defaultValue?.slot_price?.slot_afternoon}
                  />
                </FormControl>

                <FormControl className="my-1">
                  <FormLabel fontSize="sm">ราคาช่วงเวลาค่ำ</FormLabel>
                  <Input
                    size="sm"
                    placeholder="ช่วงเวลาค่ำ"
                    name={`slot_price.slot_evening`}
                    ref={register}
                    defaultValue={defaultValue?.slot_price?.slot_evening}
                  />
                </FormControl>
              </div>
            ) : (
              <div>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>จำนวนชั่วโมง</Th>
                      <Th>ราคา (บาท)</Th>
                      <Th>ดำเนินการ</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {_.map(priceListFields, (eachField, index) => (
                      <Tr key={eachField.id}>
                        <Td>
                          {index === 0 ? (
                            <div className="flex gap-2 items-center">
                              <div> 0</div>
                              <div> - </div>
                              <div>
                                <Input
                                  size="sm"
                                  defaultValue={defaultValue?.price_list?.[index]?.hour}
                                  name={`price_list[${index}].hour`}
                                  ref={register}
                                />
                              </div>
                              <div>ชม.</div>
                            </div>
                          ) : (
                            <div className="flex gap-2 items-center">
                              <div> {watch(`price_list[${index - 1}].hour`)}</div>
                              <div> - </div>
                              <div>
                                <Input
                                  size="sm"
                                  defaultValue={defaultValue?.price_list?.[index]?.hour}
                                  name={`price_list[${index}].hour`}
                                  ref={register}
                                />
                              </div>
                              <div>ชม.</div>
                            </div>
                          )}
                        </Td>
                        <Td>
                          <Input
                            size="sm"
                            name={`price_list[${index}].price`}
                            defaultValue={defaultValue?.price_list?.[index]?.price}
                            ref={register}
                          />
                        </Td>
                        <Td>
                          <Button
                            size="sm"
                            colorScheme="red"
                            type="button"
                            onClick={() => priceListRemove(index)}
                          >
                            ลบ
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Td>
                        <Button size="sm" colorScheme="purple" onClick={() => priceListAppend({})}>
                          เพิ่ม
                        </Button>
                      </Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            )}
          </div>
          <div className="w-full px-4">
            <div className="relative w-full mb-3">
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                รูปภาพห้องประชุม
              </label>
              <div className="flex flex-wrap gap-2 overflow-x-auto mb-3">
                {_.map(defaultValue?.images, (image, index) => (
                  <div key={index} className="mx-2 rounded-md border border-gray-300">
                    <img src={image?.url} className="w-48" />
                    <div className="my-2 flex justify-center">
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => {
                          handleDeletePhoto(index)
                        }}
                      >
                        {t("general.delete")}
                      </Button>
                    </div>
                  </div>
                ))}
                {_.map(imageOnCreated, (image, index) => (
                  <div key={index} className="mx-2 rounded-md border border-gray-300">
                    <img src={image?.data_url} className="w-48" />
                    <div className="my-2 flex justify-center">
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => {
                          handleDeletePhoto(index)
                        }}
                      >
                        {t("general.delete")}
                      </Button>
                    </div>
                  </div>
                ))}
                <div
                  className="h-48 w-48 self-center rounded-lg bg-gray-200 hover:bg-gray-300 cursor-pointer p-4 flex justify-center "
                  onClick={() => setAddModalOpen(true)}
                >
                  <div className="self-center">
                    <i className="fas fa-plus text-3xl text-gray-500" />
                    <h3 className="mt-2 text-gray-500 text-2xl"> {t("general.add")} </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WhitePanel>
  )
}

export default MeetingRoomForm
