import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import _ from "lodash"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import Alert from "../../../components/common/Alert/AlertDialog"
import { MeetingRoomForm } from "../../../components/Forms/Room"
import * as actions from "../../../redux/actions"
import { ImageUpload } from "../../../components"

export default function CreateMeetingRoom() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [uploadedImage, setUploadedImage] = useState()

  const { t } = useTranslation()
  const { register, handleSubmit, watch, control } = useForm()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCreateMeetingRoom = (data) => {
    dispatch(
      actions.createOneMeetingRoom({
        ...data,
        images: _.map(uploadedImage, (image) => ({
          imageType: "roomTypes",
          image: image.data_url,
        })),
      }),
    )
      .then(() => {
        setIsLoading(true)
        history.goBack()
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }

  if (!isLoading) {
    return (
      <div>
        <SpinnerLoading />
      </div>
    )
  }

  const renderModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-sans">
          อัพโหลดรูปภาพ
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <ImageUpload images={uploadedImage} setImages={setUploadedImage} maxNumber={5} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="green">
            {t("general.save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )

  return (
    <Layout categoryTitle={t("roomtype.title")} title={"เพิ่มห้องประชุม"}>
      {renderModal()}
      <Alert errMessage={errMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full min-h-screen overflow-x-auto">
        <form onSubmit={handleSubmit(handleCreateMeetingRoom)}>
          <MeetingRoomForm
            register={register}
            watch={watch}
            control={control}
            handleDeletePhoto={onOpen}
            imageOnCreated={uploadedImage}
            setAddModalOpen={onOpen}
          />
          <Button isFullWidth colorScheme="purple" type="submit">
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
