import { USER_ALL, USER_GET, USER_PUT, USER_DEL, USER_POST } from "../../actions/types"

const initialState = {
  user: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case USER_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case USER_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case USER_POST:
      return action.payload
    case USER_PUT:
      return action.payload
    case USER_DEL:
      return { isLoading: true }
    default:
      return state
  }
}
