import {
  SYSTEM_GET,
  SYSTEM_POST,
  SYSTEM_PUT,
  SYSTEM_DEL,
} from '../../actions/types'

const initialState = {
  system: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SYSTEM_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case SYSTEM_POST:
      return action.payload
    case SYSTEM_PUT:
      return action.payload
    case SYSTEM_DEL:
      return { isLoading: true }
    default:
      return state
  }
}
