import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm, Controller } from "react-hook-form"
import { Checkbox, Form, Select } from "antd"
import _ from "lodash"

import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import EachPageComposite from "../../components/Forms/HotelSite/PageComposites"
import { WhitePanel } from "../../components/common/Cards"
import { appConfig } from "../../config"

export default function EditRecentArticleDescription() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { control, handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const info = useSelector((state) => state.information)
  const articleCategories = useSelector((state) => state.articleCategories)

  const onSubmit = (data) => {
    const payload = {
      composite: {
        translation: {
          th: {
            ...info?.composite?.translation?.th,
            ...data?.composite?.translation?.th,
          },
          en: {
            ...info?.composite?.translation?.en,
            ...data?.composite?.translation?.en,
          },
        },
      },
      recent_article: {
        use_category_filter: data?.recent_article?.use_category_filter,
        filter_category: data?.recent_article?.filter_category,
      },
    }
    dispatch(actions.editOneInformation(info?.id, payload)).then(() => {
      setIsLoading(false)
      dispatch(actions.getInformation()).then(() => {
        setIsLoading(true)
        history.goBack()
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      dispatch(actions.getAllArticleCategory({ page: 1, size: appConfig.maxFetchSize }))
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="แก้ไขหัวข้อส่วนของบล็อกข่าวสาร">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <WhitePanel>
            <Form>
              <Form.Item label="กรองโดยใช้หมวดหมู่ของบทความ">
                <Controller
                  name="recent_article.use_category_filter"
                  control={control}
                  defaultValue={info?.recent_article?.use_category_filter}
                  render={(field) => (
                    <div>
                      <Checkbox
                        {...field}
                        defaultChecked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      >
                        กรองโดยใช้หมวดหมู่
                      </Checkbox>
                    </div>
                  )}
                />
              </Form.Item>
              <Form.Item label="หมวดหมู่ที่จะใช้กรอง">
                <Controller
                  name="recent_article.filter_category"
                  control={control}
                  defaultValue={info?.recent_article?.filter_category}
                  render={(field) => (
                    <div>
                      <Select {...field} style={{ width: "50%" }}>
                        {_.map(articleCategories?.arr, (category) => (
                          <Select.Option key={category.id} value={category.id}>
                            {category?.translation?.th?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                />
              </Form.Item>
            </Form>
          </WhitePanel>
          <EachPageComposite
            defaultInfo={info}
            compositePrefix={"recent_article"}
            title={"หัวข้อส่วนของบล็อกข่าวสาร"}
            control={control}
            brief
            description
          />
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
