import _ from "lodash"
import React from "react"
import moment from "moment"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

import { BookingSourceBadge } from "../common/Badge"

import { selectTailwindStatusColor } from "../../util"
import Dot from "../common/Dot/dot"

export default function BookingList({ booking }) {
  return (
    <div className="pb-2 mx-4">
      <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
        <div className="w-full ">
          <div className="flex justify-between">
            <h1 className="font-bold pb-2">
              <i className="fas fa-book"></i> {booking?.booking_no}
            </h1>
            <Link to={`${booking?.log ? "/history/view" : "/reservation/view"}/${booking?._id}`}>
              <Button size="sm">
                <i className="fas fa-info-circle"></i>
              </Button>
            </Link>
          </div>
          <div>
            <h5 className="text-sm text-gray-600">ห้องที่เข้าพัก</h5>
            <div className="flex flex-wrap">
              {_.map(booking?.list, (eachList, index) => (
                <div key={index} className="p-2 mx-1 bg-gray-200 rounded-md">
                  {eachList?.name}
                </div>
              ))}
            </div>
          </div>
          <div className="my-2 mt-4">
            <Dot color={selectTailwindStatusColor(booking?.status)} word={booking?.status} />
          </div>
          <div className="mt-2">
            <h5 className="text-sm text-gray-600 ">เข้าพักเมื่อ</h5>
            <div>
              {moment(booking?.start)?.format("D MMM YYYY")} -{" "}
              {moment(booking?.end)?.format("D MMM YYYY")}
            </div>
          </div>{" "}
          <BookingSourceBadge sourceOfBooking={booking?.booking_source} />
        </div>
      </div>
    </div>
  )
}
