import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { Checkbox } from "@chakra-ui/react"
import { Input, Select } from "antd"
import { Controller } from "react-hook-form"

import UserRoles from "../../../config/UserRoles"
import { ModuleList } from "../../../config/constant"

const UserForm = ({ register, errors, showPasswordField, user, control }) => (
  <div>
    <div className="flex flex-wrap ">
      <div className="w-full px-4 ">
        <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">ข้อมูลผู้ใช้งาน</h6>

        <div className="flex gap-2">
          <div className="relative w-full mb-3 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              ชื่อผู้ใช้งาน
            </label>
            <Controller
              control={control}
              name="user.username"
              defaultValue={user?.username}
              render={(field) => <Input {...field} size="large" placeholder="Username" />}
            />

            <p className="text-xs text-red-400 mt-2">
              {errors?.user?.username && "กรุณาใส่ชื่อผู้ใช้ให้ถูกต้อง"}
            </p>
          </div>
        </div>

        {showPasswordField && (
          <div className="flex gap-2">
            <div className="relative w-full mb-3 ">
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                รหัสผ่าน
              </label>
              <Controller
                control={control}
                name="user.password"
                defaultValue={user?.password}
                render={(field) => (
                  <Input.Password {...field} size="large" placeholder="Password" />
                )}
              />
            </div>
            <div className="relative w-full mb-3 ">
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                ยืนยันรหัสผ่าน
              </label>
              <Controller
                control={control}
                name="user.confirm_password"
                defaultValue={user?.confirm_password}
                render={(field) => (
                  <Input.Password {...field} size="large" placeholder="Confirm Password" />
                )}
              />
            </div>
          </div>
        )}

        <div className="flex gap-2">
          <div className="relative w-full mb-3  lg:w-1/2 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              บทบาทของผู้ใช้
            </label>
            <Controller
              control={control}
              name="user.role"
              defaultValue={user?.role || UserRoles.ADMIN}
              render={(field) => (
                <Select
                  {...field}
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Role"
                  options={[
                    {
                      value: UserRoles.ADMIN,
                      label: "ผู้ดูแล",
                    },
                    {
                      value: UserRoles.EMPLOYEE,
                      label: "พนักงาน",
                    },
                  ]}
                ></Select>
              )}
            />
          </div>
        </div>
        <div className="flex gap-2">
          <div className="relative w-full mb-3 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              การเข้าถึงส่วนต่าง ๆ
            </label>
            <div className="grid lg:grid-cols-3">
              {_.map(ModuleList, (eachModule, index) => (
                <div key={index}>
                  <Checkbox
                    name={`user.access[${eachModule.status_code}]`}
                    defaultChecked={user?.access?.[eachModule.status_code] || true}
                    ref={register}
                    // size="sm"
                  >
                    {eachModule.description}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

UserForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  showPasswordField: PropTypes.bool,
}

export default UserForm
