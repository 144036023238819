export const BookingSource = {
  COUNTER_BOOKING: {
    status_code: "COUNTER_BOOKING",
    description: "การจองผ่านเคาท์เตอร์",
    descriptionEN: "Counter Booking",
  },
  TELEPHONE: {
    status_code: "TELEPHONE",
    description: "โทรศัพท์",
    descriptionEN: "Telephone Call",
  },
  LINE: {
    status_code: "LINE",
    description: "ไลน์",
    descriptionEN: "Line",
  },
  FACEBOOK: {
    status_code: "FACEBOOK",
    description: "เฟสบุ๊ค",
    descriptionEN: "Facebook",
  },
  BOOKING_COM: {
    status_code: "BOOKING_COM",
    description: "Booking.com",
    descriptionEN: "Booking.com",
  },
  AGODA: {
    status_code: "AGODA",
    description: "Agoda",
    descriptionEN: "Agoda",
  },
  WHATAPPS: {
    status_code: "WHATAPPS",
    description: "Whatapps",
    descriptionEN: "Whatapps",
  },
  WE_CHAT: {
    status_code: "WE_CHAT",
    description: "WeChat",
    descriptionEN: "WeChat",
  },
  TRAVELOKA: {
    status_code: "TRAVELOKA",
    description: "Travelloka",
    descriptionEN: "Travelloka",
  },
  OTHER_OTA: {
    status_code: "OTHER_OTA",
    description: "ผู้ให้บริการ OTA อื่นๆ",
    descriptionEN: "Other OTA",
  },
  OTHER: {
    status_code: "OTHER",
    description: "อื่นๆ",
    descriptionEN: "Others",
  },
  ONLINE_SYSTEM: {
    status_code: "ONLINE_SYSTEM",
    description: "ระบบออนไลน์",
    descriptionEN: "Online System",
  },
}

export default BookingSource
