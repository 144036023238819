import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel, MaterialForm, SpinnerLoading } from "../../components"
import * as actions from "../../redux/actions"

const EditMaterial = () => {
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const material = useSelector((state) => state.material)
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.getOneMaterial(params.id)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params.id])

  const onSubmit = (data) => {
    dispatch(actions.editOneMaterial(params.id, data))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        console.error(err)
        alert(`แก้ไขวัตถุดิบไม่สำเร็จ ${err?.message}`)
      })
  }

  if (!isLoading) return <SpinnerLoading />

  return (
    <Layout categoryTitle={t("inventory.title")} title="แก้ไขวัตถุดิบ">
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline">
          กลับ
        </Button>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <WhitePanel>
            <MaterialForm
              control={control}
              onMany={false}
              formSize="large"
              defaultValue={material}
            />
          </WhitePanel>
          <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
            {t("general.save")}
          </Button>{" "}
        </form>
      </div>
    </Layout>
  )
}

export default EditMaterial
