import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { slipHeader, slipDefaultStyle } from ".."

dayjs.extend(buddhistEra)

export default function FoodOrderMiniSlip(fbOrder, record, information, systemInfo) {
  return {
    // pageSize: "RA4",
    pageSize: {
      width: 200,
      height: 300,
    },
    pageOrientation: "portrait",
    pageMargins: [10, 70, 10, 0],
    header: slipHeader(information, true),
    info: {
      title: "รายการอาหาร",
    },
    defaultStyle: slipDefaultStyle,

    content: [
      {
        text: "บิลส่งอาหาร",
        bold: true,
        alignment: "center",
        margin: [0, 3],
        fontSize: 12,
      },
      {
        text: [
          { text: "เลขที่บิล : ", bold: true },
          {
            text: `${fbOrder?.bill_number}`,
            margin: [5, 0],
          },
        ],
        alignment: "center",
        margin: [0, 3],
      },
      {
        text: [
          { text: "โต๊ะ : ", bold: true },
          {
            text: `${fbOrder?.table?.name}`,
            margin: [5, 0],
          },
        ],
        fontSize: 12,
        alignment: "center",
        margin: [0, 3],
      },
      {
        text: "-------------------------------",
        margin: [0, 2],
        alignment: "center",
      },

      {
        text: [{ text: `${record?.menu?.translation?.th?.name}`, bold: true }],
        alignment: "center",
        fontSize: 12,
        margin: [0, 10, 5, 0],
      },
      record?.option
        ? {
            text: `ตัวเลือก : ${record?.option}`,
            margin: [0, 2],
            alignment: "center",
          }
        : {},
      !_.isEmpty(record?.toppings)
        ? {
            text: `เพิ่ม : ${_.map(record?.toppings, (each) => each)}`,
            margin: [0, 2],
            alignment: "center",
          }
        : {},
      {
        text: record?.remark ? `หมายเหตุ : ${record?.remark}` : "",
        alignment: "center",
        margin: [0, 10, 5, 0],
      },
      {
        text: "-------------------------------",
        margin: [0, 10, 0, 0],
        alignment: "center",
      },
      {
        text: [
          { text: "เวลาที่พิมพ์ / Date  ", bold: true },
          {
            text: dayjs()?.format("D MMM YYYY เวลา HH.mm น."),
            margin: [5, 0],
          },
        ],
        alignment: "center",
        fontSize: 9,
        margin: [0, 3],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
