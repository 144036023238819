export const ComponentWidthVideoComponent = {
  ONE_THIRD: {
    status_code: "ONE_THIRD",
    description: "1 ใน 3",
    descriptionEn: "1/3 Of Screen",
  },
  TWO_THIRD: {
    status_code: "TWO_THIRD",
    description: "2 ใน 3",
    descriptionEn: "2/3 Of Screen",
  },
  ONE_HALF: {
    status_code: "ONE_HALF",
    description: "ครึ่งหน้าจอ",
    descriptionEn: "Half Screen",
  },
  CONTAINER: {
    status_code: "CONTAINER",
    description: "อยู่ในกล่องกลางหน้าจอ",
    descriptionEn: "In Container",
  },
  FULL_SCREEN: {
    status_code: "FULL_SCREEN",
    description: "เต็มหน้าจอ ไม่มีขอบ",
    descriptionEn: "Full Width Screen",
  },
}

export default ComponentWidthVideoComponent
