import THBText from "thai-baht-text"
import _ from "lodash"
import dayjs from "dayjs"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"

const dateFormat = { day: "numeric", month: "long", year: "numeric" }

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 */
export default function KeyDeposit({
  bookingInfo,
  information,
  systemInfo,
  price,
  era = "be",
  cashierName = "",
  isPricePerUnit,
  roomAmount,
  remark = "",
}) {
  const blankSpace = [
    {
      text: "",
      border: [false, false, false, false],
      colSpan: 5,
    },
    {},
    {},
    {},
    {},
  ]

  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "ใบเสร็จรับเงิน",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "ใบเสร็จรับเงิน",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        columns: [
          {
            text: [
              { text: "วันที่เช็คอิน / Check In : ", bold: true },
              {
                text: `  ${dayjs(bookingInfo?.start).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
          },
          {
            text: [
              { text: "เลขที / No. :", bold: true },
              {
                text: `  KD-${bookingInfo?.booking_no}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            text: [
              { text: "วันเช็คเอาท์ / Checkout Date : ", bold: true },
              {
                text: `  ${dayjs(bookingInfo?.end).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "วันที่พิมพ์ / Print Date : ", bold: true },
              {
                text: `  ${dayjs(bookingInfo?.updatedAt).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 3, 0, 15],
      },
      {
        text: [
          { text: "ลูกค้า / Customer : ", bold: true },
          {
            text: `  ${bookingInfo?.customer?.firstname || ""}  ${
              bookingInfo?.customer?.lastname || ""
            }`,
            margin: [5, 0],
          },
        ],
        margin: [0, 2],
      },

      {
        text: "รายการสินค้าและบริการ",
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, "*", 40, 40, 80],
          body: [
            [
              { text: "ที่\nNo", bold: true, alignment: "center" },
              { text: "สินค้า/ รายละเอียด\nDescription", bold: true, alignment: "center" },
              { text: "หน่วยละ\nPrice", bold: true, alignment: "center" },
              { text: "จำนวน\nQuantity", bold: true, alignment: "center" },
              { text: "ราคา\nAmount", bold: true, alignment: "center" },
            ],
            [
              {
                text: `1`,
                margin: [0, 2],
              },
              {
                text: `ค่ามัดจำกุญแจ`,
                margin: [0, 2],
              },
              {
                text: parseFloat(price || 0).toFixed(2),
                margin: [0, 2],
                alignment: "right",
              },
              {
                text: isPricePerUnit ? roomAmount : 1,
                alignment: "right",
              },
              {
                text: parseFloat(isPricePerUnit ? roomAmount * price : price || 0).toFixed(2),
                margin: [0, 2],
                alignment: "right",
              },
            ],
            [
              {
                text: `รวมเป็นเงิน / Grand Total ( ${THBText(
                  isPricePerUnit ? roomAmount * price : price,
                )} ) `,
                colSpan: 4,
                bold: true,
                margin: [0, 2],
              },
              {},
              {},
              {},
              {
                text: currencyFormatter.format(isPricePerUnit ? roomAmount * price : price),
                bold: true,
                alignment: "right",
              },
            ],
          ],
        },

        margin: [0, 15, 0, 0],
        fontSize: 10,
      },
      {
        stack: [
          { text: "หมายเหตุ / Remark", bold: true, margin: [0, 10, 0, 0] },
          {
            text: remark || "-",
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้รับบริการ / Customer" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: `(........${
                  cashierName || "........................................"
                }........)`,
                margin: [0, 2],
              },
              { text: "ผู้รับเงิน / Cashier" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
