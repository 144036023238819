import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { DateTime } from "luxon"
import DayPickerInput from "react-day-picker/DayPickerInput"
import JSONPretty from "react-json-pretty"

import SpinnerLoading from "../../Loading/SpinnerLoading"
import api from "../../../config/api"
import * as actions from "../../../redux/actions"

import "react-day-picker/lib/style.css"

export default function FindBookingTest() {
  const dispatch = useDispatch()
  const subRoomTypes = useSelector((state) => state.subRoomTypes)
  const { handleSubmit, register } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [checkInDate, setCheckinDate] = useState()
  const [checkoutDate, setCheckoutDate] = useState()
  const [result, setResult] = useState()

  useEffect(() => {
    dispatch(actions.getAllSubRoomTypes()).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  const onFindBookingTest = async () => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/booking/vacant/${DateTime.fromJSDate(
          checkInDate,
        ).toISODate()}/${DateTime.fromJSDate(checkoutDate).toISODate()}`,
      )
      .then((res) => {
        setResult(res.data)
      })
      .catch((err) => {
        setResult(err)
        console.error(err)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onFindBookingTest)}>
        <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
          <div className="w-full px-4 ">
            <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
              ทดสอบการหาห้องว่าง
            </h6>
            <div className="flex gap-2">
              <div className="relative w-full mb-3 ">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  เลือกหมวดหมู่ย่อยของห้อง
                </label>
                {/* <div className="flex gap-2">
                  <select
                    name="subtypeid"
                    ref={register}
                    className="w-3/4 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline  ease-linear transition-all duration-150"
                    placeholder="กรุณาเลือกหมวดหมู่ย่อยของห้อง"
                  >
                    <option value="">กรุณาเลือกหมวดหมู่ย่อยของห้อง</option>
                    {_.map(subRoomTypes?.arr, (eachSubTypes, index) => (
                      <option value={eachSubTypes?.id} key={index}>
                        {eachSubTypes.name}
                      </option>
                    ))}
                  </select>
                </div> */}
                <br />
                <div className="flex gap-2 ">
                  <div>
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      วันเช็คอิน
                    </label>
                    <DayPickerInput onDayChange={setCheckinDate} value={checkInDate} />
                  </div>
                  <div>
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      วันเช็คเอาท์
                    </label>
                    <DayPickerInput onDayChange={setCheckoutDate} value={checkoutDate} />
                  </div>
                  <Button colorScheme="purple" variant="solid" type="submit">
                    ค้นหา
                  </Button>
                </div>
                {result && (
                  <div className="font-mono p-3 bg-blue-100 my-2">
                    <code>
                      <JSONPretty data={JSON.stringify(result)} />
                    </code>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
      </form>
    </div>
  )
}
