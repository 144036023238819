import {
  PURCHASE_ORDER_ALL,
  PURCHASE_ORDER_CREATE,
  PURCHASE_ORDER_DELETE,
  PURCHASE_ORDER_GET,
  PURCHASE_ORDER_UPDATE,
  PURCHASE_ORDER_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const PurchaseorderAction = new GeneralReduxActionClass({
  allConst: PURCHASE_ORDER_ALL,
  createConst: PURCHASE_ORDER_CREATE,
  deleteConst: PURCHASE_ORDER_DELETE,
  errorConst: PURCHASE_ORDER_ERROR,
  getConst: PURCHASE_ORDER_GET,
  putConst: PURCHASE_ORDER_UPDATE,
  backendPrefix: "purchase-order",
  description: "รายการสั่งซื้อ",
})

export const getAllPurchaseOrder = (query) => PurchaseorderAction.getAll(query)
export const getOnePurchaseOrder = (id) => PurchaseorderAction.getOne(id)
export const createOnePurchaseOrder = (payload) => PurchaseorderAction.createOne(payload)
export const editOnePurchaseOrder = (id, payload) => PurchaseorderAction.editOne(id, payload)
export const deleteOnePurchaseOrder = (id) => PurchaseorderAction.deleteOne(id)
