import api from "../../../config/api"

import {
  ROOM_ALL,
  ROOM_GET,
  ROOM_CREATE,
  ROOM_PUT,
  ROOM_DELETE,
  ROOM_ALL_FROM_ROOMTYPE,
  ROOM_ALL_VACANT,
} from "../types"

// faker.locale = "th"

export const getAllRooms = () => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/room/?size=5000`)
    .then((res) => {
      console.log("Request Server to Get All Rooms")
      if (res.data) {
        dispatch({ type: ROOM_ALL, payload: res?.data })
      } else {
        dispatch({ type: ROOM_ALL, payload: null })
      }
    })
    .catch((err) => {
      console.log("Error on Get All Rooms", err)
      dispatch({ type: ROOM_ALL, payload: null })
    })
}

export const getAllRoomsWithBooking =
  ({
    name = "",
    date = "",
    page = 1,
    size = 200,
    showAllDay = "",
    unwindBooking = "",
    orderByField = "",
    orderBy = "",
    checkout = "",
    start = "",
    end = "",
    checkin = "",
  }) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/room/with-booking/?name=${name}&date=${date}&page=${page}&size=${size}&showAllDay=${showAllDay}&fetchBooking=true&unwindBooking=${unwindBooking}&orderByField=${orderByField}&orderBy=${orderBy}&checkout=${checkout}&start=${start}&end=${end}&checkin=${checkin}`,
      )
      .then((res) => {
        console.log("Request Server to Get All With Booking Rooms")
        if (res.data) {
          dispatch({ type: ROOM_ALL, payload: res?.data })
        } else {
          dispatch({ type: ROOM_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All Rooms", err)
        dispatch({ type: ROOM_ALL, payload: null })
        throw new Error(`ไม่สามารถดึงข้อมูลห้องมาได้  ${err?.response?.data?.error?.message}`)
      })
  }

export const getVacantRooms =
  (checekInDate, checkOutDete, typeId = null, subTypeId = null, ignoreLog = false) =>
  async (dispatch) => {
    await api
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/booking/vacant/${checekInDate}/${checkOutDete}/?size=200&${
          typeId ? `typeId=${typeId}` : ""
        }${subTypeId ? `&subTypeId=${subTypeId}` : ""}${
          ignoreLog ? `&ignoreLog=${ignoreLog}` : ""
        }`,
      )
      .then((res) => {
        console.log("Request Server to Get All Vacant Room")

        if (res.data) {
          dispatch({ type: ROOM_ALL_VACANT, payload: res.data })
        } else {
          dispatch({ type: ROOM_ALL_VACANT, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All  Vacant Room", err)
        dispatch({ type: ROOM_ALL_VACANT, payload: null })
      })
  }

export const getRoomsFromRoomType = (typeId) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/room/?typeId=${typeId}&size=200`)
    .then((res) => {
      console.log("Request Server to Get All Rooms from Roomtype")
      if (res.data) {
        dispatch({ type: ROOM_ALL_FROM_ROOMTYPE, payload: res.data?.rows })
      } else {
        dispatch({ type: ROOM_ALL_FROM_ROOMTYPE, payload: null })
      }
    })
    .catch((err) => {
      console.log("Error on Get All Rooms from Roomtype", err)
      dispatch({ type: ROOM_ALL_FROM_ROOMTYPE, payload: null })
    })
}

export const getOneRoom = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/room/${id}`).then((res) => {
    console.log("Request Server to Get One Room")
    if (res.data) {
      dispatch({ type: ROOM_GET, payload: res.data })
    } else {
      dispatch({ type: ROOM_GET, payload: null })
    }
  })
}
export const createOneRoom = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/room/`, payload).then(() => {
    console.log("Request Server to Create New Room")
    dispatch({ type: ROOM_CREATE })
  })
}

export const editOneRoom = (id, payload) => async (dispatch) => {
  await api.put(`${process.env.REACT_APP_API_URL}/room/${id}`, payload).then(() => {
    console.log("Request Server to edit bed")
    dispatch({ type: ROOM_PUT })
  })
}

export const deleteOneRoom = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/room/${id}`).then(() => {
    console.log("Request Server to Delete One Bed")
    dispatch({ type: ROOM_DELETE, payload: null })
  })
}
