/* eslint-disable no-underscore-dangle */
import React from "react"
import { Form, Input } from "antd"
import { Controller } from "react-hook-form"

function MaterialForm({ control, defaultValue, formSize = "large", onMany = false, index = 0 }) {
  return (
    <div>
      <Form layout="vertical" size={formSize}>
        <Form.Item label="รหัสวัตถุดิบ" style={{ marginBottom: "5px" }}>
          <Controller
            control={control}
            name={onMany ? `arr[${index}].type_code` : "type_code"}
            defaultValue={defaultValue?.type_code}
            render={(field) => <Input {...field} placeholder="รหัสวัตถุดิบ" />}
          />
        </Form.Item>
        <Form.Item label="ชื่อวัตถดิบ" required style={{ marginBottom: "5px" }}>
          <Controller
            control={control}
            name={onMany ? `arr[${index}].name` : "name"}
            defaultValue={defaultValue?.name}
            rules={{ required: true }}
            render={(field) => <Input {...field} required placeholder="ชื่อวัตถุดิบ" />}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default MaterialForm
