import React from "react";
import PropTypes from "prop-types";

export default function CardNote({
  note_id,
  title,
  subtitle,
  icon,
  image,
  onDelete,
}) {
  return (
    <div className="p-4 bg-white rounded-lg shadow dark:bg-gray-800">
      <div className="flex flex-wrap justify-between w-full">
        <div className="w-1/6 text-gray-500 bg-orange-100 rounded-full mr-4">
          <i className={`${icon}`}></i>
        </div>
        <div className="flex justify-end">
          <div
            className="text-red-500 cursor-pointer"
            onClick={() => {
              const confirm = window.confirm("ยืนยันการลบบันทึก");
              if (confirm) onDelete(note_id);
            }}
          >
            <i
              className=" justify-self-end fa fa-window-close fa-xs "
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className="px-6">
        {title && (
          <p className="mb-2 text-lg font-medium text-gray-700 ">{title}</p>
        )}
        {image ? (
          <div className="flex flex-wrap w-full ">
            <div className="relative w-full mb-3">
              <p className="text-sm  text-gray-600 break-all ">{subtitle}</p>
            </div>
            <div className="relative w-full mb-3">
              <img
                alt="..."
                src={image}
                className="shadow-md rounded-md h-auto align-middle border-none  max-w-150-px"
              />
            </div>
          </div>
        ) : (
          <>
            <p className="text-sm  text-gray-600 break-all  ">{subtitle}</p>
          </>
        )}
      </div>
    </div>
  );
}
CardNote.defaultProps = {
  title: "",
  subtitle: "ไม่มีข้อมูล",
  icon: "fa fa-sticky-note",
  image: null,
  onDelete: () => {},
};

CardNote.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string,
  onClick: PropTypes.func,
};
