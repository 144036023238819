import api from "../../../config/api"

import { BACKGROUND_GALLERY_ALL, BACKGROUND_GALLERY_GET, BACKGROUND_GALLERY_PUT, BACKGROUND_GALLERY_DELETE, BACKGROUND_GALLERY_CREATE } from "../types"

// faker.locale = "th"

export const getAllBackgroundGallery = () => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/background-component/`)
    .then((res) => {
      if (res.data) {
        
        dispatch({ type: BACKGROUND_GALLERY_ALL, payload: res.data })
      } else {
        dispatch({ type: BACKGROUND_GALLERY_ALL, payload: null })
      }
    })
    .catch((err) => {
      console.log("Error on Get All Photo in Gallery", err)
      dispatch({ type: BACKGROUND_GALLERY_ALL, payload: null })
    })
}

export const getBackgroundGallery = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/background-component/${id}`).then((res) => {
    console.log("Request Server to Get gallery")
    if (res.data) {
      dispatch({ type: BACKGROUND_GALLERY_GET, payload: res.data })
    } else {
      dispatch({ type: BACKGROUND_GALLERY_GET, payload: null })
    }
  })
}

export const editBackgroundGallery = (id, payload) => async (dispatch) => {
  await api.put(`${process.env.REACT_APP_API_URL}/background-component/${id}`, payload).then((res) => {
    console.log("Request Server to edit gallery")
    dispatch({ type: BACKGROUND_GALLERY_PUT })
  })
}

export const createBackgroundGallery = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/background-component/`, payload).then((res) => {
    console.log("Request Server to create gallery")
    dispatch({ type: BACKGROUND_GALLERY_CREATE, payload: res.data })
  })
}

export const deleteOneBackgroundGallery = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/background-component/${id}`).then((res) => {
    console.log("Request Server to Delete One photo in gallery")
    dispatch({ type: BACKGROUND_GALLERY_DELETE, payload: null })
  })
}
