import { combineReducers } from "redux"

// Common
import UserReducers from "./common/UserReducers"
import MeReducers from "./common/MeReducers"
import SystemInfoReducers from "./common/SystemReducer"

// Feature
import RoomTypesReducers from "./feature/RoomTypesReducers"
import BedReducers from "./feature/BedReducers"
import SubRoomTypeReducers from "./feature/SubRoomTypeReducers"
import RoomReducers from "./feature/RoomReducers"
import RelationshipReducers from "./feature/RelationshipReducers"
import ArticleReduces from "./feature/ArticleReducers"
import PromotionReduces from "./feature/PromotionReducers"
import FacilityReducers from "./feature/FacilityReducers"
import InfoReducers from "./feature/InfoReducers"
import BackGroundGalleryReducers from "./feature/BackGroundGalleryReducer"
import GalleryReducers from "./feature/GalleryReducers"
import PormptPayReducers from "./feature/PromptPayReducers"
import BookingReducers from "./feature/BookingReducers"
import CustomerReducers from "./feature/CustomerReducers"
import LogReducers from "./feature/LogReducers"
import HotelServiceReducers from "./feature/HotelServiceReducers"
import EmployeeReducer from "./feature/EmployeeReducer"
import FBOrderReducer from "./feature/FBOrderReducer"
import MeetingRoomReducers from "./feature/MeetingRoomReducers"
import TransactionReducer from "./feature/TransactionReducers"
import ServiceOrderReducer from "./feature/ServiceOrderReducer"
import MeetingRoomOrderReducer from "./feature/MeetingRoomOrderReducer"
import FrontpageComponentReducer from "./feature/FrontpageComponentReducer"
import TaxInvoiceReducer from "./feature/TaxInvoiceReducer"
import FileReducer from "./feature/FileReducer"
import ImageReducer from "./feature/ImageReducer"
import ArticleCategoryReducers from "./feature/ArticleCategoryReducers"
import ChillPayReducers from "./feature/ChillPayReducers"
import BillReducers from "./feature/BillReducers"
import MenuCategoryReducer from "./feature/MenuCategoryReducer"
import MenuReducer from "./feature/MenuReducer"
import TableReducer from "./feature/TableReducer"
import FBOrderListReducer from "./feature/FBOrderListReducer"
import MaterialReducers from "./feature/MaterialReducer"
import PurchaseOrderReducer from "./feature/PurchaseOrderReducer"

const rootReducer = combineReducers({
  bed: BedReducers,
  bill: BillReducers,
  bookings: BookingReducers,
  customers: CustomerReducers,
  employee: EmployeeReducer,
  facilities: FacilityReducers,
  fbOrder: FBOrderReducer,
  fbList: FBOrderListReducer,
  file: FileReducer,
  frontpageComponent: FrontpageComponentReducer,
  backgroundgallery: BackGroundGalleryReducers,
  gallery: GalleryReducers,
  hotelService: HotelServiceReducers,
  information: InfoReducers,
  logs: LogReducers,
  me: MeReducers,
  menu: MenuReducer,
  menuCategory: MenuCategoryReducer,
  meetingRoom: MeetingRoomReducers,
  meetingRoomOrder: MeetingRoomOrderReducer,
  material: MaterialReducers,
  articles: ArticleReduces,
  articleCategories: ArticleCategoryReducers,
  promotions: PromotionReduces,
  promptpay: PormptPayReducers,
  purchaseOrder: PurchaseOrderReducer,
  relationships: RelationshipReducers,
  rooms: RoomReducers,
  roomTypes: RoomTypesReducers,
  serviceOrder: ServiceOrderReducer,
  subRoomTypes: SubRoomTypeReducers,
  system: SystemInfoReducers,
  taxInvoice: TaxInvoiceReducer,
  transaction: TransactionReducer,
  user: UserReducers,
  image: ImageReducer,
  chillPay: ChillPayReducers,
  table: TableReducer,
})

export default rootReducer
