const depositWait = "รอการมัดจำ"
const depositPay = "ชำระเงินมัดจำแล้ว รอการยืนยัน"
const depositConfirm = "ชำระเงินมัดจำครบถ้วน"
const allPaymentWait = "รอการชำระเต็มจำนวน"
const allPaymentPay = "ชำระเต็มจำนวน รอการยืนยัน"
const allPaymentConfirm = "ชำระเงินครบถ้วน"

export const depositStatus = {
  depositWait,
  depositPay,
  depositConfirm,
}

export const allPaymentStatus = {
  allPaymentWait,
  allPaymentPay,
  allPaymentConfirm,
}

export const depositStatusArray = [depositWait, depositPay, depositConfirm]

export const allPaymentStatusArray = [allPaymentWait, allPaymentPay, allPaymentConfirm]

export const paymentStatus = { deposit: depositStatus, allPayment: allPaymentStatus }
export const paymentStatusArray = [depositWait, depositConfirm, allPaymentConfirm]
export default { deposit: depositStatus, allPayment: allPaymentStatus }
