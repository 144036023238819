import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export default function MainTextExample({ info }) {
  const { i18n } = useTranslation()
  return (
    <div>
      <div className="container mx-auto px-4">
        <div className="text-center w-full p-8 md:p-full">
          <h1 className="font-bold text-gray-600 text-2xl md:text-3xl">
            {info?.composite?.translation?.[i18n.language]?.second_header || "หัวข้อที่ 2"}{" "}
            <Link to="/site/second-description">
              <Button size="sm">แก้ไข</Button>
            </Link>
          </h1>
          <h2 className="py-5 text-blue-600 text-lg md:text-xl">
            {" "}
            {info?.composite?.translation?.[i18n.language]?.second_brief ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet augue orci. Morbi at nulla metus. Mauris vitae porttitor sapien. "}
          </h2>
          <p className="py-0 font-sans text-gray-400  ">
            {" "}
            {info?.composite?.translation?.[i18n.language]?.second_description ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit amet augue orci. Morbi at nulla metus. Mauris vitae porttitor sapien. Curabitur risus sem, pulvinar et sodales ac, pretium vitae felis. Etiam tincidunt,"}
          </p>
        </div>
      </div>
    </div>
  )
}
