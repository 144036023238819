import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Controller } from "react-hook-form"
import { SketchPicker } from "react-color"
import { FormLabel, Input, Select, FormControl, Checkbox } from "@chakra-ui/react"
import _, { each } from "lodash"
import { Select as Antselect, Space } from 'antd';
import { motion } from 'framer-motion';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';


import {
  FrontpageComponentDisplayType,
  DataSource,
  ComponentHeight,
  ComponentWidth,
  ComponentDistance,
} from "../../../config/constant"

import FCPDisplayTypeForm from "./FPCDisplayTypeForm"
import FCPDataSourceForm from "./FCPDataSourceForm"

function FrontpageComponentForm({
  defaultValue,
  register,
  watch,
  setValue,
  gallery,
  control,
  file,
  length,
  width,
}) {
  const { i18n } = useTranslation()
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const { Option } = Antselect;
  const backgroundgallery = useSelector((state) => state.backgroundgallery)
  const articleCategories = useSelector((state) => state.articleCategories)
  // console.log('backgroundgallery: ', backgroundgallery);

  const [activeTab, setActiveTab] = useState('Desktop');

  const buttonVariants = {
    idle: {
      opacity: 1,
      x: 0,
    },
    hover: {
      opacity: 0.8,
    },
    active: {
      opacity: 1,
      x: '-50%',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
    },
  };


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (

    <div>
      <div className="flex justify-center mt-4 ">
        <motion.button
          className={`px-4 py-2 lg:mr-2  ml-14 mb-4 rounded-tl-md rounded-bl-md ${activeTab === 'Desktop' ? 'bg-gradient-to-r from-blue-500 to-purple-600 text-white' : 'bg-gradient-to-r from-gray-300 to-gray-500 text-gray-800'}`}
          onClick={() => handleTabChange('Desktop')}
          variants={buttonVariants}
          whileTap="active"
          whileHover="hover"
          type="button"
          animate={activeTab === 'Desktop' ? 'active' : 'idle'}
        >
          <DesktopOutlined className="lg:mr-2" /> Desktop
        </motion.button>
        <motion.button
          className={`px-4 py-2 mb-4 ml-2 rounded-tr-md rounded-br-md ${activeTab === 'Mobile' ? 'bg-gradient-to-r from-orange-500 to-pink-600 text-white' : 'bg-gradient-to-r from-gray-300 to-gray-500 text-gray-800'}`}
          onClick={() => handleTabChange('Mobile')}
          variants={buttonVariants}
          whileTap="active"
          whileHover="hover"
          type="button"
          animate={activeTab === 'Mobile' ? 'active' : 'idle'}
        >
          <MobileOutlined className="mr-2" /> Mobile
        </motion.button>
      </div>
      <div>
        <input type="hidden" value={defaultValue?._id} ref={register} name="id" />
        <FormLabel className="lg:pl-2 text-sm font-semibold text-gray-600">
          ชื่อส่วนประกอบ
        </FormLabel>
        <div className="flex flex-wrap">
          <label
            className="pl-2 block text-sm font-semibold text-gray-700 mb-2 "
            htmlFor="grid-password"
          >
            ภาษาไทย
          </label>
          <Input
            ref={register}
            placeholder="ชื่อส่วนประกอบ"
            name="translation.th.name"
            size="sm"
            // defaultValue={defaultValue?.translation?.th?.name}
            required
            className="pl-2 text-sm rounded-md border-gray-300 focus:ring focus:ring-indigo-200 focus:border-indigo-500"
          />

          <label
            className="pl-2 pt-2 block text-sm font-semibold text-gray-700 mb-2"
            htmlFor="grid-password"
          >
            ภาษาอังกฤษ
          </label>
          <Input
            ref={register}
            placeholder="ชื่อส่วนประกอบ"
            name="translation.en.name"
            size="sm"
            // defaultValue={defaultValue?.translation?.en?.name}
            required
            className="pl-2 text-sm rounded-md border-gray-300 focus:ring focus:ring-indigo-200 focus:border-indigo-500"
          />
        </div>
      </div>
      <div>
        {activeTab === 'Desktop' && (
          <div>
            <div div className="w-full lg:w-1/2 px-2 py-1 pt-2">
              <FormLabel className="pt-2 text-sm font-semibold text-gray-600">
                รูปแบบของส่วนประกอบ
              </FormLabel>
              <Select
                ref={register}
                placeholder="รูปแบบของส่วนประกอบ"
                defaultValue={defaultValue?.display_type}
                name="display_type"
                size="sm"
                className="text-sm rounded-md border-gray-300 focus:ring focus:ring-indigo-200 focus:border-indigo-500"
              >
                {_.map(FrontpageComponentDisplayType, (displayType, index) => (
                  <option key={index} value={displayType.status_code}>
                    {i18n?.language === "th" ? displayType.description : displayType.descriptionEn}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-full pt-2 lg:w-1/2 px-2 py-1">
              <FormLabel className="pl-1 pt-2 text-sm font-semibold text-gray-600">
                ระยะห่างระหว่างส่วนประกอบ
              </FormLabel>
              <Select
                ref={register}
                placeholder="ระยะห่าง"
                defaultValue={defaultValue?.desktop?.component_distance}
                name="desktop.component_distance"
                size="sm"
              >
                {_.map(ComponentDistance, (each, index) => (
                  <option key={index} value={each.status_code}>
                    {i18n?.language === "th" ? each.description : each.descriptionEn}
                  </option>
                ))}
              </Select>
            </div>
            {watch("data_source") === DataSource.ARTICLE.status_code && (
              <div className="w-full lg:w-1/3 px-2 py-1">
                <FormLabel fontSize="sm">หมวดหมู่บทความ</FormLabel>
                <Select
                  ref={register}
                  placeholder="หมวดหมู่บทความ"
                  defaultValue={defaultValue?.type_attribute?.article_category}
                  name="type_attribute.article_category"
                  size="sm"
                >
                  {_.map(articleCategories?.arr, (eachCategory, index) => (
                    <option key={index} value={eachCategory?._id}>
                      {eachCategory?.translation?.th?.name}
                    </option>
                  ))}
                </Select>
              </div>
            )}
            {watch("display_type") &&
              watch("display_type") !== FrontpageComponentDisplayType.RICH_TEXT_CONTENT.status_code &&
              watch("display_type") !== FrontpageComponentDisplayType.VIDEO.status_code &&
              watch("display_type") !== FrontpageComponentDisplayType.BUTTON.status_code
              ? (
                <div className="w-full lg:w-1/2 px-2 py-1">
                  <FormLabel fontSize="sm">แหล่งที่มาของข้อมูล</FormLabel>
                  <Select
                    ref={register}
                    placeholder="แหล่งที่มาของข้อมูล"
                    defaultValue={defaultValue?.data_source}
                    name="data_source"
                    required
                    size="sm"
                  >
                    {_.map(DataSource, (eachDataSource, index) => (
                      <option key={index} value={eachDataSource.status_code}>
                        {i18n?.language === "th"
                          ? eachDataSource.description
                          : eachDataSource.descriptionEn}
                      </option>
                    ))}
                  </Select>
                </div>
              ) : (
                <div className="w-full lg:w-1/2"></div>
              )}
            <div div className="flex pr-80 gap-10 pt-3">
              <div className="flex pr-10 gap-10 pt-3">
                {
                  watch("display_type") !== FrontpageComponentDisplayType.VIDEO.status_code &&
                  (
                    <div>
                      <div className="w-full lg:w-1/2 px-2 py-1">
                        <label className="text-sm font-semibold text-gray-700">ความกว้าง</label>
                        <select
                          ref={register}
                          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          placeholder="ความกว้าง"
                          defaultValue={defaultValue?.desktop?.width}
                          name="desktop.width"
                          size="sm"
                        >
                          {_.map(ComponentWidth, (each, index) => (
                            <option
                              key={index}
                              value={each.status_code}
                              className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                            >
                              {i18n?.language === "th" ? each.description : each.descriptionEn}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-full lg:w-1/2 px-2 py-1">
                        <label className="text-sm font-semibold text-gray-700">ความสูง</label>
                        <select
                          ref={register}
                          className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          placeholder="ความสูง"
                          defaultValue={defaultValue?.desktop?.height}
                          name="desktop.height"
                          size="sm"
                        >
                          {_.map(ComponentHeight, (each, index) => (
                            <option
                              key={index}
                              value={each.status_code}
                              className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                            >
                              {i18n?.language === "th" ? each.description : each.descriptionEn}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            {watch("display_type") !==
              FrontpageComponentDisplayType.RICH_TEXT_CONTENT.status_code &&
              (<div className="w-full lg:w-1/3 px-2 py-5">
                <FormLabel fontSize="sm">แสดงหัวข้อ</FormLabel>
                <FormControl display="flex">
                  <Checkbox
                    ref={register}
                    defaultChecked={defaultValue?.show_name}
                    name="show_name"
                  >
                    เปิดใช้
                  </Checkbox>
                </FormControl>
                <div>
                  <div className="pb-4 pt-3">
                    <h1>ขนาดหัวข้อ</h1>
                    <div>
                      <Select
                        size="sm"
                        ref={register}
                        name="desktop.card_content.type_attribute.headersize"
                        defaultValue={defaultValue}
                        colorScheme="purple"
                      >
                        <option value="normal">normal</option>
                        <option value="sm">sm</option>
                        <option value="lg">lg</option>
                        <option value="xl">xl</option>
                        <option value="2xl">2xl</option>
                        <option value="3xl">3xl</option>
                        <option value="4xl">4xl</option>
                        <option value="5xl">5xl</option>
                      </Select>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        backgroundColor: `${watch('desktop.card_content.type_attribute.show_header_color')}`
                      }}
                    >
                      สีหัวข้อ
                    </p>
                    <Input
                      size="sm"
                      placeholder="โค้ดสี"
                      name="desktop.card_content.type_attribute.show_header_color"
                      defaultValue={defaultValue?.desktop?.card_content?.type_attribute?.show_header_color || '#'}
                      ref={register}
                      className="text-black "
                    />
                  </div>
                </div>
              </div>)
            }
            <div className="lg:flex  gap-20 py-2">
              <div className="w-full lg:w-1/3 px-2 py-6">
                <FormLabel fontSize="sm">เปิดใช้งานส่วนประกอบ</FormLabel>

                <FormControl display="flex">
                  <Checkbox ref={register} defaultChecked={defaultValue?.enable || true} name="enable">
                    เปิด
                  </Checkbox>
                </FormControl>
              </div>
              <div className="w-64 my-2 lg:w-1/3 px-1 pr-5">
                <div className="text-sm font-semibold my-4 justify-center flex">สีพื้นหลังส่วนประกอบ</div>
                <div className="flex justify-center lg:p-3 ">
                  <Controller
                    control={control}
                    name="bg_component_color"
                    defaultValue={defaultValue?.bg_component_color}
                    render={(field) => (
                      <SketchPicker
                        color={field.value}
                        onChangeComplete={(selectedColor) => {
                          field.onChange(selectedColor.hex)
                        }}
                        width={256}
                      />
                    )}
                  />
                </div>
              </div>
              <div className=" w-64 my-2 lg:w-1/3 px-1 pb-3 pr-5 pt-5 lg:pt-0">
                <div className="text-sm font-semibold my-4 justify-center flex">สีพื้นหลังส่วนเว็บไซต์</div>
                <div className="flex justify-center lg:p-3">
                  <Controller
                    control={control}
                    name="bg_website_color"
                    defaultValue={defaultValue?.bg_website_color}
                    render={(field) => (
                      <SketchPicker
                        color={field.value}
                        onChangeComplete={(selectedColor) => {
                          field.onChange(selectedColor.hex)
                        }}
                        width={256}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/2 px-2 py-1 pb-1">
              <FormLabel fontSize="sm">ภาพพื้นหลังส่วนประกอบ</FormLabel>
              {watch("display_type") === FrontpageComponentDisplayType.CARD_CONTENT_GALLERY.status_code &&
                (<div>
                  <Checkbox
                    ref={register}
                    size="sm"
                    defaultChecked={defaultValue?.background?.useCard_background || false}
                    name={`background.useCard_background`}
                  >
                    เปิดใช้งานภาพพื้นหลังการ์ด
                  </Checkbox>
                  {watch(`background.useCard_background`) === true && (
                    <Controller
                      control={control}
                      name="background.card_background._id"
                      defaultValue={defaultValue?.background?.card_background?._id || ''}
                      render={(field) => (
                        <Antselect
                          {...field}
                          style={{
                            width: '65%',
                            height: '60%'
                          }}
                          placeholder="เลือกภาพพื้นหลังส่วนประกอบ"
                          onChange={field.onChange}
                          optionLabelProp="label"

                        >
                          {_.map(backgroundgallery?.arr, (each, index) => (
                            <Option value={each?._id} key={index} label={each?._id} > 
                              <Space>
                                <span role="img" aria-label="image">
                                  <img src={each?.image?.url} />
                                </span>
                                {each?._id}{" "}
                              </Space>
                            </Option>
                          ))}
                        </Antselect>
                      )} />
                  )}
                </div>)
              }
              {watch("display_type") !== FrontpageComponentDisplayType.CARD_CONTENT_GALLERY.status_code &&
                (<div className="pt-3 pb-2">
                  <Checkbox
                    ref={register}
                    size="sm"
                    defaultChecked={defaultValue?.background?.use_background || false}
                    name={`background.use_background`}
                  >
                    เปิดใช้งานภาพพื้นหลังส่วนประกอบ
                  </Checkbox>
                  {watch(`background.use_background`) === true && (
                    <Controller
                      control={control}
                      name="type_attribute.background_gallery._id"
                      defaultValue={defaultValue?.type_attribute?.background_gallery?._id}
                      render={(field) => (
                        <Antselect
                          {...field}
                          style={{
                            width: '65%',
                            height: '60%'
                          }}
                          placeholder="เลือกภาพพื้นหลังส่วนประกอบ"
                          onChange={field.onChange}
                          optionLabelProp="label"

                        >
                          {_.map(backgroundgallery?.arr, (each, index) => (
                            <Option value={each?._id} key={index} label={each?._id} >
                              <Space>
                                <span role="img" aria-label="image">
                                  <img src={each?.image?.url} />
                                </span>
                                {each?._id}{" "}
                              </Space>
                            </Option>
                          ))}
                        </Antselect>
                      )} />
                  )}
                </div>)
              }
            </div>
          </div>
        )
        }
        {activeTab === 'Mobile' && (
          <div>
            <div>
              <div className="flex flex-wrap">
                <div className="w-full pt-2 lg:w-1/2 px-2 py-1">
                  <FormLabel className="pl-1 pt-2 text-sm font-semibold text-gray-600">
                    ระยะห่างระหว่างส่วนประกอบ
                  </FormLabel>
                  <Select
                    ref={register}
                    placeholder="ระยะห่าง"
                    defaultValue={defaultValue?.mobile?.component_distance}
                    name="mobile.component_distance"
                    size="sm"
                  >
                    {_.map(ComponentDistance, (each, index) => (
                      <option key={index} value={each.status_code}>
                        {i18n?.language === "th" ? each.description : each.descriptionEn}
                      </option>
                    ))}
                  </Select>
                </div>
                {watch("display_type") &&
                  watch("display_type") !== FrontpageComponentDisplayType.SLIDESHOW_GALLERY.status_code &&
                  watch("display_type") !== FrontpageComponentDisplayType.LIST_GALLERY_HORIZONTAL.status_code &&
                  watch("display_type") !== FrontpageComponentDisplayType.VIDEO.status_code
                  ? (
                    <motion.div
                      className="flex pr-80 gap-10 pt-3"
                      initial={{ opacity: 0, y: -20 }} // เซ็ตค่าเริ่มต้นก่อนที่คอมโพเนนต์จะแสดง
                      animate={{ opacity: 1, y: 0 }} // เซ็ตค่าขณะคอมโพเนนต์กำลังแสดง
                      transition={{ duration: 0.5 }} // ตั้งค่าเวลาที่ให้เคลื่อนไหว
                    >
                      <div className="flex pr-10 gap-10 pt-3"> {/* ปรับขนาดระยะห่าง pr-10 จากเดิมเป็น pr-80 */}
                        <div className="w-full lg:w-1/2 px-2 py-1">
                          <label className="text-sm font-semibold text-gray-700">ความกว้าง</label> {/* ปรับตัวอักษรให้เป็น font-semibold */}
                          <select
                            ref={register}
                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="ความกว้าง"
                            defaultValue={defaultValue?.mobile?.width}
                            name="mobile.width"
                            size="sm"
                          >
                            {_.map(ComponentWidth, (each, index) => (
                              <option
                                key={index}
                                value={each.status_code}
                                className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                              >
                                {i18n?.language === "th" ? each.description : each.descriptionEn}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="w-full lg:w-1/2 px-2 py-1">
                          <label className="text-sm font-semibold text-gray-700">ความสูง</label> {/* ปรับตัวอักษรให้เป็น font-semibold */}
                          <select
                            ref={register}
                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="ความสูง"
                            defaultValue={defaultValue?.mobile?.height}
                            name="mobile.height"
                            size="sm"
                          >
                            {_.map(ComponentHeight, (each, index) => (
                              <option
                                key={index}
                                value={each.status_code}
                                className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                              >
                                {i18n?.language === "th" ? each.description : each.descriptionEn}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </motion.div>
                  ) : (<div></div>)}
              </div>
            </div>
          </div>
        )
        }
        <FCPDisplayTypeForm
          componentType={watch("display_type")}
          defaultValue={defaultValue}
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          file={file}
          gallery={gallery}
          activeTab={activeTab}
          length={length}
          width={width}
        />
        <FCPDataSourceForm
          componentType={watch("data_source")}
          defaultValue={defaultValue}
          register={register}
          setValue={setValue}
          watch={watch}
          gallery={gallery}
          control={control}
          activeTab={activeTab}
        />
      </div >
    </div >
  )
}

export default FrontpageComponentForm
