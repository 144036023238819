import {
  PROMPTPAY_ALL,
  PROMPTPAY_CREATE,
  PROMPTPAY_DELETE,
  PROMPTPAY_GET,
  PROMPTPAY_PUT,
} from "../../actions/types"

const initialState = {
  promptPay: null,
  isLoading: false,
}
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PROMPTPAY_ALL:
      return {
        ...action.payload,
        isLoading: true,
      }
    case PROMPTPAY_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case PROMPTPAY_CREATE:
      return { isLoading: true }
    case PROMPTPAY_PUT:
      return { isLoading: true }
    case PROMPTPAY_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
