import {
  ARTICLE_ALL,
  ARTICLE_CREATE,
  ARTICLE_DELETE,
  ARTICLE_GET,
  ARTICLE_PUT,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function ActionReducers(state = initialState, action) {
  switch (action.type) {
    case ARTICLE_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        total: action.payload?.total,
      }
    case ARTICLE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case ARTICLE_CREATE:
      return { isLoading: true }
    case ARTICLE_PUT:
      return { isLoading: true }
    case ARTICLE_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
