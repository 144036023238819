import { DateTime } from "luxon"
import _ from "lodash"
import THBText from "thai-baht-text"
import dayjs from "dayjs"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter } from "../../../util"
import { MeetingRoomTimeSlot } from "../../constant"

const dateFormat = { day: "numeric", month: "long", year: "numeric" }

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 */

export default function OrderNoVatRecipt(serviceOrder, information, systemInfo, cashierName) {
  const meetingRoomOrder =
    _.sum(_.map(serviceOrder?.meeting_room_orders, (eachOrder) => eachOrder?.price)) || 0

  const servicePrice =
    _.sum(_.map(serviceOrder?.service_list, (eachServices) => eachServices?.price)) || 0
  const fbPrice = _.sum(_.map(serviceOrder?.fb_orders, (eachServices) => eachServices?.price)) || 0

  const allPrice = meetingRoomOrder + servicePrice + fbPrice - serviceOrder?.final_discount
  const priceBeforeFinalDiscount = allPrice + (serviceOrder?.final_discount || 0)

  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "ใบเสร็จรับเงิน",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "ใบเสร็จรับเงิน / Reciept ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        columns: [
          {
            text: [
              { text: "วันที่", bold: true },
              {
                text: `  ${DateTime.fromISO(serviceOrder?.updatedAt)
                  .setLocale("th")
                  .toLocaleString(dateFormat)}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
          "",
          {
            text: [
              { text: "เลขที่", bold: true },
              { text: `  ${serviceOrder?.order_no}`, margin: [5, 0] },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        text: [
          { text: "ลูกค้า", bold: true },
          {
            text: `  ${serviceOrder?.customer?.firstname || ""}  ${
              serviceOrder?.customer?.lastname || ""
            }`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
      {
        text: [{ text: serviceOrder?.customer?.address }],
        margin: [0, 2],
      },
      {
        text: [
          { text: "หมายเลขโทรศัพท์", bold: true },
          {
            text: `  ${serviceOrder?.customer?.tel || ""}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
      {
        text: [
          {
            text: serviceOrder?.customer?.is_legal_entity
              ? "เลขบัตรประจำตัวนิติบุคคล"
              : "เลขบัตรประจำตัวประชาชน",
            bold: true,
          },
          {
            text: `  ${serviceOrder?.customer?.id_card || ""}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 4],
      },
      !_.isEmpty(serviceOrder?.meeting_room_orders)
        ? {
            text: "รายการห้องประชุม",
            fontSize: 11,
            bold: true,
            margin: [0, 10, 0, 10],
          }
        : {},
      !_.isEmpty(serviceOrder?.meeting_room_orders)
        ? {
            table: {
              headerRows: 1,
              widths: [20, 120, "*", 100, 60],
              body: [
                [
                  { text: "ที่", bold: true },
                  { text: "ห้องประชุม", bold: true },
                  { text: "วันที่", bold: true },
                  { text: "ช่วงเวลา", bold: true },
                  { text: "ราคา", bold: true },
                ],
                ..._.map(serviceOrder?.meeting_room_orders, (eachList, index) => [
                  { text: index + 1, margin: [0, 2] },
                  {
                    text: `${eachList?.meeting_room?.translation?.th?.name || ""} `,
                    margin: [0, 2],
                  },
                  {
                    text: eachList?.meeting_room?.use_slot_price
                      ? `${DateTime.fromISO(eachList?.date)
                          .setLocale("th")
                          .toLocaleString(dateFormat)}`
                      : `${DateTime.fromISO(eachList?.start)
                          .setLocale("th")
                          .toLocaleString(dateFormat)}`,
                    margin: [0, 2],
                    alignment: "left",
                  },
                  {
                    text: eachList?.meeting_room?.use_slot_price
                      ? ` ${MeetingRoomTimeSlot[eachList?.timeslot]?.description}`
                      : ` ${dayjs(eachList?.start)?.format("HH.mm น.")} - ${dayjs(
                          eachList?.end,
                        )?.format("HH.mm น.")}  (${dayjs(eachList?.end).diff(
                          dayjs(eachList?.start),
                          "hour",
                        )}  ชม. ${
                          dayjs(eachList?.end).diff(dayjs(eachList?.start), "minute") % 60
                        } นาที )   `,
                    margin: [0, 2],
                    alignment: "left",
                  },

                  {
                    text: currencyFormatter.format(eachList?.price),
                    margin: [0, 2],
                    alignment: "right",
                  },
                ]),
              ],
            },
          }
        : {},
      {
        text: "รายการบริการ",
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, "*", 80, 60, 60],
          body: [
            [
              { text: "ที่", bold: true },
              { text: "รายการ", bold: true },
              { text: "ราคาต่อหน่วย", bold: true },
              { text: "จำนวน", bold: true },
              { text: "ราคา", bold: true },
            ],

            ..._.map(serviceOrder?.service_list, (eachList, index) => [
              { text: index + 1 + _.size(serviceOrder?.list), margin: [0, 2] },
              {
                text: eachList?.service?.translation?.th?.name || eachList?.service?.name || "",
                margin: [0, 2],
              },
              {
                text: currencyFormatter.format(parseFloat(eachList?.service?.price)),
                margin: [0, 2],
                alignment: "right",
              },
              { text: `${eachList?.amount}`, margin: [0, 2], alignment: "right" },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
              },
            ]),
            ..._.map(serviceOrder?.fb_orders, (eachList, index) => [
              {
                text: index + 1 + _.size(serviceOrder?.list) + _.size(serviceOrder?.services),
                margin: [0, 2],
              },
              {
                text: `${eachList?.remark || ""} (${DateTime.fromISO(eachList?.date)
                  .setLocale("th")
                  .toLocaleString(dateFormat)})`,
                margin: [0, 2],
              },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
              },
              { text: "1", margin: [0, 2], alignment: "right" },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
              },
            ]),
            serviceOrder?.final_discount > 0
              ? [
                  {
                    stack: [
                      { text: "ราคาก่อนรวมส่วนลด", margin: [0, 2] },
                      { text: "ส่วนลดท้ายใบเสร็จ", margin: [0, 2] },
                    ],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    stack: [
                      {
                        text: currencyFormatter.format(priceBeforeFinalDiscount),
                        margin: [0, 2],
                        alignment: "right",
                      },
                      {
                        text: currencyFormatter.format(serviceOrder?.final_discount),
                        margin: [0, 2],
                        alignment: "right",
                      },
                    ],
                  },
                ]
              : [
                  {
                    text: "",
                    border: [false, false, false, false],
                    colSpan: 5,
                  },
                  {},
                  {},
                  {},
                  {},
                ],
            [
              {
                text: `รวมเป็นเงิน ( ${THBText(allPrice)} ) `,
                colSpan: 4,
                bold: true,
                margin: [0, 2],
              },
              {},
              {},
              {},
              {
                text: currencyFormatter.format(allPrice),
                bold: true,
                alignment: "right",
              },
            ],
            serviceOrder?.payment?.paid_amount >= allPrice
              ? [
                  {
                    text: "",
                    border: [false, false, false, false],
                    colSpan: 5,
                  },
                  {},
                  {},
                  {},
                  {},
                ]
              : [
                  {
                    stack: [
                      { text: "มัดจำ", margin: [0, 2] },
                      { text: "ค้างชำระ", margin: [0, 2] },
                    ],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    stack: [
                      {
                        text: currencyFormatter.format(serviceOrder?.payment?.paid_amount),
                        margin: [0, 2],
                        alignment: "right",
                      },
                      {
                        text: currencyFormatter.format(
                          allPrice - serviceOrder?.payment?.paid_amount,
                        ),
                        margin: [0, 2],
                        alignment: "right",
                      },
                    ],
                  },
                ],
          ],
        },

        margin: [0, 15, 0, 0],
        fontSize: 10,
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้รับบริการ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: `(........${
                  cashierName || "........................................"
                }........)`,
                margin: [0, 2],
              },
              { text: "ผู้รับเงิน" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
