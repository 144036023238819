/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import _ from "lodash"
import dayjs from "dayjs"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"

import Layout from "../../components/layout/GeneralLayout"
import { SpinnerLoading, WhitePanel, Alert } from "../../components"
import { FoodOrderMiniSlip } from "../../config/pdfmake/template"
import "addthaifont-pdfmake/build/vfs_fonts"

import * as actions from "../../redux/actions"

export default function FBTransactionList() {
  const [isLoading, setIsLoading] = useState(true)
  const [size, setSize] = useState(25)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [fetchingNewOne, setFetchingNewOne] = useState(true)

  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const fbList = useSelector((state) => state.fbList)

  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const getAllFBOrder = () => {
    dispatch(
      actions.getAllFBOrderList({
        page: 1,
        size,
        query: {
          incompleted: false,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  // รีเฟรชทุกๆ 20 วินาที
  useEffect(() => {
    let timeout
    if (fetchingNewOne === false)
      timeout = setTimeout(() => {
        setFetchingNewOne(!fetchingNewOne)
      }, 10000)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [fetchingNewOne])

  useEffect(() => {
    if (fetchingNewOne) {
      setIsLoading(false)
      getAllFBOrder()
      setFetchingNewOne(!fetchingNewOne)
    }
    return () => {}
  }, [fetchingNewOne, size])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const handleMiniSlipPrinting = (record) => {
    console.log("Minislip record", record)
    const ReportDefinition = FoodOrderMiniSlip(record?.order, record, information, system)
    pdfMake.createPdf(ReportDefinition).open()
  }

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title={"รายการสั่งอาหาร"}>
      <div className="flex justify-between px-4 pb-2">
        <div>
          <Button variant="outline" colorScheme="purple" onClick={() => history.goBack()}>
            {t("general.back")}
          </Button>
        </div>
      </div>
      <Alert
        isError={isAlertOpen}
        handleOnClose={() => setIsAlertOpen(false)}
        errMessage={alertMessage}
      />
      {_.map(fbList?.arr, (item, index) => (
        <div key={index}>
          <WhitePanel>
            <div className="flex justify-between">
              <div>
                <div className="text-xl font-semibold">
                  {item?.menu?.translation?.[i18n?.language]?.name}
                  <span className="text-violet-700 text-xl font-semibold">
                    {" "}
                    x {item?.quantity}
                  </span>{" "}
                </div>
                <div>{item?.remark}</div>
                <div className="text-lg">
                  โต๊ะ{" "}
                  <span className="font-semibold text-xl text-violet-700">{item?.table?.name}</span>
                </div>
                <div>
                  เวลาที่สั่ง {dayjs(item?.createdAt).format("HH:mm")}{" "}
                  <span className="text-sm">
                    วันที่ {dayjs(item?.createdAt).format("D MMM YYYY")}
                  </span>{" "}
                </div>
              </div>
              <div className="flex gap-1">
                <div>
                  <div
                    className="bg-blue-700 rounded-md cursor-pointer hover:bg-blue-600 text-white hover:shadow-md w-20"
                    onClick={() => {
                      handleMiniSlipPrinting(item)
                    }}
                  >
                    <div className="flex flex-col p-2 justify-center text-center">
                      <i className="fas fa-download text-xl text-center"></i>
                      พิมพ์
                    </div>
                  </div>
                </div>
                {item?.recieved ? (
                  <div>
                    <div className="bg-violet-400 rounded-md w-20 text-white ">
                      <div className="flex flex-col p-2 justify-center">
                        <i className="fas fa-print text-xl text-center"></i>
                        รับทราบ
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="bg-violet-700 rounded-md cursor-pointer hover:bg-violet-600 text-white hover:shadow-md w-20"
                      onClick={() => {
                        dispatch(
                          actions.editOneFBOrderList(item?.id, {
                            recieved: true,
                          }),
                        ).then(() => {
                          setFetchingNewOne(!fetchingNewOne)
                        })
                      }}
                    >
                      <div className="flex flex-col p-2 justify-center">
                        <i className="fas fa-download text-xl text-center"></i>
                        รับทราบ
                      </div>
                    </div>
                  </div>
                )}
                {item?.completed ? (
                  <div>
                    <div className="bg-green-500 rounded-md w-20  ">
                      <div className="flex flex-col p-2 justify-center px-6">
                        <i className="fas fa-check text-xl text-center"></i>
                        เสร็จแล้ว
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className="bg-green-600 rounded-md cursor-pointer hover:bg-green-500 hover:shadow-md w-20 text-white"
                      onClick={() => {
                        dispatch(
                          actions.editOneFBOrderList(item?.id, {
                            completed: true,
                          }),
                        ).then(() => {
                          setFetchingNewOne(!fetchingNewOne)
                        })
                      }}
                    >
                      <div className="flex flex-col p-2 justify-center text-center">
                        <i className="fas fa-concierge-bell text-xl text-center"></i>
                        เสร็จ
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </WhitePanel>
        </div>
      ))}
      {fbList?.total > size && (
        <div className="flex justify-center my-2">
          <Button
            colorScheme="purple"
            leftIcon={<i className="fas fa-plus-circle"></i>}
            onClick={() => setSize(size + 10)}
          >
            เพิ่มอีก {fbList.total - size > 10 ? "10" : `${fbList?.total - size}`} รายการ
          </Button>
        </div>
      )}
    </Layout>
  )
}
