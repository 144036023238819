import { CHILL_PAY_ERROR, CHILL_PAY_GET } from "../../actions/types"

const initialState = {
  beds: null,
  isLoading: false,
}
export default function ChillpayReducer(state = initialState, action) {
  switch (action.type) {
    case CHILL_PAY_GET:
      return {
        payload: action.payload,
        isLoading: true,
        ready: action.ready,
      }
    case CHILL_PAY_ERROR:
      return {
        payload: action.payload,
        isLoading: false,
        ready: false,
      }
    default:
      return state
  }
}
