import React from "react"

const OpacitySlider = ({ register, opacityBorder, watch }) => (
  <div className="mb-0 ">
    <p className="text-m font-bold pb-4  text-purple-900">ความโปร่งแสงของกรอบข้อความ</p>
    <label className="block text-sm font-medium text-gray-700">Opacity</label>
    <input
      type="range"
      defaultValue={100}
      min={0}
      max={100}
      name={opacityBorder}
      ref={register}
      className="slider"
    />
    <span>{watch(opacityBorder)}%</span>
  </div>
)

export default OpacitySlider
