import {
  MATERIAL_ALL,
  MATERIAL_CREATE,
  MATERIAL_DELETE,
  MATERIAL_GET,
  MATERIAL_UPDATE,
  MATERIAL_ERROR,
} from "../../actions/types"

const initialState = {
  isLoading: false,
}
export default function MaterialReducer(state = initialState, action) {
  switch (action.type) {
    case MATERIAL_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        total: action.payload?.total,
      }
    case MATERIAL_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case MATERIAL_CREATE:
      return { isLoading: false }
    case MATERIAL_UPDATE:
      return { isLoading: false }
    case MATERIAL_DELETE:
      return { isLoading: false }
    case MATERIAL_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
