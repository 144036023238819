/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { Table, Select, Switch } from "antd"
import _, { filter } from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  Alert,
  GeneralMainLayout as Layout,
  ReportDateSelector,
  CSVGeneratorPanel,
  WhitePanel,
} from "../../components"
import * as actions from "../../redux/actions"
import { dayAmount, arrayBookingAnalysis, currencyFormatter } from "../../util"
import { appConfig } from "../../config"
import { BookingReport as BookingReportPDF } from "../../config/pdfmake/template"
import api from "../../config/api"

function BookingReport() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [date, setDate] = useState(new Date())
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [page, setPage] = useState(1)
  const size = 10
  const [isLoading, setIsLoading] = useState(false)
  const [orderByField, setOrderByField] = useState("")
  const [orderBy, setOrderBy] = useState("")
  const [excelExportOpen, setExcelExportOpen] = useState(false)
  const [selectedRoomType, setSelectedRoomType] = useState()
  const [filterOutRoomType, setFilterOutRoomType] = useState(false)

  const booking = useSelector((state) => state.bookings)
  const roomtypes = useSelector((state) => state.roomTypes)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const dispatch = useDispatch()

  useEffect(() => {
    setExcelExportOpen(false)
    dispatch(
      actions.getAllBookingAndLog({
        start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
        end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        page,
        size,
        orderBy,
        orderByField,
        filterOutRoomType: filterOutRoomType || "",
        roomType: selectedRoomType || "",
        onReport: true,
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [date, page, orderBy, orderByField, startDate, endDate, selectedRoomType, filterOutRoomType])

  useEffect(() => {
    dispatch(
      actions.getAllRoomTypes({
        name: "",
        page: 1,
        size: appConfig.maxFetchSize,
      }),
    )

    return () => {}
  }, [])

  const calculateWeekdayAndWeekend = (start, end) => {
    const amount = dayAmount(start, end) || 1
    let weekend = 0
    let weekday = 0
    _.map(Array(amount), (each, index) => {
      const cursorDay = dayjs(start).add(index, "day")
      // Friday and Saturday
      if (cursorDay.day() >= 5) {
        weekend += 1
      } else {
        weekday += 1
      }
    })
    return { weekday, weekend }
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "เลขที่กาารจอง",
      dataIndex: "booking_no",
      render: (text, record) => record?.booking_no,
      sorter: true,
    },
    {
      title: "ลูกค้า",
      dataIndex: "customer",
      render: (text, record) => `  ${record?.customer?.firstname} ${record?.customer?.lastname}`,
    },
    {
      title: "โทร",
      dataIndex: "customer",
      render: (text, record) => record?.customer?.tel,
    },

    {
      title: "วันที่เข้าพัก",
      dataIndex: "start",
      sorter: true,
      render: (text, record) => `${dayjs(record?.start).format("dd D/MM/YY")}`,
    },
    {
      title: "วันที่ออก",
      dataIndex: "bookings",
      render: (text, record) => `${dayjs(record?.end).format("dd D/MM/YY")}`,
    },
    {
      title: "ห้องพัก",
      dataIndex: "list",
      render: (text, record) => _.map(record?.list, (eachList) => `${eachList?.room?.name} `),
    },
    {
      title: "ยังไม่ระบุห้อง",
      dataIndex: "no_room_booking",
      render: (text, record) =>
        _.map(
          record?.no_room_booking,
          (eachList) => `${eachList?.roomtype?.translation?.th?.name} x ${eachList?.amount} `,
        ),
    },
    {
      title: "จำนวนคืน",
      dataIndex: "bookings",
      render: (text, record) => `${dayAmount(record?.start, record?.end)}`,
    },
    {
      title: "จำนวนห้อง",
      dataIndex: "room_amount",
      render: (text, record) => _.size(record?.list),
    },
    {
      title: "จำนวนวัน (อา-พฤ)",
      dataIndex: "weekday",
      render: (text, record) => calculateWeekdayAndWeekend(record?.start, record?.end)?.weekday,
    },
    {
      title: "จำนวนวัน (ศ-ส)",
      dataIndex: "weekend",
      render: (text, record) => calculateWeekdayAndWeekend(record?.start, record?.end)?.weekend,
    },
    {
      title: "ราคา",
      dataIndex: "priceBeforeDiscount",
      render: (text, record) => record?.priceBeforeDiscount,
    },

    {
      title: "ส่วนลด",
      dataIndex: "total_discount",
      render: (text, record) => record?.total_discount,
    },
    {
      title: "ราคาสุทธิ",
      dataIndex: "price",
      render: (text, record) => record?.price,
    },
    {
      title: "มัดจำ",
      dataIndex: "payment.paid_amount",
      render: (text, record) => record?.payment?.paid_amount,
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => record?.status,
    },
    {
      title: "บริการ",
      dataIndex: "services",
      render: (text, record) =>
        _.map(record?.services, (each) => `${each?.service?.name} x ${each?.amount} `),
    },
    {
      title: "หมายเหตุ",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.remark,
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderBy("asc")
    } else {
      setOrderBy("desc")
    }

    if (sorter?.field === "booking_no") {
      setOrderByField("booking_no")
    }

    if (sorter?.field === "status") {
      setOrderByField("status")
    }

    if (sorter?.field === "start") {
      setOrderByField("start")
    }
  }

  const fullQueryParams = new URLSearchParams({
    page: 1,
    size: appConfig.maxFetchSize,
    start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
    end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
    date: date ? dayjs(date).format("YYYY-MM-DD") : "",
    filterOutRoomType: filterOutRoomType || "",
    roomType: selectedRoomType || "",
    onReport: true,
    orderByField: "start",
    orderBy: "asc",
  })

  const handlePrintPDF = async () => {
    try {
      setIsLoading(false)
      api
        .get(`${process.env.REACT_APP_API_URL}/booking/with-logs/?${fullQueryParams.toString()}`)
        .then((res) => {
          const pdfDeginition = BookingReportPDF(
            arrayBookingAnalysis(res?.data?.rows),
            date,
            date !== null,
            startDate,
            endDate,
            information,
            system,
          )
          console.log("PDF Definition", pdfDeginition)
          pdfMake.createPdf(pdfDeginition).open()
        })
        .catch((error) => {
          alert(error?.message)
        })
        .finally(() => {
          setIsLoading(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout categoryTitle={t("history.title")} title={t("history.bookingReport")}>
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            colorScheme="red"
            size="sm"
            leftIcon={<i className="fas fa-file-pdf"></i>}
            onClick={() => handlePrintPDF()}
          >
            PDF
          </Button>
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<i className="fas fa-file-excel"></i>}
            onClick={() => {
              if (excelExportOpen === true) {
                setExcelExportOpen(false)
                setTimeout(() => {
                  setExcelExportOpen(true)
                }, 500)
              } else {
                setExcelExportOpen(true)
              }
            }}
          >
            CSV
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <ReportDateSelector
          pointDate={date}
          setPointDate={setDate}
          endDate={endDate}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        >
          <div>
            <div className="text-sm my-2">การกรองเพิ่มเติม</div>
            <Select
              onChange={(value) => setSelectedRoomType(value)}
              placeholder="ประเภทห้องพัก"
              style={{
                width: 200,
              }}
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              showSearch
              options={_.map(roomtypes?.arr, (eachRoomtype) => ({
                value: eachRoomtype?.id,
                label: eachRoomtype?.translation?.[i18n.language]?.name || eachRoomtype?.name,
              }))}
            ></Select>
            <div className="text-sm my-2">กรองเฉพาะรูปแบบห้องที่เลือก</div>
            <Switch
              defaultChecked={filterOutRoomType}
              onChange={(value) => setFilterOutRoomType(value)}
            />
          </div>
        </ReportDateSelector>
        <CSVGeneratorPanel
          apiSuffix="booking/with-logs"
          columns={columns}
          open={excelExportOpen}
          queryParams={fullQueryParams}
        />
        <WhitePanel>
          <div>
            <div className="font-semibold">สรุปห้องพัก</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {_.map(booking?.analysis, (eachRoomtype, index) => (
                <div className="my-1" key={index}>
                  <div className="font-semibold text-sm">
                    {eachRoomtype?.roomtype?.translation?.[i18n.language]?.name}
                  </div>
                  <div className="text-sm">
                    <i className="fas fa-door-open"></i> {eachRoomtype?.sum_amount} ห้อง{" "}
                    {currencyFormatter.format(eachRoomtype?.sum_price)} บาท
                  </div>
                </div>
              ))}
            </div>
          </div>{" "}
        </WhitePanel>

        {isLoading && (
          <Table
            columns={columns}
            dataSource={booking?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: booking?.total,
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default BookingReport
