import React from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

function RichTextEditor({ value, setValue = () => {}, onChange = () => {} }) {
  const quiiModule = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  }

  return (
    <div className="w-full">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(e) => {
          setValue(e)
          onChange(e)
        }}
        modules={quiiModule}
      />
    </div>
  )
}

export default RichTextEditor
