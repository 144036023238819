import React from "react"
import ImageUpload from "../../ImageUpload/ImageUpload"
import { Form, Input } from "antd"
import { Controller } from "react-hook-form"

const { TextArea } = Input
const PolicyInfo = ({ register, defaultInfo, image, setImage, control }) => (
  <div>
    <div className=" bg-white p-4  px-8 rounded-lg shadow-lg my-3">
      <Form layout="vertical" size="large">
        <h6 className="text-gray-900 text-base  font-bold uppercase font-sans my-2">
          นโยบายโรงแรม
        </h6>{" "}
        <Form.Item label="เวลาเช็คอิน" tooltip="จะแสดงในใบรายการการจอง">
          <Controller
            control={control}
            name="policy.checkinTime"
            defaultValue={defaultInfo?.policy?.checkinTime}
            render={(field) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                placeholder="เวลาที่สามารถเริ่มเช็คอินได้"
              />
            )}
          />
        </Form.Item>
        <Form.Item label="เวลาเช็คเอาท์" tooltip="จะแสดงในใบรายการการจอง">
          <Controller
            control={control}
            name="policy.checkoutTime"
            defaultValue={defaultInfo?.policy?.checkoutTime}
            render={(field) => (
              <Input {...field} style={{ width: "100%" }} placeholder="เวลาเช็คเอาท์" />
            )}
          />
        </Form.Item>
        <Form.Item
          label="ข้อกำหนดและนโยบายในการจองและการเข้าพัก"
          tooltip="จะขึ้นให้ลูกค้าเห็นในการจองผ่านทางออนไลน์ และในการเช็คอินออนไลน์ (ถ้ามี)"
        >
          <Controller
            control={control}
            name="policy.termAndCondition"
            defaultValue={defaultInfo?.policy?.termAndCondition}
            render={(field) => (
              <TextArea
                {...field}
                style={{ width: "100%" }}
                placeholder="ข้อกำหนดและนโยบายในการจองและการเข้าพัก"
                rows={5}
              />
            )}
          />
        </Form.Item>
        <Form.Item label="รูปภาพประกอบเกี่ยวกับนโยบายในการจอง (ถ้ามี)">
          {defaultInfo?.policy?.policyImage?.url && (
            <img src={defaultInfo?.policy?.policyImage?.url} className="w-72 my-1" />
          )}
          <ImageUpload images={image} setImages={setImage} maxNumber={1} />
        </Form.Item>
        <Form.Item label="เงื่อนไขการชำระเงิน" tooltip="จะขึ้นให้ลูกค้าเห็นในการจองผ่านทางออนไลน์">
          <Controller
            control={control}
            name="policy.paymentCondition"
            defaultValue={defaultInfo?.policy?.paymentCondition}
            render={(field) => (
              <TextArea
                {...field}
                style={{ width: "100%" }}
                placeholder="เงื่อนไขการชำระเงิน"
                rows={5}
              />
            )}
          />
        </Form.Item>
        <Form.Item label="นโยบายยกเลิกการจอง" tooltip="จะขึ้นให้ลูกค้าเห็นในการจองผ่านทางออนไลน์">
          <Controller
            control={control}
            name="policy.canclePolicy"
            defaultValue={defaultInfo?.policy?.canclePolicy}
            render={(field) => (
              <TextArea
                {...field}
                style={{ width: "100%" }}
                placeholder="นโยบายยกเลิกการจอง"
                rows={5}
              />
            )}
          />
        </Form.Item>
        <Form.Item
          label="นโยบายความเป็นส่วนตัว"
          tooltip="จะมี Popup ขึ้นมาให้ลูกค้าคลิกเข้าไปดูในระบบเว็บไซต์ โดยจะรวมนโยบายความเป็นส่วนตัวของระบบไปด้วย (เป็นไปตามนโยบาย)"
        >
          <Controller
            control={control}
            name="policy.privacyPolicy"
            defaultValue={defaultInfo?.policy?.privacyPolicy}
            render={(field) => (
              <TextArea
                {...field}
                style={{ width: "100%" }}
                placeholder="นโยบายความเป็นส่วนตัว"
                rows={5}
              />
            )}
          />
        </Form.Item>
      </Form>
    </div>

    <div className="flex flex-wrap bg-white p-4 my-3 rounded-lg shadow-lg">
      <div className="w-full px-4 ">
        <h6 className="text-gray-900 text-base my-2 font-bold uppercase font-sans ">
          เจ้าหน้าที่ผู้ดูแลด้านการจอง
        </h6>

        <div className="relative w-full mb-3 ">
          <label
            className="block uppercase text-gray-700 text-sm font-semibold mb-2"
            htmlFor="grid-password"
          >
            ชื่อ
          </label>
          <input
            name="staff.bookingAdmin.name"
            type="text"
            defaultValue={defaultInfo?.staff?.bookingAdmin?.name}
            ref={register}
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="ชื่อเจ้าหน้าที่"
          ></input>
        </div>

        <div className="relative w-full mb-3 ">
          <label
            className="block uppercase text-gray-700 text-sm font-semibold mb-2"
            htmlFor="grid-password"
          >
            ชื่อตำแหน่ง
          </label>
          <input
            name="staff.bookingAdmin.position"
            defaultValue={defaultInfo?.staff?.bookingAdmin?.position}
            ref={register}
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
            placeholder="ชื่อตำแหน่ง เช่น หัวหน้าแผนกจองห้องพัก"
          ></input>
        </div>
      </div>
    </div>
  </div>
)

export default React.forwardRef(PolicyInfo)
