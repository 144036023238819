/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter } from "../../../util"

dayjs.extend(buddhistEra)

export default function MenuReport(
  transactions,
  date,
  startDate,
  endDate,
  isPointDate,
  information,
  systemInfo,
) {
  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายงานเมนูที่สั่ง",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายงานเมนูที่สั่ง / Menu Report",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      isPointDate
        ? {
            text: [
              { text: "วันที่", bold: true },
              {
                text: `  ${dayjs(date).format("D MMMM BBBB")}  (วันที่ ${dayjs(date).format(
                  "D MMMM BBBB",
                )} เวลา 12.01 - วันที่ ${dayjs(date)
                  .add(1, "day")
                  .format("D MMMM BBBB")} เวลา 11.59 )`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          }
        : {
            text: [
              { text: "จากวันที่", bold: true },
              {
                text: `  ${dayjs(startDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
              { text: "ถึงวันที่", bold: true },
              {
                text: `  ${dayjs(endDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: [30, "*", 90, 60],
          body: [
            [
              { text: "ลำดับที่", bold: true },
              { text: "เมนูอาหาร", bold: true },
              { text: "ปริมาณที่สั่ง", bold: true },
              { text: "มูลค่ารวม", bold: true },
            ],
            ..._.map(transactions, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: eachList?.translation?.th?.name,
                margin: [0, 2],
              },
              {
                text: `${eachList?.fblist?.total || "-"} จาน`,
              },
              {
                text: `${eachList?.fblist?.price || "-"} บาท`,
              },
            ]),
            [
              { text: "รวม", colSpan: 3, bold: true },
              {},
              {},
              {
                text: currencyFormatter.format(_.sumBy(transactions, "fblist.price")),
              },
            ],
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
