export const ModuleList = {
  RESERVATION: {
    status_code: "RESERVATION",
    description: "การจอง",
    mode: "HOTEL",
  },
  CRM: {
    status_code: "CRM",
    description: "ลูกค้าสัมพันธ์",
    mode: "ALL",
  },
  ROOM_TYPES: {
    status_code: "ROOM_TYPES",
    description: "การจัดการห้องพัก",
    mode: "HOTEL",
  },
  HISTORY: {
    status_code: "HISTORY",
    description: "รายงานและประวัติ",
    mode: "HOTEL",
  },
  SITE: {
    status_code: "SITE",
    description: "การจัดการเว็บไซต์",
    mode: "ALL",
  },
  CHECK_IN: {
    status_code: "CHECK_IN",
    description: "เช็คอินออนไลน์",
    mode: "HOTEL",
  },
  FOOD_BEVERAGE: {
    status_code: "FOOD_BEVERAGE",
    description: "อาหารและเครื่องดื่ม",
    mode: "HOTEL",
  },
  SERVICE_ORDER: {
    status_code: "SERVICE_ORDER",
    description: "ห้องประชุมและออเดอร์การบริการ",
    mode: "HOTEL",
  },
  SYSTEM: {
    status_code: "SYSTEM",
    description: "จัดการระบบ",
    mode: "ALL",
  },
  INVENTORY: {
    status_code: "INVENTORY",
    description: "รายการสั่งซื้อวัตถุดิบ",
    mode: "ALL",
  },
  // HOMESTAY_INFO: {
  //   status_code: "HOMESTAY_INFO",
  //   description: "จัดการข้อมูลโฮมสเตย์",
  //   mode: "HOMESTAY",
  // },
  // HOMESTAY_RESERVATION: {
  //   status_code: "HOMESTAY_RESERVATION",
  //   description: "จัดการการจอง",
  //   mode: "HOMESTAY",
  // },
  // HOMESTAY_SERVICE: {
  //   status_code: "HOMESTAY_SERVICE",
  //   description: "บริการเสริม",
  //   mode: "HOMESTAY",
  // },
}

export default ModuleList
