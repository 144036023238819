import React from "react"
import { Checkbox, Select, FormControl, FormLabel, Badge, Button } from "@chakra-ui/react"
import { SketchPicker } from "react-color"
import { Form, Input, Switch } from "antd"
import { Controller } from "react-hook-form"
import _ from "lodash"
import Flags from "country-flag-icons/react/3x2"
import { Link } from "react-router-dom"

import { WhitePanel } from "../../common/Cards"
import { MainMenuType, FrontepageMenuList } from "../../../config/constant"
import ExpandedMenuForm from "./ExpandedMenuForm"

function MenuForm({ register, defaultValue, watch, control, selectmenutype, setSelectmenutype }) {
  return (
    <div>
      <div>
        <WhitePanel>
          <div className="flex flex-wrap">
            <div className="font-semibold w-full my-1">ตั้งค่ารูปแบบเมนู</div>

            <div className="w-full my-2 lg:w-1/2 px-1">
              <div className="text-sm font-semibold my-1">รูปแบบเมนู</div>
              <Select
                size="sm"
                ref={register}
                onClick={() => setSelectmenutype(!selectmenutype)}
                name="menu.menuType"
                defaultValue={defaultValue?.menu?.menuType}
              >
                {_.map(MainMenuType, (each, index) => (
                  <option key={index} value={each.status_code}>
                    {each?.description}
                  </option>
                ))}
              </Select>
            </div>
            <div className="w-full my-2 lg:w-1/2 px-1">
              <div className="text-sm flex justify-center pb-3 font-semibold my-1">สีตัวอักษร</div>
              <div className="flex justify-center">
                <Controller
                  control={control}
                  name="menu.color"
                  defaultValue={defaultValue?.menu?.color}
                  render={(field) => (
                    <SketchPicker
                      color={field.value}
                      onChangeComplete={(selectedColor) => {
                        field.onChange(selectedColor.hex)
                      }}
                      width={256}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </WhitePanel>
      </div>
      <div>
        <WhitePanel>
          <div className="flex justify-between my-1">
            <div className="font-semibold w-1/2 my-1">ตั้งค่ารายการเมนู</div>
            <Link to={`/site/menu/arrange`}>
              <Button size="sm" colorScheme="yellow">
                จัดเรียงเมนู
              </Button>
            </Link>
          </div>
          {_.map(FrontepageMenuList, (eachField, index) => (
            <div key={index} className="my-2 p-4 border rounded-md">
              <div className="flex flex-wrap justify-between">
                <div className="font-semibold">
                  {eachField.description}
                  <Badge size="sm" className="ml-2">
                    {eachField.link}{" "}
                  </Badge>{" "}
                </div>
                <div>
                  <Controller
                    control={control}
                    name={`menu.menuList[${index}].enable`}
                    defaultValue={defaultValue?.menu?.menuList?.[index]?.enable || eachField.enable}
                    render={(field) => (
                      <div className="flex gap-2">
                        <Switch {...field} defaultChecked={field.value} />
                        เปิดใช้งาน
                      </div>
                    )}
                  />
                </div>
              </div>
              <Form layout="vertical">
                <Form.Item label="ชื่อภาษาไทย">
                  <Controller
                    control={control}
                    name={`menu.menuList[${index}].translation.th.menuTitle`}
                    defaultValue={
                      defaultValue?.menu?.menuList?.[index]?.translation?.th?.menuTitle ||
                      eachField.description
                    }
                    render={(field) => (
                      <Input
                        placeholder="ภาษาไทย"
                        addonBefore={<Flags.TH title="Thai" className="w-5" />}
                        {...field}
                      />
                    )}
                  />
                </Form.Item>
                <Form.Item label="ชื่อภาษาอังกฤษ">
                  <Controller
                    control={control}
                    name={`menu.menuList[${index}].translation.en.menuTitle`}
                    defaultValue={defaultValue?.menu?.menuList?.[index]?.translation?.en?.menuTitle}
                    render={(field) => (
                      <Input
                        placeholder="English"
                        addonBefore={<Flags.GB title="English" className="w-5" />}
                        {...field}
                      />
                    )}
                  />
                </Form.Item>
                <Form.Item label="ลิงค์">
                  <Controller
                    control={control}
                    name={`menu.menuList[${index}].link`}
                    defaultValue={eachField.link}
                    render={(field) => <Input placeholder="Link" {...field} readOnly />}
                  />
                </Form.Item>
                {eachField.useOtherLink && (
                  <Form.Item label="ลิงค์ภายนอก">
                    <Controller
                      control={control}
                      name={`menu.menuList[${index}].otherLink`}
                      defaultValue={
                        defaultValue?.menu?.menuList?.[index]?.otherLink || eachField.otherLink
                      }
                      render={(field) => <Input {...field} />}
                    />
                  </Form.Item>
                )}

                {watch("menu.menuType") === MainMenuType.DOUBLE.status_code && (
                  <Form.Item label="มีเมนูย่อย">
                    <Controller
                      control={control}
                      name={`menu.menuList[${index}].hasExpandedMenu`}
                      defaultValue={defaultValue?.menu?.menuList?.[index]?.hasExpandedMenu || false}
                      render={(field) => (
                        <div className="flex gap-2">
                          <Switch {...field} defaultChecked={field.value} />
                          มีเมนูย่อย
                        </div>
                      )}
                    />
                  </Form.Item>
                )}
                {(watch(`menu.menuList[${index}].hasExpandedMenu`) === true ||
                  defaultValue?.menu?.menuList?.[index]?.hasExpandedMenu) && (
                  <div>
                    <ExpandedMenuForm
                      control={control}
                      selectedIndex={index}
                      defaultValue={defaultValue}
                    />
                  </div>
                )}
              </Form>
            </div>
          ))}
        </WhitePanel>
      </div>
      <div>
        <WhitePanel>
          <div className="font-semibold w-full my-1">ตั้งค่ารูปแบบเมนูเพิ่มเติม</div>
          <div className="w-full lg:w-1/3 px-2 py-1">
            <FormLabel fontSize="sm">กรอบเมนู</FormLabel>

            <FormControl display="flex">
              <Checkbox
                ref={register}
                defaultChecked={defaultValue?.menu?.enable || true}
                name="menu.enable"
              >
                เปิดใช้
              </Checkbox>
            </FormControl>
          </div>
        </WhitePanel>
      </div>
    </div>
  )
}

export default MenuForm
