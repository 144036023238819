import React, { useState, useEffect } from "react"

import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// components
import { Button, useToast } from "@chakra-ui/react"
import { Skeleton } from "antd"

import Layout from "../../../components/layout/GeneralLayout"
import { api, appConfig } from "../../../config"
import * as actions from "../../../redux/actions"
import { APIKeyPanel, FrontpagePayloadPanel, InformationBackup } from "../../../components/Panels"

export default function DevelopmentPortal() {
  const [frontpagePayload, setFrontPagePayload] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const information = useSelector((state) => state.information)
  const articles = useSelector((state) => state.articles)
  const promotions = useSelector((state) => state.promotions)
  const gallery = useSelector((state) => state.gallery)
  const facilities = useSelector((state) => state.facilities)
  const articleCategories = useSelector((state) => state.articleCategories)

  const dispatch = useDispatch()
  const toast = useToast()

  const loadingData = async () => {
    setIsLoading(false)
    try {
      await dispatch(
        actions.getAllArticle({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      )
      await dispatch(
        actions.getAllPromotions({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      )
      await dispatch(
        actions.getAllGallery({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      )
      await dispatch(
        actions.getAllFacility({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      )
      await dispatch(
        actions.getAllArticleCategory({
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      )
    } catch (error) {
      console.log(error)
    }
    setIsLoading(true)
  }

  useEffect(() => {
    loadingData()

    return () => {}
  }, [])

  const generatePayload = async () => {
    try {
      const res = await api.get(`${process.env.REACT_APP_API_URL}/infomation/frontpage-payload`)
      setFrontPagePayload(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleRestore = async (file) => {
    try {
      console.log("File", file)
      await dispatch(actions.onRestoreFromJSONFile(information?.id, file))
      dispatch(actions.getInformation())
      toast({
        title: "ดำเนินการสำเร็จ",
        status: "success",
      })
    } catch (error) {
      console.log(error)
      toast({
        title: `ไม่สามารถ Restore ข้อมูลได้ ${error?.message}`,
        status: "error",
      })
    }
  }
  return (
    <Layout categoryTitle="จัดการระบบ" title="สำหรับนักพัฒนา">
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="w-full p-4 ">
        <APIKeyPanel />
        <div className="my-2">
          <FrontpagePayloadPanel
            handleGeneratePayload={generatePayload}
            payloadData={frontpagePayload}
          />
        </div>{" "}
        <div className="my-2">
          {isLoading ? (
            <InformationBackup
              information={information}
              handleRestore={handleRestore}
              article={articles?.arr}
              promotion={promotions?.arr}
              gallery={gallery?.arr}
              facility={facilities?.arr}
              articleCategory={articleCategories?.arr}
            />
          ) : (
            <Skeleton active />
          )}
        </div>
      </div>
    </Layout>
  )
}
