import {
  GALLERY_ALL,
  GALLERY_CREATE,
  GALLERY_DELETE,
  GALLERY_GET,
  GALLERY_PUT,
} from "../../actions/types"

const initialState = {
  gallery: null,
  isLoading: false,
}
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case GALLERY_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case GALLERY_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case GALLERY_CREATE:
      return { isLoading: true }
    case GALLERY_PUT:
      return { isLoading: true }
    case GALLERY_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
