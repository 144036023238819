import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Button as AntButton } from "antd"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { useForm, useFieldArray } from "react-hook-form"
import { useHistory } from "react-router-dom"

import _ from "lodash"
import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel, Alert, TableForm } from "../../components"

import * as actions from "../../redux/actions"

export default function CreateManyTable() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { control, handleSubmit } = useForm({ defaultValues: { arr: [{}] } })
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const { fields, append, remove } = useFieldArray({
    name: "arr",
    control,
  })

  const handleCreateTable = (data) => {
    console.log("Data", data)
    dispatch(actions.createOneTable(data))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })
  }

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="สร้างโต๊ะ">
      <Alert errMessage={errMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-between flex-wrap px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>

        <div className="w-full md:w-1/4  self-center mt-2"></div>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(handleCreateTable)}>
          <WhitePanel>
            {_.map(fields, (field, index) => (
              <div key={field?.id}>
                <TableForm control={control} formSize="medium" index={index} onMany />
                <div className="flex justify-end">
                  <AntButton danger onClick={() => remove(index)}>
                    ลบ
                  </AntButton>
                </div>
                <hr className="my-2" />
              </div>
            ))}

            <AntButton
              type="dashed"
              onClick={() => append({})}
              block
              icon={<i className="fas fa-plus" />}
            >
              เพิ่มโต๊ะ
            </AntButton>
          </WhitePanel>
          <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
