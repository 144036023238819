/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from "react"
import { Alert, Button, FormControl, FormLabel, Input, Switch } from "@chakra-ui/react"
import { useSelector, useDispatch } from "react-redux"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"
import { useTranslation } from "react-i18next"
import QRCode from "qrcode"
import { Link } from "react-router-dom"

import * as actions from "../../redux/actions"
import {
  Recipt,
  TravelItinerary,
  RegustrationCard as RegistrationCard,
  ServiceCoupon,
  Folio,
  Invoice,
} from "../../config/pdfmake/template"
import { BookingStatus } from "../../config/constant"
import { appConfig } from "../../config"
// pdfMake.vfs = pdfFonts.pdfMake.vfs

export default function PrintingPanel({
  bookingInfo,
  skipTitle = false,
  keyDeposit = true,
  reciept = true,
  initery = true,
  noVatRecipt = true,
  registrationCard = true,
  serviceCoupon = false,
  custom = true,
  folio = false,
  invoice = true,
  vatExcludeRecipt = false,
}) {
  const me = useSelector((state) => state.me)
  const [isLoading, setIsLoading] = useState(false)
  const [cashierName, setCashierName] = useState(
    `${me?.employeeData?.firstname || "-"}  ${me?.employeeData?.lastname || ""}`,
  )
  const ERA = ["be", "ad"]
  const [selectedEra, setSelectedEra] = useState(ERA[0]) // ad for christain era
  const [reciptMode, setReciptMode] = useState("")
  const [openCashier, setOpenCashier] = useState(false)
  const [displayRoom, setDisplayRoom] = useState(false)
  const [displayReserve, setDisplayReserve] = useState(false)
  const [customBookNo, setCustomBookNo] = useState("")
  const [customBookingNo, setCustomBookingNo] = useState("")
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (bookingInfo) {
      dispatch(actions.getAllSubRoomTypes({ page: 1, size: appConfig.maxFetchSize })).then(() => {
        setIsLoading(true)
      })
    }
    return () => {}
  }, [bookingInfo])
  console.log("Displarreserve", displayReserve)

  const initeryDefinition = TravelItinerary({
    bookingInfo,
    information,
    systemInfo: system,
    era: selectedEra,
    displayReserve,
  })
  const receiptDefinition = Recipt({
    bookingInfo,
    information,
    cashierName,
    systemInfo: system,
    vatEnable: true,
    era: selectedEra,
    displayRoom,
    customBookNo,
    customBookingNo,
  })
  const noVatReciptDefinition = Recipt({
    bookingInfo,
    information,
    cashierName,
    systemInfo: system,
    vatEnable: false,
    era: selectedEra,
    displayRoom,
    customBookNo,
    customBookingNo,
  })

  const vatExcludeDefinition = Recipt({
    bookingInfo,
    information,
    cashierName,
    systemInfo: system,
    vatEnable: true,
    era: selectedEra,
    displayRoom,
    vatStyle: "exclude",
    customBookNo,
    customBookingNo,
  })

  const registrationCardDefinition = RegistrationCard(bookingInfo, information, system)

  const serviceCouponGetting = async (bookingData) => {
    let count = 1
    const serviceList = []
    for await (const eachService of bookingData?.services || []) {
      const arrayOfAmount = new Array(eachService?.amount)
      for await (const aa of arrayOfAmount) {
        try {
          const url = await QRCode.toDataURL(`${eachService?.order_no}-${count}`)
          serviceList.push({
            ...eachService,
            order_no: bookingData?.order_no,
            number: count,
            date: bookingData?.start,
            serviceOrder: bookingData,
            qrcode: url,
          })
        } catch (err) {
          console.error("Error", err)
          serviceList.push({
            ...eachService,
            order_no: bookingData?.order_no,
            number: count,
            qrcode: "",
            serviceOrder: bookingData,
            date: bookingData?.start,
          })
        }
        count += 1
      }
    }

    return serviceList
  }

  useEffect(() => {
    serviceCouponGetting(bookingInfo)

    return () => {}
  }, [bookingInfo])

  if (!isLoading) {
    return <div></div>
  }

  return (
    <div>
      {!skipTitle && (
        <h6 className="text-xs font-bold mt-2 font-sans ">{t("reservation.document")}</h6>
      )}
      <div className="flex flex-wrap gap-2">
        <div className="border p-2 rounded-md w-48 ">
          <div className="text-sm  text-center my-1">ศักราช</div>
          <div className="flex gap-2  justify-center items-center">
            <div>พ.ศ.</div>
            <Switch
              size="sm"
              colorScheme="purple"
              checked={selectedEra === ERA[1]}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedEra(ERA[1])
                } else {
                  setSelectedEra(ERA[0])
                }
              }}
            >
              ค.ศ.
            </Switch>
          </div>{" "}
        </div>
        <div className="border p-2 rounded-md w-48 ">
          <div className="text-sm text-center my-1">แสดงห้องในใบเสร็จ</div>
          <div className="flex gap-2 justify-center items-center">
            <div>ไม่แสดง</div>
            <Switch
              size="sm"
              colorScheme="purple"
              checked={displayRoom}
              onChange={(e) => {
                setDisplayRoom(e.target.checked)
              }}
            >
              แสดง
            </Switch>
          </div>
        </div>
        <div className="border p-2 rounded-md w-48 ">
          <div className="text-sm text-center my-1">แสดงห้องในใบรายการจอง</div>
          <div className="flex gap-2 justify-center items-center">
            <div>ไม่แสดง</div>
            <Switch
              size="sm"
              colorScheme="purple"
              checked={displayReserve}
              onChange={(e) => {
                setDisplayReserve(e.target.checked)
              }}
            >
              แสดง
            </Switch>
          </div>
        </div>
      </div>
      <div div className="flex flex-wrap gap-2 mt-2">
        {initery && (
          <Button
            size="sm"
            onClick={() => {
              try {
                pdfMake.createPdf(initeryDefinition).open()
              } catch (error) {
                console.error(error)
              }
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>{t("reservation.bookingInfo")}</span>
          </Button>
        )}
        {invoice && (
          <Button
            size="sm"
            onClick={async () => {
              const invoiceData = Invoice({
                bookingInfo,
                information,
                systemInfo: system,
                era: selectedEra,
              })
              pdfMake.createPdf(invoiceData).open()
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>Invoice</span>
          </Button>
        )}
        {keyDeposit && (
          <Link to={`/reservation/key-deposit/${bookingInfo?.id}`}>
            <Button size="sm">
              <i className="fas fa-print mr-2" />
              <span>{t("reservation.keyDeposit")}</span>
            </Button>
          </Link>
        )}{" "}
        {reciept && (
          <Button
            size="sm"
            onClick={() => {
              setOpenCashier(true)
              setReciptMode("vat")
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>{t("reservation.receipt")} (Vat Include)</span>
          </Button>
        )}
        {vatExcludeRecipt && (
          <Button
            size="sm"
            onClick={() => {
              setOpenCashier(true)
              setReciptMode("vat-ext")
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>{t("reservation.receipt")} (Vat Exclude)</span>
          </Button>
        )}
        {noVatRecipt && (
          <Button
            size="sm"
            onClick={() => {
              setOpenCashier(true)
              setReciptMode("no-vat")
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>{t("reservation.receipt")}(No VAT)</span>
          </Button>
        )}
        {registrationCard && (
          <Button
            size="sm"
            onClick={() => {
              try {
                pdfMake.createPdf(registrationCardDefinition).open()
              } catch (error) {
                console.error(error)
              }
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>{t("reservation.registrationCard")}</span>
          </Button>
        )}
        {serviceCoupon && (
          <Button
            size="sm"
            onClick={async () => {
              const serviceOrderListInCoupon = await serviceCouponGetting(bookingInfo)
              const coupon = ServiceCoupon(serviceOrderListInCoupon, information, system)
              pdfMake.createPdf(coupon).open()
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>คูปองบริการ</span>
          </Button>
        )}{" "}
        {folio && (
          <Button
            size="sm"
            onClick={async () => {
              const folioDef = Folio({
                bookingInfo,
                information,
                systemInfo: system,
                era: selectedEra,
              })
              pdfMake.createPdf(folioDef).open()
            }}
          >
            <i className="fas fa-print mr-2" />
            <span>Folio</span>
          </Button>
        )}
        {custom && (
          <Link
            to={
              bookingInfo?.status !== BookingStatus.success
                ? `/reservation/custom-print/${bookingInfo?.id}`
                : `/history/custom-print/${bookingInfo?.id}`
            }
          >
            <Button size="sm">
              <i className="fas fa-print mr-2" />
              <span>ออกใบเสร็จให้บุคคลอื่น /แยกรายการ</span>
            </Button>
          </Link>
        )}
      </div>

      {openCashier && (
        <Alert colorScheme="gray" rounded="md" className="m-2">
          <div className="w-full">
            <FormLabel fontSize="sm">ชื่อผู้รับเงิน</FormLabel>
            <div className="flex flex-wrap w-full gap-1">
              <div className="w-full md:w-4/12">
                <Input
                  size="sm"
                  value={cashierName}
                  onChange={(e) => setCashierName(e.target.value)}
                  placeholder="ชื่อผู้รับเงิน"
                  autoFocus
                  bgColor="white"
                />
              </div>
              {system?.setting?.allowAddCustomNumberInRecipt && (
                <div className="w-full md:w-3/12">
                  <Input
                    size="sm"
                    value={customBookNo}
                    onChange={(e) => setCustomBookNo(e.target.value)}
                    placeholder="เล่มที่"
                    autoFocus
                    bgColor="white"
                  />
                </div>
              )}{" "}
              {system?.setting?.allowAddCustomNumberInRecipt && (
                <div className="w-full md:w-3/12">
                  <Input
                    size="sm"
                    value={customBookingNo}
                    onChange={(e) => setCustomBookingNo(e.target.value)}
                    placeholder="เลขที่"
                    autoFocus
                    bgColor="white"
                  />
                </div>
              )}
              <div className="md:w-1/12">
                <Button
                  size="sm"
                  isFullWidth
                  colorScheme="blue"
                  onClick={() => {
                    try {
                      if (reciptMode === "vat") {
                        pdfMake.createPdf(receiptDefinition).open()
                      } else if (reciptMode === "vat-ext") {
                        pdfMake.createPdf(vatExcludeDefinition).open()
                      } else {
                        pdfMake.createPdf(noVatReciptDefinition).open()
                      }
                      setReciptMode("")
                      setOpenCashier(false)
                    } catch (error) {
                      console.error(error)
                    }
                  }}
                >
                  พิมพ์
                </Button>
              </div>
            </div>
          </div>
        </Alert>
      )}
    </div>
  )
}
