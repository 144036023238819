import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react"
import { Link, useHistory } from "react-router-dom"
import _ from "lodash"
import { BookingStatus } from "../../config/constant/BookingStatus"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Layout from "../../components/layout/GeneralLayout"
import Paginator from "../../components/common/Paginator/NewPagination"
// import SelectedRoomList from "../../components/List/SelectedRoomList"
import ModalBookingInfo from "../../components/Modal/ReservationManagement/ModalBookingInfo"
import UserRole from "../../config/UserRoles"
import * as actions from "../../redux/actions"

export default function ReservationManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const bookings = useSelector((state) => state.bookings)
  const system = useSelector((state) => state.system)
  const me = useSelector((state) => state.me)
  const [selectedBooking, setSelectedBooking] = useState()
  const [isModalBookingInfoShow, setIsModalBookingInfoShow] = useState(false)
  const dispatch = useDispatch()
  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const history = useHistory()

  console.log("booking", bookings)
  useEffect(() => {
    console.log("CustomerLayout : ACTIVE")
    dispatch(actions.meGet(storageRemember.id))
    return () => {}
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      if (
        me?.role === UserRole.ADMIN.toString() ||
        me?.role === UserRole.EMPLOYEE.toString() ||
        me?.role === UserRole.SUPER_USER.toString()
        //  && system.enable_dashboard
      ) {
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  useEffect(() => {
    dispatch(actions.getAllBooking({ page })).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [page])

  return isLoading ? (
    <Layout categoryTitle="จัดการการเช็คอิน" title="จัดการการเช็คอิน">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full overflow-x-auto">
        <ModalBookingInfo
          bookingInfo={selectedBooking}
          isOpen={isModalBookingInfoShow}
          handleOnClose={() => {
            setSelectedBooking()
            setIsModalBookingInfoShow(false)
          }}
        />
        <Table variant="simple" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>Booking No.</Th>
              <Th>ชื่อลูกค้า</Th>
              <Th>สถานะ/ดูประวัติการเช็คอิน</Th>
            </Tr>
          </Thead>
          <Tbody className>
            {_.map(bookings?.arr, (eachBooking, index) => (
              <Tr key={index}>
                <Td>{(page - 1) * 10 + index + 1}</Td>
                <Td>
                  <div className="ml-3">{eachBooking?.booking_no} </div>
                  <div className="flex justify-start mt-1">
                    <Link to={`/reservation/view/${eachBooking?.id}`}>
                      <div className="text-xs text-blue-700 hover:text-blue-800 cursor-pointer font-bold bg-blue-200 hover:bg-blue-100 rounded-md px-2 ">
                        รายละเอียด
                      </div>
                    </Link>
                  </div>
                </Td>
                <Td>
                  <div className="mb-2 text-sm">
                    {eachBooking?.customer?.firstname} {eachBooking?.customer?.lastname}
                  </div>
                  {/* <SelectedRoomList bookingList={eachBooking} /> */}
                </Td>
                <Td>
                  <Link to={`/checkIn/create/${eachBooking.id}`}>
                    <Button
                      size="sm"
                      width="20"
                      disabled={
                        eachBooking?.status === BookingStatus.pending ||
                        eachBooking?.status === BookingStatus.book ||
                        eachBooking?.status === BookingStatus.confirm
                      }
                      colorScheme="blue"
                    >
                      <i
                        className={`fas fa-check mr-2 ${
                          eachBooking?.status === BookingStatus.book ||
                          eachBooking?.status === BookingStatus.pending ||
                          eachBooking?.status === BookingStatus.confirm
                            ? `hidden`
                            : ``
                        }`}
                      />{" "}
                      เช็คอิน
                    </Button>
                  </Link>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      <Paginator lastPage={bookings?.lastPage} setPage={setPage} />
    </Layout>
  ) : (
    <SpinnerLoading />
  )
}
