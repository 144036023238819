import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { FormControl, FormLabel, Select, Input, Checkbox } from "@chakra-ui/react"
import GridFrameDistance from "../../../../config/constant/GridFrameDistance"
// import GridgelleryDisplayType  from "../../../../config/constant/GridgelleryDisplayType"


function GridGallery({ register, defaultValue, watch, setValue, activeTab }) {
  const { i18n } = useTranslation()
  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-1/2 px-2 py-1">
        <FormLabel fontSize="sm">จำนวนรูปภาพต่อ 1 แถว</FormLabel>
        <Input
          ref={register}
          placeholder="จำนวนรูปภาพต่อ 1 แถว"
          name="type_attribute.number_per_row"
          type="number"
          size="sm"
          required
          defaultValue={defaultValue?.type_attribute?.number_per_row || 10}
        />
      </div>
      <div className="w-full lg:w-1/2 px-2 py-1">
        <FormLabel fontSize="sm">ลิมิตของจำนวนรูปภาพ</FormLabel>
        {!watch("type_attribute.unlimit") && (
          <Input
            ref={register}
            placeholder="ลิมิตของจำนวนรูปภาพ"
            name="type_attribute.limit"
            type="number"
            size="sm"
            required
            defaultValue={defaultValue?.type_attribute?.limit || 10}
          />
        )}
        <FormControl display="flex">
          <Checkbox
            ref={register}
            name="type_attribute.unlimit"
            defaultChecked={defaultValue?.type_attribute?.limit === null}
            size="sm"
            onChange={(event) => {
              if (event.target.checked) {
                setValue("type_attribute.limit", null)
                setValue("type_attribute.unlimit", true)
              } else {
                setValue("type_attribute.limit", 10)
                setValue("type_attribute.unlimit", false)
              }
            }}
          >
            ไม่จำกัด
          </Checkbox>
        </FormControl>
      </div>
      <div className="w-full lg:w-1/2 px-2 py-1">
        <FormLabel fontSize="sm">ระยะห่างจากขอบของ ContentGRID</FormLabel>
        <Select
          ref={register}
          placeholder="ระยะห่างจากขอบ"
          defaultValue={defaultValue?.desktop?.grid_gallery?.grid_distance}
          name="desktop.grid_gallery.grid_distance"
          size="sm"
        >
          {_.map(GridFrameDistance, (displayType, index) => (
            <option key={index} value={displayType.status_code}>
              {i18n?.language === "th" ? displayType.description : displayType.descriptionEn}
            </option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default forwardRef(GridGallery)
