import React from "react"

import GridGallery from "./GridGallery"
import HorizontalList from "./HorizontalList"
import Slideshow from "./Slideshow"
import TextContent from "./TextContent"
import VerticalList from "./VerticalList"
import Video from "./Video"
import Button from "./Button"
import CardContentGallery from "./CardContentGallery"

import { FrontpageComponentDisplayType } from "../../../../config/constant"

const FCPDisplayTypeForm = ({
  componentType,
  register,
  defaultValue,
  watch,
  setValue,
  control,
  file,
  gallery,
  activeTab,
  length,
  width,
}) => {
  const props = {
    length,
    width,
    register,
    defaultValue,
    watch,
    setValue,
    control,
    file,
    gallery,
    activeTab,
  }
  // console.log("activeTab in index", activeTab)
  switch (componentType) {
    case FrontpageComponentDisplayType.BUTTON.status_code:
      return <Button {...props} />
    case FrontpageComponentDisplayType.GRID_GALLERY.status_code:
      return <GridGallery {...props} />
    case FrontpageComponentDisplayType.LIST_GALLERY_HORIZONTAL.status_code:
      return <HorizontalList {...props} />
    case FrontpageComponentDisplayType.LIST_GALLERY_VERTICAL.status_code:
      return <VerticalList {...props} />
    case FrontpageComponentDisplayType.VIDEO.status_code:
      return <Video {...props} />
    case FrontpageComponentDisplayType.SLIDESHOW_GALLERY.status_code:
      return <Slideshow {...props} />
    case FrontpageComponentDisplayType.RICH_TEXT_CONTENT.status_code:
      return <TextContent {...props} />
    case FrontpageComponentDisplayType.CARD_CONTENT_GALLERY.status_code:
      return <CardContentGallery {...props} />
    default:
      return <div></div>
  }
}

export default FCPDisplayTypeForm
