/* eslint-disable react/display-name */
import React, { useState } from "react"
import { Table, Button, Modal } from "antd"
import dayjs from "dayjs"

import { currencyFormatter } from "../../util"
import { PaymentMethod } from "../../config/constant"

function PaymentTransactionTable({
  page,
  setPage,
  size,
  setSize,
  transaction,
  tableSize = "middle",
}) {
  const [modalOn, setModalOn] = useState(false)
  const [image, setImage] = useState(null)
  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize
    setPage(newPage)
    setSize(pageSize)
    console.log("params", pagination, filters, sorter, extra)
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "วันที่",
      dataIndex: "createdAt",
      render: (text) => dayjs(text).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "item_price",
      render: (text) => (text ? `${currencyFormatter.format(text)} บาท` : "-"),
    },
    {
      title: "วิธีการชำระเงิน",
      dataIndex: "payment_method",
      render: (text) => PaymentMethod[text]?.description,
    },
    {
      title: "พนักงาน",
      dataIndex: "user",
      render: (text) => `${text?.employee?.firstname || ""}`,
    },
    {
      title: "ดำเนินการ",
      dataIndex: "action",
      render: (text, record) => (
        <div>
          {record?.image && (
            <Button
              icon={<i className="fas fa-image"></i>}
              onClick={() => {
                setModalOn(true)
                setImage(record?.image?.url)
              }}
            >
              สลิป
            </Button>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <Modal
        open={modalOn}
        onOk={() => {
          setImage(null)
          setModalOn(false)
        }}
        onCancel={() => {
          setImage()
          setModalOn(false)
        }}
        title="สลิป"
      >
        <img src={image} alt="slip" style={{ width: "100%" }} />
      </Modal>
      <Table
        columns={columns}
        dataSource={transaction?.arr}
        onChange={onChange}
        pagination={{
          current: page,
          pageSize: size,
          total: transaction?.total,
        }}
        scroll={{
          x: true,
        }}
        size={tableSize}
      />
    </div>
  )
}

export default PaymentTransactionTable
