import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { WhitePanel } from "../../common/Cards"
import { RoomFacilityDisplayPanel } from "../../Panels"
import RoomSelectBySubType from "./RoomSelectBySubType"

const SpecifyRoomReservation = ({ selectedRooms, toggleSelectedRoom, rooms, roomTypes }) => {
  const { t, i18n } = useTranslation()

  const generateEachRoomTypeList = (roomTypeId, index) => {
    const roomByRoomType = _.groupBy(rooms?.arr, "type.id")
    const selectedRoomType = _.find(roomTypes?.arr, (roomType) => roomType?.id === roomTypeId)
    return (
      <div key={index}>
        <WhitePanel>
          <div className="flex flex-wrap">
            <div className="lg:w-1/4 p-1 flex justify-center">
              <img src={selectedRoomType?.images?.[0]?.image?.url} className="h-32 rounded-md " />
            </div>
            <div className="w-full lg:w-3/4 px-2">
              <h3 className="my-2 text-lg font-bold font-sans">
                {selectedRoomType?.translation?.[i18n.language]?.name}
              </h3>
              <p>{selectedRoomType?.translation?.[i18n.language]?.description}</p>
              <RoomFacilityDisplayPanel roomFacilities={selectedRoomType?.facility} />
            </div>
          </div>
          <hr className="my-2" />
          <div className="my-2 ">
            {roomByRoomType[roomTypeId] ? (
              <RoomSelectBySubType
                roomByRoomType={roomByRoomType[roomTypeId]}
                selectedRooms={selectedRooms}
                toggleSelectedRoom={toggleSelectedRoom}
              />
            ) : (
              <div className=" p-4 flex justify-center">
                <p>{t("roomtype.emptyCategory")}</p>
              </div>
            )}
          </div>{" "}
        </WhitePanel>
      </div>
    )
  }
  return (
    <div>{_.map(roomTypes?.arr, (each, index) => generateEachRoomTypeList(each?._id, index))}</div>
  )
}

export default SpecifyRoomReservation
