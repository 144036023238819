import React from "react"
import { Select, Checkbox, FormControl } from "@chakra-ui/react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import { TextField } from "../../common/Input"
import { THAI_BANK, PROMPTPAY_TYPE } from "../../../config/constant"
import ImageUpload from "../../ImageUpload/ImageUpload"

function PaymentInfoForm({ defaultValue, register, watch, setValue, images, setImages }) {
  const { i18n } = useTranslation()
  return (
    <div className="flex flex-wrap">
      <h6 className="text-gray-900 w-full text-base my-3 font-bold  font-sans ">
        ตั้งค่าข้อมูลการชำระเงิน
        <p className="text-xs font-normal">
          ข้อมูลการรับชำระเงินผ่านบัญชีธนาคาร ข้อมูลจะไปเสดงในเอกสาร Invoice
          และเปิดเป็นวิธีการรับจองออนไลน์มนอนาคต{" "}
        </p>
      </h6>
      <div className="w-full my-1 lg:w-1/2 px-2">
        <div className="font-semibold my-2">ข้อมูลการชำระเงินบัญชีหลัก</div>
        <TextField
          label="ชื่อบัญชีธนาคาร"
          defaultValue={defaultValue?.transfer_info?.accountName}
          name="transfer_info.accountName"
          placeholder="ชื่อบัญชีธนาคาร"
          register={register}
        />

        <label className="block text-gray-700 text-sm font-semibold mb-2">ชื่อธนาคาร</label>
        <div className="my-2">
          <Select
            label="ชื่อธนาคาร"
            defaultValue={defaultValue?.transfer_info?.bank}
            name="transfer_info.bank"
            placeholder="ชื่อธนาคาร"
            ref={register}
            size="sm"
          >
            {_.map(THAI_BANK, (each, index) => (
              <option key={index} value={each.status_code}>
                {i18n.language === "th" ? each.description : each.descriptionEn}
              </option>
            ))}
          </Select>
        </div>
        <TextField
          label="เลขที่บัญชี"
          defaultValue={defaultValue?.transfer_info?.accountNo}
          name="transfer_info.accountNo"
          placeholder="เลขที่บัญชี"
          register={register}
        />
      </div>
      <div className="w-full my-1 lg:w-1/2 px-2">
        <div className="font-semibold my-2">ข้อมูลพร้อมเพย์</div>

        <TextField
          label="ชื่อบัญชีพร้อมเพย์"
          defaultValue={defaultValue?.transfer_info?.promptpayName}
          name="transfer_info.promptpayName"
          placeholder="ชื่อบัญชีพร้อมเพย์"
          register={register}
        />
        <TextField
          label="หมายเลขพร้อมเพย์"
          defaultValue={defaultValue?.transfer_info?.promptpayNo}
          name="transfer_info.promptpayNo"
          placeholder="หมายเลขพร้อมเพย์"
          register={register}
        />
        <label className="block text-gray-700 text-sm font-semibold mb-2">ประเภทของพร้อมเพย์</label>
        <div className="my-2">
          <Select
            label="ประเภทของพร้อมเพย์"
            defaultValue={defaultValue?.transfer_info?.promptpayType}
            name="transfer_info.promptpayType"
            placeholder="ประเภทของพร้อมเพย์"
            ref={register}
            size="sm"
          >
            {_.map(PROMPTPAY_TYPE, (each, index) => (
              <option key={index} value={each.status_code}>
                {i18n.language === "th" ? each.description : each.descriptionEn}
              </option>
            ))}
          </Select>
        </div>
        <label className="block text-gray-700 text-sm font-semibold mb-2">QR Code พร้อมเพย์</label>
        <div>
          {defaultValue?.payment_qr_code?.url && (
            <div className="flex justify-center">
              <img src={defaultValue?.payment_qr_code?.url} alt="QR Code" className="w-1/2 h-1/2" />
            </div>
          )}
        </div>
        <div>
          <ImageUpload
            label="อัพโหลด QR Code ของพร้อมเพย์"
            images={images}
            setImages={setImages}
            maxNumber={1}
          />
        </div>
      </div>
      <div className="w-full my-1 px-2 lg:w-1/2">
        <div className="font-semibold my-2">ข้อมูลการรับชำระเงินผ่านบัญชีธนาคาร แบบไม่เก็บ VAT</div>
        <FormControl className="my-2">
          <Checkbox
            ref={register}
            name="transfer_info_no_vat.disabled"
            colorScheme="purple"
            defaultChecked={defaultValue?.transfer_info_no_vat?.disabled}
            defaultValue={defaultValue?.transfer_info_no_vat?.disabled}
            onChange={(e) => setValue("transfer_info_no_vat.disabled", e.target.checked)}
            size="sm"
          >
            ใช้การชำระเงินบัญชีเดียวกันกับบัญชีหลัก (เก็บ VAT){" "}
          </Checkbox>
        </FormControl>
        {!watch("transfer_info_no_vat.disabled") && (
          <div>
            <TextField
              label="ชื่อบัญชีธนาคาร"
              defaultValue={defaultValue?.transfer_info_no_vat?.accountName}
              name="transfer_info_no_vat.accountName"
              placeholder="ชื่อบัญชีธนาคาร"
              register={register}
            />

            <label className="block text-gray-700 text-sm font-semibold mb-2">ชื่อธนาคาร</label>
            <div className="my-2">
              <Select
                label="ชื่อธนาคาร"
                defaultValue={defaultValue?.transfer_info_no_vat?.bank}
                name="transfer_info_no_vat.bank"
                placeholder="ชื่อธนาคาร"
                ref={register}
                size="sm"
              >
                {_.map(THAI_BANK, (each, index) => (
                  <option key={index} value={each.status_code}>
                    {i18n.language === "th" ? each.description : each.descriptionEn}
                  </option>
                ))}
              </Select>
            </div>
            <TextField
              label="เลขที่บัญชี"
              defaultValue={defaultValue?.transfer_info_no_vat?.accountNo}
              name="transfer_info_no_vat.accountNo"
              placeholder="เลขที่บัญชี"
              register={register}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentInfoForm
