import React, { useState } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useToast } from "@chakra-ui/react"
import { Input as AntdInput } from "antd"

export default function RoomSelector({
  setSelectedRooms,
  selectedRooms,
  roomTypes,
  rooms,
  limit = null,
}) {
  const [reload, setReload] = useState(false)
  const { t, i18n } = useTranslation()
  const toast = useToast()
  const [searchWord, setSearchWord] = useState("")

  const filterRoom = _.filter(
    rooms,
    (each) =>
      _.includes(_.lowerCase(each?.name || ""), _.lowerCase(searchWord)) ||
      _.includes(_.lowerCase(each?.type?.translation?.th?.name || ""), _.lowerCase(searchWord)) ||
      _.includes(_.lowerCase(each?.type?.translation?.en?.name || ""), _.lowerCase(searchWord)) ||
      _.includes(_.lowerCase(each?.type?.name || ""), _.lowerCase(searchWord)),
  )

  const toggleSelectedRoomType = (room) => {
    console.log("Room", room)
    // if (!unAvailabelRoomList?.includes(roomId)) {
    if (_.includes(selectedRooms, room)) {
      const selectedRoomIndex = _.findIndex(selectedRooms, { id: room?.id })

      selectedRooms.splice(selectedRoomIndex, 1)
      setSelectedRooms(selectedRooms)
      setReload(!reload)
    } else {
      if (limit && _.size(selectedRooms) < limit) {
        selectedRooms.push(room)
        setSelectedRooms(selectedRooms)
      } else if (limit) {
        toast({ title: "เกินจำนวนที่กำหนด", status: "error", duration: 1000 })
      } else {
        selectedRooms.push(room)
        setSelectedRooms(selectedRooms)
      }
      setReload(!reload)
    }
    console.log("Selected Rooms", selectedRooms)
    // }
  }

  const generateEachRoomTypeList = (roomTypeId, index) => {
    const roomByRoomType = _.groupBy(filterRoom, "type.id")
    const selectedRoomType = _.find(roomTypes?.arr, (roomType) => roomType?.id === roomTypeId)

    if (_.size(roomByRoomType[roomTypeId]) === 0) {
      return <div></div>
    }
    return (
      <div key={index} className="my-2">
        <h3 className="my-2 text-lg font-bold font-sans">
          {selectedRoomType?.translation?.[i18n.language]?.name || selectedRoomType?.name}
        </h3>
        {selectedRoomType?.no_room_booking_amount > 0 && (
          <div className="my-2">
            <h4>จองไม่ระบุห้อง {selectedRoomType?.no_room_booking_amount} ห้อง</h4>
          </div>
        )}
        <hr />

        <div className="my-2 flex flex-wrap ">
          {roomByRoomType[roomTypeId] ? (
            _.map(roomByRoomType[roomTypeId], (room, roomTypeIndex) => (
              <div
                className={`p-2 w-1/2 lg:w-1/4 cursor-pointer bg-white hover:bg-gray-300`}
                onClick={() => toggleSelectedRoomType(room)}
                key={roomTypeIndex}
              >
                <div
                  className={`${
                    _.includes(selectedRooms, room) ? `bg-violet-800 text-white` : `bg-gray-100`
                  } bg-gray-200 rounded-md`}
                >
                  <div className="flex justify-between p-4">
                    <h3 className={`font-bold text-lg mx-4 ml-2  font-sans`}>
                      <i className="fas fa-door-open mr-2 " />
                      {room?.name}
                    </h3>
                  </div>

                  <p className="text-sm ml-6">{room?.sub_type?.name}</p>
                  <p className="text-xs ml-6">
                    {room?.ota_reserved && t("roomtype.otaReservation")}
                  </p>

                  <br />
                </div>
              </div>
            ))
          ) : (
            <div className=" p-4 flex justify-center">
              <p>{t("roomtype.emptyCategory")}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-wrap py-4">
        <AntdInput
          placeholder="input search text"
          addonBefore={<i className="fas fa-search" />}
          allowClear
          onChange={(e) => setSearchWord(e.target.value)}
          style={{
            width: 400,
          }}
        />
      </div>
      {_.map(roomTypes?.arr, (eachRoomType, index) =>
        generateEachRoomTypeList(eachRoomType?.id, index),
      )}
    </div>
  )
}
