import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@chakra-ui/react"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel, FBMenuForm, Alert, SpinnerLoading } from "../../components"
import { appConfig } from "../../config"

import * as actions from "../../redux/actions"

export default function EditMenu() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const menuCategory = useSelector((state) => state.menuCategory)
  const menu = useSelector((state) => state.menu)
  const { control, handleSubmit, watch, setValue } = useForm(menu)

  const handleEditMenu = (data) => {
    console.log("Data", data)
    dispatch(actions.editOneMenu(params.id, data))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })
  }

  useEffect(() => {
    dispatch(actions.getOneMenu(params.id))
      .then(() => {
        dispatch(actions.getAllMenuCategory({ page: 1, size: appConfig.maxFetchSize }))
          .then(() => {
            setIsLoading(true)
          })
          .catch((err) => {
            setIsError(true)
            setErrMessage(err?.message)
            console.error(err)
          })
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [])

  useEffect(() => {
    let timeout
    if (menu?.options) {
      console.log("Set Options", menu?.options)
      timeout = setTimeout(() => {
        setValue("options", menu?.options)
        setValue("toppings", menu?.toppings)
      }, 1000)
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [menu])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="แก้ไขเมนูอาหาร">
      <Alert errMessage={errMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-between flex-wrap px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>

        <div className="w-full md:w-1/4  self-center mt-2"></div>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(handleEditMenu)}>
          <WhitePanel>
            <FBMenuForm
              control={control}
              menuCategory={menuCategory}
              defaultValue={menu}
              watch={watch}
            />
          </WhitePanel>
          <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
