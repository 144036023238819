export const MeetingRoomOrderStatus = {
  MEETING_CONFIRM: {
    status_code: "MEETING_CONFIRM",
    description: "ยืนยันการจอง",
    descriptionEn: "Meeting Confirm",
    color: "pink",
  },
  MEETING_RUNNING: {
    status_code: "MEETING_RUNNING",
    description: "ช่วงเวลาประชุม",
    descriptionEn: "Meeting Running",
    color: "cyan",
  },
  MEETING_PASS: {
    status_code: "MEETING_PASS",
    description: "การประชุมผ่านไปแล้ว",
    descriptionEn: "Meeting Pass",
    color: "yellow",
  },
  MEETING_COMPLETED: {
    status_code: "MEETING_COMPLETED",
    description: "การจองเสร็จสิ้นแล้ว",
    descriptionEn: "Order Completed",
    color: "green",
  },
  MEETING_DELETED: {
    status_code: "MEETING_DELETED",
    description: "การจองห้องถูกยกเลิก",
    descriptionEn: "Order Cancle",
    color: "red",
  },
  MEETING_UNDEFINED: {
    status_code: "MEETING_UNDEFINED",
    description: "-",
    descriptionEn: "-",
    color: "blue",
  },
}

export default MeetingRoomOrderStatus
