import {
  FB_ORDER_ALL,
  FB_ORDER_CREATE,
  FB_ORDER_DELETE,
  FB_ORDER_GET,
  FB_ORDER_UPDATE,
  FB_ORDER_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const fbOrderActions = new GeneralReduxActionClass({
  allConst: FB_ORDER_ALL,
  createConst: FB_ORDER_CREATE,
  deleteConst: FB_ORDER_DELETE,
  errorConst: FB_ORDER_ERROR,
  getConst: FB_ORDER_GET,
  putConst: FB_ORDER_UPDATE,
  backendPrefix: "fb-order",
  description: "รายการ Food and Beverage",
})

export const getAllFBOrder = (query) => fbOrderActions.getAll(query)
export const getOneFBOrder = (id) => fbOrderActions.getOne(id)
export const createOneFBOrder = (payload) => fbOrderActions.createOne(payload)
export const editOneFBOrder = (id, payload) => fbOrderActions.editOne(id, payload)
export const deleteOneFBOrder = (id) => fbOrderActions.deleteOne(id)
