/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../redux/actions"

// components
import Sidebar from "../components/common/Sidebar/MainSidebar"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"

// views
import ReservationManagement from "../views/Reservation/ReservationManagement/ReservationManagement"
import ReservationCalendar from "../views/Reservation/Calendar/ReservationCalendar"
import ReservationInfo from "../views/Reservation/ReservationManagement/InfoReservation"
// Legacy Version
// import ReservationSchedule from "../views/Reservation/Schedule/TraditionalScheduleReservation"
import ReservationSchedule from "../views/Reservation/Schedule/ReservationSchedule"
import EditReservation from "../views/Reservation/ReservationManagement/EditReservation"
import ExportReservation from "../views/Reservation/ReservationManagement/ExportReservation"
import AddServiceToReservation from "../views/Reservation/ReservationManagement/AddServiceToReservation"
import EditCheckinInformation from "../views/Reservation/ReservationManagement/EditCheckInInformation"
import RoomReservationStatus from "../views/Reservation/Room/RoomReservationStatus"
import CreateReservation from "../views/Reservation/ReservationManagement/CreateReservation"
import AssignReservation from "../views/Reservation/ReservationManagement/AssignReservation"
import TaxInvoiceManagement from "../views/Reservation/TaxInvoice/TaxInvoiceManagement"
import RoomTypeReservationSummary from "../views/Reservation/Room/RoomTypeReservationSummary"
import HistoryDashboard from "../views/History/HistoryManagement"
import MonthyForecast from "../views/Reservation/Room/MonthyForecast"
import CustomReciptPrint from "../views/Reservation/ReservationManagement/CustomReciptPrint"
import ServiceOrderOnMeetingRoomManagement from "../views/MeetingRoomAndServiceOrder/MeetingRoomOrders/ServiceOrderOnMeetingRoomManagement"
import CreateServiceOrderOnMeetingRoom from "../views/MeetingRoomAndServiceOrder/MeetingRoomOrders/CreateServiceOrderOnMeetingRoom"
import MeetingRoomListBookingStatus from "../views/MeetingRoomAndServiceOrder/MeetingRoomOrders/MeetingRoomListBookingStatus"
import ServiceOrderPayment from "../views/MeetingRoomAndServiceOrder/ServiceOrder/ServiceOrderPayment"
import AddServiceToServiceOrder from "../views/MeetingRoomAndServiceOrder/ServiceOrder/AddServiceToServiceOrder"
import EditServiceOrder from "../views/MeetingRoomAndServiceOrder/ServiceOrder/EditServiceOrder"
import PriceCalculation from "../views/Reservation/ReservationManagement/PriceCalculation"
import InfoServiceOrder from "../views/MeetingRoomAndServiceOrder/ServiceOrder/InfoServiceOrder"
import ServiceOrderManagement from "../views/MeetingRoomAndServiceOrder/ServiceOrder/ServiceOrderManagement"
import CreateServiceOrder from "../views/MeetingRoomAndServiceOrder/ServiceOrder/CreateServiceOrder"
import Checkin from "../views/Reservation/ReservationManagement/Checkin"
import BillListReport from "../views/History/BillListReport"
import BookingPayment from "../views/Reservation/ReservationManagement/BookingPayment"
import InfoBookingPayment from "../views/Reservation/ReservationManagement/InfoBookingPayment"
import KeyDeposit from "../views/Reservation/ReservationManagement/KeyDeposit"

import UserRole from "../config/UserRoles"
import { RESERVATION_MENU } from "../config/MenuList"
import { ModuleList } from "../config/constant/ModuleList"

export default function RoomsLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("ReservationLayout : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.getCurrentBill())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.access?.[ModuleList.RESERVATION.status_code] ||
        me?.role === UserRole.SUPER_USER.toString() // Reserved for some system
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar
        menuList={RESERVATION_MENU}
        isShow={showSidebar}
        setIsShowSidebar={setIsShowSidebar}
      />
      <div className={` relative bg-gray-200 ${showSidebar ? "lg:ml-64" : ""} `}>
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/reservation/room" exact component={() => <RoomReservationStatus />} />
            <Route path="/reservation/create" exact component={() => <CreateReservation />} />

            <Route path="/reservation/view/:id" exact component={() => <ReservationInfo />} />
            <Route path="/reservation/edit/:id" exact component={() => <EditReservation />} />
            <Route path="/reservation/assign/:id" exact component={() => <AssignReservation />} />
            <Route path="/reservation/calendar" exact component={() => <ReservationCalendar />} />
            <Route path="/reservation/history" exact component={() => <HistoryDashboard />} />
            <Route path="/reservation/schedule" exact component={() => <ReservationSchedule />} />
            <Route path="/reservation/export" exact component={() => <ExportReservation />} />
            <Route path="/reservation/list" exact component={() => <ReservationManagement />} />
            <Route
              path="/reservation/roomtype"
              exact
              component={() => <RoomTypeReservationSummary />}
            />
            <Route
              path="/reservation/tax-invoice"
              exact
              component={() => <TaxInvoiceManagement />}
            />

            <Route
              path="/reservation/edit-checkin/:id"
              exact
              component={() => <EditCheckinInformation />}
            />
            <Route
              path="/reservation/add-service/:id"
              exact
              component={() => <AddServiceToReservation />}
            />

            <Route path="/reservation/monthy-forecast" exact component={() => <MonthyForecast />} />
            <Route
              path="/reservation/custom-print/:id"
              exact
              component={() => <CustomReciptPrint />}
            />
            <Route
              path="/reservation/meeting-room-orders"
              exact
              component={() => <ServiceOrderOnMeetingRoomManagement />}
            />
            <Route
              path="/reservation/meeting-room-orders/create"
              exact
              component={() => <CreateServiceOrderOnMeetingRoom />}
            />
            <Route
              path="/reservation/meeting-room-orders/room"
              exact
              component={() => <MeetingRoomListBookingStatus />}
            />
            <Route
              path="/reservation/service-orders/payment/:id"
              exact
              component={() => <ServiceOrderPayment />}
            />
            <Route
              path="/reservation/service-orders/"
              exact
              component={() => <ServiceOrderManagement />}
            />
            <Route
              path="/reservation/service-orders/create"
              exact
              component={() => <CreateServiceOrder />}
            />
            <Route
              path="/reservation/service-orders/view/:id"
              exact
              component={() => <InfoServiceOrder />}
            />
            <Route
              path="/reservation/service-orders/add-service/:id"
              exact
              component={() => <AddServiceToServiceOrder />}
            />
            <Route
              path="/reservation/service-orders/edit/:id"
              exact
              component={() => <EditServiceOrder />}
            />
            <Route
              path="/reservation/price-calculation/:id"
              exact
              component={() => <PriceCalculation />}
            />
            <Route path="/reservation/payment/:id" exact component={() => <BookingPayment />} />
            <Route path="/reservation/bill" exact component={() => <BillListReport />} />
            <Route path="/reservation/checkin/:id" exact component={() => <Checkin />} />
            <Route
              path="/reservation/detail-payment/:id"
              exact
              component={() => <InfoBookingPayment />}
            />
            <Route path="/reservation/key-deposit/:id" exact component={() => <KeyDeposit />} />
            {system?.setting?.showListViewOfBookingInstead ? (
              <Redirect from="/reservation" to="/reservation/list" />
            ) : (
              <Redirect from="/reservation" to="/reservation/room" />
            )}
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
