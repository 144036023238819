export const CountryList = [
  {
    thaiName: "กรีซ",
    label: "Greece",
    value: "GR",
    alpha3: "GRC",
    numeric: "300",
    iso3166_2: "ISO 3166-2:GR",
  },
  {
    thaiName: "กรีนแลนด์",
    label: "Greenland",
    value: "GL",
    alpha3: "GRL",
    numeric: "304",
    iso3166_2: "ISO 3166-2:GL",
  },
  {
    thaiName: "กวม",
    label: "Guam",
    value: "GU",
    alpha3: "GUM",
    numeric: "316",
    iso3166_2: "ISO 3166-2:GU",
  },
  {
    thaiName: "กัมพูชา",
    label: "Cambodia",
    value: "KH",
    alpha3: "KHM",
    numeric: "116",
    iso3166_2: "ISO 3166-2:KH",
  },
  {
    thaiName: "กัวเดอลุป",
    label: "Guadeloupe",
    value: "GP",
    alpha3: "GLP",
    numeric: "312",
    iso3166_2: "ISO 3166-2:GP",
  },
  {
    thaiName: "กัวเตมาลา",
    label: "Guatemala",
    value: "GT",
    alpha3: "GTM",
    numeric: "320",
    iso3166_2: "ISO 3166-2:GT",
  },
  {
    thaiName: "กาตาร์",
    label: "Qatar",
    value: "QA",
    alpha3: "QAT",
    numeric: "634",
    iso3166_2: "ISO 3166-2:QA",
  },
  {
    thaiName: "กานา",
    label: "Ghana",
    value: "GH",
    alpha3: "GHA",
    numeric: "288",
    iso3166_2: "ISO 3166-2:GH",
  },
  {
    thaiName: "กาบอง",
    label: "Gabon",
    value: "GA",
    alpha3: "GAB",
    numeric: "266",
    iso3166_2: "ISO 3166-2:GA",
  },
  {
    thaiName: "กาบูเวร์ดี",
    label: "Cabo Verde",
    value: "CV",
    alpha3: "CPV",
    numeric: "132",
    iso3166_2: "ISO 3166-2:CV",
  },
  {
    thaiName: "กายอานา",
    label: "Guyana",
    value: "GY",
    alpha3: "GUY",
    numeric: "328",
    iso3166_2: "ISO 3166-2:GY",
  },
  {
    thaiName: "กินี",
    label: "Guinea",
    value: "GN",
    alpha3: "GIN",
    numeric: "324",
    iso3166_2: "ISO 3166-2:GN",
  },
  {
    thaiName: "กินี-บิสเซา",
    label: "Guinea-Bissau",
    value: "GW",
    alpha3: "GNB",
    numeric: "624",
    iso3166_2: "ISO 3166-2:GW",
  },
  {
    thaiName: "กือราเซา",
    label: "Curaçao",
    value: "CW",
    alpha3: "CUW",
    numeric: "531",
    iso3166_2: "ISO 3166-2:CUW",
  },
  {
    thaiName: "เกรเนดา",
    label: "Grenada",
    value: "GD",
    alpha3: "GRD",
    numeric: "308",
    iso3166_2: "ISO 3166-2:GD",
  },
  {
    thaiName: "เกาหลีใต้",
    label: "South Korea",
    value: "KR",
    alpha3: "KOR",
    numeric: "410",
    iso3166_2: "ISO 3166-2:KR",
  },
  {
    thaiName: "เกาหลีเหนือ",
    label: "North Korea",
    value: "KP",
    alpha3: "PRK",
    numeric: "408",
    iso3166_2: "ISO 3166-2:KP",
  },
  {
    thaiName: "เกาะคริสต์มาส",
    label: "Christmas Island",
    value: "CX",
    alpha3: "CXR",
    numeric: "162",
    iso3166_2: "ISO 3166-2:CX",
  },
  {
    thaiName: "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช",
    label: "South Georgia and the South Sandwich Islands",
    value: "GS",
    alpha3: "SGS",
    numeric: "239",
    iso3166_2: "ISO 3166-2:GS",
  },
  {
    thaiName: "เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช",
    label: "South Sudan",
    value: "SS",
    alpha3: "SSD",
    numeric: "728",
    iso3166_2: "ISO 3166-2:SS",
  },
  {
    thaiName: "เกาะนอร์ฟอล์ก",
    label: "Norfolk Island",
    value: "NF",
    alpha3: "NFK",
    numeric: "574",
    iso3166_2: "ISO 3166-2:NF",
  },
  {
    thaiName: "เกาะบูเวต์",
    label: "Bouvet Island",
    value: "BV",
    alpha3: "BVT",
    numeric: "74",
    iso3166_2: "ISO 3166-2:BV",
  },
  {
    thaiName: "เกาะเล็กรอบนอกของสหรัฐอเมริกา",
    label: "United States Minor Outlying Islands",
    value: "UM",
    alpha3: "UMI",
    numeric: "581",
    iso3166_2: "ISO 3166-2:UM",
  },
  {
    thaiName: "เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์",
    label: "Heard Island and McDonald Islands",
    value: "HM",
    alpha3: "HMD",
    numeric: "334",
    iso3166_2: "ISO 3166-2:HM",
  },
  {
    thaiName: "เกิร์นซีย์",
    label: "Guernsey",
    value: "GG",
    alpha3: "GGY",
    numeric: "831",
    iso3166_2: "ISO 3166-2:GG",
  },
  {
    thaiName: "แกมเบีย",
    label: "Gambia",
    value: "GM",
    alpha3: "GMB",
    numeric: "270",
    iso3166_2: "ISO 3166-2:GM",
  },
  {
    thaiName: "โกตดิวัวร์",
    label: "Côte d'Ivoire",
    value: "CI",
    alpha3: "CIV",
    numeric: "384",
    iso3166_2: "ISO 3166-2:CI",
  },
  {
    thaiName: "คอโมโรส",
    label: "Comoros",
    value: "KM",
    alpha3: "COM",
    numeric: "174",
    iso3166_2: "ISO 3166-2:KM",
  },
  {
    thaiName: "คอสตาริกา",
    label: "Costa Rica",
    value: "CR",
    alpha3: "CRI",
    numeric: "188",
    iso3166_2: "ISO 3166-2:CR",
  },
  {
    thaiName: "คาซัคสถาน",
    label: "Kazakhstan",
    value: "KZ",
    alpha3: "KAZ",
    numeric: "398",
    iso3166_2: "ISO 3166-2:KZ",
  },
  {
    thaiName: "คิริบาส",
    label: "Kiribati",
    value: "KI",
    alpha3: "KIR",
    numeric: "296",
    iso3166_2: "ISO 3166-2:KI",
  },
  {
    thaiName: "คิวบา",
    label: "Cuba",
    value: "CU",
    alpha3: "CUB",
    numeric: "192",
    iso3166_2: "ISO 3166-2:CU",
  },
  {
    thaiName: "คีร์กีซสถาน",
    label: "Kyrgyzstan",
    value: "KG",
    alpha3: "KGZ",
    numeric: "417",
    iso3166_2: "ISO 3166-2:KG",
  },
  {
    thaiName: "คูเวต",
    label: "Kuwait",
    value: "KW",
    alpha3: "KWT",
    numeric: "414",
    iso3166_2: "ISO 3166-2:KW",
  },
  {
    thaiName: "เคนยา",
    label: "Kenya",
    value: "KE",
    alpha3: "KEN",
    numeric: "404",
    iso3166_2: "ISO 3166-2:KE",
  },
  {
    thaiName: "แคนาดา",
    label: "Canada",
    value: "CA",
    alpha3: "CAN",
    numeric: "124",
    iso3166_2: "ISO 3166-2:CA",
  },
  {
    thaiName: "แคเมอรูน",
    label: "Cameroon",
    value: "CM",
    alpha3: "CMR",
    numeric: "120",
    iso3166_2: "ISO 3166-2:CM",
  },
  {
    thaiName: "โครเอเชีย",
    label: "Croatia",
    value: "HR",
    alpha3: "HRV",
    numeric: "191",
    iso3166_2: "ISO 3166-2:HR",
  },
  {
    thaiName: "โคลอมเบีย",
    label: "Colombia",
    value: "CO",
    alpha3: "COL",
    numeric: "170",
    iso3166_2: "ISO 3166-2:CO",
  },
  {
    thaiName: "จอร์เจีย",
    label: "Georgia",
    value: "GE",
    alpha3: "GEO",
    numeric: "268",
    iso3166_2: "ISO 3166-2:GE",
  },
  {
    thaiName: "จอร์แดน",
    label: "Jordan",
    value: "JO",
    alpha3: "JOR",
    numeric: "400",
    iso3166_2: "ISO 3166-2:JO",
  },
  {
    thaiName: "จาเมกา",
    label: "Jamaica",
    value: "JM",
    alpha3: "JAM",
    numeric: "388",
    iso3166_2: "ISO 3166-2:JM",
  },
  {
    thaiName: "จิบูตี",
    label: "Djibouti",
    value: "DJ",
    alpha3: "DJI",
    numeric: "262",
    iso3166_2: "ISO 3166-2:DJ",
  },
  {
    thaiName: "จีน",
    label: "China",
    value: "CN",
    alpha3: "CHN",
    numeric: "156",
    iso3166_2: "ISO 3166-2:CN",
  },
  {
    thaiName: "เจอร์ซีย์",
    label: "Jersey",
    value: "JE",
    alpha3: "JEY",
    numeric: "832",
    iso3166_2: "ISO 3166-2:JE",
  },
  {
    thaiName: "ชาด",
    label: "Chad",
    value: "TD",
    alpha3: "TCD",
    numeric: "148",
    iso3166_2: "ISO 3166-2:TD",
  },
  {
    thaiName: "ชิลี",
    label: "Chile",
    value: "CL",
    alpha3: "CHL",
    numeric: "152",
    iso3166_2: "ISO 3166-2:CL",
  },
  {
    thaiName: "เช็กเกีย",
    label: "Czech Republic",
    value: "CZ",
    alpha3: "CZE",
    numeric: "203",
    iso3166_2: "ISO 3166-2:CZ",
  },
  {
    thaiName: "ซานมารีโน",
    label: "San Marino",
    value: "SM",
    alpha3: "SMR",
    numeric: "674",
    iso3166_2: "ISO 3166-2:SM",
  },
  {
    thaiName: "ซามัว",
    label: "Samoa",
    value: "WS",
    alpha3: "WSM",
    numeric: "882",
    iso3166_2: "ISO 3166-2:WS",
  },
  {
    thaiName: "ซาอุดีอาระเบีย",
    label: "Saudi Arabia",
    value: "SA",
    alpha3: "SAU",
    numeric: "682",
    iso3166_2: "ISO 3166-2:SA",
  },
  {
    thaiName: "ซาฮาราตะวันตก",
    label: "Western Sahara",
    value: "EH",
    alpha3: "ESH",
    numeric: "732",
    iso3166_2: "ISO 3166-2:EH",
  },
  {
    thaiName: "ซิมบับเว",
    label: "Zimbabwe",
    value: "ZW",
    alpha3: "ZWE",
    numeric: "716",
    iso3166_2: "ISO 3166-2:ZW",
  },
  {
    thaiName: "ซีเรีย",
    label: "Syrian Arab Republic",
    value: "SY",
    alpha3: "SYR",
    numeric: "760",
    iso3166_2: "ISO 3166-2:SY",
  },
  {
    thaiName: "ซูดาน",
    label: "Sudan",
    value: "SD",
    alpha3: "SDN",
    numeric: "736",
    iso3166_2: "ISO 3166-2:SD",
  },
  {
    thaiName: "ซูรินาม",
    label: "SurithaiName",
    value: "SR",
    alpha3: "SUR",
    numeric: "740",
    iso3166_2: "ISO 3166-2:SR",
  },
  {
    thaiName: "เซเชลส์",
    label: "Seychelles",
    value: "SC",
    alpha3: "SYC",
    numeric: "690",
    iso3166_2: "ISO 3166-2:SC",
  },
  {
    thaiName: "เซนต์คิตส์และเนวิส",
    label: "Saint Kitts and Nevis",
    value: "KN",
    alpha3: "KNA",
    numeric: "659",
    iso3166_2: "ISO 3166-2:KN",
  },
  {
    thaiName: "เซนต์ลูเชีย",
    label: "Saint Lucia",
    value: "LC",
    alpha3: "LCA",
    numeric: "662",
    iso3166_2: "ISO 3166-2:LC",
  },
  {
    thaiName: "เซนต์วินเซนต์และเกรนาดีนส์",
    label: "Saint Vincent and the Grenadines",
    value: "VC",
    alpha3: "VCT",
    numeric: "670",
    iso3166_2: "ISO 3166-2:VC",
  },
  {
    thaiName: "เซนต์เฮเลนา",
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "SH",
    alpha3: "SHN",
    numeric: "654",
    iso3166_2: "ISO 3166-2:SH",
  },
  {
    thaiName: "เซเนกัล",
    label: "Senegal",
    value: "SN",
    alpha3: "SEN",
    numeric: "686",
    iso3166_2: "ISO 3166-2:SN",
  },
  {
    thaiName: "เซอร์เบีย",
    label: "Serbia",
    value: "RS",
    alpha3: "SRB",
    numeric: "688",
    iso3166_2: "ISO 3166-2:RS",
  },
  {
    thaiName: "เซาตูเมและปรินซีปี",
    label: "Sao Tome and Principe",
    value: "ST",
    alpha3: "STP",
    numeric: "678",
    iso3166_2: "ISO 3166-2:ST",
  },
  {
    thaiName: "เซียร์ราลีโอน",
    label: "Sierra Leone",
    value: "SL",
    alpha3: "SLE",
    numeric: "694",
    iso3166_2: "ISO 3166-2:SL",
  },
  {
    thaiName: "แซงบาร์เตเลมี",
    label: "Saint Barthélemy",
    value: "BL",
    alpha3: "BLM",
    numeric: "652",
    iso3166_2: "ISO 3166-2:BL",
  },
  {
    thaiName: "แซงปีแยร์และมีเกอลง",
    label: "Saint Pierre and Miquelon",
    value: "PM",
    alpha3: "SPM",
    numeric: "666",
    iso3166_2: "ISO 3166-2:PM",
  },
  {
    thaiName: "แซงมาร์แตง",
    label: "Saint Martin",
    value: "MF",
    alpha3: "MAF",
    numeric: "663",
    iso3166_2: "ISO 3166-2:MF",
  },
  {
    thaiName: "แซมเบีย",
    label: "Zambia",
    value: "ZM",
    alpha3: "ZMB",
    numeric: "894",
    iso3166_2: "ISO 3166-2:ZM",
  },
  {
    thaiName: "โซมาเลีย",
    label: "Somalia",
    value: "SO",
    alpha3: "SOM",
    numeric: "706",
    iso3166_2: "ISO 3166-2:SO",
  },
  {
    thaiName: "ไซปรัส",
    label: "Cyprus",
    value: "CY",
    alpha3: "CYP",
    numeric: "196",
    iso3166_2: "ISO 3166-2:CY",
  },
  {
    thaiName: "ญี่ปุ่น",
    label: "Japan",
    value: "JP",
    alpha3: "JPN",
    numeric: "392",
    iso3166_2: "ISO 3166-2:JP",
  },
  {
    thaiName: "ดอมินีกา",
    label: "Dominica",
    value: "DM",
    alpha3: "DMA",
    numeric: "212",
    iso3166_2: "ISO 3166-2:DM",
  },
  {
    thaiName: "เดนมาร์ก",
    label: "Denmark",
    value: "DK",
    alpha3: "DNK",
    numeric: "208",
    iso3166_2: "ISO 3166-2:DK",
  },
  {
    thaiName: "ตรินิแดดและโตเบโก",
    label: "Trinidad and Tobago",
    value: "TT",
    alpha3: "TTO",
    numeric: "780",
    iso3166_2: "ISO 3166-2:TT",
  },
  {
    thaiName: "ตองงา",
    label: "Tonga",
    value: "TO",
    alpha3: "TON",
    numeric: "776",
    iso3166_2: "ISO 3166-2:TO",
  },
  {
    thaiName: "ติมอร์-เลสเต",
    label: "Timor-Leste",
    value: "TL",
    alpha3: "TLS",
    numeric: "626",
    iso3166_2: "ISO 3166-2:TL",
  },
  {
    thaiName: "ตุรกี",
    label: "Turkey",
    value: "TR",
    alpha3: "TUR",
    numeric: "792",
    iso3166_2: "ISO 3166-2:TR",
  },
  {
    thaiName: "ตูนิเซีย",
    label: "Tunisia",
    value: "TN",
    alpha3: "TUN",
    numeric: "788",
    iso3166_2: "ISO 3166-2:TN",
  },
  {
    thaiName: "ตูวาลู",
    label: "Tuvalu",
    value: "TV",
    alpha3: "TUV",
    numeric: "798",
    iso3166_2: "ISO 3166-2:TV",
  },
  {
    thaiName: "เติร์กเมนิสถาน",
    label: "Turkmenistan",
    value: "TM",
    alpha3: "TKM",
    numeric: "795",
    iso3166_2: "ISO 3166-2:TM",
  },
  {
    thaiName: "โตโก",
    label: "Togo",
    value: "TG",
    alpha3: "TGO",
    numeric: "768",
    iso3166_2: "ISO 3166-2:TG",
  },
  {
    thaiName: "ไต้หวัน",
    label: "Taiwan",
    value: "TW",
    alpha3: "TWN",
    numeric: "158",
    iso3166_2: "ISO 3166-2:TW",
  },
  {
    thaiName: "ทาจิกิสถาน",
    label: "Tajikistan",
    value: "TJ",
    alpha3: "TJK",
    numeric: "762",
    iso3166_2: "ISO 3166-2:TJ",
  },
  {
    thaiName: "แทนซาเนีย",
    label: "Tanzania",
    value: "TZ",
    alpha3: "TZA",
    numeric: "834",
    iso3166_2: "ISO 3166-2:TZ",
  },
  {
    thaiName: "โทเคอเลา",
    label: "Tokelau",
    value: "TK",
    alpha3: "TKL",
    numeric: "772",
    iso3166_2: "ISO 3166-2:TK",
  },
  {
    thaiName: "ไทย",
    label: "Thailand",
    value: "TH",
    alpha3: "THA",
    numeric: "764",
    iso3166_2: "ISO 3166-2:TH",
  },
  {
    thaiName: "นครรัฐวาติกัน",
    label: "Vatican City State",
    value: "VA",
    alpha3: "VAT",
    numeric: "336",
    iso3166_2: "ISO 3166-2:VA",
  },
  {
    thaiName: "นอร์เวย์",
    label: "Norway",
    value: "NO",
    alpha3: "NOR",
    numeric: "578",
    iso3166_2: "ISO 3166-2:NO",
  },
  {
    thaiName: "นามิเบีย",
    label: "Namibia",
    value: "NA",
    alpha3: "NAM",
    numeric: "516",
    iso3166_2: "ISO 3166-2:NA",
  },
  {
    thaiName: "นาอูรู",
    label: "Nauru",
    value: "NR",
    alpha3: "NRU",
    numeric: "520",
    iso3166_2: "ISO 3166-2:NR",
  },
  {
    thaiName: "นิการากัว",
    label: "Nicaragua",
    value: "NI",
    alpha3: "NIC",
    numeric: "558",
    iso3166_2: "ISO 3166-2:NI",
  },
  {
    thaiName: "นิวแคลิโดเนีย",
    label: "New Caledonia",
    value: "NC",
    alpha3: "NCL",
    numeric: "540",
    iso3166_2: "ISO 3166-2:NC",
  },
  {
    thaiName: "นิวซีแลนด์",
    label: "New Zealand",
    value: "NZ",
    alpha3: "NZL",
    numeric: "554",
    iso3166_2: "ISO 3166-2:NZ",
  },
  {
    thaiName: "นีวเว",
    label: "Niue",
    value: "NU",
    alpha3: "NIU",
    numeric: "570",
    iso3166_2: "ISO 3166-2:NU",
  },
  {
    thaiName: "เนเธอร์แลนด์",
    label: "Netherlands",
    value: "NL",
    alpha3: "NLD",
    numeric: "528",
    iso3166_2: "ISO 3166-2:NL",
  },
  {
    thaiName: "เนปาล",
    label: "Nepal",
    value: "NP",
    alpha3: "NPL",
    numeric: "524",
    iso3166_2: "ISO 3166-2:NP",
  },
  {
    thaiName: "ไนจีเรีย",
    label: "Nigeria",
    value: "NG",
    alpha3: "NGA",
    numeric: "566",
    iso3166_2: "ISO 3166-2:NG",
  },
  {
    thaiName: "ไนเจอร์",
    label: "Niger",
    value: "NE",
    alpha3: "NER",
    numeric: "562",
    iso3166_2: "ISO 3166-2:NE",
  },
  {
    thaiName: "บราซิล",
    label: "Brazil",
    value: "BR",
    alpha3: "BRA",
    numeric: "76",
    iso3166_2: "ISO 3166-2:BR",
  },
  {
    thaiName: "บริติชอินเดียนโอเชียนเทร์ริทอรี",
    label: "British Indian Ocean Territory",
    value: "IO",
    alpha3: "IOT",
    numeric: "86",
    iso3166_2: "ISO 3166-2:IO",
  },
  {
    thaiName: "บรูไน",
    label: "Brunei Darussalam",
    value: "BN",
    alpha3: "BRN",
    numeric: "96",
    iso3166_2: "ISO 3166-2:BN",
  },
  {
    thaiName: "บอตสวานา",
    label: "Botswana",
    value: "BW",
    alpha3: "BWA",
    numeric: "72",
    iso3166_2: "ISO 3166-2:BW",
  },
  {
    thaiName: "บอสเนียและเฮอร์เซโกวีนา",
    label: "Bosnia and Herzegovina",
    value: "BA",
    alpha3: "BIH",
    numeric: "70",
    iso3166_2: "ISO 3166-2:BA",
  },
  {
    thaiName: "บังกลาเทศ",
    label: "Bangladesh",
    value: "BD",
    alpha3: "BGD",
    numeric: "50",
    iso3166_2: "ISO 3166-2:BD",
  },
  {
    thaiName: "บัลแกเรีย",
    label: "Bulgaria",
    value: "BG",
    alpha3: "BGR",
    numeric: "100",
    iso3166_2: "ISO 3166-2:BG",
  },
  {
    thaiName: "บาร์เบโดส",
    label: "Barbados",
    value: "BB",
    alpha3: "BRB",
    numeric: "52",
    iso3166_2: "ISO 3166-2:BB",
  },
  {
    thaiName: "บาห์เรน",
    label: "Bahrain",
    value: "BH",
    alpha3: "BHR",
    numeric: "48",
    iso3166_2: "ISO 3166-2:BH",
  },
  {
    thaiName: "บาฮามาส",
    label: "Bahamas",
    value: "BS",
    alpha3: "BHS",
    numeric: "44",
    iso3166_2: "ISO 3166-2:BS",
  },
  {
    thaiName: "บุรุนดี",
    label: "Burundi",
    value: "BI",
    alpha3: "BDI",
    numeric: "108",
    iso3166_2: "ISO 3166-2:BI",
  },
  {
    thaiName: "บูร์กินาฟาโซ",
    label: "Burkina Faso",
    value: "BF",
    alpha3: "BFA",
    numeric: "854",
    iso3166_2: "ISO 3166-2:BF",
  },
  {
    thaiName: "เบนิน",
    label: "Benin",
    value: "BJ",
    alpha3: "BEN",
    numeric: "204",
    iso3166_2: "ISO 3166-2:BJ",
  },
  {
    thaiName: "เบลเยียม",
    label: "Belgium",
    value: "BE",
    alpha3: "BEL",
    numeric: "56",
    iso3166_2: "ISO 3166-2:BE",
  },
  {
    thaiName: "เบลารุส",
    label: "Belarus",
    value: "BY",
    alpha3: "BLR",
    numeric: "112",
    iso3166_2: "ISO 3166-2:BY",
  },
  {
    thaiName: "เบลีซ",
    label: "Belize",
    value: "BZ",
    alpha3: "BLZ",
    numeric: "84",
    iso3166_2: "ISO 3166-2:BZ",
  },
  {
    thaiName: "เบอร์มิวดา",
    label: "Bermuda",
    value: "BM",
    alpha3: "BMU",
    numeric: "60",
    iso3166_2: "ISO 3166-2:BM",
  },
  {
    thaiName: "โบลิเวีย",
    label: "Bolivia",
    value: "BO",
    alpha3: "BOL",
    numeric: "68",
    iso3166_2: "ISO 3166-2:BO",
  },
  {
    thaiName: "ปวยร์โตรีโก",
    label: "Puerto Rico",
    value: "PR",
    alpha3: "PRI",
    numeric: "630",
    iso3166_2: "ISO 3166-2:PR",
  },
  {
    thaiName: "ปากีสถาน",
    label: "Pakistan",
    value: "PK",
    alpha3: "PAK",
    numeric: "586",
    iso3166_2: "ISO 3166-2:PK",
  },
  {
    thaiName: "ปานามา",
    label: "Panama",
    value: "PA",
    alpha3: "PAN",
    numeric: "591",
    iso3166_2: "ISO 3166-2:PA",
  },
  {
    thaiName: "ปาปัวนิวกินี",
    label: "Papua New Guinea",
    value: "PG",
    alpha3: "PNG",
    numeric: "598",
    iso3166_2: "ISO 3166-2:PG",
  },
  {
    thaiName: "ปารากวัย",
    label: "Paraguay",
    value: "PY",
    alpha3: "PRY",
    numeric: "600",
    iso3166_2: "ISO 3166-2:PY",
  },
  {
    thaiName: "ปาเลสไตน์",
    label: "Palestine",
    value: "PS",
    alpha3: "PSE",
    numeric: "275",
    iso3166_2: "ISO 3166-2:PS",
  },
  {
    thaiName: "ปาเลา",
    label: "Palau",
    value: "PW",
    alpha3: "PLW",
    numeric: "585",
    iso3166_2: "ISO 3166-2:PW",
  },
  {
    thaiName: "เปรู",
    label: "Peru",
    value: "PE",
    alpha3: "PER",
    numeric: "604",
    iso3166_2: "ISO 3166-2:PE",
  },
  {
    thaiName: "โปรตุเกส",
    label: "Portugal",
    value: "PT",
    alpha3: "PRT",
    numeric: "620",
    iso3166_2: "ISO 3166-2:PT",
  },
  {
    thaiName: "โปแลนด์",
    label: "Poland",
    value: "PL",
    alpha3: "POL",
    numeric: "616",
    iso3166_2: "ISO 3166-2:PL",
  },
  {
    thaiName: "ฝรั่งเศส",
    label: "France",
    value: "FR",
    alpha3: "FRA",
    numeric: "250",
    iso3166_2: "ISO 3166-2:FR",
  },
  {
    thaiName: "พม่า",
    label: "Myanmar",
    value: "MM",
    alpha3: "MMR",
    numeric: "104",
    iso3166_2: "ISO 3166-2:MM",
  },
  {
    thaiName: "ฟินแลนด์",
    label: "Finland",
    value: "FI",
    alpha3: "FIN",
    numeric: "246",
    iso3166_2: "ISO 3166-2:FI",
  },
  {
    thaiName: "ฟิลิปปินส์",
    label: "Philippines",
    value: "PH",
    alpha3: "PHL",
    numeric: "608",
    iso3166_2: "ISO 3166-2:PH",
  },
  {
    thaiName: "ฟีจี",
    label: "Fiji",
    value: "FJ",
    alpha3: "FJI",
    numeric: "242",
    iso3166_2: "ISO 3166-2:FJ",
  },
  {
    thaiName: "เฟรนช์เกียนา",
    label: "French Guiana",
    value: "GF",
    alpha3: "GUF",
    numeric: "254",
    iso3166_2: "ISO 3166-2:GF",
  },
  {
    thaiName: "เฟรนช์เซาเทิร์นและแอนตาร์กติกแลนดส์",
    label: "French Southern Territories",
    value: "TF",
    alpha3: "ATF",
    numeric: "260",
    iso3166_2: "ISO 3166-2:TF",
  },
  {
    thaiName: "เฟรนช์พอลินีเชีย",
    label: "French Polynesia",
    value: "PF",
    alpha3: "PYF",
    numeric: "258",
    iso3166_2: "ISO 3166-2:PF",
  },
  {
    thaiName: "ภูฏาน",
    label: "Bhutan",
    value: "BT",
    alpha3: "BTN",
    numeric: "64",
    iso3166_2: "ISO 3166-2:BT",
  },
  {
    thaiName: "มองโกเลีย",
    label: "Mongolia",
    value: "MN",
    alpha3: "MNG",
    numeric: "496",
    iso3166_2: "ISO 3166-2:MN",
  },
  {
    thaiName: "มอนต์เซอร์รัต",
    label: "Montserrat",
    value: "MS",
    alpha3: "MSR",
    numeric: "500",
    iso3166_2: "ISO 3166-2:MS",
  },
  {
    thaiName: "มอนเตเนโกร",
    label: "Montenegro",
    value: "ME",
    alpha3: "MNE",
    numeric: "499",
    iso3166_2: "ISO 3166-2:ME",
  },
  {
    thaiName: "มอริเชียส",
    label: "Mauritius",
    value: "MU",
    alpha3: "MUS",
    numeric: "480",
    iso3166_2: "ISO 3166-2:MU",
  },
  {
    thaiName: "มอริเตเนีย",
    label: "Mauritania",
    value: "MR",
    alpha3: "MRT",
    numeric: "478",
    iso3166_2: "ISO 3166-2:MR",
  },
  {
    thaiName: "มอลโดวา",
    label: "Moldova",
    value: "MD",
    alpha3: "MDA",
    numeric: "498",
    iso3166_2: "ISO 3166-2:MD",
  },
  {
    thaiName: "มอลตา",
    label: "Malta",
    value: "MT",
    alpha3: "MLT",
    numeric: "470",
    iso3166_2: "ISO 3166-2:MT",
  },
  {
    thaiName: "มัลดีฟส์",
    label: "Maldives",
    value: "MV",
    alpha3: "MDV",
    numeric: "462",
    iso3166_2: "ISO 3166-2:MV",
  },
  {
    thaiName: "มาเก๊า",
    label: "Macao",
    value: "MO",
    alpha3: "MAC",
    numeric: "446",
    iso3166_2: "ISO 3166-2:MO",
  },
  {
    thaiName: "นอร์ทมาซิโดเนีย",
    label: "North Macedonia",
    value: "MK",
    alpha3: "MKD",
    numeric: "807",
    iso3166_2: "ISO 3166-2:MK",
  },
  {
    thaiName: "มาดากัสการ์",
    label: "Madagascar",
    value: "MG",
    alpha3: "MDG",
    numeric: "450",
    iso3166_2: "ISO 3166-2:MG",
  },
  {
    thaiName: "มายอต",
    label: "Mayotte",
    value: "YT",
    alpha3: "MYT",
    numeric: "175",
    iso3166_2: "ISO 3166-2:YT",
  },
  {
    thaiName: "มาร์ตีนิก",
    label: "Martinique",
    value: "MQ",
    alpha3: "MTQ",
    numeric: "474",
    iso3166_2: "ISO 3166-2:MQ",
  },
  {
    thaiName: "มาลาวี",
    label: "Malawi",
    value: "MW",
    alpha3: "MWI",
    numeric: "454",
    iso3166_2: "ISO 3166-2:MW",
  },
  {
    thaiName: "มาลี",
    label: "Mali",
    value: "ML",
    alpha3: "MLI",
    numeric: "466",
    iso3166_2: "ISO 3166-2:ML",
  },
  {
    thaiName: "มาเลเซีย",
    label: "Malaysia",
    value: "MY",
    alpha3: "MYS",
    numeric: "458",
    iso3166_2: "ISO 3166-2:MY",
  },
  {
    thaiName: "เม็กซิโก",
    label: "Mexico",
    value: "MX",
    alpha3: "MEX",
    numeric: "484",
    iso3166_2: "ISO 3166-2:MX",
  },
  {
    thaiName: "โมซัมบิก",
    label: "Mozambique",
    value: "MZ",
    alpha3: "MOZ",
    numeric: "508",
    iso3166_2: "ISO 3166-2:MZ",
  },
  {
    thaiName: "โมนาโก",
    label: "Monaco",
    value: "MC",
    alpha3: "MCO",
    numeric: "492",
    iso3166_2: "ISO 3166-2:MC",
  },
  {
    thaiName: "โมร็อกโก",
    label: "Morocco",
    value: "MA",
    alpha3: "MAR",
    numeric: "504",
    iso3166_2: "ISO 3166-2:MA",
  },
  {
    thaiName: "ไมโครนีเซีย",
    label: "Micronesia",
    value: "FM",
    alpha3: "FSM",
    numeric: "583",
    iso3166_2: "ISO 3166-2:FM",
  },
  {
    thaiName: "ยิบรอลตาร์",
    label: "Gibraltar",
    value: "GI",
    alpha3: "GIB",
    numeric: "292",
    iso3166_2: "ISO 3166-2:GI",
  },
  {
    thaiName: "ยูกันดา",
    label: "Uganda",
    value: "UG",
    alpha3: "UGA",
    numeric: "800",
    iso3166_2: "ISO 3166-2:UG",
  },
  {
    thaiName: "ยูเครน",
    label: "Ukraine",
    value: "UA",
    alpha3: "UKR",
    numeric: "804",
    iso3166_2: "ISO 3166-2:UA",
  },
  {
    thaiName: "เยเมน",
    label: "Yemen",
    value: "YE",
    alpha3: "YEM",
    numeric: "887",
    iso3166_2: "ISO 3166-2:YE",
  },
  {
    thaiName: "เยอรมนี",
    label: "Germany",
    value: "DE",
    alpha3: "DEU",
    numeric: "276",
    iso3166_2: "ISO 3166-2:DE",
  },
  {
    thaiName: "รวันดา",
    label: "Rwanda",
    value: "RW",
    alpha3: "RWA",
    numeric: "646",
    iso3166_2: "ISO 3166-2:RW",
  },
  {
    thaiName: "รัสเซีย",
    label: "Russian Federation",
    value: "RU",
    alpha3: "RUS",
    numeric: "643",
    iso3166_2: "ISO 3166-2:RU",
  },
  {
    thaiName: "เรอูว์นียง",
    label: "Réunion",
    value: "RE",
    alpha3: "REU",
    numeric: "638",
    iso3166_2: "ISO 3166-2:RE",
  },
  {
    thaiName: "โรมาเนีย",
    label: "Romania",
    value: "RO",
    alpha3: "ROU",
    numeric: "642",
    iso3166_2: "ISO 3166-2:RO",
  },
  {
    thaiName: "ลักเซมเบิร์ก",
    label: "Luxembourg",
    value: "LU",
    alpha3: "LUX",
    numeric: "442",
    iso3166_2: "ISO 3166-2:LU",
  },
  {
    thaiName: "ลัตเวีย",
    label: "Latvia",
    value: "LV",
    alpha3: "LVA",
    numeric: "428",
    iso3166_2: "ISO 3166-2:LV",
  },
  {
    thaiName: "ลาว",
    label: "Laos",
    value: "LA",
    alpha3: "LAO",
    numeric: "418",
    iso3166_2: "ISO 3166-2:LA",
  },
  {
    thaiName: "ลิกเตนสไตน์",
    label: "Liechtenstein",
    value: "LI",
    alpha3: "LIE",
    numeric: "438",
    iso3166_2: "ISO 3166-2:LI",
  },
  {
    thaiName: "ลิทัวเนีย",
    label: "Lithuania",
    value: "LT",
    alpha3: "LTU",
    numeric: "440",
    iso3166_2: "ISO 3166-2:LT",
  },
  {
    thaiName: "ลิเบีย",
    label: "Libya",
    value: "LY",
    alpha3: "LBY",
    numeric: "434",
    iso3166_2: "ISO 3166-2:LY",
  },
  {
    thaiName: "เลโซโท",
    label: "Lesotho",
    value: "LS",
    alpha3: "LSO",
    numeric: "426",
    iso3166_2: "ISO 3166-2:LS",
  },
  {
    thaiName: "เลบานอน",
    label: "Lebanon",
    value: "LB",
    alpha3: "LBN",
    numeric: "422",
    iso3166_2: "ISO 3166-2:LB",
  },
  {
    thaiName: "ไลบีเรีย",
    label: "Liberia",
    value: "LR",
    alpha3: "LBR",
    numeric: "430",
    iso3166_2: "ISO 3166-2:LR",
  },
  {
    thaiName: "วานูวาตู",
    label: "Vanuatu",
    value: "VU",
    alpha3: "VUT",
    numeric: "548",
    iso3166_2: "ISO 3166-2:VU",
  },
  {
    thaiName: "วาลิสและฟูตูนา",
    label: "Wallis and Futuna",
    value: "WF",
    alpha3: "WLF",
    numeric: "876",
    iso3166_2: "ISO 3166-2:WF",
  },
  {
    thaiName: "เวเนซุเอลา",
    label: "Venezuela",
    value: "VE",
    alpha3: "VEN",
    numeric: "862",
    iso3166_2: "ISO 3166-2:VE",
  },
  {
    thaiName: "เวียดนาม",
    label: "Vietnam",
    value: "VN",
    alpha3: "VNM",
    numeric: "704",
    iso3166_2: "ISO 3166-2:VN",
  },
  {
    thaiName: "ศรีลังกา",
    label: "Sri Lanka",
    value: "LK",
    alpha3: "LKA",
    numeric: "144",
    iso3166_2: "ISO 3166-2:LK",
  },
  {
    thaiName: "สเปน",
    label: "Spain",
    value: "ES",
    alpha3: "ESP",
    numeric: "724",
    iso3166_2: "ISO 3166-2:ES",
  },
  {
    thaiName: "สโลวาเกีย",
    label: "Slovakia",
    value: "SK",
    alpha3: "SVK",
    numeric: "703",
    iso3166_2: "ISO 3166-2:SK",
  },
  {
    thaiName: "สโลวีเนีย",
    label: "Slovenia",
    value: "SI",
    alpha3: "SVN",
    numeric: "705",
    iso3166_2: "ISO 3166-2:SI",
  },
  {
    thaiName: "สวาซิแลนด์",
    label: "Swaziland",
    value: "SZ",
    alpha3: "SWZ",
    numeric: "748",
    iso3166_2: "ISO 3166-2:SZ",
  },
  {
    thaiName: "สวิตเซอร์แลนด์",
    label: "Switzerland",
    value: "CH",
    alpha3: "CHE",
    numeric: "756",
    iso3166_2: "ISO 3166-2:CH",
  },
  {
    thaiName: "สวีเดน",
    label: "Sweden",
    value: "SE",
    alpha3: "SWE",
    numeric: "752",
    iso3166_2: "ISO 3166-2:SE",
  },
  {
    thaiName: "สหรัฐ",
    label: "United States of America",
    value: "US",
    alpha3: "USA",
    numeric: "840",
    iso3166_2: "ISO 3166-2:US",
  },
  {
    thaiName: "สหรัฐอาหรับเอมิเรตส์",
    label: "United Arab Emirates",
    value: "AE",
    alpha3: "ARE",
    numeric: "784",
    iso3166_2: "ISO 3166-2:AE",
  },
  {
    thaiName: "สหราชอาณาจักร",
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "GB",
    alpha3: "GBR",
    numeric: "826",
    iso3166_2: "ISO 3166-2:GB",
  },
  {
    thaiName: "สาธารณรัฐคองโก",
    label: "Republic of the Congo",
    value: "CG",
    alpha3: "COG",
    numeric: "178",
    iso3166_2: "ISO 3166-2:CG",
  },
  {
    thaiName: "สาธารณรัฐโดมินิกัน",
    label: "Dominican Republic",
    value: "DO",
    alpha3: "DOM",
    numeric: "214",
    iso3166_2: "ISO 3166-2:DO",
  },
  {
    thaiName: "สาธารณรัฐประชาธิปไตยคองโก",
    label: "Democratic Republic of the Congo",
    value: "CD",
    alpha3: "COD",
    numeric: "180",
    iso3166_2: "ISO 3166-2:CD",
  },
  {
    thaiName: "สาธารณรัฐแอฟริกากลาง",
    label: "Central African Republic",
    value: "CF",
    alpha3: "CAF",
    numeric: "140",
    iso3166_2: "ISO 3166-2:CF",
  },
  {
    thaiName: "สิงคโปร์",
    label: "Singapore",
    value: "SG",
    alpha3: "SGP",
    numeric: "702",
    iso3166_2: "ISO 3166-2:SG",
  },
  {
    thaiName: "หมู่เกาะคุก",
    label: "Cook Islands",
    value: "CK",
    alpha3: "COK",
    numeric: "184",
    iso3166_2: "ISO 3166-2:CK",
  },
  {
    thaiName: "หมู่เกาะเคย์แมน",
    label: "Cayman Islands",
    value: "KY",
    alpha3: "CYM",
    numeric: "136",
    iso3166_2: "ISO 3166-2:KY",
  },
  {
    thaiName: "หมู่เกาะโคโคส",
    label: "Cocos (Keeling) Islands",
    value: "CC",
    alpha3: "CCK",
    numeric: "166",
    iso3166_2: "ISO 3166-2:CC",
  },
  {
    thaiName: "หมู่เกาะโซโลมอน",
    label: "Solomon Islands",
    value: "SB",
    alpha3: "SLB",
    numeric: "90",
    iso3166_2: "ISO 3166-2:SB",
  },
  {
    thaiName: "หมู่เกาะเติกส์และเคคอส",
    label: "Turks and Caicos Islands",
    value: "TC",
    alpha3: "TCA",
    numeric: "796",
    iso3166_2: "ISO 3166-2:TC",
  },
  {
    thaiName: "หมู่เกาะนอร์เทิร์นมาเรียนา",
    label: "Northern Mariana Islands",
    value: "MP",
    alpha3: "MNP",
    numeric: "580",
    iso3166_2: "ISO 3166-2:MP",
  },
  {
    thaiName: "หมู่เกาะบริติชเวอร์จิน",
    label: "British Virgin Islands",
    value: "VG",
    alpha3: "VGB",
    numeric: "92",
    iso3166_2: "ISO 3166-2:VG",
  },
  {
    thaiName: "หมู่เกาะพิตแคร์น",
    label: "Pitcairn",
    value: "PN",
    alpha3: "PCN",
    numeric: "612",
    iso3166_2: "ISO 3166-2:PN",
  },
  {
    thaiName: "หมู่เกาะฟอล์กแลนด์",
    label: "Falkland Islands",
    value: "FK",
    alpha3: "FLK",
    numeric: "238",
    iso3166_2: "ISO 3166-2:FK",
  },
  {
    thaiName: "หมู่เกาะแฟโร",
    label: "Faroe Islands",
    value: "FO",
    alpha3: "FRO",
    numeric: "234",
    iso3166_2: "ISO 3166-2:FO",
  },
  {
    thaiName: "หมู่เกาะมาร์แชลล์",
    label: "Marshall Islands",
    value: "MH",
    alpha3: "MHL",
    numeric: "584",
    iso3166_2: "ISO 3166-2:MH",
  },
  {
    thaiName: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
    label: "United States Virgin Islands",
    value: "VI",
    alpha3: "VIR",
    numeric: "850",
    iso3166_2: "ISO 3166-2:VI",
  },
  {
    thaiName: "หมู่เกาะโอลันด์",
    label: "Åland Islands",
    value: "AX",
    alpha3: "ALA",
    numeric: "248",
    iso3166_2: "ISO 3166-2:AX",
  },
  {
    thaiName: "อเมริกันซามัว",
    label: "American Samoa",
    value: "AS",
    alpha3: "ASM",
    numeric: "16",
    iso3166_2: "ISO 3166-2:AS",
  },
  {
    thaiName: "ออสเตรเลีย",
    label: "Australia",
    value: "AU",
    alpha3: "AUS",
    numeric: "36",
    iso3166_2: "ISO 3166-2:AU",
  },
  {
    thaiName: "ออสเตรีย",
    label: "Austria",
    value: "AT",
    alpha3: "AUT",
    numeric: "40",
    iso3166_2: "ISO 3166-2:AT",
  },
  {
    thaiName: "อันดอร์รา",
    label: "Andorra",
    value: "AD",
    alpha3: "AND",
    numeric: "20",
    iso3166_2: "ISO 3166-2:AD",
  },
  {
    thaiName: "อัฟกานิสถาน",
    label: "Afghanistan",
    value: "AF",
    alpha3: "AFG",
    numeric: "4",
    iso3166_2: "ISO 3166-2:AF",
  },
  {
    thaiName: "อาเซอร์ไบจาน",
    label: "Azerbaijan",
    value: "AZ",
    alpha3: "AZE",
    numeric: "31",
    iso3166_2: "ISO 3166-2:AZ",
  },
  {
    thaiName: "อาร์เจนตินา",
    label: "Argentina",
    value: "AR",
    alpha3: "ARG",
    numeric: "32",
    iso3166_2: "ISO 3166-2:AR",
  },
  {
    thaiName: "อาร์มีเนีย",
    label: "Armenia",
    value: "AM",
    alpha3: "ARM",
    numeric: "51",
    iso3166_2: "ISO 3166-2:AM",
  },
  {
    thaiName: "อารูบา",
    label: "Aruba",
    value: "AW",
    alpha3: "ABW",
    numeric: "533",
    iso3166_2: "ISO 3166-2:AW",
  },
  {
    thaiName: "อิเควทอเรียลกินี",
    label: "Equatorial Guinea",
    value: "GQ",
    alpha3: "GNQ",
    numeric: "226",
    iso3166_2: "ISO 3166-2:GQ",
  },
  {
    thaiName: "อิตาลี",
    label: "Italy",
    value: "IT",
    alpha3: "ITA",
    numeric: "380",
    iso3166_2: "ISO 3166-2:IT",
  },
  {
    thaiName: "อินเดีย",
    label: "India",
    value: "IN",
    alpha3: "IND",
    numeric: "356",
    iso3166_2: "ISO 3166-2:IN",
  },
  {
    thaiName: "อินโดนีเซีย",
    label: "Indonesia",
    value: "ID",
    alpha3: "IDN",
    numeric: "360",
    iso3166_2: "ISO 3166-2:ID",
  },
  {
    thaiName: "อิรัก",
    label: "Iraq",
    value: "IQ",
    alpha3: "IRQ",
    numeric: "368",
    iso3166_2: "ISO 3166-2:IQ",
  },
  {
    thaiName: "อิสราเอล",
    label: "Israel",
    value: "IL",
    alpha3: "ISR",
    numeric: "376",
    iso3166_2: "ISO 3166-2:IL",
  },
  {
    thaiName: "อิหร่าน",
    label: "Iran",
    value: "IR",
    alpha3: "IRN",
    numeric: "364",
    iso3166_2: "ISO 3166-2:IR",
  },
  {
    thaiName: "อียิปต์",
    label: "Egypt",
    value: "EG",
    alpha3: "EGY",
    numeric: "818",
    iso3166_2: "ISO 3166-2:EG",
  },
  {
    thaiName: "อุซเบกิสถาน",
    label: "Uzbekistan",
    value: "UZ",
    alpha3: "UZB",
    numeric: "860",
    iso3166_2: "ISO 3166-2:UZ",
  },
  {
    thaiName: "อุรุกวัย",
    label: "Uruguay",
    value: "UY",
    alpha3: "URY",
    numeric: "858",
    iso3166_2: "ISO 3166-2:UY",
  },
  {
    thaiName: "เอกวาดอร์",
    label: "Ecuador",
    value: "EC",
    alpha3: "ECU",
    numeric: "218",
    iso3166_2: "ISO 3166-2:EC",
  },
  {
    thaiName: "เอธิโอเปีย",
    label: "Ethiopia",
    value: "ET",
    alpha3: "ETH",
    numeric: "231",
    iso3166_2: "ISO 3166-2:ET",
  },
  {
    thaiName: "เอริเทรีย",
    label: "Eritrea",
    value: "ER",
    alpha3: "ERI",
    numeric: "232",
    iso3166_2: "ISO 3166-2:ER",
  },
  {
    thaiName: "เอลซัลวาดอร์",
    label: "El Salvador",
    value: "SV",
    alpha3: "SLV",
    numeric: "222",
    iso3166_2: "ISO 3166-2:SV",
  },
  {
    thaiName: "เอสโตเนีย",
    label: "Estonia",
    value: "EE",
    alpha3: "EST",
    numeric: "233",
    iso3166_2: "ISO 3166-2:EE",
  },
  {
    thaiName: "แองกวิลลา",
    label: "Anguilla",
    value: "AI",
    alpha3: "AIA",
    numeric: "660",
    iso3166_2: "ISO 3166-2:AI",
  },
  {
    thaiName: "แองโกลา",
    label: "Angola",
    value: "AO",
    alpha3: "AGO",
    numeric: "24",
    iso3166_2: "ISO 3166-2:AO",
  },
  {
    thaiName: "แอนตาร์กติกา",
    label: "Antarctica",
    value: "AQ",
    alpha3: "ATA",
    numeric: "10",
    iso3166_2: "ISO 3166-2:AQ",
  },
  {
    thaiName: "แอนติกาและบาร์บูดา",
    label: "Antigua and Barbuda",
    value: "AG",
    alpha3: "ATG",
    numeric: "28",
    iso3166_2: "ISO 3166-2:AG",
  },
  {
    thaiName: "แอฟริกาใต้",
    label: "South Africa",
    value: "ZA",
    alpha3: "ZAF",
    numeric: "710",
    iso3166_2: "ISO 3166-2:ZA",
  },
  {
    thaiName: "แอลจีเรีย",
    label: "Algeria",
    value: "DZ",
    alpha3: "DZA",
    numeric: "12",
    iso3166_2: "ISO 3166-2:DZ",
  },
  {
    thaiName: "แอลเบเนีย",
    label: "Albania",
    value: "AL",
    alpha3: "ALB",
    numeric: "8",
    iso3166_2: "ISO 3166-2:AL",
  },
  {
    thaiName: "โอมาน",
    label: "Oman",
    value: "OM",
    alpha3: "OMN",
    numeric: "512",
    iso3166_2: "ISO 3166-2:OM",
  },
  {
    thaiName: "ไอซ์แลนด์",
    label: "Iceland",
    value: "IS",
    alpha3: "ISL",
    numeric: "352",
    iso3166_2: "ISO 3166-2:IS",
  },
  {
    thaiName: "ไอร์แลนด์",
    label: "Ireland",
    value: "IE",
    alpha3: "IRL",
    numeric: "372",
    iso3166_2: "ISO 3166-2:IE",
  },
  {
    thaiName: "ไอล์ออฟแมน",
    label: "Isle of Man",
    value: "IM",
    alpha3: "IMN",
    numeric: "833",
    iso3166_2: "ISO 3166-2:IM",
  },
  {
    thaiName: "ฮ่องกง",
    label: "Hong Kong",
    value: "HK",
    alpha3: "HKG",
    numeric: "344",
    iso3166_2: "ISO 3166-2:HK",
  },
  {
    thaiName: "ฮอนดูรัส",
    label: "Honduras",
    value: "HN",
    alpha3: "HND",
    numeric: "340",
    iso3166_2: "ISO 3166-2:HN",
  },
  {
    thaiName: "ฮังการี",
    label: "Hungary",
    value: "HU",
    alpha3: "HUN",
    numeric: "348",
    iso3166_2: "ISO 3166-2:HU",
  },
  {
    thaiName: "เฮติ",
    label: "Haiti",
    value: "HT",
    alpha3: "HTI",
    numeric: "332",
    iso3166_2: "ISO 3166-2:HT",
  },
  {
    thaiName: "ซินต์มาร์เติน",
    label: "Sint Maarten",
    value: "SX",
    alpha3: "SXM",
    numeric: "534",
    iso3166_2: "ISO 3166-2:SX",
  },
  {
    thaiName: "เนเธอร์แลนด์แคริบเบียน",
    label: "Bonaire, Sint Eustatius and Saba",
    value: "BQ",
    alpha3: "BES",
    numeric: "535",
    iso3166_2: "ISO 3166-2:BQ",
  },
  {
    thaiName: "สฟาลบาร์และยานไมเอน",
    label: "Svalbard and Jan Mayen",
    value: "SJ",
    alpha3: "SJM",
    numeric: "744",
    iso3166_2: "ISO 3166-2:SJ",
  },
]

export default CountryList
