export const VideoPosition = {
  JUSTIFY_LEFT: {
    status_code: "JUSTIFY_LEFT",
    description: "ชิดขอบซ้าย",
    descriptionEn: "justify left",
  },
  JUSTIFY_RIGHT: {
    status_code: "JUSTIFY_RIGHT",
    description: "ชิดขอบขวา",
    descriptionEn: "justify right",
  },
  JUSTIFY_CENTER: {
    status_code: "JUSTIFY_CENTER",
    description: "ตรงกลางจอ",
    descriptionEn: "Center of Screen",
  },
}

export default VideoPosition
