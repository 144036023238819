import React from "react"
import { WhitePanel } from "../../common/Cards"
// eslint-disable-next-line arrow-body-style
const TextContent = () => {
  return (
    <div>
      <WhitePanel>
        <div className="text-center">
          <h3 className="text-4xl my-4 font-semibold">
            <i className="fas fa-align-center"></i>{" "}
          </h3>
        </div>
      </WhitePanel>
    </div>
  )
}

TextContent.propTypes = {}

export default TextContent
