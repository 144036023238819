/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import _ from "lodash"
import { DateTime } from "luxon"

import { paymentStatus } from "../config/constant"
import { selectRoomTypeColor } from "./colors"

const formatEventTime = (isoDate) =>
  DateTime.fromISO(isoDate).set({ hour: 12, minute: 1 }).toISODate()

export const generateBookingCalendarEvent = (
  bookingInfoArray,
  place = "calendar",
  divideBooking = false,
) => {
  function chunkSubstr(str, size) {
    const numChunks = Math.ceil(str.length / size)
    const chunks = new Array(numChunks)

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size)
    }

    return chunks
  }

  const mapBookingEvent = _.map(bookingInfoArray, (eachBooking) => {
    const bookinglist = _.map(eachBooking?.list, (eachBookingList) => eachBookingList?.room?.name)
    const amountOfBookingList = Array.isArray(bookinglist) ? bookinglist?.length : 0
    // console.log("Each Booking", eachBooking)
    const selectDepositStatus = (currentStatus) => {
      switch (currentStatus) {
        case paymentStatus.deposit.depositWait:
          return "❎"
        case paymentStatus.deposit.depositPay:
          return "🕒"
        case paymentStatus.deposit.depositConfirm:
          return "✅"
        default:
          return "✅"
      }
    }

    const selectAllPaymentStatus = (currentStatus) => {
      switch (currentStatus) {
        case paymentStatus.allPayment.allPaymentWait:
          return "❎"
        case paymentStatus.allPayment.allPaymentPay:
          return "🕒"
        case paymentStatus.allPayment.allPaymentConfirm:
          return "✅"
        default:
          return "❎"
      }
    }

    const colorPayload =
      place === "calendar"
        ? { backgroundColor: selectRoomTypeColor(eachBooking?.list?.[0]?.room?.type?._id) }
        : { bgColor: selectRoomTypeColor(eachBooking?.list?.[0]?.room?.type?._id) }

    return {
      id: eachBooking?.id,
      title: `${eachBooking?.customer?.firstname} -  ${bookinglist} ${selectDepositStatus(
        eachBooking?.paymentStatus,
      )}${selectAllPaymentStatus(eachBooking?.paymentStatus)}`,
      resourceId: "r1", // _.first(_.shuffle(["r1", "r2"])),
      start: formatEventTime(eachBooking?.start),
      end: formatEventTime(eachBooking?.end),
      movable: false,
      borderColor: "#FFFFFF",
      classNames: ["text-wrap"],
      ...colorPayload,
    }
  })
  let count = 0
  const reducedEvent = _.reduce(
    mapBookingEvent,
    (result, value, key) => {
      const splitedValue = chunkSubstr(value?.title, 20)
      const tempResult = []
      _.map(splitedValue, (each) => {
        result.push({
          ...value,
          title: each,
          count,
        })

        count += 1
      })
      const reverseTempResult = _.reverse(tempResult)
      return result.concat(reverseTempResult)
    },
    [],
  )

  if (divideBooking) {
    return reducedEvent
  }
  return mapBookingEvent
}

export default generateBookingCalendarEvent
