/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import _ from "lodash"
import { Checkbox } from "antd"

import OnlineBookingLayout from "../../components/layout/OnlineBookingLayout"
import { WhitePanel, OnlineBookingInfoForm, Confirm, Alert } from "../../components"
import { useQuery } from "../../config"
import * as actions from "../../redux/actions"
import { OnlineBookingContext } from "../../contexts/OnlineBookingContext"
import { BookingSource, BookingStatus, paymentStatus, bookingType } from "../../config/constant"
import { calculatePriceOnSubtype } from "../../util"

function OnlineBookingAddInfo() {
  const { t } = useTranslation()
  const query = useQuery()
  const dispatch = useDispatch()
  const {
    color,
    specifyBookingList,
    checkinDate,
    checkoutDate,
    setCheckinDate,
    setCheckedoutDate,
    selectedServices,
    unSpecifyBookingList,
    setBookingInfo,
  } = useContext(OnlineBookingContext)
  const history = useHistory()
  const information = useSelector((state) => state.information)
  const bookings = useSelector((state) => state.bookings)
  const [selectedRoomType, setSelectedRoomType] = useState(query.get("roomtype"))
  const { control, register, handleSubmit } = useForm()
  const [agreeCheck, setAgreeCheck] = useState(false)
  const [bookingFormData, setBookingFormData] = useState({})
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [isAlertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isBookingIsReady, setIsBookingIsReady] = useState(false)

  const handlePreCreateBooking = (data) => {
    setBookingFormData(data)
    setIsConfirmOpen(true)
  }

  console.log("information in add-info page", information)

  useEffect(() => {
    dispatch(actions.getInformation({})).then(() => {})

    return () => {}
  }, [])

  const pricingGenerator = (selectedSubtype) => {
    try {
      const amountOfBooking = dayjs(dayjs(checkoutDate).startOf("day")).diff(
        dayjs(checkinDate).startOf("day"),
        "day",
      )
      const amountOfBookingAsList = new Array(amountOfBooking)
      const pricingList = _.map(amountOfBookingAsList, (each, index) => {
        const tempCheckinDate = dayjs(checkinDate).add(index, "day").toISOString()
        const tempCheckoutDate = dayjs(checkinDate)
          .add(index + 1, "day")
          .toISOString()
        const priceOnSubtype = calculatePriceOnSubtype(
          selectedSubtype,
          tempCheckinDate,
          tempCheckoutDate,
        )

        return priceOnSubtype
      })

      return _.sum(pricingList)
    } catch (error) {
      return 0
    }
  }

  const handleCreateBooking = () => {
    // console.log("unSpecifyBookingList", unSpecifyBookingList)

    const payload = {
      customer: bookingFormData?.customer,
      list: _.map(
        _.filter(specifyBookingList, (each) => _.isObject(each)),
        (each) => ({
          ...each,
          price: pricingGenerator(each?.sub_type),
          room: each,
          room_id: each?._id,
          // TODO: Discount
        }),
      ),
      no_room_booking: _.map(
        _.filter(unSpecifyBookingList, (each) => _.isObject(each)),
        (each) => ({
          ...each,
          roomtype: each?.subtype?.type,
          price: pricingGenerator(each?.subtype),
          amount: each?.amount,
        }),
      ),
      start: dayjs(checkinDate).locale("th").hour(12).minute(1).toDate(),
      end: dayjs(checkoutDate).locale("th").hour(11).minute(59).toDate(),
      status: BookingStatus.book,
      services: _.filter(selectedServices, (each) => _.isObject(each)),
      booking_type: bookingType.onlineBooking,
      paymentStatus: paymentStatus.deposit.depositWait,
      booking_source: BookingSource?.ONLINE_SYSTEM.status_code,
      capacity: bookingFormData?.capacity,
      lock: {
        enable: true,
        until: dayjs().add(10, "minute").toDate(),
      },
      password: dayjs().format("DDMMYYHHmmss"),
    }
    console.log("payload in add info page", payload)
    dispatch(actions.createOneBooking(payload))
      .then(() => {
        setIsBookingIsReady(true)
        setIsConfirmOpen(false)
      })
      .catch((err) => {
        setAlertOpen(true)
        setIsConfirmOpen(false)
        console.log("err", err?.response)
        setAlertMessage(
          err?.response?.data?.error?.message || `ขออภัย ไม่สามารถสร้างการจองได้ ${err?.message}`,
        )
      })
  }

  useEffect(() => {
    if (isBookingIsReady) {
      if (bookings?.createNew === true) {
        setBookingInfo(bookings)
        history.push(`/online-booking/payment/${bookings?._id}`)
        setIsConfirmOpen(false)
      }
    }

    return () => {}
  }, [isBookingIsReady, bookings])

  return (
    <OnlineBookingLayout
      title={t("reservation.bookingInfoInput")}
      checkinDate={checkinDate}
      checkoutDate={checkoutDate}
      handleNext={() => {}}
      handlePrevious={() => {
        history.push(`/online-booking/service-select`)
      }}
      selectedRoomType={selectedRoomType}
      setSelectedRoomType={setSelectedRoomType}
      setCheckOutDate={setCheckedoutDate}
      setCheckedInDate={setCheckinDate}
      selectedRooms={specifyBookingList}
      selectedIndex={3}
    >
      <Confirm
        isOpen={isConfirmOpen}
        title={t("reservation.confirmBooking")}
        handleOnClose={() => {
          setIsConfirmOpen(false)
        }}
        body={t("reservation.confirmBookingPolicy")}
        handleSuccess={() => handleCreateBooking()}
      />
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setAlertOpen(false)}
        isError={isAlertOpen}
      />
      <div className="px-2 mb-24">
        <form onSubmit={handleSubmit(handlePreCreateBooking)}>
          {" "}
          <h2 className="text-xl font-semibold my-2 ">{t("reservation.guestInfo")}</h2>
          <WhitePanel>
            <OnlineBookingInfoForm control={control} register={register} />
          </WhitePanel>
          <h2 className="text-xl font-semibold my-2 ">{t("reservation.termAndCondition")}</h2>
          <WhitePanel>
            <div>
              <b>{t("reservation.termAndCondition")}</b>
              <br />
              <div className="font-serif text-sm">{information?.policy?.termAndCondition}</div>
              <br />

              <b>{t("reservation.canclePolicy")}</b>
              <br />
              <div className="font-serif text-sm">{information?.policy?.canclePolicy}</div>

              <div className="my-6">
                <Checkbox onChange={(e) => setAgreeCheck(e.target.checked)}>
                  {t("reservation.conditionAccept")}
                </Checkbox>
              </div>
            </div>
          </WhitePanel>
          <div className="mb-4 my-2">
            <Button isFullWidth disabled={!agreeCheck} colorScheme={color} type="submit">
              {t("reservation.book")}
            </Button>
          </div>
        </form>
      </div>
    </OnlineBookingLayout>
  )
}

export default OnlineBookingAddInfo
