/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/BookingLayout"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import SpinnersLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import { ActionButtonList } from "../../../components/Buttons/BookingActionButton"
import { BookingInfoContainer } from "../../../components/containers"
import { Alert } from "../../../components/common/Alert"
import { appConfig } from "../../../config"
import { BookingStatus } from "../../../config/constant"

export default function InfoReservation() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bookingInfo = useSelector((state) => state.bookings)
  const rooms = useSelector((state) => state.rooms)
  const me = useSelector((state) => state.me)
  const hotelServices = useSelector((state) => state.hotelService)
  const transaction = useSelector((state) => state.transaction)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const handleCreateImage = async (payload) => {
    setIsLoading(false)
    dispatch(actions.createOneImage(payload))
      .then(() => {
        dispatch(actions.getOneBooking(params?.id))
          .then(() => {
            setIsLoading(true)
          })
          .catch((err) => {
            setAlertOpen(true)
            setAlertMessage(err?.message)
          })
      })
      .catch((err) => {
        setAlertOpen(true)
        setAlertMessage(err?.message)
      })
  }

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOneBooking(params?.id))
        .then(() => {
          dispatch(actions.getAllRooms())
          dispatch(actions.getAllServices({ size: appConfig.maxFetchSize, page: 1 }))
          dispatch(
            actions.getAllTransaction({
              size: appConfig.maxFetchSize,
              page: 1,
              query: { booking: params?.id },
            }),
          )
          setIsLoading(true)
        })
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        })
    }

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnersLoading />
  }

  const handleEditBooking = (id, payload) => {
    setIsLoading(false)
    dispatch(actions.editOneBooking(id, { ...payload, user: me?._id }))
      .then(() => {
        dispatch(actions.getCurrentBill())
        if (payload.status === BookingStatus.checkedOut) {
          history.push("/reservation")
        } else {
          dispatch(actions.getOneBooking(id))
          setIsLoading(true)
        }
      })
      .catch((err) => {
        setAlertOpen(true)
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  const handleDeleteBooking = (id) => {
    dispatch(actions.deleteOneBooking(id, { user: me?._id }))
      .then(() => {
        history.push("/reservation")
      })
      .catch((err) => {
        setAlertOpen(true)
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  const handleEditLog = (id, payload) => {
    setIsLoading(false)
    dispatch(actions.editOnelog(id, { ...payload, user: me?._id }))
      .then(() => {
        dispatch(actions.getCurrentBill())
        if (payload.status === BookingStatus.checkedOut) {
          history.push("/reservation")
        } else {
          dispatch(actions.getOneBooking(id))
          setIsLoading(true)
        }
      })
      .catch((err) => {
        setAlertOpen(true)
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.detailBooking")}>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setAlertOpen(false)}
        isError={alertOpen}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>{" "}
        <Link to={`/reservation/edit/${params.id}`}>
          <Button colorScheme="yellow" variant="solid">
            {t("general.edit")}
          </Button>
        </Link>
      </div>
      <div className="p-4 w-full">
        <WhitePanel>
          <BookingInfoContainer bookingInfo={bookingInfo} transactions={transaction} />
          <div className="my-2 w-full">
            <h6 className="text-xs font-bold font-sans">{t("reservation.changeStatus")}</h6>
            <div className="my-2 justify-start flex  ">
              <ActionButtonList
                eachBooking={bookingInfo}
                place="info"
                handleDeleteBooking={handleDeleteBooking}
                handleEditBooking={handleEditBooking}
                hotelServices={hotelServices}
                rooms={rooms}
                handleCreateImage={handleCreateImage}
                handleEditLog={handleEditLog}
              />
            </div>
          </div>
          <br />
        </WhitePanel>
      </div>
    </Layout>
  )
}
