import React from "react"
import _ from "lodash"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { MeetingRoomTimeSlot } from "../../config/constant"

export default function MeetingRoomOnOrderList({ serviceOrder, full = false }) {
  const { i18n, t } = useTranslation()
  return (
    <div className="my-2">
      <h6 className="text-xs font-bold mt-2 font-sans">{t("reservation.meetingRoomList")}</h6>
      <div className="flex flex-wrap">
        {_.map(serviceOrder?.meeting_room_orders, (eachMeetingRoomOrder, mroIndex) => (
          <div className={` w-full ${full ? "" : "lg:w-1/5"}  my-1 pr-2`} key={`mro${mroIndex}`}>
            <div className="bg-gray-200 rounded-md p-2 w-full">
              <div className="text-sm font-semibold">
                {eachMeetingRoomOrder?.meeting_room?.name}
              </div>
              {eachMeetingRoomOrder?.meeting_room?.use_slot_price ? (
                <div>
                  <div className="text-sm">
                    <i
                      className={`${
                        MeetingRoomTimeSlot[eachMeetingRoomOrder?.timeslot]?.icon || ""
                      } mr-2`}
                    />
                    {MeetingRoomTimeSlot[eachMeetingRoomOrder?.timeslot]?.description}
                  </div>
                  <div className="text-sm">
                    <i className="fas fa-calendar mr-2" />
                    {dayjs(eachMeetingRoomOrder?.date).locale(i18n.language).format("D MMM")}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="text-sm">
                    {dayjs(eachMeetingRoomOrder?.start).format("HH:mm")} -{" "}
                    {dayjs(eachMeetingRoomOrder?.end).format("HH:mm")}
                  </div>
                  <div className="text-sm">
                    {dayjs(eachMeetingRoomOrder?.end).diff(
                      dayjs(eachMeetingRoomOrder?.start),
                      "hour",
                    )}{" "}
                    ชม.{" "}
                    {dayjs(eachMeetingRoomOrder?.end).diff(
                      dayjs(eachMeetingRoomOrder?.start),
                      "minute",
                    ) % 60}{" "}
                    นาที
                  </div>
                  <div className="text-sm">
                    <i className="fas fa-calendar mr-2" />
                    {dayjs(eachMeetingRoomOrder?.start).locale(i18n.language).format("D MMM")}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <hr className="my-2" />
    </div>
  )
}
