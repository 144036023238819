// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Controller } from "react-hook-form"
import { Form, Input } from "antd"
import Flags from "country-flag-icons/react/3x2"

import ImageUpload from "../../ImageUpload/ImageUpload"

const GeneralInfo = ({ defaultInfo, control, logoImage, setLogoImage }) => (
  <div className="my-3">
    <div className="bg-white p-4 px-8 rounded-lg shadow-lg">
      <div className="flex flex-wrap">
        {" "}
        <Form
          layout="vertical"
          size="large"
          className="w-full lg:w-2/4"
          // labelCol={{ span: 6 }}
          // wrapperCol={{ span: 20 }}
        >
          <h6 className="text-gray-900 text-base my-2  font-bold  font-sans ">
            ข้อมูลเบื้องต้นของโรงแรม
          </h6>

          <Form.Item label="ชื่อโรงแรมภาษาไทย" required>
            <Controller
              name="translation.th.name"
              control={control}
              defaultValue={defaultInfo?.translation?.th?.name || ""}
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.TH title="Thai" className="w-5" />}
                  placeholder="ชื่อโรงแรม"
                />
              )}
            />
          </Form.Item>
          <Form.Item label="ชื่อโรงแรม ภาษาอังกฤษ">
            <Controller
              name="translation.en.name"
              control={control}
              defaultValue={defaultInfo?.translation?.en?.name || ""}
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.GB title="English" className="w-5" />}
                  placeholder="ชื่อโรงแรม"
                />
              )}
            />
          </Form.Item>

          <h6 className="text-gray-900 text-base my-2  font-bold  font-sans ">
            หัวใบเสร็จ /ใบกำกับภาษี
          </h6>
          <Form.Item label="ชื่อเต็มบริษัท" tooltip="จะนำไปเป็นหัวของใบเสร็จรับเงิน และเอกสารต่างๆ">
            <Controller
              name="company_name"
              control={control}
              defaultValue={defaultInfo?.company_name || ""}
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="ชื่อบริษัท / หัวใบกำกับภาษี"
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label="หมายเลขประจำตัวผู้เสียภาษี"
            tooltip="จะนำไปเป็นหัวของใบเสร็จรับเงิน และเอกสารต่างๆ"
          >
            <Controller
              name="tax_id"
              control={control}
              defaultValue={defaultInfo?.tax_id || ""}
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  placeholder="หมายเลขประจำตัวผู้เสียภาษี"
                />
              )}
            />
          </Form.Item>
        </Form>
        <Form
          layout="vertical"
          size="large"
          className="w-full lg:w-2/4 pl-2"
          // labelCol={{ span: 6 }}
          // wrapperCol={{ span: 16 }}
        >
          <h6 className="w-full text-gray-900 text-base  font-bold  font-sans my-2">
            รูปภาพอัตลักษณ์เว็บไซต์
          </h6>
          <Form.Item label="โลโก้เว็บไซต์" tooltip="จะขึ้นใน Header ของเว็บไซต์">
            <div className="flex">
              {_.isObject(defaultInfo?.logo) && (
                <div className="lg:w-1/2 flex justify-center border m-2 p-2 rounded-md">
                  <img src={defaultInfo?.logo?.url || ""} className="h-32" />
                </div>
              )}
              <ImageUpload
                images={logoImage}
                setImages={setLogoImage}
                preview_size="250"
                maxNumber={1}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  </div>
)

export default GeneralInfo
