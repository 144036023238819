/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../redux/actions"
import { HOTEL_SITE_MENU } from "../config/MenuList"

// components
import MainSidebar from "../components/common/Sidebar/MainSidebar"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"

// views
import MainInfoPage from "../views/HotelSite/HotelSiteMainInfo"
import PromotionPage from "../views/HotelSite/Promotion"
import ArticlePage from "../views/HotelSite/Article"
import CreateOrEditPromotion from "../views/HotelSite/CreateOrEditPromotion"
import CreateOrEditFacility from "../views/HotelSite/CreateOrEditFacility"
import CreateOrEditArticle from "../views/HotelSite/CreateOrEditArticle"
import PromotionView from "../views/HotelSite/PromotionView"
import FacilityView from "../views/HotelSite/FacilityView"
import ArticleView from "../views/HotelSite/ArticleView"
import FrontpageManagement from "../views/HotelSite/FrontpageManagement"
import BackgroundManagement from "../views/HotelSite/BackgroundManagement"
import GalleryManagement from "../views/HotelSite/GalleryManagement"
import Facility from "../views/HotelSite/Facility"
import Policy from "../views/HotelSite/Policy"
import LookAndFeel from "../views/HotelSite/LookAndFeel"
import UpdateFrontPage from "../views/HotelSite/UpdateFrontpage"
import FrontpageComponentsManagement from "../views/HotelSite/archive/FrontpageComponentsManagement"
import GalleyPhotoManagement from "../views/HotelSite/GalleryPhotoManagement"
import ArrangeFrontpageComponent from "../views/HotelSite/archive/ArrangeFrontpageComponent"
import EditHeader from "../views/HotelSite/EditHeader"
import EditFooter from "../views/HotelSite/EditFooter"
import EditMenu from "../views/HotelSite/EditMenu"
import FileManagement from "../views/HotelSite/FilesManagement"
import EditPaymentInfo from "../views/HotelSite/EditPaymentInfo"
import ArticleCategories from "../views/HotelSite/ArticleCategories"
import CreateOrEditCategoryArticle from "../views/HotelSite/CreateOrEditCategoryArticle"
import SEOManagement from "../views/HotelSite/SEOManagement"
import ComponentTemplate from "../views/HotelSite/ComponentTemplate"
import EditPageDescription from "../views/HotelSite/EditPageDescription"
import EditSecondDescription from "../views/HotelSite/EditSecondDescription"
import EditRoomSectionDescription from "../views/HotelSite/EditRoomSectionDescription"
import EditCoverImage from "../views/HotelSite/EditCoverImage"
import EditGallery from "../views/HotelSite/EditGallery"
import EditRecentArticleDescription from "../views/HotelSite/EditRecentArticleDescription"
import ArrangeMenu from "../views/HotelSite/ArrangeMenu"

import UserRole from "../config/UserRoles"

export default function HotelSiteLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("HotelSites : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.role === UserRole.ADMIN.toString() ||
        me?.role === UserRole.SUPER_USER.toString()
        //  && system.enable_dashboard
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <MainSidebar
        menuList={HOTEL_SITE_MENU}
        isShow={showSidebar}
        setIsShowSidebar={setIsShowSidebar}
      />
      <div className={` relative bg-gray-200 ${showSidebar ? "lg:ml-64" : ""} `}>
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/site" exact component={() => <MainInfoPage />} />
            <Route path="/site/" exact component={() => <MainInfoPage />} />
            <Route path="/site/promotion" exact component={() => <PromotionPage />} />
            <Route
              path="/site/promotion/create"
              exact
              component={() => <CreateOrEditPromotion type="create" />}
            />
            <Route path="/site/promotion/view/:id" exact component={() => <PromotionView />} />
            <Route
              path="/site/promotion/edit/:id"
              exact
              component={() => <CreateOrEditPromotion type="edit" />}
            />
            {/* ----------------------------------------Article--------------------------------------------- */}
            <Route path="/site/article" exact component={() => <ArticlePage />} />
            <Route
              path="/site/article/create"
              exact
              component={() => <CreateOrEditArticle type="create" />}
            />
            <Route path="/site/article/view/:id" exact component={() => <ArticleView />} />
            <Route
              path="/site/article/edit/:id"
              exact
              component={() => <CreateOrEditArticle type="edit" />}
            />
            {/* ------------------------------------------------------------------------------------------------------------ */}
            <Route path="/site/article-category" exact component={() => <ArticleCategories />} />
            <Route
              path="/site/article-category/edit/:id"
              exact
              component={() => <CreateOrEditCategoryArticle type="edit" />}
            />
            {/* ------------------------------------------------------------------------------------------------------------ */}
            <Route
              path="/site/facility/create"
              exact
              component={() => <CreateOrEditFacility type="create" />}
            />
            <Route path="/site/promotion/view/:id" exact component={() => <PromotionView />} />
            <Route
              path="/site/facility/edit/:id"
              exact
              component={() => <CreateOrEditFacility type="edit" />}
            />
            <Route path="/site/facility/view/:id" exact component={() => <FacilityView />} />
            <Route path="/site/facility" exact component={() => <Facility />} />
            <Route
              path="/site/frontpage-management"
              exact
              component={() => <FrontpageManagement />}
            />
            <Route path="/site/footer" exact component={() => <EditFooter />} />
            <Route path="/site/header" exact component={() => <EditHeader />} />
            <Route path="/site/policy" exact component={() => <Policy />} />
            <Route path="/site/look-and-feel" exact component={() => <LookAndFeel />} />
            <Route path="/site/gallery" exact component={() => <GalleryManagement />} />
            <Route path="/site/background" exact component={() => <BackgroundManagement />} />
            <Route
              path="/site/gallery/photo/:id"
              exact
              component={() => <GalleyPhotoManagement />}
            />{" "}
            <Route path="/site/gallery/edit/:id" exact component={() => <EditGallery />} />
            <Route path="/site/update-frontpage" exact component={() => <UpdateFrontPage />} />
            <Route path="/site/menu" exact component={() => <EditMenu />} />
            <Route path="/site/menu/arrange" exact component={() => <ArrangeMenu />} />
            <Route path="/site/file" exact component={() => <FileManagement />} />
            <Route path="/site/payment" exact component={() => <EditPaymentInfo />} />
            <Route path="/site/seo" exact component={() => <SEOManagement />} />
            <Route path="/site/page-description" exact component={() => <EditPageDescription />} />
            <Route
              path="/site/room-description"
              exact
              component={() => <EditRoomSectionDescription />}
            />{" "}
            <Route
              path="/site/recent-article-description"
              exact
              component={() => <EditRecentArticleDescription />}
            />
            <Route
              path="/site/second-description"
              exact
              component={() => <EditSecondDescription />}
            />{" "}
            <Route path="/site/cover-image" exact component={() => <EditCoverImage />} />
            {/** ARCHIEVED */}
            <Route
              path="/site/frontpage-component"
              exact
              component={() => <FrontpageComponentsManagement />}
            />
            <Route
              path="/site/frontpage-component/arrange"
              exact
              component={() => <ArrangeFrontpageComponent />}
            />
            <Route
              path="/site/frontpage-component/template"
              exact
              component={() => <ComponentTemplate />}
            />
            {/** ARCHIEVED */}
            <Redirect from="/site" to="/site" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
