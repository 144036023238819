export const HotelFacility = [
  {
    ref: "breakfast",
    nameTH: "อาหารเช้า",
    nameEn: "Breakfast",
    icon: "fas fa-coffee",
  },
  { ref: "freeWifi", nameTH: "WiFi ฟรี", nameEn: "Free WiFi", icon: "fas fa-wifi" },
  { ref: "tv", nameTH: "โทรทัศน์", nameEn: "Television", icon: "fas fa-tv" },
  { ref: "coffeeSet", nameTH: "ชุดกาแฟ", nameEn: "Coffee Set", icon: "fas fa-coffee" },
  { ref: "hairDryer", nameTH: "ไดร์เป่าผม", nameEn: "Hair Dryer", icon: "fas fa-wind" },
  { ref: "waterHeater", nameTH: "เครื่องทำน้ำอุ่น", nameEn: "Water Heater", icon: "fas fa-shower" },
  {
    ref: "airCondition",
    nameTH: "เครื่องปรับอากาศ",
    nameEn: "Air Condition",
    icon: "fas fa-temperature-low",
  },
  {
    ref: "noSmoking",
    nameTH: "ห้องปลอดบุหรี่",
    nameEn: "Non Smoking Room",
    icon: "fas fa-smoking-ban",
  },
]

export const facilityJSON = {
  breakfast: false,
  freeWifi: false,
  tv: false,
  coffeeSet: false,
  hairDryer: false,
  waterHeater: false,
  airCondition: false,
  noSmoking: false,
}

export default HotelFacility
