import {
  RELATIONSHIP_ALL,
  RELATIONSHIP_CREATE,
  RELATIONSHIP_GET,
  RELATIONSHIP_UPDATE,
  RELATIONSHIP_DELETE,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case RELATIONSHIP_ALL:
      return {
        arr: action.payload?.rows,
        isLoading: true,
      }
    case RELATIONSHIP_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case RELATIONSHIP_CREATE:
      return { isLoading: true }
    case RELATIONSHIP_UPDATE:
      return { isLoading: true }
    case RELATIONSHIP_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
