import { Checkbox, Select, Switch } from "antd"
import React, { useState } from "react"
import { SketchPicker, CompactPicker } from "react-color"
import { Controller } from "react-hook-form"
import { motion } from "framer-motion"
import { DesktopOutlined, MobileOutlined } from "@ant-design/icons"

import { WhitePanel } from "../../common/Cards"

function HeaderForm({ register, defaultValue, watch, setValue, control }) {
  const [activeTab, setActiveTab] = useState("Desktop")
  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  const buttonVariants = {
    idle: {
      opacity: 1,
      x: 0,
    },
    hover: {
      opacity: 0.8,
    },
    active: {
      opacity: 1,
      x: "-50%",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
  }

  return (
    <div>
      <div>
        <WhitePanel>
          <div className="flex justify-center mt-4 ">
            <motion.button
              className={`px-4 py-2 lg:mr-2  ml-14 mb-4 rounded-tl-md rounded-bl-md ${
                activeTab === "Desktop"
                  ? "bg-gradient-to-r from-blue-500 to-purple-600 text-white"
                  : "bg-gradient-to-r from-gray-300 to-gray-500 text-gray-800"
              }`}
              onClick={() => handleTabChange("Desktop")}
              variants={buttonVariants}
              whileTap="active"
              whileHover="hover"
              type="button"
              animate={activeTab === "Desktop" ? "active" : "idle"}
            >
              <DesktopOutlined className="lg:mr-2" /> Desktop
            </motion.button>
            <motion.button
              className={`px-4 py-2 mb-4 ml-2 rounded-tr-md rounded-br-md ${
                activeTab === "Mobile"
                  ? "bg-gradient-to-r from-orange-500 to-pink-600 text-white"
                  : "bg-gradient-to-r from-gray-300 to-gray-500 text-gray-800"
              }`}
              onClick={() => handleTabChange("Mobile")}
              variants={buttonVariants}
              whileTap="active"
              whileHover="hover"
              type="button"
              animate={activeTab === "Mobile" ? "active" : "idle"}
            >
              <MobileOutlined className="mr-2" /> Mobile
            </motion.button>
          </div>
          {activeTab === "Desktop" && (
            <div className="flex flex-wrap">
              <div className="font-semibold w-full my-1">การแสดงอักษรบน Header</div>
              <div className="w-full my-2 lg:w-1/2 px-1">
                <div className="my-2">
                  <div className="font-semibold text-sm my-1">การแสดงชื่อ</div>
                  <div>
                    <Controller
                      control={control}
                      name="header.hotelName"
                      defaultValue={defaultValue?.header?.hotelName}
                      render={(field) => (
                        <Checkbox
                          {...field}
                          onChange={(e) => field.onChange(e.target.checked)}
                          defaultChecked={field.value}
                        >
                          แสดงชื่อโรงแรม
                        </Checkbox>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="header.hotelLogo"
                      defaultValue={defaultValue?.header?.hotelName}
                      render={(field) => (
                        <Checkbox
                          {...field}
                          onChange={(e) => field.onChange(e.target.checked)}
                          defaultChecked={field.value}
                        >
                          แสดงโลโก้
                        </Checkbox>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-1">
                <div>
                  <div className="font-semibold text-sm my-1">ตำแหน่งของตัวอักษรกับรูป</div>
                  <div className="flex self-center gap-2 items-center ">
                    <div>เว้นบรรทัด</div>
                    <Controller
                      control={control}
                      defaultChecked={defaultValue?.desktop?.header?.flex}
                      name="desktop.header.flex"
                      render={(field) => <Switch {...field} defaultChecked={field.value}></Switch>}
                    />
                    <div>บรรทัดเดียวกัน</div>
                  </div>
                </div>
                <div className="my-2">
                  <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                  <Controller
                    control={control}
                    name="desktop.header.fontSize"
                    defaultValue={defaultValue?.desktop?.header?.fontSize}
                    render={(field) => (
                      <Select {...field} colorScheme="purple" style={{ width: "50%" }}>
                        <Select.Option value="normal">normal</Select.Option>
                        <Select.Option value="sm">sm</Select.Option>
                        <Select.Option value="lg">lg</Select.Option>
                        <Select.Option value="xl">xl</Select.Option>
                        <Select.Option value="2xl">2xl</Select.Option>
                        <Select.Option value="3xl">3xl</Select.Option>
                        <Select.Option value="4xl">4xl</Select.Option>
                      </Select>
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {activeTab === "Mobile" && (
            <div className="flex flex-wrap">
              <div className="font-semibold w-full my-1 pb-4">
                การแสดงอักษรบน Header ของ Mobile{" "}
              </div>
              <div className="w-full lg:w-1/2 px-1">
                <div>
                  <div className="font-semibold text-sm my-1">ตำแหน่งของตัวอักษรกับรูป</div>
                  <div className="flex self-center gap-2 items-center ">
                    <div>เว้นบรรทัด</div>
                    <Controller
                      control={control}
                      defaultChecked={defaultValue?.mobile?.header?.flex}
                      name="mobile.header.flex"
                      render={(field) => <Switch {...field} defaultChecked={field.value}></Switch>}
                    />
                    <div>บรรทัดเดียวกัน</div>
                  </div>
                </div>
                <div className="my-2">
                  <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                  <Controller
                    control={control}
                    name="mobile.header.fontSize"
                    defaultValue={defaultValue?.mobile?.header?.fontSize}
                    render={(field) => (
                      <Select {...field} colorScheme="purple" style={{ width: "50%" }}>
                        <Select.Option value="normal">normal</Select.Option>
                        <Select.Option value="sm">sm</Select.Option>
                        <Select.Option value="lg">lg</Select.Option>
                        <Select.Option value="xl">xl</Select.Option>
                        <Select.Option value="2xl">2xl</Select.Option>
                        <Select.Option value="3xl">3xl</Select.Option>
                        <Select.Option value="4xl">4xl</Select.Option>
                      </Select>
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </WhitePanel>
      </div>
      <div>
        {activeTab === "Desktop" && (
          <>
            <WhitePanel>
              <div className="font-semibold w-full my-1">สี</div>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2 px-1">
                  <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                    สีตัวอักษร{" "}
                  </h6>
                  <div className="w-full flex justify-center">
                    <Controller
                      control={control}
                      name="desktop.header.color"
                      defaultValue={defaultValue?.desktop?.header?.color}
                      render={(field) => (
                        <CompactPicker
                          color={field.value}
                          onChangeComplete={(selectedColor) => {
                            field.onChange(selectedColor.hex)
                          }}
                          width={256}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-1/2 px-1">
                  <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                    สีพื้นหลังส่วนหัว
                  </h6>
                  <div className="w-full flex justify-center">
                    <Controller
                      control={control}
                      name="desktop.header.backgroud"
                      defaultValue={defaultValue?.desktop?.header?.backgroud}
                      render={(field) => (
                        <SketchPicker
                          color={field.value}
                          onChangeComplete={(selectedColor) => {
                            field.onChange(selectedColor.hex)
                          }}
                          width={256}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </WhitePanel>
            {/* <WhitePanel >
              <div className="font-semibold w-full my-1">
                <p className="text-xl">
                  SIDEBAR
                </p>
              </div>
              <div>
                <div className="lg:pl-0 pl-0  w-full ">
                  <FormLabel fontSize="lg" className="pt-4">ข้อความ</FormLabel>
                  <label
                    className="lg:block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    ภาษาไทย
                  </label>
                  <Controller
                    control={control}
                    name="header.sidebar.th.description"
                    defaultValue={defaultValue?.header?.sidebar?.th?.description}
                    render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                  />
                  <label
                    className="pt-2  lg:block flex text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    ภาษาอังกฤษ
                  </label>
                  <Controller
                    control={control}
                    name="header.sidebar.en.description"
                    defaultValue={defaultValue?.header?.sidebar?.en?.description}
                    render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                  />
                  <div className="flex pr-96  mr-96 pt-3">
                    <Input
                      size="sm"
                      placeholder="โค้ดสี"
                      defaultValue={defaultValue?.header?.sidebar?.text_description_color?.color || '#โค้ดสีข้อความ'}
                      name="header.sidebar.text_description_color.color"
                      ref={register}
                      className="text-black"
                      style={{
                        backgroundColor: `${watch('header.sidebar.text_description_color?.color')}`
                      }}
                    />
                  </div>
                  <div className="my-2 pr-96 mr-96">
                    <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                    <Select
                      size="sm"
                      ref={register}
                      name="header.sidebar.button.description_size"
                      defaultValue={defaultValue?.header?.sidebar?.button?.description_size}
                      colorScheme="purple"
                    >
                      <option value="normal">normal</option>
                      <option value="sm">sm</option>
                      <option value="lg">lg</option>
                      <option value="xl">xl</option>
                      <option value="2xl">2xl</option>
                      <option value="3xl">3xl</option>
                      <option value="4xl">4xl</option>
                    </Select>
                  </div>
                  <p className="text-xl pt-8">
                    Button บน Sidebar
                  </p>
                  <div className="w-full lg:w-full px-1 lg:pt-0 lg:mt-0 pt-80 mt-40 ">
                    <h6 className="lg:ml-0 flex justify-center  ml-0 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                      ตัวเทียบสี {" "}
                    </h6>
                    <div className="w-full flex justify-center pb-8">
                      <Controller
                        control={control}
                        name=""
                        render={(field) => (
                          <SketchPicker
                            color={field.value}
                            onChangeComplete={(selectedColor) => {
                              field.onChange(selectedColor.hex)
                            }}
                            width={256}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="pt-4 pb-2">ปุ่ม 1</p>
                    <Input
                      ref={register}
                      placeholder="ชื่อปุ่ม ไทย"
                      defaultValue={defaultValue?.header?.sidebar?.th?.button1}
                      name="header.sidebar.th.button1"
                      size="sm" />
                    <Input
                      ref={register}
                      placeholder="ชื่อปุ่ม Eng"
                      defaultValue={defaultValue?.header?.sidebar?.en?.button1}
                      name="header.sidebar.en.button1"
                      size="sm"
                    />
                    <div className="flex pr-96  mr-96">
                      <Input
                        size="sm"
                        placeholder="โค้ดสี"
                        defaultValue={defaultValue?.header?.sidebar?.button_text_color1?.color || '#โค้ดสีตัวอักษร'}
                        name="header.sidebar.button_text_color1.color"
                        ref={register}
                        className="text-black "
                        style={{
                          backgroundColor: `${watch('header.sidebar.button_text_color1.color')}`
                        }}
                      />
                    </div>
                    <div className="flex pr-96  mr-96">
                      <Input
                        size="sm"
                        placeholder="สีปุ่ม"
                        defaultValue={defaultValue?.header?.sidebar?.button?.button_color1 || '#โค้ดสีปุ่ม'}
                        name="header.sidebar.button.button_color1"
                        ref={register}
                        className="text-black "
                        style={{
                          backgroundColor: `${watch('header.sidebar.button.button_color1')}`
                        }}
                      />
                    </div>
                    <div className="my-2 pr-96 mr-96">
                      <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                      <Select
                        size="sm"
                        ref={register}
                        name="header.sidebar.button.button_size1"
                        defaultValue={defaultValue?.header?.sidebar?.button?.button_size1}
                        colorScheme="purple"
                      >
                        <option value="normal">normal</option>
                        <option value="sm">sm</option>
                        <option value="lg">lg</option>
                        <option value="xl">xl</option>
                        <option value="2xl">2xl</option>
                        <option value="3xl">3xl</option>
                        <option value="4xl">4xl</option>
                      </Select>
                    </div>
                    <div className="px-0 py-0 pr-96 mr-96">
                      {!watch(`header.sidebar.button.useOtherLink`) === true && (
                        <>
                          <div className="text-sm font-semibold pt-2">ลิงค์ในข้อความ</div>
                          <Select
                            size="sm"
                            placeholder="ลิงค์เมนู"
                            name={`header.sidebar.button.link`}
                            defaultValue={defaultValue?.header?.sidebar?.button?.link}
                            ref={register}
                          >
                            {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                              <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                                {eachlink?.description}
                              </option>
                            ))}
                          </Select>
                        </>
                      )}
                      <div className="my-1 pb-1 pt-4">
                        <Checkbox
                          ref={register}
                          size="sm"
                          name={`header.sidebar.button.useOtherLink`}
                        >
                          ใช้ลิงค์กำหนดเอง
                        </Checkbox>
                      </div>
                      {watch(`header.sidebar.button.useOtherLink`) === true && (
                        <div className="my-2">
                          <div className="text-gray-600 text-sm">
                            กรณีที่เป็นลิงค์ในเว็บไซต์เดียวกัน เริ่มต้นที่ / แล้วต่อด้วยลิงค์ได้เลย
                            ถ้าเป็นลิงค์ภายนอก ให้เริ่มต้นด้วย http:// หรือ https://
                          </div>
                          <div className="text-sm font-semibold">ลิงค์กำหนดเอง</div>
                          <Input
                            size="sm"
                            placeholder="ชื่อเมนู"
                            name={`header.sidebar.button.otherLink`}
                            ref={register}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="pt-4 pb-2">ปุ่ม 2</p>
                    <Input
                      ref={register}
                      placeholder="ชื่อปุ่ม ไทย"
                      defaultValue={defaultValue?.header?.sidebar?.th?.button2}
                      name="header.sidebar.th.button2"
                      size="sm" />
                    <Input
                      ref={register}
                      placeholder="ชื่อปุ่ม Eng"
                      defaultValue={defaultValue?.header?.sidebar?.en?.button2}
                      name="header.sidebar.en.button2"
                      size="sm"
                    />
                    <div className="flex pr-96  mr-96">
                      <Input
                        size="sm"
                        placeholder="โค้ดสี"
                        defaultValue={defaultValue?.header?.sidebar?.button_text_color2?.color || '#โค้ดสีตัวอักษร'}
                        name="header.sidebar.button_text_color2.color"
                        ref={register}
                        className="text-black "
                        style={{
                          backgroundColor: `${watch('header.sidebar.button_text_color2.color')}`
                        }}
                      />
                    </div>
                    <div className="flex pr-96  mr-96">
                      <Input
                        size="sm"
                        placeholder="สีปุ่ม"
                        defaultValue={defaultValue?.header?.sidebar?.button?.button_color2 || '#โค้ดสีปุ่ม'}
                        name="header.sidebar.button.button_color2"
                        ref={register}
                        className="text-black"
                        style={{
                          backgroundColor: `${watch('header.sidebar.button.button_color2')}`
                        }}
                      />
                    </div>
                    <div className="my-2 pr-96 mr-96">
                      <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                      <Select
                        size="sm"
                        ref={register}
                        name="header.sidebar.button.button_size2"
                        defaultValue={defaultValue?.header?.sidebar?.button?.button_size2}
                        colorScheme="purple"
                      >
                        <option value="normal">normal</option>
                        <option value="sm">sm</option>
                        <option value="lg">lg</option>
                        <option value="xl">xl</option>
                        <option value="2xl">2xl</option>
                        <option value="3xl">3xl</option>
                        <option value="4xl">4xl</option>
                      </Select>
                    </div>
                    <div className="px-0 py-0 pr-96 mr-96">
                      {!watch(`header.sidebar.button.useOtherLink2`) === true && (
                        <>
                          <div className="text-sm font-semibold pt-2">ลิงค์ในข้อความ</div>
                          <Select
                            size="sm"
                            placeholder="ลิงค์เมนู"
                            name={`header.sidebar.button.link2`}
                            defaultValue={defaultValue?.header?.sidebar?.button?.link2}
                            ref={register}
                          >
                            {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                              <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                                {eachlink?.description}
                              </option>
                            ))}
                          </Select>
                        </>
                      )}
                      <div className="my-1 pb-1 pt-4">
                        <Checkbox
                          ref={register}
                          size="sm"
                          name={`header.sidebar.button.useOtherLink2`}
                        >
                          ใช้ลิงค์กำหนดเอง
                        </Checkbox>
                      </div>
                      {watch(`header.sidebar.button.useOtherLink2`) === true && (
                        <div className="my-2">
                          <div className="text-gray-600 text-sm">
                            กรณีที่เป็นลิงค์ในเว็บไซต์เดียวกัน เริ่มต้นที่ / แล้วต่อด้วยลิงค์ได้เลย
                            ถ้าเป็นลิงค์ภายนอก ให้เริ่มต้นด้วย http:// หรือ https://
                          </div>
                          <div className="text-sm font-semibold">ลิงค์กำหนดเอง</div>
                          <Input
                            size="sm"
                            placeholder="ชื่อเมนู"
                            name={`header.sidebar.button.otherLink2`}
                            ref={register}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="pt-4 pb-2">ปุ่ม 3</p>
                    < Input
                      ref={register}
                      placeholder="ชื่อปุ่ม ไทย"
                      defaultValue={defaultValue?.header?.sidebar?.th?.button3}
                      name="header.sidebar.th.button3"
                      size="sm" />
                    <Input
                      ref={register}
                      placeholder="ชื่อปุ่ม Eng"
                      defaultValue={defaultValue?.header?.sidebar?.en?.button3}
                      name="header.sidebar.en.button3"
                      size="sm"
                    />
                    <div className="flex pr-96  mr-96">
                      <Input
                        size="sm"
                        placeholder="โค้ดสี"
                        defaultValue={defaultValue?.header?.sidebar?.button_text_color3?.color || '#โค้ดสีตัวอักษร'}
                        name="header.sidebar.button_text_color3.color"
                        ref={register}
                        className="text-black "
                        style={{
                          backgroundColor: `${watch('header.sidebar.button_text_color3.color')}`
                        }}
                      />
                    </div>
                    <div className="flex pr-96  mr-96">
                      <Input
                        size="sm"
                        placeholder="สีปุ่ม"
                        defaultValue={defaultValue?.header?.sidebar?.button?.button_color3 || '#โค้ดสีปุ่ม'}
                        name="header.sidebar.button.button_color3"
                        ref={register}
                        className="text-black "
                        style={{
                          backgroundColor: `${watch('header.sidebar.button.button_color3')}`
                        }}
                      />
                    </div>
                  </div>
                  <div className="my-2 pr-96 mr-96">
                    <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                    <Select
                      size="sm"
                      ref={register}
                      name="header.sidebar.button.button_size3"
                      defaultValue={defaultValue?.header?.sidebar?.button?.button_size3}
                      colorScheme="purple"
                    >
                      <option value="normal">normal</option>
                      <option value="sm">sm</option>
                      <option value="lg">lg</option>
                      <option value="xl">xl</option>
                      <option value="2xl">2xl</option>
                      <option value="3xl">3xl</option>
                      <option value="4xl">4xl</option>
                    </Select>
                  </div>
                  <div className="px-0 py-0 pr-96 mr-96">
                    {!watch(`header.sidebar.button.useOtherLink3`) === true && (
                      <>
                        <div className="text-sm font-semibold pt-3">ลิงค์ในข้อความ</div>
                        <Select
                          size="sm"
                          placeholder="ลิงค์เมนู"
                          name={`header.sidebar.button.link3`}
                          defaultValue={defaultValue?.header?.sidebar?.button?.link3}
                          ref={register}
                        >
                          {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                            <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                              {eachlink?.description}
                            </option>
                          ))}
                        </Select>
                      </>
                    )}
                    <div className="my-1 pb-1 pt-4">
                      <Checkbox
                        ref={register}
                        size="sm"
                        name={`header.sidebar.button.useOtherLink3`}
                      >
                        ใช้ลิงค์กำหนดเอง
                      </Checkbox>
                    </div>
                    {watch(`header.sidebar.button.useOtherLink3`) === true && (
                      <div className="my-2">
                        <div className="text-gray-600 text-sm">
                          กรณีที่เป็นลิงค์ในเว็บไซต์เดียวกัน เริ่มต้นที่ / แล้วต่อด้วยลิงค์ได้เลย
                          ถ้าเป็นลิงค์ภายนอก ให้เริ่มต้นด้วย http:// หรือ https://
                        </div>
                        <div className="text-sm font-semibold">ลิงค์กำหนดเอง</div>
                        <Input
                          size="sm"
                          placeholder="ชื่อเมนู"
                          name={`header.sidebar.button.otherLink3`}
                          ref={register}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </WhitePanel> */}
          </>
        )}
        {activeTab === "Mobile" && (
          <WhitePanel>
            <div className="font-semibold w-full my-1">สี</div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2 px-1">
                <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                  สีตัวอักษรใน Mobile{" "}
                </h6>
                <div className="w-full flex justify-center">
                  <Controller
                    control={control}
                    name="mobile.header.color"
                    defaultValue={defaultValue?.mobile?.header?.color}
                    render={(field) => (
                      <CompactPicker
                        color={field.value}
                        onChangeComplete={(selectedColor) => {
                          field.onChange(selectedColor.hex)
                        }}
                        width={256}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-1">
                {" "}
                <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                  สีพื้นหลังส่วนหัวใน Mobile
                </h6>
                <div className="w-full flex justify-center">
                  <Controller
                    control={control}
                    name="mobile.header.backgroud"
                    defaultValue={defaultValue?.mobile?.header?.backgroud}
                    render={(field) => (
                      <SketchPicker
                        color={field.value}
                        onChangeComplete={(selectedColor) => {
                          field.onChange(selectedColor.hex)
                        }}
                        width={256}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </WhitePanel>
        )}
      </div>
    </div>
  )
}

export default HeaderForm
