import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"

import Layout from "../../../components/layout/GeneralLayout"
import * as actions from "../../../redux/actions"
import { UserForm } from "../../../components/Forms/Management"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import { Alert } from "../../../components/common/Alert"

export default function EditUser() {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const [stateError, setStateErrors] = useState()
  const user = useSelector((state) => state.user)
  const { register, handleSubmit, reset, errors, setValue } = useForm({ defaultValues: user?.data })
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(actions.userGet(params.id)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  useEffect(() => {
    if (user && user.data) {
      setValue("username", user?.data?.username)
      setValue("role", user?.data?.role)
      setValue("access", user?.data?.access)
    }

    return () => {}
  }, [user])

  const onSubmit = async (data, e) => {
    try {
      if (data.password && data.password === data.confirm_password) {
        e.preventDefault()
        dispatch(actions.userPut(params.id, data))
        reset()
        dispatch(actions.userAll(1, 10)).then(() => {
          history.push("/system/users")
        })
      } else if (data.password && data.password !== data.confirm_password) {
        setStateErrors("รหัสผ่านไม่ตรงกัน")
        setIsError(true)
      } else {
        dispatch(actions.userPut(params.id, data))
        reset()
        dispatch(actions.userAll(1, 10)).then(() => {
          history.push("/system/users")
        })
      }
    } catch (error) {
      setStateErrors(error?.toString())
      setIsError(true)
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการระบบ" title="แก้ไขผู้ใช้งาน">
      <Alert errMessage={stateError} handleOnClose={() => setIsError(false)} isError={isError} />
      <div className="flex justify-between p-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm errors={errors} register={register} user={user?.data} />
          <div className="text-center px-4 gap-0 py-3">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
