import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import _ from "lodash"

import Layout from "../../../components/layout/GeneralLayout"
import * as actions from "../../../redux/actions"
import { EmployeeForm } from "../../../components/Forms/Management"
import { Alert } from "../../../components/common/Alert"

export default function CreateEmployee() {
  const { register, handleSubmit, reset, errors, watch, setValue, control } = useForm({
    defaultValues: {
      createUser: true,
    },
  })
  const history = useHistory()
  const dispatch = useDispatch()
  const [stateError, setStateErrors] = useState()
  const [isError, setIsError] = useState(false)
  const [image, setImage] = useState([])

  const onSubmit = async (data, e) => {
    console.log("data", data)
    e.preventDefault()
    if (!_.isEmpty(image)) {
      // eslint-disable-next-line no-param-reassign
      data.image = { image: image?.[0]?.data_url }
    }

    if (data.password === data.confirm_password) {
      console.log(data)
      try {
        await dispatch(actions.createOneEmployee(data))
          .then(() => {
            dispatch(actions.getAllEmployee({ page: 1, size: 10 }))
            reset()
            history.goBack()
          })
          .catch((err) => {
            setIsError(true)
            setStateErrors(err)
          })
      } catch (error) {
        setIsError(true)
        setStateErrors(`สร้างพนักงานไม่สำเร็จ ${error.toString()}`)
      }
    } else {
      setIsError(true)
      setStateErrors("รหัสผ่านไม่ตรงกัน")
    }
  }

  return (
    <Layout categoryTitle="จัดการระบบ" title="เพิ่มพนักงาน">
      <Alert errMessage={stateError} handleOnClose={() => setIsError(false)} isError={isError} />
      <div className="flex justify-between p-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <EmployeeForm
            errors={errors}
            register={register}
            watch={watch}
            image={image}
            setImage={setImage}
            control={control}
          />
          <div className="text-center px-4 gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              สร้างพนักงาน
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
