import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Badge, Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { PassportAuth } from "../../../contexts/AuthContext"
import AppLogo from "../../../assets/img/E-Accom.png"
import { LanguageSwitcher } from "../../Buttons"

export default function MainSidebar({ isShow = true, menuList, setIsShowSidebar }) {
  const [collapseShow, setCollapseShow] = React.useState("hidden")
  const { handleSignout } = useContext(PassportAuth)
  const history = useHistory()
  const system = useSelector((state) => state.system)
  const me = useSelector((state) => state.me)
  const { i18n, t } = useTranslation()

  const onSignOut = async () => {
    await handleSignout()
    history.push("/login")
  }

  const closeCollapse = () => {
    setCollapseShow("hidden")
  }

  if (isShow === true && system && system.isLoading === true)
    return (
      <nav className="font-sans lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-no-wrap lg:overflow-hidden shadow-xl   bg-white flex flex-wrap items-center justify-between relative lg:w-64 z-30 py-4 px-6 print-disable">
        <div className="lg:flex-col lg:items-stretch lg:min-h-full lg:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <div className="flex">
            <button
              className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
            >
              <i className="fas fa-bars"></i>
            </button>
            {/* Brand */}
            <Link
              className="lg:block text-left lg:pb-2 text-gray-700 mr-0 flex whitespace-no-wrap text-base font-sans uppercase font-bold md:py-4 px-0 max-h-20"
              to="/"
            >
              <div className="flex gap-2">
                <img
                  src={system.logo ? system?.logo?.url : AppLogo}
                  className="w-auto align-middle h-6 md:h-12"
                />
              </div>
              <div className="self-center">{system?.name}</div>
            </Link>
          </div>

          {/** MOBILE MODE */}
          <div
            className={`lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ${collapseShow}`}
          >
            {/* Collapse header */}
            <div className="lg:min-w-full lg:hidden block mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="lg:block text-left lg:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold px-0"
                    to="/"
                  >
                    <div className="flex gap-2">
                      <img src={system.logo ? system?.logo?.url : AppLogo} className=" h-12" />

                      <span className="self-center">{system.name}</span>
                    </div>
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Heading */}

            <h6 className="lg:min-w-full   uppercase font-bold block pt-1 pb-4 no-underline ">
              <div className="flex gap-2 justify-between">
                <div className="self-center text-gray-600">
                  {i18n.language === "th" ? menuList.moduleNameTH : menuList.moduleNameEN}{" "}
                </div>
                <Button size="sm" colorScheme="gray" onClick={() => setIsShowSidebar(false)}>
                  <i className="fas fa-angle-double-left mr-2"></i>
                  {t("general.collapseSidebar")}
                </Button>
              </div>
            </h6>

            {/* Navigation */}

            <ul className="lg:flex-col lg:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link className={" py-3  text-sm block text-gray-800 hover:text-gray-600 "} to="/">
                  <i className={"fas fa-home mr-2 text-sm text-gray-400 "}></i> {t("system.home")}
                </Link>
              </li>

              {_.map(menuList.menuList, (eachMenuList, index) => (
                <li className="items-center" key={index}>
                  <Link
                    className={`text-sm  py-3 font-bold block ${
                      window.location.href.indexOf(
                        `/${menuList.moduleCode}${eachMenuList.link}`,
                      ) !== -1 &&
                      window.location.href.indexOf(
                        `/${menuList.moduleCode}${eachMenuList.link}/`,
                      ) === -1
                        ? "text-indigo-500 hover:text-indigo-600"
                        : "text-gray-800 hover:text-gray-600"
                    }`}
                    to={`/${menuList.moduleCode}${eachMenuList.link}`}
                    onClick={closeCollapse}
                  >
                    <i
                      className={`${eachMenuList.icon} mr-2 text-sm  ${
                        eachMenuList?.submenu ? "ml-4" : ""
                      } ${
                        window.location.href.indexOf(
                          `/${menuList.moduleCode}${eachMenuList.link}`,
                        ) !== -1 &&
                        window.location.href.indexOf(
                          `/${menuList.moduleCode}${eachMenuList.link}/`,
                        ) === -1
                          ? "opacity-75"
                          : "text-gray-400"
                      }`}
                    ></i>
                    {eachMenuList?.submenu ? "-" : ""}{" "}
                    {i18n?.language === "th" ? eachMenuList.nameTH : eachMenuList.nameEN}
                  </Link>
                </li>
              ))}
            </ul>

            <hr className="my-4 lg:min-w-full" />
            <ul className="lg:flex-col lg:min-w-full flex flex-col list-none lg:mb-4">
              <LanguageSwitcher />

              <li className="items-center">
                <div className="text-gray-800 hover:text-gray-600 text-xs py-2 font-bold block ">
                  <div className="flex py-2">
                    <i className="fas fa-user text-gray-500 mr-2 text-sm"></i>
                    <div>
                      <li className="items-center">
                        <div className="text-gray-800 hover:text-gray-600 text-sm font-bold block ">
                          {me?.employeeData?.firstname}
                        </div>
                        <div className="text-gray-800 hover:text-gray-600 text-xs  font-bold block ">
                          {me?.employeeData?.lastname}
                        </div>
                      </li>{" "}
                      <Badge colorScheme="gray" className="mb-1">
                        {me?.role}
                      </Badge>
                    </div>
                  </div>
                </div>
              </li>
              <li className="items-center">
                <div
                  className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                  onClick={() => {
                    onSignOut()
                  }}
                >
                  <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i> Sign Out
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )

  return (
    <div className="hidden md:block md:fixed  top-10 mt-6  z-20">
      <Button
        colorScheme="purple"
        rounded="none"
        roundedRight="lg"
        onClick={() => setIsShowSidebar(true)}
      >
        <i className="fas fa-angle-double-right mr-2"></i>
        <span className="text-base">{t("general.expandSidebar")}</span>
      </Button>
    </div>
  )
}
