import api from "../../../config/api"

import {
  RELATIONSHIP_ALL,
  RELATIONSHIP_CREATE,
  RELATIONSHIP_GET,
  RELATIONSHIP_UPDATE,
  RELATIONSHIP_DELETE,
} from "../types"

// faker.locale = "th"

export const getAllRelationships =
  (customerId = "") =>
  async (dispatch) => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/relationship/?customersId=${customerId}`)
      .then((res) => {
        console.log("Request Server to Get All Relationship")
        if (res.data) {
          dispatch({ type: RELATIONSHIP_ALL, payload: res.data })
        } else {
          dispatch({ type: RELATIONSHIP_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All Relationship", err)
        dispatch({ type: RELATIONSHIP_ALL, payload: null })
      })
  }

export const getOneRelationship = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/relationship/${id}`).then((res) => {
    console.log("Request Server to Get One Bed")
    if (res.data) {
      dispatch({ type: RELATIONSHIP_GET, payload: res.data })
    } else {
      dispatch({ type: RELATIONSHIP_GET, payload: null })
    }
  })
}
export const createOneRelationship = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/relationship/`, payload).then(() => {
    console.log("Request Server to Create New relationship")
    dispatch({ type: RELATIONSHIP_CREATE })
  })
}

export const editOneRelationship = (id, data) => async (dispatch) => {
  console.log(id, data)
  await api.put(`${process.env.REACT_APP_API_URL}/relationship/${id}`, data).then(() => {
    console.log("Request Server to edit relationship")
    dispatch({ type: RELATIONSHIP_UPDATE })
  })
}

export const deleteOneRelationship = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/relationship/${id}`).then(() => {
    console.log("Request Server to Delete One relationship")
    dispatch({ type: RELATIONSHIP_DELETE, payload: null })
  })
}
