/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import Layout from "../../../components/layout/BookingLayout"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import SpinnersLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import { BasicBookingInfoPanel } from "../../../components/Panels"
import { SelectedRoomList, UnAssignRoomList } from "../../../components/List"
import { Alert } from "../../../components/common/Alert"
import { KeyDepositForm } from "../../../components/Forms"
import { KeyDeposit as KeyDepositPDF } from "../../../config/pdfmake/template"

export default function KeyDeposit() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { t } = useTranslation()
  const { control, watch } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const bookingInfo = useSelector((state) => state.bookings)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const amountOfroom = _.size(bookingInfo?.list)

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOneBooking(params?.id))
        .then(() => {
          setIsLoading(true)
        })
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        })
    }

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnersLoading />
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.keyDeposit")}>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setAlertOpen(false)}
        isError={alertOpen}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>{" "}
      </div>
      <div className="p-4 w-full">
        <WhitePanel>
          <BasicBookingInfoPanel bookingInfo={bookingInfo} showPrintingPanel={false} />
          <hr />
          <div className=" w-full my-2">
            <h6 className="text-sm font-bold font-sans">{t("roomtype.room")}</h6>
            <div className="pr-4 mt-2">
              <SelectedRoomList bookingList={bookingInfo} />
              <UnAssignRoomList unAssignBookingList={bookingInfo} />
            </div>
          </div>

          <KeyDepositForm control={control} />
          <div>
            ราคารวม{" "}
            <span className="font-semibold">
              {watch("is_price_per_unit")
                ? parseFloat(watch("price")) * amountOfroom
                : parseFloat(watch("price"))}{" "}
            </span>
            บาท
          </div>
          <div className="flex justify-end">
            <Button
              size="sm"
              colorScheme="purple"
              onClick={async () => {
                const invoiceData = KeyDepositPDF({
                  bookingInfo,
                  information,
                  systemInfo: system,
                  price: parseFloat(watch("price")),
                  isPricePerUnit: watch("is_price_per_unit"),
                  roomAmount: amountOfroom,
                  remark: watch("remark"),
                })
                pdfMake.createPdf(invoiceData).open()
              }}
            >
              พิมพ์
            </Button>
          </div>
        </WhitePanel>
      </div>
    </Layout>
  )
}
