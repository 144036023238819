/* eslint-disable no-underscore-dangle */
import React from "react"
import { Controller } from "react-hook-form"
import { Select, Space } from "antd"
import _ from "lodash"
import dayjs from "dayjs"

function FBOrderAddToBookingForm({ control, defaultValue, booking }) {
  return (
    <div className="flex w-full flex-wrap">
      <div className="w-full py-1 px-1">
        <div className="text-sm my-2">การจองห้องพัก</div>
        <Controller
          control={control}
          name="booking"
          defaultValue={defaultValue?.booking}
          render={(field) => (
            <Select
              showSearch
              {...field}
              size="large"
              placeholder="เลือกหมายเลขการจอง"
              optionFilterProp="children"
              optionLabelProp="label"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                (option?.booking_no ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={_.map(booking?.arr, (eachBooking) => ({
                ...eachBooking,
                label: `Booking No. ${eachBooking?.booking_no}`,
                value: eachBooking?._id,
                start: eachBooking?.start,
                end: eachBooking?.end,
              }))}
              optionRender={(option) => (
                <Space>
                  <div>{option?.label}</div>
                  <div className="text-xs text-gray-500">
                    {option?.data?.customer?.firstname} {option?.data?.customer?.lastname}
                  </div>
                  <div className="text-sm ">
                    เข้าพัก {dayjs(option?.data?.start).format("D MMM YYYY")} -{" "}
                    {dayjs(option?.data?.end).format("D MMM YYYY")}
                  </div>
                </Space>
              )}
            />
          )}
        />
      </div>
    </div>
  )
}

export default FBOrderAddToBookingForm
