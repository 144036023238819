import React from "react"

const SliderVideoMobileTextArea = ({ watch }) => (
  <div
    className=" flex border-2 pb-0 h-[desired-height] border-gray-600 justify-center items-center w-full"
    style={{
      "@media (min-width: 1536px)": {
        width: "1440px",
        height: "500px",
      },
    }}
  >
    <div
      className={` bg-blue-500 bg-opacity-50  `}
      style={{
        marginRight: `${watch("mobile.video.textvideo.right_margin_textvideo")}px`, // Specify padding-right
        marginLeft: `${watch("mobile.video.textvideo.left_margin_textvideo")}px`,
        marginBottom: `${watch("mobile.video.textvideo.bottom_margin_textvideo")}px`,
      }}
    >
      <p
        className="flex text-center items-center text-white border-4 border-white "
        style={{
          borderColor: `rgba(255, 0, 0, ${watch("mobile.video.textvideo.opacityBorder") / 100})`,
        }}
      >
        {watch("mobile.video.translation.en.video_content")}
      </p>
    </div>
  </div>
)

export default SliderVideoMobileTextArea
