/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { Button, useToast } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"
import moment from "moment"

import Layout from "../../../components/layout/GeneralLayout"
import { ServiceOrderForm, AttachServiceForm } from "../../../components/Forms/Reservation"
import { WhitePanel } from "../../../components/common/Cards"
import * as actions from "../../../redux/actions"
import CreateOrEditCustomerModal from "../../../components/Modal/CustomerManagement/CreateOrEditCustomerModal"
import { Alert } from "../../../components/common/Alert"

export default function CreateServiceOrder() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const customers = useSelector((state) => state.customers)
  const serviceOrder = useSelector((state) => state.serviceOrder)
  const me = useSelector((state) => state.me)
  const toast = useToast()
  const hotelServices = useSelector((state) => state.hotelService)

  const { register, control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      use_one_day: serviceOrder?.use_one_day,
      start: serviceOrder?.start,
    },
  })

  const [customerOptions, setCustomerOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    const rowsData = _.map(customers?.arr, (eachRow) => ({
      label: `${eachRow.firstname} ${eachRow.lastname}`,
      value: eachRow.id,
    }))
    setCustomerOptions(rowsData)

    return () => {}
  }, [customers])

  const handleCreateCustomer = (data) => {
    dispatch(actions.createOneCustomer(data))
      .then(() => {
        dispatch(actions.getAllCustomers(1, 1000000, ""))
        setIsModalCreateCustomerOpen(false)
        toast({
          title: "สร้างลูกค้าคนใหม่สำเร็จ",
          duration: 1000,
          status: "success",
        })
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(`สร้างลูกค้าใหม่ไม่สำเร็จด้วยเหตุผล ${err?.message} กรุณาลองใหม่อีกครั้ง`)
      })
  }

  useEffect(() => {
    dispatch(actions.getAllCustomers(1, 1000000, ""))
    return () => {}
  }, [])

  const getAllServices = () => {
    dispatch(actions.getAllServices({ page: 1, size: 10000 }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((error) => {
        setIsError(true)
        setErrorMessage(error?.message)
      })
  }

  useEffect(() => {
    getAllServices()
    return () => {}
  }, [params])

  const handleCreateServiceOrder = (data) => {
    const payload = {
      start: moment(data.start).format("YYYY-MM-DD"),
      end: data.use_one_day ? "" : moment(data.end).format("YYYY-MM-DD"),
      use_one_day: data.use_one_day,
      remark: data.remark,
      customer: data.customer?.value,
      service_list: data.services,
      name: data?.name || "",
      user: me?._id,
      createMeetingRoomOrder: false,
    }

    console.log("Adding Payload", payload)

    dispatch(actions.createOneServiceOrder(payload))
      .then(() => {
        toast({
          title: "สร้างออเดอร์สำเร็จ",
          duration: 1000,
          status: "success",
        })
        history.goBack()
      })
      .catch((err) => {
        setErrorMessage(err?.message)
        setIsError(true)
      })
  }

  return (
    <Layout
      categoryTitle={t("reservation.title")}
      title={t("reservation.createServiceOrder")}
      preview
    >
      <Alert errMessage={errorMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-start px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="px-4 my-4">
        <CreateOrEditCustomerModal
          handleCreateCustomer={handleCreateCustomer}
          handleOnClose={() => {
            setIsModalCreateCustomerOpen(false)
          }}
          isOpen={modalCreateCustomerOpen}
        />
        <form onSubmit={handleSubmit(handleCreateServiceOrder)}>
          <WhitePanel>
            <ServiceOrderForm
              control={control}
              register={register}
              watch={watch}
              customers={customerOptions}
              setIsModalCreateCustomerOpen={() => setIsModalCreateCustomerOpen(true)}
              defaultValue={serviceOrder}
              isLoading={isLoading}
              setValue={setValue}
            />
            <h2 className="text-xl font-semibold my-2">{t("reservation.price.servicePrice")}</h2>
            <AttachServiceForm
              control={control}
              register={register}
              hotelServices={hotelServices}
              setValue={setValue}
              watch={watch}
              defaultValue={serviceOrder?.service_list}
            />
            <div className="my-2"></div>
          </WhitePanel>{" "}
          <div className="text-center  gap-0 py-6 fixed bottom-0 px-5 bg-white w-9/12 lg:w-2/4 flex justify-start shadow-lg rounded-md">
            <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
              {t("general.save")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
