/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Spinner } from "@chakra-ui/react"
import { useHistory, Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import {
  GeneralMainLayout as Layout,
  Alert,
  WhitePanel,
  FoodAndBeverageOrderForm,
} from "../../components"
import { appConfig as config } from "../../config"

import * as actions from "../../redux/actions"

export default function CreateFBOrder() {
  const [isLoading, setIsLoading] = useState(true)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const table = useSelector((state) => state.table)
  const me = useSelector((state) => state.me)
  const { control, handleSubmit, watch } = useForm()

  const handleCreateOrder = async (data) => {
    dispatch(actions.createOneFBOrder({ ...data, date: new Date(), user: me?._id }))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  useEffect(() => {
    dispatch(actions.getAllTable({ page: 1, size: config.maxFetchSize }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })

    return () => {}
  }, [])

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  const renderBackButton = () => (
    <div className="flex justify-between flex-wrap px-4">
      <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
        {t("general.back")}
      </Button>
    </div>
  )

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="เปิดบิลออเดอร์" preview>
      {renderAlert()}
      {renderBackButton()}
      {!isLoading ? (
        <div className="flex justify-center p-4 my-2">
          <Spinner />
        </div>
      ) : (
        <div className="p-4">
          <form onSubmit={handleSubmit(handleCreateOrder)}>
            {" "}
            <WhitePanel>
              <FoodAndBeverageOrderForm control={control} table={table} />
            </WhitePanel>{" "}
            <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
              บันทึก
            </Button>
          </form>
        </div>
      )}
    </Layout>
  )
}
