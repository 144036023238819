import React from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  FormControl,
  Input,
  Button,
  Textarea,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as actions from "../../../redux/actions"

export default function ModalServiceCreate({ isOpen, handleOnClose }) {
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleCreateService = (data) => {
    console.log("Add room ", data)
    dispatch(actions.createOneService(data)).then(() => {
      dispatch(actions.getAllServices())
      handleOnClose()
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-sans" fontSize="lg">
          {t("roomtype.createService")}
        </ModalHeader>
        <ModalCloseButton />
        <form>
          <ModalBody className="font-sans">
            <FormControl>
              <label className="text-sm font-bold">{t("roomtype.serviceName")}</label>
              <Input type="text" size="sm" ref={register} name="name" />
            </FormControl>
            <FormControl>
              <label className="text-sm font-bold">{t("roomtype.serviceDetail")}</label>
              <Textarea ref={register} size="sm" name="description" />
            </FormControl>
            <FormControl>
              <label className="text-sm font-bold">{t("roomtype.generalPrice")}</label>
              <Input type="number" size="sm" ref={register} name="price" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-end gap-2 font-sans">
              <Button
                size="sm"
                colorScheme="green"
                type="button"
                onClick={handleSubmit(handleCreateService)}
              >
                {t("general.add")}
              </Button>
              <Button size="sm" colorScheme="gray" type="button" onClick={handleOnClose}>
                {t("general.cancle")}
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
