import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { useParams, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import ModalUploadImage from "../../components/Modal/HotelSites/ModalUploadToGallery"
import * as actions from "../../redux/actions"

export default function GalleyPhotoManagement() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const gallery = useSelector((state) => state.gallery)
  const params = useParams()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(actions.getGallery(params.id)).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const handleOnDeleteImage = (imageIndex) => {
    const confirm = window.confirm("ยืนยันการลบรูปภาพนี้")
    if (confirm) {
      const tempGalleryImage = gallery.images
      setIsLoading(false)
      console.log(imageIndex)
      tempGalleryImage.splice(imageIndex, 1)
      try {
        dispatch(actions.editGallery(params?.id, { images: tempGalleryImage })).then(() => {
          dispatch(actions.getGallery(params.id)).then(() => {
            setIsLoading(true)
          })
        })
      } catch (error) {
        window.alert("ขออภัย ไม่สามารถลบรูปภาพได้")
        console.error("Cannot Remove Image", error)
        setIsLoading(true)
      }
    }
  }

  return (
    <Layout categoryTitle={`${t("site.title")} > แกลอรี่`} title={`${gallery?.name || ""}`}>
      <div className="flex gap-2 px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
        <Button
          colorScheme="purple"
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          เพิ่ม
        </Button>
      </div>
      <div className="p-4 w-full min-h-screen">
        <ModalUploadImage
          handleOnClose={() => {
            setIsModalOpen(false)
          }}
          isOpen={isModalOpen}
          galleryInfo={gallery || { images: [] }}
        />
        <div className="flex gap-2 flex-wrap ">
          {_.map(gallery?.images, (eachPhoto, index) => (
            <div
              key={index}
              className="m-4 border border-gray-300 rounded-md shadow-md flex flex-col "
            >
              <img src={eachPhoto?.image?.url} className="w-64 my-auto " />
              <div className="my-4 flex justify-center  ">
                <Button colorScheme="red" size="sm" onClick={() => handleOnDeleteImage(index)}>
                  ลบ
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
