import React from "react"

import { useSelector } from "react-redux"
import Info from "../../../version.json"
import BottomMenu from "./BottomMenu"

export default function FooterAdmin() {
  const system = useSelector((state) => state.system)
  if (system && system.isLoading === true)
    return (
      <div>
        <BottomMenu />
        <footer className="block py-4">
          <div className="container mx-auto px-4">
            <hr className="mb-4 border-b-1 border-gray-300" />
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div className="w-full md:w-4/12 px-4">
                <div className="text-sm text-gray-600 font-semibold py-1 text-center md:text-left">
                  Copyright © {new Date().getFullYear()} Prince of Songkla University by
                  <a
                    href="https://iarc.psu.ac.th/"
                    className="text-gray-600 hover:text-gray-800 text-sm font-semibold py-1"
                  >
                    {" "}
                    Intelligent Automation Research Center
                  </a>{" "}
                  <br />
                  <span className="text-xs">Ver. {Info.version}</span>
                </div>
              </div>
              {system.systemSponsor && (
                <div className="w-full md:w-8/12 px-4 flex justify-end">
                  <div className="text-sm text-gray-600 font-semibold py-1 text-center md:text-left">
                    Supported By{" "}
                    <a
                      href={system.systemSponsorURL}
                      className="text-gray-600 hover:text-gray-800 text-sm font-semibold py-1"
                    >
                      {system.systemSponsor}
                    </a>{" "}
                  </div>
                </div>
              )}
            </div>
          </div>
        </footer>
      </div>
    )

  return <></>
}
