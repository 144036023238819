/* eslint-disable no-underscore-dangle */
import React from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export default function ServiceButton({ orderInfo }) {
  const { t } = useTranslation()
  return (
    <div>
      <Link to={`/reservation/service-orders/add-service/${orderInfo?.id}`}>
        <Button size="sm" width="30" colorScheme="pink">
          {t("reservation.addService")}
        </Button>
      </Link>
    </div>
  )
}
