import { DateTime } from "luxon"
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"
import { header, defaultStyle, footer } from ".."

import {
  calculateAllPrice,
  calculatePriceWithoutDiscount,
  calculateBookingListDiscount,
  calculateFinalDiscount,
  dayAmount,
} from "../../../util"
import { THAI_BANK } from "../../../config/constant"

const dateFormat = { day: "numeric", month: "long", year: "numeric" }
dayjs.extend(buddhistEra)

export default function Invoice({ bookingInfo, information, systemInfo, era }) {
  const duration = dayAmount(bookingInfo?.start, bookingInfo?.end)

  const noRoomBookingList = _.filter(
    bookingInfo?.no_room_booking,
    (each) => each?.amount && each?.amount > 0,
  )

  const bookingList = _.map(bookingInfo?.list, (each) => ({
    ...each,
    roomtype: each?.room?.type,
    sub_type: each?.room?.sub_type,
  }))

  const bookingUnion = _.union(noRoomBookingList, bookingList)

  const bookingUnionByRoomType = _.values(_.groupBy(bookingUnion, "roomtype._id"))
  const byPriceGrouping = _.map(bookingUnionByRoomType, (eachRoomTypeList) =>
    _.values(_.groupBy(eachRoomTypeList, "price")),
  )

  const byPriceReducing = _.reduce(
    byPriceGrouping,
    (result, value, key) => {
      _.map(value, (priceGroup) => result.push(priceGroup))
      return result
    },
    [],
  )

  const byPriceAsOne1DArray = _.map(byPriceReducing, (eachGroup) => ({
    roomtype: eachGroup?.[0]?.roomtype,
    sub_type: eachGroup?.[0]?.sub_type,
    amount: _.size(eachGroup),
    totalPrice: _.sum(_.map(eachGroup, (each) => each?.price)),
    totalDiscount: _.sum(_.map(eachGroup, (each) => each?.discount)),
  }))

  console.log("By Price Grouping", byPriceAsOne1DArray)
  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "Invoice",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "Invoice",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        columns: [
          {
            text: [
              { text: "วันที่ / Date :", bold: true },
              {
                text: ` ${dayjs(bookingInfo?.createdAt).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "หมายเลขการจอง / Booking Number :", bold: true },
              { text: `  ${bookingInfo?.booking_no}`, margin: [5, 0] },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            text: [
              { text: "ลูกค้า / Guest :", bold: true },
              {
                text: `  ${bookingInfo?.customer?.firstname}  ${bookingInfo?.customer?.lastname}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
          {
            text: [{ text: "ช่วงเวลาที่พัก / Peroid of stay: ", bold: true }],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            text: [
              { text: "หมายเลขโทรศัพท์ / Tel :", bold: true },
              {
                text: `  ${bookingInfo?.customer?.tel}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
          {
            text: [
              {
                text: `  ${dayjs(bookingInfo?.start).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )} - ${dayjs(bookingInfo?.end).format(era === "be" ? "D MMMM BBBB" : "D/MM/YYYY")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            // TODO: Add Agency on Booking
            text: [
              { text: "เอเจนซี่ / Agency :", bold: true },
              {
                text: `  ${bookingInfo?.agency || "-"}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
          {
            text: [
              { text: "ผู้ใหญ่ / Adult: ", bold: true },
              {
                text: `  ${bookingInfo?.capacity?.adult || "-"}   `,
                margin: [5, 0],
              },
              { text: "  เด็ก / Child: ", bold: true },
              {
                text: `  ${bookingInfo?.capacity?.child || "-"}   `,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            // TODO: Add Agency on Booking
            text: [{ text: "", bold: true }],
          },
          {
            text: [
              { text: "การชำระเงิน / Payment :", bold: true },
              {
                text: `  ${
                  bookingInfo?.payment?.paid_amount > 0
                    ? `มัดจำ ${bookingInfo?.payment?.paid_amount} บาท`
                    : "C/L"
                }`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            text: [
              {
                text: "",
                margin: [5, 0],
              },
            ],
          },
          {
            text: [
              { text: "ผู้รับจอง / Issued By :", bold: true },
              {
                text: `  ${bookingInfo?.user?.employee?.firstname || "-"}`,
                margin: [5, 0],
              },
            ],
          },
        ],

        margin: [0, 3],
      },

      { text: "รายการห้องพัก / Accomodation", bold: true, margin: [0, 15, 0, 0] },
      {
        table: {
          headerRows: 1,
          widths: [20, "*", 60, 60, 40, 45, 50, 50, 50],
          body: [
            [
              { text: "ที่\nNo.", bold: true, alignment: "center" },
              { text: "ประเภทห้องพัก\n Room Type", bold: true, alignment: "center" },
              { text: "เช็คอิน\nCheck-in", bold: true, alignment: "center" },
              { text: "เช็คเอาท์\nCheck-out", bold: true, alignment: "center" },
              { text: "จำนวน \n Qty", bold: true, alignment: "center" },
              { text: "จำนวนคืน\nNights", bold: true, alignment: "center" },
              { text: "ราคาเต็ม\n Price", bold: true, alignment: "center" },
              { text: "ส่วนลด\n Discount", bold: true, alignment: "center" },
              { text: "ราคา\nTotal", bold: true, alignment: "center" },
            ],
            ..._.map(byPriceAsOne1DArray, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: eachList?.roomtype.translation?.th?.name || eachList?.roomtype.name || "",
                margin: [0, 2],
              },
              {
                text: dayjs(bookingInfo?.start).format(era === "be" ? "D/MM/BBBB" : "D/MM/YYYY"),
                margin: [0, 2],
              },
              {
                text: dayjs(bookingInfo?.end).format(era === "be" ? "D/MM/BBBB" : "D/MM/YYYY"),
                margin: [0, 2],
              },
              {
                text: eachList?.amount || "",
                margin: [0, 2],
              },
              {
                text: dayAmount(bookingInfo?.start, bookingInfo?.end),
                margin: [0, 2],
              },
              {
                text: eachList?.totalPrice / eachList.amount,
                margin: [0, 2],
              },
              {
                text: eachList?.totalDiscount / eachList.amount,
                margin: [0, 2],
              },
              {
                text:
                  (eachList?.totalPrice + eachList.totalDiscount) *
                  dayAmount(bookingInfo?.start, bookingInfo?.end),
                margin: [0, 2],
              },
            ]),

            [
              { text: "รวม / Total", bold: true, colSpan: 8, alignment: "right" },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: _.sum(
                  _.map(
                    byPriceAsOne1DArray,
                    (each) =>
                      (each?.totalPrice - each.totalDiscount) *
                      dayAmount(bookingInfo?.start, bookingInfo?.end),
                  ),
                ),
              },
            ],
          ],
        },
        margin: [0, 15, 0, 0],
        fontSize: 10,
      },
      !_.isEmpty(bookingInfo?.services)
        ? { text: "บริการเสริม / Services", bold: true, margin: [0, 15, 0, 0] }
        : {},
      !_.isEmpty(bookingInfo?.services)
        ? {
            table: {
              headerRows: 1,
              widths: [20, "*", "*", 40],
              body: [
                [
                  { text: "ที่\n No.", bold: true },
                  { text: "ชื่อบริการ\n Service Name", bold: true },
                  { text: "จำนวน\n Quantity", bold: true },
                  { text: "ราคา\n Price", bold: true },
                ],
                ..._.map(bookingInfo?.services, (eachList, index) => [
                  { text: index + 1, margin: [0, 2] },
                  {
                    text: eachList?.service?.translation?.th?.name || eachList?.service?.name || "",
                    margin: [0, 2],
                  },
                  { text: `${eachList?.amount} ชุด`, margin: [0, 2] },
                  { text: `${parseFloat(eachList?.price).toFixed(2)} `, margin: [0, 2] },
                ]),
                [
                  { text: "รวม", bold: true, colSpan: 3 },
                  {},
                  {},
                  {
                    text: parseFloat(
                      _.sum(_.map(bookingInfo?.services, (eachService) => eachService.price)),
                    ).toFixed(2),
                    bold: true,
                    colSpan: 1,
                  },
                ],
              ],
            },
            margin: [0, 15, 0, 0],
            fontSize: 10,
          }
        : {},
      !_.isEmpty(bookingInfo?.fb_orders)
        ? { text: "อาหารและเครื่องดื่ม / Food & Beverage", bold: true, margin: [0, 15, 0, 0] }
        : {},
      !_.isEmpty(bookingInfo?.fb_orders)
        ? {
            table: {
              headerRows: 1,
              widths: [20, "*", "*", 40],
              body: [
                [
                  { text: "ที่\n No.", bold: true },
                  { text: "วันที่ \n Date", bold: true },
                  { text: "รายละเอียด \n Detail", bold: true },
                  { text: "ราคา \n Price", bold: true },
                ],
                ..._.map(bookingInfo?.fb_orders, (eachList, index) => [
                  { text: index + 1, margin: [0, 2] },
                  {
                    text: eachList?.remark,
                    margin: [0, 2],
                  },
                  {
                    text: `${DateTime.fromISO(eachList?.date)
                      .setLocale("th")
                      .toLocaleString(dateFormat)} `,
                    margin: [0, 2],
                  },
                  { text: `${parseFloat(eachList?.price).toFixed(2)} `, margin: [0, 2] },
                ]),
                [
                  { text: "รวม", bold: true, colSpan: 3 },
                  {},
                  {},
                  {
                    text: parseFloat(
                      _.sum(_.map(bookingInfo?.fb_orders, (eachService) => eachService.price)),
                    ).toFixed(2),
                    bold: true,
                    colSpan: 1,
                  },
                ],
              ],
            },
            margin: [0, 15, 0, 0],
            fontSize: 10,
          }
        : {},

      { text: "สรุปราคา / Price Summary", bold: true, margin: [0, 5, 0, 5] },

      calculateFinalDiscount(bookingInfo) > 0
        ? {
            columns: [
              { text: "ราคารวม / Total Price", alignment: "left", bold: false },
              {
                text: `${parseFloat(calculatePriceWithoutDiscount(bookingInfo)).toFixed(2)} บาท`,
                alignment: "right",
              },
            ],
            margin: [0, 5, 0, 0],
          }
        : {},
      calculateFinalDiscount(bookingInfo) > 0
        ? {
            columns: [
              {
                text: `ส่วนลดท้ายใบเสร็จ / Discount ${
                  bookingInfo?.use_discount_percent ? `(${bookingInfo?.final_discount || 0} %)` : ""
                }`,
                alignment: "left",
                bold: false,
              },
              {
                text: `${parseFloat(calculateFinalDiscount(bookingInfo)).toFixed(2)} บาท`,
                alignment: "right",
              },
            ],
            margin: [0, 5, 0, 0],
          }
        : {},
      {
        columns: [
          { text: "ราคาสุทธิ / Grand Total Price", alignment: "left", bold: true },
          {
            text: `${parseFloat(
              calculateAllPrice(bookingInfo) - calculateFinalDiscount(bookingInfo),
            ).toFixed(2)} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      { text: "" },
      {
        columns: [
          { text: "ชำระเงินแล้ว / Paid", alignment: "left", bold: true },
          {
            text: `${parseFloat(bookingInfo?.payment?.paid_amount).toFixed(2)} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          { text: "ค้างชำระ / Balance", alignment: "left", bold: true },
          {
            text: `${parseFloat(
              calculateAllPrice(bookingInfo) -
                calculateFinalDiscount(bookingInfo) -
                bookingInfo?.payment?.paid_amount,
            ).toFixed(2)} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          { text: "กรุณามัดจำภายใน / Please Deposit with in", alignment: "left", bold: true },
          {
            text: ``,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            width: "50%",
            stack: [
              {
                text: "เงื่อนไขการจอง / Payment Condition ",
                bold: true,
                margin: [0, 3],
              },
              {
                text: `${information?.policy?.paymentCondition || "-"}`,
                fontSize: 9,
                margin: [0, 5],
              },
              {
                text: information?.transfer_info_no_vat?.disabled
                  ? "ช่องทางการชำระเงิน / Payment"
                  : "ช่องทางการชำระเงิน กรณีขอใบกำกับภาษี (Tax Invoice)",
                bold: true,
                margin: [0, 3],
              },
              {
                text: [
                  { text: "ธนาคาร / Bank: ", bold: true },
                  {
                    text: `${THAI_BANK[information?.transfer_info?.bank]?.descriptionEn} (${
                      information?.transfer_info?.bank
                    })`,
                  },
                ],
                fontSize: 9,
                margin: [0, 2],
              },
              {
                text: [
                  { text: "ชื่อบัญชี / Account Name: ", bold: true },
                  {
                    text: information?.transfer_info?.accountName || "",
                  },
                ],
                fontSize: 9,
                margin: [0, 2],
              },
              {
                text: [
                  { text: "เลขที่บัญชี / Account No.: ", bold: true },
                  {
                    text: information?.transfer_info?.accountNo || "",
                  },
                ],
                fontSize: 9,
                margin: [0, 2],
              },
              !information?.transfer_info_no_vat?.disabled
                ? {
                    text: " กรณีไม่ขอใบกำกับภาษี (Non Tax Invoice)",
                    bold: true,
                    margin: [0, 5],
                  }
                : {},
              !information?.transfer_info_no_vat?.disabled
                ? {
                    stack: [
                      {
                        text: [
                          { text: "ธนาคาร / Bank: ", bold: true },
                          {
                            text: `${
                              THAI_BANK[information?.transfer_info_no_vat?.bank]?.descriptionEn
                            } (${information?.transfer_info_no_vat?.bank})`,
                          },
                        ],
                        fontSize: 9,
                        margin: [0, 2],
                      },
                      {
                        text: [
                          { text: "ชื่อบัญชี / Account Name: ", bold: true },
                          {
                            text: information?.transfer_info_no_vat?.accountName || "",
                          },
                        ],
                        fontSize: 9,
                        margin: [0, 2],
                      },
                      {
                        text: [
                          {
                            text: "เลขที่บัญชี / Account No.: ",
                            bold: true,
                          },
                          {
                            text: information?.transfer_info_no_vat?.accountNo || "",
                          },
                        ],
                        fontSize: 9,
                        margin: [0, 2],
                      },
                    ],
                  }
                : {},
            ],
          },
          {
            width: "50%",
            stack: [
              {
                text: "ข้อตกลงและเงื่อนไขการยกเลิก / Cancellation Policy ",
                bold: true,
                margin: [0, 3],
              },
              {
                text: `${information?.policy?.canclePolicy || "-"}`,
                fontSize: 9,
                margin: [0, 5],
              },
            ],
          },
        ],
        margin: [0, 10],
      },
      {
        text: "หมายเหตุ / Remark",
        bold: true,
        margin: [0, 3],
      },
      {
        text: bookingInfo?.remark || "",
      },
      {
        columns: [
          {
            stack: [
              {
                text: "",
                margin: [0, 2],
              },
              {
                text: "",
                margin: [0, 2],
              },
              { text: "" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              { text: "ผู้มีอำนาจลงนาม / Authorized Signature" },
            ],
          },
        ],
        margin: [0, 3],
        alignment: "center",
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
