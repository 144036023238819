import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel, MaterialForm } from "../../components"
import * as actions from "../../redux/actions"

const CreateMaterial = () => {
  const { t } = useTranslation()
  const { control, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const history = useHistory()

  const onSubmit = (data) => {
    dispatch(actions.createOneMaterial(data))
      .then(() => {
        console.log("Create Material Success")
        history.goBack()
      })
      .catch((err) => {
        console.error(err)
        alert(`สร้างวัตถุดิบไม่สำเร็จ ${err?.message}`)
      })
  }
  return (
    <Layout categoryTitle={t("inventory.title")} title="สร้างวัตถุดิบ">
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline">
          กลับ
        </Button>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <WhitePanel>
            <MaterialForm control={control} onMany={false} formSize="large" />
          </WhitePanel>
          <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
            {t("general.save")}
          </Button>{" "}
        </form>
      </div>
    </Layout>
  )
}

export default CreateMaterial
