import {
  MEETING_ROOM_ALL,
  MEETING_ROOM_CREATE,
  MEETING_ROOM_DELETE,
  MEETING_ROOM_GET,
  MEETING_ROOM_UPDATE,
  MEETING_ROOM_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const meetingRoomAction = new GeneralReduxActionClass({
  allConst: MEETING_ROOM_ALL,
  createConst: MEETING_ROOM_CREATE,
  deleteConst: MEETING_ROOM_DELETE,
  errorConst: MEETING_ROOM_ERROR,
  getConst: MEETING_ROOM_GET,
  putConst: MEETING_ROOM_UPDATE,
  backendPrefix: "meeting-room",
  description: " ห้องประชุม",
})

export const getAllMeetingRoom = (query) => meetingRoomAction.getAll(query)
export const getOneMeetingRoom = (id) => meetingRoomAction.getOne(id)
export const createOneMeetingRoom = (payload) => meetingRoomAction.createOne(payload)
export const editOneMeetingRoom = (id, payload) => meetingRoomAction.editOne(id, payload)
export const deleteOneMeetingRoom = (id) => meetingRoomAction.deleteOne(id)
