const book = "จอง"
const confirm = "ยืนยันการจอง"
const checkedIn = "เช็คอินเข้าพัก"
const checkedOut = "เช็คเอาท์ออกจากห้องพัก" // เช็คเอาท์ออกแล้ว แต่ยังไม่เสร็จสมบูรณ์ อาจจะมีค้างคาอะรอยู่
const cancle = "ยกเลิก"
const pending = "รอ" // สถานะกำลังดำเนินการอะไรซักอย่างที่ไม่ได้อยู่ในที่กล่าวมา
const success = "เสร็จสิ้น" // เสร็จสิ้นอย่างสมบูรณ์

export const BookingStatus = {
  book,
  confirm,
  checkedIn,
  checkedOut,
  cancle,
  pending,
  success,
}

export const BookingStatusArray = [book, confirm, checkedIn, checkedOut, pending]

export default BookingStatus
