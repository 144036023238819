export const TypedisplayVideo = {
  VIDEO_EDIT: {
    status_code: "VIDEO_EDIT",
    description: "วิดีโอแบบแก้ไขเอง",
    descriptionEn: "custom edit",
  },
  SLIDESHOW_PICTURE_VIDEO: {
    status_code: "SLIDESHOW_PICTURE_VIDEO",
    description: "วิดีโอแบบสไลด์โชว",
    descriptionEn: "slideshow video",
  },
}

export default TypedisplayVideo
