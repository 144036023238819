import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { slipHeader, slipDefaultStyle, slipFooter } from ".."
import { currencyFormatter } from "../../../util"
import { MeetingRoomTimeSlot } from "../../constant"

dayjs.extend(buddhistEra)

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 */

export default function OrderNoVatSlip(serviceOrder, information, systemInfo, cashierName) {
  const meetingRoomOrder =
    _.sum(_.map(serviceOrder?.meeting_room_orders, (eachOrder) => eachOrder?.price)) || 0

  const servicePrice =
    _.sum(_.map(serviceOrder?.service_list, (eachServices) => eachServices?.price)) || 0
  const fbPrice = _.sum(_.map(serviceOrder?.fb_orders, (eachServices) => eachServices?.price)) || 0

  const allPrice = meetingRoomOrder + servicePrice + fbPrice - serviceOrder?.final_discount
  const priceBeforeFinalDiscount = allPrice + (serviceOrder?.final_discount || 0)

  return {
    // pageSize: "RA4",
    pageSize: {
      width: 200,
      height: 1000,
    },
    pageOrientation: "portrait",
    pageMargins: [10, 70, 10, 100],
    header: slipHeader(information),
    info: {
      title: "ใบเสร็จรับเงิน",
    },
    defaultStyle: slipDefaultStyle,

    content: [
      {
        text: `ใบเสร็จรับเงิน (Reciept)`,
        fontSize: 12,
        bold: true,
        margin: [0, 2],
        alignment: "center",
      },
      {
        text: "-------------------------------",
        margin: [0, 2],
        alignment: "center",
      },
      {
        text: [
          { text: "เลขที่ใบเสร็จ / Recieipt No.", bold: true },
          {
            text: `${serviceOrder?.order_no}`,
            margin: [5, 0],
          },
        ],
        alignment: "left",
        margin: [0, 3],
      },
      {
        text: [
          { text: "วันที่ / Date  ", bold: true },
          {
            text: dayjs()?.format("D MMM YYYY เวลา HH.mm น."),
            margin: [5, 0],
          },
        ],
        alignment: "left",
        margin: [0, 3],
      },

      {
        text: [
          { text: "ลูกค้า / Guest", bold: true },
          {
            text: `  ${serviceOrder?.customer?.firstname || "-"}  ${
              serviceOrder?.customer?.lastname || ""
            }`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
        alignment: "left",
      },
      {
        text: [
          { text: "แคชเชียร์ / Cashier", bold: true },
          {
            text: `  ${cashierName || "-"}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
        alignment: "left",
      },
      {
        text: "-------------------------------",
        margin: [0, 2],
        alignment: "center",
      },
      {
        text: "รายการบริการ / Service Orders",
        fontSize: 11,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: "center",
      },
      {
        table: {
          headerRows: 1,
          widths: [20, "*", 40],

          body: [
            [
              { text: "No.", bold: true, border: [false, false, false, false] },
              { text: "รายการ / Items", bold: true, border: [false, false, false, false] },
              { text: "ราคา / Price", bold: true, border: [false, false, false, false] },
            ],
            ..._.map(serviceOrder?.meeting_room_orders, (eachList, index) => [
              {
                text: index + 1 + _.size(serviceOrder?.list),
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: `การใช้ ${eachList?.meeting_room?.translation?.th?.name || eachList?.name} ${
                  eachList?.meeting_room?.use_slot_price
                    ? `ช่วง ${MeetingRoomTimeSlot[eachList?.timeslot]?.description} วันที่ ${dayjs(
                        eachList?.date,
                      )?.format("D MMM BBBB")}`
                    : `ตั้งแต่วันที่ ${dayjs(eachList?.start)?.format(
                        "D MMM BBBB เวลา HH:mm",
                      )} ถึงวันที่ ${dayjs(eachList?.end)?.format(
                        "D MMM BBBB เวลา HH:mm",
                      )} คิดเป็นเวลา  ${dayjs(eachList?.end).diff(
                        dayjs(eachList?.start),
                        "hour",
                      )}  ชม. ${
                        dayjs(eachList?.end).diff(dayjs(eachList?.start), "minute") % 60
                      } นาที `
                }`,
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
                border: [false, false, false, false],
              },
            ]),
            ..._.map(serviceOrder?.service_list, (eachList, index) => [
              {
                text:
                  index +
                  1 +
                  _.size(serviceOrder?.list) +
                  _.size(serviceOrder?.meeting_room_orders),
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: `${
                  eachList?.service?.translation?.th?.name || eachList?.service?.name || ""
                } x ${currencyFormatter.format(eachList?.amount)}`,
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
                border: [false, false, false, false],
              },
            ]),
            ..._.map(serviceOrder?.fb_orders, (eachList, index) => [
              {
                text:
                  index +
                  1 +
                  _.size(serviceOrder?.list) +
                  _.size(serviceOrder?.services) +
                  _.size(serviceOrder?.meeting_room_orders),
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: `${eachList?.remark || ""} (${dayjs(eachList?.date).format("D MMM BBBB")})`,
                margin: [0, 2],
                border: [false, false, false, false],
              },

              {
                text: currencyFormatter.format(parseFloat(eachList?.price)),
                margin: [0, 2],
                alignment: "right",
                border: [false, false, false, false],
              },
            ]),
            serviceOrder?.final_discount > 0
              ? [
                  {
                    stack: [
                      { text: "ราคาก่อนรวมส่วนลด", margin: [0, 2] },
                      { text: "ส่วนลดท้ายใบเสร็จ", margin: [0, 2] },
                    ],
                    colSpan: 2,
                    border: [false, false, false, false],
                  },
                  {},

                  {
                    stack: [
                      {
                        text: currencyFormatter.format(priceBeforeFinalDiscount),
                        margin: [0, 2],
                        alignment: "right",
                        border: [false, false, false, false],
                      },
                      {
                        text: currencyFormatter.format(serviceOrder?.final_discount),
                        margin: [0, 2],
                        alignment: "right",
                        border: [false, false, false, false],
                      },
                    ],
                  },
                ]
              : [
                  {
                    text: "",
                    border: [false, false, false, false],
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
            [
              {
                text: `รวมเป็นเงิน `,
                colSpan: 2,
                bold: true,
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {},

              {
                text: currencyFormatter.format(allPrice),
                bold: true,
                alignment: "right",
                border: [false, false, false, false],
              },
            ],
            serviceOrder?.payment?.paid_amount >= allPrice
              ? [
                  {
                    text: "",
                    border: [false, false, false, false],
                    colSpan: 3,
                  },
                  {},
                  {},
                ]
              : [
                  {
                    stack: [
                      { text: "มัดจำ", margin: [0, 2] },
                      { text: "ค้างชำระ", margin: [0, 2] },
                    ],
                    border: [false, false, false, false],
                    colSpan: 2,
                  },
                  {},
                  {
                    stack: [
                      {
                        text: currencyFormatter.format(serviceOrder?.payment?.paid_amount),
                        margin: [0, 2],
                        alignment: "right",
                      },
                      {
                        text: currencyFormatter.format(
                          allPrice - serviceOrder?.payment?.paid_amount,
                        ),
                        margin: [0, 2],
                        alignment: "right",
                      },
                    ],
                    border: [false, false, false, false],
                  },
                ],
          ],
        },

        margin: [0, 15, 0, 0],
        fontSize: 10,
      },
      ...slipFooter(),
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
