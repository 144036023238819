/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, Link } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { CSVLink } from "react-csv"

import { DateTime } from "luxon"
import _ from "lodash"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/BookingLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"
import { selectRoomTypeColor, MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../../util"

export default function ExportReservation() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bookings = useSelector((state) => state.bookings)
  const roomTypes = useSelector((state) => state.roomTypes)
  const history = useHistory()
  const logs = useSelector((state) => state.logs)
  const [selectedRoomType, setSelectedRoomType] = useState("")

  const [startDate, setStartDate] = useState(
    DateTime.now().startOf("month").minus({ days: 7 }).toISODate(),
  )
  const [endDate, setEndDate] = useState(
    DateTime.now().startOf("month").plus({ months: 2 }).toISODate(),
  )
  const [exportBookingEvent, setExportBookingEvent] = useState([])

  const [isShowAlert, setIsShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const generateCSVData = (bookingData) => {
    try {
      const tableHead = [
        [
          t("general.no"),
          t("reservation.bookingNo"),
          t("crm.customer"),
          t("crm.tel"),
          t("reservation.checkinDate"),
          t("reservation.checkoutDate"),
          t("roomtype.room"),
          t("reservation.otherService"),
          t("reservation.totalPrice"),
          t("reservation.bookingStatus"),
          t("reservation.paymentStatus"),
          t("reservation.remark"),
        ],
      ]
      const tableData = _.map(bookingData, (eachBooking, index) => [
        index + 1,
        eachBooking?.booking_no || "",
        `${eachBooking?.customer?.firstname || ""} ${eachBooking?.customer?.lastname || ""}`,
        `${eachBooking?.customer?.tel || ""}`,
        DateTime.fromISO(eachBooking?.start)
          .setLocale("th")
          .toLocaleString({ day: "numeric", month: "short" }),
        DateTime.fromISO(eachBooking?.end)
          .setLocale("th")
          .toLocaleString({ day: "numeric", month: "short" }),
        _.map(
          eachBooking?.list,
          (bookingList) =>
            `${bookingList?.room?.type?.name || ""} -  ${bookingList?.room?.name || "-"}  ราคา ${
              bookingList?.price || "0"
            } บาท \n `,
        ),
        _.map(
          eachBooking?.services,
          (eachService) => `${eachService?.name} ราคา  ${eachService?.price} บาท \n`,
        ),
        eachBooking?.price || "0",
        eachBooking?.status || "",
        eachBooking?.paymentStatus || "",
        eachBooking?.remark || "",
      ])
      const resultData = _.concat(tableHead, tableData)
      // console.log("Result Data", resultData)
      return resultData
    } catch (error) {
      console.error(error)
      return []
    }
  }

  const getAllBookingAndLogs = () => {
    dispatch(
      actions.getAllBooking({
        start: startDate,
        end: endDate,
        size: "",
        roomType: selectedRoomType,
        orderBy: "asc",
        orderByField: "start",
      }),
    )
      .then(() => {
        dispatch(
          actions.getAlllog({
            page: 1,
            start: startDate,
            end: endDate,
            roomType: selectedRoomType,
            orderBy: "asc",
            orderByField: "start",
          }),
        ).then(() => {
          setIsLoading(true)
        })
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsShowAlert(true)
      })
  }

  useEffect(() => {
    getAllBookingAndLogs()
    return () => {}
  }, [startDate, endDate, selectedRoomType])

  useEffect(() => {
    dispatch(actions.getAllRoomTypes())
    return () => {}
  }, [])

  useEffect(() => {
    if (bookings?.arr || logs?.arr) {
      const tempCalendarEvents = generateCSVData(_.union(bookings?.arr, logs?.arr))
      // console.log("Calendar Events", tempCalendarEvents)
      setExportBookingEvent(tempCalendarEvents)
    }
    return () => {}
  }, [bookings, logs])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const renderOldService = () => (
    <div className="p-4 w-full">
      <div>
        <WhitePanel>
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            {t("roomtype.roomType")}
            <p className="text-xs font-normal">{t("reservation.selectToExport")}</p>
          </h6>
          <div className="grid grid-cols-3">
            <div className="flex gap-2">
              <div className="rounded-full w-4 h-4 self-center bg-purple-600"></div>
              <div
                className={`${
                  selectedRoomType === "" ? "text-gray-900 font-bold " : "text-gray-500"
                } hover:text-gray-900 hover:font-bold cursor-pointer`}
                onClick={() => {
                  setSelectedRoomType("")
                }}
              >
                {t("roomtype.allCategory")}
              </div>
            </div>
            {_.map(roomTypes?.arr, (eachRoomType, index) => (
              <div key={index} className="flex gap-2">
                <div
                  className="rounded-full w-4 h-4 self-center"
                  style={{ backgroundColor: selectRoomTypeColor(eachRoomType?.id) }}
                ></div>
                <div
                  onClick={() => {
                    setSelectedRoomType(eachRoomType?.id)
                  }}
                  className={`${
                    selectedRoomType === eachRoomType?.id
                      ? "text-gray-900 font-bold"
                      : selectedRoomType === ""
                      ? "text-gray-800"
                      : "text-gray-500"
                  } hover:text-gray-900 hover:font-bold cursor-pointer`}
                >
                  {eachRoomType?.name}
                </div>
              </div>
            ))}
          </div>
        </WhitePanel>
      </div>
      <div>
        <WhitePanel>
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            {t("reservation.duration")}
          </h6>
          <div className="my-2 flex justify-between ">
            <div className="w-full my-1 md:w-2/6 min-w-min-content border p-1 ">
              <div> {t("reservation.beginDate")} </div>
              <DayPickerInput
                onDayChange={setStartDate}
                value={startDate}
                formatDate={(date) => dayjs(date)?.format("D MMM YYYY")}
                dayPickerProps={{
                  locale: "th",
                  months: MONTHS,
                  weekdaysLong: WEEKDAYS_LONG,
                  weekdaysShort: WEEKDAYS_SHORT,
                }}
              />
            </div>{" "}
            <div className="w-full my-1 md:w-2/6 min-w-min-content border p-1 ">
              <div>{t("reservation.untilDate")} </div>
              <DayPickerInput
                onDayChange={setEndDate}
                value={endDate}
                formatDate={(date) => dayjs(date)?.format("D MMM YYYY")}
                dayPickerProps={{
                  locale: "th",
                  months: MONTHS,
                  weekdaysLong: WEEKDAYS_LONG,
                  weekdaysShort: WEEKDAYS_SHORT,
                }}
              />
            </div>
          </div>
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            {t("reservation.totalAmount")} {_.size(exportBookingEvent) - 1}{" "}
            {t("reservation.transaction")}
          </h6>

          <div className="my-2">
            <CSVLink
              data={exportBookingEvent}
              filename={`${dayjs().format("BOOKING-YYYYMMDDHHmm")}.csv`}
            >
              <Button
                className="mx-2 my-1 self-center"
                colorScheme="green"
                size="sm"
                variant="solid"
              >
                Export CSV
              </Button>
            </CSVLink>
          </div>
        </WhitePanel>
      </div>
    </div>
  )

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.exportCSV")}>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setIsShowAlert(false)}
        isError={isShowAlert}
      />
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="px-4 my-2">
        <WhitePanel>
          <div className="text-center">
            เราได้ย้ายระบบการสรุปรายงานห้องพัก รวมถึงรายงานอื่นๆ ไปที่เมนู <b>แดชบอร์ดและรายงาน</b>{" "}
            <br />
            พบกับรายงานที่ละเอียด และตอบสนองการใช้งานที่ดียิ่งขึ้น
          </div>

          <div className="flex justify-center my-4">
            <Link to={`/history`}>
              <Button colorScheme="purple">ไปที่แดชบอร์ดและรายงาน</Button>
            </Link>
          </div>
        </WhitePanel>
      </div>
    </Layout>
  )
}
