import React from "react"
import PropTypes from "prop-types"

import PaymentButton from "./PaymentButton"
import DeleteButton from "./DeleteButton"
import EditOrderButton from "./EditOrderButton"
import PaymentSlipUploadButton from "./PaymentSlipUploadButton"
import ServiceButton from "./ServiceButton"
import SuccessButton from "./SuccessButton"

export default function ServiceActionButtonList({
  eachOrder,
  place = "table",
  disableAll = false,
  handleEditOrder = () => {},
  handleDeleteOrder = () => {},
}) {
  const propList = { orderInfo: eachOrder, handleEditOrder, handleDeleteOrder }
  if (disableAll) return <div></div>

  if (place === "modal") {
    return (
      <div className="flex gap-1 justify-center">
        <PaymentButton {...propList} />
        <ServiceButton {...propList} />
        <SuccessButton {...propList} />
        <EditOrderButton {...propList} />
        <DeleteButton {...propList} />
      </div>
    )
  }

  if (place === "info") {
    return (
      <div className="flex gap-1 justify-center overflow-x-auto">
        <PaymentButton {...propList} />
        <ServiceButton {...propList} />
        {/* <PaymentSlipUploadButton {...propList} /> */}
        <SuccessButton {...propList} />
        <EditOrderButton {...propList} />
        <DeleteButton {...propList} />
      </div>
    )
  }

  return (
    <div className="flex gap-1 justify-center overflow-x-auto">
      <SuccessButton {...propList} />
      <EditOrderButton {...propList} />
      <DeleteButton {...propList} />
    </div>
  )
}

ServiceActionButtonList.propTypes = {
  eachOrder: PropTypes.object,
  place: PropTypes.string,
  disableAll: PropTypes.bool,
  handleEditOrder: PropTypes.func,
  handleDeleteOrder: PropTypes.func,
}
