/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import THBText from "thai-baht-text"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"
import { PaymentMethod } from "../../constant"

dayjs.extend(buddhistEra)

export default function PaymentTransactionReport({
  transactions,
  startDate,
  endDate,
  information,
  systemInfo,
  date,
  selectedShift,
}) {
  const renderDateShow = () => {
    if (!startDate && !endDate) {
      return dayjs(date).format("D MMMM BBBB")
    }

    if (selectedShift) {
      const foundTimeShift = _.find(
        systemInfo?.setting?.timeshifts,
        (each) => each?._id === selectedShift,
      )

      const startTime = dayjs(date)
        .hour(foundTimeShift?.startTime?.hour)
        .minute(foundTimeShift?.startTime?.minute)

      const endTime = dayjs(date)
        .hour(foundTimeShift?.endTime?.hour)
        .add(foundTimeShift?.endTime?.hour - foundTimeShift?.startTime?.hour >= 0 ? 0 : 1, "day")
        .minute(foundTimeShift?.endTime?.minute)

      return `${foundTimeShift?.title || ""} ${startTime.format(
        "D MMM BBBB HH:mm",
      )} - ${endTime.format("D MMM BBBB HH:mm")}`
    }

    return `${dayjs(startDate)?.format("D MMMM BBBB")} - ${dayjs(endDate)?.format("D MMMM BBBB")}`
  }

  const findNumberOnPaymentMethod = (record, paymentType) => {
    if (record?.payment_method === paymentType?.status_code) {
      return record?.price
    }
    return 0
  }
  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "ใบรายงานการชำระเงิน",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "ใบรายงานการชำระเงิน / Payment Transaction Report ",
        fontSize: 14,
        bold: true,
        margin: [0, 0],
        alignment: "center",
      },
      {
        text: "ใบปิดรอบกะ และใบส่งเงินตามรอบบิล",
        fontSize: 13,
        bold: true,
        margin: [0, 10],
        alignment: "center",
      },
      {
        columns: [
          {
            text: [
              { text: "ช่วงเวลา", bold: true },
              {
                text: `  ${renderDateShow()}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 0],
          },
          "",
        ],
      },
      {
        columns: [
          {
            text: [
              { text: "ข้อมูล ณ วันที่", bold: true },
              {
                text: `  ${dayjs().format("D MMMM BBBB HH:mm")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2, 0, 10],
          },
          "",
        ],
      },
      {
        table: {
          headerRows: 1,
          widths: [
            10,
            43,
            40,
            40,
            "*",
            50,
            40,
            40,
            40,
            ..._.map(PaymentMethod, () => 35),
            35,
            40,
            40,
          ],
          body: [
            [
              { text: "ที่", bold: true },
              { text: "วันที่", bold: true },
              { text: "หมายเลขการจอง", bold: true },
              { text: "เบอร์ห้อง", bold: true },
              { text: "ลูกค้า", bold: true },
              { text: "วันที่เข้า", bold: true },
              { text: "เรทห้อง", bold: true },
              { text: "ราคารวม", bold: true },
              { text: "สินค้า", bold: true },
              ..._.map(PaymentMethod, (each) => ({
                text: each.description,
                bold: true,
                fontSize: 9,
              })),
              { text: "ไม่ระบุ", bold: true },
              { text: "รวม", bold: true },
              { text: "ผู้ดำเนินการ", bold: true },
            ],
            ..._.map(transactions, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${dayjs(eachList?.createdAt).format("D MMM BB")} \n ${dayjs(
                  eachList?.createdAt,
                ).format("HH:mm")}`,
                margin: [0, 2],
                fontSize: 9,
              },
              {
                text: eachList?.booking?.booking_no,
              },
              {
                text: "",
              },
              {
                text: `${eachList?.booking?.customer?.firstname || "-"} ${
                  eachList?.booking?.customer?.lastname || ""
                }`,
              },
              {
                text: eachList?.booking?.checkin?.timestamp
                  ? `${dayjs(eachList?.booking?.checkin?.timestamp).format("dd D/MM/YY HH:mm")}`
                  : "-",
              },
              {
                text: _.map(
                  _.uniqBy(eachList?.booking?.list, "price"),
                  (eachBookingList) => `${eachBookingList?.price}, `,
                ),
              },
              {
                text: eachList?.booking?.price,
              },
              {
                text: _.sum(
                  _.map(eachList?.booking?.services, (each) => each?.price * each?.amount),
                ),
              },
              ..._.map(PaymentMethod, (each) => ({
                text: findNumberOnPaymentMethod(eachList, each),
              })),
              {
                text: findNumberOnPaymentMethod(eachList, {}),
              },
              {
                text: currencyFormatter.format(eachList?.price) || "",
              },
              {
                text: `${eachList?.user?.employee?.firstname || "-"}`,
                fontSize: 9,
              },
            ]),
            [
              { text: "รวม", colSpan: 7, bold: true },
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: currencyFormatter.format(
                  _.sum(_.map(transactions, (each) => each?.booking?.price || 0)),
                ),
                fontSize: 9,
                bold: true,
              },
              {
                text: currencyFormatter.format(
                  _.sum(
                    _.map(transactions, (eachList) =>
                      _.sum(
                        _.map(
                          eachList?.booking?.services,
                          (eachSevice) => eachSevice?.price * eachSevice?.amount,
                        ),
                      ),
                    ),
                  ),
                ),
                fontSize: 9,
                bold: true,
              },
              ..._.map(PaymentMethod, (each) => ({
                text: currencyFormatter.format(
                  _.sum(
                    _.map(transactions, (eachTransaction) =>
                      findNumberOnPaymentMethod(eachTransaction, each),
                    ),
                  ),
                ),
                fontSize: 9,
                bold: true,
              })),
              {
                text: currencyFormatter.format(
                  _.sum(
                    _.map(transactions, (eachTransaction) =>
                      findNumberOnPaymentMethod(eachTransaction, {}),
                    ),
                  ),
                ),
                fontSize: 9,
                bold: true,
              },
              {
                text:
                  currencyFormatter.format(
                    _.sum(_.map(transactions, (eachTransaction) => eachTransaction?.price)),
                  ) || "",
                bold: true,
                fontSize: 9,
              },
              {
                text: "",
              },
            ],
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
        pageBreak: "after",
      },

      {
        text: "ใบปิดรอบกะ และใบส่งเงินตามรอบบิล",
        fontSize: 13,
        bold: true,

        alignment: "center",
      },
      {
        text: [
          { text: "ช่วงเวลา", bold: true },
          {
            text: `  ${renderDateShow()}`,
            margin: [5, 0],
          },
        ],
        fontSize: 13,
        bold: true,
        alignment: "center",
        margin: [0, 10],
      },
      {
        columns: [
          {
            width: "30%",
            stack: [
              {
                text: "การชำระเงินตามช่องทางต่าง ๆ",
                fontSize: 12,
                bold: true,
              },
              {
                table: {
                  headerRows: 1,
                  widths: ["50%", "40%", "10%"],
                  body: [
                    ..._.map(
                      _.filter(
                        PaymentMethod,
                        (each) => each?.status_code !== PaymentMethod.INVOICE.status_code,
                      ),
                      (eachMethod) => [
                        { text: eachMethod?.description, border: [false, false, false, false] },
                        {
                          text: currencyFormatter.format(
                            _.sum(
                              _.map(transactions, (eachTransaction) =>
                                findNumberOnPaymentMethod(eachTransaction, eachMethod),
                              ),
                            ),
                          ),
                          alignment: "right",
                          border: [false, false, false, false],
                        },
                        { text: "บาท", border: [false, false, false, false] },
                      ],
                    ),
                    [
                      {
                        text: "",
                        border: [false, false, false, false],
                        margin: [0, 10],
                        colSpan: 3,
                      },
                      {},
                      {},
                    ],
                    [
                      {
                        text: "รวมรายรับ (ไม่รวมยอดในเงินสด)",
                        border: [false, false, false, false],
                      },
                      {
                        text:
                          currencyFormatter.format(
                            _.sum(
                              _.map(
                                _.filter(
                                  transactions,
                                  (each) =>
                                    each?.payment_method !== PaymentMethod.CASH.status_code &&
                                    each?.payment_method !== PaymentMethod.INVOICE.status_code,
                                ),
                                (eachTransaction) => eachTransaction?.price,
                              ),
                            ),
                          ) || "",
                        alignment: "right",
                        border: [false, false, false, false],
                      },
                      { text: "บาท", border: [false, false, false, false] },
                    ],
                    [
                      {
                        text: "รวมรายรับ",
                        bold: true,
                        border: [false, false, false, false],
                      },
                      {
                        text:
                          currencyFormatter.format(
                            _.sum(
                              _.map(
                                _.filter(
                                  transactions,
                                  (each) =>
                                    each?.payment_method !== PaymentMethod.INVOICE.status_code,
                                ),
                                (eachTransaction) => eachTransaction?.price,
                              ),
                            ),
                          ) || "",
                        alignment: "right",
                        border: [false, false, false, false],
                      },
                      { text: "บาท", border: [false, false, false, false] },
                    ],
                    [
                      {
                        text: "รวมเงินรับหนี้ทั้งหมด",
                        border: [false, false, false, false],
                      },
                      {
                        text:
                          currencyFormatter.format(
                            _.sum(
                              _.map(
                                _.filter(
                                  transactions,
                                  (each) =>
                                    each?.payment_method === PaymentMethod.INVOICE.status_code,
                                ),
                                (eachTransaction) => eachTransaction?.price,
                              ),
                            ),
                          ) || "",
                        alignment: "right",
                        border: [false, false, false, false],
                      },
                      { text: "บาท", border: [false, false, false, false] },
                    ],
                  ],
                },
                margin: [0, 10, 0, 10],
              },
            ],
          },
          {
            width: "30%",
            margin: [20, 0, 0, 10],
            stack: [
              {
                text: "เงินที่ต้องส่งบัญชี",
                fontSize: 12,
                bold: true,
                alignment: "center",
              },
              {
                text: [
                  {
                    text:
                      currencyFormatter.format(
                        _.sum(
                          _.map(
                            _.filter(
                              transactions,
                              (each) => each?.payment_method !== PaymentMethod.INVOICE.status_code,
                            ),
                            (eachTransaction) => eachTransaction?.price,
                          ),
                        ),
                      ) || "",
                    fontSize: 15,
                  },
                  {
                    text: "  บาท",
                    fontSize: 12,
                    margin: [20, 0, 0, 0],
                  },
                ],
                fontSize: 15,
                margin: [0, 10, 0, 10],
                bold: true,
                alignment: "center",
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ส่งกะ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้รับกะ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
