import React from "react"

export default function Dot({ color = "blue", word }) {
  return (
    <div className="flex gap-1 align-middle">
      <div className={`rounded-full w-3 h-3 bg-${color}-500  mt-1`}></div>
      <p className="text-sm font-semibold whitespace-no-wrap "> {word} </p>
    </div>
  )
}
