import { INFO_GET, INFO_CREATE, INFO_PUT } from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case INFO_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case INFO_CREATE:
      return { isLoading: true }
    case INFO_PUT:
      return { isLoading: true }
    default:
      return state
  }
}
