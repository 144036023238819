/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { Input, Table } from "antd"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Alert from "../../components/common/Alert/AlertDialog"
import * as actions from "../../redux/actions"

export default function ArticleCategories() {
  const { i18n } = useTranslation()
  const articleCategories = useSelector((state) => state.articleCategories)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const { control, handleSubmit } = useForm()
  const dispatch = useDispatch()

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    dispatch(actions.getAllArticleCategory({ page, size: 10 }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
    return () => {}
  }, [page])

  const handleCreateArticleCategory = (data) => {
    dispatch(actions.createOneArticleCategory(data))
      .then(() => {
        dispatch(actions.getAllArticleCategory({ page, size: 10 }))
          .then(() => {
            setIsLoading(true)
            onClose()
          })
          .catch((err) => {
            setErrMessage(err)
            setIsError(true)
          })
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  const handleDeleteArticle = (articleCategoryId) => {
    const confirm = window.confirm("ยืนยันการลบหมวดหมู่นี้")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOneArticleCategory(articleCategoryId))
        .then(() => {
          dispatch(actions.getAllArticleCategory({ page, size: 10 }))
            .then(() => {
              setIsLoading(true)
            })
            .catch((err) => {
              setErrMessage(err)
              setIsError(true)
            })
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  const renderForm = (defaultValue = {}) => (
    <ModalBody>
      <div className="flex flex-col my-2">
        <label className="text-sm">ชื่อหมวดหมู่บทความ (ภาษาไทย)</label>
        <Controller
          name="translation.th.name"
          control={control}
          defaultValue={defaultValue.translation?.th?.name || ""}
          render={(field) => <Input {...field} placeholder="ชื่อหมวดหมู่บทความ" />}
        />
      </div>
      <div className="flex flex-col my-2">
        <label className="text-sm">ชื่อหมวดหมู่บทความ (ภาษาอังกฤษ)</label>
        <Controller
          name="translation.en.name"
          control={control}
          defaultValue={defaultValue.translation?.en?.name || ""}
          render={(field) => <Input {...field} placeholder="ชื่อหมวดหมู่บทความ" />}
        />
      </div>
    </ModalBody>
  )

  const renderAddModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(handleCreateArticleCategory)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            เพิ่มหมวดหมู่บทความ
            <ModalCloseButton />
          </ModalHeader>
          {renderForm()}
          <div className="flex justify-end gap-2 p-4">
            <Button colorScheme="green" type="submit">
              บันทึก
            </Button>
          </div>
        </ModalContent>
      </form>
    </Modal>
  )

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize
    setSize(pageSize)
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "ชื่อ",
      dataIndex: "name",
      render: (text, record) => <div>{record?.translation?.[i18n.language]?.name}</div>,
    },

    {
      title: "รายละเอียด",
      dataIndex: "active",
      render: (text, record) => (
        <div className="flex gap-2">
          <Link to={`/site/article-category/edit/${record?.id}`}>
            <Button size="sm" colorScheme="yellow">
              แก้ไข
            </Button>
          </Link>
          <Button size="sm" colorScheme="red" onClick={() => handleDeleteArticle(record?.id)}>
            ลบ
          </Button>
        </div>
      ),
    },
  ]

  //  console.log("facilities",facilities);
  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="หมวดหมู่บทความ">
      {renderAddModal()}
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" onClick={() => onOpen()}>
          เพิ่ม
        </Button>
      </div>
      <div className="p-4 w-full overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={articleCategories?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: articleCategories?.total,
            }}
            scroll={{
              x: "true",
            }}
          />
        )}
      </div>
    </Layout>
  )
}
