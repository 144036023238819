export const TransactionGroup = {
  BOOKING: {
    status_code: "BOOKING",
    description: "การจอง",
    color: "green",
  },
  SYSTEM: {
    status_code: "SYSTEM",
    description: "ข้อมูลและการตั้งค่าระบบ",
    color: "gray",
  },
  ROOM: {
    status_code: "ROOM",
    description: "ห้องพัก",
    color: "blue",
  },
  PAYMENT: {
    status_code: "PAYMENT",
    description: "ชำระเงิน",
    color: "yellow",
  },
  CHECKIN: {
    status_code: "CHECKIN",
    description: "การเช็คอิน-เช็คเอาท์",
    color: "teal",
  },
  CUSTOMER: {
    status_code: "CUSTOMER",
    description: "การจัดการลูกค้า",
    color: "blue",
  },
  SERVICING: {
    status_code: "SERVICING",
    description: "การให้บริการ",
    color: "pink",
  },
  FB: {
    status_code: "FB",
    description: "Food and Beverage",
    color: "purple",
  },
  OTHER: { status_code: "OTHER", description: "อื่นๆ", color: "gray" },
}

export default TransactionGroup
