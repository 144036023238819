import {
  EMPLOYEE_ALL,
  EMPLOYEE_CREATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_GET,
  EMPLOYEE_UPDATE,
  EMPLOYEE_ERROR,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function EmployeeReducer(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case EMPLOYEE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case EMPLOYEE_CREATE:
      return { isLoading: false }
    case EMPLOYEE_UPDATE:
      return { isLoading: false }
    case EMPLOYEE_DELETE:
      return { isLoading: false }
    case EMPLOYEE_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
