/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from "react"
import { Table, Tr, Th, Thead, Tbody, Td } from "@chakra-ui/react"
import dayjs from "dayjs"
import _ from "lodash"

import { BookingStatus } from "../../config/constant"

function RoomSchedule({ bookingArray, startDate, roomArray, setSelectedBooking = () => {} }) {
  const scheduleStartDate = dayjs(startDate).subtract(1, "day")
  const dayArray = new Array(10)
  console.log("Booking Array", bookingArray)

  const renderBookingBox = (selectedDay, selectedRoom) => {
    const selectedBooking = _.filter(
      bookingArray,
      (each) =>
        dayjs(each?.start).isBefore(dayjs(selectedDay).endOf("day")) &&
        dayjs(each.end).isAfter(dayjs(selectedDay).endOf("day")),
    )

    const filterBookingOnRoom = _.filter(
      selectedBooking,
      (each) => each?.list?.room?._id === selectedRoom?.id,
    )

    return (
      <div className="w-28">
        {_.map(filterBookingOnRoom, (eachBooking, bookingIndex) => (
          <div key={bookingIndex}>
            <div
              key={`booking${bookingIndex}`}
              onClick={() => setSelectedBooking(eachBooking)}
              className={`rounded-md p-2  w-full my-1 text-white text-xs cursor-pointer hover:shadow-lg ${
                _.includes(
                  [BookingStatus.book, BookingStatus.confirm, BookingStatus.pending],
                  eachBooking?.status,
                )
                  ? "bg-yellow-500"
                  : _.includes(
                      [BookingStatus.checkedOut, BookingStatus.success],
                      eachBooking?.status,
                    )
                  ? "bg-purple-500"
                  : !dayjs(eachBooking?.end).isSame(selectedDay, "day")
                  ? "bg-pink-500"
                  : "bg-orange-500"
              }`}
            >
              No. {eachBooking?.booking_no}
              {/* <div>
                <i className="fas fa-sign-in-alt mr-2"></i>
                {dayjs(eachBooking?.start)?.format("D MMM  HH:mm")}{" "}
                <i className="fas fa-sign-out-alt mr-2"></i>
                {dayjs(eachBooking?.end)?.format("D MMM  HH:mm")}{" "}
              </div> */}
              <div className="flex">
                <i className="fas fa-user mr-2"></i> {eachBooking?.customer?.firstname || ""}{" "}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <div></div>
      <div>
        <Table variant="striped" position="relative">
          <Thead className="sticky top-0">
            <Tr>
              <Th position="sticky" top={0}>
                ห้อง
              </Th>
              {_.map(dayArray, (each, index) => (
                <Th className="sticky top-0" position="sticky" top={0} key={index}>
                  {dayjs(scheduleStartDate).add(index, "day").format("D MMM")}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {_.map(_.orderBy(roomArray, ["name"]), (eachRoom, roomIdx) => (
              <Tr key={`room${roomIdx}`}>
                <Td>{eachRoom?.name}</Td>
                {_.map(dayArray, (eachDay, index) => (
                  <Td key={index}>
                    {renderBookingBox(dayjs(scheduleStartDate).add(index, "day"), eachRoom)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  )
}

export default RoomSchedule
