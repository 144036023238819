import React from "react"
import { Controller } from "react-hook-form"
import { Form, Input } from "antd"

function SEOInfo({ defaultInfo, register, control }) {
  const { TextArea } = Input
  return (
    <div>
      <div className=" bg-white p-4 rounded-lg shadow-lg my-3">
        <div className="">
          <h6 className="w-full text-gray-900 text-base  font-bold  font-sans my-2">
            ข้อมูลสำหรับทำ Search Engine Optimzation SEO
          </h6>
          <Form layout="vertical">
            <Form.Item label="Title / หัวข้อ">
              <Controller
                name="seo.title"
                control={control}
                defaultValue={defaultInfo?.seo?.title || ""}
                render={({ onChange, value }) => (
                  <Input onChange={onChange} value={value} placeholder="title" />
                )}
              />
            </Form.Item>
            <Form.Item label="Short Title / หัวข้อย่อ">
              <Controller
                name="seo.shortTitle"
                control={control}
                defaultValue={defaultInfo?.seo?.shortTitle || ""}
                render={({ onChange, value }) => (
                  <Input onChange={onChange} value={value} placeholder="short title" />
                )}
              />
            </Form.Item>
            <Form.Item label="URL">
              <Controller
                name="seo.url"
                control={control}
                defaultValue={defaultInfo?.seo?.url || ""}
                render={({ onChange, value }) => (
                  <Input onChange={onChange} value={value} placeholder="URL" />
                )}
              />
            </Form.Item>
            <Form.Item label="Sub Title / หัวข้อรอง">
              <Controller
                name="seo.subTitle"
                control={control}
                defaultValue={defaultInfo?.seo?.subTitle || ""}
                render={({ onChange, value }) => (
                  <Input onChange={onChange} value={value} placeholder="sub title" />
                )}
              />
            </Form.Item>
            <Form.Item label="Keywords / คำค้นหา">
              <Controller
                name="seo.keyword"
                control={control}
                defaultValue={defaultInfo?.seo?.keyword || ""}
                render={({ onChange, value }) => (
                  <Input onChange={onChange} value={value} placeholder="keywords" />
                )}
              />
            </Form.Item>
            <Form.Item label="Description / คำอธิบาย">
              <Controller
                name="seo.description"
                control={control}
                defaultValue={defaultInfo?.seo?.description || ""}
                render={({ onChange, value }) => (
                  <TextArea onChange={onChange} value={value} placeholder="description" />
                )}
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default SEOInfo
