export const MeetingRoomBookingType = {
  SLOT_BOOKING: {
    status_code: "SLOT_BOOKING",
    description: "การจองแบบสล็อต",
  },
  HOUR_BOOKING: {
    status_code: "HOUR_BOOKING",
    description: "การจองแบบรายชั่วโมง",
  },
}

export default MeetingRoomBookingType
