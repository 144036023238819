import React from "react"
import { useTranslation } from "react-i18next"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react"

export default function Confirm({
  title,
  body,
  isOpen,
  handleOnClose,
  handleSuccess,
  successText = null,
  cancleText = null,
}) {
  const { t } = useTranslation()
  return (
    <AlertDialog isOpen={isOpen} onClose={handleOnClose}>
      {" "}
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader className="font-sans" fontSize="lg">
          {title} <AlertDialogCloseButton />
        </AlertDialogHeader>{" "}
        <AlertDialogBody>{body}</AlertDialogBody>
        <AlertDialogFooter>
          <Button size="sm" colorScheme="purple" className="font-sans mx-2" onClick={handleSuccess}>
            {successText || t("general.confirm")}
          </Button>
          <Button size="sm" colorScheme="gray" className="font-sans" onClick={handleOnClose}>
            {cancleText || t("general.cancle")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
