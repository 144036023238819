import React from "react"

const SliderPaddingSlideshow = ({
  register,
  width_picture,
  height_picture,
  // leftPaddingPicture,
  // rightPaddingPicture,
  topPaddingPicture,
  bottomPaddingPicture,
  watch,
}) => (
  <div className="mb-4">
    <div className="pb-4">
      <h1 className="text-xl bg-blue-400 text-white p-2  border-4 flex  justify-center border-black">
        ส่วนข้อความ
      </h1>
    </div>
    <label className="block text-lg font-medium text-gray-700">ระยะจากขอบด้านบน</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={500}
      name={topPaddingPicture}
      ref={register}
      className="slider"
    />
    <span className="text-lg font-bold text-green-800 pl-4 ">{watch(topPaddingPicture)}px</span>

    <label className="block text-lg font-medium text-gray-700">ระยะจากขอบด้านล่าง</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={500}
      name={bottomPaddingPicture}
      ref={register}
      className="slider"
    />
    <span className="text-lg font-bold text-green-800 pl-4 ">{watch(bottomPaddingPicture)}px</span>

    <label className="block text-lg font-medium text-gray-700">ความกว้างพื้นที่ส่วนข้อความ</label>
    <input type="range" defaultValue={984} min={1} max={2000} name={width_picture} ref={register} />
    <span className="text-lg font-bold text-red-700 pl-4 ">{watch(width_picture)}px</span>

    <label className="block text-lg font-medium text-gray-700">ความสูงพื้นที่ส่วนข้อความ</label>
    <input
      type="range"
      defaultValue={375}
      min={1}
      max={2000}
      name={height_picture}
      ref={register}
    />
    <span className="text-lg font-bold text-red-700 pl-4 ">{watch(height_picture)}px</span>
    <br />
  </div>
)

export default SliderPaddingSlideshow
