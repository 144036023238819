import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import WhitePanel from "../../components/common/Cards/WhitePanel"
import SpinnersLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import { BookingInfoContainer } from "../../components/containers"
import { Alert } from "../../components/common/Alert"

export default function InfoHistoryReservation() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bookingInfo = useSelector((state) => state.logs)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOnelog(params?.id))
        .then(() => {
          setIsLoading(true)
        })
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        })
    }

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnersLoading />
  }

  return (
    <Layout categoryTitle={t("history.title")} title={t("history.infoReservationHistory")}>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setAlertOpen(false)}
        isError={alertOpen}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <WhitePanel>
          <BookingInfoContainer bookingInfo={bookingInfo} />
        </WhitePanel>
      </div>
    </Layout>
  )
}
