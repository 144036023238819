import React from "react"

const SliderTextcontentTypeMobile = ({ watch }) => (
  <div
    className="relative "
    style={{
      backgroundColor: `${watch("bg_website_color")}`,
    }}
  >
    <div className="border-2 border-black flex justify-center">
      <div
        className={`  bg-black justify-center `}
        style={{
          backgroundColor: `${watch("bg_component_color")}`,
          width: `${watch("mobile.text_component.text_area_width")}px`,
          height: `${watch("mobile.text_component.text_area_length")}px`,
          borderRadius: `${watch("mobile.text_component.text_area_curve")}%`,
          marginTop: `${watch("mobile.text_component.top_padding_content")}px`,
          marginBottom: `${watch("mobile.text_component.bottom_padding_content")}px`,
        }}
      >
        <p
          className="flex pr-6 pl-6 pt-6 pb-0 justify-center text-sm items-center text-red-400"
          style={{
            color: `${watch("v.text_component.text_color")}`,
          }}
        >
          {watch("translation.th.content")}
        </p>
        <p
          className="flex  pr-6 pl-6 pt-4 pb-0 text-sm  underline justify-center items-center text-white"
          style={{
            color: `${watch("mobile.text_component.text_content_color")}`,
          }}
        >
          {watch("translation.th.text_content")}
        </p>
      </div>
    </div>
  </div>
)

export default SliderTextcontentTypeMobile
