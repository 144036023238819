import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { slipHeader, slipDefaultStyle, slipFooter } from ".."
import { currencyFormatter } from "../../../util"

dayjs.extend(buddhistEra)

export default function FBOrderBillSlip(fbOrder, fbList, information, systemInfo) {
  return {
    // pageSize: "RA4",
    pageSize: {
      width: 200,
      height: 1000,
    },
    pageOrientation: "portrait",
    pageMargins: [10, 70, 10, 100],
    header: slipHeader(information),
    info: {
      title: "บิลค่าอาหาร",
    },
    defaultStyle: slipDefaultStyle,

    content: [
      {
        text: `บิลค่าอาหาร`,
        fontSize: 12,
        bold: true,
        margin: [0, 2],
        alignment: "center",
      },
      {
        text: "-------------------------------",
        margin: [0, 2],
        alignment: "center",
      },
      {
        table: {
          headerRows: 1,
          widths: ["30%", "70%"],
          body: [
            [
              { text: "เลขที่บิล :", bold: true, border: [false, false, false, false] },
              { text: fbOrder?.bill_number, border: [false, false, false, false] },
            ],
            [
              { text: "โต๊ะ :", bold: true, border: [false, false, false, false] },
              { text: fbOrder?.table?.name, border: [false, false, false, false] },
            ],
            [
              { text: "เวลาเข้า :", bold: true, border: [false, false, false, false] },
              {
                text: dayjs(fbOrder?.createdAt)?.format("D MMM YYYY  HH.mm น."),
                border: [false, false, false, false],
                fontSize: 9,
              },
            ],
            [
              { text: "เวลาพิมพ์ :", bold: true, border: [false, false, false, false] },
              {
                text: dayjs()?.format("D MMM YYYY  HH.mm น."),
                border: [false, false, false, false],
                fontSize: 9,
              },
            ],
          ],
        },
        margin: [0, 5, 0, 0],
        fontSize: 10,
      },
      {
        text: "-------------------------------",
        margin: [0, 2],
        alignment: "center",
      },
      {
        table: {
          headerRows: 1,
          widths: ["50%", "*", "20%"],
          body: [
            [
              { text: "รายการ", bold: true, border: [false, false, false, false] },
              {
                text: "จำนวน",
                bold: true,
                border: [false, false, false, false],
                alignment: "right",
              },
              { text: "ราคา", bold: true, border: [false, false, false, false] },
            ],
            ..._.map(fbList, (eachList) => [
              {
                text: `${eachList?.menu?.translation?.th?.name} ${
                  eachList?.option ? `(${eachList?.option})` : ""
                }${
                  !_.isEmpty(eachList?.toppings)
                    ? `\nเพิ่ม : ${_.map(eachList?.toppings, (each) => each)}`
                    : ""
                }${eachList?.remark ? `\n- ${eachList?.remark}` : ""}`,
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: eachList?.quantity,
                margin: [0, 2],
                alignment: "center",
                border: [false, false, false, false],
              },
              {
                text: currencyFormatter.format(eachList?.price * eachList?.quantity),
                margin: [0, 2],
                alignment: "center",
                border: [false, false, false, false],
              },
            ]),
            [
              { text: "รวม", bold: true, border: [false, false, false, false] },
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: currencyFormatter.format(
                  _.sum(_.map(fbList, (each) => each?.price * each?.quantity)),
                ),
                bold: true,
                border: [false, false, false, false],
              },
            ],
          ],
        },
        margin: [0, 5, 0, 0],
        fontSize: 10,
      },

      ...slipFooter(),
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
