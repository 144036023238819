import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory, Link } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { Tabs } from "antd"

import Layout from "../../components/layout/GeneralLayout"
import Alert from "../../components/common/Alert/AlertDialog"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"

export default function PromotionView() {
  const dispatch = useDispatch()
  const promotion = useSelector((state) => state.promotions)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.getOnePromotion(params.id)).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [params])

  const handleDelete = () => {
    const confirm = window.confirm("ยืนยันการลบ")
    if (confirm) {
      dispatch(actions.deleteOnePromotion(params.id)).then(() => {
        history.push("/site/promotion")
      })
    }
  }

  const items = [
    {
      key: "1",
      label: "ภาษาไทย",
      children: (
        <div>
          <h1 className="text-xl font-semibold my-2">{promotion?.translation?.th?.name}</h1>{" "}
          <h4 className="font-semibold my-2">{promotion?.translation?.th?.brief}</h4>{" "}
          <p
            className="container contents "
            dangerouslySetInnerHTML={{
              __html: promotion?.translation?.th?.description,
            }}
          ></p>
        </div>
      ),
    },
    {
      key: "2",
      label: "ภาษาอังกฤษ",
      children: (
        <div>
          <h1 className="text-xl font-semibold my-2">{promotion?.translation?.en?.name}</h1>{" "}
          <h4 className="font-semibold my-2">{promotion?.translation?.en?.brief}</h4>{" "}
          <p
            className="container contents"
            dangerouslySetInnerHTML={{
              __html: promotion?.translation?.en?.description,
            }}
          ></p>
        </div>
      ),
    },
  ]

  if (!isLoading) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  return (
    <Layout
      categoryTitle="จัดการเว็บไซต์โรงแรม > โปรโมชั่น"
      title={promotion?.translation?.th?.name}
    >
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
        <div className="flex gap-1">
          <Link to={`/site/promotion/edit/${params.id}`}>
            <Button colorScheme="yellow">แก้ไข</Button>
          </Link>
          <Button colorScheme="red" onClick={handleDelete}>
            ลบ
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <h1 className="text-xl font-semibold">
          {promotion?.translation?.th?.name}
          <div className="text-lg">{promotion?.translation?.en?.name}</div>
        </h1>
        <div className="flex gap-2 flex-wrap justify-center">
          {_.map(promotion?.images, (eachImage, index) => (
            <img src={eachImage?.image?.url} className="h-64" key={index} />
          ))}
        </div>
        <div className="p-4 w-full">
          <Tabs defaultActiveKey="1" items={items} />
          <br />
        </div>
      </div>
    </Layout>
  )
}
