import React from "react"

// const hexToRgb = (hex) => {
//   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
//   return result
//     ? {
//       r: parseInt(result[1], 16),
//       g: parseInt(result[2], 16),
//       b: parseInt(result[3], 16),
//     }
//     : null
// }

// const BorderTextColorRGB = hexToRgb`${watch('desktop.Slideshow.text_content.border_color')}`
// const textcontent_opacity_border = `${watch('desktop?.Slideshow?.text_content?.opacityBorde') / 100}`

const SlideshowComponentArea = ({ watch }) => (

  
  <div className="relative">
    <div className="border-2  border-black flex justify-center">
      <div
        className={`  bg-black  flex justify-center `}
        style={{
          width: "1440px",
          height: `${watch("desktop.Slideshow.height_picture")}px`,
          marginRight: `${watch("desktop.Slideshow.right_padding_picture")}px`, // Specify padding-right
          marginLeft: `${watch("desktop.Slideshow.left_padding_picture")}px`,
          marginTop: `${watch("desktop.Slideshow.top_padding_picture")}px`,
          marginBottom: `${watch("desktop.Slideshow.bottom_padding_picture")}px`,
          borderColor: `${watch("desktop?.Slideshow?.text_content?.border_color")}`,
          backgroundColor: `rgba(255, 0, 0, ${watch("desktop.Slideshow.opacityPicture") / 100})`, // Apply opacity to border color using watch
        }}
      ></div>
    </div>
    <div className={`  flex border-2 border-gray-600 justify-center absolute top-0 left-0`}>
      <div
        className={` bg-blue-500 bg-opacity-50   flex justify-center `}
        style={{
          width: `${watch("desktop.Slideshow.text_content.width_picture")}px`,
          height: `${watch("desktop.Slideshow.text_content.height_picture")}px`,
          marginTop: `${watch("desktop.Slideshow.text_content.top_padding_picture")}px`,
          marginBottom: `${watch("desktop.Slideshow.text_content.bottom_padding_picture")}px`,
        }}
      >
        <p
          className="flex text-center items-center text-white border-4 border-red-500"
          style={{
            borderColor: `rgba(255, 0, 0, ${
              watch("desktop.Slideshow.text_content.opacityBorder") / 100
            })`,
            // borderColor: `rgba(${BorderTextColorRGB?.r}, ${BorderTextColorRGB?.g}, ${BorderTextColorRGB?.b
            // }, watch(${textcontent_opacity_border})`,
            // borderColor: `${watch('desktop.Slideshow.text_content.border_color')}`
          }}
        >
          {watch("desktop.Slideshow.translation.en.text_content")}
        </p>
      </div>
    </div>
  </div>
)

export default SlideshowComponentArea
