import footer1 from "../../assets/footer-style/footer1.png"
import footer2 from "../../assets/footer-style/footer2.png"

export const FooterType = {
  BASIC_FOOTER: {
    status_code: "BASIC_FOOTER",
    description: "รูปแบบที่ 1",
    image: footer1 ,
  },
  ELEGANT_FOOTER: {
      status_code: "ELEGANT_FOOTER",
      description: "รูปแบบที่ 2",
      image: footer2,
  }

};

export default FooterType;