/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { Table } from "antd"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  Alert,
  GeneralMainLayout as Layout,
  ReportDateSelector,
  CSVGeneratorPanel,
} from "../../components"
import * as actions from "../../redux/actions"
import { bookingAnalysis } from "../../util"

import { appConfig } from "../../config"
import { HouseKeeperReport as HouseKeeperReportPDF } from "../../config/pdfmake/template"
import api from "../../config/api"

function HouseKeeperReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [date, setDate] = useState(new Date())
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [orderByField, setOrderByField] = useState("name")
  const [orderBy, setOrderBy] = useState("asc")
  const [excelExportOpen, setExcelExportOpen] = useState(false)

  const room = useSelector((state) => state.rooms)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const dispatch = useDispatch()

  useEffect(() => {
    setExcelExportOpen(false)
    dispatch(
      actions.getAllRoomsWithBooking({
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        unwindBooking: "true",
        page,
        size: appConfig.bookingMaxFetchSize,
        orderBy,
        orderByField,
        showAllDay: true,
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [date, page, orderBy, orderByField])

  const columns = [
    {
      title: "ห้อง",
      dataIndex: "name",
      sorter: true,
      render: (text, record) => record?.name,
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      render: (text, record) =>
        `${record?.type?.translation?.th?.name || record?.type?.name || ""}`,
      sorter: true,
    },
    {
      title: "เช็คอิน",
      dataIndex: "start",
      sorter: true,
      render: (text, record) => `  ${dayjs(record?.bookings?.start).format("D/MM/YYYY")} `,
    },
    {
      title: "เช็คเอาท์",
      dataIndex: "end",
      sorter: true,
      render: (text, record) => `  ${dayjs(record?.bookings?.end).format("D/MM/YYYY")} `,
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderBy("asc")
    } else {
      setOrderBy("desc")
    }

    if (sorter?.field === "name") {
      setOrderByField("name")
    }
    if (sorter?.field === "bookings") {
      setOrderByField("bookings.booking_no")
    }
    if (sorter?.field === "start") {
      setOrderByField("bookings.start")
    }
    if (sorter?.field === "end") {
      setOrderByField("bookings.end")
    }
    if (sorter?.field === "type") {
      setOrderByField("type.name")
    }
  }

  const fullQueryParams = new URLSearchParams({
    page: 1,
    size: appConfig.maxFetchSize,
    unwindBooking: true,
    date: dayjs(date).format("YYYY-MM-DD"),
    fetchBooking: true,
    orderBy,
    orderByField,
    showAllDay: true,
  })

  const handlePrintPDF = async () => {
    try {
      setIsLoading(false)
      api
        .get(`${process.env.REACT_APP_API_URL}/room/with-booking/?${fullQueryParams.toString()}`)
        .then((res) => {
          const ReportDefinition = HouseKeeperReportPDF(
            _.map(res?.data?.rows, (each) => ({
              ...each,
              bookings: bookingAnalysis(each.bookings),
            })),
            date,
            null,
            null,
            true,
            information,
            system,
          )
          console.log("ReportDefinition", ReportDefinition)
          pdfMake.createPdf(ReportDefinition).open()
        })
        .catch((error) => {
          alert(error?.message)
        })
        .finally(() => {
          setIsLoading(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const renderTableOnEachStatus = (type) => {
    let dataRows = []
    if (type === "CHECKOUT") {
      dataRows = _.filter(room?.arr, (each) =>
        dayjs(each?.bookings?.end).isSame(dayjs(date), "day"),
      )
    } else if (type === "CHECKIN") {
      dataRows = _.filter(room?.arr, (each) =>
        dayjs(each?.bookings?.start).isSame(dayjs(date), "day"),
      )
    } else if (type === "EXTEND") {
      dataRows = _.filter(
        room?.arr,
        (each) =>
          !dayjs(each?.bookings?.start).isSame(dayjs(date), "day") &&
          !dayjs(each?.bookings?.end).isSame(dayjs(date), "day"),
      )
    }

    return (
      <div className="w-full lg:w-1/2 px-2">
        <div className="my-1 font-semibold">
          {type === "CHECKOUT" && "เช็คเอาท์ (Check Out)"}
          {type === "CHECKIN" && "เช็คอิน (Check In)"}
          {type === "EXTEND" && "พักต่อ (Extend)"}
        </div>
        <Table
          columns={columns}
          dataSource={dataRows}
          onChange={onChange}
          pagination={{
            current: page,
            pageSize: appConfig.bookingMaxFetchSize,
            total: room?.total,
          }}
        />
      </div>
    )
  }

  return (
    <Layout categoryTitle={t("history.title")} title={t("history.housekeeperReport")}>
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            colorScheme="red"
            size="sm"
            leftIcon={<i className="fas fa-file-pdf"></i>}
            onClick={() => handlePrintPDF()}
          >
            PDF
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <ReportDateSelector pointDate={date} setPointDate={setDate} dateOnly />
        <CSVGeneratorPanel
          apiSuffix="room/with-booking"
          columns={columns}
          open={excelExportOpen}
          queryParams={fullQueryParams}
        />
        {isLoading && (
          <div className="flex flex-wrap">
            {renderTableOnEachStatus("CHECKOUT")}
            {renderTableOnEachStatus("CHECKIN")}
            {renderTableOnEachStatus("EXTEND")}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default HouseKeeperReport
