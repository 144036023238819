export default {
  colors: {
    purple: {
      50: "#EFEDF7",
      100: "#D1CDE9",
      200: "#B4ADDB",
      300: "#978ECD",
      400: "#796EBF",
      500: "#5C4EB1",
      600: "#493E8E",
      700: "#372F6A",
      800: "#251F47",
      900: "#121023",
    },
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.9rem",
    md: "1.125rem",
    lg: "1.250rem",
    xl: "1.625rem",
    "2xl": "1.75rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  styles: {
    global: {
      button: {
        fontFamily: "IBM Plex Sans Thai",
      },
      label: {
        fontFamily: "IBM Plex Sans Thai",
      },
    },
  },

  // fontWeight: {
  //   hairline: 100,
  //   thin: 200,
  //   light: 300,
  //   normal: 300,
  //   medium: 500,
  //   semibold: 500,
  //   bold: 500,
  //   extrabold: 800,
  //   black: 900,
  // },
}
