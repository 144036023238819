/* eslint-disable no-underscore-dangle */
import React, { useState } from "react"
import ReactSelect from "react-select/creatable"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"
import { Button } from "@chakra-ui/react"

import { CreateOrEditCustomerModal } from "../../Modal"

function CustomReciptCustomerForm({
  customers,
  control,
  bookingInfo,
  handleCreateCustomer,
  watch,
  handleEditCustomer = () => {},
  selectedCustomer,
  setSelectedCustomer,
}) {
  const { t } = useTranslation()
  const [isModalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(false)
  const [tempCustomerName, setTempCustomerName] = useState("")
  const [modalEditCustomerOn, setEditModalEditCustomerOn] = useState(false)

  const renderCustomerInfo = () => (
    <div>
      <div className=" font-semibold">
        ข้อมูลลูกค้า
        <Button
          onClick={() => {
            setEditModalEditCustomerOn(true)
          }}
          leftIcon={<i className="fas fa-pencil-alt" />}
          size="sm"
          colorScheme="yellow"
          className="ml-2"
          type="button"
        >
          แก้ไข
        </Button>
      </div>
      <ul className="list-inside list-disc text-sm ml-2 my-2 bg-gray-200 rounded-md p-4">
        <li>
          <span className="font-semibold">ชื่อ </span>
          {selectedCustomer?.firstname}{" "}
          {selectedCustomer?.is_legal_entity ? "" : selectedCustomer?.lastname}
        </li>
        <li>
          <span className="font-semibold">รูปแบบ </span>{" "}
          {selectedCustomer?.is_legal_entity ? "นิติบุคคล" : "บุคคลธรรมดา"}
        </li>
        <li>
          <span className="font-semibold">ที่อยู่ </span> {selectedCustomer?.address || "-"}
        </li>
        <li>
          <span className="font-semibold">เลขบัตรประชาชน / เลขประจำตัวนิติบุคคล </span>{" "}
          {selectedCustomer?.id_card || "-"}
        </li>
        <li>
          <span className="font-semibold">หมายเลขโทรศัพท์ </span> {selectedCustomer?.tel || "-"}
        </li>
      </ul>
    </div>
  )

  return (
    <div className="flex flex-wrap">
      <CreateOrEditCustomerModal
        defaultValue={{ firstname: tempCustomerName }}
        handleCreateCustomer={(data) => {
          handleCreateCustomer(data)
          setIsModalCreateCustomerOpen(false)
        }}
        handleOnClose={() => {
          setTempCustomerName("")
          setIsModalCreateCustomerOpen(false)
        }}
        isOpen={isModalCreateCustomerOpen}
      />
      <CreateOrEditCustomerModal
        defaultValue={selectedCustomer}
        handleCreateCustomer={(data) => {
          handleEditCustomer(selectedCustomer?._id, data)
          setEditModalEditCustomerOn(false)
        }}
        handleOnClose={() => {
          setEditModalEditCustomerOn(false)
        }}
        isOpen={modalEditCustomerOn}
        mode="edit"
      />
      <div className="w-full py-1 px-2">
        <div className="font-semibold  py-2">ลูกค้าที่จะออกใบเสร็จให้</div>
        {customers?.isLoading && (
          <Controller
            name="customer"
            control={control}
            defaultValue={{
              label: `${selectedCustomer.firstname} ${selectedCustomer.lastname}`,
              value: selectedCustomer._id,
            }}
            render={(field) => (
              <ReactSelect
                placeholder={t("crm.addCustomerName")}
                options={_.map(customers?.arr, (eachRow) => ({
                  label: `${eachRow?.firstname} ${eachRow?.lastname}`,
                  value: eachRow?.id,
                }))}
                onChange={(e) => {
                  const foundCustomer = _.find(customers?.arr, (each) => each?._id === e.value)
                  setSelectedCustomer(foundCustomer)
                  field.onChange(e)
                }}
                onCreateOption={(label) => {
                  setIsModalCreateCustomerOpen(true)
                  setTempCustomerName(label)
                }}
                value={field.value}
              />
            )}
          />
        )}

        <div className="my-2 text-gray-500 text-sm">
          กรณีลูกค้าใหม่ สามารถพิมพ์เข้าจากส่วนนี้ได้เลย จากนั้นจึงกด Create เพื่อสร้างลูกค้า
        </div>
      </div>
      <div className="w-full px-2 py-1">{renderCustomerInfo()}</div>
    </div>
  )
}

export default CustomReciptCustomerForm
