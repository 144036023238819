import React from "react"
import { WhitePanel } from "../../common/Cards"
// eslint-disable-next-line arrow-body-style
const Video = () => {
  return (
    <div>
      <WhitePanel>
        <div className="flex justify-center py-12">
          <i className="fas fa-photo-video text-2xl"></i>
        </div>
      </WhitePanel>
    </div>
  )
}

Video.propTypes = {}

export default Video
