import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, useToast } from "@chakra-ui/react"
import _ from "lodash"
import { useHistory } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Paginator from "../../components/common/Paginator/NewPagination"
import { Alert } from "../../components/common/Alert"
import { WhitePanel } from "../../components/common"
import FrontpageTemplate from "../../template/FrontpageTemplate"
import * as actions from "../../redux/actions"
import { appConfig } from "../../config"

export default function ComponentTemplate() {
  const frontpageComponent = useSelector((state) => state.frontpageComponent)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const dispatch = useDispatch()
  const toast = useToast()

  const handleEditFrontpageComponent = async (template) => {
    // console.log("handleEditFrontpageComponent data", payload)
    const confirm = window.confirm(
      "ยืนยันการแก้ไข การดำเนินการนี้จะลบส่วนประกอบทั้งหมด แล้วสร้างส่วนประกอบใหม่แทน",
    )
    if (confirm) {
      try {
        await dispatch(actions.removeAllAndRepace(template))

        toast({ status: "success", title: "แก้ไขส่วนประกอบเว็บไซต์สำเร็จ" })
      } catch (error) {
        setIsError(true)
        setErrMessage(`ไม่สามารถแก้ไขส่วนประกอบหน้าเว็บไซต์ได้ ${error?.message}`)
      }
    }
  }
  // console.log("handleEditFrontpageComponent data", payload)

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ส่วนประกอบของหน้าเว็บไซต์" preview>
      <div className="flex gap-2 px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>

      <div className="p-4 w-full overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />

        {_.map(FrontpageTemplate, (eachComponent, index) => (
          <div key={index}>
            <WhitePanel>
              <div className="flex justify-between">
                <div className="font-semibold text-xl self-center">{eachComponent.name}</div>
                <div>
                  <Button
                    colorScheme="purple"
                    onClick={() => handleEditFrontpageComponent(eachComponent.component)}
                  >
                    ใช้งาน
                  </Button>
                </div>
              </div>
            </WhitePanel>
          </div>
        ))}
      </div>
    </Layout>
  )
}
