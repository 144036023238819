import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../redux/actions"
import { SYSTEM_MENU } from "../config/MenuList"

// components
import Sidebar from "../components/common/Sidebar/MainSidebar"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"

// views
import ManagementUsers from "../views/system/management-users/ManagementUsers"
import CreateUsers from "../views/system/management-users/CreateUser"
import EditUser from "../views/system/management-users/EditUser"
import UserRole from "../config/UserRoles"
import ManagementSystemInfo from "../views/system/management-system/ManagementSystem"
import PaymentSystem from "../views/system/management-system/PaymentSystem"
import OuterServiceManagement from "../views/SuperSystem/OuterServiceManagement"
import DeveloperSandbox from "../views/system/sandbox/Sandbox"
import NotifyManagement from "../views/system/management-system/NotifyManagement"
import ManagementEmployee from "../views/system/management-users/ManagementEmployee"
import CreateEmployee from "../views/system/management-users/CreateEmployee"
import EditEmployee from "../views/system/management-users/EditEmployee"
import EditPassword from "../views/system/management-users/EditPassword"
import CustomizeSetting from "../views/system/management-system/CustomizeSetting"
import SystemTransaction from "../views/system/management-system/SystemTransaction"
import DevelopmentPortal from "../views/system/sandbox/DevelopmentPortal"
import OnlineBookingManagement from "../views/system/management-system/OnlineBookingManagement"
import { ModuleList } from "../config/constant/ModuleList"

export default function SystemLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("SystemLayout : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (
        me?.access?.[ModuleList.RESERVATION.status_code] ||
        me?.role === UserRole.SUPER_USER.toString() // Reserved for some system
      ) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <Sidebar menuList={SYSTEM_MENU} isShow={showSidebar} setIsShowSidebar={setIsShowSidebar} />
      <div className={`relative  bg-gray-200 ${showSidebar ? "lg:ml-64" : ""} `}>
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/system/users" exact component={ManagementUsers} />
            <Route path="/system/employee" exact component={ManagementEmployee} />
            <Route path="/system/employee/create" exact component={CreateEmployee} />
            <Route path="/system/employee/edit/:id" exact component={EditEmployee} />
            <Route path="/system/user/create" exact component={CreateUsers} />
            <Route path="/system/user/edit/:id" exact component={EditUser} />
            <Route path="/system/user/password-edit/:id" exact component={EditPassword} />
            <Route path="/system/info/" exact component={ManagementSystemInfo} />
            <Route path="/system/payment" exact component={PaymentSystem} />
            <Route path="/system/outerservice/" exact component={OuterServiceManagement} />
            <Route path="/system/sandbox/" exact component={DeveloperSandbox} />
            <Route path="/system/development-portal/" exact component={DevelopmentPortal} />
            <Route path="/system/notify/" exact component={NotifyManagement} />
            <Route path="/system/system-customize/" exact component={CustomizeSetting} />
            <Route path="/system/transaction/" exact component={SystemTransaction} />
            <Route path="/system/online-booking/" exact component={OnlineBookingManagement} />
            <Redirect from="/system" to="/system/info" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <>
      <SpinnerLoading />
    </>
  )
}
