/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import React, { useState } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"

import MeetingRoomSlotSelector from "./MeetingRoomSlotSelector"
import MeetingRoomHourSelector from "./MeetingRoomHourSelector"
import { Confirm } from "../common/Alert"

export default function MeetingRoomSelector({
  meetingRoom,
  selectedMeetingRooms = [],
  setSelectedMeetingRooms,
  rerender,
  setRerender,
  startDate,
  endDate,
  isOneDay,
  alreadyBooked,
  handleDeleteMeetingRoomOrder = () => {},
  plusDay = false,
  selectedServiceOrder,
}) {
  const { t, i18n } = useTranslation()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [selectedMROToDeleteId, setSelectedMROToDeleteId] = useState()

  const dayAmount =
    (isOneDay
      ? 1
      : dayjs(endDate).startOf("day").diff(dayjs(startDate).startOf("day"), "day", false)) +
    (plusDay ? 1 : 0)
  const dayArray = Array(dayAmount > 0 ? dayAmount : 0)

  const checkSelectRoom = (selectedMeetingRoom) =>
    _.findIndex(selectedMeetingRooms, {
      meetingRoom: selectedMeetingRoom?._id,
      start: dayjs(startDate).format("YYYY-MM-DD HH:mm"),
      end: dayjs(endDate).format("YYYY-MM-DD HH:mm"),
    }) !== -1

  const handleSelectRoomWithHour = (selectedMeetingRoom) => {
    if (checkSelectRoom(selectedMeetingRoom)) {
      const founedIndex = _.findIndex(
        selectedMeetingRooms,
        (eachMeetingRoom) => eachMeetingRoom?.meetingRoom === selectedMeetingRoom?._id,
      )
      // eslint-disable-next-line no-param-reassign
      delete selectedMeetingRooms[founedIndex]
      setSelectedMeetingRooms(selectedMeetingRooms)
    } else {
      selectedMeetingRooms.push({
        meetingRoom: selectedMeetingRoom?._id,
        start: dayjs(startDate).format("YYYY-MM-DD HH:mm"),
        end: dayjs(endDate).format("YYYY-MM-DD HH:mm"),
      })
      setSelectedMeetingRooms(selectedMeetingRooms)
    }
    setRerender(!rerender)
  }
  // Return Timeslot List
  return (
    <div>
      <div>
        {t("general.amount")} {dayAmount} {t("general.day")}
      </div>
      <Confirm
        isOpen={confirmOpen}
        title="ยืนการลบการจอง"
        body="ยืนยันการลบการจองในช่วงดังกล่าว การยกเลิกจะมีผลทันที แต่ไม่ได้มีผลกับการจองช่วยอื่นๆ"
        successText={t("general.confirm")}
        cancleText={t("general.cancle")}
        handleSuccess={() => {
          handleDeleteMeetingRoomOrder(selectedMROToDeleteId)
          setConfirmOpen(false)
          setSelectedMROToDeleteId()
        }}
        handleOnClose={() => setConfirmOpen(false)}
      />
      <div>
        {_.map(meetingRoom?.arr, (eachMeetingRoom, index) => (
          <div key={index}>
            <div className="flex justify-between">
              <h3 className="my-2 text-lg font-bold">
                {eachMeetingRoom?.translation?.[i18n.language]?.name}
              </h3>
              {!eachMeetingRoom?.use_slot_price && (
                <Button
                  size="sm"
                  onClick={() => handleSelectRoomWithHour(eachMeetingRoom)}
                  colorScheme={checkSelectRoom(eachMeetingRoom) ? "purple" : "gray"}
                >
                  เลือก
                </Button>
              )}
            </div>
            <hr />

            {eachMeetingRoom?.use_slot_price &&
              _.map(dayArray, (eachDay, dayIndex) => (
                <MeetingRoomSlotSelector
                  alreadyBooked={alreadyBooked}
                  dayslotKey={dayIndex}
                  selectedDay={startDate}
                  rerender={rerender}
                  selectedMeetingRoom={eachMeetingRoom}
                  selectedMeetingRooms={selectedMeetingRooms}
                  setConfirmOpen={setConfirmOpen}
                  setRerender={setRerender}
                  setSelectedMROToDeleteId={setSelectedMROToDeleteId}
                  setSelectedMeetingRooms={setSelectedMeetingRooms}
                />
              ))}

            {!eachMeetingRoom?.use_slot_price && (
              <MeetingRoomHourSelector
                meetingRoom={eachMeetingRoom}
                dayArray={dayArray}
                selectedDay={startDate}
                selectedMeetingRooms={selectedMeetingRooms}
                selectedServiceOrder={selectedServiceOrder}
                setSelectedMROToDeleteId={setSelectedMROToDeleteId}
                setConfirmOpen={setConfirmOpen}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

MeetingRoomSelector.propTypes = {
  meetingRoom: PropTypes.shape({
    arr: PropTypes.arrayOf(PropTypes.object),
  }),
}
