import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory, Link } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { Tabs } from "antd"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import Alert from "../../components/common/Alert/AlertDialog"

export default function FacilityView() {
  const dispatch = useDispatch()
  const facility = useSelector((state) => state.facilities)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const params = useParams()
  const history = useHistory()
  const { i18n } = useTranslation()

  useEffect(() => {
    dispatch(actions.getOneFacility(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
    return () => {}
  }, [params])

  const handleDelete = () => {
    const confirm = window.confirm("ยืนยันการลบ")
    if (confirm) {
      dispatch(actions.deleteOneFacility(params.id))
        .then(() => {
          history.push("/site/")
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  if (!isLoading) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  const items = [
    {
      key: "1",
      label: "ภาษาไทย",
      children: (
        <div>
          <h1 className="text-xl font-semibold my-2">{facility?.translation?.th?.name}</h1>{" "}
          <h4 className="font-semibold my-2">{facility?.translation?.th?.brief}</h4>{" "}
          <p
            className="container contents "
            dangerouslySetInnerHTML={{
              __html: facility?.translation?.th?.description,
            }}
          ></p>
        </div>
      ),
    },
    {
      key: "2",
      label: "ภาษาอังกฤษ",
      children: (
        <div>
          <h1 className="text-xl font-semibold my-2">{facility?.translation?.en?.name}</h1>{" "}
          <h4 className="font-semibold my-2">{facility?.translation?.en?.brief}</h4>{" "}
          <p
            className="container contents"
            dangerouslySetInnerHTML={{
              __html: facility?.translation?.en?.description,
            }}
          ></p>
        </div>
      ),
    },
  ]

  return (
    <Layout
      categoryTitle="จัดการเว็บไซต์โรงแรม > สิ่งอำนวยความสะดวก"
      title={facility?.translation?.[i18n.language]?.name}
    >
      <div className="flex justify-between px-4">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
        <div className="flex gap-1">
          <Link to={`/site/facility/edit/${params.id}`}>
            <Button colorScheme="yellow">แก้ไข</Button>
          </Link>
          <Button colorScheme="red" onClick={handleDelete}>
            ลบ
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <div className="flex gap-2 flex-wrap justify-center">
          {_.map(facility?.images, (eachImage, index) => (
            <img src={eachImage?.image?.url} className="h-64" key={index} />
          ))}
        </div>
        <div className="p-4 w-full">
          <Tabs defaultActiveKey="1" items={items} />
          <br />
        </div>
      </div>
    </Layout>
  )
}
