import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm, Controller } from "react-hook-form"
import _ from "lodash"
import { DatePicker, Space, Input } from "antd"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import Flags from "country-flag-icons/react/3x2"

import { RichTextEditor } from "../../components/common/RichTextEditor"
import Layout from "../../components/layout/GeneralLayout"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import ModalAddPhoto from "../../components/Modal/HotelSites/ModalAddPhotoToFacilityAndPromotion"
import Alert from "../../components/common/Alert/AlertDialog"
import DialogLoading from "../../components/Loading/DialogLoading"
import * as actions from "../../redux/actions"

dayjs.extend(customParseFormat)

export default function CreateOrEditPromotion({ type = "create" }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const currentPromotion = useSelector((state) => state.promotions)
  const [uploadedImage, setUploadedImage] = useState([])
  const [isModalAddPhotoOpen, setIsModalAddPhotoOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoadingDialogShow, setIsDialogLoadingShow] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const { register, handleSubmit, control } = useForm()
  const { TextArea } = Input

  const dateFormat = "YYYY-MM-DD"
  const [rangeData, setRangeData] = useState(
    [
      currentPromotion?.rangetime?.startdate || dayjs().format(dateFormat),
      currentPromotion?.rangetime?.enddate || dayjs().format(dateFormat),
    ] || [],
  )

  useEffect(() => {
    if (type === "create") {
      setIsLoading(true)
    }
    return () => {}
  }, [type])

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOnePromotion(params?.id)).then(() => {
        setIsLoading(true)
      })
    }
    return () => {}
  }, [params])

  useEffect(() => {
    setRangeData([
      currentPromotion?.rangetime?.startdate || dayjs().format(dateFormat),
      currentPromotion?.rangetime?.enddate || dayjs().format(dateFormat),
    ])

    return () => {}
  }, [currentPromotion])

  const onCreatePromotion = (data) => {
    setIsDialogLoadingShow(true)
    const payload = {
      ...data,
      images: _.map(uploadedImage, (image) => ({
        image: image.data_url,
      })),
    }
    dispatch(actions.createOnePromotion(payload))
      .then(() => {
        dispatch(actions.getAllPromotions())
          .then(() => {
            history.goBack()
          })
          .catch((err) => {
            setErrMessage(err)
            setIsError(true)
          })
          .finally(() => {
            setIsDialogLoadingShow(false)
          })
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
      .finally(() => {
        setIsDialogLoadingShow(false)
      })
  }

  const onEditPromotion = (data) => {
    const payload = {
      ...data,
      rangetime: { startdate: rangeData[0], enddate: rangeData[1] },
    }
    console.log("Data", data)
    setIsDialogLoadingShow(true)
    dispatch(actions.editOnePromotion(params?.id, payload))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }

  const handleOnDeleteImage = (imageIndex) => {
    const confirm = window.confirm("ยืนยันการลบรูปภาพนี้")

    if (confirm) {
      const tempGalleryImage = currentPromotion.images
      setIsLoading(false)
      console.log(imageIndex)
      tempGalleryImage.splice(imageIndex, 1)
      try {
        dispatch(actions.editOnePromotion(currentPromotion?.id, { images: tempGalleryImage }))
          .then(() => {
            dispatch(actions.editOnePromotion(currentPromotion?.id))
              .then(() => {
                setIsLoading(true)
              })
              .catch((err) => {
                setErrMessage(err)
                setIsError(true)
              })
          })
          .catch((err) => {
            setErrMessage(err)
            setIsError(true)
          })
      } catch (error) {
        setErrMessage("ขออภัย ไม่สามารถลบรูปภาพได้")
        setIsError(true)
        console.error("Cannot Remove Image", error)
        setIsLoading(true)
      }
    }
  }

  const isEditMode = type === "edit"

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />{" "}
      </div>
    )
  }

  const { RangePicker } = DatePicker

  const disabledDate = (current) => current && current < dayjs().endOf("day")

  return (
    <Layout
      categoryTitle="จัดการเว็บไซต์โรงแรม > โปรโมชั่น"
      title={type === "create" ? "เพิ่มโปรโมชั่น" : "แก้ไขโปรโมชั่น"}
    >
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <div className="flex flex-wrap justify-between pb-8">
          <Alert
            isError={isError}
            errMessage={errMessage}
            handleOnClose={() => {
              setIsError(false)
              setErrMessage()
            }}
          />
          <DialogLoading isLoading={isLoadingDialogShow} />
          <div className="w-full  ">
            <form onSubmit={handleSubmit(isEditMode ? onEditPromotion : onCreatePromotion)}>
              <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
                <div className="w-full px-4 ">
                  <div className="relative w-full mb-3 ">
                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      ชื่อโปรโมชั่น <span className="text-red-400">*</span>
                    </label>

                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password "
                    >
                      ภาษาไทย *
                    </label>
                    <Controller
                      name="translation.th.name"
                      control={control}
                      defaultValue={isEditMode ? currentPromotion?.translation?.th?.name : ""}
                      render={(field) => (
                        <Input
                          {...field}
                          addonBefore={<Flags.TH title="TH" className="w-5" />}
                          placeholder="ชื่อโปรโมชั่น"
                          required
                          size="large"
                        />
                      )}
                    />

                    <label className="block  text-gray-700 text-sm font-semibold mb-2 pt-3">
                      ภาษาอังกฤษ
                    </label>
                    <Controller
                      name="translation.en.name"
                      control={control}
                      defaultValue={isEditMode ? currentPromotion?.translation?.en?.name : ""}
                      render={(field) => (
                        <Input
                          {...field}
                          addonBefore={<Flags.GB title="EN" className="w-5" />}
                          placeholder="ชื่อโปรโมชั่น"
                          size="large"
                        />
                      )}
                    />
                  </div>
                  <div className="relative w-full mb-3 ">
                    <div className="pt-5">คำเชิญชวน</div>
                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      ภาษาไทย
                    </label>
                    <Controller
                      control={control}
                      name="translation.th.brief"
                      defaultValue={isEditMode ? currentPromotion?.translation?.th?.brief : ""}
                      render={(field) => <TextArea rows={3} {...field} />}
                    />

                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2 pt-3"
                      htmlFor="grid-password"
                    >
                      ภาษาอังกฤษ
                    </label>
                    <Controller
                      control={control}
                      name="translation.en.brief"
                      defaultValue={isEditMode ? currentPromotion?.translation?.en?.brief : ""}
                      render={(field) => <TextArea rows={3} {...field} />}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <div className="pt-5">รายละเอียด</div>
                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      ภาษาไทย
                    </label>
                    <Controller
                      control={control}
                      name="translation.th.description"
                      defaultValue={
                        isEditMode ? currentPromotion?.translation?.th?.description : ""
                      }
                      render={(field) => (
                        <RichTextEditor placeholder="รายละเอียด" {...field} rows={10} />
                      )}
                    />

                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2 pt-3"
                      htmlFor="grid-password"
                    >
                      ภาษาอังกฤษ
                    </label>
                    <Controller
                      control={control}
                      name="translation.en.description"
                      defaultValue={
                        isEditMode ? currentPromotion?.translation?.th?.description : ""
                      }
                      render={(field) => (
                        <RichTextEditor placeholder="รายละเอียด" {...field} rows={10} />
                      )}
                    />
                    <div className="pt-5">ช่วงระยะเวลาโปรโมชั่น</div>
                    <Space direction="vertical" size={12} className="pt-4 py-3 pb-6 ">
                      <RangePicker
                        disabledDate={disabledDate}
                        defaultValue={[dayjs(rangeData[0]), dayjs(rangeData[1])]}
                        onChange={(value, dateString) => setRangeData(dateString)}
                      />
                    </Space>
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      รูปภาพโปรโมชั่น
                    </label>
                    {isEditMode ? (
                      <div className="flex flex-wrap">
                        <ModalAddPhoto
                          defaultValueArray={currentPromotion}
                          handleOnClose={() => {
                            setIsModalAddPhotoOpen(false)
                          }}
                          isOpen={isModalAddPhotoOpen}
                          itemId={currentPromotion?.id}
                          type="promotion"
                        />
                        {_.map(currentPromotion?.images, (eachImage, index) => (
                          <div className="m-4 border border-gray-400 rounded-md" key={index}>
                            <img src={eachImage?.image?.url} className="h-64" />
                            <div className="flex my-2 justify-center">
                              <Button
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleOnDeleteImage(index)}
                              >
                                ลบ
                              </Button>
                            </div>
                          </div>
                        ))}
                        <div
                          className="h-64 w-64 self-center rounded-lg bg-gray-200 hover:bg-gray-300 cursor-pointer p-4 flex justify-center "
                          onClick={() => setIsModalAddPhotoOpen(true)}
                        >
                          <div className="self-center">
                            <i className="fas fa-plus text-3xl text-gray-500" />
                            <h3 className="mt-2 text-gray-500 text-2xl">เพิ่ม</h3>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <ImageUpload
                          images={uploadedImage}
                          setImages={setUploadedImage}
                          preview_size="250"
                          maxNumber={5}
                        />
                      </div>
                    )}
                  </div>
                  <br />

                  <div className="text-center  gap-0 py-3 ">
                    <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                      {isEditMode ? "แก้ไข" : "เพิ่ม"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
