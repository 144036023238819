/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory, Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { InputNumber, Input, Radio, Checkbox, Form } from "antd"
import { Button } from "@chakra-ui/react"
import _ from "lodash"

import { GeneralMainLayout as Layout, SpinnerLoading, Alert, WhitePanel } from "../../components"
import * as actions from "../../redux/actions"
import { MobileOrderContext } from "../../contexts/MobileOrderContext"

function DetailfoodMenu() {
  const params = useParams()
  const history = useHistory()
  const fbOrder = useSelector((state) => state.fbOrder)
  const menu = useSelector((state) => state.menu)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [selectedOption, setSelectedOption] = useState()
  const [selectedTopping, setSelectedTopping] = useState([])
  const [price, setPrice] = useState(0)
  const [remark, setRemark] = useState("")
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { TextArea } = Input
  const { toggleSelectedList, color } = useContext(MobileOrderContext)

  useEffect(() => {
    dispatch(actions.getOneMenu(params?.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
    return () => {}
  }, [params])

  const handleAddToList = () => {
    if (!_.isEmpty(menu?.options) && !selectedOption) {
      setIsError(true)
      setErrorMessage("กรุณาเลือกตัวเลือก")
      return
    }

    const tempListItem = {
      menu,
      quantity: selectedQuantity,
      table: fbOrder?.table?._id,
      price,
      option: selectedOption,
      toppings: selectedTopping,
      remark,
    }

    toggleSelectedList(tempListItem)
    history.goBack()
  }

  const calculatePrice = () => {
    let totalPrice
    if (_.isEmpty(menu?.options)) {
      totalPrice = menu?.price
    } else {
      const foundSelectedOption = _.find(
        menu?.options,
        (eachOption) => eachOption?.name === selectedOption,
      )
      totalPrice = foundSelectedOption?.price
    }

    _.map(selectedTopping, (eachTopping) => {
      const foundSelectedTopping = _.find(menu?.toppings, (each) => each?.name === eachTopping)
      totalPrice += foundSelectedTopping?.price
    })
    setPrice(totalPrice)
  }

  useEffect(() => {
    calculatePrice()

    return () => {}
  }, [selectedOption, selectedTopping, menu])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="Mobile Order" title="เมนูอาหาร">
      <Alert isError={isError} errMessage={errorMessage} handleOnClose={() => setIsError(false)} />
      <div className="px-4">
        <div className="flex ">
          <Button colorScheme={color} variant="outline" onClick={() => history.goBack()}>
            กลับ
          </Button>
        </div>
        <img
          src={menu?.image?.url}
          className="mt-2 h-48 w-full object-cover rounded-md shadow-md"
        />
        <div className="my-2">
          <WhitePanel>
            <div className="text-xl font-semibold ">
              {menu?.translation?.[i18n.language]?.name || menu?.translation?.th?.name}
            </div>
            <div>ราคา {price || "-"} บาท</div>
            <div className="flex items-center justify-between">
              <div>จำนวน</div>
              <div className="flex">
                <div className="my-2 px-2">
                  <InputNumber
                    min={1}
                    max={100}
                    size="large"
                    defaultValue={1}
                    onChange={(value) => setSelectedQuantity(value)}
                  />
                </div>
              </div>
            </div>
            {!_.isEmpty(menu?.options) && (
              <div>
                <div className="my-2 text-sm">ตัวเลือก</div>
                <Radio.Group onChange={(event) => setSelectedOption(event.target.value)}>
                  <div className="flex flex-col">
                    {_.map(menu?.options, (eachOption, index) => (
                      <Radio value={eachOption?.name} key={index} className="my-1">
                        {eachOption?.name} ({eachOption?.price} บาท)
                      </Radio>
                    ))}
                  </div>{" "}
                </Radio.Group>
              </div>
            )}
            {!_.isEmpty(menu?.toppings) && (
              <div>
                <div className="my-2 text-sm">เพิ่มเติม</div>
                <Checkbox.Group onChange={(value) => setSelectedTopping(value)}>
                  <div className="flex flex-col">
                    {_.map(menu?.toppings, (eachTopping, index) => (
                      <Checkbox value={eachTopping?.name} key={index} className="my-1">
                        {eachTopping?.name} ({eachTopping?.price} บาท)
                      </Checkbox>
                    ))}
                  </div>{" "}
                </Checkbox.Group>
              </div>
            )}
            <hr className="my-1" />
            <div className="text-sm my-2">หมายเหตุ</div>
            <TextArea
              rows={2}
              style={{ width: "100%" }}
              onChange={(e) => setRemark(e.target.value)}
            />
            <div className="flex justify-end my-2">
              <Button colorScheme={color} size="sm" onClick={() => handleAddToList()}>
                เพิ่มในรายการ
              </Button>
            </div>
          </WhitePanel>
        </div>
      </div>
    </Layout>
  )
}

export default DetailfoodMenu
