import React from "react"

const SliderPictureArea = ({ watch }) => (
  <div className="border-2 border-black flex justify-center">
    <div
      className={`  bg-black  flex justify-center `}
      style={{
        width: "1440px",
        // width: `${watch("desktop.Horizontal_Gallery.width_picture")}px`,
        height: `${watch("desktop.Horizontal_Gallery.height_picture")}px`,
        marginRight: `${watch("desktop.Horizontal_Gallery.right_padding_picture")}px`, // Specify padding-right
        marginLeft: `${watch("desktop.Horizontal_Gallery.left_padding_picture")}px`,
        marginTop: `${watch("desktop.Horizontal_Gallery.top_padding_picture")}px`,
        marginBottom: `${watch("desktop.Horizontal_Gallery.bottom_padding_picture")}px`,
        //   borderRadius: `${watch("mobile.button_component.button_curve")}%`,
        //   backgroundColor: `${watch("desktop.button_component.button_color")}`,
      }}
    >
      <p
        className="flex  items-center "
        //   style={{
        //     color: `${watch("desktop.button_component.button_text_color")}`,
        //   }}
      >
        {/* {watch("translation.th.button_component_name")} */}
      </p>
    </div>
  </div>
)

export default SliderPictureArea
