import {
  PROMOTION_ALL,
  PROMOTION_CREATE,
  PROMOTION_PUT,
  PROMOTION_GET,
  PROMOTION_DELETE,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case PROMOTION_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        total: action.payload?.total,
      }
    case PROMOTION_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case PROMOTION_CREATE:
      return { isLoading: true }
    case PROMOTION_PUT:
      return { isLoading: true }
    case PROMOTION_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
