import {
  IMAGE_ALL,
  IMAGE_CREATE,
  IMAGE_DELETE,
  IMAGE_GET,
  IMAGE_UPDATE,
  IMAGE_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const imageActions = new GeneralReduxActionClass({
  allConst: IMAGE_ALL,
  createConst: IMAGE_CREATE,
  deleteConst: IMAGE_DELETE,
  errorConst: IMAGE_ERROR,
  getConst: IMAGE_GET,
  putConst: IMAGE_UPDATE,
  backendPrefix: "image",
  description: "รูปภาพ",
})

export const getAllImage = (query) => imageActions.getAll(query)
export const getOneImage = (id) => imageActions.getOne(id)
export const createOneImage = (payload) => imageActions.createOne(payload)
export const editOneImage = (id, payload) => imageActions.editOne(id, payload)
export const deleteOneImage = (id) => imageActions.deleteOne(id)
