/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Button, Input } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import DayPickerInput from "react-day-picker/DayPickerInput"

import Layout from "../../../components/layout/BookingLayout"
import { Alert } from "../../../components/common/Alert"
import { SpinnerLoading } from "../../../components/Loading"

import * as actions from "../../../redux/actions"
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../../util"

export default function RoomTypeReservationSummary() {
  const [isLoading, setIsLoading] = useState(false)
  const [checkedInDate, setCheckedInDate] = useState(dayjs().toDate())
  const [page, setPage] = useState(1)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isShowAllDay, setIsShowAllDay] = useState(false)
  const room = useSelector((state) => state.rooms)
  const roomtype = useSelector((state) => state.roomTypes)
  const history = useHistory()

  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const getAllBooking = () => {
    dispatch(
      actions.getAllRoomsWithBooking({
        name,
        date: checkedInDate ? dayjs(checkedInDate).format("YYYY-MM-DD") : "",
        showAllDay: isShowAllDay || "",
      }),
    )
      .then(() => {
        dispatch(
          actions.getAllRoomTypes({
            name,
            date: checkedInDate ? dayjs(checkedInDate).format("YYYY-MM-DD") : "",
            showAllDay: isShowAllDay || "",
            fetchBooking: true,
          }),
        )

        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  useEffect(() => {
    setIsLoading(false)
    getAllBooking()
    return () => {}
  }, [page, name, checkedInDate, isShowAllDay])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const handlePreviousDay = () => {
    const newDate = dayjs(checkedInDate).subtract(1, "day").toDate()
    setCheckedInDate(newDate)
  }

  const handleNextDay = () => {
    const newDate = dayjs(checkedInDate).add(1, "day").toDate()
    setCheckedInDate(newDate)
  }

  const groupByTypeRoom = _.groupBy(room?.arr, "type_id")

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  const renderBackButton = () => (
    <div className="flex justify-between flex-wrap px-4 ">
      <div className="lg:w-1/4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>

      <div className="w-full lg:w-2/3 gap-2 px-2 flex flex-wrap lg:flex-no-wrap">
        <div className="lg:w-1/3">
          <Input
            type="text"
            placeholder={t("general.search")}
            size="sm"
            onChange={(event) => setSearchWord(event.target.value)}
          />
        </div>
        <div className="border">
          <DayPickerInput
            onDayChange={setCheckedInDate}
            value={checkedInDate}
            placeholder="วันที่ต้องการค้นหา"
            formatDate={(date) => dayjs(date)?.format("D MMM YYYY")}
            dayPickerProps={{
              locale: "th",
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
            }}
          />
        </div>
        <Button
          leftIcon={<i className="fas fa-chevron-left"></i>}
          size="sm"
          onClick={handlePreviousDay}
        >
          ย้อนกลับ 1 วัน
        </Button>
        <Button
          leftIcon={<i className="fas fa-chevron-right"></i>}
          size="sm"
          onClick={handleNextDay}
        >
          ไปหน้า 1 วัน
        </Button>
      </div>
    </div>
  )

  const renderDate = () => (
    <div className="flex flex-wrap justify-between">
      <div className="my-2 text-xl font-semibold lg:w-1/2">
        {dayjs(checkedInDate).locale(i18n.language).format("D MMMM YYYY")}
      </div>
    </div>
  )
  const renderTypeList = () => (
    <div className="grid md:grid-cols-2">
      {_.map(groupByTypeRoom, (eachType, index) => {
        const selectedRoomType = _.find(
          roomtype?.arr,
          (each) => each?._id === eachType?.[0]?.type?._id,
        )

        const roomAmount = _.size(eachType)
        const disable = _.size(_.filter(eachType, (eachRoom) => eachRoom?.disabled === true))
        const bookingAmount = _.sum(_.map(eachType, (eachRoom) => _.size(eachRoom?.bookings)))

        const bookingArray = []
        _.map(eachType, (each) => {
          _.map(each?.bookings, (eachBooking) => {
            bookingArray.push(eachBooking)
          })
        })

        console.log("Booking Array", bookingArray)

        const bookingStartToday = _.filter(
          bookingArray,
          (each) =>
            dayjs(each?.start).isAfter(dayjs(checkedInDate).startOf("day")) &&
            dayjs(each?.start).isBefore(dayjs(checkedInDate).endOf("day")),
        )

        const bookingCheckoutToday = _.filter(
          bookingArray,
          (each) =>
            dayjs(each?.end).isAfter(dayjs(checkedInDate).startOf("day")) &&
            dayjs(each?.end).isBefore(dayjs(checkedInDate).endOf("day")),
        )

        return (
          <div key={index} className="px-2">
            <div className="w-full p-4 my-1  rounded-md bg-white shadow-sm">
              <div className="font-bold text-2xl pb-2">
                {selectedRoomType?.translation?.[i18n.language]?.name ||
                  selectedRoomType?.name ||
                  "-"}
              </div>
              <div className="flex flex-wrap gap-2 my-2">
                <div className="md:w-1/4">
                  <i className="fas fa-door-closed" />
                  ทั้งหมด
                  <div className="text-xl font-semibold my-2">{roomAmount} ห้อง</div>
                </div>{" "}
                <div className="md:w-1/4">
                  <i className="fas fa-times-circle mr-1" />
                  ปิด{" "}
                  <div
                    className={`text-xl font-semibold my-2 ${disable > 0 ? "text-red-500" : ""} `}
                  >
                    {disable} ห้อง
                  </div>
                </div>{" "}
                {!isShowAllDay && (
                  <div className="md:w-1/4">
                    <i className="fas fa-bed mr-1" />
                    ระบุห้องแล้ว
                    <div className="text-xl font-semibold my-2"> {bookingAmount || 0} ห้อง</div>
                  </div>
                )}
                <div className="md:w-1/4">
                  <i className="fas fa-book mr-1" />
                  จองไว้{" "}
                  <div className="text-xl font-semibold my-2">
                    {selectedRoomType?.no_room_booking_amount || 0} ห้อง
                  </div>
                </div>
                {!isShowAllDay && (
                  <div className="md:w-1/4 ">
                    <i className="fas fa-door-open mr-1" />
                    ว่าง
                    <div className="text-xl font-semibold my-2 text-green-500">
                      {" "}
                      {roomAmount -
                        disable -
                        bookingAmount -
                        (selectedRoomType?.no_room_booking_amount || 0) || 0}{" "}
                      ห้อง
                    </div>
                  </div>
                )}
                <hr className="w-full my-2" />
                <div className="md:w-1/4 ">
                  <i className="fas fa-calendar mr-1" />
                  เข้าพักวันนี้
                  <div className="text-xl font-semibold my-2">{_.size(bookingStartToday)} ห้อง</div>
                </div>
                <div className="md:w-1/4 ">
                  <i className="fas fa-suitcase-rolling mr-1" />
                  เข้าพักมาก่อนแล้ว
                  <div className="text-xl font-semibold my-2">
                    {bookingAmount - _.size(bookingStartToday)} ห้อง
                  </div>
                </div>
                <hr className="w-full my-2" />
                <div className="md:w-1/4 ">
                  <i className="fas fa-sign-out-alt mr-1" />
                  ออกวันนี้
                  <div className="text-xl font-semibold my-2">
                    {_.size(bookingCheckoutToday)} ห้อง
                  </div>
                </div>
                <div className="md:w-1/4 ">
                  <i className="fas fa-dolly-flatbed mr-1" />
                  พักต่อ
                  <div className="text-xl font-semibold my-2">
                    {bookingAmount - _.size(bookingCheckoutToday)} ห้อง
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="my-2">
              <div className="grid grid-cols-4 gap-2 ">
                {eachType &&
                  _.map(eachType, (eachRoom, roomIndex) => (
                    <div className="p-2 rounded-md my-2 border bg-white " key={roomIndex}>
                      <div className="font-semibold text-lg">{eachRoom?.name}</div>
                      <div className="flex flex-row gap-2 ">
                        {_.map(eachRoom?.bookings, (eachBooking, bookingIndex) => (
                          <div
                            key={`booking${bookingIndex}`}
                            className="p-2 my-2 rounded-sm  border w-full"
                          >
                            {eachBooking?.status !== BookingStatus.success ? (
                              <Link
                                to={`/reservation/view/${eachBooking?._id}`}
                                className="hover:underline hover:font-semibold"
                              >
                                Booking No. {eachBooking?.booking_no}
                              </Link>
                            ) : (
                              <Link
                                to={`/history/view/${eachBooking?._id}`}
                                className="hover:underline hover:font-semibold"
                              >
                                Booking No. {eachBooking?.booking_no}
                              </Link>
                            )}
                            <div>
                              <BookingSourceBadge sourceOfBooking={eachBooking?.booking_source} />
                            </div>
                            <div className="mt-4">
                              <i className="fas fa-user mr-2"></i>{" "}
                              {eachBooking?.customer?.firstname || ""}{" "}
                              {eachBooking?.customer?.lastname || ""}{" "}
                            </div>
                            <div>
                              <i className="fas fa-sign-in-alt mr-2"></i>
                              {dayjs(eachBooking?.start)
                                .locale(i18n.language)
                                ?.format(isShowTime ? "D MMM  HH:mm" : "D MMM YYYY")}{" "}
                              <i className="fas fa-sign-out-alt mr-2"></i>
                              {dayjs(eachBooking?.end)
                                .locale(i18n.language)
                                ?.format(isShowTime ? "D MMM  HH:mm" : "D MMM YYYY")}{" "}
                            </div>
                            <div>
                              <Dot
                                word={eachBooking?.status}
                                color={selectTailwindStatusColor(eachBooking.status)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
          </div>
        )
      })}
    </div>
  )

  return (
    <Layout categoryTitle={t("reservation.title")} title="สรุปการจองแต่ละหมวดหมู่">
      {renderAlert()}
      {renderBackButton()}
      {isLoading ? (
        <div className="m-4">
          {renderDate()}
          {renderTypeList()}
        </div>
      ) : (
        <div>
          <SpinnerLoading />{" "}
        </div>
      )}
    </Layout>
  )
}
