import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import ModalUploadSlip from "../../Modal/ReservationManagement/ModalUploadSlip"

export default function PaymentSlipUploadButton({
  bookingInfo,
  handleEditBooking,
  handleCreateImage,
}) {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <div>
      <ModalUploadSlip
        bookingInfo={bookingInfo}
        handleEditBooking={handleEditBooking}
        handleOnClose={() => setIsPaymentModalOpen(false)}
        isOpen={isPaymentModalOpen}
        handleCreateImage={handleCreateImage}
      />
      <Button size="sm" colorScheme="purple" onClick={() => setIsPaymentModalOpen(true)}>
        {t("reservation.slipUpload")}
      </Button>
    </div>
  )
}
