export const PROMPTPAY_TYPE = {
  ID_CARD: {
    status_code: "ID_CARD",
    description: "เลขบัตรประจำตัวประชาชน",
    descriptionEn: "Thai National ID Card Number",
  },
  PHONE: {
    status_code: "PHONE",
    description: "หมายเลขโทรศัพท์มือถือ",
    descriptionEn: "Telephone Number",
  },
  E_WALLET: {
    status_code: "E_WALLET",
    description: "E-Wallet ต่าง ๆ",
    descriptionEn: "E-Wallet",
  },
}

export default PROMPTPAY_TYPE
