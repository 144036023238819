import {
  FB_LIST_ALL,
  FB_LIST_CREATE,
  FB_LIST_DELETE,
  FB_LIST_GET,
  FB_LIST_UPDATE,
  FB_LIST_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const fbOrderActions = new GeneralReduxActionClass({
  allConst: FB_LIST_ALL,
  createConst: FB_LIST_CREATE,
  deleteConst: FB_LIST_DELETE,
  errorConst: FB_LIST_ERROR,
  getConst: FB_LIST_GET,
  putConst: FB_LIST_UPDATE,
  backendPrefix: "fb-order-list",
  description: "รายการ Food and Beverage",
})

export const getAllFBOrderList = (query) => fbOrderActions.getAll(query)
export const getOneFBOrderList = (id) => fbOrderActions.getOne(id)
export const createOneFBOrderList = (payload) => fbOrderActions.createOne(payload)
export const editOneFBOrderList = (id, payload) => fbOrderActions.editOne(id, payload)
export const deleteOneFBOrderList = (id) => fbOrderActions.deleteOne(id)
