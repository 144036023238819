import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import { MeetingRoomOrderStatus, paymentStatus } from "../../../config/constant"
import Confirm from "../../common/Alert/ConfirmDialog"

export default function SuccessButton({ orderInfo, handleEditOrder }) {
  const currentStatus = orderInfo.status
  const currentPaymentStatus = orderInfo.paymentStatus
  const [confirmOpen, setIsConfirmOpen] = useState(false)
  const { t } = useTranslation()

  const handleOnSuccess = () => {
    handleEditOrder(orderInfo?.id, {
      completed: true,
    })
    setIsConfirmOpen(false)
  }

  return (
    <div>
      <Confirm
        title={t("reservation.confirmBookingStatusChange")}
        body="ยืนยันการเปลี่ยนสถานะการจองเป็น สำเร็จ"
        handleOnClose={() => setIsConfirmOpen(false)}
        handleSuccess={handleOnSuccess}
        isOpen={confirmOpen}
      />
      <Button
        size="sm"
        width="20"
        colorScheme="green"
        onClick={() => setIsConfirmOpen(true)}
        disabled={
          currentStatus?.completed || currentStatus?.deleted
          // ||currentPaymentStatus !== paymentStatus.allPayment.allPaymentConfirm
        }
      >
        {t("reservation.success")}
      </Button>
    </div>
  )
}
