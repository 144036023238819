import React from "react"
import { Button, Tooltip } from "@chakra-ui/react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

export default function RoomTypeList({
  roomList,
  handleEditRoom = () => {},
  handleDeleteRoom,
  handleChangeOnlineBookingOpen,
}) {
  const { t, i18n } = useTranslation()
  const roomByRoomType = _.groupBy(roomList, "type.id")

  const generateEachRoomTypeList = (eachRoomInType, index) => (
    <div key={index} className="my-2 ">
      <h3 className="my-2 text-lg font-bold">
        {i18n.language === "th"
          ? eachRoomInType?.[0]?.type?.translation?.th?.name || eachRoomInType?.[0]?.type?.name
          : eachRoomInType?.[0]?.type?.translation?.en?.name ||
            eachRoomInType?.[0]?.type?.translation?.th?.name}
      </h3>
      <hr />
      <div className="my-2 flex flex-wrap ">
        {eachRoomInType ? (
          _.map(eachRoomInType, (room, roomIndex) => (
            <div className="p-2 w-full lg:w-1/3 cursor-pointer hover:bg-gray-300" key={roomIndex}>
              <div className="bg-gray-200  rounded-md">
                <div className="flex justify-between p-4">
                  <h3 className="font-bold text-lg mx-4 ml-2 ">
                    <i className="fas fa-door-open mr-2" />
                    {room?.name}
                  </h3>
                  <div className="flex gap-2">
                    <Tooltip label="เปิดให้จองผ่านหน้าเว็บไซต์">
                      <Button
                        variant="outline"
                        size="sm"
                        colorScheme="green"
                        onClick={() => {
                          handleChangeOnlineBookingOpen(room?.id, !room?.online_booking_open)
                        }}
                      >
                        {room?.online_booking_open ? (
                          <i className="fas fa-store-alt"></i>
                        ) : (
                          <i className="fas fa-store-alt-slash"></i>
                        )}
                      </Button>
                    </Tooltip>
                    <Button
                      variant="outline"
                      size="sm"
                      colorScheme="blue"
                      onClick={() => {
                        handleEditRoom(room)
                      }}
                    >
                      <i className="fas fa-pencil-alt " />
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      colorScheme="red"
                      onClick={() => {
                        handleDeleteRoom(room?.id)
                      }}
                    >
                      <i className="fas fa-trash " />
                    </Button>
                  </div>
                </div>

                <div className="flex gap-2 items-center ml-6">
                  <p className="text-sm ">{room?.sub_type?.name}</p>
                  {_.map(room?.other_sub_type, (each, idx) => (
                    <p key={idx}>{each?.sub_type?.name}</p>
                  ))}
                  {room?.ota_reserved && (
                    <p className="text-sm font-semibold">{t("roomtype.otaReservation")}</p>
                  )}
                </div>
                <br />
              </div>
            </div>
          ))
        ) : (
          <div className=" p-4 flex justify-center">
            <p>{t("roomtype.emptyCategory")}</p>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div>
      {_.map(_.values(roomByRoomType), (eachRoomInType, index) =>
        generateEachRoomTypeList(eachRoomInType, index),
      )}
    </div>
  )
}
