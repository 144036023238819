import React from "react"

const CurveCardContentSlider = ({ register, curveFormName, watch }) => (
  <div>
    <label htmlFor="CurveCardContentSlider">ความโค้งปุ่ม:</label>
    <input
      type="range"
      id="CurveButtonSlider"
      defaultValue={0}
      min={0}
      max={100}
      name={curveFormName}
      ref={register}
    />
    <span>{watch(curveFormName)}%</span>
  </div>
)

export default CurveCardContentSlider
