import defaulttext from "../../assets/animation-style/default_text.png"
import flashtext from "../../assets/animation-style/flashtext.gif"
import fadeflash from "../../assets/animation-style/fadeflash.gif"
import staggering from "../../assets/animation-style/staggering.gif"
import TypeWriter from "../../assets/animation-style/TypeWriter.gif"
import Shine_effect from "../../assets/animation-style/Shine_effect.gif"

export const AnimationType = {
  DEFAULT_TEXT: {
    status_code: "DEFAULT_TEXT",
    description: "ไม่แสดงแอนนิเมชัน",
    image: defaulttext,
  },
  FLASH_TEXT: {
      status_code: "FLASH_TEXT",
      description: "แอนนิเมชั่นแบบกระพริบ",
      image: flashtext,
  },
  FADE_FLASH: {
      status_code: "FADE_FLASH",
      description: "แอนนิเมชั่นแบบกระพริบตอนเริ่มแรก",
      image: fadeflash,
  },
  ANIMATION_STAGGERING: {
    status_code: "ANIMATION_STAGGERING",
    description: "แอนนิเมชั่นแบบส่ายตอนเริ่มแรก",
    image: staggering,
  },
  TYPEWRITTER: {
    status_code: "TYPEWRITTER",
    description: "TypeWriter",
    image: TypeWriter,
  },
  SHINE_EFFECT: {
    status_code: "SHINE_EFFECT",
    description: "Shine_effect",
    image: Shine_effect,
}

};

export default AnimationType;


