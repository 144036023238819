import api from "../../../config/api"

import {
  SUB_ROOMTYPE_ALL,
  SUB_ROOMTYPE_CREATE,
  SUB_ROOMTYPE_DELETE,
  SUB_ROOMTYPE_GET,
  SUB_ROOMTYPE_PUT,
  SUB_ROOMTYPE_FROM_TYPE,
  SUB_ROOMTYPE_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const roomSubTypeActions = new GeneralReduxActionClass({
  allConst: SUB_ROOMTYPE_ALL,
  createConst: SUB_ROOMTYPE_CREATE,
  deleteConst: SUB_ROOMTYPE_DELETE,
  errorConst: SUB_ROOMTYPE_ERROR,
  getConst: SUB_ROOMTYPE_GET,
  putConst: SUB_ROOMTYPE_PUT,
  backendPrefix: "room-sub-type",
  description: "หมวดหมู่ย่อย",
})

export const getAllSubRoomTypes = (query) => roomSubTypeActions.getAll(query)
export const getOneSubRoomType = (id) => roomSubTypeActions.getOne(id)
export const createOneSubRoomType = (payload) => roomSubTypeActions.createOne(payload)
export const editOneSubRoomType = (id, payload) => roomSubTypeActions.editOne(id, payload)
export const deleteOneSubRoomType = (id) => roomSubTypeActions.deleteOne(id)

export const getAllSubTypeByRoomType = (roomTypeId) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/room-sub-type?typeId=${roomTypeId}`)
    .then((res) => {
      console.log("Request Server to Get All from selected room type - Sub Roomtypes")
      if (res.data) {
        dispatch({ type: SUB_ROOMTYPE_FROM_TYPE, payload: res.data?.rows })
      } else {
        dispatch({ type: SUB_ROOMTYPE_FROM_TYPE, payload: null })
      }
    })
    .catch((err) => {
      console.log("Error on Get All from selected room type - sub roomtype", err)
      dispatch({ type: SUB_ROOMTYPE_FROM_TYPE, payload: null })
    })
}
