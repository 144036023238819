import React from "react"
import { Badge } from "@chakra-ui/react"
import { bookingType } from "../../../config/constant"

export default function BookingTypeBadge({ typeOfBooking }) {
  if (typeOfBooking === bookingType.offilneBooking) {
    return <div></div>
  }
  if (typeOfBooking === bookingType.onlineBooking) {
    return (
      <div className="text-xs mt-1">
        <Badge size="sm" colorScheme="teal" rounded="lg">
          <span className="px-1"> จองผ่านเว็บไซต์ </span>
        </Badge>
      </div>
    )
  }
  return (
    <Badge size="sm" rounded="lg">
      {typeOfBooking}
    </Badge>
  )
}
