import React from "react"
import { ButtonType } from "../../../config/constant/ButtonTypeConstant"

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? (
    {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
  ) : (
    <>null</>
  )
}

const SliderTypeMobile = ({ watch }) => {
  const switchButtonType = watch("mobile.video.button.button_type")
  const buttonColorRGB = hexToRgb(watch("mobile.video.button.button_color"))

  switch (switchButtonType) {
    case ButtonType.ROUNDED.status_code:
      return (
        <div className="border-white  border-2 p-0 flex justify-center items-center">
          <div
            className="lg:ml-0 mb-2 rounded-md  flex justify-center "
            style={{
              width: `${watch("mobile.video.button.button_component_width")}px`,
              height: `${watch("mobile.video.button.button_component_length")}px`,
              backgroundColor: `rgba(${buttonColorRGB.r}, ${buttonColorRGB.g}, ${
                buttonColorRGB.b
              }, ${watch("mobile.video.button.button_background_opacity") / 100})`,
            }}
          >
            <p
              className="flex  items-center "
              style={{
                color: `${watch("mobile.video.button.button_text_color")}`,
              }}
            >
              {watch("mobile.video.translation.th.button_video")}
            </p>
          </div>
        </div>
      )

    case ButtonType.CIRCLE.status_code:
      return (
        <div className="border-white  border-2 p-0 flex justify-center items-center">
          <div
            className="lg:ml-0 mb-0 rounded-full  flex justify-center items-center bg-blue-500"
            style={{
              width: `${watch("mobile.video.button.button_component_width")}px`,
              height: `${watch("mobile.video.button.button_component_length")}px`,
              backgroundColor: `rgba(${buttonColorRGB.r}, ${buttonColorRGB.g}, ${
                buttonColorRGB.b
              }, ${watch("mobile.video.button.button_background_opacity") / 100})`,
            }}
          >
            <p
              className="flex  items-center "
              style={{
                color: `${watch("mobile.video.button.button_text_color")}`,
              }}
            >
              {watch("mobile.video.translation.th.button_video")}
            </p>
          </div>
        </div>
      )

    default:
      return (
        <div className="border-white border-2 p-0 flex justify-center items-center">
          <div
            className={`lg:ml-0 mb-2 flex justify-center bg-blue-500
          bg-opacity-${watch("mobile.video.button.button_background_opacity")}
          `}
            style={{
              width: `${watch("mobile.video.button.button_component_width")}px`,
              height: `${watch("mobile.video.button.button_component_length")}px`,
              backgroundColor: `rgba(${buttonColorRGB.r}, ${buttonColorRGB.g}, ${
                buttonColorRGB.b
              }, ${watch("mobile.video.button.button_background_opacity") / 100})`,
            }}
          >
            {console.log(watch("mobile.video.button.button_background_opacity"))}
            <p
              className="flex  items-center "
              style={{
                color: `${watch("mobile.video.button.button_text_color")}`,
              }}
            >
              {watch("mobile.video.translation.th.button_video")}
            </p>
          </div>
        </div>
      )
  }
}

export default SliderTypeMobile
