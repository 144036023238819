import React, { useContext } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { currencyFormatter, calculatePriceOnSubtype } from "../../../util"
import { OnlineBookingContext } from "../../../contexts/OnlineBookingContext"

function RoomSelectBySubType({ roomByRoomType, toggleSelectedRoom, selectedRooms }) {
  const { t } = useTranslation()
  const roomBySubType = _.values(_.groupBy(roomByRoomType, "sub_type.name"))
  const { checkinDate, checkoutDate } = useContext(OnlineBookingContext)

  const findingSubtypePrice = (eachSubtype) => {
    const priceOnSubtype = calculatePriceOnSubtype(
      eachSubtype,
      dayjs(checkinDate).toISOString(),
      dayjs(checkoutDate).toISOString(),
    )
    if (_.isArray(priceOnSubtype)) {
      if (_.min(priceOnSubtype) === _.max(priceOnSubtype)) {
        return currencyFormatter.format(_.min(priceOnSubtype))
      }
      return `${currencyFormatter.format(_.min(priceOnSubtype))} - ${currencyFormatter.format(
        _.max(priceOnSubtype),
      )}`
    }
    return currencyFormatter.format(priceOnSubtype)
  }
  return (
    <div>
      {_.map(roomBySubType, (subtype, roomTypeIndex) => (
        <div className="my-2 px-1">
          <div className="p-2 font-semibold ">
            {_.upperFirst(subtype?.[0]?.sub_type?.name)}
            <span className="font-light ml-4">
              {findingSubtypePrice(subtype?.[0]?.sub_type)} {t("general.baht")} /{" "}
              {t("roomtype.night")}
            </span>
          </div>

          <div className="flex flex-wrap ">
            {_.map(subtype, (eachRoom, eachRoomIndex) => (
              <div
                className={`p-1 w-1/2  lg:w-1/5 md:w-1/4  cursor-pointer bg-white hover:bg-gray-300`}
                onClick={() => toggleSelectedRoom(eachRoom)}
                key={roomTypeIndex}
              >
                <div
                  key={eachRoomIndex}
                  className={`${
                    _.includes(selectedRooms, eachRoom) ? `bg-violet-800 text-white` : `bg-gray-100`
                  } bg-gray-200 rounded-md`}
                >
                  <div className="flex justify-between p-2">
                    <h3 className={`mx-4 ml-2  font-sans`}>
                      <i className="fas fa-door-open mr-2 " />
                      {eachRoom?.name}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default RoomSelectBySubType
