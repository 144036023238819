/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import _ from "lodash"
import { useForm } from "react-hook-form"
import { Button, useToast } from "@chakra-ui/react"

import {
  GeneralMainLayout as Layout,
  PricePanel,
  ServiceDisplayPanel,
  BookingPriceAddingForm,
  WhitePanel,
  SpinnerLoading,
  Alert,
} from "../../../components"
import { dayAmount as dayAmountFunction } from "../../../util"
import * as actions from "../../../redux/actions"

export default function PriceCalculation() {
  const { t } = useTranslation()
  const bookingInfo = useSelector((state) => state.bookings)
  const rooms = useSelector((state) => state.rooms)
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const [rerender, setRerender] = useState(false)
  const [allPrice, setAllPrice] = useState()
  const [allDiscount, setAllDiscount] = useState()
  const [finalDiscount, setFinalDiscount] = useState(0)
  const dayAmount = dayAmountFunction(bookingInfo?.start, bookingInfo?.end)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const servicePriceArray = bookingInfo?.services?.map((eachList) => eachList?.price)
  const fbPriceArray = bookingInfo?.fb_orders?.map((eachList) => eachList?.price)
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {
      list: _.isArray(bookingInfo?.list) ? bookingInfo?.list : [],
      use_discount_percent: bookingInfo?.use_discount_percent,
    },
  })

  useEffect(() => {
    dispatch(actions.getOneBooking(params?.id))
    dispatch(actions.getAllRooms({}))
    return () => {}
  }, [params])

  useEffect(() => {
    let timeout
    if (_.isArray(bookingInfo?.list)) {
      setAllPrice(bookingInfo?.list?.map((eachList) => eachList?.price * dayAmount))
      setAllDiscount(bookingInfo?.list?.map((eachList) => eachList?.discount * dayAmount))
      setFinalDiscount(bookingInfo?.final_discount || 0)
      setValue("list", bookingInfo?.list)
      console.log("Setting List", bookingInfo?.list)
      timeout = setTimeout(() => {
        setIsLoading(true)
      }, 500)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [bookingInfo])

  const handleEditPaymentPrice = (data) => {
    // todo: มันจะดีมากเลยถ้ามีการเก็บ Payment Transaction แยกอีกทีหนึ่ง
    const bookingPayload = {
      ...bookingInfo,
      ...data,
      skipTransactionCreate: true,
    }
    console.log("Payload Data", bookingPayload)
    dispatch(actions.editOneBooking(bookingInfo?._id, { ...data, skipTransactionCreate: true }))
      .then(() => {
        dispatch(actions.getOneBooking(params?.id))
          .then(() => {
            toast({
              title: "แก้ไขการจองสำเร็จ",
              status: "success",
            })
            history.goBack()
          })
          .catch((err) => {
            setIsError(true)
            setErrorMessage(err?.message)
          })
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.priceCalculation")}>
      <Alert
        errMessage={errorMessage}
        isError={isError}
        handleOnClose={() => setIsError(false)}
        title="แก้ไขการจองมีปัญหา"
      />
      <div className="px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(handleEditPaymentPrice)}>
          <WhitePanel>
            <div className=" w-full ">
              <BookingPriceAddingForm
                allDiscount={allDiscount}
                allPrice={allPrice}
                bookingInfo={bookingInfo}
                control={control}
                endDate={bookingInfo?.end}
                finalDiscount={finalDiscount}
                register={register}
                rerender={rerender}
                rooms={rooms}
                setAllDiscount={setAllDiscount}
                setAllPrice={setAllPrice}
                setFinalDiscount={setFinalDiscount}
                setRerender={setRerender}
                setValue={setValue}
                startDate={bookingInfo?.start}
                watch={watch}
                inputBookingList={[]}
              />
              <hr />
              <h6 className="text-xs font-bold mt-2">{t("reservation.otherService")} </h6>
              <div className="mb-2">
                <ServiceDisplayPanel services={bookingInfo?.services} />
              </div>
              <hr />
              <PricePanel
                allPrice={
                  _.sum(allPrice) +
                  _.sum(servicePriceArray) +
                  _.sum(fbPriceArray) -
                  _.sum(allDiscount) -
                  finalDiscount
                }
                bookingDiscount={_.sum(allDiscount)}
                finalDiscount={finalDiscount}
                bookingInfo={bookingInfo}
                showTransaction={false}
              />
              <hr />
            </div>{" "}
          </WhitePanel>
          <div>
            <Button colorScheme="purple" isFullWidth type="submit">
              {t("general.save")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
