/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory, Link } from "react-router-dom"
import dayjs from "dayjs"
import { Table, Button as AntButton } from "antd"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  Alert,
  GeneralMainLayout as Layout,
  ReportDateSelector,
  CSVGeneratorPanel,
} from "../../components"
import * as actions from "../../redux/actions"
import { bookingAnalysis } from "../../util"
import { appConfig } from "../../config"
import { FBReport } from "../../config/pdfmake/template"
import api from "../../config/api"

function FoodAndBeverageReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [date, setDate] = useState(new Date())
  const [page, setPage] = useState(1)
  const size = 10
  const [isLoading, setIsLoading] = useState(false)
  const [orderByField, setOrderByField] = useState("name")
  const [orderBy, setOrderBy] = useState("asc")
  const [excelExportOpen, setExcelExportOpen] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const fbOrder = useSelector((state) => state.fbOrder)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const dispatch = useDispatch()

  useEffect(() => {
    setExcelExportOpen(false)
    dispatch(
      actions.getAllFBOrder({
        page,
        size,
        query: {
          start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
          end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
          date: date ? dayjs(date).format("YYYY-MM-DD") : "",
          orderBy,
          orderByField,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [date, page, orderBy, orderByField, startDate, endDate])

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "เลขที่บิล",
      dataIndex: "bill_number",
      sorter: true,
      render: (text, record) => record?.bill_number,
    },
    {
      title: "โต๊ะ",
      dataIndex: "table",
      sorter: true,
      render: (text, record) => record?.table?.name || "-",
    },
    {
      title: "รายการจองห้อง",
      dataIndex: "booking",
      render: (text, record) => record?.booking?.booking_no || "-",
    },
    {
      title: "วันที่",
      dataIndex: "date",
      render: (text, record) => dayjs(record?.date).format("D MMM YYYY HH:mm"),
      sorter: true,
    },
    {
      title: "ราคา",
      dataIndex: "price",
      render: (text, record) => record?.price || "-",
    },
    {
      title: "รายละเอียด",
      dataIndex: "info",
      render: (text, record) => (
        <div>
          <Link
            to={`/food-beverage/order/detail/${record?._id}`}
            className="font-semibold hover:text-gray-500 cursor-pointer"
          >
            <AntButton icon={<i className="fas fa-file-alt" />}></AntButton>
          </Link>
        </div>
      ),
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderBy("asc")
    } else {
      setOrderBy("desc")
    }

    if (sorter?.field === "date") {
      setOrderByField("date")
    }

    if (sorter?.field === "bill_number") {
      setOrderByField("bill_number")
    }

    if (sorter?.field === "table") {
      setOrderByField("table")
    }
  }

  const fullQueryParams = new URLSearchParams({
    page: 1,
    size: appConfig.maxFetchSize,
    date: date ? dayjs(date).format("YYYY-MM-DD") : "",
    orderBy,
    orderByField,
    start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
    end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
  })

  const handlePrintPDF = async () => {
    try {
      setIsLoading(false)
      api
        .get(`${process.env.REACT_APP_API_URL}/fb-order/?${fullQueryParams.toString()}`)
        .then((res) => {
          const ReportDefinition = FBReport(
            res?.data?.rows,
            date,
            startDate,
            endDate,
            date !== null,
            information,
            system,
          )
          console.log("ReportDefinition", ReportDefinition)
          pdfMake.createPdf(ReportDefinition).open()
        })
        .catch((error) => {
          alert(error?.message)
        })
        .finally(() => {
          setIsLoading(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout categoryTitle={t("history.title")} title={"รายงานอาหารและเครื่องดื่ม"}>
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            colorScheme="red"
            size="sm"
            leftIcon={<i className="fas fa-file-pdf"></i>}
            onClick={() => handlePrintPDF()}
          >
            PDF
          </Button>
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<i className="fas fa-file-excel"></i>}
            onClick={() => {
              if (excelExportOpen === true) {
                setExcelExportOpen(false)
                setTimeout(() => {
                  setExcelExportOpen(true)
                }, 500)
              } else {
                setExcelExportOpen(true)
              }
            }}
          >
            CSV
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <ReportDateSelector
          pointDate={date}
          setPointDate={setDate}
          endDate={endDate}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <CSVGeneratorPanel
          apiSuffix="fb-order"
          columns={columns}
          open={excelExportOpen}
          queryParams={fullQueryParams}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={fbOrder?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: fbOrder?.total,
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default FoodAndBeverageReport
