/* eslint-disable no-underscore-dangle */
import React from "react"
import _ from "lodash"
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function UnAssignRoomList({ unAssignBookingList }) {
  const filteredBookingList = _.filter(
    unAssignBookingList.no_room_booking,
    (each) => each?.amount > 0,
  )
  const { i18n } = useTranslation()
  return (
    <div className="flex flex-wrap">
      {_.map(filteredBookingList, (eachBookingList, index) => (
        <div className=" mb-2 px-1 " key={index}>
          <Popover placement="auto-end">
            <PopoverTrigger>
              <div className="bg-blue-200 p-2 rounded-md text-center text-sm hover:bg-blue-300 cursor-pointer">
                {eachBookingList?.roomtype?.translation?.[i18n.language]?.name} <br />{" "}
                {eachBookingList?.subtype?.name && `(${eachBookingList?.subtype?.name})`} x{" "}
                {eachBookingList?.amount}
              </div>
            </PopoverTrigger>
            <PopoverContent width="32">
              <PopoverArrow />
              <PopoverBody>
                <Link
                  to={`/reservation/assign/${unAssignBookingList?._id}?roomtype=${eachBookingList?.roomtype?._id}&amount=${eachBookingList?.amount}`}
                >
                  <Button size="sm" colorScheme="blue">
                    จัดสรรห้อง
                  </Button>
                </Link>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </div>
      ))}
    </div>
  )
}
