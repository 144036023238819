import api from "../../../config/api"

import { BED_ALL, BED_CREATE, BED_GET, BED_PUT, BED_DELETE } from "../types"

// faker.locale = "th"

export const getAllBeds = () => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/bed/`)
    .then((res) => {
      if (res.data) {
        dispatch({ type: BED_ALL, payload: res.data })
      } else {
        dispatch({ type: BED_ALL, payload: null })
      }
    })
    .catch((err) => {
      console.log("Error on Get All Beds", err)
      dispatch({ type: BED_ALL, payload: null })
    })
}

export const getOneBed = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/bed/${id}`).then((res) => {
    console.log("Request Server to Get One Bed")
    if (res.data) {
      dispatch({ type: BED_GET, payload: res.data })
    } else {
      dispatch({ type: BED_GET, payload: null })
    }
  })
}
export const createOneBed = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/bed/`, payload).then(() => {
    console.log("Request Server to Create New Bed")
    dispatch({ type: BED_CREATE })
  })
}

export const editOneBed = (id, payload) => async (dispatch) => {
  await api.put(`${process.env.REACT_APP_API_URL}/bed/${id}`, payload).then(() => {
    console.log("Request Server to edit bed")
    dispatch({ type: BED_PUT })
  })
}

export const deleteOneBed = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/bed/${id}`).then(() => {
    console.log("Request Server to Delete One Bed")
    dispatch({ type: BED_DELETE, payload: null })
  })
}
