import React from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export default function PaymentButton({ bookingInfo }) {
  const { t } = useTranslation()
  return (
    <div>
      <Link to={`/reservation/payment/${bookingInfo.id}`}>
        <Button size="sm" colorScheme="purple">
          {t("reservation.payment")}
        </Button>
      </Link>
    </div>
  )
}
