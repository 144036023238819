import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

export default function SelectedRoomList({ bookingList }) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-wrap">
      {_.map(bookingList.list, (eachBookingList, index) => (
        <div className=" mb-2 px-1 " key={index}>
          <div className="bg-gray-200 p-2 rounded-md text-center text-sm">
            {eachBookingList?.room?.name || t("roomtype.undefinedRoom")}
          </div>
        </div>
      ))}
    </div>
  )
}
