import api from "../../../config/api"

import { LOG_ALL, LOG_CREATE, LOG_GET, LOG_UPDATE, LOG_DELETE } from "../types"

// faker.locale = "th"

export const getAlllog =
  ({
    page = 1,
    size = "",
    startDate = "",
    endDate = "",
    start = "",
    end = "",
    orderByField = "",
    orderBy = "",
    roomType = "",
    name = "",
  }) =>
  async (dispatch) => {
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/log/?page=${page}&size=${size}&startDate=${startDate}&endDate=${endDate}&start=${start}&end=${end}&roomType=${roomType}&orderByField=${orderByField}&orderBy=${orderBy}&name=${name}`,
      )
      .then((res) => {
        console.log("Request Server to Get All log according to the query")
        if (res.data) {
          dispatch({ type: LOG_ALL, payload: res.data })
        } else {
          dispatch({ type: LOG_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All log", err)
        dispatch({ type: LOG_ALL, payload: null })
      })
  }

export const getOnelog = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/log/${id}`).then((res) => {
    console.log("Request Server to Get One log")
    if (res.data) {
      dispatch({ type: LOG_GET, payload: res.data })
    } else {
      dispatch({ type: LOG_GET, payload: null })
    }
  })
}
export const createOnelog = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/log/`, payload).then(() => {
    console.log("Request Server to Create New log")
    dispatch({ type: LOG_CREATE })
  })
}

export const convertLogFromBooking = (bookingId) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/log/transfer/${bookingId}`).then(() => {
    console.log("Request Server To Transfer Booking into log")
    dispatch({ type: LOG_CREATE })
  })
}

export const editOnelog = (id, payload) => async (dispatch) => {
  await api.put(`${process.env.REACT_APP_API_URL}/log/${id}`, payload).then(() => {
    console.log("Request Server to edit log")
    dispatch({ type: LOG_UPDATE })
  })
}

export const deleteOnelog = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/log/${id}`).then(() => {
    console.log("Request Server to Delete One Log")
    dispatch({ type: LOG_DELETE, payload: null })
  })
}
