import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export default function RoomTypeExample({ rooms, info }) {
  const { i18n } = useTranslation()

  const findHilightImage = (images) => {
    if (_.isEmpty(images) || !images) {
      return "https://storage.googleapis.com/mydev_eonlineshop/other/thumbnails/1645339249181_800x800.png"
    }

    const foundHilight = _.find(images, { hilight: true })
    if (foundHilight) {
      return foundHilight?.image?.url
    }
    return images?.[0]?.image?.url
  }

  return (
    <div id="room" className=" h-full w-full md:items-center ">
      <div className="mx-0 sm:mx-6 ">
        <div className="bg-white w-full p-8 md:p-full rounded-xl">
          <h1 className="text-center font-bold text-gray-600 text-3xl">
            {info.composite?.translation?.[i18n.language].room_header || "ห้องพัก"}{" "}
            <Link to="/site/room-description">
              <Button size="sm">แก้ไข</Button>
            </Link>
          </h1>
          <h2 className="text-center py-5 text-xl text-gray-400">
            {info.composite?.translation?.[i18n.language]?.room_brief || ""}
          </h2>
          <section className="pt-8 px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/** แสดงทุกห้องที่ตั้งค่าว่าแสดงผล หรือว่า enable เป็น true */}
              {_.map(_.filter(rooms, { enable: true }), (row) => (
                <div>
                  <div
                    className="px-4 mb-8 transform  motion-safe:hover:scale-110 hover:cursor-pointer"
                    key={row?.id}
                  >
                    <div className="flex items-center p-4 justify-center" style={{ height: 150 }}>
                      <img
                        objectFit="cover"
                        loading="eager"
                        quality="10"
                        src={findHilightImage(row?.images)}
                        alt={row?.name}
                        className="rounded-lg "
                      />
                    </div>
                    <div className="p-1 text-blue-600 text-xl h-20 flex items-center justify-center">
                      <h1 className="text-center ">{row?.translation?.[i18n.language]?.name}</h1>
                    </div>
                    <p className="text-center pt-0 text-gray-500 text-base  ">
                      {_.truncate(row?.translation?.[i18n.language]?.description, { length: 150 })}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
