import React, { useState } from "react"
import { Input, Button } from "antd"

const PurchaseOrderAmountAddingPanel = ({ appendFunction, selectedData, disabled = false }) => {
  const [amount, setAmount] = useState(1)
  return (
    <td className="flex gap-2">
      <Input
        defaultValue={1}
        placeholder="จำนวน"
        size="sm"
        onChange={(e) => setAmount(e.target.value)}
        disabled={disabled}
      />
      <Button
        type="primary"
        onClick={() => {
          appendFunction({
            ...selectedData,
            amount,
          })
        }}
        disabled={disabled}
      >
        เพิ่ม
      </Button>
    </td>
  )
}

export default PurchaseOrderAmountAddingPanel
