export const PaymentMethod = {
  CASH: {
    status_code: "CASH",
    description: "เงินสด",
    descriptionEN: "Cash",
  },
  BANK_TRANSFER: {
    status_code: "BANK_TRANSFER",
    description: "โอนเงิน",
    descriptionEN: "Bank Transfer",
  },
  ONLINE_SYSTEM: {
    status_code: "ONLINE_SYSTEM",
    description: "ระบบออนไลน์",
    descriptionEN: "Online System",
  },
  CARD: {
    status_code: "CARD",
    description: "บัตรเครดิต/เดบิต",
    descriptionEN: "Credit / Debit Card Payment",
  },
  INVOICE: {
    status_code: "INVOICE",
    description: "วางบิล/รับหนี้",
    descriptionEN: "Create Invoice",
  },
  // GOVERNMENT_POLICY: {
  //   status_code: "GOVERNMENT_POLICY",
  //   description: "นโยบายภาครัฐ เช่น เราเที่ยวด้วยกัน",
  //   descriptionEN: "Government Policy",
  // },
  // E_WALLET: {
  //   status_code: "E_WALLET",
  //   description: "E-Wallet เช่น Wechat Pay, Alipay, Rabbit Line Pay",
  //   descriptionEN: "E-Wallet like Wechat Pay, Alipay, Rabbit Line Pay,",
  // },
}

export default PaymentMethod
