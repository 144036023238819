/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import dayjs from "dayjs"
import { Select, Table, Radio, Space } from "antd"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  Alert,
  GeneralMainLayout as Layout,
  ReportDateSelector,
  CSVGeneratorPanel,
  PaymentSummaryPanel,
} from "../../components"
import * as actions from "../../redux/actions"
import { bookingAnalysis, dayAmount } from "../../util"
import { TransactionType, PaymentMethod } from "../../config/constant"
import { appConfig } from "../../config"
import { PaymentTransactionReport } from "../../config/pdfmake/template"
import api from "../../config/api"

function BillReport() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [date, setDate] = useState(new Date())
  const [page, setPage] = useState(1)
  const size = 10
  const [isLoading, setIsLoading] = useState(false)
  const [orderByField, setOrderByField] = useState("name")
  const [orderBy, setOrderBy] = useState("asc")
  const [excelExportOpen, setExcelExportOpen] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedPaymentType, setSelectedPaymentType] = useState()
  const [showTime, setShowTime] = useState(false)
  const [selectedShift, setSelectedShift] = useState()

  const transaction = useSelector((state) => state.transaction)
  const bill = useSelector((state) => state.bill)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.getAllRoomTypes({ name: "", page: 1, size: appConfig.maxFetchSize }))
    dispatch(actions.getOneBill(params.id))
    return () => {}
  }, [])

  useEffect(() => {
    setExcelExportOpen(false)
    dispatch(
      actions.getAllTransaction({
        page,
        size: 20,
        query: {
          transactionType: TransactionType.CREATE_PAYMENT.status_code,
          date: date ? dayjs(date).format("YYYY-MM-DD") : "",
          start: startDate ? dayjs(startDate).toISOString() : "",
          end: endDate ? dayjs(endDate).toISOString() : "",
          orderByField,
          orderBy,
          paymentMethod: selectedPaymentType || "",
          bill: params?.id,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [date, page, orderBy, orderByField, startDate, endDate, selectedPaymentType])

  const findNumberOnPaymentMethod = (record, paymentType) => {
    if (record?.payment_method === paymentType?.status_code) {
      return record?.price
    }
    return 0
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "วันที่จ่าย",
      dataIndex: "createdAt",
      render: (text, record) => dayjs(record?.createdAt).format("D MMM HH:mm"),
      sorter: true,
    },
    {
      title: "หมายเลขการจอง",
      dataIndex: "bookings",
      render: (text, record) => `  ${record?.booking?.booking_no || "-"}`,
    },
    {
      title: "เบอร์ห้อง",
      dataIndex: "bookings",
      render: (text, record) => "",
    },
    {
      title: "ลูกค้า",
      dataIndex: "booking",
      render: (text, record) =>
        `${record?.booking?.customer?.firstname || "-"} ${
          record?.booking?.customer?.lastname || ""
        }`,
    },
    {
      title: "ช่องทางการชำระเงิน",
      dataIndex: "booking",
      render: (text, record) => PaymentMethod[record?.payment_method]?.description,
    },
    {
      title: "วันที่เข้าพัก",
      dataIndex: "booking",
      render: (text, record) =>
        record?.booking?.checkin?.timestamp
          ? `${dayjs(record?.booking?.checkin?.timestamp).format("dd D/MM/YY HH:mm")}`
          : "-",
    },
    {
      title: "เรทห้อง",
      dataIndex: "booking",
      render: (text, record) =>
        _.map(record?.booking?.list, (eachBookingList) => `${eachBookingList?.price}, `),
    },
    {
      title: "จำนวนคืน",
      dataIndex: "booking",
      render: (text, record) => dayAmount(record?.booking?.start, record?.booking?.end),
    },
    {
      title: "ราคารวม",
      dataIndex: "booking",
      render: (text, record) => {
        const bookingWithAnalysis = bookingAnalysis(record?.booking)
        return bookingWithAnalysis?.price
      },
    },
    {
      title: "สินค้า/บริการ",
      dataIndex: "booking",
      render: (text, record) =>
        _.sum(_.map(record?.booking?.services, (each) => each?.price * each?.amount)),
    },
    // ..._.map(PaymentMethod, (eachMethod) => ({
    //   title: eachMethod.description,
    //   dataIndex: "booking",
    //   render: (text, record) => findNumberOnPaymentMethod(record, eachMethod),
    // })),
    // {
    //   title: "ไม่ระบุ",
    //   dataIndex: "booking",
    //   render: (text, record) => findNumberOnPaymentMethod(record, {}),
    // },
    {
      title: "ช่องทางการชำระเงิน",
      dataIndex: "payment_method",
      sorter: true,
      render: (text, record) => PaymentMethod[record?.payment_method]?.description || "-",
    },
    {
      title: "รวมจำนวนเงินที่ชำระ",
      dataIndex: "price",
      sorter: true,
      render: (text, record) => record?.price,
    },
    {
      title: "พนักงาน",
      dataIndex: "bookings",
      render: (text, record) => record?.user?.employee?.firstname,
    },
    { title: "รายละเอียด", dataIndex: "info", render: (text, record) => record?.info },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderBy("asc")
    } else {
      setOrderBy("desc")
    }

    if (sorter?.field === "name") {
      setOrderByField("name")
    }
    if (sorter?.field === "bookings") {
      setOrderByField("bookings.booking_no")
    }
    if (sorter?.field === "type") {
      setOrderByField("type.translation.th.name")
    }
  }

  const handleChangeShiftTime = (tempSelectedShift) => {
    const foundTimeShift = _.find(
      system?.setting?.timeshifts,
      (each) => each?._id === tempSelectedShift,
    )
    if (foundTimeShift) {
      const startTime = dayjs(date)
        .hour(foundTimeShift?.startTime?.hour)
        .minute(foundTimeShift?.startTime?.minute)

      const endTime = dayjs(date)
        .hour(foundTimeShift?.endTime?.hour)
        .add(foundTimeShift?.endTime?.hour - foundTimeShift?.startTime?.hour >= 0 ? 0 : 1, "day")
        .minute(foundTimeShift?.endTime?.minute)

      setStartDate(startTime.toDate())
      setEndDate(endTime.toDate())
      setShowTime(true)
      setSelectedShift(tempSelectedShift)
    } else {
      setShowTime(false)
      setSelectedShift()
      setStartDate()
      setEndDate()
    }
  }

  useEffect(() => {
    handleChangeShiftTime()
    setStartDate()
    setEndDate()

    return () => {}
  }, [date])

  const fullQueryParams = new URLSearchParams({
    page: 1,
    size: appConfig.maxFetchSize,
    date: date ? dayjs(date).format("YYYY-MM-DD") : "",
    fetchBooking: true,
    orderBy,
    orderByField,
    transactionType: TransactionType.CREATE_PAYMENT.status_code,
    start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
    end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
  })

  const handlePrintPDF = async () => {
    try {
      setIsLoading(false)
      api
        .get(`${process.env.REACT_APP_API_URL}/transaction/?${fullQueryParams.toString()}`)
        .then((res) => {
          const ReportDefinition = PaymentTransactionReport({
            transactions: _.map(res?.data?.rows, (each) => ({
              ...each,
              booking: bookingAnalysis(each.booking),
            })),
            endDate,
            startDate,
            information,
            systemInfo: system,
            date,
            selectedShift,
            bill: params.id,
          })
          console.log("ReportDefinition", ReportDefinition)
          pdfMake.createPdf(ReportDefinition).open()
        })
        .catch((error) => {
          alert(error?.message)
        })
        .finally(() => {
          setIsLoading(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout categoryTitle={t("history.title")} title={"รายงานการชำระเงินในแต่ละรอบบิล"}>
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            colorScheme="red"
            size="sm"
            leftIcon={<i className="fas fa-file-pdf"></i>}
            onClick={() => handlePrintPDF()}
          >
            PDF
          </Button>
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<i className="fas fa-file-excel"></i>}
            onClick={() => {
              if (excelExportOpen === true) {
                setExcelExportOpen(false)
                setTimeout(() => {
                  setExcelExportOpen(true)
                }, 500)
              } else {
                setExcelExportOpen(true)
              }
            }}
          >
            CSV
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <ReportDateSelector
          pointDate={date}
          setPointDate={setDate}
          endDate={endDate}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          showTime={showTime}
          showTimeDescription={false}
        >
          <div>
            <div className="">หมายเลขรอบบิล</div>
            <div className="text-xl font-semibold">{_.padStart(bill?.bill_no, 4, "0") || "-"}</div>
            <div className="text-lg">
              {dayjs(bill?.createdAt).format("D MMM YYYY เวลา HH:mm")} -{" "}
              {bill?.active ? "ปัจจุบัน" : dayjs(bill?.closed_date).format("D MMM YYYY เวลา HH:mm")}
            </div>
            <div className="my-2 text-sm">การกรองเพิ่มเติม</div>
            <Select
              onChange={(value) => setSelectedPaymentType(value)}
              placeholder="ช่องทางการชำระเงิน"
              style={{
                width: 200,
              }}
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? "").includes(input)}
              showSearch
              options={_.map(PaymentMethod, (eachPaymentMethod) => ({
                value: eachPaymentMethod?.status_code,
                label:
                  i18n?.language === "th"
                    ? eachPaymentMethod?.description
                    : eachPaymentMethod?.descriptionEN,
              }))}
            ></Select>
          </div>
        </ReportDateSelector>
        <PaymentSummaryPanel transactions={transaction} bill={bill} displayOutcome />
        <CSVGeneratorPanel
          apiSuffix="transaction"
          columns={columns}
          open={excelExportOpen}
          queryParams={fullQueryParams}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={transaction?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: transaction?.total,
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default BillReport
