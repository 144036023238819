import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { CardProgress, WhitePanel, CheckinCheckoutSearchPanel } from "../../components"
import { OnlineBookingProgress } from "../../config/constant"
import { appConfig } from "../../config"
import * as actions from "../../redux/actions"

function DateSearch() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const roomTypes = useSelector((state) => state.roomTypes)
  const [checkedInDate, setCheckedInDate] = useState(dayjs().toDate())
  const [checkoutDate, setCheckoutDate] = useState(dayjs().add(1, "day").toDate())
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRoomType, setSelectedRoomType] = useState({})

  useEffect(() => {
    dispatch(
      actions.getAllRoomTypes({ page: 1, size: appConfig.maxFetchSize, fetchBooking: true }),
    ).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  return (
    <Layout categoryTitle={t("reservation.onlineBooking")} title={t("reservation.roomSearch")}>
      <div className="overflow-x-auto">
        <CardProgress stepList={OnlineBookingProgress} selectedIndex={0} />
      </div>
      {isLoading && (
        <WhitePanel>
          <CheckinCheckoutSearchPanel
            checkedInDate={checkedInDate}
            checkoutDate={checkoutDate}
            setCheckedInDate={setCheckedInDate}
            setCheckoutDate={setCheckoutDate}
            roomTypes={roomTypes}
            onSearch={() => {
              history.push(
                `/online-booking/room-select?checkin=${dayjs(checkedInDate).format(
                  "YYYY-MM-DD",
                )}&checkout=${dayjs(checkoutDate).format("YYYY-MM-DD")}&roomtype=${
                  selectedRoomType?.value || ""
                }`,
              )
            }}
            setSelectedRoomType={setSelectedRoomType}
          />
        </WhitePanel>
      )}
    </Layout>
  )
}

export default DateSearch
