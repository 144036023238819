import {
    BACKGROUND_GALLERY_ALL,
    BACKGROUND_GALLERY_CREATE,
    BACKGROUND_GALLERY_DELETE,
    BACKGROUND_GALLERY_GET,
    BACKGROUND_GALLERY_PUT,
  } from "../../actions/types"
  
  const initialState = {
    gallery: null,
    isLoading: false,
  }
  // eslint-disable-next-line func-names
  export default function (state = initialState, action) {
    switch (action.type) {
      case BACKGROUND_GALLERY_ALL:
        return {
          arr: action.payload?.rows,
          currPage: action.payload?.currPage,
          lastPage: action.payload?.lastPage,
          isLoading: true,
        }
      case BACKGROUND_GALLERY_GET:
        return {
          ...action.payload,
          isLoading: true,
        }
      case BACKGROUND_GALLERY_CREATE:
        return { isLoading: true }
      case BACKGROUND_GALLERY_PUT:
        return { isLoading: true }
      case BACKGROUND_GALLERY_DELETE:
        return { isLoading: true }
      default:
        return state
    }
  }
  