import React from "react"

const SliderPaddingPicture = ({
  register,
  // width_picture,
  height_picture,
  leftPaddingPicture,
  rightPaddingPicture,
  topPaddingPicture,
  bottomPaddingPicture,
  watch,
}) => (
  <div className="mb-4 pt-6">
    <div className="pb-4">
      <h1 className="text-xl bg-red-400 text-white p-2  border-4 flex  justify-center border-black">
        พื้นที่ส่วนรูปภาพ
      </h1>
    </div>
    <label className="block text-lg font-medium text-gray-700">ระยะห่างจากพื้นที่ด้านขวา</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={1000}
      name={rightPaddingPicture}
      ref={register}
      className="slider"
    />
    <span className="text-lg font-bold text-blue-700 pl-4 ">{watch(rightPaddingPicture)}px</span>
    <label className="block text-lg font-medium text-gray-700">ระยะห่างจากพื้นที่ด้านซ้าย</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={2000}
      name={leftPaddingPicture}
      ref={register}
      className="slider"
    />
    <span className="text-lg font-bold text-blue-700 pl-4 ">{watch(leftPaddingPicture)}px</span>
    <label className="block text-lg font-medium text-gray-700">ระยะห่างจากพื้นที่ด้านล่าง</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={500}
      name={bottomPaddingPicture}
      ref={register}
      className="slider"
    />
    <span className="text-lg font-bold text-blue-700 pl-4 ">{watch(bottomPaddingPicture)}px</span>
    <label className="block text-lg font-medium text-gray-700">ระยะห่างจากพื้นที่ด้านบน</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={500}
      name={topPaddingPicture}
      ref={register}
      className="slider"
    />
    <span className="text-lg font-bold text-blue-700 pl-4 ">{watch(topPaddingPicture)}px</span>

    <label className="block text-lg font-medium text-gray-700">ความสูง:</label>
    <input
      type="range"
      defaultValue={375}
      min={1}
      max={2000}
      name={height_picture}
      ref={register}
    />
    <span className="text-lg font-bold text-blue-700 pl-4 ">{watch(height_picture)}px</span>
    <br />
  </div>
)

export default SliderPaddingPicture
