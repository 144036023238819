import React from "react"
import { useForm } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import axios from "axios"

export default function EmailTest() {
  const { handleSubmit, register } = useForm()

  const onSendTestEmail = async (data) => {
    const confirm = window.confirm("ยืนยันการส่งอีเมลทดสอบ")
    if (confirm) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/sandbox/email`, data)
        .then(() => {
          window.alert("ส่งอีเมลเสร็จสิ้น")
        })
        .catch((err) => {
          window.alert(
            "เกิดปัญหาขึ้นกับการส่งอีเมล ถ้าอยู่ใน Development Mode เปิด Console เพื่อดูรายละเอียดปัญหา",
          )
          console.error(err)
        })
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSendTestEmail)}>
        <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
          <div className="w-full px-4 ">
            <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">ส่งอีเมลทดสอบ</h6>
            <div className="flex gap-2">
              <div className="relative w-full mb-3 ">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  อีเมลปลายทาง ที่ต้องการรับอีเมลทดสอบ
                </label>
                <div className="flex gap-2">
                  <input
                    name="emailAddress"
                    type="email"
                    ref={register}
                    required
                    className="w-3/4 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline  ease-linear transition-all duration-150"
                    placeholder="myemail@email.com"
                  />
                  <Button colorScheme="purple" variant="solid" type="submit">
                    ส่ง
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </form>
    </div>
  )
}
