import React from "react"

const SliderTextcontentType = ({ watch }) => (
  <div
    className="relative"
    style={{
      backgroundColor: `${watch("bg_website_color")}`,
    }}
  >
    <div className="border-2  border-black flex justify-center">
      <div
        className={`  bg-black   justify-center `}
        style={{
          backgroundColor: `${watch("bg_component_color")}`,
          width: `${watch("desktop.text_component.text_area_width")}px`,
          height: `${watch("desktop.text_component.text_area_length")}px`,
          borderRadius: `${watch("desktop.text_component.text_area_curve")}%`,
          //   marginRight: `${watch("mobile.Slideshow.right_padding_picture")}px`, // Specify padding-right
          //   marginLeft: `${watch("mobile.Slideshow.left_padding_picture")}px`,
          //   marginTop: `${watch("mobile.Slideshow.top_padding_picture")}px`,
          //   marginBottom: `${watch("mobile.Slideshow.bottom_padding_picture")}px`,
          //   backgroundColor: `rgba(255, 0, 0, ${watch("mobile.Slideshow.opacityPicture") / 100})`, // Apply opacity to border color using watch
          //   borderRadius: `${watch("mobile.button_component.button_curve")}%`,
          //   backgroundColor: `${watch("desktop.button_component.button_color")}`,
        }}
      >
        <p
          className="flex pr-6 pl-6 pt-6 pb-0 justify-center items-center text-red-400"
          style={{
            fontSize: `${watch("desktop.text_component.text_font_Size")}`,
            color: `${watch("desktop.text_component.text_color")}`,
          }}
        >
          {watch("translation.th.content")}
        </p>
        <p
          className="flex  pr-6 pl-6 pt-4 pb-0  underline justify-center items-center text-white"
          style={{
            fontSize: `${watch("desktop.text_component.text_content_Size")}`,
            color: `${watch("desktop.text_component.text_content_color")}`,
          }}
        >
          {watch("translation.th.text_content")}
        </p>
      </div>
    </div>
  </div>
)

export default SliderTextcontentType
