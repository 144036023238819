import React, { useState, useEffect } from "react"
import { useFieldArray, Controller } from "react-hook-form"
import _ from "lodash"
import { Button, Switch, Alert, AlertIcon, AlertTitle, Checkbox } from "@chakra-ui/react"
import { TreeSelect, Input } from "antd"

import api from "../../../config/api"
import { WhitePanel } from "../../common/Cards"

function SubTypeForm({ control, register, watch, rerender, setRerender }) {
  const [allRoomTypes, setAllRoomTypes] = useState([])
  const { fields, remove, append } = useFieldArray({ control, name: "room_sub_types" })
  const dayList = [
    { pre: "SUN", name: "วันอาทิตย์" },
    { pre: "MON", name: "วันจันทร์" },
    { pre: "TUE", name: "วันอังคาร" },
    { pre: "WED", name: "วันพุธ" },
    { pre: "THU", name: "วันพฤหัสบดี" },
    { pre: "FRI", name: "วันศุกร์" },
    { pre: "SAT", name: "วันเสาร์" },
  ]

  useEffect(() => {
    api.get(`${process.env.REACT_APP_API_URL}/room-type`).then((res) => {
      setAllRoomTypes(res?.data?.rows)
    })

    return () => {}
  }, [])

  return (
    <div>
      {_.map(fields, (eachField, index) => (
        <div key={eachField?.id}>
          <WhitePanel>
            {watch(`room_sub_types[${index}].deleted`) ? (
              <Alert colorScheme="red" status="error">
                <AlertIcon />
                <AlertTitle>ถูกลบ</AlertTitle>
              </Alert>
            ) : (
              ""
            )}
            <div className="flex md:flex-wrap">
              <div className="w-full md:w-10/12">
                <div className="py-2">
                  <input
                    type="hidden"
                    defaultValue={eachField?._id}
                    name={`room_sub_types[${index}].id`}
                    ref={register}
                  />{" "}
                  <input
                    type="hidden"
                    defaultValue={eachField?.original}
                    name={`room_sub_types[${index}].original`}
                    ref={register}
                  />
                  <label className="text-sm font-semibold">ชื่อหมวดหมู่ย่อย</label>
                  <Controller
                    control={control}
                    defaultValue={eachField?.name}
                    name={`room_sub_types[${index}].name`}
                    render={(field) => <Input {...field} placeholder="ชื่อหมวดหมู่ย่อย" />}
                  />
                </div>
                <div className="py-2">
                  <label className="text-sm font-semibold">การคิดราคา</label>

                  <Controller
                    control={control}
                    name={`room_sub_types[${index}].use_price_list`}
                    defaultValue={eachField?.use_price_list}
                    render={(field) => (
                      <div className="my-2 flex gap-2">
                        <div>ใช้ราคาเดียว</div>
                        <Switch
                          {...field}
                          defaultChecked={field.value}
                          onChange={(e) => field.onChange(e?.target?.checked)}
                          size="sm"
                        >
                          ใช้ชุดราคาตามวัน
                        </Switch>
                      </div>
                    )}
                  />
                  {watch(`room_sub_types[${index}].use_price_list`) ? (
                    <div className="py-2">
                      <div className="flex flex-col">
                        {_.map(dayList, (eachDayList, dayListIndex) => (
                          <div className="flex gap-2 items-center py-1" key={`day-${dayListIndex}`}>
                            <div className="w-1/2">{eachDayList.name}</div>
                            <Controller
                              control={control}
                              defaultValue={eachField?.price_list?.[eachDayList?.pre]}
                              name={`room_sub_types[${index}].price_list.${eachDayList?.pre}`}
                              render={(field) => <Input {...field} placeholder="ราคา" />}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="py-2">
                      <label className="text-sm font-semibold">ราคา</label>
                      <Controller
                        control={control}
                        defaultValue={eachField?.price}
                        name={`room_sub_types[${index}].price`}
                        render={(field) => <Input {...field} type="number" placeholder="จำนวน" />}
                      />
                    </div>
                  )}
                  {watch("is_special_type") && (
                    <div>
                      <div className="my-2 z-50">
                        <label className="text-sm font-semibold my-2">
                          หมวดหมู่ย่อยที่เลือกให้รวมกัน
                        </label>
                        <Controller
                          control={control}
                          name={`room_sub_types[${index}].another_sub_type`}
                          defaultValue={
                            eachField?.another_sub_type?._id || eachField?.another_sub_type
                          }
                          render={(field) => (
                            <TreeSelect
                              {...field}
                              showSearch
                              style={{ width: "100%" }}
                              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                              placeholder="เลือกหมวดหมู่ และหมวดหมู่ย่อยของห้องพัก"
                              allowClear
                              popupClassName="z-100"
                              treeDefaultExpandAll
                              treeData={_.map(
                                _.filter(allRoomTypes, (roomtype) => !roomtype?.is_special_type),
                                (each) => ({
                                  value: each?._id,
                                  title: `${each?.translation?.th?.name || each?.name} ${
                                    each?.translation?.en ? `(${each?.translation?.en?.name})` : ""
                                  }`,
                                  selectable: false,
                                  children: _.map(each?.room_sub_types, (eachSubType) => ({
                                    value: eachSubType?._id,
                                    title: eachSubType?.name,
                                  })),
                                }),
                              )}
                            />
                          )}
                        />
                        <label className="text-sm font-semibold my-2">
                          จำนวนห้องที่เท่ากับหมวดหมู่นี้ 1 ห้อง
                        </label>
                        <Controller
                          control={control}
                          name={`room_sub_types[${index}].another_sub_type_weight`}
                          defaultValue={eachField?.another_sub_type_weight}
                          render={(field) => <Input {...field} type="number" placeholder="จำนวน" />}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-1/12 flex justify-center px-2">
                <Controller
                  control={control}
                  name={`room_sub_types[${index}].deleted`}
                  render={(field) => (
                    <Button
                      size="sm"
                      colorScheme="red"
                      className="self-center"
                      disabled={watch(`room_sub_types[${index}].deleted`)}
                      onClick={() => {
                        if (eachField?.original) {
                          field.onChange(`room_sub_types[${index}].deleted`, true)
                          setRerender(!rerender)
                        } else {
                          remove(index)
                        }
                      }}
                    >
                      ลบ
                    </Button>
                  )}
                />
              </div>
            </div>
          </WhitePanel>
        </div>
      ))}
      <div>
        <Button
          size="sm"
          colorScheme="purple"
          disabled={watch("is_special_type") ? (_.size(fields) >= 1 ? true : false) : false}
          onClick={() => append({})}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  )
}

export default SubTypeForm
