/* eslint-disable no-param-reassign */
import React from "react"
import { useForm, Controller } from "react-hook-form"
import { Button, FormLabel, Switch } from "@chakra-ui/react"
import { Form, Input, Radio } from "antd"

import _ from "lodash"
import SpinnerLoading from "../../Loading/SpinnerLoading"
import ImageUpload from "../../ImageUpload/ImageUpload"
import { HomepageType } from "../../../config/constant"

export default function SystemInfoForm({ systemInfo, onSubmit = () => {} }) {
  const { register, handleSubmit, control } = useForm()

  if (!systemInfo) {
    return <SpinnerLoading />
  }

  const renderSystemInfo = () => (
    <div>
      <h6 className="w-full  text-gray-900 text-base mt-3 mb-6 font-bold uppercase font-sans ">
        จัดการข้อมูลระบบ
      </h6>
      <Form layout="vertical">
        <Form.Item label="ชื่อระบบ">
          <Controller
            control={control}
            name="name"
            defaultValue={systemInfo.name}
            render={(field) => <Input {...field} />}
          />
        </Form.Item>{" "}
        <Form.Item label="URL ของระบบ">
          <Controller
            control={control}
            name="systemURL"
            defaultValue={systemInfo.systemURL}
            render={(field) => <Input {...field} />}
          />
        </Form.Item>{" "}
        <Form.Item label="ชื่อบริษัท / หน่วยงาน เจ้าของระบบ">
          <Controller
            control={control}
            name="systemOwner"
            defaultValue={systemInfo.systemOwner}
            render={(field) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item label="เว็บไซต์เจ้าของระบบ">
          <Controller
            control={control}
            name="systemOwnerURL"
            defaultValue={systemInfo.systemOwnerURL}
            render={(field) => <Input {...field} />}
          />
        </Form.Item>
        <Form.Item label="ชื่อผู้สนับสนุนระบบ">
          <Controller
            control={control}
            name="systemSponsor"
            defaultValue={systemInfo.systemSponsor}
            render={(field) => <Input {...field} />}
          />
        </Form.Item>{" "}
        <Form.Item label="เว็บไซต์ผู้สนับสนุนระบบ">
          <Controller
            control={control}
            name="systemSponsorURL"
            defaultValue={systemInfo.systemSponsorURL}
            render={(field) => <Input {...field} />}
          />
        </Form.Item>
      </Form>

      <div className="w-full px-4">
        <div className="relative w-full mb-3">
          <label className="block uppercase text-gray-700 text-sm  mb-2" htmlFor="grid-password">
            โลโก้ระบบ
          </label>
          <Controller
            control={control}
            name="logo"
            render={(field) => (
              <div>
                <ImageUpload
                  images={null}
                  setImages={(logo) => field.onChange(logo)}
                  preview_size="250"
                />
                {_.isArray(field.value) && <div>อัพโหลดมาแล้ว {_.size(field.value)} ไฟล์</div>}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )

  const renderModuleSetting = () => (
    <div className="w-full ">
      <h6 className="w-full  text-gray-900 text-base mt-3 mb-6 font-bold uppercase font-sans ">
        โหมดการใช้งาน
      </h6>
      <div className="flex justify-center border rounded-md py-2">
        <FormLabel className="px-2 self-center">โหมดโรงแรม</FormLabel>
        <Switch
          ref={register}
          defaultChecked={systemInfo?.homestay_mode}
          name="homestay_mode"
          size="lg"
          colorScheme="purple"
          onChange={handleSubmit(onSubmit)}
        />
        <FormLabel className="px-2 self-center">โหมดโฮมสเตย์</FormLabel>
      </div>
      <h6 className="w-full  text-gray-900 text-base mt-3 mb-6 font-bold uppercase font-sans ">
        รูปแบบหน้าแรก
      </h6>
      <Controller
        control={control}
        name="homepage"
        defaultValue={systemInfo?.homepage}
        render={(field) => (
          <Radio.Group
            {...field}
            onChange={(e) => field.onChange(e.target.value)}
            value={field.value}
          >
            <div className="flex flex-col">
              {_.map(HomepageType, (type) => (
                <Radio value={type?.status_code}> {type?.description} </Radio>
              ))}
            </div>
          </Radio.Group>
        )}
      />
    </div>
  )

  return (
    <div className=" bg-white p-4 rounded-lg shadow-lg ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-wrap w-full">
          <div className="w-full lg:w-1/2 px-2">{renderSystemInfo()}</div>
          <div className="w-full lg:w-1/2 px-2">{renderModuleSetting()}</div>
        </div>

        <div className="text-center px-4 gap-0 py-3 ">
          <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
            บันทึก{" "}
          </Button>
        </div>
      </form>
    </div>
  )
}
