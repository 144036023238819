/* eslint-disable import/no-named-as-default-member */
import fontExample from "../../assets/font-style"

export const Font = {
  KANIT: {
    status_code: "KANIT",
    description: "Kanit",
    example: fontExample.kanit,
  },
  MITR: {
    status_code: "MITR",
    description: "Mitr",
    example: fontExample.mitr,
  },
  PROMPT: {
    status_code: "PROMPT",
    description: "Prompt",
    example: fontExample.prompt,
  },
  PRIDI: {
    status_code: "PRIDI",
    description: "Pridi",
    example: fontExample.pridi,
  },
  SARABUN: {
    status_code: "SARABUN",
    description: "Sarabun",
    example: fontExample.sarabun,
  },
  ATHITI: {
    status_code: "ATHITI",
    description: "Athiti",
    example: fontExample.athiti,
  },
  ARCHIVO: {
    status_code: "ARCHIVO",
    description: "Archivo",
    example: fontExample.archivo,
  },
  BAIJAMJUREE: {
    status_code: "BAIJAMJUREE",
    description: "Bai Jamjuree",
    example: fontExample.baijamjuree,
  },
  BEBAS: {
    status_code: "BEBAS",
    description: "Bebas",
    example: fontExample.bebas,
  },
  BREE_SERIF: {
    status_code: "BREE_SERIF",
    description: "Bree Serif",
    example: fontExample.breeSerif,
  },
  CHONBURI: {
    status_code: "CHONBURI",
    description: "Chonburi",
    example: fontExample.chonburi,
  },
  IBM_PLEX: {
    status_code: "IBM_PLEX",
    description: "IBM Plex Sans",
    example: fontExample.ibmPlex,
  },
  IBM_PLEX_LOOP: {
    status_code: "IBM_PLEX_LOOP",
    description: "IBM Plex Sans Loop",
    example: fontExample.ibmPlexLoop,
  },
  MAITREE: {
    status_code: "MAITREE",
    description: "Maitree",
    example: fontExample.maitree,
  },
  SRIRACHA: {
    status_code: "SRIRACHA",
    description: "Sriracha",
    example: fontExample.sriracha,
  },
  MONOTON: {
    status_code: "MONOTON",
    description: "Monoton",
    example: fontExample.monoton,
  },
  MONTSERRAT: {
    status_code: "MONTSERRAT",
    description: "Montserrat",
    example: fontExample.montserrat,
  },
  CARDO: {
    status_code: "CARDO",
    description: "Cardo",
    example: fontExample.cardo,
  },
  LORA: {
    status_code: "LORA",
    description: "Lora",
    example: fontExample.lora,
  },
  PLAYFAIR: {
    status_code: "PLAYFAIR",
    description: "Playfair",
    example: fontExample.playfair,
  },
  TILTPRISM: {
    status_code: "TILTPRISM",
    description: "Tilt Prism",
    example: fontExample.tiltprism,
  },
}

export default Font
