/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory, Link } from "react-router-dom"
import dayjs from "dayjs"
import { Table, Button as AntdButton } from "antd"
import _ from "lodash"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  Alert,
  GeneralMainLayout as Layout,
  ReportDateSelector,
  CSVGeneratorPanel,
} from "../../components"
import * as actions from "../../redux/actions"
import { TransactionType } from "../../config/constant"
import { appConfig } from "../../config"
import { currencyFormatter } from "../../util"

function BillListReport() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [date, setDate] = useState(new Date())
  const [page, setPage] = useState(1)
  const size = 10
  const [isLoading, setIsLoading] = useState(false)
  const [orderByField, setOrderByField] = useState("name")
  const [orderBy, setOrderBy] = useState("asc")
  const [excelExportOpen, setExcelExportOpen] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const bill = useSelector((state) => state.bill)
  const dispatch = useDispatch()

  useEffect(() => {
    setExcelExportOpen(false)
    dispatch(
      actions.getAllBills({
        page,
        size: 20,
        query: {
          date: date ? dayjs(date).format("YYYY-MM-DD") : "",
          start: startDate ? dayjs(startDate).toISOString() : "",
          end: endDate ? dayjs(endDate).toISOString() : "",
          orderByField,
          orderBy,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [date, page, orderBy, orderByField, startDate, endDate])

  const columns = [
    {
      title: "รอบบิลที่",
      dataIndex: "bill_no",
      render: (text, record) => _.padStart(record?.bill_no, 4, "0"),
      sorter: true,
    },
    {
      title: "เงินเข้า",
      dataIndex: "income",
      render: (text, record) => currencyFormatter.format(record?.income || 0) || "-",
      sorter: true,
    },
    {
      title: "เงินออก",
      dataIndex: "outcome",
      render: (text, record) => currencyFormatter.format(record?.outcome || 0) || "-",
      sorter: true,
    },
    {
      title: "เงินสำรอง",
      dataIndex: "spare_money",
      render: (text, record) => currencyFormatter.format(record?.spare_money || 0),
    },
    {
      title: "คงเหลือ",
      dataIndex: "total",
      render: (text, record) =>
        currencyFormatter.format(
          (record?.income || 0) + (record?.spare_money || 0) - (record?.outcome || 0),
        ),
    },

    {
      title: "วันที่เปิดรอบ",
      dataIndex: "createdAt",
      render: (text, record) => dayjs(record?.createdAt).format("DD MMM YYYY"),
    },
    {
      title: "วันที่ปิดรอบ",
      dataIndex: "closed_date",
      render: (text, record) =>
        !record?.active && record?.closed_date
          ? dayjs(record?.closed_date).format("DD MMM YYYY")
          : "-",
    },
    {
      title: "สถานะปัจจุบัน",
      dataIndex: "active",
      render: (text, record) => (record?.active ? "ยังดำเนินการ" : "เสร็จสิ้น"),
    },
    {
      title: "รายละเอียด",
      dataIndex: "active",
      render: (text, record) => (
        <div>
          <Link to={`/history/bill/detail/${record?._id}`}>
            <AntdButton>รายละเอียด</AntdButton>
          </Link>
        </div>
      ),
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderBy("asc")
    } else {
      setOrderBy("desc")
    }

    if (sorter?.field === "bill_no") {
      setOrderByField("bill_no")
    }
    if (sorter?.field === "income") {
      setOrderByField("income")
    }
    if (sorter?.field === "outcome") {
      setOrderByField("outcome")
    }
  }

  useEffect(() => {
    setStartDate()
    setEndDate()

    return () => {}
  }, [date])

  const fullQueryParams = new URLSearchParams({
    page: 1,
    size: appConfig.maxFetchSize,
    date: date ? dayjs(date).format("YYYY-MM-DD") : "",
    fetchBooking: true,
    orderBy,
    orderByField,
    transactionType: TransactionType.CREATE_PAYMENT.status_code,
    start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
    end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
  })

  return (
    <Layout categoryTitle={t("history.title")} title={"รอบบิล"}>
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<i className="fas fa-file-excel"></i>}
            onClick={() => {
              if (excelExportOpen === true) {
                setExcelExportOpen(false)
                setTimeout(() => {
                  setExcelExportOpen(true)
                }, 500)
              } else {
                setExcelExportOpen(true)
              }
            }}
          >
            CSV
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <ReportDateSelector
          pointDate={date}
          setPointDate={setDate}
          endDate={endDate}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          showTimeDescription={false}
        >
          <div></div>
        </ReportDateSelector>
        <CSVGeneratorPanel
          apiSuffix="bill"
          columns={columns}
          open={excelExportOpen}
          queryParams={fullQueryParams}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={bill?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: bill?.total,
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default BillListReport
