import React, { createContext, useState } from "react"
import dayjs from "dayjs"
import _ from "lodash"

export const OnlineBookingContext = createContext(null)

export default function OnlineBookingProvider({
  defaultColor = null,
  defaultSpecifyBookingList = [],
  children,
  defaultCheckinDate = dayjs().startOf("day").toDate(),
  defaultCheckoutDate = dayjs().add(1, "day").toDate(),
  defaultUnSpecifyBookingList = [], // {subtype:Object,amount:Number}
  defaultSelectedService = [], // {service:Object,amount:Number}
}) {
  const [color, setColor] = useState(defaultColor || "purple")
  const [specifyBookingList, setSpecifyBookingList] = useState(defaultSpecifyBookingList)
  const [unSpecifyBookingList, setUnSpecifyBookingList] = useState(defaultUnSpecifyBookingList)
  const [checkinDate, setCheckinDate] = useState(defaultCheckinDate)
  const [checkoutDate, setCheckedoutDate] = useState(defaultCheckoutDate)
  const [rerender, setRerender] = useState(false)
  const [selectedServices, setSelectedServices] = useState(defaultSelectedService)
  const [bookingInfo, setBookingInfo] = useState()

  const toggleSelectedRoom = (room) => {
    console.log("Room", room)
    // if (!unAvailabelRoomList?.includes(roomId)) {
    if (_.includes(specifyBookingList, room)) {
      const selectedRoomIndex = _.findIndex(specifyBookingList, "roomId", room?.id)
      console.log("Selected Room Index", selectedRoomIndex)
      specifyBookingList.splice(selectedRoomIndex, 1)
      setSpecifyBookingList(specifyBookingList)
    } else {
      specifyBookingList.push(room)
      setSpecifyBookingList(specifyBookingList)
    }
  }
  return (
    <OnlineBookingContext.Provider
      value={{
        color,
        setColor,
        specifyBookingList,
        setSpecifyBookingList,
        checkinDate,
        checkoutDate,
        setCheckinDate,
        setCheckedoutDate,
        unSpecifyBookingList,
        setUnSpecifyBookingList,
        toggleSelectedRoom,
        rerender,
        setRerender,
        selectedServices,
        setSelectedServices,
        bookingInfo,
        setBookingInfo,
      }}
    >
      {children}
    </OnlineBookingContext.Provider>
  )
}
