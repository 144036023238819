import { IMAGE_ALL, IMAGE_CREATE, IMAGE_GET, IMAGE_UPDATE, IMAGE_DELETE } from "../../actions/types"

const initialState = {
  beds: null,
  isLoading: false,
}
export default function ImageReducer(state = initialState, action) {
  switch (action.type) {
    case IMAGE_ALL:
      return {
        arr: action.payload,
        isLoading: true,
      }
    case IMAGE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case IMAGE_CREATE:
      return { isLoading: false }
    case IMAGE_UPDATE:
      return { isLoading: false }
    case IMAGE_DELETE:
      return { isLoading: false }
    default:
      return state
  }
}
