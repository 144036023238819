import {
  FILE_ALL,
  FILE_GET,
  FILE_POST,
  FILE_PUT,
  FILE_DEL,
  FILE_ERROR,
  FILE_LOADING,
} from "../../actions/types"

const initialState = {
  droplet: null,
  isLoading: true,
  isCompleted: true,
}

export default function FileReducer(state = initialState, action) {
  switch (action.type) {
    case FILE_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case FILE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      }
    case FILE_POST:
      return { isLoading: false, isCompleted: true }
    case FILE_PUT:
      return { isLoading: false, isCompleted: true }
    case FILE_DEL:
      return { isLoading: false, isCompleted: true }
    case FILE_LOADING:
      return { isLoading: true, isCompleted: true }
    case FILE_ERROR:
      return { isLoading: false, isCompleted: false }
    default:
      return state
  }
}
