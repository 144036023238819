import React, { forwardRef } from "react"

const TextArea = ({
  defaultValue,
  register,
  name,
  type,
  placeholder,
  label,
  required = false,
  readonly = false,
  rows = 3,
}) => (
  <div className="relative w-full mb-3">
    <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
      {label}
    </label>
    <textarea
      name={name}
      type={type}
      ref={register}
      required={required}
      readOnly={readonly}
      className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
      placeholder={placeholder}
      defaultValue={defaultValue}
      rows={rows}
    />
  </div>
)

export default forwardRef(TextArea)
