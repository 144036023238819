/* eslint-disable no-underscore-dangle */
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { DatePicker } from "antd"

import _, { isObject } from "lodash"
import { IconButton } from "@chakra-ui/react"
import { OnlineBookingContext } from "../../../contexts/OnlineBookingContext"

import { currencyFormatter, calculatePriceOnSubtype } from "../../../util"

function DateSelectVerticleForm({ checkinDate, setCheckinDate, checkoutDate, setCheckoutDate }) {
  const { t, i18n } = useTranslation()
  const {
    color,
    specifyBookingList,
    toggleSelectedRoom,
    unSpecifyBookingList,
    setUnSpecifyBookingList,
    rerender,
    setRerender,
    selectedServices,
    setSelectedServices,
  } = useContext(OnlineBookingContext)

  const handleDeleteUnSpecify = (selectedSubtype) => {
    const foundedSubtypeIndex = _.findIndex(
      unSpecifyBookingList,
      (each) => each?.subtype?._id === selectedSubtype?._id,
    )
    delete unSpecifyBookingList[foundedSubtypeIndex]
    setUnSpecifyBookingList(unSpecifyBookingList)
    setRerender(!rerender)
  }

  const handleDeleteService = (selectedService) => {
    const foundSelectedServiceIdx = _.findIndex(
      selectedServices,
      (each) => each?.service?._id === selectedService?._id,
    )
    delete selectedServices[foundSelectedServiceIdx]
    setSelectedServices(unSpecifyBookingList)
    setRerender(!rerender)
  }

  const servicePrice = _.sum(
    _.map(
      selectedServices,
      (each) =>
        each?.amount *
          each?.service?.price *
          (each?.service?.price_per_night
            ? dayjs(dayjs(checkoutDate).startOf("day")).diff(
                dayjs(checkinDate).startOf("day"),
                "day",
              )
            : 1) || 0,
    ),
  )

  const pricingGenerator = (conditionalBookingList) => {
    try {
      const amountOfBooking = dayjs(dayjs(checkoutDate).startOf("day")).diff(
        dayjs(checkinDate).startOf("day"),
        "day",
      )
      const amountOfBookingAsList = new Array(amountOfBooking)
      const eachListType = _.map(conditionalBookingList, (eachBookingList) => {
        const pricingList = _.map(amountOfBookingAsList, (each, index) => {
          const tempCheckinDate = dayjs(checkinDate).add(index, "day").toISOString()
          const tempCheckoutDate = dayjs(checkinDate)
            .add(index + 1, "day")
            .toISOString()

          // FYI: In Unspecify using .subtype but in specify using sub_type
          const priceOnSubtype = calculatePriceOnSubtype(
            eachBookingList?.subtype || eachBookingList?.sub_type,
            tempCheckinDate,
            tempCheckoutDate,
          )

          return priceOnSubtype
        })
        return _.sum(pricingList)
      })
      return _.sum(eachListType)
    } catch (error) {
      return 0
    }
  }

  const generateSpecifyBookingList = () => (
    <ul>
      {_.map(specifyBookingList, (eachList, index) => (
        <li
          key={index}
          className="flex justify-between text-gray-600 hover:text-gray-500 list-disc list-inside"
        >
          <div>
            {eachList?.name}
            <div className="text-xs">
              {eachList?.type?.translation?.[i18n.language]?.name} - {eachList?.sub_type?.name}
            </div>
          </div>
          <div>
            <IconButton
              icon={<i className="fas fa-times" />}
              onClick={() => {
                toggleSelectedRoom(eachList)
                setRerender(!rerender)
              }}
            />
          </div>
        </li>
      ))}
    </ul>
  )

  const generateUnSpecifyBookingList = () => (
    <ul>
      {_.map(
        _.filter(unSpecifyBookingList, (each) => isObject(each?.subtype)),
        (eachList, index) => (
          <li
            key={index}
            className="flex justify-between text-gray-600 hover:text-gray-500 list-disc list-inside my-2"
          >
            <div>
              - {eachList?.subtype?.name} x {eachList?.amount}
              <div className="text-xs">
                {eachList?.subtype?.type?.translation?.[i18n.language]?.name}
              </div>
            </div>
            <div>
              <IconButton
                variant="outline"
                size="sm"
                icon={<i className="fas fa-times" />}
                onClick={() => handleDeleteUnSpecify(eachList?.subtype)}
              />
            </div>
          </li>
        ),
      )}
    </ul>
  )

  const generateServiceList = () => (
    <div>
      {_.map(
        _.filter(selectedServices, (each) => isObject(each?.service)),
        (eachList, index) => (
          <div key={index} className="flex justify-between text-gray-600 hover:text-gray-500 my-2">
            <div>
              - {eachList?.service?.translation?.[i18n.language]?.name || eachList?.service?.name} x{" "}
              {eachList?.amount}
            </div>
            <div>
              <IconButton
                variant="outline"
                size="sm"
                icon={<i className="fas fa-times" />}
                onClick={() => handleDeleteService(eachList?.service)}
              />
            </div>
          </div>
        ),
      )}
    </div>
  )
  return (
    <div>
      <div className="text-lg font-semibold">{t("reservation.roomSearch")}</div>
      <div className="px-1 w-full my-2">
        <h5 className="font-semibold text-sm">{t("reservation.checkinDate")}</h5>
        <DatePicker
          className="my-2"
          onChange={(date) => setCheckinDate(dayjs(date).toDate())}
          value={dayjs(checkinDate)}
          format={"D/MM/YYYY"}
          locale={i18n.language}
          size="large"
          style={{ width: "100%" }}
        />
      </div>
      <div className="px-1 w-full my-2">
        <h5 className="font-semibold text-sm">{t("reservation.checkoutDate")}</h5>
        <DatePicker
          onChange={(date) => setCheckoutDate(dayjs(date).toDate())}
          value={dayjs(checkoutDate)}
          className="my-2"
          disabledDate={(current) =>
            // Can not select days before today and today
            current && current < dayjs(checkinDate).endOf("day")
          }
          format={"D/MM/YYYY"}
          locale={i18n.language}
          size="large"
          style={{ width: "100%" }}
        />
      </div>
      <div className="my-2 px-1">
        {t("general.amount")}{" "}
        {dayjs(dayjs(checkoutDate).startOf("day")).diff(dayjs(checkinDate).startOf("day"), "day")}{" "}
        {t("roomtype.night")}
      </div>
      {(!_.isEmpty(specifyBookingList) || !_.isEmpty(unSpecifyBookingList)) && (
        <div className="my-2">
          <div className="text-lg font-semibold">{t("roomtype.room")}</div>
          <div className="flex flex-col px-2">{generateSpecifyBookingList()}</div>
          <div className="flex flex-col px-2">{generateUnSpecifyBookingList()}</div>
          {_.size(_.filter(selectedServices, (each) => isObject(each))) > 0 && (
            <div className="text-lg font-semibold my-2">{t("reservation.extraService")}</div>
          )}

          <div className="flex flex-col px-2">{generateServiceList()}</div>
          <div className="text-lg font-semibold mt-4 my-2">
            {t("reservation.price.priceSummary")}
          </div>
          <div className="flex justify-between flex-nowrap text-gray-600 hover:text-gray-500">
            <div> {t("reservation.price.roomPrice")} </div>

            <div>
              {currencyFormatter.format(
                pricingGenerator(specifyBookingList) + pricingGenerator(unSpecifyBookingList),
              )}{" "}
              THB{" "}
            </div>
          </div>
          <div className="flex justify-between flex-nowrapn text-gray-600 hover:text-gray-500">
            <div> {t("reservation.price.extraServicePrice")} </div>
            <div className="whitespace-nowrap">
              {currencyFormatter.format(servicePrice || 0)} THB{" "}
            </div>
          </div>
          <div className="flex justify-between flex-nowrapn text-gray-600 hover:text-gray-500">
            <div className="mr-2"> {t("reservation.totalPrice")} </div>
            <div className="whitespace-nowrap">
              {currencyFormatter.format(
                pricingGenerator(specifyBookingList) +
                  pricingGenerator(unSpecifyBookingList) +
                  servicePrice || 0,
              )}{" "}
              THB{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DateSelectVerticleForm
