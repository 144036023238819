import React from "react"
import { useHistory } from "react-router-dom"
function BottomMenu() {
  const history = useHistory()
  return (
    <div className="block md:hidden ">
      <div className="fixed bottom-0 inset-x-0  z-50 shadow h-16 p-2 bg-gray-200 grid grid-cols-4">
        <div
          className="bg-white cursor-pointer py-1 rounded-l-md px-2"
          onClick={() => {
            history.push("/reservation/room")
          }}
        >
          <div className="flex justify-center my-1 text-lg">
            <i className="fas fa-door-open"></i>
          </div>
          <div className="text-sm text-center">รายการจอง</div>
        </div>
        <div
          className="bg-white cursor-pointer py-1  px-2"
          onClick={() => {
            history.push("/reservation/calendar")
          }}
        >
          <div className="flex justify-center my-1 text-lg">
            <i className="fas fa-calendar-alt"></i>
          </div>
          <div className="text-sm text-center">ปฏิทิน</div>
        </div>
        <div
          className="bg-white cursor-pointer py-1  px-2"
          onClick={() => {
            history.push("/reservation/create")
          }}
        >
          <div className="flex justify-center my-1 text-lg">
            <i className="fas fa-calendar-plus"></i>
          </div>
          <div className="text-sm text-center">เพิ่มการจอง</div>
        </div>{" "}
        <div
          className="bg-white cursor-pointer py-1  rounded-r-md px-2"
          onClick={() => {
            history.push("/")
          }}
        >
          <div className="flex justify-center my-1 text-lg">
            <i className="fas fa-home"></i>
          </div>
          <div className="text-sm text-center">หน้าหลัก</div>
        </div>
      </div>
    </div>
  )
}

export default BottomMenu
