import {
  FRONTPAGE_COMPONENT_ALL,
  FRONTPAGE_COMPONENT_CREATE,
  FRONTPAGE_COMPONENT_DELETE,
  FRONTPAGE_COMPONENT_GET,
  FRONTPAGE_COMPONENT_UPDATE,
  FRONTPAGE_COMPONENT_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"
import api from "../../../config/api"

const frontpageComponentActions = new GeneralReduxActionClass({
  allConst: FRONTPAGE_COMPONENT_ALL,
  createConst: FRONTPAGE_COMPONENT_CREATE,
  deleteConst: FRONTPAGE_COMPONENT_DELETE,
  errorConst: FRONTPAGE_COMPONENT_ERROR,
  getConst: FRONTPAGE_COMPONENT_GET,
  putConst: FRONTPAGE_COMPONENT_UPDATE,
  backendPrefix: "frontpage-component",
  description: "ส่วนประกอบของหน้าแรก",
})

export const getAllFrontpageComponent = (query) => frontpageComponentActions.getAll(query)
export const getOneFrontpageComponent = (id) => frontpageComponentActions.getOne(id)
export const createOneFrontpageComponent = (payload) => frontpageComponentActions.createOne(payload)
export const editOneFrontpageComponent = (id, payload) =>
  frontpageComponentActions.editOne(id, payload)
export const deleteOneFrontpageComponent = (id) => frontpageComponentActions.deleteOne(id)

export const removeAllAndRepace = (payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/frontpage-component/replace-all/XXX`, payload)
    .then((res) => {
      dispatch({ type: FRONTPAGE_COMPONENT_UPDATE })
    })
    .catch((err) => {
      dispatch({ type: FRONTPAGE_COMPONENT_ERROR, payload: null })

      throw new Error(
        `ไม่สามารถแก้ไข Frontpage Component ได้  ${err?.response?.data?.error?.message}`,
      )
    })
}
