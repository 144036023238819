import React from "react"

const SlideshowTextMobileComponent = ({ watch }) => (
  <div className="pl-96 pr-96 ml-28 mr-28">
    <div className=" flex border-2 border-gray-600 justify-center ">
      <div
        className={`  bg-blue-500 bg-opacity-50   flex justify-center `}
        style={{
          width: `${watch("mobile.Slideshow.text_content.width_picture")}px`,
          height: `${watch("mobile.Slideshow.text_content.height_picture")}px`,
          marginRight: `${watch("mobile.Slideshow.text_content.right_padding_picture")}px`, // Specify padding-right
          marginLeft: `${watch("mobile.Slideshow.text_content.left_padding_picture")}px`,
          marginTop: `${watch("mobile.Slideshow.text_content.top_padding_picture")}px`,
          marginBottom: `${watch("mobile.Slideshow.text_content.bottom_padding_picture")}px`,
        }}
      >
        <p
          className="flex text-center items-center text-white border-4 border-red-600"
          style={{
            borderColor: `rgba(255, 0, 0, ${
              watch("mobile.Slideshow.text_content.opacityBorder") / 100
            })`,
          }}
        >
          {watch("mobile.Slideshow.translation.en.text_content")}
        </p>
      </div>
    </div>
  </div>
)

export default SlideshowTextMobileComponent
