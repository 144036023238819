/* eslint-disable no-underscore-dangle */
import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { Link } from "react-router-dom"
import { currencyFormatter } from "../../util"
import PaymentTransactionTable from "../Tables/PaymentTransactionTable"

// TODO: Show only Online Booking
export default function PricePanel({
  allPrice,
  bookingInfo,
  bookingDiscount = 0,
  finalDiscount = 0,
  displayPercentDiscount = true,
  displayPaymentAmount = true,
  transactions,
  showTransaction = true,
}) {
  const { t } = useTranslation()

  const servicePrice =
    _.sum(_.map(bookingInfo?.services, (eachServices) => eachServices?.price)) || 0
  const fbPrice = _.sum(_.map(bookingInfo?.fb_orders, (eachServices) => eachServices?.price)) || 0

  const roomPrice = allPrice - servicePrice - fbPrice + bookingDiscount + finalDiscount

  const legacyImage = bookingInfo?.payment?.payment_slip
  const imageList = []
  if (legacyImage) {
    imageList.push(legacyImage)
  }

  _.map(bookingInfo?.images, (eachImage) => {
    imageList.push(eachImage)
  })

  return (
    <div className="flex flex-wrap">
      <div className={`w-full ${showTransaction ? "lg:w-1/2" : "w-full"} px-2`}>
        <div className="w-full px-2">
          <h6 className="text-sm font-bold mt-2 font-sans">
            {t("reservation.price.priceSummary")}
          </h6>
          <div className="flex justify-between">
            <p className="text-sm  my-1">{t("reservation.price.roomPrice")}</p>
            <p className="text-sm  my-1 ">
              {currencyFormatter.format(roomPrice)} {t("general.baht")}{" "}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm  my-1">{t("reservation.price.roomDiscount")}</p>
            <p className="text-sm  my-1 ">
              {currencyFormatter.format(bookingDiscount)} {t("general.baht")}{" "}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm  my-1">
              {t("reservation.price.finalDiscount")}{" "}
              {bookingInfo?.use_discount_percent && displayPercentDiscount
                ? `(${bookingInfo?.final_discount || 0} %)`
                : ""}
            </p>
            <p className="text-sm  my-1 ">
              {currencyFormatter.format(finalDiscount)} {t("general.baht")}{" "}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm  my-1">{t("reservation.price.totalDiscount")}</p>
            <p className="text-sm  my-1 ">
              {currencyFormatter.format(bookingDiscount + finalDiscount)} {t("general.baht")}{" "}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm  my-1">{t("reservation.price.priceWithDiscount")}</p>
            <p className="text-sm  my-1 ">
              {currencyFormatter.format(allPrice - servicePrice - fbPrice)} {t("general.baht")}{" "}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm  my-1">{t("reservation.price.servicePrice")} </p>
            <p className="text-sm  my-1 ">
              {currencyFormatter.format(servicePrice)} {t("general.baht")}
            </p>
          </div>
          {!_.isEmpty(bookingInfo?.fb_orders) &&
            _.map(bookingInfo?.fb_orders, (eachFBOrder, index) => (
              <div className="flex justify-between" key={index}>
                <p className="text-sm  my-1">
                  {t("foodAndBeverage.title")} - {eachFBOrder?.remark}{" "}
                </p>
                <p className="text-sm  my-1 ">
                  {currencyFormatter.format(eachFBOrder?.price)} {t("general.baht")}
                </p>
              </div>
            ))}
          <br />
          <div className="flex justify-between font-bold">
            <p className="text-sm  my-1">{t("reservation.totalPrice")} </p>
            <p className="text-sm  my-1 underline">
              {currencyFormatter.format(allPrice)} {t("general.baht")}{" "}
            </p>
          </div>{" "}
          {displayPaymentAmount && (
            <div className="w-full">
              <div className="flex justify-between">
                <p className="text-sm  my-1"> {t("reservation.price.paidAmount")} </p>
                <p className="text-sm  my-1 ">
                  {currencyFormatter.format(bookingInfo?.payment?.paid_amount)} {t("general.baht")}{" "}
                </p>
              </div>
              <div className="flex justify-between">
                <p className="text-sm  my-1">{t("reservation.price.remainingAmount")} </p>
                <p className="text-sm  my-1 ">
                  {currencyFormatter.format(allPrice - bookingInfo?.payment?.paid_amount)} บาท{" "}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showTransaction && (
        <div className="px-2 w-full lg:w-1/2">
          <h6 className="text-sm font-bold my-2 font-sans">
            การชำระเงิน{"  "}
            <Link to={`/reservation/detail-payment/${bookingInfo?._id}`}>
              <Button>เพิ่มเติม</Button>
            </Link>
          </h6>
          <PaymentTransactionTable
            page={1}
            setPage={() => {}}
            setSize={() => {}}
            size={5}
            transaction={transactions}
            tableSize="small"
          />

          <div className="flex flex-wrap w-full">
            {_.map(imageList, (eachImage, index) => (
              <img src={eachImage?.url} className="w-64 lg:w-1/5 px-2" key={index}></img>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
