/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, useToast } from "@chakra-ui/react"
import _ from "lodash"
import { useTranslation } from "react-i18next"

// eslint-disable-next-line import/no-unresolved
import SpinnerLoading from "components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import RoomList from "../../../components/List/RoomList"
import Layout from "../../../components/layout/GeneralLayout"
import ModalAddOrEditRoom from "../../../components/Modal/RoomManagement/ModalAddRooms"
import Alert from "../../../components/common/Alert/AlertDialog"
import { appConfig } from "../../../config"

export default function RoomManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalAddRoomOpen, setIsModalAddRoomOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState()
  const [isModalEditRoomOpen, setIsModalEditRoomOpen] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const roomTypes = useSelector((state) => state.roomTypes)
  const rooms = useSelector((state) => state.rooms)
  const toast = useToast()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getAllRooms = () =>
    dispatch(actions.getAllRooms())
      .then(() => {
        dispatch(actions.getAllRoomTypes({ page: 1, size: appConfig.maxFetchSize }))
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err)
      })

  useEffect(() => {
    getAllRooms()
    return () => {}
  }, [])

  const handleDeleteRoom = (id) => {
    const confirm = window.confirm(t("roomtype.confirmRemoveRoom"))
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOneRoom(id)).then(() => {
        dispatch(actions.getAllRooms()).then(() => {
          setIsLoading(true)
        })
      })
    }
  }

  const handleChangeOnlineBookingOpen = (roomId, destinationStatus) => {
    setIsLoading(false)
    dispatch(
      actions.editOneRoom(roomId, {
        online_booking_open: destinationStatus,
      }),
    )
      .then(() => {
        dispatch(actions.getAllRooms())
        setIsLoading(true)
      })
      .catch((err) => {
        alert(`แก้ไขห้องพักไม่ได้ ${err?.message}`)
      })
  }

  const handleAddRoom = (data) => {
    const foundRoomType = _.find(roomTypes?.arr, (each) =>
      _.includes(
        _.map(each?.room_sub_types, (eachSubtype) => eachSubtype?._id),
        data?.sub_type,
      ),
    )

    if (!foundRoomType) {
      toast({
        status: "error",
        title: "ไม่สามารถเพิ่มห้องใหม่ได้",
        description: "ไม่พบหมวดหมู่ห้องพัก",
      })
      setIsModalAddRoomOpen(false)
      return
    }
    dispatch(actions.createOneRoom({ ...data, type_id: foundRoomType?._id }))
      .then(() => {
        getAllRooms()
        toast({
          status: "success",
          title: "เพิ่มห้องสำเร็จ",
        })
        setIsModalAddRoomOpen(false)
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "ไม่สามารถเพิ่มห้องใหม่ได้",
          description: err?.message,
        })
      })
  }

  const handleEditRoom = (data) => {
    dispatch(actions.editOneRoom(selectedRoom?._id, data))
      .then(() => {
        getAllRooms()
        toast({
          status: "success",
          title: "แก้ไขห้องสำเร็จ",
        })
        setIsModalEditRoomOpen(false)
        setSelectedRoom({})
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "ไม่สามารถแก้ไขห้องได้",
          description: err?.message,
        })
      })
  }

  return (
    <Layout categoryTitle={t("roomtype.title")} title={t("roomtype.roomManagement")}>
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="solid"
          onClick={() => {
            setIsModalAddRoomOpen(true)
          }}
        >
          {t("general.add")}
        </Button>
      </div>
      <Alert
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage()
        }}
        isError={isError}
      />
      <ModalAddOrEditRoom
        isOpen={isModalAddRoomOpen}
        handleOnClose={() => {
          setIsModalAddRoomOpen(false)
        }}
        allRoomTypes={roomTypes?.arr}
        handleAddRoomType={handleAddRoom}
      />
      <ModalAddOrEditRoom
        allRoomTypes={roomTypes?.arr}
        handleOnClose={() => {
          setIsModalEditRoomOpen(false)
          setSelectedRoom({})
        }}
        defaultValue={selectedRoom}
        handleAddRoomType={handleEditRoom}
        isEditMode
        isOpen={isModalEditRoomOpen}
      />
      {isLoading ? (
        <div className="p-4 w-full">
          {_.size(rooms?.arr) > 0 ? (
            <div>
              <RoomList
                roomList={rooms?.arr}
                roomTypeList={roomTypes?.arr}
                handleEditRoom={(room) => {
                  setSelectedRoom(room)
                  setIsModalEditRoomOpen(true)
                }}
                handleDeleteRoom={handleDeleteRoom}
                handleChangeOnlineBookingOpen={handleChangeOnlineBookingOpen}
              />
            </div>
          ) : (
            <div className=" grid place-content-center place-items-center w-full p-5  mb-10">
              {t("roomtype.undefinedRoom")}
            </div>
          )}
        </div>
      ) : (
        <SpinnerLoading />
      )}
    </Layout>
  )
}
