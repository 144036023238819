import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Button, Input } from "@chakra-ui/react"
import _ from "lodash"
import * as actions from "../../../redux/actions"

// components
// import EmployeeLists from "./components/EmployeeLists";
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import Layout from "../../../components/layout/GeneralLayout"
import Paginator from "../../../components/common/Paginator/NewPagination"
import { CardEmployee } from "../../../components/common/Cards"
import { Confirm } from "../../../components/common/Alert"

export default function ManagementEmployee() {
  const employee = useSelector((state) => state.employee)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  useEffect(() => {
    dispatch(
      actions.getAllEmployee({
        page,
        size: 10,
        query: {
          name,
        },
      }),
    ).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [page, name])

  const handleConfirmDelete = async () => {
    try {
      setIsLoading(false)
      await dispatch(actions.deleteOneEmployee(selectedEmployee?.id))
      dispatch(actions.getAllEmployee({ page, size: 10 })).then(() => {
        setIsLoading(true)
        setIsConfirmDelete(false)
      })
    } catch (error) {
      console.error("Error", error.toString())
    }
  }

  return isLoading ? (
    <Layout categoryTitle="จัดการระบบ" title="จัดการพนักงาน">
      <Confirm
        title="ยืนยันการลบพนักงาน"
        body={`ยืนยันการลบ ${selectedEmployee?.firstname} ออกจากการเป็นพนักงานของระบบ ระบบจะลบเฉพาะข้อมูลในพนักงานเท่านั้น ถ้าหากพนักงานเป็นผู้ใช้งานที่มีบัญชีในการเข้าสู่ระบบอยู่ด้วย กรุณาลบที่จัดการผู้ใช้ อีกครั้ง`}
        handleOnClose={() => {
          setIsConfirmDelete(false)
        }}
        successText="ยืนยัน"
        cancleText="ยกเลิก"
        handleSuccess={() => {
          handleConfirmDelete()
        }}
        isOpen={isConfirmDelete}
      />
      <div className="flex justify-between p-4">
        <Link to={"/system/employee/create"}>
          <Button colorScheme="purple" variant="solid">
            เพิ่ม
          </Button>
        </Link>
        <div>
          <Input size="sm" placeholder="ค้นหา" onChange={(e) => setSearchWord(e.target.value)} />
        </div>
      </div>
      <div className="p-4 w-full">
        {_.size(employee?.arr) ? (
          <div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2">
              {_.map(employee?.arr, (eachEmployee, index) => (
                <div key={index}>
                  <div className="flex justify-center">
                    <CardEmployee
                      key={index}
                      firstname={eachEmployee?.firstname}
                      lastname={eachEmployee?.lastname}
                      subtitle={eachEmployee?.user?.username}
                      title={eachEmployee?.user?.role}
                      email={eachEmployee?.email}
                      image={eachEmployee?.image?.url}
                      phoneNumber={eachEmployee?.tel}
                    />
                  </div>
                  <div className="flex gap-2 justify-center ">
                    <Link to={`/system/employee/edit/${eachEmployee?.id}`}>
                      <Button colorScheme="yellow" size="sm">
                        แก้ไข
                      </Button>
                    </Link>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => {
                        setSelectedEmployee(eachEmployee)
                        setIsConfirmDelete(true)
                      }}
                    >
                      ลบ
                    </Button>{" "}
                    <Link to={`/system/user/password-edit/${eachEmployee?.user?._id}`}>
                      <Button colorScheme="blue" size="sm">
                        แก้ไขรหัสผ่าน
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <Paginator currentPage={page} lastPage={employee.lastPage} setPage={setPage} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </Layout>
  ) : (
    <SpinnerLoading />
  )
}
