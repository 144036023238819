import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@chakra-ui/react"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel, TableForm, Alert, SpinnerLoading } from "../../components"
import { appConfig } from "../../config"

import * as actions from "../../redux/actions"

export default function EditTable() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const table = useSelector((state) => state.table)
  const history = useHistory()
  const params = useParams()
  const { control, handleSubmit } = useForm()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errMessage, setErrMessage] = useState("")

  useEffect(() => {
    dispatch(actions.getOneTable(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [params])

  const handleEditTable = (data) => {
    console.log("Data", data)
    dispatch(actions.editOneBed(params.id, data))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="แก้ไขโต๊ะ">
      <Alert errMessage={errMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-between flex-wrap px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>

        <div className="w-full md:w-1/4  self-center mt-2"></div>
      </div>
      <div className="p-4">
        <form onSubmit={handleSubmit(handleEditTable)}>
          <WhitePanel>
            <TableForm control={control} defaultValue={table} />
          </WhitePanel>
          <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
