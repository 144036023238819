/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import _ from "lodash"

import { PaymentInfoForm, WhitePanel, DialogLoading } from "../../components"
import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"

export default function EditPaymentInfo() {
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const information = useSelector((state) => state.information)
  const { register, handleSubmit, watch, setValue } = useForm({ defaultValues: information })
  const [images, setImages] = useState([])
  const [dialogLoadingOn, setDialogLOadingOn] = useState(false)

  const onSubmit = (data) => {
    const payload = {
      ...data,
      payment_qr_code: _.isEmpty(images) ? information?.payment_qr_code?._id : images?.[0],
    }

    setDialogLOadingOn(true)
    dispatch(actions.editOneInformation(information?.id, payload)).then(() => {
      dispatch(actions.getInformation()).then(() => {
        toast({
          title: "แก้ไขข้อมูลสำเร็จ",
          description: "แก้ไขข้อมูลเกี่ยวกับโรงแรมและเว็บไซต์โรงแรมสำเร็จ",
          status: "success",
          duration: 3000,
          isClosable: true,
        })
        setImages([])
        setDialogLOadingOn(false)
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ตั้งค่าการชำระเงิน">
      <DialogLoading isLoading={dialogLoadingOn} />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <WhitePanel>
                <PaymentInfoForm
                  defaultValue={information}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  images={images}
                  setImages={setImages}
                />
              </WhitePanel>
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
