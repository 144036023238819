import TRANSACTION_GROUP from "./TransactionGroup"

export const TransactionType = {
  CREATE_BOOKING: {
    status_code: "CREATE_BOOKING",
    description: "สร้างการจอง",
    group: TRANSACTION_GROUP.BOOKING,
  },
  EDIT_BOOKING: {
    status_code: "EDIT_BOOKING",
    description: "แก้ไขการจอง",
    group: TRANSACTION_GROUP.BOOKING,
  },
  DELETE_BOOKING: {
    status_code: "DELETE_BOOKING",
    description: "ลบการจอง",
    group: TRANSACTION_GROUP.BOOKING,
  },
  ADD_SERVICE_BOOKING: {
    status_code: "ADD_SERVICE_BOOKING",
    description: "เพิ่มบริการเสริม",
    group: TRANSACTION_GROUP.BOOKING,
  },
  CREATE_PAYMENT: {
    status_code: "CREATE_PAYMENT",
    description: "สร้างการจ่ายเงิน",
    group: TRANSACTION_GROUP.PAYMENT,
  },
  GUEST_CHECKIN: {
    status_code: "GUEST_CHECKIN",
    description: "แขกเข้าเช็คอิน",
    group: TRANSACTION_GROUP.CHECKIN,
  },
  GUEST_CHECKOUT: {
    status_code: "GUEST_CHECKOUT",
    description: "สร้างการเช็คเอาท์",
    group: TRANSACTION_GROUP.CHECKIN,
  },
  CREATE_CUSTOMER: {
    status_code: "CREATE_CUSTOMER",
    description: "สร้างลูกค้า",
    group: TRANSACTION_GROUP.CUSTOMER,
  },
  EDIT_CUSTOMER: {
    status_code: "EDIT_CUSTOMER",
    description: "แก้ไขลูกค้า",
    group: TRANSACTION_GROUP.CUSTOMER,
  },
  EDIT_INFO: {
    status_code: "EDIT_INFO",
    description: "แก้ไขข้อมูลระบบ",
    group: TRANSACTION_GROUP.SYSTEM,
  },
  CREATE_SERVICING: {
    status_code: "CREATE_SERVICING",
    description: "สร้างการจอง",
    group: TRANSACTION_GROUP.SERVICING,
  },
  EDIT_SERVICING: {
    status_code: "EDIT_SERVICING",
    description: "แก้ไขการจอง",
    group: TRANSACTION_GROUP.SERVICING,
  },
  DELETE_SERVICING: {
    status_code: "DELETE_SERVICING",
    description: "ลบการจอง",
    group: TRANSACTION_GROUP.SERVICING,
  },
  ADD_SERVICE_SERVICING: {
    status_code: "ADD_SERVICE_SERVICING",
    description: "เพิ่มบริการเสริม",
    group: TRANSACTION_GROUP.SERVICING,
  },
  OTHER: { status_code: "OTHER", description: "อื่นๆ", group: TRANSACTION_GROUP.OTHER },
}

export default TransactionType
