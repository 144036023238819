import React, { useState, useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import { Input } from "antd"

import OnlineBookingLayout from "../../components/layout/OnlineBookingLayout"
import { SpinnerLoading, SpecifyRoomReservation, UnSpecifyRoomReservation } from "../../components"
import { appConfig, useQuery } from "../../config"
import * as actions from "../../redux/actions"
import { OnlineBookingContext } from "../../contexts/OnlineBookingContext"

function RoomSelection() {
  const { t } = useTranslation()
  const query = useQuery()
  const dispatch = useDispatch()
  const {
    specifyBookingList,
    checkinDate,
    checkoutDate,
    setCheckinDate,
    setCheckedoutDate,
    toggleSelectedRoom,
  } = useContext(OnlineBookingContext)
  const history = useHistory()
  const roomTypes = useSelector((state) => state.roomTypes)
  const rooms = useSelector((state) => state.rooms)
  const system = useSelector((state) => state.system)
  const subtype = useSelector((state) => state.subRoomTypes)

  console.log("system in room-select", system)

  const [selectedRoomType, setSelectedRoomType] = useState(query.get("roomtype"))
  const [isLoading, setIsLoading] = useState(false)
  const [rerender, setReRender] = useState(false)
  const [name, setName] = useState("")
  const [searchWord, setSearchWord] = useState("")

  console.log("selectedRoomType in room-select", selectedRoomType)

  useEffect(() => {
    setCheckinDate(dayjs(query.get("checkin")).toDate())
    setCheckedoutDate(dayjs(query.get("checkout")).toDate())
    return () => {}
  }, [query.get("checkin"), query.get("checkout")])

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getVacantRooms(
        dayjs(checkinDate).format("YYYY-MM-DD"),
        dayjs(checkoutDate).format("YYYY-MM-DD"),
      ),
    ).then(() => {
      dispatch(actions.getAllRoomTypes({ page: 1, size: appConfig.maxFetchSize, name: name || "" }))
      dispatch(
        actions.getAllSubRoomTypes({
          page: 1,
          size: 1000000,
          query: {
            start: dayjs(checkinDate).format("YYYY-MM-DD"),
            end: dayjs(checkoutDate).format("YYYY-MM-DD"),
            isShowAllDay: false,
            fetchBooking: true,
            fetchRoomBooking: true,
          },
        }),
      )
      setIsLoading(true)
    })
    return () => {}
  }, [checkinDate, checkoutDate, name])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  return (
    <OnlineBookingLayout
      title={t("reservation.selectRoom")}
      checkinDate={checkinDate}
      checkoutDate={checkoutDate}
      handleNext={() => {
        history.push("/online-booking/service-select")
      }}
      handlePrevious={() => {
        history.push("/online-booking")
      }}
      selectedRoomType={selectedRoomType}
      setSelectedRoomType={setSelectedRoomType}
      setCheckOutDate={setCheckedoutDate}
      setCheckedInDate={setCheckinDate}
      selectedRooms={specifyBookingList}
      selectedIndex={1}
    >
      <div className="flex justify-end px-2 my-4">
        <div className="md:w-1/2 lg:w-1/3">
          <Input
            value={searchWord}
            onChange={(event) => setSearchWord(event.target.value)}
            addonBefore={<i className="fas fa-search" />}
            placeholder={t("general.search")}
          />
        </div>
      </div>
      {isLoading ? (
        <div className="mb-20">
          {system?.setting?.onlineBooking?.enableType?.specify && (
            <SpecifyRoomReservation
              checkinDate={checkinDate}
              checkoutDate={checkoutDate}
              selectedRooms={specifyBookingList}
              toggleSelectedRoom={(state) => {
                toggleSelectedRoom(state)
                setReRender(!rerender)
              }}
              roomTypes={roomTypes}
              rooms={rooms}
            />
          )}
          {system?.setting?.onlineBooking?.enableType?.unSpecify && (
            <UnSpecifyRoomReservation
              roomTypes={roomTypes}
              subtype={subtype}
              setSelectedRoomType={setSelectedRoomType}
              rerenderSetting={() => {
                setReRender(!rerender)
              }}
            />
          )}
        </div>
      ) : (
        <div>
          <SpinnerLoading />
        </div>
      )}
    </OnlineBookingLayout>
  )
}

export default RoomSelection
