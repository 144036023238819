export const OnlineBookingProgress = {
  DATE_SEARCH: {
    status_code: "DATE_SEARCH",
    description: "ค้นหาวัน",
    descriptionEn: "Date Search",
    index: 0,
  },
  ROOM_SELECT: {
    status_code: "ROOM_SELECT",
    description: "เลือกห้องพัก",
    descriptionEn: "Room Select",
    index: 1,
  },
  EXTRA_SERVICE_SELECT: {
    status_code: "EXTRA_SERVICE_SELECT",
    description: "เลือกบริการเสริม",
    descriptionEn: "Add Extra Service",
    index: 2,
  },
  INFORMATION: {
    status_code: "INFORMATION",
    description: "กรอกข้อมูล",
    descriptionEn: "Information Input",
    index: 3,
  },
  CHECKOUT: {
    status_code: "CHECKOUT",
    description: "ชำระเงิน",
    descriptionEn: "Payment",
    index: 4,
  },
  SUCCESS: {
    status_code: "SUCCESS",
    description: "สำเร็จ",
    descriptionEn: "Success",
    index: 5,
  },
}

export default OnlineBookingProgress
