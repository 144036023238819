/* eslint-disable no-underscore-dangle */
import React, { createContext, useState } from "react"
import _ from "lodash"

export const MobileOrderContext = createContext(null)

export default function MobileOrderProvider({
  defaultColor = null,
  defaultTempList = [],
  children,
}) {
  const [color, setColor] = useState(defaultColor || "purple")

  const [rerender, setRerender] = useState(false)
  const [orderInfo, setOrderInfo] = useState()
  const [tempOrderList, setTempOrderList] = useState(defaultTempList)
  const toggleSelectedList = (input) => {
    console.log("Input Order Item", input)
    // if (!unAvailabelRoomList?.includes(roomId)) {
    if (_.includes(tempOrderList, input)) {
      const selectedRoomIndex = _.findIndex(tempOrderList, "menu._id", input?.menu?._id)
      console.log("Selected Room Index", selectedRoomIndex)
      tempOrderList.splice(selectedRoomIndex, 1)
      setTempOrderList(tempOrderList)
    } else {
      tempOrderList.push(input)
      setTempOrderList(tempOrderList)
    }
  }
  return (
    <MobileOrderContext.Provider
      value={{
        color,
        setColor,
        tempOrderList,
        setTempOrderList,
        toggleSelectedList,
        rerender,
        setRerender,
        orderInfo,
        setOrderInfo,
      }}
    >
      {children}
    </MobileOrderContext.Provider>
  )
}
