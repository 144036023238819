/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

// components
import { MobileOrderNavbar } from "../components"
import MobileOrderProvider from "../contexts/MobileOrderContext"

import FooterBooking from "../components/common/Footers/FooterBooking"
import * as actions from "../redux/actions"
import ByPassAuthorizeChecking from "../views/CheckIn/ByPassAuthorizeChecking"

// views
import MainPage from "../views/MobileOrder/MainPage"
import FoodMenu from "../views/MobileOrder/FoodMenu"
import DetailFoodMenu from "../views/MobileOrder/DetailFoodMenu"
import TemporaryOrder from "../views/MobileOrder/TemporaryOrder"
import DetailOrder from "../views/MobileOrder/DetailOrder"

export default function MobileOrder() {
  const dispatch = useDispatch()
  const [outerLogin, setIsOuterLogin] = useState(false)
  const { t } = useTranslation()

  console.log("outerLogin", outerLogin)

  useEffect(() => {
    console.log("Mobile Order Layout : ACTIVE")
    // Login User
    if (window.localStorage.eaccom_remember) {
      const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {
          setIsOuterLogin(false) // Direct To Checkin
          dispatch(actions.getInformation())
        })
        .catch((err) => {
          window.alert(err)
          setIsOuterLogin(true) // Go to Recaptcha
        })
    }

    dispatch(actions.systemInfoGet()).then(() => {})
    return () => {}
  }, [])

  useEffect(() => {
    if (window.localStorage.token) {
      dispatch(actions.getInformation())
        .then(() => {
          setIsOuterLogin(false) // Direct To Checkin
        })
        .catch((err) => {
          setIsOuterLogin(true) // Go to Recaptcha
        })
    }

    return () => {}
  }, [outerLogin])

  if (outerLogin) {
    return (
      <div>
        <MobileOrderNavbar displaySignOut={false} />
        <ByPassAuthorizeChecking
          setIsOuterLogin={setIsOuterLogin}
          categoryTitle={t("reservation.onlineBooking")}
          title={t("reservation.onlineBooking")}
        />
      </div>
    )
  }
  return (
    <div>
      <MobileOrderNavbar displaySignOut={false} />
      <div className=" relative bg-gray-200 ">
        <div className="px-4 py-10 lg:px-10 mx-auto min-h-screen my-12 lg:mx-24">
          <MobileOrderProvider>
            <Switch>
              <Route path="/mobile-order/temp-order" exact component={() => <TemporaryOrder />} />
              <Route
                path="/mobile-order/temp-order/:id"
                exact
                component={() => <TemporaryOrder />}
              />
              <Route path="/mobile-order/:id" exact component={() => <MainPage />} />
              <Route path="/mobile-order" exact component={() => <MainPage />} />
              <Route
                path="/mobile-order/menu/detail/:id"
                exact
                component={() => <DetailFoodMenu />}
              />
              <Route path="/mobile-order/menu/:id" exact component={() => <FoodMenu />} />
              <Route path="/mobile-order/detail/:id" exact component={() => <DetailOrder />} />

              <Redirect from="/mobile-order" to="/mobile-order" />
            </Switch>
          </MobileOrderProvider>
        </div>
      </div>
      <FooterBooking />
    </div>
  )
}
