import React from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ModuleList } from "../../config/constant"

function MenuPanel({ system, me, cols = 2 }) {
  const { t } = useTranslation()
  const gridColSelect = {
    2: "grid-cols-2",
    3: "grid-cols-3",
    4: "grid-cols-4",
  }
  return (
    <div>
      <div className={`grid grid-cols-1 lg:${gridColSelect[cols]} gap-2`}>
        {system?.available_modules?.[ModuleList.HISTORY.status_code] &&
          me?.access?.[ModuleList.HISTORY.status_code] && (
            <div className="my-4  px-4">
              <Link to={"/history"}>
                <div className=" bg-red-600 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-chart-bar fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">
                    {t("dashboard.title")} {t("history.title")}
                  </p>
                </div>
              </Link>
            </div>
          )}

        {system?.available_modules?.[ModuleList.RESERVATION.status_code] &&
          me?.access?.[ModuleList.RESERVATION.status_code] && (
            <div className="my-4  px-4">
              <Link to={"/reservation"}>
                <div className=" bg-indigo-600 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-calendar-alt fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">{t("reservation.title")}</p>
                </div>
              </Link>
            </div>
          )}

        {system?.available_modules?.[ModuleList.CRM.status_code] &&
          me?.access?.[ModuleList.CRM.status_code] && (
            <div className="my-4  px-4">
              <Link to={"/crm"}>
                <div className=" bg-pink-600 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-users fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">{t("crm.title")}</p>
                </div>
              </Link>
            </div>
          )}

        {system?.available_modules?.[ModuleList.ROOM_TYPES.status_code] &&
          me?.access?.[ModuleList.ROOM_TYPES.status_code] && (
            <div className="my-4  px-4">
              <Link to={"/roomtypes"}>
                <div className=" bg-green-600 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-hotel fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">{t("roomtype.title")}</p>
                </div>
              </Link>
            </div>
          )}

        {system?.available_modules?.[ModuleList.SITE.status_code] &&
          me?.access?.[ModuleList.SITE.status_code] && (
            <div className="my-4  px-4">
              {" "}
              <Link to={"/site"}>
                <div className=" bg-teal-500 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-store fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">{t("site.title")}</p>
                </div>
              </Link>
            </div>
          )}

        {system?.available_modules?.[ModuleList.FOOD_BEVERAGE.status_code] &&
          me?.access?.[ModuleList.FOOD_BEVERAGE.status_code] && (
            <div className="my-4  px-4">
              {" "}
              <Link to={"/food-beverage"}>
                <div className=" bg-purple-800 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-mug-hot fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">
                    {t("foodAndBeverage.title")}
                  </p>
                </div>
              </Link>
            </div>
          )}
        {system?.available_modules?.[ModuleList.INVENTORY.status_code] &&
          me?.access?.[ModuleList.INVENTORY.status_code] && (
            <div className="my-4  px-4">
              {" "}
              <Link to={"/inventory"}>
                <div className=" bg-cyan-800 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-warehouse fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">{t("inventory.title")}</p>
                </div>
              </Link>
            </div>
          )}

        {system?.available_modules?.[ModuleList.SYSTEM.status_code] &&
          me?.access?.[ModuleList.SYSTEM.status_code] && (
            <div className="my-4  px-4">
              {" "}
              <Link to={"/system"}>
                <div className=" bg-gray-800 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                  <i className="fas fa-cogs fa-3x text-white"></i>
                  <p className="text-lg text-white mt-4 font-semibold">{t("system.title")}</p>
                </div>
              </Link>
            </div>
          )}
      </div>
    </div>
  )
}

export default MenuPanel
