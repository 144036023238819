/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Switch,
} from "@chakra-ui/react"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Tag } from "antd"

import CreateOrEditCustomerModal from "../../../components/Modal/CustomerManagement/CreateOrEditCustomerModal"
import { RoomSelector, SubtypeSelector } from "../../../components/Selectors"
import { BookingInfoForm } from "../../../components/Forms"
import { BookingStatus, paymentStatus } from "../../../config/constant"
import { dayAmount } from "../../../util"
import * as actions from "../../../redux/actions"
import {
  SpinnerLoading as Spinners,
  Alert,
  Confirm,
  BookingPriceAddingForm,
  PricePanel,
} from "../../../components"
import { appConfig } from "../../../config"

// Create Room Booking with List View
export default function CreateRoomListReservation() {
  // Gloabal State Selector
  const customers = useSelector((state) => state.customers)
  const roomTypes = useSelector((state) => state.roomTypes)
  const subtype = useSelector((state) => state.subRoomTypes)
  const rooms = useSelector((state) => state.rooms)
  const me = useSelector((state) => state.me)
  const system = useSelector((state) => state.system)

  // Library Hook
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: { list: [] },
  })
  const { t, i18n } = useTranslation()

  // Local State
  const [options, setOptions] = useState([])
  const [isModalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [selectedRooms, setSelectedRooms] = useState([])
  const [checkedInDate, setCheckedInDate] = useState(dayjs().toDate())
  const [checkedOutDate, setCheckedoutDate] = useState(dayjs().add(1, "day").toDate())
  const [isLoading, setIsLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [formData, setFormData] = useState()
  const [isNotRoomSpecify, setIsNotRoomSpecify] = useState(system?.setting?.setPreBookingAsDefault)
  const [tempCustomerName, setTempCustomerName] = useState("")
  const [reRender, setRerender] = useState(false)
  const [allPrice, setAllPrice] = useState(0)
  const [allDiscount, setAllDiscount] = useState(0)
  const [finalDiscount, setFinalDiscount] = useState(0)
  const [modalPriceAndServiceOn, setModalPriceAndServiceOn] = useState(false)
  const [isIgnoreLog, setIsIgnoreLog] = useState(false)

  const onHandleFromRoomSelectToValidate = (data) => {
    if (selectedCustomer && selectedRooms) {
      setFormData(data)

      const bookingList = _.map(selectedRooms, (eachRoom) => ({
        room_id: eachRoom.id,
        room: eachRoom,
        price: eachRoom?.sub_type?.price,
      }))
      setAllPrice(
        bookingList?.map(
          (eachList) => eachList?.price || 0 * dayAmount(checkedInDate, checkedOutDate) || 0,
        ),
      )
      setAllDiscount(
        bookingList?.map(
          (eachList) => eachList?.discount || 0 * dayAmount(checkedInDate, checkedOutDate) || 0,
        ),
      )
      setFinalDiscount(0)

      // setConfirmOpen(true)
      setModalPriceAndServiceOn(true)
    } else {
      setAlertOpen(true)
      setAlertMessage(t("reservation.alertIncompleteData"))
    }
  }

  // For Remove Specify Room
  const handleDelete = (eachRoom) => {
    const selectedRoomIndex = _.findIndex(selectedRooms, { id: eachRoom?.id })
    console.log("Selected Room Index", selectedRoomIndex)
    selectedRooms.splice(selectedRoomIndex, 1)
    setSelectedRooms(selectedRooms)
    setRerender(!reRender)
  }

  // Main Booking Command
  const handleCreateBooking = async (data) => {
    console.log("formData?.no_room_booking", formData?.no_room_booking)
    const noRoomBooking = _.filter(
      formData?.no_room_booking,
      (each) => parseInt(each?.amount, 10) > 0,
    )

    const payload = {
      customer_id: selectedCustomer?.value,
      list: data?.list,
      no_room_booking: noRoomBooking,
      start: dayjs(checkedInDate).locale("th").hour(12).minute(1).toDate(),
      end: dayjs(checkedOutDate).locale("th").hour(11).minute(59).toDate(),
      status: BookingStatus.confirm,
      paymentStatus: paymentStatus.deposit.depositWait,
      remark: formData?.remark,
      booking_source: formData?.booking_source,
      capacity: formData?.capacity,
      user: me?._id,
      final_discount: data.final_discount,
      use_discount_percent: !!data.use_discount_percent,
    }
    console.log(payload)
    try {
      dispatch(actions.createOneBooking(payload))
        .then((result) => {
          if (system?.setting?.showListViewOfBookingInstead) {
            history.push(`/reservation/list`)
          } else {
            history.push(
              `/reservation/room?checkin=${dayjs(checkedInDate)
                .startOf("day")
                .format("YYYY-MM-DD")}`,
            )
          }
        })
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(`${t("reservation.alertCannotBooking")} ${err?.message}`)
        })
    } catch (err) {
      setAlertOpen(true)
      setAlertMessage(`${t("reservation.alertCannotBooking")} ${err?.message}`)
    }
  }

  const handleCreateCustomer = (data) => {
    dispatch(actions.createOneCustomer(data))
      .then(() => {
        dispatch(actions.getAllCustomers(1, 1000000, ""))
        setIsModalCreateCustomerOpen(false)
      })
      .catch((err) => {
        window.alert("Create Customer Error", err?.message)
      })
  }

  useEffect(() => {
    dispatch(actions.getAllCustomers(1, 1000000, ""))
    setIsNotRoomSpecify(system?.setting?.setPreBookingAsDefault)
    return () => {}
  }, [])

  useEffect(() => {
    setIsLoading(false)
    setSelectedRooms([])
    dispatch(
      actions.getVacantRooms(
        dayjs(checkedInDate).format("YYYY-MM-DD"),
        dayjs(checkedOutDate).format("YYYY-MM-DD"),
        null,
        null,
        isIgnoreLog,
      ),
    ).then(() => {
      dispatch(
        actions.getAllSubRoomTypes({
          page: 1,
          size: appConfig.maxFetchSize,
          query: {
            start: dayjs(checkedInDate).format("YYYY-MM-DD"),
            end: dayjs(checkedOutDate).format("YYYY-MM-DD"),
            isShowAllDay: false,
            fetchBooking: true,
          },
        }),
      )
        .then((result) => {})
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(`${t("reservation.alertCannotBooking")} ${err?.message}`)
        })
      dispatch(
        actions.getAllRoomTypes({
          start: dayjs(checkedInDate).format("YYYY-MM-DD"),
          end: dayjs(checkedOutDate).format("YYYY-MM-DD"),
          isShowAllDay: false,
          fetchBooking: true,
        }),
      )
        .then((result) => {})
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(`${t("reservation.alertCannotBooking")} ${err?.message}`)
        })
      setIsLoading(true)
    })
    return () => {}
  }, [checkedInDate, checkedOutDate, isIgnoreLog])

  useEffect(() => {
    const rowsData = _.map(customers?.arr, (eachRow) => ({
      label: `${eachRow.firstname} ${eachRow.lastname}`,
      value: eachRow.id,
    }))

    setOptions(rowsData)
    setSelectedCustomer(rowsData?.[0])
    setIsLoading(true)

    return () => {}
  }, [customers])

  return (
    <div className="w-full">
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => {
          setAlertOpen(false)
          setAlertMessage("")
        }}
        isError={alertOpen}
      />
      <Confirm
        title={t("reservation.confirmBooking")}
        body={t("reservation.confirmThisBooking")}
        isOpen={confirmOpen}
        handleOnClose={() => {
          setConfirmOpen(false)
        }}
        handleSuccess={() => handleCreateBooking()}
        successText={t("general.confirm")}
        cancleText={t("general.cancle")}
      />
      <CreateOrEditCustomerModal
        isOpen={isModalCreateCustomerOpen}
        handleOnClose={() => setIsModalCreateCustomerOpen(false)}
        handleCreateCustomer={handleCreateCustomer}
        defaultValue={{ firstname: tempCustomerName }}
      />
      <Modal
        isOpen={modalPriceAndServiceOn}
        onClose={() => setModalPriceAndServiceOn(false)}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="font-sans" fontSize="lg">
            สรุปข้อมูลเพื่อเพิ่มการจองห้องพัก
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div className="w-full">
                <BookingPriceAddingForm
                  allDiscount={allDiscount}
                  allPrice={allPrice}
                  control={control}
                  endDate={dayjs(checkedOutDate).locale("th").hour(11).minute(59).toISOString()}
                  startDate={dayjs(checkedInDate).locale("th").hour(12).minute(1).toISOString()}
                  finalDiscount={finalDiscount}
                  register={register}
                  rerender={reRender}
                  setRerender={setRerender}
                  rooms={rooms}
                  setAllDiscount={setAllDiscount}
                  setAllPrice={setAllPrice}
                  setFinalDiscount={setFinalDiscount}
                  setValue={setValue}
                  watch={watch}
                  inputBookingList={_.map(selectedRooms, (eachRoom) => ({
                    room_id: eachRoom.id,
                    room: eachRoom,
                    price: eachRoom?.sub_type?.price,
                  }))}
                />
              </div>
              <hr />
              <div className="font-bold  my-2 self-center font-sans">สรุปราคา</div>
              <div className="w-full">
                <PricePanel
                  allPrice={_.sum(allPrice) - _.sum(allDiscount) - finalDiscount}
                  bookingDiscount={_.sum(allDiscount)}
                  bookingInfo={{
                    list: watch("list"),
                  }}
                  finalDiscount={finalDiscount}
                  displayPercentDiscount={watch("use_discount_percent")}
                  displayPaymentAmount={false}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="green"
              className="font-sans"
              onClick={handleSubmit(handleCreateBooking)}
            >
              ยืนยัน
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <form>
        <BookingInfoForm
          checkedInDate={checkedInDate}
          setCheckedInDate={setCheckedInDate}
          checkedOutDate={checkedOutDate}
          setCheckedoutDate={setCheckedoutDate}
          control={control}
          customerOption={options}
          isNotRoomSpecify={isNotRoomSpecify}
          selectedCustomer={selectedCustomer}
          setIsModalCreateCustomerOpen={setIsModalCreateCustomerOpen}
          setIsNotRoomSpecify={setIsNotRoomSpecify}
          setSelectedCustomer={setSelectedCustomer}
          setTempCustomerName={setTempCustomerName}
        />
        <div className="flex justify-end my-2">
          <div className="flex gap-2 border rounded-md p-4 border-gray-400">
            <Switch
              value={isIgnoreLog}
              checked={isIgnoreLog}
              onChange={(event) => setIsIgnoreLog(event.target.checked)}
            />
            <div className="text-sm">แสดงรายการที่เช็คเอาท์เป็นห้องว่าง</div>
          </div>
        </div>
        <div className="my-2 px-2">
          {isLoading ? (
            <div>
              {isNotRoomSpecify ? (
                <SubtypeSelector
                  subtype={subtype}
                  rooms={rooms?.arr}
                  register={register}
                  watch={watch}
                />
              ) : (
                <div>
                  <div className="w-full">
                    {!isNotRoomSpecify && (
                      <div>
                        <div className="my-2 font-semibold text-sm">รายการที่เลือก</div>
                        {_.map(selectedRooms, (each, index) => (
                          <Tag
                            key={index}
                            closable
                            onClose={(e) => {
                              e.preventDefault()
                              handleDelete(each)
                            }}
                          >
                            {each?.name}
                          </Tag>
                        ))}
                      </div>
                    )}
                  </div>
                  <RoomSelector
                    roomTypes={roomTypes}
                    rooms={rooms?.arr}
                    selectedRooms={selectedRooms}
                    setSelectedRooms={(eachRoom) => {
                      setSelectedRooms(eachRoom)
                      setRerender(!reRender)
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <Spinners />
          )}
        </div>

        <div className="text-center  gap-0 py-6 fixed bottom-10 z-10 px-5 bg-white w-9/12 lg:w-2/4 flex justify-start shadow-lg rounded-md">
          <Button
            colorScheme="purple"
            variant="solid"
            type="button"
            isFullWidth
            onClick={handleSubmit(onHandleFromRoomSelectToValidate)}
          >
            {t("reservation.roomBook")}
          </Button>
        </div>
      </form>
    </div>
  )
}
