/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import { Text, Button } from "@chakra-ui/react"
import _ from "lodash"

import OnlineBookingLayout from "../../components/layout/OnlineBookingLayout"
import { SpinnerLoading, WhitePanel } from "../../components"
import { appConfig, useQuery } from "../../config"
import * as actions from "../../redux/actions"
import { OnlineBookingContext } from "../../contexts/OnlineBookingContext"
import { currencyFormatter } from "../../util"

function ServiceSelect() {
  const { t, i18n } = useTranslation()
  const query = useQuery()
  const dispatch = useDispatch()
  const {
    color,
    specifyBookingList,
    checkinDate,
    checkoutDate,
    setCheckinDate,
    setCheckedoutDate,
    selectedServices,
    setSelectedServices,
    rerender,
    setRerender,
    unSpecifyBookingList,
  } = useContext(OnlineBookingContext)
  const history = useHistory()
  const hotelService = useSelector((state) => state.hotelService)
  const [selectedRoomType, setSelectedRoomType] = useState(query.get("roomtype"))
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(
      actions.getAllServices({ page: 1, size: appConfig.maxFetchSize, onlineAvailable: true }),
    ).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  const handleAddService = (addedService) => {
    if (!_.isArray(selectedServices)) {
      setSelectedServices([{ service: addedService, amount: 1 }])
    } else {
      const foundedService = _.find(
        selectedServices,
        (each) => each?.service?._id === addedService?._id,
      )

      if (foundedService) {
        const foundServiceIndex = _.findIndex(
          selectedServices,
          (each) => each?.service?._id === addedService?._id,
        )

        delete selectedServices[foundServiceIndex]
        selectedServices.push({ service: addedService, amount: foundedService?.amount + 1 })
        setSelectedServices(selectedServices)
      } else {
        selectedServices.push({ service: addedService, amount: 1 })
        setSelectedServices(selectedServices)
      }
    }
    setRerender(!rerender)
  }

  const checkServiceEnable = (eachService) => {
    if (eachService?.limit_per_order_enable) {
      const foundedService = _.find(
        selectedServices,
        (each) => each?.service?._id === eachService?._id,
      )
      if (foundedService) {
        // Find Amount of room in order

        const filterOnSubtype = _.filter(unSpecifyBookingList, (each) =>
          _.includes(
            _.map(eachService?.online_available?.available_roomtype, (eachRoom) => eachRoom?._id),
            each?.subtype?.type?._id,
          ),
        )

        console.log("Filter On Subtype", filterOnSubtype)
        const sumFilter = _.sumBy(filterOnSubtype, "amount")

        if (
          foundedService?.amount >=
          eachService?.limit_per_order * (sumFilter !== 0 ? sumFilter : 1)
        ) {
          return false
        }
      }
    }

    return true
  }
  return (
    <OnlineBookingLayout
      title={t("reservation.addExtraService")}
      checkinDate={checkinDate}
      checkoutDate={checkoutDate}
      handleNext={() => {
        history.push("/online-booking/add-info")
      }}
      handlePrevious={() => {
        history.push(
          `/online-booking/room-select?checkin=${dayjs(checkinDate).format(
            "YYYY-MM-DD",
          )}&checkout=${dayjs(checkoutDate).format("YYYY-MM-DD")}`,
        )
      }}
      selectedRoomType={selectedRoomType}
      setSelectedRoomType={setSelectedRoomType}
      setCheckOutDate={setCheckedoutDate}
      setCheckedInDate={setCheckinDate}
      selectedRooms={specifyBookingList}
      selectedIndex={2}
    >
      {isLoading ? (
        <div className="px-2 mb-20">
          <h2 className="text-xl font-semibold my-2 ">{t("reservation.extraService")}</h2>
          {_.map(
            _.filter(hotelService?.arr, (each) => {
              if (each?.online_available?.mode === "all") {
                return true
              }
              if (each?.online_available?.mode === "some") {
                const foundRoomType = _.find(
                  each?.online_available?.available_roomtype,
                  (eachRoomType) => {
                    return _.includes(
                      _.map(unSpecifyBookingList, (each) => each?.subtype?.type?._id),
                      eachRoomType?._id,
                    )
                  },
                )
                if (foundRoomType) {
                  return true
                }
                return false
              }
            }),
            (each, index) => (
              <div key={index}>
                <WhitePanel>
                  <div className="flex flex-wrap">
                    <div className="w-1/2 md:w-3/4  px-2">
                      <h3 className="text-lg font-bold font-sans">
                        {each?.translation?.[i18n.language]?.name || each?.translation?.name}
                      </h3>

                      {each?.limit_per_order_enable && (
                        <p>
                          {" "}
                          {t("reservation.availableAmount")} {each?.limit_per_order}{" "}
                          {t("dashboard.item")}
                          {t("reservation.perRoom")}{" "}
                        </p>
                      )}
                      <p> {each?.translation?.[i18n.language]?.description}</p>
                    </div>
                    <div className="w-1/2 md:w-1/4  px-2">
                      <div className="flex gap-2">
                        <div>
                          <div className="text-sm">{t("reservation.price.pricePerUnit")}</div>
                          <div className="text-lg ">
                            <Text fontSize="lg" textColor={color} fontWeight="bold">
                              {currencyFormatter.format(each?.price)} THB{" "}
                              {each?.price_per_night ? `/ ${t("reservation.perNight")}` : " "}
                            </Text>
                          </div>
                        </div>
                        <div>
                          <Button
                            colorScheme={color}
                            disabled={!checkServiceEnable(each)}
                            onClick={() => handleAddService(each)}
                          >
                            {t("general.add")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </WhitePanel>
              </div>
            ),
          )}
        </div>
      ) : (
        <div>
          <SpinnerLoading />
        </div>
      )}
    </OnlineBookingLayout>
  )
}

export default ServiceSelect
