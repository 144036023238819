import React from "react"
import PropTypes from "prop-types"
import { Tag } from "@chakra-ui/react"

export default function CardEmployee({
  firstname,
  lastname,
  title,
  subtitle,
  email,
  phoneNumber,
  image,
}) {
  return (
    <>
      <div className="w-full rounded rounded-t-lg overflow-hidden shadow-lg max-w-xs my-3 bg-white py-4">
        <div className="flex justify-center ">
          {image ? (
            <>
              <img
                alt="..."
                src={image}
                className="shadow-md rounded-full h-32 align-middle border-none  my-2 w-32"
              />
            </>
          ) : (
            <div className="shadow-md rounded-full h-32 align-middle border-none  my-2 w-32 flex justify-center">
              <i className="fa fa-user fa-4x my-5 "></i>
            </div>
          )}
        </div>
        <div className="text-center px-3 pb-6 pt-2">
          <h3 className="text-black  font-semibold font-sans">{`${firstname} ${lastname}`}</h3>
          <p className="mt-2  text-gray-500">{subtitle}</p>
          <p className="mt-2 uppercase text-xs">
            {" "}
            <Tag>{title || "-"}</Tag>
          </p>
        </div>
        <hr />
        <div className="flex  flex-wrap justify-center my-2 text-grey-dark">
          <div className="text-center flex gap-2 min-w-max-content">
            <i className="fa fa-phone"></i>
            <div className="text-xs">{phoneNumber}</div>
          </div>
        </div>
      </div>
    </>
  )
}

CardEmployee.defaultProps = {
  firstname: "ชื่อ",
  lastname: "สกุล",
  title: "",
  subtitle: "",
  email: "อีเมล",
  phoneNumber: "เบอร์โทรศัพท์",
  image: null,
}

CardEmployee.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  image: PropTypes.string,
}
