export const HorizontalType = {
    HORIZONTAL_BASIC: {
        status_code: "HORIZONTAL_BASIC",
        description: "แกลอรี่แนวนอนแบบธรรมดา",
        descriptionEn: "Horizontal Basic",
    },
    HORIZONTAL_STYLE1: {
        status_code: "HORIZONTAL_STYLE1",
        description: "แกลอรี่แนวนอนแบบ 1",
        descriptionEn: "Horizontal Style1",
    },
    // HORIZONTAL_STYLE2: {
    //     status_code: "HORIZONTAL_STYLE2",
    //     description: "แกลอรี่แนวนอนแบบ 2",
    //     descriptionEn: "Horizontal Style2",
    // },
}

export default HorizontalType;
