import React from "react"
import { Badge } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { BookingSource } from "../../../config/constant"

export default function BookingSourceBadge({ sourceOfBooking }) {
  const { i18n, t } = useTranslation()
  const renderDescOfSource = (bookingSource) => {
    switch (bookingSource) {
      case BookingSource.LINE.status_code:
        return (
          <div className="text-xs">
            <i className="fab fa-line mr-2"></i>{" "}
            {i18n.language === "th"
              ? BookingSource.LINE.description
              : BookingSource.LINE.descriptionEN}
          </div>
        )
      case BookingSource.FACEBOOK.status_code:
        return (
          <div className="text-xs">
            <i className="fab fa-facebook mr-2"></i>{" "}
            {i18n.language === "th"
              ? BookingSource.FACEBOOK.description
              : BookingSource.FACEBOOK.descriptionEN}
          </div>
        )
      case BookingSource.WE_CHAT.status_code:
        return (
          <div className="text-xs">
            <i className="fab fa-weixin mr-2"></i>{" "}
            {i18n.language === "th"
              ? BookingSource.WE_CHAT.description
              : BookingSource.WE_CHAT.descriptionEN}
          </div>
        )
      case BookingSource.WHATAPPS.status_code:
        return (
          <div>
            <i className="fab fa-whatsapp mr-2"></i>{" "}
            {i18n.language === "th"
              ? BookingSource.WHATAPPS.description
              : BookingSource.WHATAPPS.descriptionEN}
          </div>
        )
      case BookingSource.TELEPHONE.status_code:
        return (
          <div>
            <i className="fas fa-phone mr-2"></i>{" "}
            {i18n.language === "th"
              ? BookingSource.TELEPHONE.description
              : BookingSource.TELEPHONE.descriptionEN}
          </div>
        )
      case BookingSource.COUNTER_BOOKING.status_code:
        return (
          <div>
            <i className="fas fa-laptop mr-2"></i>{" "}
            {i18n.language === "th"
              ? BookingSource.COUNTER_BOOKING.description
              : BookingSource.COUNTER_BOOKING.descriptionEN}
          </div>
        )

      default:
        return <div className="text-xs">{BookingSource?.[bookingSource]?.description}</div>
    }
  }
  return (
    <Badge size="sm" rounded="lg" colorScheme="teal">
      {renderDescOfSource(sourceOfBooking)}
    </Badge>
  )
}
