/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import _ from "lodash"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import { Alert } from "../../components/common/Alert"
import { WhitePanel } from "../../components/common/Cards"

export default function GalleryManagement() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const gallery = useSelector((state) => state.gallery)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { register, handleSubmit } = useForm()

  useEffect(() => {
    dispatch(actions.getAllGallery()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const handleCreateGallery = (data) => {
    dispatch(actions.createGallery(data))
      .then(() => {
        dispatch(actions.getAllGallery())
          .then(() => {
            setIsLoading(true)
            onClose()
          })
          .catch((err) => {
            onClose()
            setIsError(true)
            setErrorMessage(err?.message)
          })
      })
      .catch((err) => {
        onClose()
        setIsLoading(true)
        setIsError(true)
        setErrorMessage(err?.message)
      })
  }

  const handleOnDeleteGallery = (galleryId) => {
    const confirm = window.confirm("ยืนยันการลบแกลอรี่นี้")
    if (confirm) {
      dispatch(actions.deleteOneGallery(galleryId))
        .then(() => {
          dispatch(actions.getAllGallery())
        })
        .catch((error) => {
          setIsError(true)
          setErrorMessage(`ขออภัย ไม่สามารถลบแกลอรี่ได้ ${error?.message}`)
        })
    }
  }

  const renderAlert = () => (
    <Alert isError={isError} handleOnClose={() => setIsError(false)} errMessage={errorMessage} />
  )

  const renderModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form>
          <ModalHeader>
            <div className="font-sans text-xl">สร้างแกลอรี่</div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel fontSize="sm">ชื่อแกลอรี่ (ภาษาไทย)</FormLabel>
            <Input
              size="sm"
              placeholder="ชื่อแกลอรี่"
              ref={register}
              name="translation.th.name"
              className="font-sans"
            />{" "}
            <FormLabel fontSize="sm">ชื่อแกลอรี่ (ภาษาอังกฤษ)</FormLabel>
            <Input
              size="sm"
              placeholder="Gallery Name"
              ref={register}
              name="translation.en.name"
              className="font-sans"
            />
          </ModalBody>
          <ModalFooter className="gap-2">
            <Button
              colorScheme="green"
              type="button"
              onClick={handleSubmit(handleCreateGallery)}
              size="sm"
            >
              สร้าง
            </Button>
            <Button colorScheme="gray" type="button" onClick={onClose} size="sm">
              ยกเลิก
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )

  return (
    <Layout categoryTitle={t("site.title")} title="แกลอรี่">
      {renderAlert()}
      {renderModal()}
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" onClick={onOpen}>
          {t("general.add")}
        </Button>
      </div>
      <div className="p-4 w-full min-h-screen">
        {_.map(gallery?.arr, (eachGallery, index) => (
          <div key={index} className="my-2">
            <WhitePanel>
              <div className="flex justify-between">
                <div className="text-xl font-semibold">
                  {eachGallery?.translation?.[i18n.language]?.name || eachGallery?.name}
                </div>
                <div className="flex gap-2">
                  <Link to={`/site/gallery/edit/${eachGallery?.id}`}>
                    <Button colorScheme="yellow" size="sm">
                      {t("general.edit")}
                    </Button>
                  </Link>{" "}
                  <Link to={`/site/gallery/photo/${eachGallery?.id}`}>
                    <Button colorScheme="blue" size="sm">
                      เพิ่ม/ลด รูปภาพ
                    </Button>
                  </Link>
                  <Button
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleOnDeleteGallery(eachGallery?.id)}
                  >
                    {t("general.delete")}
                  </Button>
                </div>
              </div>
              <div>จำนวน {_.size(eachGallery?.images)} รูป</div>
              <div className="flex gap-2 my-2 ">
                {_.map(_.take(eachGallery?.images, 4), (eachImage, imageIndex) => (
                  <div key={imageIndex}>
                    <img src={eachImage?.image?.url} className="h-48" />
                  </div>
                ))}
              </div>
            </WhitePanel>
          </div>
        ))}
      </div>
    </Layout>
  )
}
