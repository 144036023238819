import React from "react"
import MenuPanel from "../../Panels/MenuPanel"

function Type1Homepage({ me, system }) {
  return (
    <div>
      <section className="container mx-auto items-center flex flex-wrap min-h-screen font-sans">
        <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-20">
          <MenuPanel me={me} system={system} />
        </div>
      </section>
    </div>
  )
}

export default Type1Homepage
