/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { useHistory, useParams } from "react-router-dom"
import { DateTime } from "luxon"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import "dayjs/locale/th"
import { Select, DatePicker, InputNumber, Input } from "antd"

import RoomSelector from "../../../components/Selectors/RoomSelector"
import * as actions from "../../../redux/actions"
import Spinners from "../../../components/Loading/SpinnerLoading"
import { BasicBookingInfoPanel } from "../../../components/Panels"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import Layout from "../../../components/layout/BookingLayout"
import { BookingSource } from "../../../config/constant"

dayjs.locale("th")

// TODO: Using New Alert Components
export default function EditReservation() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { handleSubmit, control } = useForm()
  const roomTypes = useSelector((state) => state.roomTypes)
  const rooms = useSelector((state) => state.rooms)
  const booking = useSelector((state) => state.bookings)
  const me = useSelector((state) => state.me)
  const { t, i18n } = useTranslation()
  const { RangePicker } = DatePicker
  const { TextArea } = Input

  const [avaliableRoom, setAvaliableRoom] = useState(rooms?.arr)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [checkedInDate, setCheckedInDate] = useState(dayjs(booking?.start).toDate())
  const [checkedOutDate, setCheckedoutDate] = useState(dayjs(booking?.end).toDate())
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(actions.getOneBooking(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        console.error("Booking Get Error", err)
      })
    return () => {}
  }, [params])

  useEffect(() => {
    if (
      booking &&
      dayjs(booking?.start).isSame(dayjs(checkedInDate), "date") &&
      (dayjs(booking?.end).isBefore(dayjs(checkedOutDate), "date") ||
        dayjs(booking?.end).isSame(dayjs(checkedOutDate), "date"))
    ) {
      const bookedRoom = _.map(booking?.list, (eachList) => ({
        ...eachList?.room,
        id: eachList?.room?._id,
      }))
      const concatingRoom = _.concat(rooms?.arr, bookedRoom)
      setSelectedRooms(bookedRoom)
      setAvaliableRoom(concatingRoom)
    } else {
      setAvaliableRoom(rooms?.arr)
      setSelectedRooms([])
    }
    return () => {}
  }, [booking, checkedInDate, rooms])

  useEffect(() => {
    if (booking) {
      setCheckedInDate(dayjs(booking?.start).toDate())
      setCheckedoutDate(dayjs(booking?.end).toDate())
    }

    return () => {}
  }, [booking])

  const onEditBooking = (data) => {
    if (selectedRooms) {
      const confirm = window.confirm(t("reservation.confirmEditBooking"))
      if (confirm) {
        const payload = {
          list: _.map(
            _.filter(selectedRooms, (room) => room !== undefined),
            (eachRoom) => ({
              room_id: eachRoom.id,
              room: eachRoom.id,
              price: eachRoom?.sub_type?.price,
            }),
          ),
          start: dayjs(checkedInDate).startOf("day"),
          end: dayjs(checkedOutDate).startOf("day"),
          remark: data?.remark,
          booking_source: data?.booking_source,
          user: me?._id,
          capacity: data?.capacity,
          info: "แก้ไขวัน หรือ ห้องพักของการจอง",
        }

        console.log(payload)
        dispatch(actions.editOneBooking(params.id, payload))
          .then(() => {
            dispatch(actions.getOneBooking(params.id))
            history.push(`/reservation/view/${params.id}`)
          })
          .catch((err) => {
            window.alert("Edit Booking Fail", err?.message)
          })
      }
    } else {
      window.alert(t("reservation.alertIncompleteData"))
    }
  }

  useEffect(() => {
    dispatch(actions.getAllRoomTypes())
    return () => {}
  }, [])

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getVacantRooms(
        DateTime.fromJSDate(checkedInDate).toISODate(),
        DateTime.fromJSDate(checkedOutDate).toISODate(),
      ),
    ).then(() => {
      if (booking?.id) {
        setIsLoading(true)
      }
    })
    return () => {}
  }, [checkedInDate, checkedOutDate])

  return (
    <Layout title={t("reservation.editBooking")} categoryTitle={t("reservation.title")}>
      <div className="w-full flex px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="w-full my-2">
        <WhitePanel>
          <BasicBookingInfoPanel bookingInfo={booking} showPrintingPanel={false} />
        </WhitePanel>
        {isLoading ? (
          <form onSubmit={handleSubmit(onEditBooking)}>
            <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
              <div className="w-full px-4 ">
                <div className="flex flex-wrap ">
                  <div className="relative w-full lg:w-1/3 mb-3 px-1 ">
                    <label
                      className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      {t("reservation.checkinDate")} - {t("reservation.checkoutDate")}
                    </label>
                    <RangePicker
                      defaultValue={[dayjs(checkedInDate), dayjs(checkedOutDate)]}
                      onChange={(val) => {
                        setCheckedInDate(val?.[0]?.toDate())
                        setCheckedoutDate(val?.[1]?.toDate())
                      }}
                      size="large"
                      format="D/MM/BBBB"
                    />
                  </div>
                  <div className="relative w-full lg:w-1/3 mb-3 px-1">
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      {t("reservation.bookingSource")}
                    </label>
                    <Controller
                      control={control}
                      name="booking_source"
                      defaultValue={booking?.booking_source}
                      render={(field) => (
                        <Select
                          {...field}
                          placeholder={t("reservation.bookingSource")}
                          size="large"
                          style={{
                            width: 200,
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? "").includes(input)}
                          showSearch
                          options={_.map(BookingSource, (eachBookingSource) => ({
                            value: eachBookingSource?.status_code,
                            label:
                              i18n?.language === "th"
                                ? eachBookingSource?.description
                                : eachBookingSource?.descriptionEN,
                          }))}
                        ></Select>
                      )}
                    />
                  </div>{" "}
                  <div className="relative w-full lg:w-1/3 mb-3 px-2">
                    <div className="flex gap-2">
                      <div>
                        {" "}
                        <label
                          className="block  text-gray-700 text-sm font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("general.amount")} {t("roomtype.adult")}
                        </label>
                        <Controller
                          control={control}
                          name="capacity.adult"
                          defaultValue={booking?.capacity?.adult}
                          render={(field) => (
                            <InputNumber
                              {...field}
                              placeholder={t("roomtype.adult")}
                              size="large"
                            />
                          )}
                        />
                      </div>{" "}
                      <div>
                        {" "}
                        <label
                          className="block  text-gray-700 text-sm font-semibold mb-2"
                          htmlFor="grid-password"
                        >
                          {t("general.amount")} {t("roomtype.child")}
                        </label>
                        <Controller
                          control={control}
                          defaultValue={booking?.capacity?.child}
                          name="capacity.child"
                          render={(field) => (
                            <InputNumber
                              {...field}
                              placeholder={t("roomtype.child")}
                              size="large"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    {t("reservation.remark")}
                  </label>
                  <Controller
                    control={control}
                    name="remark"
                    defaultValue={booking?.remark}
                    placeholder={t("reservation.remark")}
                    render={(field) => <TextArea {...field} rows={3} />}
                  />
                </div>
                <br />
                <RoomSelector
                  roomTypes={roomTypes}
                  rooms={avaliableRoom}
                  selectedRooms={selectedRooms}
                  setSelectedRooms={setSelectedRooms}
                />

                <div className="text-center  gap-0 py-3 ">
                  <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                    {t("reservation.editBooking")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <Spinners />
        )}
      </div>{" "}
    </Layout>
  )
}
