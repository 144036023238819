/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import { CSVLink } from "react-csv"

import { DateTime } from "luxon"
import _ from "lodash"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"
import { selectRoomTypeColor, MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../../util"

export default function TaxInvoiceManagement() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const taxInvoice = useSelector((state) => state.taxInvoice)
  const history = useHistory()
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState()

  if (!isLoading) {
    return <SpinnerLoading />
  }

  useEffect(() => {
    dispatch(actions.getAllTaxInvoice({ page: 1, size: 10, query: {} }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((error) => {
        setIsShowAlert(true)
        setAlertMessage(error?.message)
      })

    return () => {}
  }, [])

  return (
    <Layout categoryTitle={t("reservation.title")} title="ใบกำกับภาษี" preview>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setIsShowAlert(false)}
        isError={isShowAlert}
      />
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>

      <div className="p-4 w-full"></div>
    </Layout>
  )
}
