import _ from "lodash"

import {
  FILE_ALL,
  FILE_GET,
  FILE_POST,
  FILE_PUT,
  FILE_DEL,
  FILE_ERROR,
  FILE_LOADING,
} from "../types"

import api from "../../../config/api"

export const fileCreate =
  ({ file }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FILE_LOADING })
      if (file) {
        // Upload File First
        const formData = new FormData()
        formData.append("file", file)

        const { data: uploadData } = await api.post(
          `${process.env.REACT_APP_API_URL}/upload/file`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        )
        console.log("Upload Data", uploadData)
        // Then Create Comment
        const { data } = await api.post(`${process.env.REACT_APP_API_URL}/file`, {
          file,
          url: uploadData?.url,
          file_name: _.trimStart(uploadData?.file_name, "files/"),
        })
        dispatch({ type: FILE_POST, payload: data })
      } else {
        throw new Error("User not attach file")
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: FILE_ERROR })
      throw new Error(error)
    }
  }

export const fileAll =
  ({
    name = "",
    size = "",
    page = 1,
    customer = "",
    project = "",
    product = "",
    system = "",
    fileType = "",
  }) =>
  async (dispatch) => {
    try {
      const { data, status } = await api.get(
        `${process.env.REACT_APP_API_URL}/file?name=${name}&size=${size}&page=${page}&customer=${customer}&project=${project}&product=${product}&system=${system}&fileType=${fileType}`,
      )
      if (status === 200) {
        dispatch({ type: FILE_ALL, payload: data })
      }
    } catch (error) {
      console.error(error)
      dispatch({ type: FILE_ERROR })
      throw new Error(error)
    }
  }

export const fileGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(`${process.env.REACT_APP_API_URL}/file/${id}`)
    if (status === 200) {
      dispatch({ type: FILE_GET, payload: data })
    }
  } catch (error) {
    console.error(error)
    dispatch({ type: FILE_ERROR })
    throw new Error(error)
  }
}

export const filePut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: FILE_LOADING })
    const { data } = await api.put(`${process.env.REACT_APP_API_URL}/file/${id}`, payload)
    dispatch({ type: FILE_PUT, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FILE_ERROR })
    throw new Error(error)
  }
}

export const fileDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: FILE_LOADING })
    const { data } = await api.delete(`${process.env.REACT_APP_API_URL}/file/${id}`)
    dispatch({ type: FILE_DEL, payload: data })
  } catch (error) {
    console.error(error)
    dispatch({ type: FILE_ERROR })
    throw new Error(error)
  }
}
