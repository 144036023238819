export const DataSource = {
  ROOM_TYPE: {
    status_code: "ROOM_TYPE",
    description: "หมวดหมู่ห้องพัก",
    descriptionEn: "Room Type",
  },
  MEETING_ROOM: {
    status_code: "MEETING_ROOM",
    description: "ห้องประชุม",
    descriptionEn: "Meeting Room",
  },
  PROMOTION: {
    status_code: "PROMOTION",
    description: "โปรโมชั่น",
    descriptionEn: "Promotion",
  },
  FACILITY: {
    status_code: "FACILITY",
    description: "สิ่งอำนวยความสะดวก",
    descriptionEn: "Facility",
  },
  GALLERY: {
    status_code: "GALLERY",
    description: "แกลอรี่",
    descriptionEn: "Gallery",
  },
  ARTICLE: {
    status_code: "ARTICLE",
    description: "บทความ",
    descriptionEn: "Article",
  },
  INSTAGRAM: {
    status_code: "INSTAGRAM",
    description: "ภาพจาก Instagram (ฟีเจอร์ในอนาคต)",
    descriptionEn: "Instagram Photo (Future feature)",
  },
  STATIC_CONTENT: {
    status_code: "STATIC_CONTENT",
    description: "กำหนดเอง",
    descriptionEn: "Create Your Own Content",
  },
}

export default DataSource
