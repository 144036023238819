/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { useHistory, useParams } from "react-router-dom"
import { Button, Radio, RadioGroup, Tag, Text } from "@chakra-ui/react"
import _ from "lodash"

import OnlineBookingLayout from "../../components/layout/OnlineBookingLayout"
import { WhitePanel, ImageUpload, DialogLoading, Alert } from "../../components"
import { api, useQuery } from "../../config"
import { THAI_BANK, PaymentMethod, paymentStatus, ChillpayPaymentName } from "../../config/constant"
import * as actions from "../../redux/actions"
import { currencyFormatter, bookingAnalysis } from "../../util"
import { OnlineBookingContext } from "../../contexts/OnlineBookingContext"

function BookingPayment() {
  const { t, i18n } = useTranslation()
  const query = useQuery()
  const dispatch = useDispatch()
  const {
    color,
    specifyBookingList,
    checkinDate,
    checkoutDate,
    setCheckinDate,
    setCheckedoutDate,
    bookingInfo,
    setBookingInfo,
  } = useContext(OnlineBookingContext)
  const history = useHistory()
  const params = useParams()
  const chillPay = useSelector((state) => state.chillPay)
  const system = useSelector((state) => state.system)
  const information = useSelector((state) => state.information)

  const [selectedRoomType, setSelectedRoomType] = useState(query.get("roomtype"))
  const [mainPaymentType, setMainPaymentType] = useState(null)
  const [minorPaymentType, setMinorPaymentType] = useState({})
  const [linkPayment, setLinkPayment] = useState()
  const [slip, setSlip] = useState([])
  const [paidPrice, setPaidPrice] = useState(bookingInfo?.price || 0)
  const [dialogLoadingOn, setDialogLoadingOn] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")

  useEffect(() => {
    dispatch(actions.resetChillPay())

    return () => {}
  }, [])

  console.log("bookingInfo", bookingInfo)

  useEffect(() => {
    let timeout
    if (chillPay?.ready) {
      console.log("res for get chill pay useEffect", chillPay)

      setLinkPayment(chillPay?.payload)
      if (_.startsWith(chillPay?.payload, "https://")) {
        setErrMessage(
          `กำลังรีไดเร็คไปยังหน้าการชำระเงิน กรุณารอสักครู่<br><br>หากระบบไม่ Redirect ภายใน 2-3 วินาที กรุณากดที่ลิงค์ด้านล่างนี้ <a href="${chillPay?.payload}">${chillPay?.payload}</a>`,
        )
        timeout = setTimeout(() => {
          window.location.href = chillPay?.payload
        }, 500)
      } else {
        setErrMessage(`${chillPay?.payload}`)
      }
      setIsError(true)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [chillPay])

  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_API_URL}/booking/${params.id}`)
      .then((res) => {
        if (res?.data?._id) {
          const booking = bookingAnalysis(res?.data)
          setBookingInfo(booking)
          setPaidPrice(booking?.price)
        }
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.respose?.data?.message || err?.message)
      })

    return () => {}
  }, [params.id])

  const handlePaymentButton = async (minorPaymentValue) => {
    if (minorPaymentType.value || minorPaymentValue?.value) {
      setDialogLoadingOn(true)
      dispatch(
        actions.getChillPay({
          MerchantCode: system?.setting?.onlineBooking?.paymentType?.chillpay?.merchantId,
          OrderNo: bookingInfo?.booking_no,
          CustomerId: bookingInfo?.customer?._id,
          Amount: parseInt(paidPrice * 100, 10),
          PhoneNumber: bookingInfo?.customer?.tel,
          Description: `Booking ${bookingInfo?.booking_no}`,
          ChannelCode: minorPaymentType.value || minorPaymentValue?.value,
          currency: 764,
          LangCode: "TH",
          RouteNo: 1,
          ApiKey: system?.setting?.onlineBooking?.paymentType?.chillpay?.apiKey,
          CustEmail: bookingInfo?.customer?.email,
          IPAddress: system?.setting?.onlineBooking?.paymentType?.chillpay?.ipAddress,
        }),
      ).then(() => {
        setDialogLoadingOn(false)
      })
    } else {
      setIsError(true)
      setErrMessage("กรุณาเลือกช่องทางการชำระเงิน")
    }

    // console.log("chill pay payload", chillPay)
    // window.location.href = chillPay?.payload
  }

  const handleSelectPaymentType = (tempMainPaymentType, subPaymentType) => {
    setMainPaymentType(tempMainPaymentType)
    setMinorPaymentType(subPaymentType)
    console.log("subPaymentType", subPaymentType)
    handlePaymentButton(subPaymentType)
  }

  const handleUploadSlip = async () => {
    if (!slip?.[0]?.data_url) {
      setIsError(true)
      setErrMessage("กรุณาอัพโหลดสลิปการโอนเงิน")
      return
    }

    setDialogLoadingOn(true)
    const payload = {
      payment: { payment_slip: { image: slip?.[0]?.data_url } },
      payment_type: PaymentMethod.ONLINE_SYSTEM.status_code,
      paid_amount: paidPrice,
      lock: {
        enable: false,
      },
      paymentStatus: paymentStatus.deposit.depositPay,
    }

    dispatch(actions.editOneBooking(bookingInfo?._id, payload))
      .then(() => {
        history.push(`/online-booking/success`)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.respose?.data?.message || err?.message)
      })
      .finally(() => {
        setDialogLoadingOn(false)
      })
  }

  const renderTransferBox = () => (
    <div>
      <div className="border m-2 p-4 rounded-md">
        <div>
          <div className="text-lg font-semibold my-2">{t("reservation.payViaTransfer")}</div>
          <div className="font-semibold my-1">{t("reservation.account.bankAccount")}</div>
          <div className="">{THAI_BANK?.[information?.transfer_info?.bank]?.descriptionEn}</div>
          <div className="">
            <b>{t("reservation.account.accountName")}</b> {information?.transfer_info?.accountName}
          </div>
          <div className="">
            <b>{t("reservation.account.accountNo")}</b> {information?.transfer_info?.accountNo}
          </div>
        </div>
        {information?.transfer_info?.promptpayNo && (
          <div className="mt-4">
            <div>{t("general.or")}</div>
            <div className="font-semibold my-1">{t("reservation.account.promptPay")}</div>
            <div className="">
              <b>{t("reservation.account.promptpayAccount")}</b>{" "}
              {information?.transfer_info?.promptpayName}
            </div>
            <div className="">
              <b>{t("reservation.account.promptpayNo")}</b>{" "}
              {information?.transfer_info?.promptpayNo}
            </div>
            <div className="my-2">
              {information?.payment_qr_code && (
                <img src={information?.payment_qr_code?.url} className="w-80" />
              )}
            </div>
          </div>
        )}
        <div className="mt-4">
          {" "}
          <div>{t("reservation.slipUpload")}</div>
          <ImageUpload images={slip} setImages={setSlip} maxNumber={1} />
        </div>
        <div className="my-2">
          <Button isFullWidth colorScheme={color} onClick={() => handleUploadSlip()}>
            {t("reservation.paymentConfirm")}
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <OnlineBookingLayout
      title={t("reservation.payment")}
      checkinDate={checkinDate}
      checkoutDate={checkoutDate}
      handleNext={() => {}}
      handlePrevious={() => {}}
      selectedRoomType={selectedRoomType}
      setSelectedRoomType={setSelectedRoomType}
      setCheckOutDate={setCheckedoutDate}
      setCheckedInDate={setCheckinDate}
      selectedRooms={specifyBookingList}
      selectedIndex={4}
      enableVerticleForm={false}
    >
      <WhitePanel>
        <div className="  pb-20">
          <div>
            <DialogLoading isLoading={dialogLoadingOn} title={t("reservation.nowPaymentSaving")} />
            <Alert
              isError={isError}
              errMessage={errMessage}
              handleOnClose={() => setIsError(false)}
              html
              title={"ผลของระบบชำระเงิน"}
            />
            <div className="text-2xl font-semibold my-2">
              {t("reservation.bookingCurrentlySave")}
            </div>
            <div className="mt-4 text-lg font-semibold">
              Booking Number {bookingInfo?.booking_no}
            </div>
            <div className="text-lg">
              {t("reservation.totalPrice")} {currencyFormatter.format(bookingInfo?.price)}{" "}
              {t("general.baht")}
            </div>
            <Text textColor={color} fontSize="md">
              {t("reservation.price.remainingAmount")} {currencyFormatter.format(paidPrice)}{" "}
              {t("general.baht")}
            </Text>
            <div>
              {t("reservation.pleaseCreatePaymentIn")}{" "}
              {dayjs(bookingInfo?.lock?.until).format("HH:mm")} {t("reservation.ofTheDay")}{" "}
              {dayjs(bookingInfo?.lock?.until).locale(i18n.language).format("DD MMM YYYY")}
            </div>{" "}
            <hr className="my-2" />
            <div className="mt-4">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                  <div>
                    {t("reservation.guestInfo")} : {bookingInfo?.customer?.firstname}{" "}
                    {bookingInfo?.customer?.lastname}
                  </div>
                  <div className="flex gap-2">
                    {_.map(bookingInfo?.list, (each, index) => (
                      <div className="rounded-md bg-gray-100 p-2" key={index}>
                        {each?.room?.name}{" "}
                      </div>
                    ))}
                    {_.map(bookingInfo?.no_room_booking, (each, index) => (
                      <div className="rounded-md bg-gray-100 p-2" key={index}>
                        {each?.roomtype?.translation?.[i18n?.language]?.name ||
                          each?.roomtype?.name}{" "}
                        x {each?.amount}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <div>
                    {t("reservation.checkin")}-{t("reservation.checkout")} :{" "}
                    {dayjs(bookingInfo?.start).locale(i18n.language).format("D MMM")} -{" "}
                    {dayjs(bookingInfo?.end).locale(i18n.language).format("D MMM YYYY")}
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-2" />
            <div className="mt-4">{t("reservation.paymentType")}</div>
            <RadioGroup className="my-2 ml-2">
              <Radio
                size="sm"
                defaultChecked
                onClick={() => {
                  setPaidPrice(bookingInfo?.price)
                }}
              >
                {t("reservation.price.payFull")}
              </Radio>
              {system?.setting?.onlineBooking?.deposit?.allowDeposit && (
                <Radio
                  size="sm"
                  onClick={() =>
                    setPaidPrice(
                      (bookingInfo?.price *
                        system?.setting?.onlineBooking?.deposit?.depositPercent) /
                        100,
                    )
                  }
                >
                  {t("reservation.deposit")}{" "}
                  {system?.setting?.onlineBooking?.deposit?.depositPercent} %
                </Radio>
              )}
            </RadioGroup>
            <div className="mt-4">{t("reservation.paymentMethod")}</div>
            <div className="grid grid-cols-3">
              {system?.setting?.onlineBooking?.paymentType?.transfer && (
                <div className="p-2">
                  <Button isFullWidth onClick={() => handleSelectPaymentType("transfer")}>
                    <div className="py-2">{t("reservation.bankingTransferAndPromptpay")}</div>
                  </Button>
                </div>
              )}
              {system?.setting?.onlineBooking?.paymentType?.chillpay?.enable &&
                _.map(
                  system?.setting?.onlineBooking?.paymentType?.chillpay?.chillpayPaymentMethod,
                  (each, index) => (
                    <div className="p-2">
                      <Button
                        isFullWidth
                        key={index}
                        onClick={() => {
                          handleSelectPaymentType("chillpay", ChillpayPaymentName[each])
                        }}
                      >
                        <div className="py-2">{ChillpayPaymentName[each]?.name}</div>
                      </Button>
                    </div>
                  ),
                )}
            </div>
            {mainPaymentType === "transfer" && renderTransferBox()}
            {/* <div className="my-4">
              <Button isFullWidth colorScheme={color} onClick={() => handlePaymentButton()}>
                ชำระเงิน
              </Button>
            </div> */}
          </div>
        </div>
      </WhitePanel>
    </OnlineBookingLayout>
  )
}

export default BookingPayment
