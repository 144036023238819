import {
  FRONTPAGE_COMPONENT_ALL,
  FRONTPAGE_COMPONENT_CREATE,
  FRONTPAGE_COMPONENT_DELETE,
  FRONTPAGE_COMPONENT_GET,
  FRONTPAGE_COMPONENT_UPDATE,
  FRONTPAGE_COMPONENT_ERROR,
} from "../../actions/types"

const initialState = {
  meetingRoomOrder: null,
  isLoading: false,
}
export default function FrontpageComponentReducer(state = initialState, action) {
  switch (action.type) {
    case FRONTPAGE_COMPONENT_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case FRONTPAGE_COMPONENT_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case FRONTPAGE_COMPONENT_CREATE:
      return { isLoading: false }
    case FRONTPAGE_COMPONENT_UPDATE:
      return { isLoading: false }
    case FRONTPAGE_COMPONENT_DELETE:
      return { isLoading: false }
    case FRONTPAGE_COMPONENT_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
