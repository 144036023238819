import React from "react"

function ChillpayDataPanel({ chillpaySpecialAttribute }) {
  return (
    <div className="my-2">
      <ul className="list-disc list-inside text-sm">
        <li>
          <b>Transaction ID</b>: {chillpaySpecialAttribute?.TransactionId}
        </li>
        <li>
          <b>วิธีการชำระเงิน</b>: {chillpaySpecialAttribute?.BankCode}
        </li>
        <li>
          <b>จำนวนเงิน</b>: {parseInt(chillpaySpecialAttribute?.Amount, 10) / 100} บาท
        </li>
        <li>
          <b>Payment Date</b>: {chillpaySpecialAttribute?.CurrentDate} เวลา{" "}
          {chillpaySpecialAttribute?.CurrentTime?.slice(0, 2)}:
          {chillpaySpecialAttribute?.CurrentTime?.slice(2, 4)}:
          {chillpaySpecialAttribute?.CurrentTime?.slice(4, 6)}
        </li>
      </ul>
    </div>
  )
}

export default ChillpayDataPanel
