import {
  TABLE_ALL,
  TABLE_CREATE,
  TABLE_DELETE,
  TABLE_GET,
  TABLE_UPDATE,
  TABLE_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const tableActions = new GeneralReduxActionClass({
  allConst: TABLE_ALL,
  createConst: TABLE_CREATE,
  deleteConst: TABLE_DELETE,
  errorConst: TABLE_ERROR,
  getConst: TABLE_GET,
  putConst: TABLE_UPDATE,
  backendPrefix: "table",
  description: "รายการโต๊ะ",
})

export const getAllTable = (query) => tableActions.getAll(query)
export const getOneTable = (id) => tableActions.getOne(id)
export const createOneTable = (payload) => tableActions.createOne(payload)
export const editOneTable = (id, payload) => tableActions.editOne(id, payload)
export const deleteOneTable = (id) => tableActions.deleteOne(id)
