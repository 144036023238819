import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory } from "react-router-dom"

import {
  GeneralMainLayout as Layout,
  SpinnerLoading,
  PaymentTransactionTable,
  WhitePanel,
} from "../../../components"
import * as actions from "../../../redux/actions"

export default function InfoBookingPayment() {
  const { t } = useTranslation()
  const transaction = useSelector((state) => state.transaction)
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(actions.getAllTransaction({ page, size, query: { booking: params.id } })).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout title={"รายละเอียดการชำระเงิน"} categoryTitle={t("reservation.title")}>
      <div className="flex flex-wrap px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4">
        <WhitePanel>
          <PaymentTransactionTable
            page={page}
            setPage={setPage}
            setSize={setSize}
            size={size}
            transaction={transaction}
          />
        </WhitePanel>
      </div>
    </Layout>
  )
}
