import TravelItinerary from "./TravelItinerary"
import KeyDeposit from "./KeyDeposit"
import Recipt from "./Recipt"
import TaxInvoice from "./TaxInvoice"
import RegustrationCard from "./RegistrationCard"
import PaymentTransactionReport from "./PaymentTransactionReport"
import OrderNoVatRecipt from "./OrderNoVatRecipt"
import OrderNoVatSlip from "./OrderNoVatSlip"
import ServiceCoupon from "./ServiceCoupon"
import RoomReport from "./RoomReport"
import BookingReport from "./BookingReport"
import CheckoutReport from "./CheckoutReport"
import CheckInReport from "./CheckInReport"
import MonthyForecastReport from "./MonthyForecastReport"
import InhouseGuestReport from "./InhouseGuestReport"
import HouseKeeperReport from "./HouseKeeperReport"
import Folio from "./Folio"
import Invoice from "./Invoice"
import FoodOrderMiniSlip from "./FoodOrderMiniSlip"
import FBOrderSlip from "./FBOrderSlip"
import FBOrderBillSlip from "./FBOrderBillSlip"
import FBReport from "./FBReport"
import MenuReport from "./MenuReport"

export {
  TravelItinerary,
  KeyDeposit,
  Recipt,
  TaxInvoice,
  RegustrationCard,
  PaymentTransactionReport,
  OrderNoVatRecipt,
  OrderNoVatSlip,
  ServiceCoupon,
  RoomReport,
  BookingReport,
  CheckoutReport,
  CheckInReport,
  MonthyForecastReport,
  InhouseGuestReport,
  HouseKeeperReport,
  Folio,
  Invoice,
  FoodOrderMiniSlip,
  FBOrderSlip,
  FBOrderBillSlip,
  FBReport,
  MenuReport,
}

export default {
  TravelItinerary,
  KeyDeposit,
  Recipt,
  TaxInvoice,
  RegustrationCard,
  PaymentTransactionReport,
  OrderNoVatRecipt,
  OrderNoVatSlip,
  ServiceCoupon,
  RoomReport,
  BookingReport,
  CheckoutReport,
  CheckInReport,
  MonthyForecastReport,
  InhouseGuestReport,
  HouseKeeperReport,
  Folio,
  Invoice,
  FoodOrderMiniSlip,
  FBOrderSlip,
  FBOrderBillSlip,
  FBReport,
  MenuReport,
}
