/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"
import { BookingStatus } from "../../constant"

dayjs.extend(buddhistEra)

export default function InhouseGuestReport(
  transactions,
  date,
  startDate,
  endDate,
  isPointDate,
  information,
  systemInfo,
  roomtypes,
) {
  const findMainRecord = (record) => {
    const founedList = _.find(record?.bookings?.list, (each) => each?.room === record?._id)
    return (
      (founedList?.price || 0) * (dayAmount(record?.bookings?.start, record?.bookings?.end) || 0)
    )
  }

  const findExtraRecord = (eachList) =>
    _.sum(
      _.map(
        _.filter(eachList?.bookings?.services, (each) => each?.room === eachList?._id),
        (each) => (each?.price || 0) * (each?.amount || 0),
      ),
    )

  const roomByRoomType = _.groupBy(transactions, "type._id")

  const geneateAmountOnType = (typeId) => {
    const selectedRoomList = roomByRoomType?.[typeId]
    return _.size(selectedRoomList)
  }

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายงานแขกเข้าพัก",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายงานแขกเข้าพัก /Inhouse Guest",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      isPointDate
        ? {
            text: [
              { text: "วันที่", bold: true },
              {
                text: `  ${dayjs(date).format("D MMMM BBBB")}  (วันที่ ${dayjs(date).format(
                  "D MMMM BBBB",
                )} เวลา 12.01 - วันที่ ${dayjs(date)
                  .add(1, "day")
                  .format("D MMMM BBBB")} เวลา 11.59 )`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          }
        : {
            text: [
              { text: "จากวันที่", bold: true },
              {
                text: `  ${dayjs(startDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
              { text: "ถึงวันที่", bold: true },
              {
                text: `  ${dayjs(endDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, 40, "*", 60, 40, 40, 40, 40, 70, 50, 30, 80, 40],
          body: [
            [
              { text: "ลำดับที่", bold: true },
              { text: "เลขที่การจอง", bold: true },
              { text: "ชื่อ", bold: true },
              { text: "ห้องพัก", bold: true },
              { text: "ราคา", bold: true },
              { text: "สินค้า/บริการ", bold: true },
              { text: "รวม", bold: true },
              { text: "Balance", bold: true },
              { text: "วันที่เข้าพัก", bold: true },
              { text: "วันที่ออก", bold: true },
              { text: "จำนวน\nคืน", bold: true },
              { text: "สินค้าและบริการ", bold: true },
              { text: "พนักงาน", bold: true },
            ],
            ..._.map(transactions, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${eachList?.bookings?.booking_no || ""}`,
                margin: [0, 2],
              },
              {
                text: `${eachList?.bookings?.customer?.firstname || "-"} ${
                  eachList?.bookings?.customer?.lastname || ""
                }`,
              },
              {
                text: eachList?.name || "",
              },
              {
                text: findMainRecord(eachList),
              },
              {
                text: findExtraRecord(eachList),
              },
              {
                text: findMainRecord(eachList) + findExtraRecord(eachList),
              },
              {
                text: eachList?.bookings?.price,
              },
              {
                text: eachList?.bookings?.checkin?.timestamp
                  ? dayjs(eachList?.bookings?.checkin?.timestamp).format("D MMM BB HH:mm")
                  : "-",
              },

              {
                text:
                  eachList?.bookings?.status === BookingStatus.checkedOut ||
                  eachList?.bookings?.status === BookingStatus.success
                    ? "เสร็จสิ้น"
                    : "ยังไม่คืนห้อง",
              },
              {
                text: dayAmount(eachList?.bookings?.start, eachList?.bookings?.end),
              },
              {
                text: _.map(
                  _.filter(eachList?.bookings?.services, (each) => each?.room === eachList?._id),
                  (each) => each?.service?.name,
                ),
              },
              {
                text: eachList?.bookings?.user?.employee?.firstname || "",
                fontSize: 9,
              },
            ]),
            [
              { text: "รวม", colSpan: 4, bold: true },
              {},
              {},
              {},

              {
                text: currencyFormatter.format(
                  _.sum(_.map(transactions, (eachList) => findMainRecord(eachList))),
                ),
              },
              {
                text: currencyFormatter.format(
                  _.sum(_.map(transactions, (eachList) => findExtraRecord(eachList))),
                ),
              },
              {
                text: currencyFormatter.format(
                  _.sum(
                    _.map(
                      transactions,
                      (eachList) => findMainRecord(eachList) + findExtraRecord(eachList),
                    ),
                  ),
                ),
              },

              { text: "", colspan: 6 },
              {},
              {},
              {},
              {},
              {},
            ],
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
      },
      {
        margin: [0, 20, 0, 20],
        table: {
          headerRows: 1,
          widths: [100, 50],
          body: [
            [
              { text: "รูปแบบห้องพัก", bold: true },
              { text: "จำนวนห้อง", bold: true },
            ],
            ..._.map(roomtypes, (each) => [
              { text: `${each?.translation?.th?.name || each?.name || ""}` },
              { text: geneateAmountOnType(each?._id) },
            ]),
          ],
        },
      },
      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
