import React, { useContext, useState } from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { DatePicker } from "antd"
import { Button } from "@chakra-ui/react"
import { OnlineBookingContext } from "../../contexts/OnlineBookingContext"
import { currencyFormatter } from "../../util"

const OnlineBookingBottomPanel = ({
  checkinDate,
  checkoutDate,
  selectedRooms,
  handlePrevious,
  handleNext,
  selectedIndex,
}) => {
  const { t, i18n } = useTranslation()
  const { RangePicker } = DatePicker
  const [isOpenEditDate, setIsOpenEditDate] = useState(false)
  const {
    unSpecifyBookingList,
    specifyBookingList,
    selectedServices,
    setCheckinDate,
    setCheckedoutDate,
    setUnSpecifyBookingList,
  } = useContext(OnlineBookingContext)
  const sumUnSpecifyBooking = _.sum(_.map(unSpecifyBookingList, (each) => each?.amount))

  const specifyRoomPrice = 0 // TODO: Not Implement
  const unspecifyRoomPriceOneNight = _.sum(
    _.map(unSpecifyBookingList, (each) => each?.amount * each?.subtype?.price || 0),
  )

  const servicePrice = _.sum(
    _.map(selectedServices, (each) => each?.amount * each?.service?.price || 0),
  )

  const unspecifyRoomPrice =
    dayjs(dayjs(checkoutDate).startOf("day")).diff(dayjs(checkinDate).startOf("day"), "day") *
    unspecifyRoomPriceOneNight

  return (
    <div className=" w-full fixed left-0 bottom-0  bg-white shadow-2xl rounded-md opacity-90">
      <div className="flex flex-wrap justify-center py-2">
        <div className="text-center">
          <div
            className="flex justify-center gap-2 py-2 my-2 hover:shadow-md"
            onClick={() => setIsOpenEditDate(!isOpenEditDate)}
          >
            <div>
              <div> {t("reservation.checkin")} </div>
              <div className="text-xl font-semibold">
                {dayjs(checkinDate).locale(i18n.language).format("D MMM ")}
              </div>
            </div>
            <div className="text-lg self-center text-purple-800">
              <i className="fas fa-arrow-circle-right"></i>
            </div>
            <div>
              <div>{t("reservation.checkout")} </div>
              <div className="text-xl font-semibold">
                {dayjs(checkoutDate).locale(i18n.language).format("D MMM ")}{" "}
              </div>
            </div>
          </div>
          {t("general.total")}{" "}
          <span className="font-semibold text-2xl mx-2">
            {dayjs(checkoutDate)
              .hour(12)
              .minute(1)
              .diff(dayjs(checkinDate).hour(12).minute(0), "d")}{" "}
          </span>{" "}
          {t("roomtype.night")} - {t("roomtype.room")}
          <span className="font-semibold text-2xl mx-2">
            {_.size(selectedRooms) + sumUnSpecifyBooking || 0}
          </span>{" "}
          {}
          {t("dashboard.room")}
        </div>
        <div
          className={`w-full px-4 text-center  ${
            isOpenEditDate ? "" : "hidden"
          } transition-opacity	ease-in`}
        >
          <div className="px-1 w-full my-2">
            <h5 className="font-semibold text-sm">{t("reservation.checkinDate")}</h5>
            <DatePicker
              className="my-2"
              onChange={(date) => setCheckinDate(dayjs(date).toDate())}
              value={dayjs(checkinDate)}
              format={"D/MM/YYYY"}
              locale={i18n.language}
              size="large"
            />
          </div>
          <div className="px-1 w-full my-2">
            <h5 className="font-semibold text-sm">{t("reservation.checkoutDate")}</h5>
            <DatePicker
              onChange={(date) => {
                setCheckedoutDate(dayjs(date).toDate())
                setIsOpenEditDate(false)
              }}
              value={dayjs(checkoutDate)}
              className="my-2"
              disabledDate={(current) =>
                // Can not select days before today and today
                current && current < dayjs(checkinDate).endOf("day")
              }
              format={"D/MM/YYYY"}
              locale={i18n.language}
              size="large"
            />
          </div>
        </div>
        <div className="lg:hidden w-full  my-2 ">
          <div className="mr-2 text-center"> {t("reservation.totalPrice")} </div>
          <div className="whitespace-nowrap text-xl font-semibold text-center">
            {currencyFormatter.format(specifyRoomPrice + unspecifyRoomPrice + servicePrice || 0)}{" "}
            THB{" "}
            <Button onClick={() => setUnSpecifyBookingList([])} className="text-red-600">
              x
            </Button>
          </div>
        </div>
        <div className="flex justify-center gap-2 p-4  ">
          <Button
            size="lg"
            rightIcon={<i className="fas fa-arrow-circle-left"></i>}
            onClick={handlePrevious}
          >
            {t("general.backward")}
          </Button>

          {selectedIndex < 3 && (
            <Button
              size="lg"
              rightIcon={<i className="fas fa-arrow-circle-right"></i>}
              onClick={handleNext}
              colorScheme="green"
            >
              {t("general.forward")}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default OnlineBookingBottomPanel
