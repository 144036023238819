import React from "react"
import { useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { CustomerForm } from "../../components/Forms/CRM"
import Layout from "../../components/layout/GeneralLayout"

import * as actions from "../../redux/actions"

export default function Gallery() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, watch, setValue } = useForm()

  // id_card_image: {
  //   imageType: "idCard",
  //   image: idCardImage?.[0]?.data_url,
  //   alt: "",
  // },

  const onCreateCustomer = (data) => {
    dispatch(actions.createOneCustomer(data)).then(() => {
      dispatch(actions.getAllRooms()).then(() => {})
      history.push("/crm")
    })
  }

  return (
    <Layout categoryTitle="จัดการลูกค้า" title="เพิ่มลูกค้า">
      <div className="flex justify-between px-4 gap-2">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <div className="flex flex-wrap justify-between pb-8">
          <div className="w-full  ">
            <form onSubmit={handleSubmit(onCreateCustomer)}>
              <CustomerForm register={register} watch={watch} setValue={setValue} />
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  เพิ่ม
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
