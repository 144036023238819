import _ from "lodash"
import { LOG_ALL, LOG_CREATE, LOG_GET, LOG_UPDATE, LOG_DELETE } from "../../actions/types"
import { bookingAnalysis, arrayBookingAnalysis } from "../../../util"

const initialState = {
  logs: null,
  isLoading: false,
}
export default function LogReducer(state = initialState, action) {
  switch (action.type) {
    case LOG_ALL:
      return {
        arr: arrayBookingAnalysis(action?.payload?.rows),
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case LOG_GET:
      return {
        ...bookingAnalysis(action.payload),
        isLoading: true,
      }
    case LOG_CREATE:
      return { isLoading: false }
    case LOG_UPDATE:
      return { isLoading: false }
    case LOG_DELETE:
      return { isLoading: false }
    default:
      return state
  }
}
