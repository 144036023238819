import React, { useState } from "react"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  Button,
  ModalFooter,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import ImageUpload from "../../ImageUpload/ImageUpload"
import { DialogLoading } from "../../Loading"

export default function ModalUploadSlip({ isOpen, handleOnClose, bookingInfo, handleCreateImage }) {
  const [image, setImage] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const handleEditServiceOnBooking = async () => {
    try {
      const slectedImage = image?.[0]
      setIsLoading(true)
      console.log("Selected Image", slectedImage, bookingInfo)
      await handleCreateImage({
        image: slectedImage?.data_url,
        booking: bookingInfo?.id,
      })

      handleOnClose()
      setIsLoading(false)
    } catch (err) {
      alert(`${t("reservation.errorSlipUpload")} ${err?.message}`)
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="xl">
      <form>
        <ModalOverlay />
        <DialogLoading isLoading={isLoading} title={t("general.imageLoading")} />
        <ModalContent>
          <ModalHeader className="font-sans" fontSize="lg">
            {t("reservation.slipUpload")}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody className="font-sans mb-4">
            <div className="flex flex-wrap justify-center">
              <div className="justify-center">
                <ImageUpload images={image} setImages={setImage} preview_size="250" maxNumber={1} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-end mt-2 gap-2 font-sans">
              <Button
                size="sm"
                colorScheme="green"
                type="button"
                onClick={handleEditServiceOnBooking}
              >
                {t("general.save")}
              </Button>
              <Button size="sm" type="button" onClick={handleOnClose}>
                {t("general.cancle")}
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>{" "}
      </form>
    </Modal>
  )
}
