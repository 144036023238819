import {
  ARTICLE_CATEGORY_ALL,
  ARTICLE_CATEGORY_CREATE,
  ARTICLE_CATEGORY_DELETE,
  ARTICLE_CATEGORY_GET,
  ARTICLE_CATEGORY_UPDATE,
  ARTICLE_CATEGORY_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const articleCategoryAction = new GeneralReduxActionClass({
  allConst: ARTICLE_CATEGORY_ALL,
  createConst: ARTICLE_CATEGORY_CREATE,
  deleteConst: ARTICLE_CATEGORY_DELETE,
  errorConst: ARTICLE_CATEGORY_ERROR,
  getConst: ARTICLE_CATEGORY_GET,
  putConst: ARTICLE_CATEGORY_UPDATE,
  backendPrefix: "article-category",
  description: "หมวดหมู่บทความ",
})

export const getAllArticleCategory = (query) => articleCategoryAction.getAll(query)
export const getOneArticleCategory = (id) => articleCategoryAction.getOne(id)
export const createOneArticleCategory = (payload) => articleCategoryAction.createOne(payload)
export const editOneArticleCategory = (id, payload) => articleCategoryAction.editOne(id, payload)
export const deleteOneArticleCategory = (id) => articleCategoryAction.deleteOne(id)
