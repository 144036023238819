import React from "react"
import _ from "lodash"
import { Checkbox } from "antd"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"

import { HotelFacility } from "../../config/constant/HotelFacility"

const FacilitySelectorForm = ({ control, roomType }) => {
  const { i18n } = useTranslation()
  return (
    <div className="grid grid-cols-3">
      {_.map(HotelFacility, (facility, index) => (
        <Controller
          control={control}
          name={`facility.${facility.ref}`}
          defaultValue={roomType?.facility?.[facility.ref]}
          render={(field) => (
            <Checkbox
              key={index}
              {...field}
              defaultChecked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            >
              {i18n.language === "en" ? facility.nameEn : facility.nameTH}
            </Checkbox>
          )}
        />
      ))}
    </div>
  )
}

export default React.forwardRef(FacilitySelectorForm)
