export const ComponentWidthSlideShowMobile = {
    // ONE_THIRD: {
    //   status_code: "ONE_THIRD",
    //   description: "1 ใน 3",
    //   descriptionEn: "1/3 Of Screen",
    // },
    // TWO_THIRD: {
    //   status_code: "TWO_THIRD",
    //   description: "2 ใน 3",
    //   descriptionEn: "2/3 Of Screen",
    // },
    ONE_HALF_LEFT: {
      status_code: "ONE_HALF_LEFT",
      description: "ครึ่งหน้าจอทางซ้าย",
      descriptionEn: "Half Screen Left",
    },
    ONE_HALF_RIGHT: {
      status_code: "ONE_HALF_RIGHT",
      description: "ครึ่งหน้าจอทางขวา",
      descriptionEn: "Half Screen Right", 
    },
    CONTAINER: {
      status_code: "CONTAINER",
      description: "อยู่ในกล่องกลางหน้าจอ",
      descriptionEn: "In Container",
    },
    FULL_SCREEN: {
      status_code: "FULL_SCREEN",
      description: "เต็มหน้าจอ",
      descriptionEn: "Full Width Screen",
    },
  }
  
  export default ComponentWidthSlideShowMobile
  