import { FormLabel, Input, Select, FormControl, Checkbox, RadioGroup, Radio } from "@chakra-ui/react"
import _ from "lodash"
import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import { SketchPicker } from "react-color"
import { useTranslation } from "react-i18next"
import { motion } from 'framer-motion';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

import { Visibility, VisibilityOff } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RichTextEditor } from "../../../common/RichTextEditor"
import { FrontPageMenuLink, ButtonType, VideoPosition, TypedisplayVideo, VideoTextAnimationType, ComponentHeightVideoComponent, ComponentWidthVideoComponent } from "../../../../config/constant"


import SliderType from "../../../common/Slider/SliderType"
import SliderTypeMobile from "../../../common/Slider/SliderTypeMobile"
import Slider from "../../../common/Slider/Slider"
import SliderPddingVideoText from "../../../common/Slider/SliderPddingVideoText"
import SliderVideoTextArea from "../../../common/Slider/SliderVideoTextArea"
import SliderVideoMobileTextArea from "../../../common/Slider/SliderVideoMobileTextArea"
import OpacitySlider from "../../../common/Slider/OpacitySlider"


function Video({ register, watch, defaultValue, control, file, gallery, activeTab, setValue }) {

    const { i18n } = useTranslation()


    const handleChange = () => {
        setValue('desktop.video.show_video_info', !watch('desktop.video.show_video_info'))
    };
    const handleOnChange = () => {
        setValue('desktop.video.show_video_button', !watch('desktop.video.show_video_button'))
    };
    const handleMobileChange = () => {
        setValue('mobile.video.show_video_info', !watch('mobile.video.show_video_info'))
    };
    const handleMobileOnChange = () => {
        setValue('mobile.video.show_video_button', !watch('mobile.video.show_video_button'))
    };

    const [showContent, setShowContent] = useState(true);
    const handleButtonClick = () => {
        setShowContent(!showContent);
    };
    const [showMobileContent, setShowMobileContent] = useState(true);
    const handleButtonMobileClick = () => {
        setShowMobileContent(!showMobileContent);
    };

    // const [activeInput, setActiveInput] = useState('TH'); // สมมติว่า activeInput เป็น state ที่เก็บค่า 'TH' หรือ 'EN'
    const [activeMobileInput, setActiveMobileInput] = useState('th'); // สมมติว่า activeInput เป็น state ที่เก็บค่า 'TH' หรือ 'EN'

    const [showHeading, setShowHeading] = useState(true);
    const [showEditTextColor, setShowEditTextColor] = useState(true);

    const [showDiv1, setShowDiv1] = useState(true);
    const handleButtonDivClick = () => {
        setShowDiv1(!showDiv1);
    };
    const [showDiv2, setShowDiv2] = useState(true);
    const handleButtonDiv2Click = () => {
        setShowDiv2(!showDiv2);
    };

    const [showDivInSide, setShowDivInSide] = useState(true);
    const handleButtonDivInSideClick = () => {
        setShowDivInSide(!showDivInSide);
    };

    const [showDivVideo, setShowDivVideo] = useState(null);

    const [activeInput, setActiveInput] = useState('TH'); // สมมติว่า activeInput เป็น state ที่เก็บค่า 'TH' หรือ 'EN'
    const [th, setTh] = useState(defaultValue?.desktop?.video?.translation?.th?.slide_video_content_header || ""); // สมมติว่า th เป็น state ที่เก็บข้อความภาษาไทย
    const [en, setEn] = useState(defaultValue?.desktop?.video?.translation?.en?.slide_video_content_header || ''); // สมมติว่า en เป็น state ที่เก็บข้อความภาษาอังกฤษ

    const [activeInput2, setActiveInput2] = useState('TH2'); // สมมติว่า activeInput เป็น state ที่เก็บค่า 'TH' หรือ 'EN'
    const [th2, setTh2] = useState(defaultValue?.desktop?.video?.translation?.th?.slide_video_content_header2 || ""); // สมมติว่า th เป็น state ที่เก็บข้อความภาษาไทย
    const [en2, setEn2] = useState(defaultValue?.desktop?.video?.translation?.en?.slide_video_content_header2 || ''); // สมมติว่า en เป็น state ที่เก็บข้อความภาษาอังกฤษ

    const [activeInput3, setActiveInput3] = useState('TH3'); // สมมติว่า activeInput เป็น state ที่เก็บค่า 'TH' หรือ 'EN'
    const [th3, setTh3] = useState(defaultValue?.desktop?.video?.translation?.th?.slide_video_content_header3 || ""); // สมมติว่า th เป็น state ที่เก็บข้อความภาษาไทย
    const [en3, setEn3] = useState(defaultValue?.desktop?.video?.translation?.en?.slide_video_content_header3 || ''); // สมมติว่า en เป็น state ที่เก็บข้อความภาษาอังกฤษ

    const [SlideShowText, setSlideShowText] = useState(true);

    return (
        <div>
            {activeTab === 'Desktop' && (
                <div className="pb-8 pt-8 w-1/2 flex">
                    <div className="w-full  px-2">
                        <label className="text-sm font-semibold text-gray-700">ความกว้าง</label>
                        <select
                            ref={register}
                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="ความกว้าง"
                            defaultValue={defaultValue?.desktop?.width}
                            name="desktop.width"
                            size="sm"
                        >
                            {_.map(ComponentWidthVideoComponent, (each, index) => (
                                <option
                                    key={index}
                                    value={each.status_code}
                                    className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                                >
                                    {i18n?.language === "th" ? each.description : each.descriptionEn}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="w-full  px-2 py-1">
                        <label className="text-sm font-semibold text-gray-700">ความสูง</label>
                        <select
                            ref={register}
                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="ความสูง"
                            defaultValue={defaultValue?.desktop?.height}
                            name="desktop.height"
                            size="sm"
                        >
                            {_.map(ComponentHeightVideoComponent, (each, index) => (
                                <option
                                    key={index}
                                    value={each.status_code}
                                    className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                                >
                                    {i18n?.language === "th" ? each.description : each.descriptionEn}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            {activeTab === 'Mobile' && (
                <div className="pb-8 pt-8 w-1/2 flex">
                    <div className="w-full  px-2">
                        <label className="text-sm font-semibold text-gray-700">ความกว้าง</label>
                        <select
                            ref={register}
                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="ความกว้าง"
                            defaultValue={defaultValue?.mobile?.width}
                            name="mobile.width"
                            size="sm"
                        >
                            {_.map(ComponentWidthVideoComponent, (each, index) => (
                                <option
                                    key={index}
                                    value={each.status_code}
                                    className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                                >
                                    {i18n?.language === "th" ? each.description : each.descriptionEn}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="w-full  px-2 py-1">
                        <label className="text-sm font-semibold text-gray-700">ความสูง</label>
                        <select
                            ref={register}
                            className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="ความสูง"
                            defaultValue={defaultValue?.mobile?.height}
                            name="mobile.height"
                            size="sm"
                        >
                            {_.map(ComponentHeightVideoComponent, (each, index) => (
                                <option
                                    key={index}
                                    value={each.status_code}
                                    className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                                >
                                    {i18n?.language === "th" ? each.description : each.descriptionEn}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            {activeTab === 'Desktop' && (
                <div>
                    <div className="text-xl font-bold mb-4 flex justify-center">
                        จัดการส่วนประกอบภายในวิดีโอ
                    </div>

                    <div className="w-96 flex justify-center border-black bg-gray-200 border-2 p-2">
                        <FormLabel fontSize="sm" className="pt-2 pl-2">รูปแบบการแสดง</FormLabel>
                        <Select
                            size="sm"
                            ref={register}
                            onChange={(e) => setShowDivVideo(e?.target?.value)}
                            defaultValue={defaultValue?.desktop?.video?.type_display_video}
                            name="desktop.video.type_display_video"
                            placeholder="เลือกไฟล์"
                        >
                            {_.map(TypedisplayVideo, (each, index) => (
                                <option key={index} value={each?.status_code} >
                                    {i18n?.language === "th" ? each.description : each.descriptionEn}
                                </option>
                            ))}
                        </Select>
                    </div>

                    {watch('desktop.video.type_display_video') === TypedisplayVideo.SLIDESHOW_PICTURE_VIDEO.status_code && <div className="flex flex-wrap">
                        <div className="w-86 mr-60 lg:mr-10 lg:w-full   lg:pr-10 lg:pl-10 lg:pb-40   mx-2 py-4">
                            <div className="my-2 ">
                                <div className=" bg-gradient-to-r from-blue-200 to-purple-200 lg:border-2 border-indigo-300 pt-10 lg:pr-10 lg:pl-10 pb-2 ">
                                    <div className="lg:border-2 lg:border-indigo-600 lg:p-20 mb-5 ">
                                        <motion.button
                                            className={`px-3 py-1 mb-2 rounded-md bg-green-500 text-white text-sm focus:outline-none hover:bg-blue-600`}
                                            type="button"
                                            onClick={() => setSlideShowText(!SlideShowText)}
                                        >
                                            {SlideShowText ? (
                                                <Visibility fontSize="small" />
                                            ) : (
                                                <VisibilityOff fontSize="small" />
                                            )}
                                            <span className="ml-1">จัดการส่วนข้อความเนื้อหา</span>
                                        </motion.button>
                                        {SlideShowText && (
                                            <div>
                                                <div className="w-full  px-2 py-1 pt-5 pb-3  lg:pr-24">
                                                    <div className="justify-center flex pb-4">
                                                        <div>
                                                            <div className="flex gap-20">
                                                                <div className="">
                                                                    <h1 className="flex justify-center pb-4 text-2xl">หัวข้อ </h1>
                                                                    <div className="flex justify-center shadow-4xl">
                                                                        <button
                                                                            className={`px-4 py-2 text-black ${activeInput === 'TH' ? 'bg-green-500 text-white' : 'bg-gray-300'
                                                                                } hover:bg-blue-600 transition-colors shadow-lg`}
                                                                            type="button"
                                                                            onClick={() => setActiveInput('TH')}
                                                                        >
                                                                            ข้อความ TH
                                                                        </button>
                                                                        <button
                                                                            className={`px-4 py-2 text-black ${activeInput === 'EN' ? 'bg-red-500 text-white' : 'bg-gray-300'
                                                                                } hover:bg-red-600 transition-colors shadow-lg`}
                                                                            type="button"
                                                                            onClick={() => setActiveInput('EN')}
                                                                        >
                                                                            ข้อความ EN
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mt-4">
                                                                <textarea
                                                                    className={`${activeInput === 'EN' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                    placeholder="หัวข้อภาษาไทย"
                                                                    name="desktop.video.translation.en.slide_video_content_header"
                                                                    value={th}
                                                                    onChange={(e) => setTh(e.target.value)}
                                                                    ref={register}
                                                                    rows={1}
                                                                />
                                                                <textarea
                                                                    className={`${activeInput === 'TH' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                    placeholder="หัวข้อภาษาอังกฤษ"
                                                                    name="desktop.video.translation.th.slide_video_content_header"
                                                                    value={en}
                                                                    onChange={(e) => setEn(e.target.value)}
                                                                    ref={register}
                                                                    rows={1}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormLabel fontSize="sm">ส่วนเนื้อหา 1</FormLabel>
                                                    <label
                                                        className="block  text-gray-700 text-sm font-semibold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ภาษาไทย
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        name="desktop.video.translation.th.slide_video_content"
                                                        defaultValue={defaultValue?.desktop?.video?.translation?.th?.slide_video_content}
                                                        render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                                                    />
                                                    <label
                                                        clas sName="pt-2 block  text-gray-700 text-sm font-semibold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ภาษาอังกฤษ
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        name="desktop.video.translation.en.slide_video_content"
                                                        defaultValue={defaultValue?.desktop?.video?.translation?.en?.slide_video_content}
                                                        render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                                                    />
                                                </div>
                                                <div className="w-full  px-2 py-1 pt-5 pb-3  lg:pr-24">
                                                    <div className="justify-center flex pb-4">
                                                        <div>
                                                            <h1 className="flex justify-center pb-4 text-2xl">หัวข้อ2 </h1>
                                                            <div className="flex justify-center shadow-4xl">
                                                                <button
                                                                    className={`px-4 py-2 text-black ${activeInput2 === 'TH2' ? 'bg-green-500 text-white' : 'bg-gray-300'
                                                                        } hover:bg-blue-600 transition-colors shadow-lg`}
                                                                    type="button"
                                                                    onClick={() => setActiveInput2('TH2')}
                                                                >
                                                                    ข้อความ TH
                                                                </button>
                                                                <button
                                                                    className={`px-4 py-2 text-black ${activeInput2 === 'EN2' ? 'bg-red-500 text-white' : 'bg-gray-300'
                                                                        } hover:bg-red-600 transition-colors shadow-lg`}
                                                                    type="button"
                                                                    onClick={() => setActiveInput2('EN2')}
                                                                >
                                                                    ข้อความ EN
                                                                </button>
                                                            </div>
                                                            <div className="mt-4">
                                                                <textarea
                                                                    className={`${activeInput2 === 'EN2' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                    placeholder="หัวข้อภาษาไทย"
                                                                    name="desktop.video.translation.en.slide_video_content_header2"
                                                                    value={th2}
                                                                    onChange={(e) => setTh2(e.target.value)}
                                                                    ref={register}
                                                                    rows={1}
                                                                />
                                                                <textarea
                                                                    className={`${activeInput2 === 'TH2' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                    placeholder="หัวข้อภาษาอังกฤษ"
                                                                    name="desktop.video.translation.th.slide_video_content_header2"
                                                                    value={en2}
                                                                    onChange={(e) => setEn2(e.target.value)}
                                                                    ref={register}
                                                                    rows={1}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormLabel fontSize="sm">ส่วนเนื้อหา 2</FormLabel>
                                                    <label
                                                        className="block  text-gray-700 text-sm font-semibold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ภาษาไทย
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        name="desktop.video.translation.th.slide_video_content2"
                                                        defaultValue={defaultValue?.desktop?.video?.translation?.th?.slide_video_content2}
                                                        render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                                                    />
                                                    <label
                                                        className="pt-2 block  text-gray-700 text-sm font-semibold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ภาษาอังกฤษ
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        name="desktop.video.translation.en.slide_video_content2"
                                                        defaultValue={defaultValue?.desktop?.video?.translation?.en?.slide_video_content2}
                                                        render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                                                    />
                                                </div>
                                                <div className="w-full  px-2 py-1 pt-5 pb-3  lg:pr-24">
                                                    <div className="justify-center flex pb-4">
                                                        <div>
                                                            <h1 className="flex justify-center pb-4 text-2xl">หัวข้อ 3 </h1>
                                                            <div className="flex justify-center shadow-4xl">
                                                                <button
                                                                    className={`px-4 py-2 text-black ${activeInput3 === 'TH3' ? 'bg-green-500 text-white' : 'bg-gray-300'
                                                                        } hover:bg-blue-600 transition-colors shadow-lg`}
                                                                    type="button"
                                                                    onClick={() => setActiveInput3('TH3')}
                                                                >
                                                                    ข้อความ TH
                                                                </button>
                                                                <button
                                                                    className={`px-4 py-2 text-black ${activeInput3 === 'EN3' ? 'bg-red-500 text-white' : 'bg-gray-300'
                                                                        } hover:bg-red-600 transition-colors shadow-lg`}
                                                                    type="button"
                                                                    onClick={() => setActiveInput3('EN3')}
                                                                >
                                                                    ข้อความ EN
                                                                </button>
                                                            </div>
                                                            <div className="mt-4">
                                                                <textarea
                                                                    className={`${activeInput3 === 'EN3' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                    placeholder="หัวข้อภาษาไทย"
                                                                    name="desktop.video.translation.en.slide_video_content_header3"
                                                                    value={th3}
                                                                    onChange={(e) => setTh3(e.target.value)}
                                                                    ref={register}
                                                                    rows={1}
                                                                />
                                                                <textarea
                                                                    className={`${activeInput3 === 'TH3' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                    placeholder="หัวข้อภาษาอังกฤษ"
                                                                    name="desktop.video.translation.th.slide_video_content_header3"
                                                                    value={en3}
                                                                    onChange={(e) => setEn3(e.target.value)}
                                                                    ref={register}
                                                                    rows={1}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <FormLabel fontSize="sm">ส่วนเนื้อหา 3</FormLabel>
                                                    <label
                                                        className="block  text-gray-700 text-sm font-semibold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ภาษาไทย
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        name="desktop.video.translation.th.slide_video_content3"
                                                        defaultValue={defaultValue?.desktop?.video?.translation?.th?.slide_video_content3}
                                                        render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                                                    />
                                                    <label
                                                        className="pt-2 block  text-gray-700 text-sm font-semibold mb-2"
                                                        htmlFor="grid-password"
                                                    >
                                                        ภาษาอังกฤษ
                                                    </label>
                                                    <Controller
                                                        control={control}
                                                        name="desktop.video.translation.en.slide_video_content3"
                                                        defaultValue={defaultValue?.desktop?.video?.translation?.en?.slide_video_content3}
                                                        render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <div className="pt-8">
                                            <h1 className="text-2xl pb-8">องค์ประกอบส่วน SlideShowVideo</h1>
                                            <div>
                                                <div className="pb-10 justify-center ">
                                                    <div className="w-full px-2 lg:pr-6 lg:pl-6 pr-10 3xl:pr-90 3xl:pl-90  ">
                                                        <div className="pb-8">
                                                            <div className="flex justify-center border-black bg-gray-200 border-2 p-2  rounded-full ">
                                                                <FormLabel fontSize="sm" className="pt-2 pl-2">Video1</FormLabel>
                                                                <Select
                                                                    size="sm"
                                                                    ref={register}
                                                                    // setValue={watch('desktop.video.slide_show_video.selected_file1.file_name')}
                                                                    defaultValue={defaultValue?.desktop?.video?.slide_show_video?.selected_file1}
                                                                    name="desktop.video.slide_show_video.selected_file1"
                                                                    placeholder="เลือกไฟล์"
                                                                >
                                                                    {_.map(file?.arr, (each, index) => (
                                                                        <option key={index} value={each?.id}>
                                                                            {each?.file_name}{" "}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className=" flex justify-center border-black bg-gray-200 border-2 p-2  rounded-full ">
                                                                <FormLabel fontSize="sm" className="pt-2 pl-2">Video2</FormLabel>
                                                                <Select
                                                                    size="sm"
                                                                    ref={register}
                                                                    // setValue={watch('desktop.video.slide_show_video.selected_file2.file_name')}
                                                                    defaultValue={defaultValue?.desktop?.video?.slide_show_video?.selected_file2}
                                                                    name="desktop.video.slide_show_video.selected_file2"
                                                                    placeholder="เลือกไฟล์"
                                                                >
                                                                    {_.map(file?.arr, (each, index) => (
                                                                        <option key={index} value={each?.id}>
                                                                            {each?.file_name}{" "}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="pb-8 pt-8">
                                                            <div className="flex justify-center border-black bg-gray-200 border-2 p-2  rounded-full ">
                                                                <FormLabel fontSize="sm" className="pt-2 pl-2">รูปภาพ1</FormLabel>
                                                                <Select
                                                                    size="sm"
                                                                    ref={register}
                                                                    // setValue={watch('desktop.video.slide_show_video.selected_file1.file_name')}
                                                                    defaultValue={defaultValue?.desktop?.video?.slide_show_video?.slide_show_picture1}
                                                                    name="desktop.video.slide_show_video.slide_show_picture1"
                                                                    placeholder="เลือกไฟล์"
                                                                >
                                                                    {_.map(gallery?.arr, (each, index) => (
                                                                        <option key={index} value={each?.id}>
                                                                            {each?.name}{" "}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                        <div className="pb-8">
                                                            <div className="flex justify-center border-black bg-gray-200 border-2 p-2  rounded-full ">
                                                                <FormLabel fontSize="sm" className="pt-2 pl-2">รูปภาพ2</FormLabel>
                                                                <Select
                                                                    size="sm"
                                                                    ref={register}
                                                                    // setValue={watch('desktop.video.slide_show_video.selected_file1.file_name')}
                                                                    defaultValue={defaultValue?.desktop?.video?.slide_show_video?.slide_show_picture2}
                                                                    name="desktop.video.slide_show_video.slide_show_picture2"
                                                                    placeholder="เลือกไฟล์"
                                                                >
                                                                    {_.map(gallery?.arr, (each, index) => (
                                                                        <option key={index} value={each?.id}>
                                                                            {each?.name}{" "}
                                                                        </option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >}

                    {watch('desktop.video.type_display_video') === TypedisplayVideo.VIDEO_EDIT.status_code && <div className="flex flex-wrap">
                        <div className="w-86 mr-60 lg:mr-10 lg:w-full   lg:pr-10 lg:pl-10 lg:pb-40   mx-2 py-4">
                            <div className="my-2 ">
                                <div className=" bg-gradient-to-r from-blue-200 to-purple-200 lg:border-2 border-indigo-300 pt-10 lg:pr-10 lg:pl-10 pb-2 ">
                                    <div className="lg:border-2 lg:border-indigo-600 lg:p-20 mb-5 ">
                                        <div className="pb-10 justify-center ">
                                            <div className="w-full px-2 lg:pr-6 lg:pl-6 pr-10 3xl:pr-90 3xl:pl-90  ">
                                                <div className=" flex justify-center border-black bg-gray-200 border-2 p-2  rounded-full ">
                                                    <FormLabel fontSize="sm" className="pt-2 pl-2">Video</FormLabel>
                                                    <Select
                                                        size="sm"
                                                        ref={register}
                                                        defaultValue={defaultValue?.type_attribute?.selected_file}
                                                        name="type_attribute.selected_file"
                                                        placeholder="เลือกไฟล์"
                                                    >
                                                        {_.map(file?.arr, (each, index) => (
                                                            <option key={index} value={each?.id}>
                                                                {each?.file_name}{" "}
                                                            </option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pb-10 font-semibold">
                                            <h1>ตำแหน่งเริ่มต้น video</h1>
                                            <select
                                                ref={register}
                                                className="block w-64 mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                                placeholder="ตำแหน่งเริ่มต้นของรูปภาพ"
                                                defaultValue={defaultValue?.desktop?.video?.video_position}
                                                name="desktop.video.video_position"
                                                size="sm"
                                            >
                                                {_.map(VideoPosition, (each, index) => (
                                                    <option
                                                        key={index}
                                                        value={each.status_code}
                                                        className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                                                    >
                                                        {i18n?.language === "th" ? each.description : each.descriptionEn}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="pb-4">
                                            <h1>แสดง sidebar</h1>
                                            <div>
                                                <Checkbox
                                                    ref={register}
                                                    size="sm"
                                                    defaultChecked={defaultValue?.desktop?.video?.show_sidebar || false}
                                                    name={`desktop.video.show_sidebar`}
                                                >
                                                    เปิดใช้งาน
                                                </Checkbox>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex pt-2 pb-4">
                                                <motion.div
                                                    className={`text-sm ${watch('desktop.video.show_video_info') ? 'text-green-600' : 'text-black'}`}
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{ duration: 0.3 }}
                                                >
                                                    <Checkbox
                                                        ref={register}
                                                        defaultChecked={watch('desktop.video.show_video_info')}
                                                        name="desktop.video.show_video_info"
                                                        onChange={handleChange}
                                                    >
                                                        <a className="text-sm">
                                                            เพิ่มข้อความ
                                                        </a>
                                                    </Checkbox>
                                                </motion.div>
                                            </div>
                                            {watch(`desktop.video.show_video_info`) === true && (
                                                <div>
                                                    <div className="pb-4">
                                                        <select
                                                            ref={register}
                                                            className="block w-64 mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                                            placeholder="รูปแบบแอนนิเมชั่นของข้อความ"
                                                            defaultValue={defaultValue?.desktop?.video?.textvideo?.animation_text_type}
                                                            name="desktop.video.textvideo.animation_text_type"
                                                            size="sm"
                                                        >
                                                            {_.map(VideoTextAnimationType, (each, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={each.status_code}
                                                                    className="hover:bg-indigo-500 hover:text-white hover:opacity-80"
                                                                >
                                                                    {i18n?.language === "th" ? each.description : each.descriptionEn}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <div className="pb-4">
                                                            <div className="flex gap-0">
                                                                <div className="flex justify-center shadow-4xl">
                                                                    <button
                                                                        className={`px-4 py-2 text-black ${activeInput === 'TH' ? 'bg-blue-500 text-white' : 'bg-gray-300'
                                                                            } hover:bg-blue-600 transition-colors shadow-lg`}
                                                                        type="button"
                                                                        onClick={() => setActiveInput('TH')}
                                                                    >
                                                                        ข้อความ TH
                                                                    </button>
                                                                    <button
                                                                        className={`px-4 py-2 text-black ${activeInput === 'EN' ? 'bg-red-500 text-white' : 'bg-gray-300'
                                                                            } hover:bg-red-600 transition-colors shadow-lg`}
                                                                        type="button"
                                                                        onClick={() => setActiveInput('EN')}
                                                                    >
                                                                        ข้อความ EN
                                                                    </button>
                                                                </div>
                                                                <div className="mt-4 flex justify-center ">
                                                                    <textarea
                                                                        className={`${activeInput === 'EN' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                        placeholder="ข้อความภาษาไทย1"
                                                                        setValue={watch('desktop.video.translation.th.video_content')}
                                                                        name="desktop.video.translation.th.video_content"
                                                                        ref={register}
                                                                        rows={3}
                                                                    />
                                                                    <textarea
                                                                        className={`${activeInput === 'TH' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                        placeholder="ข้อความภาษาอังกฤษ1"
                                                                        setValue={watch('desktop.video.translation.en.video_content')}
                                                                        name="desktop.video.translation.en.video_content"
                                                                        ref={register}
                                                                        rows={3}
                                                                    />
                                                                </div>
                                                                <div className="my-2 pl-4  lg:pr-6 2xl:pr-0 pt-3 2xl:w-64 lg:w-full">
                                                                    <div className="font-semibold text-sm my-1 pr-8 2xl:pl-34 2xl:ml-4">ขนาดตัวอักษร</div>
                                                                    <Select
                                                                        size="sm"
                                                                        ref={register}
                                                                        name="desktop.video.video_info_Size"
                                                                        defaultValue={defaultValue}
                                                                        colorScheme="purple"
                                                                    >
                                                                        <option value="normal">normal</option>
                                                                        <option value="sm">sm</option>
                                                                        <option value="lg">lg</option>
                                                                        <option value="xl">xl</option>
                                                                        <option value="2xl">2xl</option>
                                                                        <option value="3xl">3xl</option>
                                                                        <option value="4xl">4xl</option>
                                                                        <option value="5xl">5xl</option>
                                                                    </Select>
                                                                </div>
                                                                <div className="w-52 lg:w-full px-1 pl-0 ">
                                                                    <h6 className="text-gray-900 text-sm  lg:pl-20 2xl:pl-56 font-bold  w-full font-sans my-2">
                                                                        สีตัวอักษร {" "}
                                                                    </h6>
                                                                    <div className="w-full lg:w-60 2xl:ml-40 flex justify-center">
                                                                        <Controller
                                                                            control={control}
                                                                            name="desktop.video.video_info_color"
                                                                            defaultValue={watch('desktop?.video?.video_info_color', '')}
                                                                            // defaultValue={defaultValue?.desktop?.video?.video_info_color}
                                                                            render={(field) => (
                                                                                <SketchPicker
                                                                                    color={field.value}
                                                                                    onChangeComplete={(selectedColor) => {
                                                                                        field.onChange(selectedColor.hex)
                                                                                    }}
                                                                                    width={256}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="pt-14">
                                                                <SliderPddingVideoText
                                                                    rightPaddingVideoText='desktop.video.textvideo.right_margin_textvideo'
                                                                    leftPaddingVideoText='desktop.video.textvideo.left_margin_textvideo'
                                                                    bottomPaddingVideoText='desktop.video.textvideo.bottom_margin_textvideo'
                                                                    opacityBorder='desktop.video.textvideo.opacityBorder'
                                                                    register={register}
                                                                    defaultValue={defaultValue}
                                                                    watch={watch}
                                                                />
                                                                <SliderVideoTextArea
                                                                    name=""
                                                                    register={register}
                                                                    defaultValue={defaultValue}
                                                                    watch={watch}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <div className="flex pt-2 pb-4">
                                                <motion.div
                                                    className={`text-sm ${watch('desktop.video.show_video_button') ? 'text-green-600' : 'text-black'}`}
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    transition={{ duration: 0.3 }}
                                                >
                                                    <Checkbox
                                                        ref={register}
                                                        defaultChecked={watch('desktop.video.show_video_button')}
                                                        name="desktop.video.show_video_button"
                                                        onChange={handleOnChange}
                                                    >
                                                        <a className="text-sm">
                                                            เพิ่มปุ่มกด
                                                        </a>
                                                    </Checkbox>
                                                </motion.div>
                                            </div>
                                            {watch(`desktop.video.show_video_button`) === true && (
                                                <div >
                                                    <div className="pb-4">
                                                        <div
                                                            className={`border-2  rounded-lg   border-indigo-600 pt-3 pb-3 3xl:mr-0 3xl:ml-0 ml-3 mr-3  lg:mr-40 lg:ml-40 shadow-lg hover:shadow-xl transition-all`}
                                                            style={{
                                                                background: defaultValue?.desktop?.video?.button?.button_color,
                                                            }}
                                                        >
                                                            <div className="flex justify-center">
                                                                <IconButton
                                                                    type="button"
                                                                    onClick={handleButtonClick}>
                                                                    <div
                                                                        className="text-lg"
                                                                        style={{
                                                                            color: defaultValue?.desktop?.video?.button?.button_text_color,
                                                                        }}
                                                                    >
                                                                        Button
                                                                        <KeyboardArrowDown />
                                                                    </div>
                                                                </IconButton>
                                                            </div>

                                                            <div className="flex flex-wrap justify-center justify-items-center ">
                                                                {showContent && (
                                                                    <div>
                                                                        <div className="hover:bg-white">
                                                                            < Input
                                                                                ref={register}
                                                                                placeholder="ชื่อปุ่ม ไทย"
                                                                                setValue={watch('desktop?.video?.translation?.th?.button_video')}
                                                                                name="desktop.video.translation.th.button_video"
                                                                                size="sm" />
                                                                            <Input
                                                                                ref={register}
                                                                                placeholder="ชื่อปุ่ม Eng"
                                                                                setValue={watch('desktop?.video?.translation?.en?.button_video')}
                                                                                // defaultValue={defaultValue?.desktop?.video?.translation?.en?.button_video}
                                                                                name="desktop.video.translation.en.button_video"
                                                                                size="sm"
                                                                            />
                                                                        </div>
                                                                        <div className="my-2 pl-2 pr-1 pt-3 ">
                                                                            <div className="font-semibold text-sm my-1 pl-2">ขนาดตัวอักษร</div>
                                                                            <Select
                                                                                size="sm"
                                                                                ref={register}
                                                                                setValue={watch('desktop?.video?.button?.button_text_size')}
                                                                                // defaultValue={defaultValue?.desktop?.video?.button?.button_text_size}
                                                                                name="desktop.video.button.button_text_size"
                                                                                colorScheme="purple"
                                                                            >
                                                                                <option value="normal">normal</option>
                                                                                <option value="sm">sm</option>
                                                                                <option value="lg">lg</option>
                                                                                <option value="xl">xl</option>
                                                                                <option value="2xl">2xl</option>
                                                                                <option value="3xl">3xl</option>
                                                                                <option value="4xl">4xl</option>
                                                                            </Select>
                                                                        </div>
                                                                        <div className="px-2 py-1">
                                                                            {!watch(`button.useOtherLink`) === true && (
                                                                                <>
                                                                                    <div className="text-sm pt-4 pl-2">Link ไปที่</div>
                                                                                    <Select
                                                                                        size="sm"
                                                                                        placeholder="ลิงค์เมนู"
                                                                                        name={`button.link`}
                                                                                        ref={register}
                                                                                    >
                                                                                        {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                                                                                            <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                                                                                                {eachlink?.description}
                                                                                            </option>
                                                                                        ))}
                                                                                    </Select>
                                                                                </>
                                                                            )}
                                                                            <div className="my-1 pb-1 pt-4">
                                                                                <Checkbox
                                                                                    ref={register}
                                                                                    size="sm"
                                                                                    // defaultChecked={eachField?.useOtherLink || false}
                                                                                    name={`button.useOtherLink`}
                                                                                >
                                                                                    ใช้ลิงค์กำหนดเอง
                                                                                </Checkbox>
                                                                            </div>
                                                                            {watch(`button.useOtherLink`) === true && (
                                                                                <div className="my-2">
                                                                                    <div className="text-gray-600 text-sm">
                                                                                        กรณีที่เป็นลิงค์ในเว็บไซต์เดียวกัน เริ่มต้นที่ / แล้วต่อด้วยลิงค์ได้เลย
                                                                                        ถ้าเป็นลิงค์ภายนอก ให้เริ่มต้นด้วย http:// หรือ https://
                                                                                    </div>
                                                                                    <div className="text-sm font-semibold">ลิงค์กำหนดเอง</div>
                                                                                    <Input
                                                                                        size="sm"
                                                                                        placeholder="ใส่ลิ้ง"
                                                                                        name={`button.otherLink`}
                                                                                        // defaultValue={otherLink||''}
                                                                                        ref={register}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pt-4 bg-white pb-4 ">
                                                        <div className="pt-2">
                                                            <h1
                                                                className="text-xl border-2 text-center p-2 
                                                            pr-40 pl-40 bg-white">
                                                                Preview
                                                            </h1>
                                                        </div>
                                                        <div className="flex justify-center pt-4">
                                                            <h1>ตัวช่วยเลือกความยาวและความกว้าง</h1>
                                                        </div>
                                                        <div className=" pt-4 pb-4">
                                                            <div className="flex justify-center">
                                                                <Slider
                                                                    lengthFormName='desktop.video.button.button_component_length'
                                                                    widthFormName="desktop.video.button.button_component_width"
                                                                    register={register}
                                                                    defaultValue={defaultValue}
                                                                    watch={watch}
                                                                />
                                                            </div>
                                                            <div className="flex justify-center">
                                                                <OpacitySlider
                                                                    opacityBorder='desktop.video.button.button_background_opacity'
                                                                    register={register}
                                                                    defaultValue={defaultValue}
                                                                    watch={watch}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <SliderType
                                                                watch={watch}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="lg:pl-0">
                                                        <div className="lg:pl-12  pt-4">
                                                            <motion.span
                                                                whileHover={{ scale: 1.05 }}
                                                                whileTap={{ scale: 0.95 }}
                                                                className={`cursor- pointer px-3 py-1 mb-2 rounded-md bg-blue-500 text-white text-sm focus:outline-none hover:bg-purple-400`}
                                                                onClick={() => setShowHeading(!showHeading)}
                                                            >
                                                                {showHeading ? (
                                                                    <Visibility fontSize="small" />
                                                                ) : (
                                                                    <VisibilityOff fontSize="small" />
                                                                )}
                                                                <span className="ml-1">รูปแบบของปุ่มกด</span>
                                                            </motion.span>
                                                            {showHeading && (
                                                                <div className="pt-4    ">
                                                                    <Controller
                                                                        name="desktop.video.button.button_type"
                                                                        control={control}
                                                                        render={(field) => (
                                                                            <RadioGroup
                                                                                className="flex gap-2 flex-wrap my-2"
                                                                                value={field.value}
                                                                                onChange={value => {
                                                                                    field.onChange(value)
                                                                                }}
                                                                            // onFocus={() => {
                                                                            //     alert('คุณกำลังเลือกรูปแบบของปุ่มอยู่ กรุณาเลือกด้วยความละเอิียด')
                                                                            // }}
                                                                            // onBlur={e => {
                                                                            //     if (field.value === 'CIRCLE') {
                                                                            //         alert('คุณต้องใช้ Circle เท่านั้น')
                                                                            //     }
                                                                            // }}
                                                                            >
                                                                                {_.map(ButtonType, (eachType, index) => (
                                                                                    <Radio value={eachType.status_code} key={index} >
                                                                                        <div className="border p-2">
                                                                                            {eachType.status_code}
                                                                                            <img src={eachType?.image} className="w-24" />
                                                                                            <div className="text-base">{eachType?.description}</div>
                                                                                        </div>
                                                                                    </Radio>
                                                                                ))}
                                                                            </RadioGroup>
                                                                        )}
                                                                    />

                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="lg:pl-12 lg:pr-6  pt-4">
                                                            <motion.span
                                                                whileHover={{ scale: 1.05 }}
                                                                whileTap={{ scale: 0.95 }}
                                                                className={`cursor- pointer px-3 py-1 mb-2 rounded-md bg-red-400 text-white text-sm focus:outline-none hover:bg-red-300`}
                                                                onClick={() => setShowEditTextColor(!showEditTextColor)}
                                                            >
                                                                {showHeading ? (
                                                                    <Visibility fontSize="small" />
                                                                ) : (
                                                                    <VisibilityOff fontSize="small" />
                                                                )}
                                                                <span className="ml-1">การตกแต่งสีบนปุ่มกด</span>
                                                            </motion.span>
                                                            {showEditTextColor && (
                                                                <div className="pt-4 lg:pr-36">
                                                                    <div className="flex  border-2 bg-gradient-to-r from-gray-200 to-gray-500">
                                                                        <h6 className="pl-4 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                                                                            ** สีตัวข้อความบน Button  {" "}
                                                                        </h6>
                                                                        <div className="w-full flex justify-center">
                                                                            <button
                                                                                onClick={handleButtonDivClick}
                                                                                type="button"
                                                                            >
                                                                                <ExpandMoreIcon />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {/* {showDiv1 && ( */}
                                                                    <div className={`${showDiv1 ? "block" : "hidden"} w-60 lg:w-full flex justify-center bg-gradient-to-r from-gray-100 to-gray-400 pt-3 pb-7`}>
                                                                        <Controller
                                                                            control={control}
                                                                            setValue={watch('desktop?.video?.button?.button_text_color')}
                                                                            // defaultValue={defaultValue?.desktop?.video?.button?.button_text_color}
                                                                            name="desktop.video.button.button_text_color"
                                                                            render={(field) => (
                                                                                <SketchPicker
                                                                                    color={field.value}
                                                                                    onChangeComplete={(selectedColor) => {
                                                                                        field.onChange(selectedColor.hex)
                                                                                    }}
                                                                                    width={256}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    {/* )} */}
                                                                    <div className="flex border-2 bg-gradient-to-r from-gray-300 to-gray-500">
                                                                        <h6 className="pl-4 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                                                                            ** สี Button {" "}
                                                                        </h6>
                                                                        <div className="w-full flex justify-center">
                                                                            <button
                                                                                onClick={handleButtonDiv2Click}
                                                                                type="button"
                                                                            >
                                                                                <ExpandMoreIcon />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {/* {showDiv2 && ( */}
                                                                    <div className={`${showDiv2 ? "block" : "hidden"}`}>
                                                                        {watch(`desktop.video.button.button_backdrop_color`) !== true && (
                                                                            <div>
                                                                                <div className="flex">
                                                                                    <h6 className="pl-4 text-gray-900 text-sm font-bold w-full font-sans my-2">
                                                                                        เลือกสีแบบปกติ {" "}
                                                                                    </h6>
                                                                                    <div className="w-full flex justify-center">
                                                                                        <button
                                                                                            onClick={handleButtonDivInSideClick}
                                                                                            type="button"
                                                                                        >
                                                                                            <ExpandMoreIcon />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                                {/* {showDivInSide && ( */}
                                                                                <div className={`${showDivInSide ? "block" : "hidden"}w-60 lg:w-full flex justify-center bg-gradient-to-r from-gray-100 to-gray-400 pt-3 pb-7`}>
                                                                                    <Controller
                                                                                        control={control}
                                                                                        setValue={watch('desktop?.video?.button?.button_color')}
                                                                                        // defaultValue={defaultValue?.desktop?.video?.button?.button_color} name="desktop.video.button.button_color"
                                                                                        name="desktop.video.button.button_color"
                                                                                        render={(field) => (
                                                                                            <SketchPicker
                                                                                                color={field.value}
                                                                                                onChangeComplete={(selectedColor) => {
                                                                                                    field.onChange(selectedColor.hex)
                                                                                                }}
                                                                                                width={256}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >}
                </div>
            )
            }
            {
                activeTab === 'Mobile' && (
                    <div>
                        <div className="pt-8 text-xl font-bold mb-4 flex justify-center">
                            จัดการส่วนประกอบภายในวิดีโอ (รูปแบบ Mobile)
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-64 lg:w-full lg:pr-40 lg:pl-40 lg:pb-40   mx-2 py-4">
                                <div className="my-2 ">
                                    <div className=" lg:bg-gradient-to-r  lg:from-blue-200 to-purple-200 border-2 border-indigo-300 pt-10 lg:pr-10 lg:pl-10 pb-2 ">
                                        <div className="lg:border-2 border-indigo-600 p-10 lg:p-20 mb-5 ">
                                            <div>
                                                <div className="flex pt-2 pb-4">
                                                    <motion.div
                                                        className={`text-sm ${watch('mobile?.video?.how_video_info') ? 'text-green-600' : 'text-black'}`}
                                                        initial={{ opacity: 0, y: 20 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        <Checkbox
                                                            ref={register}
                                                            defaultChecked={watch('mobile?.video?.how_video_info')}
                                                            name="mobile.video.show_video_info"
                                                            onChange={handleMobileChange}
                                                        >
                                                            <a className="text-sm">
                                                                เพิ่มข้อความ
                                                            </a>
                                                        </Checkbox>
                                                    </motion.div>
                                                </div>
                                                {watch(`mobile.video.show_video_info`) === true && (
                                                    <div className="justify-center flex pb-4">
                                                        <div>
                                                            <div className="flex justify-center shadow-4xl pt-2">
                                                                <button
                                                                    className={`px-4 py-2 text-black ${activeMobileInput === 'th' ? 'bg-blue-500 text-white' : 'bg-gray-300'
                                                                        } hover:bg-blue-600 transition-colors shadow-lg`}
                                                                    type="button"
                                                                    onClick={() => setActiveMobileInput('th')}
                                                                >
                                                                    ข้อความ TH
                                                                </button>
                                                                <button
                                                                    className={`px-4 py-2 text-black ${activeMobileInput === 'en' ? 'bg-red-500 text-white' : 'bg-gray-300'
                                                                        } hover:bg-red-600 transition-colors shadow-lg`}
                                                                    type="button"
                                                                    onClick={() => setActiveMobileInput('en')}
                                                                >
                                                                    ข้อความ EN
                                                                </button>
                                                            </div>
                                                            <div className="mt-4 ">
                                                                <div className="flex justify-center">
                                                                    <textarea
                                                                        className={`${activeMobileInput === 'en' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                        placeholder="ข้อความภาษาอังกฤษ1"
                                                                        name="mobile.video.translation.th.video_content"
                                                                        // value={mobileth}
                                                                        // onChange={(e) => setmobileTH(e.target.value)}
                                                                        ref={register}
                                                                        rows={3}
                                                                    />
                                                                    <textarea
                                                                        className={`${activeMobileInput === 'th' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                                                                        placeholder="ข้อความภาษาอังกฤษ1"
                                                                        name="mobile.video.translation.en.video_content"
                                                                        // value={mobileen}
                                                                        // onChange={(e) => setmobileEN(e.target.value)}
                                                                        ref={register}
                                                                        rows={3}
                                                                    />
                                                                </div>
                                                                <div className="my-2">
                                                                    <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                                                                    <Select
                                                                        size="sm"
                                                                        ref={register}
                                                                        defaultValue={watch('mobile?.video?.video_info_Size')}
                                                                        // defaultValue={defaultValue?.mobile?.video?.video_info_Size}
                                                                        name="mobile.video.video_info_Size"
                                                                        colorScheme="purple"
                                                                    >
                                                                        <option value="normal">normal</option>
                                                                        <option value="sm">sm</option>
                                                                        <option value="lg">lg</option>
                                                                        <option value="xl">xl</option>
                                                                        <option value="2xl">2xl</option>
                                                                        <option value="3xl">3xl</option>
                                                                        <option value="4xl">4xl</option>
                                                                        <option value="5xl">5xl</option>
                                                                    </Select>
                                                                    <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                                                                        สีตัวอักษร {" "}
                                                                    </h6>
                                                                    <div className="w-full flex justify-center">
                                                                        <Controller
                                                                            control={control}
                                                                            defaultValue={watch('mobile?.video?.video_info_color')}
                                                                            // defaultValue={defaultValue?.mobile?.video?.video_info_color}
                                                                            name="mobile.video.video_info_color"
                                                                            render={(field) => (
                                                                                <SketchPicker
                                                                                    color={field.value}
                                                                                    onChangeComplete={(selectedColor) => {
                                                                                        field.onChange(selectedColor.hex)
                                                                                    }}
                                                                                    width={256}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div className="pt-14">
                                                                        <SliderPddingVideoText
                                                                            rightPaddingVideoText='mobile.video.textvideo.right_margin_textvideo'
                                                                            leftPaddingVideoText='mobile.video.textvideo.left_margin_textvideo'
                                                                            bottomPaddingVideoText='mobile.video.textvideo.bottom_margin_textvideo'
                                                                            opacityBorder='mobile.video.textvideo.opacityBorder'
                                                                            register={register}
                                                                            defaultValue={defaultValue}
                                                                            watch={watch}
                                                                        />
                                                                        <SliderVideoMobileTextArea
                                                                            name=""
                                                                            register={register}
                                                                            defaultValue={defaultValue}
                                                                            watch={watch}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <div className="flex pt-2 pb-4">
                                                    <motion.div
                                                        className={`text-sm ${watch('mobile?.video?.show_video_button') ? 'text-green-600' : 'text-black'}`}
                                                        initial={{ opacity: 0, y: 20 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        <Checkbox
                                                            ref={register}
                                                            defaultChecked={watch('mobile?.video?.show_video_button')}
                                                            name="mobile.video.show_video_button"
                                                            onChange={handleMobileOnChange}
                                                        >
                                                            <a className="text-sm">
                                                                เพิ่มปุ่มกด
                                                            </a>
                                                        </Checkbox>
                                                    </motion.div>
                                                </div>
                                                {watch(`mobile.video.show_video_button`) === true && (
                                                    <div>
                                                        <div
                                                            className=" border-2 rounded-lg lg:p-5  bg-white border-indigo-600 pt-3 pb-3 lg:mr-20 lg:ml-20 shadow-lg hover:shadow-xl transition-all"
                                                            style={{
                                                                background: defaultValue?.mobile?.video?.button?.button_color,
                                                            }}
                                                        >
                                                            <div className="flex justify-center">
                                                                <IconButton
                                                                    type="button"
                                                                    onClick={handleButtonMobileClick}>
                                                                    <div className="text-lg"
                                                                        style={{
                                                                            color: defaultValue?.mobile?.video?.button?.button_text_color,
                                                                        }}
                                                                    >
                                                                        Button
                                                                        <KeyboardArrowDown />
                                                                    </div>
                                                                </IconButton>
                                                            </div>
                                                            <div className="flex flex-wrap justify-center justify-items-center ">
                                                                {showMobileContent && (
                                                                    <div>
                                                                        <div className="hover:bg-white">
                                                                            < Input
                                                                                ref={register}
                                                                                placeholder="ชื่อปุ่ม ไทย"
                                                                                settValue={watch('mobile?.video?.translation?.th?.button_video')}
                                                                                // defaultValue={defaultValue?.mobile?.video?.translation?.th?.button_video}
                                                                                name="mobile.video.translation.th.button_video"
                                                                                size="sm" />
                                                                            <Input
                                                                                ref={register}
                                                                                placeholder="ชื่อปุ่ม Eng"
                                                                                setValue={watch('mobile?.video?.translation?.en?.button_video')}
                                                                                // defaultValue={defaultValue?.mobile?.video?.translation?.en?.button_video}
                                                                                name="mobile.video.translation.en.button_video"
                                                                                size="sm" />
                                                                        </div>
                                                                        <div className="my-2 pl-1 pr-1 pt-3">
                                                                            <div className="font-semibold text-sm my-1 ">ขนาดตัวอักษร</div>
                                                                            <Select
                                                                                size="sm"
                                                                                ref={register}
                                                                                defaultValue={watch('mobile?.video?.button?.button_text_size')}
                                                                                // defaultValue={defaultValue?.mobile?.video?.button?.button_text_size}
                                                                                name="mobile.video.button.button_text_size"
                                                                                colorScheme="purple"
                                                                            >
                                                                                <option value="normal">normal</option>
                                                                                <option value="sm">sm</option>
                                                                                <option value="lg">lg</option>
                                                                                <option value="xl">xl</option>
                                                                                <option value="2xl">2xl</option>
                                                                                <option value="3xl">3xl</option>
                                                                                <option value="4xl">4xl</option>
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="pt-8 pb-8">
                                                            <div className=" bg-white pt-4 pb-8 ">
                                                                <div className="pt-2">
                                                                    <h1
                                                                        className="text-xl border-2 text-center p-2 
                                                                        pr-40 pl-40 bg-white">
                                                                        Preview
                                                                    </h1>
                                                                </div>
                                                                <div className="flex justify-center pt-4">
                                                                    <h1>ตัวช่วยเลือกความยาวและความกว้าง</h1>
                                                                </div>
                                                                <div className=" pt-4 pb-4">
                                                                    <div className="flex justify-center">
                                                                        <Slider
                                                                            lengthFormName='mobile.video.button.button_component_length'
                                                                            widthFormName="mobile.video.button.button_component_width"
                                                                            register={register}
                                                                            defaultValue={defaultValue}
                                                                            watch={watch}
                                                                        />
                                                                    </div>
                                                                    <div className="flex justify-center">
                                                                        <OpacitySlider
                                                                            opacityBorder='mobile.video.button.button_background_opacity'
                                                                            register={register}
                                                                            defaultValue={defaultValue}
                                                                            watch={watch}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <SliderTypeMobile
                                                                        watch={watch}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="lg:pl-20 pt-4">
                                                            <motion.span
                                                                whileHover={{ scale: 1.05 }}
                                                                whileTap={{ scale: 0.95 }}
                                                                className={`cursor- pointer px-3 py-1 mb-2 rounded-md bg-blue-500 text-white text-sm focus:outline-none hover:bg-purple-400`}
                                                                onClick={() => setShowHeading(!showHeading)}
                                                            >
                                                                {showHeading ? (
                                                                    <Visibility fontSize="small" />
                                                                ) : (
                                                                    <VisibilityOff fontSize="small" />
                                                                )}
                                                                <span className="ml-1">รูปแบบของปุ่มกด</span>
                                                            </motion.span>
                                                            {showHeading && (
                                                                <div className="pt-4 ">
                                                                    <RadioGroup
                                                                        className="flex gap-2 flex-wrap my-2"
                                                                        defaultValue={watch('mobile?.video?.button?.button_type')}
                                                                        // defaultValue={defaultValue?.mobile?.video?.button?.button_type}
                                                                        name="mobile.video.button.button_type"
                                                                        ref={register}
                                                                    >
                                                                        {_.map(ButtonType, (eachType, index) => (
                                                                            <Radio value={eachType.status_code} key={index} ref={register}>
                                                                                <div className="border border-black p-2 ">
                                                                                    <img src={eachType?.image} className="w-24" />
                                                                                    <div className="text-base">{eachType?.description}</div>
                                                                                </div>
                                                                            </Radio>
                                                                        ))}
                                                                    </RadioGroup>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="lg:pl-20 pt-4">
                                                            <motion.span
                                                                whileHover={{ scale: 1.05 }}
                                                                whileTap={{ scale: 0.95 }}
                                                                className={`cursor- pointer px-3 py-1 mb-2 rounded-md bg-red-400 text-white text-sm focus:outline-none hover:bg-red-300`}
                                                                onClick={() => setShowEditTextColor(!showEditTextColor)}
                                                            >
                                                                {showHeading ? (
                                                                    <Visibility fontSize="small" />
                                                                ) : (
                                                                    <VisibilityOff fontSize="small" />
                                                                )}
                                                                <span className="ml-1">การตกแต่งสีบนปุ่มกด</span>
                                                            </motion.span>
                                                            {showEditTextColor && (
                                                                <div className="pt-4 lg:pr-20">
                                                                    <div className="flex border-2 bg-gradient-to-r from-gray-200 to-gray-500">
                                                                        <h6 className="pl-4 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                                                                            ** สีข้อความบน Button{" "}
                                                                        </h6>
                                                                        <div className="w-full flex justify-center">
                                                                            <button
                                                                                onClick={handleButtonDivClick}
                                                                                type="button"
                                                                            >
                                                                                <ExpandMoreIcon />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {showDiv1 && (
                                                                        <div className="w-full flex justify-center bg-gradient-to-r from-gray-100 to-gray-400 pt-3 pb-7">
                                                                            <Controller
                                                                                control={control}
                                                                                defaultValue={watch('mobile?.video?.button?.button_text_color')}
                                                                                // defaultValue={defaultValue?.mobile?.video?.button?.button_text_color}
                                                                                name="mobile.video.button.button_text_color"
                                                                                render={(field) => (
                                                                                    <SketchPicker
                                                                                        color={field.value}
                                                                                        onChangeComplete={(selectedColor) => {
                                                                                            field.onChange(selectedColor.hex)
                                                                                        }}
                                                                                        width={256}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    <div className="flex border-2 bg-gradient-to-r from-gray-300 to-gray-500">
                                                                        <h6 className="pl-4 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                                                                            ** สี Button   {" "}
                                                                        </h6>
                                                                        <div className="w-full flex justify-center">
                                                                            <button
                                                                                onClick={handleButtonDiv2Click}
                                                                                type="button"
                                                                            >
                                                                                <ExpandMoreIcon />
                                                                            </button>
                                                                        </div>

                                                                    </div>
                                                                    {showDiv2 && (
                                                                        <div>
                                                                            {watch(`mobile.video.button.button_backdrop_color`) !== true && (
                                                                                <div>
                                                                                    <div className="flex">
                                                                                        <h6 className="pl-4 text-gray-900 text-sm font-bold w-full font-sans my-2">
                                                                                            เลือกสีแบบปกติ {" "}
                                                                                        </h6>
                                                                                        <div className="w-full flex justify-center">
                                                                                            <button
                                                                                                onClick={handleButtonDivInSideClick}
                                                                                                type="button"
                                                                                            >
                                                                                                <ExpandMoreIcon />
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    {showDivInSide && (
                                                                                        <div className="w-60 lg:w-full flex justify-center bg-gradient-to-r from-gray-100 to-gray-400 pt-3 pb-7">
                                                                                            <Controller
                                                                                                control={control}
                                                                                                defaultValue={watch('mobile?.video?.button?.button_color')}
                                                                                                // defaultValue={defaultValue?.mobile?.video?.button?.button_color}
                                                                                                name="mobile.video.button.button_color"
                                                                                                render={(field) => (
                                                                                                    <SketchPicker
                                                                                                        color={field.value}
                                                                                                        onChangeComplete={(selectedColor) => {
                                                                                                            field.onChange(selectedColor.hex)
                                                                                                        }}
                                                                                                        width={256}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                )
            }
        </div >
    )
}

export default Video
