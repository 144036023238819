import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Input } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import Alert from "../../../components/common/Alert/AlertDialog"
import * as actions from "../../../redux/actions"

export default function MeetingRoomManagement() {
  const dispatch = useDispatch()
  const meetingRoom = useSelector((state) => state.meetingRoom)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [page, setPage] = useState(1)

  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const { t, i18n } = useTranslation()

  const getAllMeetingRoom = () => {
    dispatch(actions.getAllMeetingRoom({ size: 200, page, query: { name } }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }
  useEffect(() => {
    getAllMeetingRoom()
    return () => {}
  }, [page, name])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const handleDeleteMeetingRoom = (serviceId) => {
    const confirm = window.confirm(t("roomtype.confirmRemoveService"))
    if (confirm) {
      dispatch(actions.deleteOneservice(serviceId))
        .then(() => {
          getAllMeetingRoom()
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  if (!isLoading) {
    return (
      <div>
        <SpinnerLoading />
      </div>
    )
  }

  return (
    <Layout categoryTitle={t("roomtype.title")} title={"จัดการห้องประชุม"} preview>
      <div className="flex justify-between px-4">
        <Link to={`/roomtypes/meeting-room/create`}>
          <Button colorScheme="purple" variant="solid">
            {t("general.add")}
          </Button>
        </Link>
        <div className="w-full lg:w-1/3 my-1">
          <Input
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            placeholder={t("general.search")}
            size="sm"
          />
        </div>
      </div>
      <div className="p-4 w-full min-h-screen overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />

        <div className="my-2 flex flex-wrap ">
          {meetingRoom?.arr ? (
            _.map(meetingRoom?.arr, (eachMeetingRoom, roomIndex) => (
              <div className="p-2 w-full lg:w-1/3 cursor-pointer hover:bg-gray-300" key={roomIndex}>
                <div className="bg-gray-200  rounded-md">
                  <div className="flex justify-between p-4">
                    <h3 className="font-bold text-lg mx-4 ml-2 ">
                      <i className="fas fa-door-open mr-2" />
                      {eachMeetingRoom?.translation?.[i18n.language]?.name || eachMeetingRoom?.name}
                    </h3>
                    <div className="flex gap-2">
                      <Link to={`meeting-room/edit/${eachMeetingRoom?.id}`}>
                        <Button variant="outline" size="sm" colorScheme="blue">
                          <i className="fas fa-pencil-alt " />
                        </Button>
                      </Link>
                      <Button
                        variant="outline"
                        size="sm"
                        colorScheme="red"
                        onClick={() => {
                          handleDeleteMeetingRoom(eachMeetingRoom?.id)
                        }}
                      >
                        <i className="fas fa-trash " />
                      </Button>
                    </div>
                  </div>

                  <p className="text-sm ml-6">ความจุ {eachMeetingRoom?.capacity} คน</p>

                  <br />
                </div>
              </div>
            ))
          ) : (
            <div className=" p-4 flex justify-center">
              <p>{t("roomtype.emptyCategory")}</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}
