import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { currencyFormatter } from "../../util"

// TODO: Show only Online Booking
export default function ServiceOrderPricePanel({
  orderInfo,
  finalDiscount = 0,
  allPrice,
  meetingRoomOrder,
  serviceList,
}) {
  const { t } = useTranslation()
  const meetingRoomOrderPrice = _.sum(_.map(meetingRoomOrder, (eachOrder) => eachOrder?.price)) || 0

  const servicePrice = _.sum(_.map(serviceList, (eachServices) => eachServices?.price)) || 0

  return (
    <div className="flex flex-wrap">
      <div
        className={`w-full  ${
          orderInfo?.payment?.payment_slip?.url ? " lg:w-9/12 " : "w-full"
        }px-2`}
      >
        <h6 className="text-xs font-bold mt-2 font-sans">{t("reservation.price.priceSummary")}</h6>
        <div className="flex justify-between">
          <p className="text-sm  my-1">{t("reservation.price.meetingRoomPrice")}</p>
          <p className="text-sm  my-1 ">
            {currencyFormatter.format(meetingRoomOrderPrice)} {t("general.baht")}{" "}
          </p>
        </div>

        <div className="flex justify-between">
          <p className="text-sm  my-1">{t("reservation.price.servicePrice")} </p>
          <p className="text-sm  my-1 ">
            {currencyFormatter.format(servicePrice)} {t("general.baht")}
          </p>
        </div>

        {!_.isEmpty(orderInfo?.fb_orders) &&
          _.map(orderInfo?.fb_orders, (eachFBOrder, index) => (
            <div className="flex justify-between" key={index}>
              <p className="text-sm  my-1">
                {t("foodAndBeverage.title")} - {eachFBOrder?.remark}{" "}
              </p>
              <p className="text-sm  my-1 ">
                {currencyFormatter.format(eachFBOrder?.price)} {t("general.baht")}
              </p>
            </div>
          ))}

        <div className="flex justify-between">
          <p className="text-sm  my-1">{t("reservation.price.priceBeforeDiscount")}</p>
          <p className="text-sm  my-1 ">
            {currencyFormatter.format(parseFloat(allPrice) + parseFloat(finalDiscount))}{" "}
            {t("general.baht")}{" "}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm  my-1">{t("reservation.price.totalDiscount")}</p>
          <p className="text-sm  my-1 ">
            {currencyFormatter.format(finalDiscount)} {t("general.baht")}{" "}
          </p>
        </div>

        <br />
        <div className="flex justify-between font-bold">
          <p className="text-sm  my-1">{t("reservation.totalPrice")} </p>
          <p className="text-sm  my-1 underline">
            {currencyFormatter.format(parseFloat(allPrice))} {t("general.baht")}{" "}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm  my-1"> {t("reservation.price.paidAmount")} </p>
          <p className="text-sm  my-1 ">
            {currencyFormatter.format(orderInfo?.payment?.paid_amount || 0)} {t("general.baht")}{" "}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="text-sm  my-1">{t("reservation.price.remainingAmount")} </p>
          <p className="text-sm  my-1 ">
            {currencyFormatter.format(parseFloat(allPrice) - orderInfo?.payment?.paid_amount || 0)}{" "}
            บาท{" "}
          </p>
        </div>
      </div>
      <div
        className={` px-2 ${
          orderInfo?.payment?.payment_slip?.url ? " w-full lg:w-3/12 " : "hidden"
        }`}
      >
        <h6 className="text-xs font-bold my-2 font-sans">{t("reservation.paymentSlip")}</h6>
        <img src={orderInfo?.payment?.payment_slip?.url} className="w-64"></img>
      </div>
    </div>
  )
}
