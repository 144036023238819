import api from "../../../config/api"

import {
  PROMOTION_ALL,
  PROMOTION_CREATE,
  PROMOTION_DELETE,
  PROMOTION_GET,
  PROMOTION_PUT,
} from "../types"

// faker.locale = "th"

export const getAllPromotions =
  (page = 1) =>
  async (dispatch) => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/promotion/?page=${page}&size=10`)
      .then((res) => {
        console.log("Request Server to Get All Promotions")
        if (res.data) {
          dispatch({ type: PROMOTION_ALL, payload: res.data })
        } else {
          dispatch({ type: PROMOTION_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All Promotions", err)
        dispatch({ type: PROMOTION_ALL, payload: null })
        throw new Error(
          `ไม่สามารถดึงข้อมูลโปรโมชั่นทั้งหมดได้ ${err?.response?.data?.error?.message}`,
        )
      })
  }

export const getOnePromotion = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/promotion/${id}`)
    .then((res) => {
      console.log("Request Server to Get One Promotion")
      if (res.data) {
        dispatch({ type: PROMOTION_GET, payload: res.data })
      } else {
        dispatch({ type: PROMOTION_GET, payload: null })
      }
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถดึงข้อมูลโปรโมชั่นได้ ${err?.response?.data?.error?.message}`)
    })
}
export const createOnePromotion = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/promotion/`, payload)
    .then(() => {
      console.log("Request Server to Create New Promotion")
      dispatch({ type: PROMOTION_CREATE })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถสร้างโปรโมชั่นได้ ${err?.response?.data?.error?.message}`)
    })
}

export const editOnePromotion = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/promotion/${id}`, payload)
    .then(() => {
      console.log("Request Server to edit Promotion")
      dispatch({ type: PROMOTION_PUT })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถแก้ไขโปรโมชั่นได้ ${err?.response?.data?.error?.message}`)
    })
}

export const deleteOnePromotion = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/promotion/${id}`)
    .then(() => {
      console.log("Request Server to Delete One Promotion")
      dispatch({ type: PROMOTION_DELETE, payload: null })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถลบข้อมูลโปรโมชั่นได้ ${err?.response?.data?.error?.message}`)
    })
}
