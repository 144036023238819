import React from "react"
import PropTypes from "prop-types"
// components

export default function CardProfile({
  firstname,
  lastname,
  department,
  position,
  email,
  phoneNumber,
  address,
  image,
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-\">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div className="relative">
                {image ? (
                  <>
                    {" "}
                    <img
                      alt="..."
                      src={image}
                      className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      alt="..."
                      src={require("assets/img/team-2-800x800.jpg")}
                      className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="w-full px-4 text-center mt-20">
              <div className="flex flex-wrap justify-center py-4 lg:pt-4 pt-8">
                <div className="mr-4 p-3 text-center">
                  <span className="text-sm font-bold block uppercase tracking-wide text-gray-700">
                    {email}
                  </span>
                  <span className="text-sm text-gray-500">
                    {" "}
                    <i className="fa fa-mail-bulk fa-1x"></i>
                  </span>
                </div>
                <div className="lg:mr-4 p-3 text-center">
                  <span className="text-sm font-bold block uppercase tracking-wide text-gray-700">
                    {phoneNumber}
                  </span>
                  <span className="text-sm text-gray-500">
                    {" "}
                    <i className="fa fa-phone"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-12">
            <h3 className="text-xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
              {`${firstname} ${lastname}`}
            </h3>
            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
              <i className="fas fa-briefcase  mr-2 text-lg text-gray-500"></i> {position}
            </div>
            <div className="mb-2 text-gray-700 ">
              <i className="fas fa-building mr-2 text-lg text-gray-500"></i>
              {department}
            </div>
          </div>
          <div className="mt-10 py-10 border-t border-gray-300 text-center">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-9/12 px-4">
                <p className="mb-4 text-sm leading-relaxed text-gray-800">{address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

CardProfile.defaultProps = {
  firstname: "ชื่อ",
  lastname: "สกุล",
  department: "แผนก",
  position: "ตำแหน่ง",
  email: "อีเมล",
  phoneNumber: "เบอร์โทรศัพท์",
  address: "ที่อยู่",
  image: null,
}

CardProfile.propTypes = {
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  department: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.string,
  image: PropTypes.string,
}
