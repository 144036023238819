/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { Table } from "antd"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  Alert,
  GeneralMainLayout as Layout,
  ReportDateSelector,
  CSVGeneratorPanel,
} from "../../components"
import * as actions from "../../redux/actions"
import { dayAmount, bookingAnalysis } from "../../util"
import { BookingStatus, paymentStatus } from "../../config/constant"
import { appConfig } from "../../config"
import { CheckoutReport as CheckoutReportPDF } from "../../config/pdfmake/template"
import api from "../../config/api"

function CheckoutReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [date, setDate] = useState(new Date())
  const [page, setPage] = useState(1)
  const size = 10
  const [isLoading, setIsLoading] = useState(false)
  const [orderByField, setOrderByField] = useState("")
  const [orderBy, setOrderBy] = useState("")
  const [excelExportOpen, setExcelExportOpen] = useState(false)

  const room = useSelector((state) => state.rooms)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const dispatch = useDispatch()

  useEffect(() => {
    setExcelExportOpen(false)
    dispatch(
      actions.getAllRoomsWithBooking({
        checkout: date ? dayjs(date).format("YYYY-MM-DD") : "",
        unwindBooking: "true",
        page,
        size,
        orderBy,
        orderByField,
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [date, page, orderBy, orderByField])

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "เลขที่กาารจอง",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.booking_no,
      sorter: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "bookings",
      render: (text, record) =>
        `  ${record?.bookings?.customer?.firstname} ${record?.bookings?.customer?.lastname}`,
    },
    {
      title: "เบอร์ห้อง",
      dataIndex: "name",
      sorter: true,
      render: (text, record) => record?.name,
    },
    {
      title: "วันที่เข้าพัก",
      dataIndex: "bookings",
      render: (text, record) =>
        `${dayjs(record?.bookings?.start).format("dd D/MM/YY HH:mm")} -
          ${dayjs(record?.bookings?.end).format("dd D/MM/YY HH:mm")}`,
    },
    {
      title: "จำนวนคืน",
      dataIndex: "bookings",
      render: (text, record) => `${dayAmount(record?.bookings?.start, record?.bookings?.end)} คืน`,
    },
    {
      title: "ราคาห้อง",
      dataIndex: "bookings",
      render: (text, record) => {
        const founedList = _.find(record?.bookings?.list, (each) => each?.room === record?._id)
        return founedList?.price * dayAmount(record?.bookings?.start, record?.bookings?.end)
      },
    },
    {
      title: "รายรับของห้อง",
      dataIndex: "bookings",
      render: (text, record) => {
        const founedList = _.filter(
          record?.bookings?.services,
          (each) => each?.room === record?._id,
        )
        const founedListPrice = _.map(
          founedList,
          (each) => (each?.price || 0) * (each?.amount || 0),
        )
        return _.sum(founedListPrice)
      },
    },
    {
      title: "วันที่เข้าพัก",
      dataIndex: "bookings",
      render: (text, record) =>
        record?.bookings?.checkin?.timestamp
          ? dayjs(record?.bookings?.checkin?.timestamp).format("dd D/MM/YY HH:mm")
          : "-",
    },
    {
      title: "วันที่ออก",
      dataIndex: "bookings",
      render: (text, record) =>
        record?.bookings?.status === BookingStatus.success ||
        record?.bookings?.status === BookingStatus.checkedOut
          ? "คืนห้องแล้ว"
          : "รอคืนห้อง",
    },
    {
      title: "สถานะการชำระเงิน",
      dataIndex: "bookings",
      render: (text, record) =>
        record?.bookings?.paymentStatus === paymentStatus.allPayment.allPaymentConfirm
          ? record?.bookings?.paymentStatus
          : `ค้างจ่าย ${record?.bookings?.price - (record?.bookings?.payment?.paid_amount || 0)}`,
    },
    {
      title: "บริการ",
      dataIndex: "bookings",
      render: (text, record) => {
        const founedList = _.filter(
          record?.bookings?.services,
          (each) => each?.room === record?._id,
        )
        return _.map(founedList, (each) => each?.service?.name)
      },
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderBy("asc")
    } else {
      setOrderBy("desc")
    }

    if (sorter?.field === "name") {
      setOrderByField("name")
    }
    if (sorter?.field === "bookings") {
      setOrderByField("bookings.booking_no")
    }
    if (sorter?.field === "type") {
      setOrderByField("type.translation.th.name")
    }
  }

  const fullQueryParams = new URLSearchParams({
    page: 1,
    size: appConfig.maxFetchSize,
    unwindBooking: true,
    checkout: dayjs(date).format("YYYY-MM-DD"),
    fetchBooking: true,
    orderBy,
    orderByField,
  })

  const handlePrintPDF = async () => {
    try {
      setIsLoading(false)
      api
        .get(`${process.env.REACT_APP_API_URL}/room/with-booking/?${fullQueryParams.toString()}`)
        .then((res) => {
          const ReportDefinition = CheckoutReportPDF(
            _.map(res?.data?.rows, (each) => ({
              ...each,
              bookings: bookingAnalysis(each.bookings),
            })),
            date,
            null,
            null,
            true,
            information,
            system,
          )
          console.log("ReportDefinition", ReportDefinition)
          pdfMake.createPdf(ReportDefinition).open()
        })
        .catch((error) => {
          alert(error?.message)
        })
        .finally(() => {
          setIsLoading(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout categoryTitle={t("history.title")} title={t("history.checkoutReport")}>
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            colorScheme="red"
            size="sm"
            leftIcon={<i className="fas fa-file-pdf"></i>}
            onClick={() => handlePrintPDF()}
          >
            PDF
          </Button>
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<i className="fas fa-file-excel"></i>}
            onClick={() => {
              if (excelExportOpen === true) {
                setExcelExportOpen(false)
                setTimeout(() => {
                  setExcelExportOpen(true)
                }, 500)
              } else {
                setExcelExportOpen(true)
              }
            }}
          >
            CSV
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <ReportDateSelector pointDate={date} setPointDate={setDate} dateOnly />
        <CSVGeneratorPanel
          apiSuffix="room/with-booking"
          columns={columns}
          open={excelExportOpen}
          queryParams={fullQueryParams}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={room?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: room?.total,
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default CheckoutReport
