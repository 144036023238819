import React from "react"

const SliderPddingVideoText = ({
  register,
  leftPaddingVideoText,
  rightPaddingVideoText,
  bottomPaddingVideoText,
  opacityBorder,
  watch,
}) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">ระยะกรอบด้านซ้าย</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={1000}
      name={rightPaddingVideoText}
      ref={register}
      className="slider"
    />
    <span>{watch(rightPaddingVideoText)}px</span>
    <label className="block text-sm font-medium text-gray-700">ระยะกรอบด้านขวา</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={2000}
      name={leftPaddingVideoText}
      ref={register}
      className="slider"
    />
    <span>{watch(leftPaddingVideoText)}px</span>
    <label className="block text-sm font-medium text-gray-700">ตำแหน่งข้อความในแนวตั้ง</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={800}
      name={bottomPaddingVideoText}
      ref={register}
      className="slider"
    />
    <span>{watch(bottomPaddingVideoText)}px</span>
    <label className="block text-sm font-medium text-gray-700">
      Opacity 
    </label>
    <input
      type="range"
      defaultValue={100}
      min={0}
      max={100}
      name={opacityBorder}
      ref={register}
      className="slider"
    />
    <span>{watch(opacityBorder)}%</span>

  </div>
)

export default SliderPddingVideoText
