import React from "react"

const SliderHeaderArea = ({ register, headerArea, watch }) => (
  <div>
    <label htmlFor="lengthSlider">พื้นที่หัวข้อ:</label>
    <input
      type="range"
      id="lengthSlider"
      defaultValue={200}
      min={1}
      max={300}
      name={headerArea}
      ref={register}
    />
    <span>{watch(headerArea)}px</span>
  </div>
)

export default SliderHeaderArea
