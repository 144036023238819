import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { Button } from "@chakra-ui/react"
import Select from "react-select"
import _ from "lodash"
import { DatePicker } from "antd"

import { OnlineBookingContext } from "../../contexts/OnlineBookingContext"

const { RangePicker } = DatePicker
const CheckinCheckoutSearchPanel = ({
  checkedInDate,
  setCheckedInDate,
  checkoutDate,
  setCheckoutDate,
  onSearch = () => {},
  roomTypes,
  setSelectedRoomType = () => {},
}) => {
  const { t, i18n } = useTranslation()
  const { color } = useContext(OnlineBookingContext)
  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full lg:w-2/4 flex justify-center my-2">
        <div className="px-1 w-full">
          <div className="text-lg font-semibold">{t("reservation.roomSearch")}</div>
          <div className="px-1 w-full my-2">
            <h5 className="font-semibold text-sm">{t("reservation.checkinDate")}</h5>
            <DatePicker
              className="my-2"
              onChange={(date) => setCheckedInDate(dayjs(date).toDate())}
              value={dayjs(checkedInDate)}
              format={"D/MM/YYYY"}
              locale={i18n.language}
              size="large"
              style={{ width: "100%" }}
            />
          </div>
          <div className="px-1 w-full my-2">
            <h5 className="font-semibold text-sm">{t("reservation.checkoutDate")}</h5>
            <DatePicker
              onChange={(date) => setCheckoutDate(dayjs(date).toDate())}
              value={dayjs(checkoutDate)}
              className="my-2"
              disabledDate={(current) =>
                // Can not select days before today and today
                current && current < dayjs(checkedInDate).endOf("day")
              }
              format={"D/MM/YYYY"}
              locale={i18n.language}
              size="large"
              style={{ width: "100%" }}
            />
          </div>
          <div className="my-2 px-1">
            {t("general.amount")}{" "}
            {dayjs(dayjs(checkoutDate).startOf("day")).diff(
              dayjs(checkedInDate).startOf("day"),
              "day",
            )}{" "}
            {t("roomtype.night")}
          </div>
          <div className="my-4 w-full flex justify-center">
            <Button className="my-1" colorScheme={color} onClick={onSearch} isFullWidth>
              {t("general.search")}
            </Button>
          </div>
        </div>
        {/* <div className="w-full flex justify-center gap-2">
          <div className="w-full lg:w-1/2">
            <h5 className="my-2">{t("roomtype.selectCategory")}</h5>
            <Select
              options={_.map(roomTypes?.arr, (each) => ({
                value: each?._id,
                label: each?.translation?.[i18n.language]?.name,
              }))}
              isClearable
              onChange={(e) => setSelectedRoomType(e)}
            />{" "}
          </div>{" "}
        </div> */}
      </div>
    </div>
  )
}

export default CheckinCheckoutSearchPanel
