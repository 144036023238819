import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"

// components
import { Button, Checkbox, Alert } from "@chakra-ui/react"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import Layout from "../../components/layout/GeneralLayout"

export default function ManagementSystem() {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const systemInfo = useSelector((state) => state.system)
  const { handleSubmit, register } = useForm()

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  const onEditOuterService = (data) => {
    const confirm = window.confirm("ยืนยันการเปลี่ยนแปลงข้อมูลการตั้งค่า")
    if (confirm) {
      dispatch(actions.systemInfoPut(data)).then(() => {
        dispatch(actions.systemInfoGet())
        window.alert("แก้ไขข้อมูลเสร็จเรียบร้อย")
      })
    }
  }

  return isLoading ? (
    <Layout categoryTitle="จัดการระบบ" title="จัดการการเชื่อมต่อซอฟต์แวร์ภายนอก">
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="w-full p-4 ">
        <Alert className="mb-4 rounded-md text-sm" colorScheme="yellow">
          <i className="fas fa-info-circle mr-2" /> หน้านี้มีข้อมูลทางด้านเทคนิค
          ถ้าท่านไม่แน่ใจกรุณาติดต่อผู้พัฒนา หรือ ผู้ดูแลระบบ
        </Alert>
        <form onSubmit={handleSubmit(onEditOuterService)}>
          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
            <div className="w-full px-4 ">
              <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
                การเชื่อมต่ออีเมล
                <p className="text-xs font-normal">Powered By Node Mailer / ZeptoMail</p>
              </h6>
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Mail Server Host
                  </label>
                  <input
                    name="email.host"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.email?.host}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="smtp.email.com"
                  />
                </div>

                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Server Port
                  </label>
                  <input
                    name="email.port"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.email?.port}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="587"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Sender Mail Titile
                  </label>
                  <input
                    name="email.support_mail_title"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.email?.support_mail_title}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="E-Accom Support"
                  />
                </div>{" "}
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Sender Email Address
                  </label>
                  <input
                    name="email.support_mail"
                    type="email"
                    ref={register}
                    defaultValue={systemInfo?.email?.support_mail}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="support@eaccom.net"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Server Username
                  </label>
                  <input
                    name="email.user"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.email?.user}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="myuser"
                  />
                </div>
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Server Passcode
                  </label>
                  <input
                    name="email.password"
                    type="password"
                    ref={register}
                    defaultValue={systemInfo?.email?.password}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="*****"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <Checkbox
                  name="email.is_secure"
                  ref={register}
                  size="sm"
                  defaultChecked={systemInfo?.email?.is_secure}
                >
                  Secure Conncetion
                </Checkbox>
              </div>
              <br />
            </div>
          </div>
          <br />

          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
            <div className="w-full px-4 ">
              <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
                การเชื่อมต่อ SMS
                <p className="text-xs font-normal">Powered By ThaiBulkSMS</p>
              </h6>

              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    API Key{" "}
                  </label>
                  <input
                    name="sms.apikey"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.sms?.apikey}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Your API Key"
                  />
                </div>
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    API Secret
                  </label>
                  <input
                    name="sms.secret"
                    type="password"
                    ref={register}
                    defaultValue={systemInfo?.sms?.secret}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Your API Secret"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
            <div className="w-full px-4 ">
              <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
                SSH Server Connection
                <p className="text-xs font-normal"> For Building Frontpage </p>
              </h6>

              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Server URL
                  </label>
                  <input
                    name="ssh.serverURL"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.ssh?.serverURL}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Your Server URL"
                  />
                </div>
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    Working Directory Location
                  </label>
                  <input
                    name="ssh.serverDirectory"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.ssh?.serverDirectory}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Your SSH Server Directory"
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    API Server
                  </label>
                  <input
                    name="mainConfig.apiServer"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.mainConfig?.apiServer}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Your API Server"
                  />
                </div>
                <div className="relative w-full mb-3 ">
                  <label
                    className="block  text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    API Key
                  </label>
                  <input
                    name="mainConfig.apiKey"
                    type="text"
                    ref={register}
                    defaultValue={systemInfo?.mainConfig?.apiKey}
                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Your API Key"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทีก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  ) : (
    <SpinnerLoading />
  )
}
