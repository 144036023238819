import { BILL_ALL, BILL_CREATE, BILL_DELETE, BILL_GET, BILL_UPDATE, BILL_ERROR } from "../types"

import api from "../../../config/api"
import GeneralReduxActionClass from "../class/GeneralReduxAction"

const billActions = new GeneralReduxActionClass({
  allConst: BILL_ALL,
  createConst: BILL_CREATE,
  deleteConst: BILL_DELETE,
  errorConst: BILL_ERROR,
  getConst: BILL_GET,
  putConst: BILL_UPDATE,
  backendPrefix: "bill",
  description: "บิล",
})

export const getAllBills = (query) => billActions.getAll(query)
export const getOneBill = (id) => billActions.getOne(id)
export const createOneBill = (payload) => billActions.createOne(payload)
export const editOneBill = (id, payload) => billActions.editOne(id, payload)
export const deleteOneBill = (id) => billActions.deleteOne(id)

export const getCurrentBill = () => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/bill/current`)
    .then((res) => {
      if (res.data) {
        dispatch({ type: BILL_GET, payload: res.data })
      } else {
        dispatch({ type: BILL_ERROR, payload: null })
      }
    })
    .catch((err) => {
      dispatch({ type: BILL_ERROR, payload: null })

      throw new Error(`ไม่สามารถดึงข้อมูลบิลมาได้  ${err?.response?.data?.error?.message}`)
    })
}
