export const ComponentDistance = {
    NON_DISTANCE: {
        status_code: "NON_DISTANCE",
        description: "ชิดกัน",
        descriptionEn: "close together",
        tailwind: "pt-0 pb-0",
    },
    FOUR_APART_DISTANCE: {
        status_code: "FOUR_APART_DISTANCE",
        description: "ห่างกัน 4 px",
        descriptionEn: "4px apart",
        tailwind: "lg:pt-4 lg:pb-4 md:pb-20 md:pt-20 pb-4 pt-4",
    },
    EIGHT_DISTANCE: {
        status_code: "EIGHT_DISTANCE",
        description: "ห่างกัน 8 px",
        descriptionEn: "8 pixels apart",
        tailwind: "pt-8 pb-8",
    },
    SIXTEEN_DISTANCE: {
        status_code: "SIXTEEN_DISTANCE",
        description: "ห่างกัน 16 px",
        descriptionEn: "16 pixels apart",
        tailwind: "pt-16 pb-16",
    },
    TWENTY_DISTANCE: {
        status_code: "TWENTY_DISTANCE",
        description: "ห่างกัน 20 px",
        descriptionEn: "20 pixels apart",
        tailwind: "pt-20 pb-20",
    },
    FOURTY_DISTANCE: {
        status_code: "FOURTY_DISTANCE",
        description: "ห่างกัน 40 px",
        descriptionEn: "40 pixels apart",
        tailwind: "pt-40 pb-40",
    },
    SIXTY_DISTANCE: {
        status_code: "SIXTY_DISTANCE",
        description: "ห่างกัน 60 px",
        descriptionEn: "60 pixels apart",
        tailwind: "pt-60 pb-60",
    },
  }
  
  export default ComponentDistance