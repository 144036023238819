import React from "react"
import { useTranslation } from "react-i18next"
import {} from "antd"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export default function FrontpageHeroExample({ info }) {
  const { i18n } = useTranslation()
  return (
    <div>
      <div className="w-full relative font-sans max-h-100">
        <div className="w-full h-full bg-blue-500 top-0 left-0 absolute opacity-50 max-h-1/3 lg:max-h-1/3" />
        <div className="w-full h-full top-0 left-0 absolute opacity-50 max-h-1/2 lg:max-h-2/3">
          <img src={info.cover?.url} alt="" className="opacity-90 cover-fill" />
        </div>{" "}
        <div className="flex justify-end z-100">
          <Link to="/site/cover-image">
            <Button size="sm" className="m-2">
              แก้ไขรูปภาพ
            </Button>
          </Link>
        </div>
        <div className="w-full h-full flex flex-col absolute justify-center text-center pt-20 ">
          <div className="ring-white ring-4 p-8 mx-10 md:mx-20">
            <div className="text-xl md:text-3xl lg:text-4xl font-bold text-white text-center uppercase">
              {info?.composite?.translation?.[i18n.language]?.page_header || "หัวข้อหลัก"}{" "}
              <Link to="/site/page-description">
                <Button size="sm" className="m-2" textColor="black">
                  แก้ไข
                </Button>
              </Link>
            </div>
            <div className="text-lg md:text-2xl lg:text-3xl text-white text-center pt-2">
              {info?.composite?.translation?.[i18n.language]?.page_brief || "หัวข้อรอง"}
            </div>
          </div>
          <div className="mx-auto pt-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10 text-white animate-bounce"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        <div style={{ height: 550 }}></div>
      </div>
    </div>
  )
}
