export const MainMenuType = {
  SINGLE: {
    status_code: "SINGLE",
    description: "เมนูระดับเดียว",
  },
  DOUBLE: {
    status_code: "DOUBLE",
    description: "เมนูสองระดับ",
  },
}

export default MainMenuType
