/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import _ from "lodash"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { Input, Table } from "antd"

import Layout from "../../components/layout/GeneralLayout"
import { Alert, Confirm } from "../../components/common/Alert"

import * as actions from "../../redux/actions"

export default function MenuManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState()
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [size, setSize] = useState(10)
  const menu = useSelector((state) => state.menu)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const getAllMenu = () => {
    dispatch(
      actions.getAllMenu({
        page,
        size: 10,
        query: { name },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }
  const handleDelete = async () => {
    try {
      await dispatch(actions.deleteOneMenu(selectedMenu)).then(() => {
        getAllMenu()
      })
    } catch (error) {
      setAlertMessage(error?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      setIsAlertOpen(true)
    } finally {
      setIsConfirmOpen(false)
    }
  }

  useEffect(() => {
    setIsLoading(false)
    getAllMenu()
    return () => {}
  }, [page, name])

  const handleToggleMenuStatus = (id, currentStatus) => {
    dispatch(actions.editOneMenu(id, { enable: !currentStatus }))
      .then(() => {
        getAllMenu()
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  const toggleRunOutOffStatus = (id, currentStatus) => {
    dispatch(actions.editOneMenu(id, { runOutOff: !currentStatus }))
      .then(() => {
        getAllMenu()
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  const renderSetting = () => (
    <div className="flex justify-between flex-wrap px-4">
      <div className="flex gap-2">
        <Link to="/food-beverage/menu/create">
          <Button colorScheme="purple" variant="solid">
            {t("general.add")}
          </Button>
        </Link>{" "}
        <Link to="/food-beverage/menu/create-many">
          <Button colorScheme="teal" variant="solid">
            เพิ่มหลายเมนู
          </Button>
        </Link>
      </div>
      <div className="w-full md:w-1/4  self-center mt-2">
        <Input
          addonBefore={<i className="fas fa-search"></i>}
          onChange={(e) => setSearchWord(e.target.value)}
        />
      </div>
    </div>
  )

  const renderDeleteConfirm = () => (
    <Confirm
      title="ยืนยันการลบเมนู"
      isOpen={isConfirmOpen}
      body="ยืนยันการลบเมนูอาหาร"
      handleOnClose={() => setIsConfirmOpen(false)}
      handleSuccess={handleDelete}
    />
  )

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize

    setPage(newPage)
    setSize(pageSize)
    console.log("params", pagination, filters, sorter, extra)
  }

  const columns = [
    {
      title: "ลำดับที่",
      dataIndex: "list",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "ชื่อเมนู",
      dataIndex: "translation",
      render: (text, record) => (
        <div>
          {record?.translation?.th?.name}
          {record?.translation?.en?.name ? ` (${record?.translation?.en?.name})` : ""}
        </div>
      ),
    },
    {
      title: "รูป",
      dataIndex: "image",
      render: (text, record) => (
        <div>
          <img src={record?.image?.url} className="w-24" />
        </div>
      ),
    },
    {
      title: "หมวดหมู่",
      dataIndex: "category",
      render: (text, record) => (
        <div>
          {record?.category?.translation?.th?.name}
          {record?.category?.translation?.en?.name
            ? ` (${record?.category?.translation?.en?.name})`
            : ""}
        </div>
      ),
    },
    {
      title: "แสดงในรายการเมนู",
      dataIndex: "enable",
      render: (text, record) =>
        record?.enable ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>,
    },
    {
      title: "มีสินค้า",
      dataIndex: "runOutOff",
      render: (text, record) =>
        // eslint-disable-next-line no-nested-ternary
        record?.enable ? (
          record?.runOutOff ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-check"></i>
          )
        ) : (
          <div>-</div>
        ),
    },
    {
      title: "ราคา",
      render: (text, record) =>
        _.isEmpty(record?.options)
          ? record?.price
          : `${_.minBy(record?.options, "price")?.price} - ${
              _.maxBy(record?.options, "price")?.price
            }`,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <div className="flex gap-2">
          {/* eslint-disable-next-line no-underscore-dangle */}
          <Link to={`/food-beverage/menu/edit/${record?._id}`}>
            <Button colorScheme="yellow" size="sm">
              {t("general.edit")}
            </Button>
          </Link>
          {record?.enable ? (
            <Button
              size="sm"
              colorScheme="orange"
              onClick={() => {
                handleToggleMenuStatus(record?._id, record?.enable)
              }}
            >
              ไม่แสดง
            </Button>
          ) : (
            <Button
              size="sm"
              colorScheme="green"
              onClick={() => {
                handleToggleMenuStatus(record?._id, record?.enable)
              }}
            >
              แสดง
            </Button>
          )}{" "}
          {record?.enable &&
            (record?.runOutOff ? (
              <Button
                size="sm"
                colorScheme="green"
                onClick={() => {
                  toggleRunOutOffStatus(record?._id, record?.runOutOff)
                }}
              >
                มีสินค้า
              </Button>
            ) : (
              <Button
                size="sm"
                colorScheme="pink"
                onClick={() => {
                  toggleRunOutOffStatus(record?._id, record?.runOutOff)
                }}
              >
                หมด
              </Button>
            ))}
          <Button
            size="sm"
            colorScheme="red"
            onClick={() => {
              setSelectedMenu(record?._id)
              setIsConfirmOpen(true)
            }}
          >
            {t("general.delete")}
          </Button>
        </div>
      ),
    },
  ]

  const renderTable = () => (
    <Table
      columns={columns}
      dataSource={menu?.arr}
      onChange={onChange}
      pagination={{
        current: page,
        pageSize: size,
        total: menu?.total,
      }}
      scroll={{
        x: "true",
      }}
    />
  )

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="เมนูอาหาร">
      {renderAlert()}
      {renderDeleteConfirm()}
      {renderSetting()}

      <div className="p-4 w-full overflow-x-auto">{renderTable()}</div>
    </Layout>
  )
}
