import React, { useState, useEffect } from "react"
import _ from "lodash"
import { CSVLink } from "react-csv"
import { Button, Spinner } from "@chakra-ui/react"
import dayjs from "dayjs"

import api from "../../config/api"
import { bookingAnalysis, arrayBookingAnalysis } from "../../util"

function CSVGeneratorPanel({ columns, apiSuffix, open, handleClose, queryParams }) {
  const [isLoading, setIsLoading] = useState(false)
  const [listData, setListData] = useState([])

  const generateCSVData = (roomListData) => {
    try {
      const tableHead = [_.map(columns, (each) => each.title)]
      console.log("Room List Data", roomListData)
      const tableData = _.map(roomListData, (eachData, index) => [
        ..._.map(columns, (eachColumn) => eachColumn.render({}, eachData, index)),
      ])

      const resultData = _.concat(tableHead, tableData)
      return resultData
    } catch (error) {
      console.error(error)
      return []
    }
  }

  useEffect(() => {
    if (open) {
      api
        .get(`${process.env.REACT_APP_API_URL}/${apiSuffix}?${queryParams.toString()}`)
        .then((res) => {
          if (_.startsWith(apiSuffix, "booking")) {
            setListData(arrayBookingAnalysis(res?.data?.rows))
          } else {
            setListData(
              _.map(res?.data?.rows, (each) => ({
                ...each,
                bookings: _.isArray(each?.bookings)
                  ? arrayBookingAnalysis(each?.bookings)
                  : bookingAnalysis(each?.bookings),
              })),
            )
          }

          setIsLoading(true)
        })
        .catch((error) => {
          alert(error?.message)
        })
    }

    return () => {}
  }, [open])
  if (!open) {
    return <div></div>
  }
  return (
    <div>
      {isLoading ? (
        <div>
          <div>ไฟล์ CSV พร้อมสำหรับการดาวน์โหลด</div>
          <CSVLink
            data={generateCSVData(listData)}
            filename={`${_.upperCase(apiSuffix)}-${dayjs().format(`YYYYMMDDHHmm`)}.csv`}
          >
            <Button className="mx-2 my-1 self-center" colorScheme="green" size="sm" variant="solid">
              Download{" "}
            </Button>
          </CSVLink>
        </div>
      ) : (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}
    </div>
  )
}

export default CSVGeneratorPanel
