/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { dayAmount } from "../../../util"
import { CountryList } from "../../constant"

dayjs.extend(buddhistEra)

export default function CheckInReport(
  transactions,
  date,
  startDate,
  endDate,
  isPointDate,
  information,
  systemInfo,
) {
  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายงานการเช็คอิน",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายงานการเช็คอิน /Checkin Report ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      isPointDate
        ? {
            text: [
              { text: "วันที่", bold: true },
              {
                text: `  ${dayjs(date).format("D MMMM BBBB")}  (วันที่ ${dayjs(date).format(
                  "D MMMM BBBB",
                )} เวลา 12.01 - วันที่ ${dayjs(date)
                  .add(1, "day")
                  .format("D MMMM BBBB")} เวลา 11.59 )`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          }
        : {
            text: [
              { text: "จากวันที่", bold: true },
              {
                text: `  ${dayjs(startDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
              { text: "ถึงวันที่", bold: true },
              {
                text: `  ${dayjs(endDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, 40, "*", 60, 40, 80, 40, 50, 50, 30, 70, 60],
          body: [
            [
              { text: "ลำดับที่", bold: true },
              { text: "เลขที่การจอง", bold: true },
              { text: "ชื่อ", bold: true },
              { text: "โทรศัพท์", bold: true },
              { text: "สัญชาติ", bold: true },
              { text: "เลขบัตรประชาชน\nหนังสือเดินทาง", bold: true },
              { text: "หมายเลขห้อง", bold: true },
              { text: "วันที่เข้าพัก", bold: true },
              { text: "วันที่ออก", bold: true },
              { text: "จำนวน\nคืน", bold: true },
              { text: "เวลาเช็คอิน", bold: true },
              { text: "สถานะ", bold: true },
            ],
            ..._.map(transactions, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${eachList?.bookings?.booking_no || ""}`,
                margin: [0, 2],
              },
              {
                text: `${eachList?.bookings?.customer?.firstname || "-"} ${
                  eachList?.bookings?.customer?.lastname || ""
                }`,
              },
              {
                text: eachList?.bookings?.customer?.tel || "",
              },
              {
                text:
                  _.find(
                    CountryList,
                    (each) => each?.value === eachList?.bookings?.customer?.nationality,
                  )?.thaiName || "",
              },
              {
                text: eachList?.bookings?.checkin?.id_card || "",
              },
              {
                text: eachList?.name,
              },
              {
                text: dayjs(eachList?.bookings?.start).format("D MMM BB"),
              },
              {
                text: dayjs(eachList?.bookings?.end).format("D MMM BB"),
              },

              {
                text: dayAmount(eachList?.bookings?.start, eachList?.bookings?.end),
              },
              {
                text: eachList?.bookings?.checkin?.timestamp
                  ? dayjs(eachList?.bookings?.checkin?.timestamp).format("D MMM BB HH:mm")
                  : "ยังไม่เช็คอิน",
              },
              {
                text: eachList?.bookings?.paymentStatus || "",
                fontSize: 9,
              },
            ]),
            // [
            //   { text: "รวม", colSpan: 10, bold: true },
            //   {},
            //   {},
            //   {},
            //   {},
            //   {},
            //   {},
            //   {},
            //   {},
            //   {},
            //   { text: currencyFormatter.format(_.sum(allPriceAmount)), colSpan: 2 },
            //   {},
            //   {
            //     text: currencyFormatter.format(_.sum(allPaidAmount)),
            //     colSpan: 2,
            //   },
            //   {},
            //   { text: "" },
            // ],
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
