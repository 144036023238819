/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams, Link } from "react-router-dom"
import { Table } from "antd"
import { useForm } from "react-hook-form"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"
import dayjs from "dayjs"

import {
  GeneralMainLayout as Layout,
  SpinnerLoading,
  WhitePanel,
  Dot,
  Alert,
  FBListForm,
  Confirm,
} from "../../components"
import * as actions from "../../redux/actions"
import { appConfig as config } from "../../config"
import { FoodOrderMiniSlip, FBOrderSlip, FBOrderBillSlip } from "../../config/pdfmake/template"

export default function DetailFBOrder() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const fbOrder = useSelector((state) => state.fbOrder)
  const fbList = useSelector((state) => state.fbList)
  const menu = useSelector((state) => state.menu)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(100)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [selectedFBList, setSelectedFBList] = useState()
  const [onConfirmOpen, setIsConfirmOpen] = useState(false)

  const { handleSubmit, control, watch, setValue } = useForm({
    menuObject: {},
  })

  const findFBList = () => {
    dispatch(
      actions.getAllFBOrderList({
        page,
        size,
        query: {
          order: params.id,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  useEffect(() => {
    dispatch(actions.getOneFBOrder(params.id))
      .then(() => {
        dispatch(actions.getAllMenu({ page: 1, size: config.maxFetchSize }))
        findFBList()
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
      })
    return () => {}
  }, [params])

  const handleOnDelete = () => {
    dispatch(actions.deleteOneFBOrderList(selectedFBList))
      .then(() => {
        setIsLoading(false)
        dispatch(actions.getOneFBOrder(params.id))
        findFBList()
        setIsConfirmOpen(false)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  const handleCreateFBList = (data) => {
    const payload = {
      ...data,
      order: params.id,
      table: fbOrder?.table?._id,
    }
    dispatch(actions.createOneFBOrderList(payload))
      .then(() => {
        setIsLoading(false)
        dispatch(actions.getOneFBOrder(params.id))
        findFBList()
      })
      .then(() => {})
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
      })
  }

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize
    setPage(newPage)
    setSize(pageSize)
  }

  const handleSuccess = () => {
    const confirm = window.confirm("ยืนยันการสำเร็จรายการ")
    if (confirm) {
      dispatch(actions.editOneFBOrder(params.id, { completed: true }))
        .then(() => {
          setIsLoading(false)
          dispatch(actions.getOneFBOrder(params.id))
          findFBList()
        })
        .then(() => {})
        .catch((err) => {
          setIsError(true)
          setErrMessage(err?.message || "มีความผิดพลาดเกิดขึ้น")
        })
    }
  }

  const handleCompleteEachFBList = (id) => {
    dispatch(actions.editOneFBOrderList(id, { completed: true })).then(() => {
      findFBList()
    })
  }

  const handleRecieveFBList = (id) => {
    dispatch(actions.editOneFBOrderList(id, { recieved: true })).then(() => {
      findFBList()
    })
  }

  const handleMiniSlipPrinting = (record) => {
    const ReportDefinition = FoodOrderMiniSlip(fbOrder, record, information, system)
    pdfMake.createPdf(ReportDefinition).open()
  }

  const handlePrintSlip = () => {
    const ReportDefinition = FBOrderSlip(fbOrder, fbList?.arr, information, system)
    pdfMake.createPdf(ReportDefinition).open()
  }

  const handlePrintBill = () => {
    const ReportDefinition = FBOrderBillSlip(fbOrder, fbList?.arr, information, system)
    pdfMake.createPdf(ReportDefinition).open()
  }

  const columns = [
    {
      title: "ลำดับ",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "รายการ",
      dataIndex: "menu",
      render: (text, record) => (
        <div>
          {record?.menu?.translation?.[i18n.language]?.name}
          {record?.option && <div className="text-sm">ตัวเลือก: {record?.option}</div>}
          {!_.isEmpty(record?.toppings) && (
            <div className="text-sm">เพิ่ม: {_.map(record?.toppings, (each) => each)}</div>
          )}
          {record?.remark && (
            <div className="text-sm">
              <b>หมายเหตุ:</b> {record?.remark}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "จำนวน",
      dataIndex: "quantity",
      render: (text, record) => <div>{record?.quantity}</div>,
    },
    {
      title: "ราคาต่อหน่วย",
      dataIndex: "price",
      render: (text, record) => <div>{record?.price}</div>,
    },
    {
      title: "ราคารวม",
      dataIndex: "price",
      render: (text, record) => <div>{record?.price * record?.quantity}</div>,
    },
    {
      title: "เสร็จแล้ว",
      dataIndex: "completed",
      render: (text, record) => (
        <div>
          {record?.completed ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}
        </div>
      ),
    },
    {
      title: "เวลาที่สั่ง",
      dataIndex: "createdAt",
      render: (text, record) => <div>{dayjs(record?.createdAt).format("HH:mm")}</div>,
    },
    {
      title: "ดำเนินการ",
      dataIndex: "action",
      render: (text, record) => (
        <div className="flex gap-2">
          {" "}
          <Button
            size="sm"
            colorScheme="blue"
            leftIcon={<i className="fas fa-print"></i>}
            onClick={() => {
              handleMiniSlipPrinting(record)
            }}
          >
            สลิป
          </Button>
          <Button
            size="sm"
            colorScheme="purple"
            leftIcon={
              record?.recieved ? (
                <i className="fas fa-check"></i>
              ) : (
                <i className="fas fa-download" />
              )
            }
            onClick={() => {
              handleRecieveFBList(record?._id)
            }}
            disabled={record?.recieved}
          >
            รับทราบ
          </Button>
          <Button
            size="sm"
            colorScheme="green"
            leftIcon={
              record?.completed ? (
                <i className="fas fa-check"></i>
              ) : (
                <i className="fas fa-concierge-bell" />
              )
            }
            onClick={() => {
              handleCompleteEachFBList(record?._id)
            }}
            disabled={record?.completed}
          >
            เสร็จ
          </Button>
          <Button
            size="sm"
            colorScheme="red"
            leftIcon={<i className="fas fa-trash"></i>}
            onClick={() => {
              setSelectedFBList(record?._id)
              setIsConfirmOpen(true)
            }}
          >
            ลบ
          </Button>
        </div>
      ),
    },
  ]

  if (!isLoading)
    return (
      <div>
        <SpinnerLoading />
      </div>
    )

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="รายการอาหารและเครื่องดื่ม">
      <Alert errMessage={errMessage} handleOnClose={() => setIsError(false)} isError={isError} />
      <Confirm
        isOpen={onConfirmOpen}
        handleSuccess={() => handleOnDelete()}
        handleOnClose={() => {
          setIsConfirmOpen(false)
        }}
        title="ยืนยันการลบรายการ"
      />
      <div className="px-4 flex justify-between">
        <Button
          variant="outline"
          colorScheme="purple"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            variant="solid"
            disabled={fbOrder?.completed}
            colorScheme="green"
            onClick={handleSuccess}
          >
            สำเร็จ
          </Button>
          <Link to={`/food-beverage/order/to-booking/${params.id}`}>
            <Button variant="solid" disabled={fbOrder?.completed} colorScheme="purple">
              ลงบิลห้องพัก
            </Button>
          </Link>
        </div>
      </div>
      <div className="p-4">
        <WhitePanel>
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full lg:w-1/2">
              <div>เลขที่บิล</div>
              <div className="text-lg font-semibold">{fbOrder?.bill_number}</div>
            </div>
            <div className="w-1/2 lg:w-1/4">
              <div>โต๊ะ</div>
              <div className="text-lg font-semibold">{fbOrder?.table?.name}</div>
            </div>
            <div className="w-1/2 lg:w-1/4">
              <div>รายการจองห้อง</div>
              <div className="text-lg font-semibold">{fbOrder?.booking?.booking_no || "-"}</div>
            </div>
            <div className="w-full lg:w-1/4">
              <div>สถานะ</div>
              <div className="text-lg font-semibold">
                {fbOrder?.completed ? (
                  <Dot color="green" word="สำเร็จแล้ว" />
                ) : (
                  <Dot color="yellow" word="ดำเนินการอยู่" />
                )}
              </div>
            </div>{" "}
            <div className="w-1/2 lg:w-1/2">
              <div>ราคารวม</div>
              <div className="text-lg font-semibold">
                {fbOrder?.price || 0} {t("general.baht")}{" "}
              </div>
            </div>
          </div>
          <div className="my-2">
            <div>เอกสาร</div>
            <div className="flex gap-2">
              <Button
                variant="solid"
                colorScheme="blue"
                onClick={() => handlePrintSlip()}
                size="sm"
                leftIcon={<i className="fas fa-print"></i>}
              >
                สลิปรายการอาหาร
              </Button>{" "}
              <Button
                variant="solid"
                colorScheme="blue"
                onClick={() => handlePrintBill()}
                size="sm"
                leftIcon={<i className="fas fa-print"></i>}
              >
                บิลค่าอาหาร
              </Button>
            </div>
          </div>
        </WhitePanel>
        <Table columns={columns} dataSource={fbList?.arr} onChange={onChange} />
        <div className="my-2">
          <WhitePanel>
            <FBListForm control={control} menu={menu} watch={watch} setValue={setValue} />
            <div className="flex justify-end my-2">
              <Button colorScheme="purple" onClick={handleSubmit(handleCreateFBList)}>
                บันทึก
              </Button>
            </div>
          </WhitePanel>
        </div>
      </div>
    </Layout>
  )
}
