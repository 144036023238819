import React from "react"
import { useSelector } from "react-redux"
import Info from "../../../version.json"
import BottomMenu from "./BottomMenu"

export default function Footer() {
  const system = useSelector((state) => state.system)

  if (system && system.isLoading === true)
    return (
      <div>
        <BottomMenu />
        <footer className=" bg-gray-300 pt-4 pb-4">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div className="w-full md:w-6/12 px-4 mx-auto text-center">
                <div className="text-sm text-gray-600 font-semibold py-1">
                  Copyright © {new Date().getFullYear()} Prince of Songkla University by{" "}
                  <a href="https://iarc.psu.ac.th/" className="text-gray-600 hover:text-gray-900">
                    Intelligent Automation Research Center
                  </a>
                  <br />
                  {system?.systemSponsor && (
                    <span>
                      Suported By{" "}
                      <a
                        href={system.systemSponsorURL}
                        className="text-gray-600 hover:text-gray-900"
                      >
                        {system.systemSponsor}
                      </a>{" "}
                    </span>
                  )}
                  <br />
                  Ver {Info.version}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )

  return <></>
}
