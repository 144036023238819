import React from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

export default function EditCheckoutDateButton({ bookingInfo }) {
  const { t } = useTranslation()
  const history = useHistory()
  const handleChangeToCheckinStatus = () => {
    history.push(`/history/checkout-date/edit/${bookingInfo?.id}`)
  }

  return (
    <div>
      <Button size="sm" colorScheme="blue" onClick={() => handleChangeToCheckinStatus()}>
        {t("reservation.editCheckoutDate")}
      </Button>
    </div>
  )
}
