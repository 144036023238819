import _ from "lodash"
import React from "react"
import { Form, Input, Switch } from "antd"
import { Badge } from "@chakra-ui/react"
import { Controller } from "react-hook-form"
import Flags from "country-flag-icons/react/3x2"
import { FrontepageMenuList } from "../../../config/constant"

function ExpandedMenuForm({ control, selectedIndex, defaultValue }) {
  return (
    <div>
      {_.map(FrontepageMenuList, (eachField, index) => (
        <div key={index} className="my-2 p-4 border rounded-md">
          <div className="flex flex-wrap justify-between">
            <div className="font-semibold">
              {eachField.description}
              <Badge size="sm" className="ml-2">
                {eachField.link}{" "}
              </Badge>{" "}
            </div>
            <div>
              <Controller
                control={control}
                name={`menu.menuList[${selectedIndex}].expandedMenu[${index}].enable`}
                defaultValue={
                  defaultValue?.menu?.menuList?.[selectedIndex]?.expandedMenu?.[index]?.enable ||
                  eachField.enable
                }
                render={(field) => (
                  <div className="flex gap-2">
                    <Switch {...field} defaultChecked={field.value} />
                    เปิดใช้งาน
                  </div>
                )}
              />
            </div>
          </div>
          <Form layout="inline">
            <Form.Item label="ชื่อภาษาไทย">
              <Controller
                control={control}
                name={`menu.menuList[${selectedIndex}].expandedMenu[${index}].translation.th.title`}
                defaultValue={
                  defaultValue?.menu?.menuList?.[selectedIndex]?.expandedMenu?.[index]?.translation
                    ?.th?.title || eachField.description
                }
                render={(field) => (
                  <Input
                    placeholder="ภาษาไทย"
                    addonBefore={<Flags.TH title="Thai" className="w-5" />}
                    {...field}
                  />
                )}
              />
            </Form.Item>
            <Form.Item label="ชื่อภาษาอังกฤษ">
              <Controller
                control={control}
                name={`menu.menuList[${selectedIndex}].expandedMenu[${index}].translation.en.title`}
                defaultValue={
                  defaultValue?.menu?.menuList?.[selectedIndex]?.expandedMenu?.[index]?.transation
                    ?.en?.title || eachField.description
                }
                render={(field) => (
                  <Input
                    placeholder="English"
                    addonBefore={<Flags.GB title="English" className="w-5" />}
                    {...field}
                  />
                )}
              />
            </Form.Item>
            <Form.Item label="ลิงค์">
              <Controller
                control={control}
                name={`menu.menuList[${selectedIndex}].expandedMenu[${index}].link`}
                defaultValue={eachField.link}
                render={(field) => <Input placeholder="Link" {...field} readOnly />}
              />
            </Form.Item>
            {eachField.useOtherLink && (
              <Form.Item label="ลิงค์ภายนอก">
                <Controller
                  control={control}
                  name={`menu.menuList[${selectedIndex}].expandedMenu[${index}].otherLink`}
                  defaultValue={
                    defaultValue?.menu?.menuList?.[selectedIndex]?.expandedMenu?.[index]
                      ?.otherLink || eachField.otherLink
                  }
                  render={(field) => <Input {...field} />}
                />
              </Form.Item>
            )}
          </Form>
        </div>
      ))}
    </div>
  )
}

export default ExpandedMenuForm
