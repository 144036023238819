import React, { forwardRef } from "react"
import { Button, FormControl, FormLabel, Switch, Input } from "@chakra-ui/react"
import _ from "lodash"
import { useFieldArray } from "react-hook-form"

import { TextField } from "../../common/Input"

const CustomizeSettingForm = ({ register, defaultValue, control }) => {
  const { fields, append, remove } = useFieldArray({ control, name: "setting.timeshifts" })
  return (
    <div>
      <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
        <div className="w-full lg:w-1/2 px-4 ">
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            ตั้งค่าวิธีการจอง
            <p className="text-xs font-normal">กระบวนการพิเศษต่างๆ เกี่ยวกับการจัดการการจอง</p>
          </h6>
          {/* <div className="my-2">
            <FormControl display="flex">
              <FormLabel>อนุญาติให้จองรายชั่วโมง</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.enableHourlyBooking}
                ref={register}
                name="setting.enableHourlyBooking"
                colorScheme="purple"
              />
            </FormControl>
          </div> */}
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>อนุญาติให้จองไม่ระบุห้อง</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.enablePreBooking}
                ref={register}
                name="setting.enablePreBooking"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ตั้งค่าการจองแบบไม่ระบุห้องให้เป็นค่าเริ่มต้น</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.setPreBookingAsDefault}
                ref={register}
                name="setting.setPreBookingAsDefault"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <br />
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            ขนาดกระดาษสลิป
            <p className="text-xs font-normal">ตั้งค่าขนาดกระดาษสำหรับการพิมพ์สลิป</p>
          </h6>
          <div className="flex gap-2">
            <TextField
              label="กว้าง (mm.)"
              defaultValue={defaultValue?.setting?.paperSize?.weight}
              name="setting.paperSize.weight"
              placeholder="ความกว้าง"
              type="number"
              register={register}
            />
            <TextField
              label="ยาว (mm.)"
              defaultValue={defaultValue?.setting?.paperSize?.height}
              name="setting.paperSize.height"
              placeholder="ความยาว"
              type="number"
              register={register}
            />{" "}
          </div>
          <br />
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            กะของการทำงาน
            <p className="text-xs font-normal">
              ตั้งค่ากะของการทำงานเพื่อที่จะรวบรวมสรุปยอดของแต่ละกะเวลา
            </p>
          </h6>
          {_.map(fields, (eachField, index) => (
            <div key={eachField.id} className="flex gap-2 my-2">
              <FormControl>
                <FormLabel fontSize="sm">ชื่อกะ</FormLabel>
                <Input
                  placeholder="ชื่อกะ"
                  size="sm"
                  defaultValue={eachField?.title}
                  name={`setting.timeshifts[${index}].title`}
                  ref={register}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">เวลาเริ่ม</FormLabel>
                <div className="flex">
                  <Input
                    placeholder="ชม."
                    size="sm"
                    type="number"
                    max={24}
                    min={0}
                    defaultValue={eachField?.startTime?.hour}
                    name={`setting.timeshifts[${index}].startTime.hour`}
                    ref={register}
                  />
                  <Input
                    placeholder="นาที"
                    size="sm"
                    type="number"
                    max={60}
                    min={0}
                    defaultValue={eachField?.startTime?.minute}
                    name={`setting.timeshifts[${index}].startTime.minute`}
                    ref={register}
                  />
                </div>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">เวลาสิ้นสุด</FormLabel>
                <div className="flex">
                  <Input
                    placeholder="ชม."
                    size="sm"
                    type="number"
                    max={24}
                    min={0}
                    defaultValue={eachField?.endTime?.hour}
                    name={`setting.timeshifts[${index}].endTime.hour`}
                    ref={register}
                  />
                  <Input
                    placeholder="นาที"
                    size="sm"
                    type="number"
                    max={60}
                    min={0}
                    defaultValue={eachField?.endTime?.minute}
                    name={`setting.timeshifts[${index}].endTime.minute`}
                    ref={register}
                  />
                </div>
              </FormControl>

              <Button type="button" colorScheme="red" size="sm" onClick={() => remove(index)}>
                ลบ
              </Button>
            </div>
          ))}
          <Button
            colorScheme="purple"
            className="self-center"
            type="button"
            size="sm"
            onClick={() => append({})}
          >
            เพิ่ม
          </Button>{" "}
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            ระบบรอบบิล
            <p className="text-xs font-normal">ตั้งค่าการทำงานเป็นรอบบิล</p>
          </h6>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>เปิดใช้งาน</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.billing?.enable}
                ref={register}
                name="setting.billing.enable"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <TextField
              label="เงินสำรองในลิ้นชัก (บาท)"
              defaultValue={defaultValue?.setting?.billing?.spare_money}
              name="setting.billing.spare_money"
              placeholder="เงินสำรองในลิ้นชัก"
              type="number"
              register={register}
            />{" "}
          </div>
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            การแสดงการจองในหน้าแรก
            <p className="text-xs font-normal">รูปแบบเริ่มต้นที่จะแสดงในหน้าแดชบอร์ดรายการการจอง</p>
          </h6>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>แสดงเป็นตารางรายห้อง</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.showListViewOfBookingInstead}
                ref={register}
                name="setting.showListViewOfBookingInstead"
                colorScheme="purple"
              />{" "}
              <FormLabel>แสดงแบบรายการ</FormLabel>
            </FormControl>
          </div>
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">การตั้งค่าอื่นๆ</h6>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>อนุญาติให้ใส่เลขที่เล่มที่ในการจอง</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.allowAddCustomNumberInRecipt}
                ref={register}
                name="setting.allowAddCustomNumberInRecipt"
                colorScheme="purple"
              />{" "}
            </FormControl>
          </div>
        </div>{" "}
        <div className="w-full lg:w-1/2 px-4 ">
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            ตั้งค่าเอกสารในการจอง
            <p className="text-xs font-normal">เปิดปิดแบบฟอร์มต่างๆ ที่ใช้งานกับการจองห้องพัก</p>
          </h6>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบมัดจำกุญแจ</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.keyDeposit}
                ref={register}
                name="setting.form.keyDeposit"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบเสร็จรับเงิน (VAT Include)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.recipt}
                ref={register}
                name="setting.form.recipt"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบเสร็จรับเงิน (Vat Exclude)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.vatExcludeRecipt}
                ref={register}
                name="setting.form.vatExcludeRecipt"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบเสร็จ (ไม่มี VAT)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.noVatRecipt}
                ref={register}
                name="setting.form.noVatRecipt"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบรายการการจอง</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.initery}
                ref={register}
                name="setting.form.initery"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบลงทะเบียน</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.registrationCard}
                ref={register}
                name="setting.form.registrationCard"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>สลิปใบเสร็จ (มี VAT)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.slipRecipt}
                ref={register}
                name="setting.form.slipRecipt"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>สลิปใบเสร็จ (ไม่มี VAT)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.slipReciptNoVat}
                ref={register}
                name="setting.form.slipReciptNoVat"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>Folio</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.folio}
                ref={register}
                name="setting.form.folio"
                colorScheme="purple"
              />
            </FormControl>
          </div>{" "}
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบแจ้งหนี้/invoice</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.invoice}
                ref={register}
                name="setting.form.invoice"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          {/* <div className="my-2">
            <FormControl display="flex">
              <FormLabel>คูปองอาหารเช้า</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.breakfastCoupon}
                ref={register}
                name="setting.form.breakfastCoupon"
                colorScheme="purple"
              />
            </FormControl>
          </div> */}
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>คูปองในแต่ละรายการบริการ</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.form?.serviceCoupon}
                ref={register}
                name="setting.form.serviceCoupon"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <br />
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
            ตั้งค่าเอกสารในการจองบริการ
            <p className="text-xs font-normal">
              เปิดปิดแบบฟอร์มต่างๆ ที่ใช้งานกับการจองบริการและห้องประชุม
            </p>
          </h6>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบเสร็จ (มี VAT)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.formOrder?.recipt}
                ref={register}
                name="setting.formOrder.recipt"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบเสร็จ (ไม่มี VAT)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.formOrder?.noVatRecipt}
                ref={register}
                name="setting.formOrder.noVatRecipt"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>ใบรายการการจอง</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.formOrder?.initery}
                ref={register}
                name="setting.formOrder.initery"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>สลิปใบเสร็จ (มี VAT)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.formOrder?.orderVatSlip}
                ref={register}
                name="setting.formOrder.orderVatSlip"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>สลิปใบเสร็จ (ไม่มี VAT)</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.formOrder?.orderNoVatSlip}
                ref={register}
                name="setting.formOrder.orderNoVatSlip"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <div className="my-2">
            <FormControl display="flex">
              <FormLabel>คูปองในแต่ละรายการบริการ</FormLabel>
              <Switch
                defaultChecked={defaultValue?.setting?.formOrder?.couponPerService}
                ref={register}
                name="setting.formOrder.couponPerService"
                colorScheme="purple"
              />
            </FormControl>
          </div>
          <br />
        </div>
      </div>
      <br />
      <br />
    </div>
  )
}

export default forwardRef(CustomizeSettingForm)
