import {
  FACILITY_ALL,
  FACILITY_CREATE,
  FACILITY_DELETE,
  FACILITY_GET,
  FACILITY_PUT,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case FACILITY_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        total: action.payload?.total,
      }
    case FACILITY_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case FACILITY_CREATE:
      return { isLoading: true }
    case FACILITY_PUT:
      return { isLoading: true }
    case FACILITY_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
