import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button, useToast } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import Layout from "../../../components/layout/GeneralLayout"
import {
  MeetingRoomPaymentForm,
  AttachServiceForm,
  DiscountAndPaymentMethodForm,
} from "../../../components/Forms/Reservation"
import { SpinnerLoading } from "../../../components/Loading"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"
import { PricePaymentAmount, ServiceOrderPricePanel } from "../../../components/Panels"

export default function ServiceOrderPayment() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const toast = useToast()
  const serviceOrder = useSelector((state) => state.serviceOrder)
  const hotelServices = useSelector((state) => state.hotelService)
  const me = useSelector((state) => state.me)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const { control, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: {
      meeting_room_orders: serviceOrder?.meeting_room_orders,
      final_discount: serviceOrder?.final_discount || 0,
    },
  })

  const meetingRoomOrder =
    _.sum(_.map(watch("meeting_room_orders"), (eachOrder) => eachOrder?.price)) || 0

  const servicePrice =
    _.sum(_.map(watch("services"), (eachServices) => parseFloat(eachServices?.price))) || 0
  const fbPrice =
    _.sum(_.map(serviceOrder?.fb_orders, (eachServices) => parseFloat(eachServices?.price))) || 0
  const allPrice = meetingRoomOrder + servicePrice + fbPrice - watch("final_discount")

  useEffect(() => {
    dispatch(actions.getOneServiceOrder(params.id))
      .then(() => {
        dispatch(actions.getAllServices({ page: 1, size: 10000 })).then(() => {
          //  setIsLoading(true)
        })
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.message)
      })

    return () => {}
  }, [params])

  useEffect(() => {
    if (serviceOrder && serviceOrder?.service_list) {
      const serviceData = _.map(serviceOrder?.service_list, (eachService) => ({
        ...eachService,
        // eslint-disable-next-line no-underscore-dangle
        service: eachService?.service?._id,
        price: eachService?.price,
      }))
      setValue("services", serviceData)
      setIsLoading(true)
    }

    return () => {}
  }, [serviceOrder])

  const handleUpdatePrice = async (data) => {
    const payload = {
      ...data,
      editPrice: true,
      service_list: data.services,
      meeting_room_orders: data.meeting_room_orders,
      user: me?._id,
    }
    dispatch(actions.editOneServiceOrder(params.id, payload))
      .then(() => {
        toast({
          title: "แก้ไขสำเร็จ",
          duration: 1000,
          status: "success",
        })
        history.goBack()
        dispatch(actions.getCurrentBill())
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.message)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.orderPayment")} preview>
      <Alert
        isError={isError}
        errMessage={errorMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrorMessage("")
        }}
      />
      <div className="flex justify-start px-4">
        <Button colorScheme="purple" onClick={() => history.goBack()} variant="outline">
          {t("general.back")}
        </Button>
      </div>
      <div className="w-full p-4">
        <form onSubmit={handleSubmit(handleUpdatePrice)}>
          <div>
            <h2 className="text-xl font-semibold my-2">
              {t("reservation.price.meetingRoomPrice")}
            </h2>
            <MeetingRoomPaymentForm
              control={control}
              register={register}
              meetingRoomOrders={serviceOrder?.meeting_room_orders}
            />
          </div>
          <div className="my-4">
            <h2 className="text-xl font-semibold my-2">{t("reservation.price.servicePrice")}</h2>
            <AttachServiceForm
              control={control}
              register={register}
              hotelServices={hotelServices}
              setValue={setValue}
              watch={watch}
              defaultValue={serviceOrder?.service_list}
            />
          </div>
          <div className="w-full flex justify-end">
            <Button colorScheme="purple" type="submit">
              {t("reservation.savePrice")}
            </Button>
          </div>
          <div className="my-4">
            <h2 className="text-xl font-semibold my-2">{t("reservation.discount")}</h2>
            <DiscountAndPaymentMethodForm control={control} />
          </div>
          <div className="my-4">
            <h2 className="text-xl font-semibold my-2">{t("reservation.price.priceSummary")}</h2>
            <ServiceOrderPricePanel
              orderInfo={serviceOrder}
              allPrice={allPrice}
              finalDiscount={watch("final_discount")}
              meetingRoomOrder={watch("meeting_room_orders")}
              serviceList={watch("services")}
            />
          </div>
          <div className="my-4">
            <h2 className="text-xl font-semibold my-2">{t("reservation.savePriceWithPayment")}</h2>
            <PricePaymentAmount
              allPrice={allPrice}
              bookingInfo={serviceOrder}
              handleSubmitMainForm={handleSubmit(handleUpdatePrice)}
              paymentMethod={watch("payment.payment_type")}
              source="order"
            />
          </div>
        </form>
      </div>
    </Layout>
  )
}
