import moment from "moment"

import { MeetingRoomOrderStatus, MeetingRoomTimeSlot } from "../config/constant"

export const overallMeetingRoomOrderStatusGenerator = (serviceOrder = {}) => {
  const { completed, deleted } = serviceOrder
  if (completed && deleted) {
    return MeetingRoomOrderStatus.MEETING_UNDEFINED
  }
  if (completed) {
    return MeetingRoomOrderStatus.MEETING_COMPLETED
  }
  if (deleted) {
    return MeetingRoomOrderStatus.MEETING_DELETED
  }
  return MeetingRoomOrderStatus.MEETING_CONFIRM
}

export const meetingRoomOrderAfterMeetingStatus = (serviceOrder = {}) => {
  const { completed, deleted } = serviceOrder
  if (completed && deleted) {
    return MeetingRoomOrderStatus.MEETING_UNDEFINED
  }
  if (completed) {
    return MeetingRoomOrderStatus.MEETING_COMPLETED
  }
  if (deleted) {
    return MeetingRoomOrderStatus.MEETING_DELETED
  }
  return MeetingRoomOrderStatus.MEETING_PASS
}

export const eachMeetingRoomStatusGen = (meetingRoomOrder) => {
  const meetingDate = moment(meetingRoomOrder?.date)

  if (
    meetingDate.isSame(moment(), "date") &&
    moment().isBetween(moment().hour(9).minute(0), moment().hour(12).minute(0), "minute", "[]") &&
    meetingRoomOrder?.timeslot === MeetingRoomTimeSlot.SLOT_MORNING.status_code
  ) {
    return MeetingRoomOrderStatus.MEETING_RUNNING
  }
  if (
    meetingDate.isSame(moment(), "date") &&
    moment().isBetween(moment().hour(13).minute(0), moment().hour(16).minute(0), "minute", "[]") &&
    meetingRoomOrder?.timeslot === MeetingRoomTimeSlot.SLOT_AFTERNOON.status_code
  ) {
    return MeetingRoomOrderStatus.MEETING_RUNNING
  }
  if (
    meetingDate.isSame(moment(), "date") &&
    moment().isBetween(moment().hour(16).minute(0), moment().hour(22).minute(0), "minute", "[]") &&
    meetingRoomOrder?.timeslot === MeetingRoomTimeSlot.SLOT_EVENING.status_code
  ) {
    return MeetingRoomOrderStatus.MEETING_RUNNING
  }

  if (moment().isBefore(moment(meetingRoomOrder?.date).hour(9).minute(0))) {
    return overallMeetingRoomOrderStatusGenerator(meetingRoomOrder)
  }

  return meetingRoomOrderAfterMeetingStatus(meetingRoomOrder)
}
