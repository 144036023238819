/* eslint-disable no-param-reassign */
import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Tag } from "antd"

export default function ServiceDisplayPanel({ services }) {
  const { t, i18n } = useTranslation()

  if (_.isEmpty(services)) {
    return (
      <div className="m-2 p-4">
        <p className="text-center text-sm">{t("roomtype.undefinedService")}</p>
      </div>
    )
  }

  return (
    <div>
      {_.map(services, (eachService, index) => (
        <div className="flex justify-between" key={index}>
          <p className="text-sm  my-1">
            {" "}
            {eachService?.service?.translation?.[i18n.language]?.name ||
              eachService?.service?.name}{" "}
            x {eachService?.amount}{" "}
            {eachService?.room && <Tag color="processing">{eachService?.room?.name}</Tag>}{" "}
            <div>{eachService?.remark}</div>
          </p>
          <p className="text-sm  my-1 ">
            {eachService?.price} {t("general.baht")}{" "}
          </p>
        </div>
      ))}
    </div>
  )
}
