import {
  EMPLOYEE_ALL,
  EMPLOYEE_CREATE,
  EMPLOYEE_DELETE,
  EMPLOYEE_GET,
  EMPLOYEE_UPDATE,
  EMPLOYEE_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const employeeActions = new GeneralReduxActionClass({
  allConst: EMPLOYEE_ALL,
  createConst: EMPLOYEE_CREATE,
  deleteConst: EMPLOYEE_DELETE,
  errorConst: EMPLOYEE_ERROR,
  getConst: EMPLOYEE_GET,
  putConst: EMPLOYEE_UPDATE,
  backendPrefix: "employee",
  description: "พนักงาน",
})

export const getAllEmployee = (query) => employeeActions.getAll(query)
export const getOneEmployee = (id) => employeeActions.getOne(id)
export const createOneEmployee = (payload) => employeeActions.createOne(payload)
export const editOneEmployee = (id, payload) => employeeActions.editOne(id, payload)
export const deleteOneEmployee = (id) => employeeActions.deleteOne(id)
