/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"
import { BookingStatus } from "../../constant"

dayjs.extend(buddhistEra)

export default function HouseKeeperReport(
  transactions,
  date,
  startDate,
  endDate,
  isPointDate,
  information,
  systemInfo,
) {
  const renderTableOnEachStatus = (type) => {
    let dataRows = []
    if (type === "CHECKOUT") {
      dataRows = _.filter(transactions, (each) =>
        dayjs(each?.bookings?.end).isSame(dayjs(date), "day"),
      )
    } else if (type === "CHECKIN") {
      dataRows = _.filter(transactions, (each) =>
        dayjs(each?.bookings?.start).isSame(dayjs(date), "day"),
      )
    } else if (type === "EXTEND") {
      dataRows = _.filter(
        transactions,
        (each) =>
          !dayjs(each?.bookings?.start).isSame(dayjs(date), "day") &&
          !dayjs(each?.bookings?.end).isSame(dayjs(date), "day"),
      )
    }
    return dataRows
  }

  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายงานแม่บ้าน",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายงานแม่บ้าน /Housekeeping Report",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      isPointDate
        ? {
            text: [
              { text: "วันที่", bold: true },
              {
                text: `  ${dayjs(date).format("D MMMM BBBB")}  (วันที่ ${dayjs(date).format(
                  "D MMMM BBBB",
                )} เวลา 12.01 - วันที่ ${dayjs(date)
                  .add(1, "day")
                  .format("D MMMM BBBB")} เวลา 11.59 )`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          }
        : {
            text: [
              { text: "จากวันที่", bold: true },
              {
                text: `  ${dayjs(startDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
              { text: "ถึงวันที่", bold: true },
              {
                text: `  ${dayjs(endDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        columns: [
          {
            margin: [0, 0, 10, 0],
            stack: [
              {
                text: "เช็คเอาท์ (Check Out)",
                fontSize: 11,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              {
                table: {
                  headerRows: 1,
                  widths: [40, "*", 60, 60],
                  body: [
                    [
                      { text: "ห้อง", bold: true },
                      { text: "หมวดหมู่", bold: true },
                      { text: "เช็คอิน", bold: true },
                      { text: "เช็คเอาท์", bold: true },
                    ],
                    ..._.map(renderTableOnEachStatus("CHECKOUT"), (eachList, index) => [
                      { text: `${eachList?.name}`, margin: [0, 2] },
                      {
                        text: `${eachList?.type?.translation?.th?.name || eachList?.type?.name}`,
                        margin: [0, 2],
                      },
                      {
                        text: `${dayjs(eachList?.bookings?.start).format("D/MM/BBBB")}`,
                        margin: [0, 2],
                      },
                      {
                        text: `${dayjs(eachList?.bookings?.end).format("D/MM/BBBB")}`,
                        margin: [0, 2],
                      },
                    ]),
                    [
                      { text: "รวม", bold: true, colSpan: 3 },
                      {},
                      {},
                      {
                        text: _.size(renderTableOnEachStatus("CHECKOUT")),
                      },
                    ],
                  ],
                  margin: [0, 15, 0, 0],
                  fontSize: 10,
                },
              },
              {
                text: "พักต่อ (Extend)",
                fontSize: 11,
                bold: true,
                margin: [0, 10, 0, 10],
              },
              {
                table: {
                  headerRows: 1,
                  widths: [40, "*", 60, 60],
                  body: [
                    [
                      { text: "ห้อง", bold: true },
                      { text: "หมวดหมู่", bold: true },
                      { text: "เช็คอิน", bold: true },
                      { text: "เช็คเอาท์", bold: true },
                    ],
                    ..._.map(renderTableOnEachStatus("EXTEND"), (eachList, index) => [
                      { text: `${eachList?.name}`, margin: [0, 2] },
                      {
                        text: `${eachList?.type?.translation?.th?.name || eachList?.type?.name}`,
                        margin: [0, 2],
                      },
                      {
                        text: `${dayjs(eachList?.bookings?.start).format("D/MM/BBBB")}`,
                        margin: [0, 2],
                      },
                      {
                        text: `${dayjs(eachList?.bookings?.end).format("D/MM/BBBB")}`,
                        margin: [0, 2],
                      },
                    ]),
                    [
                      { text: "รวม", bold: true, colSpan: 3 },
                      {},
                      {},
                      {
                        text: _.size(renderTableOnEachStatus("EXTEND")),
                      },
                    ],
                  ],
                  margin: [0, 15, 0, 0],
                  fontSize: 10,
                },
              },
            ],
          },
          {
            stack: [
              {
                text: "เช็คอิน (Check In)",
                fontSize: 11,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              {
                table: {
                  headerRows: 1,
                  widths: [40, "*", 60, 60],
                  body: [
                    [
                      { text: "ห้อง", bold: true },
                      { text: "หมวดหมู่", bold: true },
                      { text: "เช็คอิน", bold: true },
                      { text: "เช็คเอาท์", bold: true },
                    ],
                    ..._.map(renderTableOnEachStatus("CHECKIN"), (eachList, index) => [
                      { text: `${eachList?.name}`, margin: [0, 2] },
                      {
                        text: `${eachList?.type?.translation?.th?.name || eachList?.type?.name}`,
                        margin: [0, 2],
                      },
                      {
                        text: `${dayjs(eachList?.bookings?.start).format("D/MM/BBBB")}`,
                        margin: [0, 2],
                      },
                      {
                        text: `${dayjs(eachList?.bookings?.end).format("D/MM/BBBB")}`,
                        margin: [0, 2],
                      },
                    ]),
                    [
                      { text: "รวม", bold: true, colSpan: 3 },
                      {},
                      {},
                      {
                        text: _.size(renderTableOnEachStatus("CHECKIN")),
                      },
                    ],
                  ],
                  margin: [0, 15, 0, 0],
                  fontSize: 10,
                },
              },
            ],
          },
        ],
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
