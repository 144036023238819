/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { Input, Form } from "antd"
import { useForm, Controller } from "react-hook-form"
import Flags from "country-flag-icons/react/3x2"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Alert from "../../components/common/Alert/AlertDialog"
import DialogLoading from "../../components/Loading/DialogLoading"
import * as actions from "../../redux/actions"
import { WhitePanel } from "../../components"

export default function EditGallery({}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const gallery = useSelector((state) => state.gallery)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false)
  const { handleSubmit, control } = useForm()

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getGallery(params?.id))
        .then(() => {
          setIsLoading(true)
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
    return () => {}
  }, [params])

  const onEditGallery = (data) => {
    dispatch(actions.editGallery(params?.id, { ...data, updateMeta: true }))
      .then(() => {
        setIsLoadingDialogOpen(false)
        history.goBack()
      })
      .catch((err) => {
        setIsLoadingDialogOpen(false)
        setErrMessage(err)
        setIsError(true)
      })

    setTimeout(() => {
      setIsLoadingDialogOpen(false)
    }, 300000)
  }

  if (isLoading === false) {
    return (
      <div>
        <SpinnerLoading />
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
      </div>
    )
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม > แกลอรี่" title="แก้ไขแกลอรี่">
      <Alert
        isError={isError}
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage()
        }}
      />
      <DialogLoading isLoading={isLoadingDialogOpen} />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onEditGallery)}>
          <WhitePanel>
            <Form layout="vertical">
              <Form.Item label="ชื่อ">
                <Controller
                  control={control}
                  name="translation.th.name"
                  defaultValue={gallery?.translation?.th?.name || gallery?.name}
                  render={(field) => (
                    <Input
                      {...field}
                      addonBefore={<Flags.TH title="Thai" className="w-5" />}
                      placeholder="ชื่อแกลอรี่"
                    />
                  )}
                />
              </Form.Item>{" "}
              <Form.Item label="ชื่อ">
                <Controller
                  control={control}
                  name="translation.en.name"
                  defaultValue={gallery?.translation?.en?.name}
                  render={(field) => (
                    <Input
                      {...field}
                      addonBefore={<Flags.GB title="English" className="w-5" />}
                      placeholder="Gallery Name"
                    />
                  )}
                />
              </Form.Item>
            </Form>
          </WhitePanel>
          <div className="w-full px-4 ">
            <div className="text-center  gap-0 py-3 ">
              <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                บันทึก
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
