/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { DatePicker } from "antd"
import { Controller, useForm } from "react-hook-form"

import Layout from "../../components/layout/GeneralLayout"
import { BasicBookingInfoPanel, SpinnerLoading, WhitePanel } from "../../components"
import * as actions from "../../redux/actions"

export default function EditHistoryCheckoutDate() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const logs = useSelector((state) => state.logs)
  const { control, handleSubmit } = useForm()
  const params = useParams()

  const { t } = useTranslation()

  const getData = () => {
    dispatch(actions.getOnelog(params.id)).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {
    setIsLoading(false)
    getData()
    return () => {}
  }, [params.id])

  const handleEditLog = (data) => {
    setIsLoading(false)
    dispatch(actions.editOnelog(params.id, data))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        console.error(err)
        alert(`แก้ไขวันเช็คเอาท์ไม่สำเร็จ ${err?.message}`)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }
  return (
    <Layout categoryTitle={"แก้ไขวันเช็คเอาท์"} title={t("history.title")}>
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4">
        <WhitePanel>
          <BasicBookingInfoPanel bookingInfo={logs} />
        </WhitePanel>
        <form onSubmit={handleSubmit(handleEditLog)}>
          {" "}
          <WhitePanel>
            <div className="my-2">
              <div className="font-semibold">วันที่เช็คเอาท์ใหม่</div>
              <div className="my-1">
                <Controller
                  control={control}
                  name="checkout_date"
                  defaultValue={logs?.checkoutDate ? dayjs(logs?.checkoutDate) : null}
                  render={(field) => (
                    <DatePicker showTime allowClear format="DD/MM/YYYY HH:mm" {...field} />
                  )}
                />
              </div>
            </div>{" "}
          </WhitePanel>
          <Button isFullWidth colorScheme="purple" type="submit" className="my-2">
            {t("general.save")}
          </Button>
        </form>
      </div>
    </Layout>
  )
}
