import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Badge } from "@chakra-ui/react"
import { PassportAuth } from "../../../contexts/AuthContext"
import AppLogo from "../../../assets/img/eiotlogo.png"
import { LanguageSwitcher } from "../../Buttons"

export default function SidebarCheckin({ isShow = true }) {
  const [collapseShow, setCollapseShow] = React.useState("hidden")
  const { handleSignout } = useContext(PassportAuth)
  const history = useHistory()
  const system = useSelector((state) => state.system)
  const me = useSelector((state) => state.me)

  const onSignOut = async () => {
    await handleSignout()
    history.push("/login")
  }

  const openCollapse = () => {
    setCollapseShow("bg-white m-2 py-3 px-6")
  }

  if (isShow === true && system && system.isLoading === true)
    return (
      <>
        <nav className="font-sans lg:left-0 lg:block lg:fixed lg:top-0 lg:bottom-0 lg:overflow-y-auto lg:flex-row lg:flex-no-wrap lg:overflow-hidden shadow-xl   bg-white flex flex-wrap items-center justify-between relative md:w-64 z-30 py-4 px-6 print-disable">
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
            {/* Toggler */}
            <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={openCollapse}
            >
              <i className="fas fa-bars"></i>
            </button>
            {/* Brand */}
            <Link
              className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-base font-sans uppercase font-bold p-4 px-0"
              to="/"
            >
              <div className="flex gap-2">
                <img
                  src={system.logo ? system.logo : AppLogo}
                  className="w-auto align-middle  max-w-50-px"
                />
                <span className="self-center">{system.name}</span>
              </div>
            </Link>

            <div
              className={`lg:flex lg:flex-col lg:items-stretch lg:opacity-100 lg:relative lg:mt-4 lg:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ${collapseShow}`}
            >
              {/* Collapse header */}
              <div className="lg:min-w-full lg:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link
                      className="lg:block text-left lg:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                      to="/"
                    >
                      <div className="flex gap-2">
                        <img src={system.logo} className="w-12 h-12" />
                        <span className="self-center">{system.name}</span>
                      </div>
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => setCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Heading */}
              <h6 className="lg:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline ">
                จัดการลูกค้า
              </h6>
              {/* Navigation */}

              <ul className="lg:flex-col lg:min-w-full flex flex-col list-none">
                <li className="items-center">
                  <Link
                    className={
                      "text-xs uppercase py-3 font-bold block text-gray-800 hover:text-gray-600 "
                    }
                    to="/"
                  >
                    <i className={"fas fa-home mr-2 text-sm text-gray-400 "}></i> หน้าแรก
                  </Link>
                </li>
                <li className="items-center">
                  <Link
                    className={`text-xs uppercase py-3 font-bold block ${
                      window.location.href.indexOf("/checkIn") !== -1 &&
                      window.location.href.indexOf("/checkIn/") === -1
                        ? "text-indigo-500 hover:text-indigo-600"
                        : "text-gray-800 hover:text-gray-600"
                    }`}
                    to="/checkIn"
                    onClick={openCollapse}
                  >
                    <i
                      className={`fas fa-hotel mr-2 text-sm ${
                        window.location.href.indexOf("/checkIn") !== -1 &&
                        window.location.href.indexOf("/checkIn/") === -1
                          ? "opacity-75"
                          : "text-gray-400"
                      }`}
                    ></i>
                    ข้อมูลออเดอร์
                  </Link>
                </li>
              </ul>

              <hr className="my-4 lg:min-w-full" />
              <ul className="lg:flex-col lg:min-w-full flex flex-col list-none lg:mb-4">
                <LanguageSwitcher />
                <li className="items-center">
                  <div className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block ">
                    <i className="fas fa-user text-gray-500 mr-2 text-sm"></i> {me?.username}{" "}
                    <Badge colorScheme="gray" className="mb-1">
                      {me?.role}
                    </Badge>
                  </div>
                </li>
                <li className="items-center">
                  <div
                    className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                    onClick={() => {
                      onSignOut()
                    }}
                  >
                    <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i> Sign Out
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    )

  return <></>
}
