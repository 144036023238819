import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

import * as actions from "../../redux/actions"

export default function CustomerInfoPanel({ customerInfo }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const customer = useSelector((state) => state.customers)
  const { t } = useTranslation()

  useEffect(() => {
    if (customerInfo?.id) {
      dispatch(actions.getOneCustomer(customerInfo?.id)).then(() => {
        setIsLoading(true)
      })
    }
    return () => {}
  }, [customerInfo])

  return (
    <div>
      <div className="flex flex-wrap mb-2 mt-2">
        <div className="w-full lg:w-1/2">
          <div className="mb-4">
            <h6 className="text-xs font-bold ">{t("crm.customer")}</h6>
            <p className="font-semibold text-sm">
              {customerInfo?.firstname} {customerInfo?.lastname}
            </p>
          </div>{" "}
          <div className="mb-4">
            <h6 className="text-xs font-bold ">{t("general.tel")}</h6>
            <p className="font-semibold text-sm"> {customerInfo?.tel} </p>
          </div>
          <div className=" mb-4">
            <h6 className="text-xs font-bold">{t("general.email")}</h6>
            <p className="font-semibold text-sm"> {customerInfo?.email} </p>
          </div>{" "}
          <div className=" mb-4">
            <h6 className="text-xs font-bold">{t("general.address")}</h6>
            <p className="font-semibold text-sm"> {customerInfo?.address} </p>
          </div>
          <div className=" mb-4">
            <h6 className="text-xs font-bold">{t("checkin.checkinTime")}</h6>
            <p className="font-semibold text-sm">
              {" "}
              {DateTime.fromISO(customerInfo?.timestamp)
                .setLocale("th")
                .toLocaleString(DateTime.DATETIME_FULL)}{" "}
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className=" mb-4">
            <h6 className="text-xs font-bold">{t("general.idCardNo")}</h6>
            <p className="font-semibold text-sm"> {customerInfo?.id_card} </p>
          </div>
          <div className=" mb-4">
            <img src={customerInfo?.image?.url} className="w-64 " />
          </div>
        </div>
      </div>
    </div>
  )
}
