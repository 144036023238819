import api from "../../../config/api"

import { INFO_GET, INFO_PUT, INFO_CREATE } from "../types"

// faker.locale = "th"

export const getInformation =
  ({ notFilterMenu = "" } = {}) =>
  async (dispatch) => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/infomation/?notFilterMenu=${notFilterMenu}`)
      .then((res) => {
        console.log("Request Server to Get information")
        if (res.data) {
          dispatch({ type: INFO_GET, payload: res.data })
        } else {
          dispatch({ type: INFO_GET, payload: null })
        }
      })
  }
export const createOneInformation = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/infomation/`, payload).then((res) => {
    console.log("Request Server to Create New Promotion")
    dispatch({ type: INFO_CREATE })
  })
}

export const buildNewWebsite = () => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/infomation/upload`)
    .then((res) => {
      console.log("Request Server to Create New Site build")
      dispatch({ type: INFO_PUT, payload: res.data })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถส่งแก้ไขข้อมูลได่  ${err?.response?.data?.error?.message}`)
    })
}

export const editOneInformation = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/infomation/${id}`, payload)
    .then((res) => {
      console.log("Request Server to edit Promotion")
      dispatch({ type: INFO_PUT, payload: res.data })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถแก้ไขข้อมูลได้  ${err?.response?.data?.error?.message}`)
    })
}

export const onRestoreFromJSONFile = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/infomation/restore-from-json/${id}`, payload)
    .then((res) => {
      console.log("Request Server to edit Infomation")
      dispatch({ type: INFO_PUT, payload: res.data })
    })
    .catch((err) => {
      throw new Error(`ไม่สามารถแก้ไขข้อมูลได้  ${err?.response?.data?.error?.message}`)
    })
}
