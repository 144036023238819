import api from "../../../config/api"

import {
  CUSTOMER_ALL,
  CUSTOMER_CREATE,
  CUSTOMER_GET,
  CUSTOMER_UPDATE,
  CUSTOMER_DELETE,
} from "../types"

// faker.locale = "th"

export const getAllCustomers =
  (page = 1, size = null, firstname = "", startDate = null, endDate = null) =>
  async (dispatch) => {
    console.log("startDate in action", startDate)
    await api
      .get(
        `${process.env.REACT_APP_API_URL}/customer/?page=${page}&size=${size}&name=${firstname}${
          startDate !== null ? `&startDate=${startDate}` : ""
        }${endDate !== null ? `&endDate=${endDate}` : ""}`,
      )
      .then((res) => {
        console.log("Request Server to Get All Customers")
        if (res.data) {
          dispatch({ type: CUSTOMER_ALL, payload: res.data })
        } else {
          dispatch({ type: CUSTOMER_ALL, payload: null })
        }
      })
      .catch((err) => {
        console.log("Error on Get All Promotions", err)
        dispatch({ type: CUSTOMER_ALL, payload: null })
      })
  }

export const getOneCustomer = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/customer/${id}?fetchBooking=true`).then((res) => {
    console.log("Request Server to Get One Customer")
    if (res.data) {
      dispatch({ type: CUSTOMER_GET, payload: res.data })
    } else {
      dispatch({ type: CUSTOMER_GET, payload: null })
    }
  })
}
export const createOneCustomer = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/customer/`, payload).then(() => {
    console.log("Request Server to Create New Customer")
    dispatch({ type: CUSTOMER_CREATE })
  })
}

export const editOneCustomer = (id, payload) => async (dispatch) => {
  console.log(id, payload)
  await api.put(`${process.env.REACT_APP_API_URL}/customer/${id}`, payload).then(() => {
    console.log("Request Server to edit Customer")
    dispatch({ type: CUSTOMER_UPDATE })
  })
}

export const deleteOneCustomer = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/customer/${id}`).then(() => {
    console.log("Request Server to Delete One Customer")
    dispatch({ type: CUSTOMER_DELETE, payload: null })
  })
}
