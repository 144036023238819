import React, { forwardRef } from "react"

const TextField = ({
  register,
  defaultValue,
  label,
  name,
  type,
  placeholder,
  required = false,
  readonly = false,
}) => (
  <div>
    <div className="relative w-full mb-3 ">
      <label className="block  text-gray-700 text-sm font-semibold mb-2" htmlFor="grid-password">
        {label}
      </label>
      <input
        name={name}
        type={type}
        ref={register}
        defaultValue={defaultValue}
        readOnly={readonly}
        required={required}
        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
        placeholder={placeholder}
      />
    </div>
  </div>
)

export default forwardRef(TextField)
