/* eslint-disable no-underscore-dangle */
import React, { useState } from "react"
import { Button, Badge } from "@chakra-ui/react"
import { useForm } from "react-hook-form"


import { WhitePanel } from "../common/Cards"
import { FrontpageComponentForm } from "../Forms/HotelSite"
import { FrontpageComponentDisplayType } from "../../config/constant"
import { FrontpageComponentExample } from "../example"

function FrontpageComponentContainer({
  frontpageComponent,
  handleEditFrontpageComponent = () => { },
  handleDelete,
  gallery,
  file,
  length,
  width,
}) {
  // console.log("frontpageComponent in container", frontpageComponent);
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit, watch, setValue, control } = useForm({
    defaultValues: frontpageComponent,
    mode: "onChange",
  })


  return (
    <div className="my-2">
      <WhitePanel>
        <form>

          <div className="flex justify-between">
            <h3 className="font-bold text-xl">
              {frontpageComponent?.translation?.th?.name || "ส่วนประกอบไม่มีชื่อ"}
              <Badge colorScheme="blue" rounded="lg" className="ml-2">
                {FrontpageComponentDisplayType?.[frontpageComponent?.display_type]?.description}
              </Badge>
              {!frontpageComponent?.enable && (
                <i className="fas fa-eye-slash mx-4 text-gray-300"></i>
              )}
            </h3>

            {isEditorMode ? (
              <div className="flex gap-2">
                <Button
                  size="sm"
                  type="button"
                  onClick={handleSubmit(handleEditFrontpageComponent)}
                  colorScheme="green"
                >
                  บันทึก
                </Button>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => {
                    setIsEditorMode(false)
                  }}
                >
                  ยกเลิก
                </Button>
              </div>
            ) : (
              <div className="flex gap-2">
                <Button
                  size="sm"
                  type="button"
                  onClick={() => setIsEditorMode(true)}
                  colorScheme="yellow"
                >
                  แก้ไข
                </Button>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="red"
                  onClick={() => {
                    handleDelete(frontpageComponent?._id)
                  }}
                >
                  ลบ
                </Button>
              </div>
            )}
          </div>
          <div className="my-2">
            {isEditorMode ? (
              <div>
                <FrontpageComponentForm
                  defaultValue={frontpageComponent}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  gallery={gallery}
                  control={control}
                  length={length}
                  width={width}
                  file={file}

                />
              </div>
            ) : (
              <div>
                <FrontpageComponentExample componentType={frontpageComponent?.display_type} />
              </div>
            )}
          </div>
        </form>
      </WhitePanel>
    </div>
  )
}

export default FrontpageComponentContainer
