import React from "react"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  Button,
} from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { BasicBookingInfoPanel, PrintingPanel, BookingRemarkPanel } from "../../Panels"
import { ActionButtonList } from "../../Buttons/BookingActionButton"
import SelectedRoomList from "../../List/SelectedRoomList"
import { BookingStatus, paymentStatus } from "../../../config/constant"

export default function ModalBookingInfo({
  isOpen,
  handleOnClose,
  bookingInfo,
  handleDeleteBooking,
  handleEditBooking,
  hotelServices,
  handleCreateImage,
  handleEditLog,
  rooms,
}) {
  const system = useSelector((state) => state.system)
  const systemEnableForm = system?.setting?.form || {}
  const isLog =
    bookingInfo?.isLog ||
    bookingInfo?.status === BookingStatus.success ||
    bookingInfo?.status === BookingStatus.cancle

  const { t } = useTranslation()
  if (!bookingInfo) {
    return (
      <Modal isOpen={isOpen} onClose={handleOnClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody className="font-sans mb-4">
            <h3 className="my-4 p-4">ขออภัย ไม่สามารถหาข้อมูลการจองรายการนี้ได้</h3>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <form>
      <Modal isOpen={isOpen} onClose={handleOnClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="font-sans" fontSize="lg">
            <div className="flex gap-2">
              <div>{t("reservation.detailBooking")} </div>
              {isLog ? (
                <Link to={`/history/view/${bookingInfo?.id}`}>
                  <Button size="sm">
                    <i className="fas fa-file-alt" />
                  </Button>
                </Link>
              ) : (
                <Link to={`/reservation/view/${bookingInfo?.id}`}>
                  <Button size="sm">
                    <i className="fas fa-file-alt" />
                  </Button>
                </Link>
              )}
            </div>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody className="font-sans mb-4">
            <BasicBookingInfoPanel bookingInfo={bookingInfo} showPrintingPanel={false} />
            <hr />
            <div className=" w-full ">
              <h6 className="text-xs font-bold">{t("reservation.bookingList")}</h6>
              <div className="pr-4 mt-2">
                <SelectedRoomList bookingList={bookingInfo} />
              </div>
            </div>
            <hr />
            <div className="">
              <div className="w-full lg:w-1/2">
                <PrintingPanel bookingInfo={bookingInfo} {...systemEnableForm} />
              </div>
            </div>
            <div className="mt-2">
              <BookingRemarkPanel bookingInfo={bookingInfo} useGetOne={false} />
            </div>
            <br />
            {!isLog && (
              <div className="mt-2 flex justify-end">
                <ActionButtonList
                  eachBooking={bookingInfo}
                  place="modal"
                  disableAll={
                    bookingInfo.status === BookingStatus.success &&
                    bookingInfo.paymentStatus === paymentStatus.allPayment.allPaymentConfirm
                  }
                  handleDeleteBooking={handleDeleteBooking}
                  handleEditBooking={handleEditBooking}
                  hotelServices={hotelServices}
                  handleCreateImage={handleCreateImage}
                  rooms={rooms}
                  handleEditLog={handleEditLog}
                />
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </form>
  )
}
