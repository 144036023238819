/* eslint-disable no-underscore-dangle */
import React from "react"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Button } from "@chakra-ui/react"

import { MeetingRoomTimeSlot } from "../../config/constant"

function MeetingRoomSlotSelector({
  selectedMeetingRoom,
  selectedDay,
  dayslotKey,
  selectedMeetingRooms,
  alreadyBooked,
  setSelectedMeetingRooms,
  rerender,
  setRerender,
  setConfirmOpen,
  setSelectedMROToDeleteId,
}) {
  const { t, i18n } = useTranslation()

  // Handle Change Slected Meeting Room Stack
  const handleSelectMeetingRoom = (timeslotCode, timeSlotDay) => {
    if (
      _.findIndex(selectedMeetingRooms, {
        meetingRoom: selectedMeetingRoom?._id,
        timeslot: timeslotCode,
        date: dayjs(timeSlotDay).format("YYYY-MM-DD"),
      }) !== -1
    ) {
      const founedIndex = _.findIndex(
        selectedMeetingRooms,
        (eachMeetingRoom) => eachMeetingRoom?.meetingRoom === selectedMeetingRoom?._id,
      )
      // eslint-disable-next-line no-param-reassign
      delete selectedMeetingRooms[founedIndex]
      setSelectedMeetingRooms(selectedMeetingRooms)
    } else {
      selectedMeetingRooms.push({
        meetingRoom: selectedMeetingRoom?._id,
        timeslot: timeslotCode,
        date: dayjs(timeSlotDay).format("YYYY-MM-DD"),
      })
      setSelectedMeetingRooms(selectedMeetingRooms)
    }
    setRerender(!rerender)
  }

  const handleDeleteAlreadyBooking = (meetingRoomOrderId) => {
    setConfirmOpen(true)
    setSelectedMROToDeleteId(meetingRoomOrderId)
  }

  // Render Timeslot
  const renderEachTimeSlot = (timeslotCode, allMeeitngRoomOrder) => {
    const dayOneThisSlot = dayjs(selectedDay).add(dayslotKey, "day")

    const foundOnSelectedBookingList =
      _.findIndex(selectedMeetingRooms, {
        meetingRoom: selectedMeetingRoom?._id,
        timeslot: timeslotCode,
        date: dayjs(dayOneThisSlot).format("YYYY-MM-DD"),
      }) !== -1

    const foundOneAlreadyBookedList =
      _.findIndex(
        alreadyBooked,
        (eachAlreadyBook) =>
          eachAlreadyBook?.meeting_room?._id === selectedMeetingRoom?._id &&
          eachAlreadyBook?.timeslot === timeslotCode &&
          dayjs(eachAlreadyBook?.date).format("YYYY-MM-DD") ===
            dayjs(dayOneThisSlot).format("YYYY-MM-DD"),
      ) !== -1

    const bookingOnThisSlot = _.filter(
      allMeeitngRoomOrder,
      (eachMeetingRoomOrder) =>
        eachMeetingRoomOrder?.timeslot === timeslotCode &&
        dayjs(eachMeetingRoomOrder?.date).isSame(dayjs(dayOneThisSlot), "date"),
    )

    const findMeetingRoomColor = () => {
      if (foundOnSelectedBookingList) {
        return "bg-purple-600 text-white"
      }
      if (foundOneAlreadyBookedList) {
        return "bg-green-500 text-white"
      }
      return "bg-gray-200"
    }

    return (
      <div
        className="p-2 w-full lg:w-1/3 cursor-pointer hover:bg-gray-300"
        onClick={() => {
          if (_.isEmpty(bookingOnThisSlot)) {
            handleSelectMeetingRoom(timeslotCode, dayOneThisSlot)
          }
        }}
      >
        <div className={`${findMeetingRoomColor()}   rounded-md`}>
          <div className=" justify-between p-4">
            <h3 className="font-bold text-lg mx-4 ml-2 ">
              <i className="fas fa-handshake mr-2" />
              {i18n.language === "th"
                ? MeetingRoomTimeSlot[timeslotCode]?.description
                : MeetingRoomTimeSlot[timeslotCode]?.descriptionEn}
            </h3>
            <h6 className="mx-2 text-sm ">
              {dayjs(dayOneThisSlot).locale(i18n.language)?.format("D MMM YYYY")}
            </h6>
          </div>

          {_.map(bookingOnThisSlot, (eachBooking, bookingIndex) => (
            <div key={bookingIndex} className="p-2 px-4 rounded-md border mx-2">
              <div>No. {eachBooking?.service_order?.order_no}</div>
              <div className="font-semibold">
                <i className="fas fa-id-card mr-2"></i>
                {eachBooking?.service_order?.name || "-"}
              </div>
              <div>
                <i className="fas fa-user mr-2"></i>
                {eachBooking?.service_order?.customer?.firstname || "-"}{" "}
                {eachBooking?.service_order?.customer?.lastname || ""}
              </div>
              <div>
                <i className="fas fa-calendar mr-2"></i>
                {dayjs(eachBooking?.date)?.locale(i18n.language).format("D MMM YYYY")}
              </div>
              <div>
                {foundOneAlreadyBookedList && (
                  <div className="flex justify-end">
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleDeleteAlreadyBooking(eachBooking?._id)
                      }}
                    >
                      {t("general.cancle")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))}
          <br />
        </div>
      </div>
    )
  }

  return (
    <div>
      {" "}
      <div className="my-2 flex flex-wrap " key={`day-${dayslotKey}`}>
        {renderEachTimeSlot(
          MeetingRoomTimeSlot.SLOT_MORNING.status_code,
          selectedMeetingRoom?.meeting_room_orders,
        )}
        {renderEachTimeSlot(
          MeetingRoomTimeSlot.SLOT_AFTERNOON.status_code,
          selectedMeetingRoom?.meeting_room_orders,
        )}
        {renderEachTimeSlot(
          MeetingRoomTimeSlot.SLOT_EVENING.status_code,
          selectedMeetingRoom?.meeting_room_orders,
        )}
      </div>
    </div>
  )
}

export default MeetingRoomSlotSelector
