import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Table, Thead, Th, Tbody, Td, Tr, Input, Badge } from "@chakra-ui/react"
import { Link, useHistory } from "react-router-dom"
import ReactCountryFlag from "react-country-flag"

import _ from "lodash"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Paginator from "../../components/common/Paginator/NewPagination"
import * as actions from "../../redux/actions"

export default function Gallery() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const customers = useSelector((state) => state.customers)

  const [searchTerm, setSearchTerm] = useState("")
  const customerArr = customers.arr
  const inputEl = useRef("")
  console.log(customers)
  // console.log(inputEl.current)

  const searchHandler = () => {
    const input = inputEl.current.value

    if (page !== 1) {
      setPage(1)
      if (input === "") {
        setSearchTerm(input)
        dispatch(actions.getAllCustomers(page, 10)).then(() => {
          setIsLoading(true)
        })
      } else {
        setSearchTerm(input)
        dispatch(actions.getAllCustomers(page, 10, searchTerm)).then(() => {
          setIsLoading(true)
        })
      }
    }
    if (page === 1) {
      if (input === "") {
        setSearchTerm(input)
        dispatch(actions.getAllCustomers(page, 10)).then(() => {
          setIsLoading(true)
        })
      } else {
        setSearchTerm(input)
        dispatch(actions.getAllCustomers(page, 10, searchTerm)).then(() => {
          setIsLoading(true)
        })
      }
    }
  }

  const handleDeleteCustomer = (id) => {
    const confirm = window.confirm("ยืนยันการลบผู้ใช้นี้")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOneCustomer(id)).then(() => {
        dispatch(actions.getAllCustomers(page, 10)).then(() => {
          history.push("/crm")
        })
      })
    }
  }

  useEffect(() => {
    dispatch(actions.getAllCustomers(page, 10)).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [page])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการลูกค้า" title="ลูกค้าทั้งหมด">
      <div className="flex justify-between px-4 gap-2">
        <Link to="/crm/create">
          <Button colorScheme="purple">เพิ่ม</Button>
        </Link>
        <div className="w-full lg:w-1/4">
          <Input placeholder="ค้นหา" value={searchTerm} onChange={searchHandler} ref={inputEl} />
        </div>
      </div>
      <div className="p-4 w-full overflow-x-auto">
        <Table variant="simple" colorScheme="facebook">
          <Thead backgroundColor="white">
            <Tr>
              <Th>ลำดับ</Th>
              <Th>ชื่อลูกค้า</Th>
              <Th>หมายเลขโทรศัพท์</Th>
              <Th>ข้อมูลลูกค้า</Th>
              <Th>ดำเนินการ</Th>
            </Tr>
          </Thead>
          <Tbody>
            {_.map(customerArr, (eachCustomer, index) => (
              <Tr key={index}>
                <Td>{(page - 1) * 10 + index + 1}</Td>
                <Td>
                  {eachCustomer?.firstname}{" "}
                  <span className="ml-1">
                    {!eachCustomer?.is_legal_entity && eachCustomer?.lastname}
                  </span>
                  {eachCustomer?.nationality !== "TH" && (
                    <ReactCountryFlag
                      countryCode={eachCustomer?.nationality}
                      svg
                      style={{
                        fontSize: "1.5em",
                        lineHeight: "1.5em",
                        marginLeft: "0.5em",
                      }}
                    />
                  )}
                  <div>
                    {eachCustomer?.is_legal_entity && <Badge colorScheme="green">นิติบุคคล</Badge>}
                  </div>
                </Td>
                <Td className="text-sm">{eachCustomer?.tel}</Td>
                <Td>
                  <Link key={index} to={`/crm/show/${eachCustomer?.id}`}>
                    <Button
                      leftIcon={<i className="fas fa-file-alt text-white hover:text-gray-600"></i>}
                      colorScheme="blue"
                      size="sm"
                    >
                      ความชอบ / ประวัติการจอง
                    </Button>
                  </Link>
                </Td>
                <Td>
                  <div className="flex gap-1">
                    <Link to={`/crm/edit/${eachCustomer?.id}`}>
                      <Button size="sm" colorScheme="yellow">
                        แก้ไข
                      </Button>
                    </Link>
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => {
                        handleDeleteCustomer(eachCustomer?.id)
                      }}
                    >
                      ลบ
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))}
            {/* {searchResults.length < 1 && inputEl.current !== "" ? (
              <div className="w-full p-5 text-red-600">ไม่พบรายชื่อที่ต้องการ</div>
            ) : (
              ""
            )} */}
          </Tbody>
        </Table>
      </div>
      <Paginator lastPage={customers?.lastPage} setPage={setPage} />
    </Layout>
  )
}
