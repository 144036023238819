import React from "react"
import ReactPaginate from "react-paginate"

export default function NewPaginator({ lastPage, setPage }) {
  const handlePageClick = (event) => {
    setPage(event.selected + 1)
  }

  return (
    <div className="flex justify-center py-4">
      <ReactPaginate
        nextLabel="ถัดไป >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={lastPage}
        previousLabel="< ก่อนหน้า"
        pageClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center border text-sm font-medium"
        pageLinkClassName="  px-4 py-2  "
        previousClassName="relative inline-flex items-center  border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        previousLinkClassName="px-4 py-2"
        nextClassName="ml-3 relative inline-flex items-center  border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        nextLinkClassName="px-4 py-2"
        breakLabel="..."
        breakClassName="relative inline-flex items-center border border-gray-300 bg-white text-sm font-medium text-gray-700"
        breakLinkClassName=" px-4 py-2"
        containerClassName="pagination"
        activeClassName="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center border text-sm font-medium"
        renderOnZeroPageCount={null}
      />
    </div>
  )
}
