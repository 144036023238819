/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  useToast,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import _ from "lodash"
import "dayjs/locale/th"
import { useTranslation } from "react-i18next"
import { Input } from "antd"
import QRCode from "react-qr-code"

import Layout from "../../components/layout/GeneralLayout"
import { Alert, Confirm, SpinnerLoading } from "../../components"
import { appConfig } from "../../config"

import * as actions from "../../redux/actions"

export default function TableManagement() {
  const [isLoading, setIsLoading] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [selectedTable, setSelectedTable] = useState()
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toast = useToast()
  const table = useSelector((state) => state.table)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const getAllTable = () => {
    dispatch(
      actions.getAllTable({
        page: 1,
        size: appConfig.maxFetchSize,
        query: { name },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }
  const handleDelete = async () => {
    try {
      await dispatch(actions.deleteOneTable(selectedTable?._id)).then(() => {
        getAllTable()
      })
    } catch (error) {
      setAlertMessage(error?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      setIsAlertOpen(true)
    } finally {
      setIsConfirmOpen(false)
    }
  }

  useEffect(() => {
    setIsLoading(false)
    getAllTable()
    return () => {}
  }, [name])

  const handleCopyLink = (tableInfo) => {
    const link = `${window.location.origin}/mobile-order/${tableInfo?._id}`
    navigator.clipboard.writeText(link)
    toast({
      title: "คัดลอกลิงค์สำเร็จ",
      status: "success",
      duration: 900,
    })
  }

  const renderSetting = () => (
    <div className="flex justify-between flex-wrap px-4">
      <div className="flex gap-2">
        <Link to="/food-beverage/table/create">
          <Button colorScheme="purple" variant="solid">
            {t("general.add")}
          </Button>
        </Link>{" "}
        <Link to="/food-beverage/table/create-many">
          <Button colorScheme="teal" variant="solid">
            เพิ่มหลายโต๊ะ
          </Button>
        </Link>
      </div>
      <div className="w-full md:w-1/4  self-center mt-2">
        <Input
          addonBefore={<i className="fas fa-search"></i>}
          onChange={(e) => setSearchWord(e.target.value)}
        />
      </div>
    </div>
  )

  const renderQRCodeModal = () => (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
        setSelectedTable(null)
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          โต๊ะ {selectedTable?.name}
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <div className="my-4">
            <QRCode
              value={`${window.location.origin}/mobile-order/${selectedTable?._id}`}
              size={150}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )

  const renderDeleteConfirm = () => (
    <Confirm
      title="ยืนยันการลบโต๊ะ"
      isOpen={isConfirmOpen}
      body="ยืนยันการลบโต๊ะ"
      handleOnClose={() => setIsConfirmOpen(false)}
      handleSuccess={handleDelete}
    />
  )

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  return (
    <Layout categoryTitle={t("foodAndBeverage.title")} title="จัดการโต๊ะ">
      {renderAlert()}
      {renderDeleteConfirm()}
      {renderSetting()}
      {renderQRCodeModal()}

      {isLoading ? (
        <div className="p-4 w-full overflow-x-auto flex flex-wrap">
          {_.map(table?.arr, (eachTable, tableIndex) => (
            <div className="p-2 w-full lg:w-1/3 cursor-pointer hover:bg-gray-300" key={tableIndex}>
              <div className="bg-gray-200  rounded-md">
                <div className="flex justify-between pt-4 px-4">
                  <h3 className="font-bold text-lg mx-4 ml-2 ">
                    <i className="fas fa-door-open mr-2" />
                    {eachTable?.name}
                  </h3>
                  <div className="flex gap-2">
                    <Link to={`/food-beverage/table/edit/${eachTable?._id}`}>
                      <Button size="sm" colorScheme="yellow">
                        <i className="fas fa-pencil-alt " />
                      </Button>
                    </Link>
                    <Button
                      size="sm"
                      colorScheme="red"
                      onClick={() => {
                        setSelectedTable(eachTable)
                        setIsConfirmOpen(true)
                      }}
                    >
                      <i className="fas fa-trash " />
                    </Button>
                  </div>
                </div>

                <div className="flex p-4 gap-2">
                  <Button
                    size="sm"
                    colorScheme="blue"
                    leftIcon={<i className="fas fa-copy"></i>}
                    onClick={() => handleCopyLink(eachTable)}
                  >
                    Copy
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="blackAlpha"
                    onClick={() => {
                      setSelectedTable(eachTable)
                      setIsModalOpen(true)
                    }}
                    leftIcon={<i className="fas fa-qrcode"></i>}
                  >
                    QR
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <SpinnerLoading />
      )}
    </Layout>
  )
}
