import { DateTime } from "luxon"
import _ from "lodash"
import dayjs from "dayjs"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter } from "../../../util"
import { CountryList } from "../../constant"

const dateFormat = { day: "numeric", month: "long", year: "numeric" }

export default function Folio({ bookingInfo, information, systemInfo, era }) {
  const dayAmount = DateTime.fromISO(bookingInfo?.end)
    .diff(DateTime.fromISO(bookingInfo?.start))
    .as("days")
    .toFixed(0)

  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "Registration Card",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "ใบแสดงรายการ / Folio",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        columns: [
          {
            text: [
              { text: "วันที่จอง / Booking Date : ", bold: true },
              {
                text: ` ${dayjs(bookingInfo?.createdAt).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "หมายเลขการจอง / Booking Number : ", bold: true },
              { text: `  ${bookingInfo?.booking_no}`, margin: [5, 0] },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 0, 0, 3],
      },
      {
        columns: [
          {
            text: [
              { text: "วันที่พิมพ์ / Print Date : ", bold: true },
              {
                text: ` ${dayjs().format(era === "be" ? "D MMMM BBBB" : "D/MM/YYYY")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "", bold: true },
              { text: ``, margin: [5, 0] },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 0, 0, 3],
      },
      {
        columns: [
          {
            text: [
              { text: "ลูกค้า / Customer : ", bold: true },
              {
                text: ` ${bookingInfo?.customer?.firstname || ""} ${
                  bookingInfo?.customer?.lastname || ""
                }`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "ประเภทลูกค้า / Customer Type : ", bold: true },
              {
                text: `  ผู้ใหญ่ ${bookingInfo?.capacity?.adult || 0} คน เด็ก ${
                  bookingInfo?.capacity?.child || 0
                } คน`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 10, 0, 3],
      },
      {
        columns: [
          {
            text: [
              { text: "ที่อยู่ / Address : ", bold: true },
              {
                text: ` ${bookingInfo?.customer?.address || ""}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "ทะเบียนรถ / Car Licence Number : ", bold: true },
              {
                text: ` ${bookingInfo?.customer?.car_licence_number || "-"}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 0, 0, 3],
      },
      {
        columns: [
          {
            text: [
              { text: "เลขที่ผู้เสียภาษี / Tax ID : ", bold: true },
              {
                text: ` ${bookingInfo?.customer?.id_card || ""}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "จำนวนการเข้าพัก / Night : ", bold: true },
              {
                text: ` ${dayAmount} คืน`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 0, 0, 3],
      },
      {
        columns: [
          {
            text: [
              { text: "หมายเลขโทรศัพท์ / Tel : ", bold: true },
              {
                text: ` ${bookingInfo?.customer?.tel || ""}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "พนักงานทำรายการ / Booking Staff : ", bold: true },
              {
                text: `${bookingInfo?.user?.employee?.firstname || ""} ${
                  bookingInfo?.user?.employee?.lastname || ""
                }`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 0, 0, 3],
      },
      {
        columns: [
          {
            text: [
              { text: "เช็คอิน / Check-in : ", bold: true },
              {
                text: ` ${dayjs(bookingInfo?.start).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "เช็คเอาท์ / Check-out : ", bold: true },
              {
                text: ` ${dayjs(bookingInfo?.end).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 10, 0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, 40, 60, "*", 40, 50, 60, 50, 50],
          body: [
            [
              { text: "ที่ \n No.", bold: true, alignment: "center" },
              { text: "ห้องพัก \n Room", bold: true, alignment: "center" },
              { text: "จำนวนลูกค้า\n Guest", bold: true, alignment: "center" },
              { text: "วันที่ \n Date", bold: true, alignment: "center" },
              { text: "จำนวน \n Amount", bold: true, alignment: "center" },
              { text: "จำนวน \n Amount", bold: true, alignment: "center" },
              { text: "ราคาต่อหน่วย \n Price Per Unit", bold: true, alignment: "center" },
              { text: "ส่วนลด \n Discount", bold: true, alignment: "center" },
              { text: "จำนวนเงิน \n Price", bold: true, alignment: "center" },
            ],
            ..._.map(bookingInfo?.list, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: eachList?.room?.name,
                margin: [0, 2],
              },
              {
                text: `${bookingInfo?.capacity?.adult || 0 + bookingInfo?.capacity?.child || 0} `,
                margin: [0, 2],
              },
              {
                text: `${dayjs(bookingInfo?.start).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )} `,
                margin: [0, 2],
              },
              {
                text: "1 ห้อง",
              },
              { text: ` ${dayAmount || 0} คืน `, margin: [0, 2] },
              { text: ` ${currencyFormatter.format(eachList?.price || 0)} `, margin: [0, 2] },
              { text: ` ${currencyFormatter.format(eachList?.discount || 0)} `, margin: [0, 2] },
              {
                text: ` ${currencyFormatter.format(
                  ((eachList?.price || 0) - (eachList?.discount || 0)) * dayAmount,
                )} `,
                margin: [0, 2],
              },
            ]),
            [
              { text: "รวม / Total ", bold: true, colSpan: 6 },
              {},
              {},
              {},
              {},
              {},
              {
                text: currencyFormatter.format(
                  parseFloat(_.sum(_.map(bookingInfo?.list, (eachList) => eachList.price))),
                ),
                bold: true,
                colSpan: 1,
              },
              {
                text: currencyFormatter.format(
                  parseFloat(_.sum(_.map(bookingInfo?.list, (eachList) => eachList.discount))),
                ),
                bold: true,
                colSpan: 1,
              },
              {
                text: currencyFormatter.format(
                  parseFloat(
                    _.sum(
                      _.map(
                        bookingInfo?.list,
                        (eachList) => (eachList?.price - eachList?.discount) * dayAmount,
                      ),
                    ),
                  ),
                ),
                bold: true,
                colSpan: 1,
              },
            ],
          ],
        },
        margin: [0, 15, 0, 0],
        fontSize: 10,
      },
      {
        stack: [
          { text: "หมายเหตุ / Remark", bold: true, margin: [0, 10, 0, 0] },
          {
            text: bookingInfo?.remark || "-",
          },
        ],
      },
      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้รับบริการ / Customer" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "พนักงาน / Hotel Staff" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
