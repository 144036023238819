/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  useDisclosure,
  Table,
  Thead,
  Tr,
  Td,
  Th,
  Tbody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { Button as AntButton, message, Upload } from "antd"

import Layout from "../../components/layout/GeneralLayout"
import { SpinnerLoading, Alert, NewPaginator } from "../../components"
import * as actions from "../../redux/actions"

export default function FileManagement() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const file = useSelector((state) => state.file)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [page, setPage] = useState(1)

  const findAllFiles = () => {
    dispatch(actions.fileAll({ name: "", page: 1, size: 10 })).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {
    findAllFiles()
    return () => {}
  }, [])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const handleDeleteFile = (galleryId) => {
    const confirm = window.confirm("ยืนยันการลบแกลอรี่นี้")
    if (confirm) {
      dispatch(actions.fileDelete(galleryId))
        .then(() => {
          findAllFiles()
        })
        .catch((error) => {
          setIsError(true)
          setErrorMessage(`ขออภัย ไม่สามารถลบแกลอรี่ได้ ${error?.message}`)
        })
    }
  }

  const renderAlert = () => (
    <Alert isError={isError} handleOnClose={() => setIsError(false)} errMessage={errorMessage} />
  )

  const props = {
    action: (selectedFile) => {
      console.log("File", selectedFile)
      dispatch(actions.fileCreate({ file: selectedFile }))
        .then(() => {
          findAllFiles()
        })
        .catch((err) => {
          alert(`การอัพโหลดไฟล์ไม่สำเร็จ ${err.message}`)
          console.error("file upload error", err)
        })
        .finally(() => {
          onClose()
        })
    },

    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`)
      }
    },
  }

  const renderModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <form>
          <ModalHeader>
            <div className="font-sans text-xl">อัพโหลดไฟล์</div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Upload {...props}>
              <AntButton icon={<i className="fas fa-upload" />} colorScheme="purple">
                เลือกไฟล์
              </AntButton>
            </Upload>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )

  return (
    <Layout categoryTitle={t("site.title")} title="ไฟล์">
      {renderAlert()}
      {renderModal()}
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" onClick={onOpen}>
          {t("general.add")}
        </Button>
      </div>
      <div className="p-4 w-full min-h-screen">
        <Table variant="simple" colorScheme="facebook">
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>ชื่อไฟล์</Th>
              <Th>รูปแบบไฟล์</Th>
              <Th>วันที่อัพโหลด</Th>
              <Th>ดำเนินการ</Th>
            </Tr>
          </Thead>
          <Tbody className>
            {_.map(file?.arr, (eachFile, index) => (
              <Tr key={index}>
                <Td>{(page - 1) * 10 + index + 1}</Td>
                <Td>{eachFile.file_name}</Td>
                <Td className="uppercase">{_.last(_.split(eachFile.file_name, "."))}</Td>
                <Td>{dayjs(eachFile.createdAt).format("D MMM YYYY HH:mm")}</Td>

                <Td>
                  <div className="flex gap-2">
                    <a href={eachFile?.url} target="_blank" rel="noreferrer">
                      <Button colorScheme="blue" size="sm">
                        เปิด
                      </Button>
                    </a>
                    <Button
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleDeleteFile(eachFile._id)}
                    >
                      ลบ
                    </Button>
                  </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <NewPaginator currentPage={page} lastPage={file?.lastPage} setPage={setPage} />
      </div>
    </Layout>
  )
}
