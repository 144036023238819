import api from "../../../config/api"

class GeneralReduxAction {
  constructor({
    allConst,
    getConst,
    createConst,
    putConst,
    deleteConst,
    errorConst,
    backendPrefix,
    description,
  }) {
    this.allConst = allConst
    this.getConst = getConst
    this.createConst = createConst
    this.putConst = putConst
    this.deleteConst = deleteConst
    this.backendPrefix = backendPrefix
    this.errorConst = errorConst
    this.description = description
  }

  getAll({ page, size, query }) {
    return async (dispatch) => {
      const queryParams = new URLSearchParams(query)
      console.info(
        "URL =>",
        `${process.env.REACT_APP_API_URL}/${
          this.backendPrefix
        }?page=${page}&size=${size}&${queryParams.toString()}`,
      )
      await api
        .get(
          `${process.env.REACT_APP_API_URL}/${
            this.backendPrefix
          }?page=${page}&size=${size}&${queryParams.toString()}`,
        )
        .then((res) => {
          if (res.data) {
            dispatch({ type: this.allConst, payload: res.data })
          } else {
            dispatch({ type: this.errorConst, payload: null })
          }
        })
        .catch((err) => {
          console.log("Error on Get All Faclity", err)
          dispatch({ type: this.errorConst, payload: null })
          throw new Error(
            `ไม่สามารถดึงข้อมูล${this.description}ทั่งหมดมาได้  ${err?.response?.data?.error?.message}`,
          )
        })
    }
  }

  getOne(id) {
    return async (dispatch) => {
      await api
        .get(`${process.env.REACT_APP_API_URL}/${this.backendPrefix}/${id}`)
        .then((res) => {
          if (res.data) {
            dispatch({ type: this.getConst, payload: res.data })
          } else {
            dispatch({ type: this.errorConst, payload: null })
          }
        })
        .catch((err) => {
          dispatch({ type: this.errorConst, payload: null })

          throw new Error(
            `ไม่สามารถดึงข้อมูล${this.description}มาได้  ${err?.response?.data?.error?.message}`,
          )
        })
    }
  }

  createOne(payload) {
    return async (dispatch) => {
      await api
        .post(`${process.env.REACT_APP_API_URL}/${this.backendPrefix}/`, payload)
        .then(() => {
          dispatch({ type: this.createConst })
        })
        .catch((err) => {
          dispatch({ type: this.errorConst, payload: null })
          throw new Error(
            `ไม่สามารถสร้าง${this.description}ได้  ${err?.response?.data?.error?.message}`,
          )
        })
    }
  }

  editOne(id, payload) {
    return async (dispatch) => {
      await api
        .put(`${process.env.REACT_APP_API_URL}/${this.backendPrefix}/${id}`, payload)
        .then((res) => {
          dispatch({ type: this.putConst })
        })
        .catch((err) => {
          dispatch({ type: this.errorConst, payload: null })

          throw new Error(
            `ไม่สามารถแก้ไข${this.description}ได้  ${err?.response?.data?.error?.message}`,
          )
        })
    }
  }

  deleteOne(id) {
    return async (dispatch) => {
      await api
        .delete(`${process.env.REACT_APP_API_URL}/${this.backendPrefix}/${id}`)
        .then((res) => {
          console.log("Request Server to Delete One Promotion")
          dispatch({ type: this.deleteConst, payload: null })
        })
        .catch((err) => {
          throw new Error(`ไม่สามารถลบ${this.description} ${err?.response?.data?.error?.message}`)
        })
    }
  }
}

export default GeneralReduxAction
