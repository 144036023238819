import Diprom1Homepage from "./Diprom1Homepage"
import Diprom2Homepage from "./Diprom2Homepage"
import Diprom3Homepage from "./Diprom3Homepage"
import Type1Homepage from "./Type1Homepage"
import Type2Homepage from "./Type2Homepage"
import Type3Homepage from "./Type3Homepage"

export {
  Diprom1Homepage,
  Diprom2Homepage,
  Diprom3Homepage,
  Type1Homepage,
  Type2Homepage,
  Type3Homepage,
}
export default {
  Diprom1Homepage,
  Diprom2Homepage,
  Diprom3Homepage,
  Type1Homepage,
  Type2Homepage,
  Type3Homepage,
}
