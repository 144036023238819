/* eslint-disable no-underscore-dangle */
import React from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export default function PriceCalculationButton({ bookingInfo, handleEditBooking, rooms }) {
  const { t } = useTranslation()
  return (
    <div>
      <Link to={`/reservation/price-calculation/${bookingInfo?._id}`}>
        <Button size="sm" colorScheme="cyan">
          {t("reservation.priceCalculation")}
        </Button>
      </Link>
    </div>
  )
}
