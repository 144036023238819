import React, { useState } from "react"
import { Textarea, FormControl, Button, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

import * as actions from "../../redux/actions"

const BookingRemarkPanel = ({ bookingInfo, useGetOne = false, page = 1, size = 10 }) => {
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const toast = useToast()
  const { t } = useTranslation()

  const handleChangeRemark = (data) => {
    dispatch(actions.editOneBooking(bookingInfo.id, data))
      .then(() => {
        if (useGetOne) {
          dispatch(actions.getOneBooking(bookingInfo.id))
            .then(() => {
              toast({
                title: t("general.successEdit"),
                description: t("reservation.remarkSuccessEdit"),
                duration: 3000,
                status: "success",
              })
            })
            .catch((err) => {
              console.error(err)
              toast({
                title: t("general.unSuccessEdit"),
                description: t("general.tryAgain"),
                duration: 3000,
                status: "error",
              })
            })
        } else {
          dispatch(actions.getAllBooking({ page, size }))
            .then(() => {
              toast({
                title: t("general.successEdit"),
                description: t("reservation.remarkSuccessEdit"),
                duration: 3000,
                status: "success",
              })
            })
            .catch((err) => {
              console.error(err)
              toast({
                title: t("general.unSuccessEdit"),
                description: t("general.tryAgain"),
                duration: 3000,
                status: "error",
              })
            })
        }
        setIsEditorMode(false)
      })
      .catch((err) => {
        console.error(err)
        toast({
          title: t("general.unSuccessEdit"),
          description: t("general.tryAgain"),
          duration: 3000,
          status: "error",
        })
      })
  }

  if (isEditorMode === false)
    return (
      <div>
        <div className="my-2 w-full lg:w-1/2">
          <h6 className="text-sm font-bold mt-2 font-sans">
            {t("reservation.remark")}{" "}
            <Button
              size="xs"
              className="mx-2"
              leftIcon={<i className="fas fa-pencil" />}
              onClick={() => setIsEditorMode(true)}
            >
              {t("general.edit")}
            </Button>
          </h6>
          <p className="text-sm font-default">{bookingInfo?.remark || "-"} </p>
          <br />
        </div>
      </div>
    )

  return (
    <div>
      <div className="my-2 w-full ">
        <form>
          <FormControl>
            <h6 className="text-xs font-bold mt-2 font-sans">{t("reservation.remark")}</h6>
            <Textarea
              ref={register}
              name="remark"
              defaultValue={bookingInfo?.remark}
              placeholder={t("reservation.reservationRemark")}
              rows={3}
              size="sm"
            ></Textarea>
          </FormControl>
          <div className="flex w-full justify-end gap-1 mt-2">
            <Button
              colorScheme="blue"
              size="sm"
              type="button"
              onClick={handleSubmit(handleChangeRemark)}
            >
              {t("general.save")}
            </Button>
            <Button
              colorScheme="gray"
              size="sm"
              type="button"
              onClick={() => setIsEditorMode(false)}
            >
              {t("general.cancle")}
            </Button>
          </div>
        </form>
        <br />
      </div>
    </div>
  )
}

export default BookingRemarkPanel
