import React, { useState } from "react"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  Button,
} from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import { BookingStatus } from "../../../config/constant/BookingStatus"
import { paymentStatus } from "../../../config/constant/PaymentStatus"
import { TransactionType } from "../../../config/constant"
import Confirm from "../../common/Alert/ConfirmDialog"

export default function ModalCheckOut({ isOpen, handleOnClose, bookingInfo, handleEditBooking }) {
  const [isConfirmKeepLogOpen, setIsConfirmKeeplogOpen] = useState(false)
  const { t } = useTranslation()

  const handleStatusToCheckout = () => {
    if (bookingInfo?.paymentStatus === paymentStatus.allPayment.allPaymentConfirm) {
      handleEditBooking(bookingInfo?.id, {
        status: BookingStatus.checkedOut,
        transaction_type: TransactionType.GUEST_CHECKOUT.status_code,
        transaction_group: TransactionType.GUEST_CHECKOUT.group.status_code,
      })
      setIsConfirmKeeplogOpen(true)
    } else {
      setIsConfirmKeeplogOpen(true)
    }
  }

  const forceHandleCheckout = () => {
    handleEditBooking(bookingInfo?.id, {
      status: BookingStatus.checkedOut,
      transaction_type: TransactionType.GUEST_CHECKOUT.status_code,
      transaction_group: TransactionType.GUEST_CHECKOUT.group.status_code,
      info: "เช็คเอาท์โดยที่ข้อมูลการชำระเงินยังไม่ครบ",
    })
    handleOnClose()
    setIsConfirmKeeplogOpen(false)
  }

  const handleSuccess = () => {
    handleEditBooking(bookingInfo?.id, {
      status: BookingStatus.success,
      info: "เปลี่ยนสถานะเป็นสำเร็จ เก็บข้อมูลไว้ในประวัติการจอง",
    })
    handleOnClose()
    setIsConfirmKeeplogOpen(false)
  }

  const paymentRequireAlertComponent = () => (
    <div className="m-2 p-4 bg-yellow-200 text-yellow-900   rounded-lg text-center">
      <h4 className="font-bold"> {t("reservation.inCompletePayment")} </h4>
      <p className="text-sm px-2">
        {t("reservation.presentStatus")} <b>{bookingInfo?.paymentStatus}</b> <br />{" "}
      </p>
      <p className="text-xs px-2">{t("reservation.instructionIncompletePaymentCheckout")}</p>
    </div>
  )

  return (
    <div>
      <Confirm
        title={t("reservation.confirmKeepHistory")}
        body={t("reservation.confirmCompleteToHistory")}
        handleOnClose={forceHandleCheckout}
        handleSuccess={handleSuccess}
        isOpen={isConfirmKeepLogOpen}
        successText={t("general.confirm")}
        cancleText={t("reservation.checkoutOnly")}
      />
      <form>
        <Modal isOpen={isOpen} onClose={handleOnClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader className="font-sans" fontSize="lg">
              {t("reservation.checkout")}
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody className="font-sans mb-4 text-center">
              {bookingInfo?.paymentStatus !== paymentStatus.allPayment.allPaymentConfirm &&
                paymentRequireAlertComponent()}
              <p className=" text-gray-700">{t("reservation.instructionCheckCustomerRoom")}</p>
              <div className="flex gap-2 mt-2 justify-center">
                <Button onClick={handleStatusToCheckout} className="text-sm" colorScheme="green">
                  <i className="mr-2 fas fa-user-check" /> {t("general.confirm")}
                </Button>
                <Button size="md" className="text-sm" onClick={handleOnClose}>
                  {t("general.cancle")}
                </Button>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </form>
    </div>
  )
}
