import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel } from "../../components"
import * as actions from "../../redux/actions"

function AuthorizationCheck({ setIsOuterLogin, categoryTitle, title }) {
  const { t } = useTranslation()
  const me = useSelector((state) => state.me)
  const captchaRef = useRef(null)
  const dispatch = useDispatch()
  const [isCustomerLogin, setIsCustomerLogin] = useState(false)
  const params = useParams()

  const onloadTurnstileCallback = () => {
    try {
      turnstile.render("#example-container", {
        sitekey: "0x4AAAAAAAEAxcvhsC8cf5Sr",
        callback(token) {
          console.log(`Challenge Success ${token}`)
          dispatch(
            actions.loginForCustomerWithCaptcha({
              token,
              booking: params.id,
            }),
          )
        },
      })
    } catch (error) {
      alert(`ระบบไม่สามารถเช็คสิทธิผู้ใช้งานได้ ${error?.message}`)
    }
  }

  useEffect(() => {
    if (isCustomerLogin) {
      onloadTurnstileCallback()
    }
    return () => {}
  }, [isCustomerLogin])

  useEffect(() => {
    if (me?.token && me?.success) {
      setIsOuterLogin(false)
    }

    return () => {}
  }, [me])

  return (
    <div className=" my-14">
      <Layout categoryTitle={categoryTitle} title={title}>
        <div className=" relative bg-gray-200">
          <WhitePanel>
            <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
              {isCustomerLogin ? (
                <div>
                  <div
                    className="cf-turnstile"
                    data-sitekey="0x4AAAAAAAEAxcvhsC8cf5Sr"
                    // onLoad={onloadTurnstileCallback}
                    ref={captchaRef}
                    id="example-container"
                  ></div>
                </div>
              ) : (
                <div>
                  <div className="self-center text-center font-semibold text-xl">
                    ต้องการใช้งานในรูปแบบของ
                  </div>
                  <div className="w-full md:w-6/12 px-4 mr-auto ml-auto ">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                      <div className="my-4  px-4">
                        <div
                          className=" bg-purple-700 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 cursor-pointer"
                          onClick={() => setIsCustomerLogin(true)}
                        >
                          <i className="fas fa-users fa-3x text-white"></i>
                          <p className="text-lg text-white mt-4 font-semibold">ลูกค้า</p>
                        </div>
                      </div>{" "}
                      <div className="my-4  px-4">
                        <Link to="/auth/login">
                          <div className=" bg-pink-600 shadow-lg rounded-lg text-center  py-8 px-4 lg:h-40 ">
                            <i className="fas fa-chart-bar fa-3x text-white"></i>
                            <p className="text-lg text-white mt-4 font-semibold">พนักงาน</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </WhitePanel>
        </div>
      </Layout>
    </div>
  )
}

export default AuthorizationCheck
