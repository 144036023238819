/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Button,
  useDisclosure,
} from "@chakra-ui/react"
import _ from "lodash"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import ModalUploadToBackgroundGallery from "../../components/Modal/HotelSites/ModalUploadToBackgroundGallery"
import * as actions from "../../redux/actions"
import { Alert } from "../../components/common/Alert"

export default function BackgroundManagement() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const backgroundgallery = useSelector((state) => state.backgroundgallery)


  useEffect(() => {
    dispatch(actions.getAllBackgroundGallery()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const handleOnDeleteBackgroundGallery = (galleryId) => {
    const confirm = window.confirm("ยืนยันการลบแกลอรี่นี้")
    if (confirm) {
      dispatch(actions.deleteOneBackgroundGallery(galleryId))
        .then(() => {
          dispatch(actions.getAllBackgroundGallery())
        })
        .catch((error) => {
          setIsError(true)
          setErrorMessage(`ขออภัย ไม่สามารถลบแกลอรี่ได้ ${error?.message}`)
        })
    }
  }

  const renderAlert = () => (
    <Alert isError={isError} handleOnClose={() => setIsError(false)} errMessage={errorMessage} />
  )

  return (
      <Layout categoryTitle={`${t("site.title")} `} title={"ภาพพื้นหลังส่วนประกอบ"}>
         {renderAlert()}
      <div className="flex gap-2 px-4">
        <Button
          colorScheme="purple"
          onClick={() => {
            setIsModalOpen(true)
          }}
        >
          เพิ่ม
        </Button>
      </div>
      <div className="p-4 w-full min-h-screen">
        <ModalUploadToBackgroundGallery
          handleOnClose={() => {
            setIsModalOpen(false)
          }}
          isOpen={isModalOpen}
          galleryInfo={backgroundgallery || { images: [] }}
        />
        <div className="flex gap-2 flex-wrap ">
          {_.map(backgroundgallery?.arr, (eachPhoto, index) => (
            <div
              key={index}
              className="m-4 border border-gray-300 rounded-md shadow-md flex flex-col "
            >
                  <img src={eachPhoto?.image?.url} className="w-64 my-auto " />
              <div className="my-4 flex justify-center" >
                <Button 
                colorScheme="red"
                 size="sm" 
                 onClick={() => handleOnDeleteBackgroundGallery(eachPhoto?.id)}>
                  ลบ
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}
