import { BED_ALL, BED_CREATE, BED_GET, BED_PUT, BED_DELETE } from "../../actions/types"

const initialState = {
  beds: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case BED_ALL:
      return {
        arr: action.payload,
        isLoading: true,
      }
    case BED_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case BED_CREATE:
      return { isLoading: true }
    case BED_PUT:
      return { isLoading: true }
    case BED_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
