/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Button, Checkbox, useDisclosure, ButtonGroup } from "@chakra-ui/react"
import { Link, useHistory } from "react-router-dom"
import { DatePicker, Input as AntdInput } from "antd"

import Layout from "../../../components/layout/BookingLayout"
import { selectTailwindStatusColor } from "../../../util/colors"
import { WhitePanel, Dot, Alert, SpinnerLoading, ModalNoRoomBooking } from "../../../components"
import * as actions from "../../../redux/actions"
import { BookingStatus } from "../../../config/constant"
import { BookingSourceBadge } from "../../../components/common/Badge"
import { useQuery } from "../../../config"

export default function RoomReservationStatus() {
  const query = useQuery()
  const [isLoading, setIsLoading] = useState(false)
  const [checkedInDate, setCheckedInDate] = useState(
    dayjs(query.get("checkin") || new Date()).toDate(),
  )
  const [page, setPage] = useState(1)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [isShowTime, setIsShowTime] = useState(false)
  const [isShowAllDay, setIsShowAllDay] = useState(true)
  const room = useSelector((state) => state.rooms)
  const roomtype = useSelector((state) => state.roomTypes)
  const history = useHistory()
  const [fetchingNewOne, setFetchingNewOne] = useState(true)
  const [noRoomBookingList, setNoRoomBookingList] = useState([])

  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const getAllBooking = () => {
    dispatch(
      actions.getAllRoomsWithBooking({
        name,
        date: checkedInDate ? dayjs(checkedInDate).format("YYYY-MM-DD") : "",
        showAllDay: isShowAllDay || "",
      }),
    )
      .then(() => {
        dispatch(
          actions.getAllRoomTypes({
            name,
            date: checkedInDate ? dayjs(checkedInDate).format("YYYY-MM-DD") : "",
            showAllDay: isShowAllDay || "",
            fetchBooking: true,
          }),
        )

        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  useEffect(() => {
    setIsLoading(false)
    getAllBooking()
    return () => {}
  }, [page, name, checkedInDate, isShowAllDay])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  // รีเฟรชทุกๆ 20 วินาที
  useEffect(() => {
    if (fetchingNewOne === false)
      setTimeout(() => {
        setFetchingNewOne(!fetchingNewOne)
      }, 10000)
    return () => {}
  }, [fetchingNewOne])

  useEffect(() => {
    if (fetchingNewOne) {
      // setIsLoading(false)
      getAllBooking()
      setFetchingNewOne(!fetchingNewOne)
    }
    return () => {}
  }, [fetchingNewOne])

  const handlePreviousDay = () => {
    const newDate = dayjs(checkedInDate).subtract(1, "day").toDate()
    setCheckedInDate(newDate)
  }

  const handleNextDay = () => {
    const newDate = dayjs(checkedInDate).add(1, "day").toDate()
    setCheckedInDate(newDate)
  }

  const groupByTypeRoom = _.groupBy(room?.arr, "type_id")

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  const renderBackButton = () => (
    <div className="flex justify-between flex-wrap px-4 mb-2 items-center  ">
      <div className="lg:w-1/5">
        <Button colorScheme="purple" onClick={() => history.push("/reservation/create")}>
          {t("general.add")}
        </Button>
      </div>
      <div className="w-full lg:w-3/5 my-2 flex flex-wrap lg:flex-no-wrap justify-between lg:justify-end gap-2">
        <ButtonGroup isAttached size="sm" variant="outline">
          <Button
            onClick={() => {
              history.push("/reservation/list")
            }}
          >
            รายการ
          </Button>{" "}
          <Button isActive>ห้อง</Button>
          <Button
            onClick={() => {
              history.push("/reservation/calendar")
            }}
          >
            ปฏิทิน
          </Button>
          <Button
            onClick={() => {
              history.push("/reservation/schedule")
            }}
          >
            ตาราง
          </Button>
        </ButtonGroup>
      </div>{" "}
    </div>
  )

  const renderDate = () => (
    <div className="flex flex-wrap justify-between my-2 lg:my-0">
      <div className="my-2 text-xl font-semibold lg:w-1/2">
        {dayjs(checkedInDate).locale(i18n.language).format("ddd D MMMM YYYY")}
      </div>
      <div className="flex gap-2 lg:w-1/3">
        <Checkbox isChecked={isShowTime} onChange={(e) => setIsShowTime(e.target.checked)}>
          แสดงเวลา{" "}
        </Checkbox>
        <Checkbox isChecked={isShowAllDay} onChange={(e) => setIsShowAllDay(e.target.checked)}>
          แสดงสถานะทั้งวัน
        </Checkbox>
      </div>
    </div>
  )
  const renderTypeList = () =>
    _.map(groupByTypeRoom, (eachType, index) => {
      const selectedRoomType = _.find(
        roomtype?.arr,
        (each) => each?._id === eachType?.[0]?.type?._id,
      )

      const roomAmount = _.size(eachType)
      const bookingAmount = _.sum(_.map(eachType, (eachRoom) => _.size(eachRoom?.bookings)))

      return (
        <div key={index}>
          <div className="w-full p-2 my-1  rounded-md bg-gray-200">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/3 self-center">
                <div className="font-bold ">
                  {selectedRoomType?.translation?.[i18n.language]?.name ||
                    selectedRoomType?.name ||
                    "-"}
                </div>
              </div>
              <div className="w-full lg:w-2/3">
                <div className="flex gap-2 my-2">
                  <div className="md:w-1/4">
                    ห้อง <div className="">{roomAmount} ห้อง</div>
                  </div>{" "}
                  {!isShowAllDay && (
                    <div className="md:w-1/4">
                      จองระบุห้อง
                      <div className=""> {bookingAmount || 0} ห้อง</div>
                    </div>
                  )}
                  {!_.isEmpty(selectedRoomType?.bookings) && (
                    <div
                      className="md:w-1/4 font-semibold text-red-500 hover:text-red-700 hover:underline cursor-pointer"
                      onClick={() => {
                        setNoRoomBookingList(selectedRoomType?.bookings)
                        onOpen()
                      }}
                    >
                      รอการระบุห้อง{" "}
                      <div className="">{selectedRoomType?.no_room_booking_amount || 0} ห้อง</div>
                    </div>
                  )}
                  {!isShowAllDay && (
                    <div className="md:w-1/4">
                      ว่าง
                      <div className="">
                        {" "}
                        {roomAmount -
                          bookingAmount -
                          (selectedRoomType?.no_room_booking_amount || 0) || 0}{" "}
                        ห้อง
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="my-2">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 ">
              {eachType &&
                _.map(eachType, (eachRoom, roomIndex) => {
                  const filterCheckoutRoomType = _.filter(eachRoom?.bookings, (eachBooking) => {
                    const sameday = dayjs(eachBooking?.end).isSame(checkedInDate, "day")
                    const statusSuccess = eachBooking?.status === BookingStatus.success
                    return !(sameday && statusSuccess)
                  })
                  return (
                    <div
                      className={`p-2 rounded-md my-2 border ${
                        _.size(filterCheckoutRoomType) === 0 ? "bg-teal-200" : "bg-white"
                      }  `}
                      key={roomIndex}
                    >
                      <div className="font-semibold text-lg">{eachRoom?.name}</div>
                      <div className="flex flex-col gap-2 ">
                        {_.map(eachRoom?.bookings, (eachBooking, bookingIndex) => (
                          <Link
                            to={
                              eachBooking?.status !== BookingStatus.success
                                ? `/reservation/view/${eachBooking?._id}`
                                : `/history/view/${eachBooking?._id}`
                            }
                          >
                            <div
                              key={`booking${bookingIndex}`}
                              className={`p-2 my-2 rounded-sm  border w-full hover:shadow-lg ${
                                _.includes(
                                  [
                                    BookingStatus.book,
                                    BookingStatus.confirm,
                                    BookingStatus.pending,
                                  ],
                                  eachBooking?.status,
                                )
                                  ? "bg-yellow-100"
                                  : _.includes(
                                      [BookingStatus.checkedOut, BookingStatus.success],
                                      eachBooking?.status,
                                    )
                                  ? dayjs(eachBooking?.end).isSame(checkedInDate, "day")
                                    ? "hidden"
                                    : "bg-purple-100"
                                  : !dayjs(eachBooking?.end).isSame(checkedInDate, "day")
                                  ? "bg-pink-200"
                                  : "bg-orange-200"
                              }`}
                            >
                              <div>
                                {dayjs(eachBooking?.end).isSame(checkedInDate, "day") &&
                                !_.includes(
                                  [BookingStatus.checkedOut, BookingStatus.success],
                                  eachBooking?.status,
                                ) ? (
                                  <span className="font-semibold my-2">รอคืนห้อง</span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="font-semibold">No. {eachBooking?.booking_no}</div>
                              <div>
                                <BookingSourceBadge sourceOfBooking={eachBooking?.booking_source} />
                              </div>
                              <div className="mt-4">
                                <i className="fas fa-user mr-2"></i>{" "}
                                {eachBooking?.customer?.firstname || ""}{" "}
                                {eachBooking?.customer?.lastname || ""}{" "}
                              </div>
                              <div>
                                <i className="fas fa-sign-in-alt mr-2"></i>
                                {dayjs(eachBooking?.start)
                                  .locale(i18n.language)
                                  ?.format(isShowTime ? "D MMM  HH:mm" : "D MMM")}{" "}
                                <i className="fas fa-sign-out-alt mr-2"></i>
                                {dayjs(eachBooking?.end)
                                  .locale(i18n.language)
                                  ?.format(isShowTime ? "D MMM  HH:mm" : "D MMM")}{" "}
                              </div>
                              <div>
                                <Dot
                                  word={eachBooking?.status}
                                  color={selectTailwindStatusColor(eachBooking.status)}
                                />
                              </div>
                            </div>{" "}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      )
    })

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.roomViewReservation")}>
      <div className="w-full">
        {renderAlert()}
        {renderBackButton()}
        <ModalNoRoomBooking
          handleClose={() => {
            onClose()
            setNoRoomBookingList([])
          }}
          isOpen={isOpen}
          noRoomBookingList={noRoomBookingList}
          roomTypeList={roomtype}
        />
        <div className="px-4">
          <div className="flex my-2">
            <div className="w-full md:w-1/2 lg:w-1/3">
              <AntdInput
                placeholder="input search text"
                addonBefore={<i className="fas fa-search" />}
                allowClear
                onChange={(e) => setSearchWord(e.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </div>
          </div>
          <WhitePanel>
            {renderDate()}
            <div className="flex flex-wrap justify-between">
              <div className="w-full lg:w-2/3 grid grid-cols-2 h-20 my-2 lg:my-0">
                <Dot color="green" word="ว่าง" />
                <Dot color="yellow" word="จองไว้ ยังไม่เช็คอิน" />
                <Dot color="pink" word="เข้าพักอยู่" />
                <Dot color="orange" word="เตรียมจะเช็คเอาท์" />
                <Dot color="purple" word="เช็คเอาท์แล้ว" />
                <div className="text-sm col-span-2 my-2">ระบบจะอัพเดทอัตโนมัติทุกๆ 10 วินาที</div>
              </div>
              <div className="w-full lg:w-1/3 my-2">
                <div className="text-sm mt-2 font-semibold ">วันที่ต้องการค้นหา</div>
                <div className=" p-1 rounded-sm flex lg:justify-end">
                  <DatePicker
                    value={dayjs(checkedInDate)}
                    onChange={(value) => setCheckedInDate(value.toDate())}
                    allowClear
                    placeholder="วันที่ต้องการค้นหา"
                    size="large"
                    style={{ width: "100%" }}
                    format="D MMM YYYY"
                  />
                </div>
                <div className="flex gap-2 my-2 lg:my-0">
                  <Button
                    size="sm"
                    leftIcon={<i className="fas fa-chevron-left mr-1"></i>}
                    onClick={handlePreviousDay}
                  >
                    ย้อนกลับ 1 วัน
                  </Button>
                  <Button
                    size="sm"
                    leftIcon={<i className="fas fa-chevron-right mr-1"></i>}
                    onClick={handleNextDay}
                  >
                    ไปหน้า 1 วัน
                  </Button>
                </div>
              </div>
            </div>
          </WhitePanel>{" "}
        </div>
        {isLoading ? (
          <div className="m-4">
            <div className="grid lg:grid-cols-2 gap-2">{renderTypeList()}</div>
          </div>
        ) : (
          <div>
            <SpinnerLoading />
          </div>
        )}
      </div>
    </Layout>
  )
}
