import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link, useHistory } from "react-router-dom"
import DayPickerInput from "react-day-picker/DayPickerInput"
import dayjs from "dayjs"

import Layout from "../../components/layout/GeneralLayout"
import SpinnersLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../util"
import DashboardMonitorComponent from "../../components/Panels/DashboardMonitorComponent"

export default function Dashboard() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const logs = useSelector((state) => state.logs)
  const customers = useSelector((state) => state.customers)
  const bookings = useSelector((state) => state.bookings)
  const rooms = useSelector((state) => state.rooms)

  const [startDate, setStartDate] = useState(dayjs().startOf("month").toDate())
  const [endDate, setEndDate] = useState(dayjs().endOf("month").toDate())
  // const [periodText, setPeriodText] = useState("ในเดือนล่าสุด")

  // console.log("startDate", dayjs(startDate).year())

  useEffect(() => {
    const fetchLogsTransaction = async () => {
      try {
        await dispatch(
          actions.getAlllog({
            page: 1,
            startDate: dayjs(startDate).toISOString(),
            endDate: dayjs(endDate).toISOString(),
          }),
        )
        setIsLoading(true)
      } catch (error) {
        console.error(error)
      }
    }
    const fetchCustomers = async () => {
      try {
        await dispatch(actions.getAllCustomers(1, "", ""))
      } catch (error) {
        console.error(error)
      }
    }
    const fetchRooms = async () => {
      try {
        await dispatch(actions.getAllRooms())
      } catch (error) {
        console.error(error)
      }
    }
    const fetchBooking = async () => {
      try {
        await dispatch(actions.getAllBooking({ page: 1, startDate, endDate }))
      } catch (error) {
        console.error(error)
      }
    }

    fetchCustomers()
    fetchRooms()
    fetchBooking()
    fetchLogsTransaction()

    return () => {}
  }, [startDate, endDate])

  if (!isLoading) {
    return <SpinnersLoading />
  }

  const TopMenu = () => (
    <div className="flex justify-between px-4 ">
      <div>
        <Button
          variant="outline"
          colorScheme="purple"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
    </div>
  )

  const DateMenu = () => (
    <div>
      <div className="grid md:grid-cols-3 pb-2 gap-x-2 gap-y-2 p-3 pt-5 ">
        <div className="p-2">
          <div className="text-lg font-bold">
            ช่วงเวลา{" "}
            <span className="text-2xl font-semibold">
              {dayjs(endDate).diff(startDate, "days")} วัน{" "}
            </span>
          </div>
          <div className="font-semibold">
            {dayjs(startDate)?.format("D MMMM")} - {dayjs(endDate)?.format("D MMMM")}
          </div>
          <div className="flex gap-1 my-2">
            <Button
              size="sm"
              variant="outline"
              colorScheme="purple"
              onClick={() => {
                setStartDate(dayjs().startOf("month").toDate())
                setEndDate(dayjs().toDate())
              }}
            >
              เดือนปัจจุบัน
            </Button>

            <Button
              size="sm"
              variant="outline"
              colorScheme="purple"
              onClick={() => {
                setStartDate(dayjs().subtract(3, "month").startOf("month").toDate())
                setEndDate(dayjs().toDate())
              }}
            >
              3 เดือน
            </Button>

            <Button
              size="sm"
              variant="outline"
              colorScheme="purple"
              onClick={() => {
                setStartDate(dayjs().subtract(1, "year").startOf("year").toDate())
                setEndDate(dayjs().toDate())
              }}
            >
              1 ปี
            </Button>
          </div>
        </div>
        <div className="p-2">
          <div>วันที่เริ่ม</div>
          <div className="min-w-min-content border round-sm shadow-md p-2 bg-white ">
            <DayPickerInput
              formatDate={(date) => dayjs(date)?.format("D MMM YYYY")}
              dayPickerProps={{
                locale: "th",
                months: MONTHS,
                weekdaysLong: WEEKDAYS_LONG,
                weekdaysShort: WEEKDAYS_SHORT,
              }}
              onDayChange={(newValue) => {
                setStartDate(newValue)
                setIsLoading(false)
              }}
              value={startDate}
            />
          </div>
        </div>
        <div className="p-2">
          <div>วันที่สิ้นสุด</div>
          <div className="min-w-min-content border round-sm shadow-md p-2 bg-white ">
            <DayPickerInput
              formatDate={(date) => dayjs(date)?.format("D MMM YYYY")}
              dayPickerProps={{
                locale: "th",
                months: MONTHS,
                weekdaysLong: WEEKDAYS_LONG,
                weekdaysShort: WEEKDAYS_SHORT,
              }}
              onDayChange={(newValue) => {
                setEndDate(newValue)
                setIsLoading(false)
              }}
              value={endDate}
            />
          </div>
        </div>
      </div>
    </div>
  )

  const renderDashbaordMenu = () => (
    <div className="m-4">
      <div className="text-xl font-semibold">รายการรายงาน</div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <div className="my-4  px-4">
          <Link to={"/history/booking"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-calendar-alt  fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">รายงานการจอง</h4>
              <div className="text-base text-white font-semibold ">Booking Report</div>
            </div>
          </Link>
        </div>{" "}
        <div className="my-4  px-4">
          <Link to={"/history/booking"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-door-open  fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">รายงานห้องพัก</h4>
              <div className="text-base text-white font-semibold ">Room Report</div>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={"/history/payment-transaction"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-money-bill fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">รายงานการชำระเงิน</h4>
              <div className="text-base text-white font-semibold ">Payment Report</div>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={"/history/inhouse-guest"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-users fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">รายงานแขกเข้าพัก</h4>
              <div className="text-base text-white font-semibold ">Inhouse Guest</div>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={"/history/checkin-report"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-sign-in-alt fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">รายงานเช็คอิน</h4>
              <div className="text-base text-white font-semibold ">Checkin Report</div>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={"/history/checkout-report"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-sign-out-alt fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">รายงานเช็คเอาท์</h4>
              <div className="text-base text-white font-semibold ">Checkout Report</div>
            </div>
          </Link>
        </div>{" "}
        <div className="my-4  px-4">
          <Link to={"/history/housekeeper"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-broom fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">รายงานแมบ้าน</h4>
              <div className="text-base text-white font-semibold ">housekeeper Report</div>
            </div>
          </Link>
        </div>
        <div className="my-4  px-4">
          <Link to={"/history/monthy-forecast"}>
            <div className=" bg-gray-400 hover:bg-gray-500 shadow-lg rounded-lg text-center hover:shadow-xl py-8 px-4 lg:h-40  duration-150 ease-in-out ">
              <i className="fas fa-calendar-alt fa-3x text-white"></i>
              <h4 className="text-lg text-white mt-4 font-semibold ">พยากรณ์การจองรายเดือน</h4>
              <div className="text-base text-white font-semibold ">Monthy Forecast</div>
            </div>
          </Link>
        </div>
      </div>{" "}
    </div>
  )
  return (
    <Layout categoryTitle="รายงานและประวัติ" title="สรุปยอดการจองทั้งหมด">
      <TopMenu />
      <DateMenu />
      <DashboardMonitorComponent
        startDate={startDate}
        endDate={endDate}
        logs={logs}
        isLoading={isLoading}
        bookings={bookings}
        customers={customers}
        rooms={rooms}
      />
      {renderDashbaordMenu()}
    </Layout>
  )
}
