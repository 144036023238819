import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
} from "@chakra-ui/react"
import _ from "lodash"

import UploadImage from "../../ImageUpload/ImageUpload"
import * as actions from "../../../redux/actions"
import DialogLoading from "../../Loading/DialogLoading"
import Alert from "../../common/Alert/AlertDialog"

export default function ModalUploadToGallery({ isOpen, handleOnClose, galleryInfo }) {
  console.log(galleryInfo)
  const dispatch = useDispatch()
  const [images, setImages] = useState([])
  const [isLoadingDialogShow, setIsLoadingDialogShow] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()

  const handleOnUpload = () => {
    setIsLoadingDialogShow(true)


    // const galleryPayload = galleryInfo?.images

    // try {
    //   galleryPayload.push(imagePayload)
    //   console.log("Image Payload can push", galleryPayload)
    // } catch (error) {
    //   setErrMessage("ไม่สามารถเพิ่มรูปภาพได้")
    //   setIsError(true)
    //   console.error("Cannot Push Image ", error)
    // }
console.log('Image',images)
    dispatch(actions.createBackgroundGallery( { image: images[0].data_url, imageType: "gallery" }))
      .then(() => {
        dispatch(actions.getAllBackgroundGallery({})).then(() => {
          handleOnClose()
          setIsLoadingDialogShow(false)
          setImages([])
        })
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <DialogLoading isLoading={isLoadingDialogShow} title="กำลังอัพโหลดรูปภาพ" />
      <Alert
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage()
        }}
        isError={isError}
      />
      <ModalContent>
        <ModalHeader className="font-sans" textStyle={{ fontsize: "sm" }}>
          เพิ่มรูปภาพ
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <UploadImage images={images} setImages={setImages} preview_size="100" maxNumber={1} />
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2 font-sans">
            <Button size="sm" colorScheme="green" onClick={handleOnUpload}>
              อัพโหลด
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
