import React, { useState, useEffect } from "react"
import { Button, Radio, RadioGroup } from "@chakra-ui/react"
import { useSelector, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import _ from "lodash"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import { CRMType } from "../../../config/constant"

export default function EditCRM() {
  const history = useHistory()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { handleSubmit, register } = useForm()
  const customers = useSelector((state) => state.customers)
  const relationships = useSelector((state) => state.relationships)

  const onEditRelationship = (data) => {
    alert("บันทึกการแก้ไขสำเร็จ")
    const relationshipsId = params?.id
    console.log(data)
    dispatch(actions.editOneRelationship(relationshipsId, data)).then(() => {})
    history.goBack()
  }

  useEffect(() => {
    const relationshipsId = params?.id
    dispatch(actions.getOneRelationship(relationshipsId)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout
      categoryTitle="จัดการลูกค้า"
      title={`คุณ ${customers?.firstname} ${customers?.lastname}`}
    >
      <div className="flex justify-between px-4 gap-2">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onEditRelationship)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <div className="">
                <h6 className="text-gray-900 text-base my-3 font-bold uppercase font-sans ">
                  แก้ไขความสัมพันธ์กับลูกค้า
                </h6>
                <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
                  <div className="w-full px-0 ">
                    <div className="relative w-full mb-3 ">
                      <label
                        className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                        htmlFor="grid-password"
                      >
                        เรื่อง
                      </label>
                      <RadioGroup value={relationships?.title}>
                        <div className="grid grid-cols-2">
                          {_.map(CRMType, (eachCRMType, index) => (
                            <Radio
                              value={eachCRMType?.status_code}
                              name="title"
                              key={index}
                              size="sm"
                              ref={register}
                            >
                              {eachCRMType?.description}
                            </Radio>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="w-full px-0">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                        htmlFor="grid-password"
                      >
                        รายละเอียด
                      </label>
                      <textarea
                        name="message"
                        type="text"
                        ref={register}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="รายละเอียด"
                        defaultValue={relationships?.message}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึกการแก้ไข
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
