/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, useToast } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/GeneralLayout"
import { SpinnerLoading, ModalAddOrEditRoom, RoomList } from "../../../components"
import * as actions from "../../../redux/actions"

export default function RoomTypeInfo() {
  const history = useHistory()
  const params = useParams()
  const rooms = useSelector((state) => state.rooms)
  const roomTypes = useSelector((state) => state.roomTypes)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalEditRoomOpen, setisModalEditRoomOpen] = useState(false)
  const [isModalAddRoomOpen, setIsModalAddRoomOpen] = useState(false)
  const [currentRoom, setCurrentRoom] = useState()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const toast = useToast()

  const getAllRooms = () => {
    const roomTypeId = params?.id
    dispatch(actions.getRoomType(roomTypeId)).then(() => {
      dispatch(actions.getRoomsFromRoomType(roomTypeId)).then(() => {
        setIsLoading(true)
      })
    })
  }

  useEffect(() => {
    getAllRooms()
    return () => {}
  }, [params])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  const handleDeleteRoom = (roomId) => {
    const confirm = window.confirm(t("roomtype.confirmRemoveRoom"))
    if (confirm) {
      dispatch(actions.deleteOneRoom(roomId)).then(() => {
        dispatch(actions.getRoomsFromRoomType(params?.id))
      })
    }
  }

  const handleChangeOnlineBookingOpen = (roomId, destinationStatus) => {
    setIsLoading(false)
    dispatch(
      actions.editOneRoom(roomId, {
        online_booking_open: destinationStatus,
      }),
    )
      .then(() => {
        getAllRooms()
        setIsLoading(true)
      })
      .catch((err) => {
        alert(`แก้ไขห้องพักไม่ได้ ${err?.message}`)
      })
  }

  const handleAddRoom = (data) => {
    dispatch(actions.createOneRoom({ ...data, type_id: roomTypes?._id }))
      .then(() => {
        getAllRooms()
        toast({
          status: "success",
          title: "เพิ่มห้องสำเร็จ",
        })
        setIsModalAddRoomOpen(false)
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "ไม่สามารถเพิ่มห้องใหม่ได้",
          description: err?.message,
        })
      })
  }

  const handleEditRoom = (data) => {
    dispatch(actions.editOneRoom(currentRoom?._id, data))
      .then(() => {
        getAllRooms()
        toast({
          status: "success",
          title: "แก้ไขห้องสำเร็จ",
        })
        setisModalEditRoomOpen(false)
        setCurrentRoom()
      })
      .catch((err) => {
        toast({
          status: "error",
          title: "ไม่สามารถแก้ไขห้องได้",
          description: err?.message,
        })
      })
  }

  return (
    <Layout
      categoryTitle={`${t("roomtype.title")} > ${t("roomtype.roomType")}`}
      title={
        i18n.language === "th" ? roomTypes?.translation?.th?.name : roomTypes?.translation?.en?.name
      }
    >
      <div className="flex justify-between px-4">
        <div className="flex gap-2">
          <Button colorScheme="purple" variant="solid" onClick={() => setIsModalAddRoomOpen(true)}>
            {t("general.add")}
          </Button>
          <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
            {t("general.back")}
          </Button>
        </div>
      </div>
      <div className="p-4 w-full min-h-screen">
        <ModalAddOrEditRoom
          defaultValue={currentRoom}
          isOpen={isModalEditRoomOpen}
          allRoomTypes={[roomTypes]}
          handleAddRoomType={handleEditRoom}
          isEditMode
          handleOnClose={() => {
            setisModalEditRoomOpen(false)
            setCurrentRoom()
          }}
        />
        <ModalAddOrEditRoom
          isOpen={isModalAddRoomOpen}
          handleOnClose={() => {
            setIsModalAddRoomOpen(false)
          }}
          specifyRoomType={params?.id}
          allRoomTypes={[roomTypes]}
          handleAddRoomType={handleAddRoom}
        />

        {_.isEmpty(rooms?.arr) && (
          <div className=" w-full flex p-4 m-4 my-8 justify-center">
            <p>{t("roomtype.emptyCategory")} </p>
          </div>
        )}
        <RoomList
          roomList={rooms?.arr}
          roomTypeList={roomTypes?.arr}
          handleEditRoom={(room) => {
            setCurrentRoom(room)
            setisModalEditRoomOpen(true)
          }}
          handleDeleteRoom={handleDeleteRoom}
          handleChangeOnlineBookingOpen={handleChangeOnlineBookingOpen}
        />
      </div>
    </Layout>
  )
}
