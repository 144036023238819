import React, { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import * as actions from "../../../redux/actions"

// components
// import EmployeeLists from "./components/EmployeeLists";
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import Layout from "../../../components/layout/GeneralLayout"
import { UserLists } from "../../../components/List"
import MainPaginator from "../../../components/common/Paginator/NewPagination"

export default function ManagementUsers() {
  const user = useSelector((state) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.userAll(page, 10)).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [page])

  const handleDelete = (id) => {
    dispatch(actions.userDelete(id)).then(() => {
      dispatch(actions.userAll()).then(() => {
        alert("ลบบัญชีผู้ใช้สำเร็จ")
      })
    })
  }
  const handleUpdate = (id) => {
    history.push(`/system/user/edit/${id}`)
  }

  return isLoading ? (
    <Layout categoryTitle="จัดการระบบ" title="ผู้ใช้ทั้งหมด">
      <div className="flex justify-between p-4">
        <Link to={"/system/employee/create"}>
          <Button colorScheme="purple" variant="solid">
            เพิ่ม
          </Button>
        </Link>
      </div>
      <div className="p-4 w-full">
        {_.size(user?.arr) ? (
          <div className="overflow-x-auto">
            <UserLists user={user?.arr} handleDelelte={handleDelete} handleUpdate={handleUpdate} />
            <MainPaginator currentPage={page} lastPage={user.lastPage} setPage={setPage} />
          </div>
        ) : (
          <div className="flex justify-center p-4">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}
      </div>
    </Layout>
  ) : (
    <SpinnerLoading />
  )
}
