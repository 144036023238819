/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Switch } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useParams, useHistory } from "react-router-dom"
import { DateTime } from "luxon"
import _ from "lodash"
// import { Link } from "react-router-dom"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import SelectedRoomList from "../../../components/List/SelectedRoomList"
import { CheckinForm } from "../../../components/Forms/CRM"
import * as actions from "../../../redux/actions"
import { DialogLoading } from "../../../components/Loading"
import { BookingStatus } from "../../../config/constant"
import api from "../../../config/api"

export default function Checkin() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isImageLoading, setIsImageLoading] = useState(false)
  const [fetchNew, setFetchNew] = useState(false)
  const { register, handleSubmit, setValue } = useForm()
  const [idCardImage, setIdCardImage] = useState()
  const bookings = useSelector((state) => state.bookings)
  const [enableFetching, setEnableFetching] = useState(false)

  useEffect(() => {
    if (fetchNew && enableFetching) {
      console.log("Fetch new")
      api
        .get(`${process.env.REACT_APP_API_URL}/id-card`)
        .then((res) => {
          console.log("resdata", res.data)
          if (res?.data?.cid) {
            setValue("firstname", res?.data?.thName)
            setValue("lastname", res?.data?.thSurname)
            setValue("id_card", res?.data?.cid)
            setValue("address", res?.data?.address)
          }
        })
        .catch((err) => {
          console.error(err)
        })
      setFetchNew(false)
    }

    return () => {}
  }, [fetchNew, enableFetching])

  useEffect(() => {
    let timeout
    if (!fetchNew) {
      timeout = setTimeout(() => {
        setFetchNew(true)
      }, 5000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [fetchNew])

  const onEditCustomer = (data) => {
    console.log("Booking Status ", bookings?.status)
    const bookingCheckinPayload = {
      ...data,
      image: _.isEmpty(idCardImage)
        ? bookings?.checkin?.image?._id
        : {
            imageType: "idCard",
            image: idCardImage?.[0]?.data_url,
          },
    }

    setIsImageLoading(true)
    dispatch(
      actions.editOneBooking(params.id, {
        status: BookingStatus.checkedIn,
        checkin: { ...bookingCheckinPayload, timestamp: new Date() },
      }),
    )
      .then(() => {
        dispatch(actions.editOneCustomer(bookings?.customer?._id, data))
          .then(() => {
            history.goBack()
            setIsImageLoading(false)
          })
          .catch((err) => {
            alert(`ขออภัยการเช็คอินของท่านไม่สำเร็จ ${err?.message}`)
            setIsImageLoading(false)
          })
      })
      .catch((err) => {
        alert(`ขออภัยการเช็คอินของท่านไม่สำเร็จ ${err?.message}`)
        setIsImageLoading(false)
      })
  }

  useEffect(() => {
    const bookingId = params?.id
    dispatch(actions.getOneBooking(bookingId)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการการจอง" title="กรอกข้อมูลสำหรับการเช็คอิน">
      <div className="flex justify-between px-4 gap-2">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
        <div>
          <div className="flex gap-1">
            <Switch
              size="sm"
              checked={enableFetching}
              onChange={(e) => setEnableFetching(e.target.checked)}
            >
              เปิดใช้งานการดึงจากบัตรประชาชน
            </Switch>
          </div>
        </div>
      </div>
      <DialogLoading isLoading={isImageLoading} title="กำลังอัพโหลดรูปภาพ" />
      <div className="flex justify-between px-4 gap-2"></div>
      <div className="p-4 w-full">
        <div className="flex flex-wrap justify-between pb-8">
          <div className="w-full  ">
            <form onSubmit={handleSubmit(onEditCustomer)}>
              <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
                <CheckinForm
                  bookings={bookings}
                  idCardImage={idCardImage}
                  setIdCardImage={setIdCardImage}
                  register={register}
                  setValue={setValue}
                />
                <div className="relative w-full my-3">
                  <div className="relative w-full mb-3">
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      รายการห้องพักที่จอง
                    </label>
                    <div className="">
                      <div className="p-2">
                        <SelectedRoomList bookingList={bookings} />
                      </div>
                      <div className="px-3">
                        <div className="md:flex">
                          <div className="w-full block  text-gray-700 text-sm font-semibold mb-2">
                            จำนวนห้อง : {bookings?.list?.length}
                          </div>
                          {/* <div className="w-full block  text-gray-700 text-sm font-semibold mb-2">
                            เป็นเงิน : {bookings?.price - servicePrice} บาท
                          </div> */}
                        </div>
                        <div className="md:flex">
                          <div className="w-full  text-gray-700 text-sm font-semibold mb-2 flex">
                            <div>บริการเสริม : </div>{" "}
                            {_.map(bookings?.services, (service) => (
                              <div> {service?.service?.name || "-"}</div>
                            ))}
                          </div>
                          {/* <div className="w-full block  text-gray-700 text-sm font-semibold mb-2">
                            รวมเป็นเงิน : {bookings?.price} บาท
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full mb-3">
                    <div className="md:flex border-double border-4 px-3 ">
                      <div className="w-full block  text-gray-700 text-sm font-semibold mb-2 pt-2">
                        วันเข้า :{" "}
                        {DateTime.fromISO(bookings?.start)
                          .setLocale("th")
                          .toLocaleString({ day: "numeric", month: "short", year: "numeric" })}
                      </div>
                      <div className="w-full block  text-gray-700 text-sm font-semibold mb-2 md:pt-2">
                        วันออก :{" "}
                        {DateTime.fromISO(bookings?.end)
                          .setLocale("th")
                          .toLocaleString({ day: "numeric", month: "short", year: "numeric" })}
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="text-center  gap-0 py-3 ">
                    <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                      บันทึก
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
