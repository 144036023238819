/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, useToast } from "@chakra-ui/react"
import { Link, useParams, useHistory } from "react-router-dom"
import { Table } from "antd"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import _ from "lodash"

import Layout from "../../components/layout/GeneralLayout"
import WhitePanel from "../../components/common/Cards/WhitePanel"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Alert from "../../components/common/Alert/AlertDialog"
import * as actions from "../../redux/actions"

export default function ViewPurchaseOrder() {
  const purchaseOrder = useSelector((state) => state.purchaseOrder)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const params = useParams()
  const history = useHistory()
  const toast = useToast()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const textShoppingList = _.map(
    purchaseOrder?.materials,
    (each, index) =>
      `${index + 1} ${each?.material?.type_code || ""} - ${each?.material?.name} จำนวน ${
        each?.amount
      } \n`,
  )

  useEffect(() => {
    dispatch(actions.getOnePurchaseOrder(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })

    return () => {}
  }, [params])

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "รหัสวัตถุดิบ",
      dataIndex: "type_code",
      render: (text, record) => record?.material?.type_code,
    },
    {
      title: "ชื่อวัตถุดิบ",
      dataIndex: "name",
      render: (text, record) => record?.material?.name,
    },
    {
      title: "ปริมาณ",
      dataIndex: "amount",
      render: (text, record) => record?.amount,
    },
  ]

  return (
    <Layout categoryTitle={t("inventory.title")} title="รายการสั่งซื้อวัตถุุดิบ/สินค้า">
      <div className="flex justify-between px-4">
        <div className="flex gap-2">
          <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
            กลับ
          </Button>
          <Link to={`/inventory/purchase-order/edit/${params.id}`}>
            <Button colorScheme="yellow">แก้ไข</Button>
          </Link>
        </div>
        <div>
          <div className="flex gap-2">
            <Button
              colorScheme="blue"
              onClick={() => {
                navigator.share({ title: "รายการสั่งซื้", text: textShoppingList })
              }}
            >
              <i className="fas fa-share-alt mr-2"></i>
              แชร์
            </Button>
            <Button
              colorScheme="green"
              onClick={() => {
                navigator.clipboard.writeText(textShoppingList)
                toast({
                  status: "success",
                  title: "คัดลอกลงในคลิปบอร์ดสำเร็จ ",
                  description: "สามารถเปิดแอพพลิเคชั่นอื่นเพื่อวางข้อความ",
                })
              }}
            >
              <i className="fas fa-copy mr-2"></i>
              คัดลอก
            </Button>
          </div>
        </div>
      </div>
      <div className="p-4 w-full overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <div className="my-2">
          <WhitePanel>
            <div>
              <b>วันที่ซื้อวัตถุดิบ : </b> {dayjs(purchaseOrder?.date).format("D MMM YYYY")}
            </div>
            <div>
              <b>หมายเหตุ : </b> {purchaseOrder?.remark || "-"}
            </div>
          </WhitePanel>
        </div>
        {isLoading && (
          <Table
            columns={columns}
            dataSource={purchaseOrder?.materials}
            scroll={{
              x: "true",
            }}
          />
        )}
      </div>
    </Layout>
  )
}
