/* eslint-disable no-underscore-dangle */
import React, { useState } from "react"
import { Card, Button as AntdButton } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { Button, Spinner } from "@chakra-ui/react"

import * as actions from "../../redux/actions"
import Alert from "../common/Alert/AlertDialog"
import { currencyFormatter } from "../../util"
import _ from "lodash"

export default function BillingHoverPanel() {
  const dispatch = useDispatch()
  const bill = useSelector((state) => state.bill)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isExpandMode, setIsExpandMode] = useState(false)

  const handleRefresh = () => {
    setIsLoading(false)
    dispatch(actions.getCurrentBill())
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsLoading(true)
        setIsError(true)
        setErrorMessage(err.message)
      })
  }

  const handleCreateBill = () => {
    setIsLoading(false)
    dispatch(actions.createOneBill({ active: true }))
      .then(() => {
        dispatch(actions.getCurrentBill())
          .then(() => {
            setIsLoading(true)
          })
          .catch((err) => {
            setIsLoading(true)
            setIsError(true)
            setErrorMessage(err.message)
          })
      })
      .catch((err) => {
        setIsLoading(true)
        setIsError(true)
        setErrorMessage(err.message)
      })
  }

  const handleCloseBill = () => {
    setIsLoading(false)
    dispatch(actions.editOneBill(bill?._id, { active: false, closed_date: new Date() }))
      .then(() => {
        dispatch(actions.getCurrentBill())
          .then(() => {
            setIsLoading(true)
          })
          .catch((err) => {
            setIsLoading(true)
            setIsError(true)
            setErrorMessage(err.message)
          })
      })
      .catch((err) => {
        setIsLoading(true)
        setIsError(true)
        setErrorMessage(err.message)
      })
  }

  if (!isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    )
  }

  return (
    <div className="fixed bottom-2 right-2 z-20">
      <Alert
        isError={isError}
        errMessage={errorMessage}
        handleOnClose={() => {
          setIsError(false)
        }}
      />
      <Card
        title={`รอบบิลที่ ${_.padStart(bill?.bill_no, 4, "0") || "-"}`}
        size={isExpandMode ? "default" : "small"}
        extra={
          <div className="flex gap-2 ml-4">
            <AntdButton onClick={() => handleRefresh()}>
              <i className="fas fa-redo"></i>
            </AntdButton>
            <AntdButton onClick={() => setIsExpandMode(!isExpandMode)}>
              {isExpandMode ? (
                <i className="fas fa-chevron-down"></i>
              ) : (
                <i className="fas fa-chevron-up"></i>
              )}
            </AntdButton>
          </div>
        }
      >
        <div className="font-sans">
          {bill?._id ? (
            <div className="">
              {isExpandMode && <div>รับเข้า {currencyFormatter.format(bill?.income)} บาท</div>}
              {isExpandMode && <div>ออก {currencyFormatter.format(bill?.outcome)} บาท</div>}
              {isExpandMode && (
                <div>เงินในโต๊ะ {currencyFormatter.format(bill?.spare_money)} บาท</div>
              )}
              <div>คงเหลือ {currencyFormatter.format(bill?.total)} บาท</div>
              <Button size="sm" onClick={() => handleCloseBill()}>
                ปิดรอบ
              </Button>
            </div>
          ) : (
            <div>
              <div className="flex gap-2 items-center">
                <div className="">ไม่มีรอบบิล</div>
                <Button colorScheme="yellow" size="sm" onClick={() => handleCreateBill()}>
                  สร้างรอบบิล
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  )
}
