import React, { useState } from "react"
import QRCode from "react-qr-code"
import { useSelector } from "react-redux"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  FormControl,
  ModalCloseButton,
  ModalHeader,
  Button,
  Input,
  useToast,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import Confirm from "../../common/Alert/ConfirmDialog"
import { BookingStatus } from "../../../config/constant/BookingStatus"
import { TransactionType } from "../../../config/constant"

export default function ModalCheckIn({
  isOpen,
  handleOnClose,
  bookingInfo,
  handleEditBooking = () => {},
}) {
  const system = useSelector((state) => state.system)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const { t } = useTranslation()
  const toast = useToast()

  return (
    <div>
      <Confirm
        title={t("checkin.confirmCheckin")}
        body={t("checkin.changeToCheckedIn")}
        isOpen={isConfirmOpen}
        handleOnClose={() => setIsConfirmOpen(false)}
        handleSuccess={() => {
          handleEditBooking(bookingInfo?.id, {
            status: BookingStatus.checkedIn,
            info: "เช็คอินให้กับแขกที่เข้าพัก",
            transaction_type: TransactionType.GUEST_CHECKIN.status_code,
            transaction_group: TransactionType.GUEST_CHECKIN.group.status_code,
          })
        }}
      />

      <form>
        <Modal isOpen={isOpen} onClose={handleOnClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader className="font-sans" fontSize="lg">
              {t("reservation.checkin")}
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody className="font-sans mb-4 text-center">
              <h3 className=" font-bold">{t("checkin.title")}</h3>
              <p className="text-xs text-gray-500 px-4">{t("checkin.onlineCheckinInstruction")}</p>
              <div className="mt-2 md:flex">
                <div className="p-3">
                  <QRCode
                    className="w-full"
                    value={`${system?.systemURL}/checkIn/create/${bookingInfo.id}`}
                    size={150}
                  />
                </div>

                <div className="w-full pt-2">
                  <h6 className="font-semibold text-sm">{t("checkin.checkinLink")}</h6>
                  <FormControl className="flex gap-1">
                    <Input
                      size="sm"
                      readOnly
                      value={`${system?.systemURL}/checkIn/create/${bookingInfo.id}`}
                    />
                    <Button
                      size="sm"
                      onClick={async () => {
                        await navigator.clipboard.writeText(
                          `${system?.systemURL}/checkIn/create/${bookingInfo.id}`,
                        )
                        toast({
                          title: "คัดลอกสำเร็จแล้ว",
                          status: "success",
                          duration: 1000,
                        })
                      }}
                    >
                      {t("general.copy")}
                    </Button>
                  </FormControl>{" "}
                  <div className="my-4">
                    {" "}
                    <p className="text-xs text-gray-500 px-4">
                      {t("checkin.inputCheckinForCustomer")}
                    </p>
                    <Link to={`/reservation/checkin/${bookingInfo.id}`}>
                      <Button colorScheme="blue">
                        <i className="mr-2 fas fa-user" /> {t("reservation.checkin")}
                      </Button>
                    </Link>
                  </div>
                  <div className="my-4">
                    {" "}
                    <p className="text-xs text-gray-500 px-4">
                      {t("checkin.inputCheckinStatusChange")}
                    </p>
                    <Button colorScheme="gray" size="sm" onClick={() => setIsConfirmOpen(true)}>
                      <i className="mr-2 fas fa-user-check" /> {t("checkin.checkinStatusCheck")}
                    </Button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </form>
    </div>
  )
}

ModalCheckIn.propTypes = {
  isOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
  bookingInfo: PropTypes.object,
  handleEditBooking: PropTypes.func,
}
