/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { Input, Table } from "antd"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Alert from "../../components/common/Alert/AlertDialog"
import * as actions from "../../redux/actions"

export default function MaterialManagement() {
  const material = useSelector((state) => state.material)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [page, setPage] = useState(1)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [size, setSize] = useState(10)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  useEffect(() => {
    dispatch(
      actions.getAllMaterial({
        query: {
          name,
        },
        page,
        size,
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
    return () => {}
  }, [page, name])

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  const handleDeleteMaterial = (articleId) => {
    const confirm = window.confirm("ยืนยันการลบวัตถุดิบนี้")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOneMaterial(articleId))
        .then(() => {
          dispatch(actions.getAllMaterial({ name, page, size }))
            .then(() => {
              setIsLoading(true)
            })
            .catch((err) => {
              setErrMessage(err)
              setIsError(true)
            })
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize
    setSize(pageSize)
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "รหัส",
      dataIndex: "type_code",
      render: (text, record) => <div>{record?.type_code}</div>,
    },
    {
      title: "ชื่อ",
      dataIndex: "name",
      render: (text, record) => <div>{record?.name}</div>,
    },

    {
      title: "ดำเนินการ",
      dataIndex: "active",
      render: (text, record) => (
        <div className="flex gap-2">
          <Link to={`/inventory/material/edit/${record?.id}`}>
            <Button size="sm" colorScheme="yellow">
              แก้ไข
            </Button>
          </Link>
          <Button size="sm" colorScheme="red" onClick={() => handleDeleteMaterial(record?.id)}>
            ลบ
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Layout categoryTitle={t("inventory.title")} title="วัตถุดิบ / สินค้า">
      <div className="flex justify-between px-4">
        <div className="flex gap-2">
          <Link to="/inventory/material/create">
            <Button colorScheme="purple">เพิ่ม</Button>
          </Link>{" "}
          <Link to="/inventory/material/create-many">
            <Button colorScheme="teal">เพิ่มทีละหลายชนิด</Button>
          </Link>
        </div>
        <div className="flex gap-2">
          <Input
            placeholder="input search text"
            addonBefore={<i className="fas fa-search" />}
            allowClear
            onChange={(e) => setSearchWord(e.target.value)}
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
      <div className="p-4 w-full overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={material?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: material?.total,
            }}
            scroll={{
              x: "true",
            }}
          />
        )}
      </div>
    </Layout>
  )
}
