/* eslint-disable no-underscore-dangle */
import React, { useContext } from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Button, Text } from "@chakra-ui/react"
import dayjs from "dayjs"

import { WhitePanel } from "../../common/Cards"
import { RoomFacilityDisplayPanel } from "../../Panels"
import { currencyFormatter, calculatePriceOnSubtype } from "../../../util"
import { OnlineBookingContext } from "../../../contexts/OnlineBookingContext"

const UnSpecifyRoomReservation = ({ roomTypes, subtype, rerenderSetting = () => {} }) => {
  const { i18n, t } = useTranslation()
  const { color, unSpecifyBookingList, setUnSpecifyBookingList, checkinDate, checkoutDate } =
    useContext(OnlineBookingContext)

  console.log("unSpecifyBookingList in room-select", unSpecifyBookingList)

  const findingSubtypePrice = (eachSubtype) => {
    const priceOnSubtype = calculatePriceOnSubtype(
      eachSubtype,
      dayjs(checkinDate).toISOString(),
      dayjs(checkoutDate).toISOString(),
    )
    if (_.isArray(priceOnSubtype)) {
      if (_.min(priceOnSubtype) === _.max(priceOnSubtype)) {
        return currencyFormatter.format(_.min(priceOnSubtype))
      }
      return `${currencyFormatter.format(_.min(priceOnSubtype))} - ${currencyFormatter.format(
        _.max(priceOnSubtype),
      )}`
    }
    return currencyFormatter.format(priceOnSubtype)
  }

  const handleAddUnspecifyBooking = (selectedSubtype) => {
    if (!_.isArray(unSpecifyBookingList)) {
      setUnSpecifyBookingList([{ subtype: selectedSubtype, amount: 1 }])
    } else {
      const foundedSubtype = _.find(
        unSpecifyBookingList,
        (each) => each?.subtype?._id === selectedSubtype?._id,
      )

      if (foundedSubtype) {
        const foundedAmount = foundedSubtype?.amount
        if (foundedAmount + 1 <= selectedSubtype?.online_booking_remain) {
          const foundedSubtypeIndex = _.findIndex(
            unSpecifyBookingList,
            (each) => each?.subtype?._id === selectedSubtype?._id,
          )

          delete unSpecifyBookingList[foundedSubtypeIndex]
          unSpecifyBookingList.push({ subtype: selectedSubtype, amount: foundedAmount + 1 })
          setUnSpecifyBookingList(unSpecifyBookingList)
        } else {
          alert("ห้องพักไม่เพียงพอ / No Enought Room")
        }
      } else {
        unSpecifyBookingList.push({ subtype: selectedSubtype, amount: 1 })
        setUnSpecifyBookingList(unSpecifyBookingList)
      }
    }
    rerenderSetting()
  }
  const generateEachRoomTypeList = (eachRoomType, index) => {
    const filterSubtype = _.filter(subtype?.arr, (each) => each?.type?._id === eachRoomType?._id)

    return (
      <div key={index}>
        <WhitePanel>
          <div className="flex flex-wrap">
            <div className="lg:w-1/4 p-1 flex justify-center">
              <img src={eachRoomType?.images?.[0]?.image?.url} className="h-48 rounded-md " />
            </div>
            <div className="w-full lg:w-3/4 px-2">
              <h3 className="my-2 text-lg font-bold font-sans">
                {eachRoomType?.translation?.[i18n.language]?.name}
              </h3>
              <p>{eachRoomType?.translation?.[i18n.language]?.description}</p>
              <RoomFacilityDisplayPanel roomFacilities={eachRoomType?.facility} />
              <hr />
              <div className="my-2 ">
                {_.map(
                  _.filter(filterSubtype, (each) => each?.online_booking_remain > 0),
                  (eachSubtype, subtypeIndex) => (
                    <div key={`RST-${subtypeIndex}`}>
                      <div className="flex flex-wrap items-center w-full">
                        <div className="w-1/2 md:w-2/3">
                          <div className="font-semibold">{eachSubtype?.name}</div>
                          {t("reservation.vacant")} {eachSubtype?.online_booking_remain}{" "}
                          {t("dashboard.room")}
                        </div>
                        <div className="w-1/2 md:w-1/3 flex gap-2 items-center">
                          <div>
                            <div className="text-sm">{t("reservation.price.pricePerNight")}</div>
                            <div className="text-lg ">
                              <Text fontSize="lg" textColor={color} fontWeight="bold">
                                {findingSubtypePrice(eachSubtype)} THB
                              </Text>
                            </div>
                          </div>
                          <Button
                            colorScheme={color}
                            onClick={() => handleAddUnspecifyBooking(eachSubtype)}
                          >
                            {t("general.add")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ),
                )}
                {_.isEmpty(_.filter(filterSubtype, (each) => each?.online_booking_remain > 0)) && (
                  <div>
                    <p>{t("roomtype.emptyCategory")}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </WhitePanel>
      </div>
    )
  }
  return <div>{_.map(roomTypes?.arr, (each, index) => generateEachRoomTypeList(each, index))}</div>
}

export default UnSpecifyRoomReservation
