export const ComponentHeightHorizontal = {
  ONE_HALF: {
    status_code: "ONE_HALF",
    description: "1 ใน 2 ของหน้าจอ",
    descriptionEn: "1/2 of Screen",
    tailwind: "h-[32rem]",
  },
  ONE_THIRD: {
    status_code: "ONE_THIRD",
    description: "1 ใน 3 ของหน้าจอ",
    descriptionEn: "1/3 of Screen",
    tailwind: "h-[12rem]",
  },
  TWO_THIRD: {
    status_code: "TWO_THIRD",
    description: "2 ใน 3 ของหน้าจอ",
    descriptionEn: "2/3 of Screen",
    tailwind: "h-[38rem]",
  },
  FULL_SCREEN: {
    status_code: "FULL_SCREEN",
    description: "เต็มหน้าจอ",
    descriptionEn: "Full Screen",
    tailwind: "h-full",
  },
}

export default ComponentHeightHorizontal
