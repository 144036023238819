/* eslint-disable no-underscore-dangle */
import React from "react"
import { Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import dayjs from "dayjs"
import _ from "lodash"
import { useHistory } from "react-router-dom"

function MeetingRoomHourSelector({
  meetingRoom,
  dayArray,
  selectedMeetingRooms,
  selectedDay,
  selectedServiceOrder = null,
  setSelectedMROToDeleteId,
  setConfirmOpen,
  allowClick,
}) {
  const hourArray = new Array(16)
  const history = useHistory()

  const searchMeetingRoomEvent = (dayToSearch, hourIndex) => {
    const selectedHour = dayjs(dayToSearch).hour(7).minute(0).add(hourIndex, "hour")
    const foundedMeetingRoomOrder = _.filter(
      meetingRoom?.meeting_room_orders,
      (eachMeetingOrder) =>
        eachMeetingOrder?.meeting_room === meetingRoom?._id &&
        dayjs(eachMeetingOrder?.start).subtract(1, "minute").isBefore(selectedHour, "minute") &&
        dayjs(eachMeetingOrder?.end).isAfter(selectedHour, "minute"),
    )

    if (_.isEmpty(foundedMeetingRoomOrder)) {
      return <div className="w-28"></div>
    }

    return (
      <div className="w-28">
        {_.map(foundedMeetingRoomOrder, (each, index) =>
          each?.service_order?._id === selectedServiceOrder?._id ? (
            <div key={index} className="bg-pink-500 p-2 text-sm  text-white rounded-md">
              การจองปัจจุบัน
              <div className="text-xs my-1">
                {dayjs(each?.start).format("HH:mm")} - {dayjs(each?.end).format("HH:mm")}
              </div>
              <Button
                size="sm"
                textColor="black"
                type="button"
                onClick={() => {
                  setSelectedMROToDeleteId(each?._id)
                  setConfirmOpen(true)
                }}
              >
                ลบ
              </Button>
            </div>
          ) : (
            <div
              key={index}
              className={`bg-purple-500 p-2 text-sm text-white rounded-md ${
                allowClick ? "cursor-pointer" : ""
              } `}
              onClick={() => {
                if (allowClick) {
                  history.push(`/order/service-orders/view/${each?.service_order?._id}`)
                }
              }}
            >
              {each?.service_order?.name || "-"}
              <div className="flex">
                {" "}
                <i className="fas fa-user mr-2"></i>
                {each?.service_order?.customer?.firstname}
              </div>
              <div className="text-xs my-1">
                {dayjs(each?.start).format("HH:mm")} - {dayjs(each?.end).format("HH:mm")}
              </div>
            </div>
          ),
        )}
      </div>
    )
  }

  const searchInputMeetingRoomEvent = (dayToSearch, hourIndex) => {
    const selectedHour = dayjs(dayToSearch).hour(7).minute(0).add(hourIndex, "hour")
    //  console.log("Selected Meeting rooms", selectedMeetingRooms)
    const foundedMeetingRoomOrder = _.filter(
      selectedMeetingRooms,
      (eachMeetingOrder) =>
        eachMeetingOrder?.meetingRoom === meetingRoom?._id &&
        dayjs(eachMeetingOrder?.start).subtract(1, "minute").isBefore(selectedHour, "minute") &&
        dayjs(eachMeetingOrder?.end).isAfter(selectedHour, "minute"),
    )

    if (_.isEmpty(foundedMeetingRoomOrder)) {
      return <div></div>
    }

    return (
      <div>
        {_.map(foundedMeetingRoomOrder, (each, index) => (
          <div key={index} className="bg-orange-500 p-2 text-sm text-center text-white rounded-md">
            การจองของคุณ
            <div className="text-xs my-1">
              {dayjs(each?.start).format("HH:mm")} - {dayjs(each?.end).format("HH:mm")}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="overflow-x-auto">
      <Table>
        <Thead>
          <Tr>
            <Th>กิจกรรม/เวลา</Th>
            {_.map(hourArray, (element, hourIndex) => (
              <Th key={hourIndex}>
                {dayjs().hour(7).minute(0).add(hourIndex, "hour").format("HH:mm")}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {_.map(dayArray, (eachDay, index) => (
            <Tr key={index}>
              <Td>{dayjs(selectedDay).add(index, "day").format("D MMM")}</Td>
              {_.map(hourArray, (element, hourIndex) => (
                <Td key={hourIndex}>
                  {searchMeetingRoomEvent(dayjs(selectedDay).add(index).toDate(), hourIndex)}
                  {searchInputMeetingRoomEvent(dayjs(selectedDay).add(index).toDate(), hourIndex)}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default MeetingRoomHourSelector
