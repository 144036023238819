export const PicturePosition = {
  JUSTIFY_LEFT: {
    status_code: "JUSTIFY_LEFT",
    description: "ชิดขอบซ้าย",
    descriptionEn: "justify left",
    tailwind: "justify-start top-0 right-0",
  },
  JUSTIFY_RIGHT: {
    status_code: "JUSTIFY_RIGHT",
    description: "ชิดขอบขวา",
    descriptionEn: "justify right",
    tailwind: "justify-end",
  },
  JUSTIFY_CENTER: {
    status_code: "JUSTIFY_CENTER",
    description: "ตรงกลางจอ",
    descriptionEn: "justify center",
    tailwind: "justify-center ",
  },
}

export default PicturePosition
