/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."

dayjs.extend(buddhistEra)

export default function MonthyForecastReport(
  bookingEvents,
  roomtypes,
  startDate,
  information,
  systemInfo,
) {
  const endOfMonthDay = dayjs(startDate).endOf("month").daysInMonth()
  const dateArray = new Array(endOfMonthDay)

  const findConditionalBooking = (dayIndex, selectedRoomType) => {
    const selectedDay = dayjs(startDate).add(dayIndex, "day").toDate()
    const selectedBooking = _.filter(
      bookingEvents,
      (each) =>
        dayjs(each?.start).isBefore(dayjs(selectedDay).endOf("day")) &&
        dayjs(each.end).isAfter(dayjs(selectedDay).endOf("day")),
    )

    if (selectedRoomType === "ALL") {
      return _.size(selectedBooking)
    }

    const filterBookingOnType = _.filter(
      selectedBooking,
      // eslint-disable-next-line no-underscore-dangle
      (each) =>
        each?.list?.room?.type?._id === selectedRoomType ||
        each?.no_room_booking?.roomtype?._id === selectedRoomType,
    )
    return _.size(filterBookingOnType)
  }

  const allRoomAmount = _.sum(_.map(roomtypes, (each) => each?.number_of_room))

  const findVacantRoom = (dayIndex) => {
    const selectedDay = dayjs(startDate).add(dayIndex, "day").toDate()
    const selectedBooking = _.filter(
      bookingEvents,
      (each) =>
        dayjs(each?.start).isBefore(dayjs(selectedDay).endOf("day")) &&
        dayjs(each.end).isAfter(dayjs(selectedDay).endOf("day")),
    )

    const unfreeRoom = _.size(selectedBooking)

    return allRoomAmount - unfreeRoom
  }

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "พยากรณ์การจองรายเดือน",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "พยากรณ์การจองรายเดือน /Monthy Forecast Report ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        text: [
          { text: "เดือน", bold: true },
          {
            text: `  ${dayjs(startDate).format("MMMM BBBB")}  `,
            margin: [5, 0],
          },
        ],
        margin: [0, 2],
      },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", ..._.map(roomtypes, (each) => 50), 50, 50, 50],
          body: [
            [
              { text: "วันที่/ห้องพัก", bold: true },
              ..._.map(roomtypes, (each) => ({
                text: each?.translation?.th?.name || each?.name || "",
                bold: true,
              })),
              { text: "รวมจอง", bold: true },
              { text: "ว่าง", bold: true },
              { text: "รวมทั้งหมด", bold: true },
            ],
            ..._.map(dateArray, (eachDay, index) => [
              { text: dayjs(startDate).add(index, "day").format("D MMM"), margin: [0, 2] },
              ..._.map(roomtypes, (each) => ({
                text: findConditionalBooking(index, each?._id),
                bold: true,
              })),
              { text: findConditionalBooking(index, "ALL") },
              { text: findVacantRoom(index) },
              { text: allRoomAmount },
            ]),
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
