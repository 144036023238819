import {
  TRANSACTION_ALL,
  TRANSACTION_CREATE,
  TRANSACTION_DELETE,
  TRANSACTION_GET,
  TRANSACTION_UPDATE,
  TRANSACTION_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const transactionAction = new GeneralReduxActionClass({
  allConst: TRANSACTION_ALL,
  createConst: TRANSACTION_CREATE,
  deleteConst: TRANSACTION_DELETE,
  errorConst: TRANSACTION_ERROR,
  getConst: TRANSACTION_GET,
  putConst: TRANSACTION_UPDATE,
  backendPrefix: "transaction",
  description: "Transaction",
})

export const getAllTransaction = (query) => transactionAction.getAll(query)
export const getOneTransaction = (id) => transactionAction.getOne(id)
export const createOneTransaction = (payload) => transactionAction.createOne(payload)
export const editOneTransaction = (id, payload) => transactionAction.editOne(id, payload)
export const deleteOneTransaction = (id) => transactionAction.deleteOne(id)
