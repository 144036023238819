import {
  SERVICE_ALL,
  SERVICE_CREATE,
  SERVICE_DELETE,
  SERVICE_GET,
  SERVICE_UPDATE,
} from "../../actions/types"

const initialState = {
  hotelService: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case SERVICE_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case SERVICE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case SERVICE_CREATE:
      return { isLoading: true }
    case SERVICE_UPDATE:
      return { isLoading: true }
    case SERVICE_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
