export const ComponentHeightSlideShowMobile = {
    ONE_HALF: {
      status_code: "ONE_HALF",
      description: "1 ใน 2 ของหน้าจอ",
      descriptionEn: "1/2 of Screen",
    },
    ONE_THIRD: {
      status_code: "ONE_THIRD",
      description: "1 ใน 3 ของหน้าจอ",
      descriptionEn: "1/3 of Screen",
    },
    TWO_THIRD: {
      status_code: "TWO_THIRD",
      description: "2 ใน 3 ของหน้าจอ",
      descriptionEn: "2/3 of Screen",
    },
    FULL_SCREEN: {
      status_code: "FULL_SCREEN",
      description: "เต็มหน้าจอ",
      descriptionEn: "Full Screen",
    },
  }
  
  export default ComponentHeightSlideShowMobile
  