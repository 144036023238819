import React, { useState, useEffect } from "react"
import { Button, Radio, RadioGroup } from "@chakra-ui/react"
import { useSelector, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { Link, useHistory, useParams } from "react-router-dom"
import ReactCountryFlag from "react-country-flag"
import moment from "moment"

import _ from "lodash"
import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import * as actions from "../../redux/actions"
import CRMList from "./Components/CRMList"
import { BookingListElement } from "../../components/List"
import { CRMType } from "../../config/constant"

export default function ShowCRM() {
  const history = useHistory()
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { handleSubmit, register, reset } = useForm()
  const customers = useSelector((state) => state.customers)
  const relationships = useSelector((state) => state.relationships)

  const mergedBooking = _.merge(customers?.bookings, customers?.logs)
  const handleCreateRelationship = (data) => {
    const customersId = params?.id
    console.log("cusID", customersId)
    const payload = { ...data, customers_id: customers.id }
    dispatch(actions.createOneRelationship(payload)).then(() => {
      dispatch(actions.getAllRelationships(customersId)).then(() => {
        setIsLoading(true)
        reset({})
      })
    })
  }

  useEffect(() => {
    const customersId = params?.id
    dispatch(actions.getAllRelationships(customersId)).then(() => {
      dispatch(actions.getOneCustomer(customersId)).then(() => {
        setIsLoading(true)
      })
    })

    return () => {}
  }, [params])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการลูกค้า" title="รายละเอียดลูกค้า">
      <div className="flex justify-between px-4 gap-2">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
        <Link to={`/crm/edit/${params.id}`}>
          <Button colorScheme="yellow">แก้ไข</Button>
        </Link>
      </div>
      <div className="p-4 w-full">
        <div className="bg-white p-2 rounded-lg shadow-lg divide-y-2">
          <div className="text-center">
            <h1 className="text-gray-900 my-3 font-bold uppercase font-sans text-2xl">
              ข้อมูลลูกค้า
            </h1>
          </div>
          <div className="mx-4">
            <h2 className="text-gray-900 my-3 font-bold  font-sans text-lg text-center">
              {customers?.firstname} {!customers?.is_legal_entity && customers?.lastname}
              <ReactCountryFlag
                countryCode={customers?.nationality}
                svg
                style={{
                  fontSize: "1.8em",
                  lineHeight: "1.5em",
                  marginLeft: "0.5em",
                }}
              />
            </h2>
            <div className="grid lg:grid-cols-3 mx-4  py-2 justify-between justify-items-center">
              <h2 className="text-gray-900 my-3 font-sans text-sm font-semibold">
                เบอร์โทรศัพท์
                <div className="text-base font-normal">{customers?.tel || "-"}</div>
              </h2>
              <h2 className="text-gray-900 my-3 font-sans text-sm">
                หมายเลขประจำตัวประชาชน/บัตรประจำตัวนิติบุคคล
                <div className="text-base font-normal"> {customers?.id_card || "-"}</div>
              </h2>
              <h2 className="text-gray-900 my-3 font-sans text-sm">
                เริ่มเป็นลูกค้าเมื่อ
                <div className="text-base font-normal">
                  {" "}
                  {moment(customers?.createdAt).format("D MMMM YYYY")}
                </div>
              </h2>
              <h2 className="text-gray-900 my-3 font-sans text-sm">
                อีเมล
                <div className="text-base font-normal">{customers?.email || "-"}</div>
              </h2>
              <h2 className="text-gray-900 my-3 font-sans text-sm">
                ที่อยู่
                <div className="text-base font-normal">{customers?.address || "-"}</div>
              </h2>
              <h2 className="text-gray-900 my-3 font-sans text-sm">
                เข้าพักกับเรามาแล้ว
                <div className="text-base font-normal">{_.size(mergedBooking)} ครั้ง</div>
              </h2>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(handleCreateRelationship)}>
          <div className="flex flex-wrap justify-between my-4">
            <div className="w-full lg:w-4/12 ">
              <div className="">
                <h6 className="text-gray-900 text-base my-3 font-bold uppercase font-sans text-center">
                  รายการบันทึก
                </h6>
                <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
                  <div className="w-full px-0 ">
                    <div className="relative w-full mb-3 ">
                      <label
                        className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                        htmlFor="grid-password"
                      >
                        เรื่อง
                      </label>
                      <RadioGroup>
                        <div className="grid grid-cols-2">
                          {_.map(CRMType, (eachCRMType, index) => (
                            <Radio
                              value={eachCRMType?.status_code}
                              name="title"
                              key={index}
                              size="sm"
                              ref={register}
                            >
                              {eachCRMType?.description}
                            </Radio>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="w-full px-0">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                        htmlFor="grid-password"
                      >
                        รายละเอียด
                      </label>
                      <textarea
                        name="message"
                        type="text"
                        ref={register}
                        className="px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="รายละเอียด"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  เพิ่ม
                </Button>
              </div>
            </div>
            <div className="w-full lg:w-8/12 lg:pl-6">
              <div className="">
                <h6 className="text-gray-900 text-base my-3 font-bold uppercase font-sans text-center">
                  ข้อมูลที่บันทึก
                </h6>
                {/* data */}
                {_.size(relationships?.arr) > 0 ? (
                  <CRMList
                    crmList={relationships?.arr}
                    customerId={customers?.id}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                ) : (
                  <div className=" grid place-content-center place-items-center w-full p-5  mb-10">
                    ยังไม่มีข้อมูลการบันทึก
                  </div>
                )}
              </div>
              <div className="my-4">
                <h6 className="text-gray-900 text-base my-3 font-bold uppercase font-sans text-center">
                  ประวัติการเข้าพัก
                </h6>
                <div className="grid lg:grid-cols-3">
                  {_.size(mergedBooking) > 0 &&
                    _.map(mergedBooking, (eachBooking, index) => (
                      <BookingListElement booking={eachBooking} key={index} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
