export const TextFrameType = {
  SQUARE: {
    status_code: "SQUARE",
    description: "ขอบสี่เหลี่ยม",
  },
  ROUNDED: {
    status_code: "ROUNDED",
    description: "ขอบโค้งมน",
  },
  CIRCLE: {
    status_code: "CIRCLE",
    description: "ขอบโค้งกลม",
  },
}

export default TextFrameType
