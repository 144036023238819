/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import * as actions from "../../../redux/actions"
import Layout from "../../../components/layout/GeneralLayout"
import { facilityJSON } from "../../../config/constant"
// import ImageUpload from "../../../components/ImageUpload/ImageUpload"
import { ModalAddPhotoToRoomType, SpinnerLoading, RoomTypeForm } from "../../../components"

import Alert from "../../../components/common/Alert/AlertDialog"
import ConfirmDialog from "../../../components/common/Alert/ConfirmDialog"

export default function EditRoomType() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const roomType = useSelector((state) => state.roomTypes)
  const { handleSubmit, register, control, setValue, watch } = useForm({
    defaultValues: {
      ...roomType,
      room_sub_types: _.map(roomType?.room_sub_types, (each) => ({
        ...each,
        use_price_list: each?.use_price_list,
        original: true,
        deleted: false,
        id: each?._id,
      })),
      // combined_type: roomType?.combined_type,
    },
  })
  const initialConfirmData = { title: "", body: "" }
  const [isAddRoomModalOpen, setIsAddRoomModalOpen] = useState(false)
  const [rerender, setRerender] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [confimData, setConfirmData] = useState(initialConfirmData)
  const [errData, setErrData] = useState("")
  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    dispatch(actions.getRoomType(params.id))
    return () => {}
  }, [params])

  useEffect(() => {
    let timeout

    if (roomType && roomType?._id) {
      setIsLoading(true)
      setTimeout(() => {
        setValue(
          "room_sub_types",
          _.map(roomType?.room_sub_types, (each) => ({
            ...each,
            original: true,
            deleted: false,
            id: each?._id,
            is_special_type: roomType?.is_special_type,
            use_price_list: each?.use_price_list,
          })),
        )

        setValue("is_special_type", roomType?.is_special_type)
        setValue("combined_type", roomType?.combined_type)
        setValue("enable", roomType?.enable)
      }, 600)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [roomType])

  const onSubmit = (data) => {
    const payload = {
      ...data,
    }

    console.log("Payload", payload)
    dispatch(actions.editRoomType(roomType?.id, payload))
      .then(() => {
        dispatch(actions.getAllRoomTypes()).then(() => {
          history.push("/roomtypes")
        })
      })
      .catch((err) => {
        setIsError(true)
        setErrData(err)
      })
  }

  const handleDeletePhoto = (photoIndex) => {
    const confirm = window.confirm(t("roomtype.confirmDeleteImage"))
    if (confirm) {
      const tempGalleryImage = roomType?.images
      setIsLoading(false)
      console.log(photoIndex)
      tempGalleryImage.splice(photoIndex, 1)
      try {
        dispatch(actions.editRoomType(roomType?.id, { images: tempGalleryImage })).then(() => {
          dispatch(actions.getRoomType(roomType?.id)).then(() => {
            setIsLoading(true)
          })
        })
      } catch (error) {
        setIsError(true)
        setErrData(error)
        setIsLoading(true)
      }
    }
  }

  const handleAddToHilight = (photoIndex) => {
    const tempGalleryImage = roomType?.images
    tempGalleryImage[photoIndex].hilight = !tempGalleryImage[photoIndex]?.hilight

    setIsLoading(false)
    try {
      dispatch(actions.editRoomType(roomType?.id, { images: tempGalleryImage })).then(() => {
        dispatch(actions.getRoomType(roomType?.id)).then(() => {
          setIsLoading(true)
        })
      })
    } catch (error) {
      setIsError(true)
      setErrData(error)
      setIsLoading(true)
    }
  }

  const handleDeleteRoomType = () => {
    dispatch(actions.deleteRoomType(params?.id))
      .then(() => {
        dispatch(actions.getAllRoomTypes()).then(() => {
          history.push("/roomtypes")
        })
      })
      .catch((err) => {
        setIsError(true)
        setErrData(err)
      })
  }

  if (!isLoading) {
    return (
      <div>
        <SpinnerLoading />{" "}
        <Alert
          errMessage={errData}
          handleOnClose={() => {
            setIsError(false)
            setErrData("")
          }}
          isError={isError}
        />
      </div>
    )
  }

  return (
    <Layout categoryTitle={t("roomtype.title")} title={t("roomtype.roomTypeEdit")}>
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <ModalAddPhotoToRoomType
          handleOnClose={() => {
            setIsAddRoomModalOpen(false)
          }}
          isOpen={isAddRoomModalOpen}
          roomTypeInfo={roomType}
        />
        <ConfirmDialog
          isOpen={isConfirmDialogOpen}
          body={confimData?.body}
          title={confimData?.title}
          handleOnClose={() => {
            setIsConfirmDialogOpen(false)
            setConfirmData(initialConfirmData)
          }}
          handleSuccess={() => {
            setIsConfirmDialogOpen(false)
            setConfirmData(initialConfirmData)
            handleDeleteRoomType()
          }}
        />
        <Alert
          errMessage={errData}
          handleOnClose={() => {
            setIsError(false)
            setErrData("")
          }}
          isError={isError}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <RoomTypeForm
            handleDeletePhoto={handleDeletePhoto}
            register={register}
            rerender={rerender}
            roomType={roomType}
            setIsAddRoomModalOpen={setIsAddRoomModalOpen}
            setRerender={setRerender}
            mainPageMode="edit"
            control={control}
            watch={watch}
            setValue={setValue}
            handleAddToHilight={handleAddToHilight}
          />
          <div className="text-center flex flex-col  gap-2 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              {t("general.save")}
            </Button>
            <Button
              isFullWidth
              colorScheme="red"
              variant="solid"
              type="button"
              onClick={() => {
                setConfirmData({
                  title: t("roomtype.confirmRemoveRoomType"),
                  body: t("roomtype.instructionRemoveRoomType"),
                })
                setIsConfirmDialogOpen(true)
              }}
            >
              {t("general.delete")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
