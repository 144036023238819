import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button, Input } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import moment from "moment"
import DayPickerInput from "react-day-picker/DayPickerInput"

import Layout from "../../../components/layout/GeneralLayout"
import * as actions from "../../../redux/actions"
import { SpinnerLoading } from "../../../components/Loading"
import { MeetingRoomStatusList } from "../../../components/Panels"
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../../util"
import { Alert } from "../../../components/common/Alert"

export default function MeetingRoomListBookingStatus() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [startDate, setStartDate] = useState(moment().toDate())
  const meetingRoom = useSelector((state) => state.meetingRoom)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getAllMeetingRoom({
        page: 1,
        size: 1000,
        query: {
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(startDate).add(1, "day").format("YYYY-MM-DD"),
          fetchOrder: true,
          name,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
    return () => {}
  }, [startDate, name])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const handlePreviousDay = () => {
    const newDate = moment(startDate).subtract(1, "day").toDate()
    setStartDate(newDate)
  }

  const handleNextDay = () => {
    const newDate = moment(startDate).add(1, "day").toDate()
    setStartDate(newDate)
  }

  const renderSeachBox = () => (
    <div className="flex justify-between flex-wrap px-4 ">
      <div className="lg:w-1/4">
        <Link to="/reservation/meeting-room-orders/create">
          <Button colorScheme="purple">{t("general.add")}</Button>
        </Link>
      </div>

      <div className="w-full lg:w-2/3 gap-2 px-2 flex flex-wrap lg:flex-no-wrap">
        <div className="lg:w-1/3">
          <Input
            type="text"
            placeholder={t("general.search")}
            size="sm"
            onChange={(event) => setSearchWord(event.target.value)}
          />
        </div>
        <div className="border">
          <DayPickerInput
            onDayChange={setStartDate}
            value={startDate}
            placeholder="วันที่ต้องการค้นหา"
            formatDate={(date) => moment(date)?.format("D MMM YYYY")}
            dayPickerProps={{
              locale: "th",
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
            }}
          />
        </div>
        <Button
          leftIcon={<i className="fas fa-chevron-left"></i>}
          size="sm"
          onClick={handlePreviousDay}
        >
          {t("reservation.oneDayBack")}
        </Button>
        <Button
          leftIcon={<i className="fas fa-chevron-right"></i>}
          size="sm"
          onClick={handleNextDay}
        >
          {t("reservation.oneDayForward")}
        </Button>
      </div>
    </div>
  )

  return (
    <Layout
      categoryTitle={t("reservation.title")}
      preview
      title={t("reservation.roomViewMeetingOrder")}
    >
      <Alert isError={isError} errMessage={errorMessage} handleOnClose={() => setIsError(false)} />
      {renderSeachBox()}
      <div className="p-4 w-full min-h-screen">
        {isLoading ? (
          <div>
            <MeetingRoomStatusList
              isOneDay
              startDate={startDate}
              endDate={startDate}
              meetingRoom={meetingRoom}
            />
          </div>
        ) : (
          <SpinnerLoading />
        )}
      </div>
    </Layout>
  )
}
