import React from "react"

const SliderButtonComponentMobile = ({ watch }) => (
  <div
    className="lg:ml-40 mb-2 rounded-md  flex justify-center "
    style={{
      width: `${watch("mobile.button_component.button_component_width")}px`,
      height: `${watch("mobile.button_component.button_component_length")}px`,
      borderRadius: `${watch("mobile.button_component.button_curve")}%`,
      backgroundColor: `${watch("desktop.button_component.button_color")}`,
    }}
  >
    <p
      className="flex  items-center "
      style={{
        color: `${watch("desktop.button_component.button_text_color")}`,
      }}
    >
      {watch("translation.th.button_component_name")}
    </p>
  </div>
)

export default SliderButtonComponentMobile
