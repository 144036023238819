/* eslint-disable no-underscore-dangle */
import React from "react"
import { Checkbox, RadioGroup, Radio } from "@chakra-ui/react"
import { CompactPicker } from "react-color"
import { Controller } from "react-hook-form"
import { Select as Antselect, Space, Form, Input } from "antd"
import _ from "lodash"

import { WhitePanel } from "../../common/Cards"
import { FooterType } from "../../../config/constant/FooterTypeConstant"

function FooterForm({ register, defaultValue, watch, backgroundgallery, setValue, control }) {
  const { TextArea } = Input
  return (
    <div>
      <div>
        <WhitePanel>
          <Form layout="vertical">
            <Form.Item label="ขนาดตัวอักษร">
              <Controller
                name="footer.fontSize"
                control={control}
                defaultValue={defaultValue?.footer?.fontSize}
                render={(field) => (
                  <Antselect {...field} style={{ width: "50%" }}>
                    <Antselect.Option value="normal">normal</Antselect.Option>
                    <Antselect.Option value="sm">sm</Antselect.Option>
                    <Antselect.Option value="lg">lg</Antselect.Option>
                    <Antselect.Option value="xl">xl</Antselect.Option>
                    <Antselect.Option value="2xl">2xl</Antselect.Option>
                    <Antselect.Option value="3xl">3xl</Antselect.Option>
                    <Antselect.Option value="4xl">4xl</Antselect.Option>
                  </Antselect>
                )}
              />
            </Form.Item>
            {/* <div className="font-semibold font-sans text-sm my-1">คำอธิบายที่จะอยู่บน Footer</div>
            <Form.Item label="คำอธิบายภาษาไทย" tooltip="จะขึ้นใน Footer ของเว็บไซต์">
              <Controller
                name="translation.th.description"
                control={control}
                defaultValue={defaultValue?.translation?.th?.description || ""}
                render={(field) => (
                  <TextArea
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="รายละเอียด"
                    rows={4}
                  />
                )}
              />
            </Form.Item>
            <Form.Item label="คำอธิบายโดยละเอียด ภาษาอังกฤษ" tooltip="จะขึ้นใน Footer ของเว็บไซต์">
              <Controller
                name="translation.en.description"
                control={control}
                defaultValue={defaultValue?.translation?.en?.description || ""}
                render={(field) => (
                  <TextArea
                    {...field}
                    style={{ width: "100%" }}
                    placeholder="รายละเอียด"
                    rows={4}
                  />
                )}
              />
            </Form.Item> */}
          </Form>
        </WhitePanel>
        <WhitePanel>
          <div className="font-semibold w-full my-1">สี</div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 px-1">
              <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                สีตัวอักษร{" "}
              </h6>
              <div className="w-full flex justify-center">
                <Controller
                  control={control}
                  name="footer.color"
                  defaultValue={defaultValue?.footer?.color}
                  render={(field) => (
                    <CompactPicker
                      color={field.value}
                      onChangeComplete={(selectedColor) => {
                        field.onChange(selectedColor.hex)
                      }}
                      width={256}
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 px-1">
              {" "}
              <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                สีพื้นหลังส่วน Footer
              </h6>
              <div className="w-full flex justify-center">
                <Controller
                  control={control}
                  name="footer.backgroud"
                  defaultValue={defaultValue?.footer?.backgroud}
                  render={(field) => (
                    <CompactPicker
                      color={field.value}
                      onChangeComplete={(selectedColor) => {
                        field.onChange(selectedColor.hex)
                      }}
                      width={256}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </WhitePanel>
        <WhitePanel>
          <div className="font-semibold w-full my-1">ภาพพื้นหลัง Footer</div>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 px-2 pt-4">
              <Checkbox
                ref={register}
                size="sm"
                defaultChecked={defaultValue?.footer?.use_background || false}
                name={`footer.use_background`}
              >
                <p className="text-sm">เปิดใช้งานภาพพื้นหลังส่วนประกอบ</p>
              </Checkbox>
              {watch(`footer.use_background`) === true && (
                <Controller
                  control={control}
                  name="footer.footer_background._id"
                  defaultValue={defaultValue?.footer?.footer_background?._id || ""}
                  render={(field) => (
                    <Antselect
                      {...field}
                      style={{
                        width: "65%",
                        height: "60%",
                      }}
                      placeholder="เลือกภาพพื้นหลังส่วนประกอบ"
                      onChange={field.onChange}
                      optionLabelProp="label"
                    >
                      {_.map(backgroundgallery?.arr, (each, index) => (
                        <Antselect.Option value={each?._id} key={index} label={each?._id}>
                          <Space>
                            <span role="img" aria-label="image">
                              <img src={each?.image?.url} />
                            </span>
                            {each?._id}{" "}
                          </Space>
                        </Antselect.Option>
                      ))}
                    </Antselect>
                  )}
                />
              )}
            </div>
          </div>
        </WhitePanel>
        <WhitePanel>
          <div className="w-full  px-2 py-5 text-l font-semibold">รูปแบบของ Footer</div>
          <RadioGroup
            className="flex gap-2 flex-wrap my-2 pb-10"
            name="footer.footer_type"
            defaultValue={defaultValue?.footer?.footer_type}
            ref={register}
          >
            {_.map(FooterType, (eachType, index) => (
              <Radio value={eachType.status_code} key={index} ref={register}>
                <div className="border p-2 flex-wrap ">
                  <div className="text-base pt-2 pb-3 font-semibold flex justify-center">
                    {eachType?.description}
                  </div>
                  <div className="flex justify-center w-full h-full">
                    <img src={eachType?.image} className="w-full h-full " />
                  </div>
                </div>
              </Radio>
            ))}
          </RadioGroup>
        </WhitePanel>
      </div>
    </div>
  )
}

export default FooterForm
