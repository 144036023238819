import React from "react"
import MenuPanel from "../../Panels/MenuPanel"
import DipromLogo from "../../../assets/img/dipromlogo.png"

function Diprom2Homepage({ me, system }) {
  return (
    <div>
      <section className="container mx-auto items-center  mt-20  min-h-screen font-sans">
        <div>
          <div className="flex flex-wrap mx-auto justify-center ">
            <div className="w-full md:w-1/6 flex justify-center items-center">
              <img src={system?.logo?.url} alt="logo" className="h-24" />
            </div>
            <div className="w-full md:w-4/12 flex flex-wrap justify-center">
              <div className="mt-4 w-full text-xl font-semibold">{system?.systemOwner}</div>
              <div className="w-full my-1">
                ระบบนี้ได้รับการสนุบสนุนโดย ศูนย์ส่งเสริมอุตสาหกรรมภาคที่ 11 กรมส่งเสริมอุตสาหกรรม
              </div>
              <div className="w-full my-1">
                <div>
                  ดีพร้อมโต โตได้ โตไว โตไกล โตให้ยั่งยืน{"  "}
                  <a href="https://www.diprom.net/" target="_blank" rel="noreferrer">
                    Diprom.go.th
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/12 flex justify-center">
              <img src={DipromLogo} alt="Diprom logo" className="h-32" />
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/4 px-4 mr-auto ml-auto">
          <MenuPanel me={me} system={system} cols={3} />
        </div>
      </section>
    </div>
  )
}

export default Diprom2Homepage
