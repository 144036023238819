import React from "react"
import { Table, Thead, Tr, Th, Td, Tbody, Button } from "@chakra-ui/react"
import _ from "lodash"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import UserRoles from "../../config/UserRoles"

export default function UserLists({ user, handleDelelte }) {
  const { t } = useTranslation()
  const genUserLists = () =>
    _.map(user, (_user, index) => (
      <Tr key={index}>
        <Td>{index + 1}</Td>
        <Td>{_user.username}</Td>
        <Td></Td>

        <Td>{_.upperFirst(_user.role)}</Td>

        <Td>
          <div className="flex gap-2">
            <Link to={`/system/user/edit/${_user.id}`}>
              <Button colorScheme="yellow" size="sm">
                {t("general.edit")}
              </Button>
            </Link>{" "}
            <Link to={`/system/user/password-edit/${_user.id}`}>
              <Button colorScheme="blue" size="sm">
                {t("system.changePassword")}
              </Button>
            </Link>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => {
                if (_user.role === UserRoles.SUPER_USER) {
                  alert(t("system.cannotRemoveSuperuser"))
                } else {
                  const confirm = window.confirm(t("system.confirmRemoveUser"))
                  if (confirm) handleDelelte(_user.id)
                }
              }}
            >
              ลบ
            </Button>
          </div>
        </Td>
      </Tr>
    ))

  return (
    <div>
      <Table colorScheme="facebook" className="whitespace-no-wrap">
        <Thead backgroundColor="white">
          <Tr>
            <Th>#</Th>
            <Th>{t("system.userName")}</Th>
            <Th></Th>
            <Th> {t("system.role")}</Th>
            <Th> {t("general.action")}</Th>
          </Tr>
        </Thead>
        <Tbody>{genUserLists()}</Tbody>
      </Table>
    </div>
  )
}
