/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useParams, useHistory } from "react-router-dom"
import _ from "lodash"
import moment from "moment"

// import { Link } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import { WhitePanel } from "../../components/common/Cards"
import * as actions from "../../redux/actions"

export default function CheckinSuccess() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const bookings = useSelector((state) => state.bookings)
  const information = useSelector((state) => state.information)
  const servicePriceArray = bookings?.services?.map((eachList) => eachList?.price)

  console.log(servicePriceArray)
  console.log(bookings?.customer?.id)

  useEffect(() => {
    const bookingId = params?.id
    dispatch(actions.getOneBooking(bookingId)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการการเช็คอิน" title="เช็คอินสำเร็จ">
      <div className="flex justify-between px-4 gap-2"></div>
      <div className="m-4">
        <WhitePanel>
          <div className=" ">
            <div className="text-center my-4">
              <i className="  text-green-600 fas fa-check-circle" style={{ fontSize: 140 }}></i>
            </div>{" "}
            <h2 className="text-2xl text-center my-2 font-bold">เช็คอินสำเร็จแล้ว</h2>
            <div className="text-center">
              <div className=" text-lg">
                {bookings?.checkin?.firstname} {bookings?.checkin?.lastname}{" "}
              </div>
              Booking No. {bookings?.booking_no}
              <div className="mt-3 font-semibold">รายการห้องพัก</div>
              <div className="flex justify-center">
                <div className="flex gap-1 flex-wrap">
                  {_.map(bookings?.list, (eachList) => (
                    <div>{eachList?.room?.name} </div>
                  ))}
                </div>
              </div>
              <div className="my-2">
                เข้าพักวันที่ <br /> {moment(bookings?.start).format("D MMM")} -{" "}
                {moment(bookings?.end).format("D MMM")}
              </div>
              <div className="my-2">
                เช็คอินเมื่อ <br />{" "}
                {moment(bookings?.checkin?.timestamp).format("D MMM YYYY HH:mm")}
              </div>
              <div className="my-4 text-sm">
                กรุณาบันทึกหน้าจอนี้ไว้แล้วแจ้งกับพนักงานตอนรับเมื่อท่านมาถึงโรงแรม
              </div>
            </div>
          </div>
        </WhitePanel>
      </div>
    </Layout>
  )
}
