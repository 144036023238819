import {
  ROOMTYPE_ALL,
  ROOMTYPE_CREATE,
  ROOMTYPE_GET,
  ROOMTYPE_PUT,
  ROOMTYPE_DELETE,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case ROOMTYPE_ALL:
      return {
        arr: action.payload,
        isLoading: true,
      }
    case ROOMTYPE_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case ROOMTYPE_CREATE:
      return { isLoading: true }
    case ROOMTYPE_PUT:
      return { isLoading: true }
    case ROOMTYPE_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
