/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import _ from "lodash"

import { GeneralMainLayout as Layout } from "../../components/layout"
import {
  Alert,
  SpinnerLoading,
  BasicBookingInfoPanel,
  WhitePanel,
  CustomReciptCustomerForm,
  CustomReciptBookingSelect,
  PrintingPanel,
} from "../../components"

import * as actions from "../../redux/actions"
import { appConfig } from "../../config"

function CustomReciptPrint() {
  const { t } = useTranslation()
  const log = useSelector((state) => state.logs)
  const customers = useSelector((state) => state.customers)
  const system = useSelector((state) => state.system)
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { control, watch } = useForm({})
  const systemEnableForm = system?.setting?.form || {}
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [roomBookingList, setRoomBookingList] = useState([])
  const [noRoomBookingList, setNoRoomBookingList] = useState([])
  const [serviceList, setServiceList] = useState([])
  const [rerender, setRerender] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(log.customer)

  const getAllCustomer = () => {
    dispatch(actions.getAllCustomers(1, appConfig.maxFetchSize * appConfig.maxFetchSize))
  }

  useEffect(() => {
    dispatch(actions.getOnelog(params.id))
      .then(() => {
        getAllCustomer()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })

    return () => {}
  }, [params])

  useEffect(() => {
    if (log && !log?.arr) {
      const tempList = _.map(log?.list, (each) => ({ ...each, enable: true }))
      const tempNoRoomBooking = _.map(log?.no_room_booking, (each) => ({
        ...each,
        enable: true,
      }))
      const tempServices = _.map(log?.services, (each) => ({ ...each, enable: true }))

      setRoomBookingList(tempList)
      setNoRoomBookingList(tempNoRoomBooking)
      setServiceList(tempServices)
      setSelectedCustomer(log?.customer)
      setIsLoading(true)
    }

    return () => {}
  }, [log])

  const handleCreateCustomer = (data) => {
    dispatch(actions.createOneCustomer(data))
      .then(() => {
        dispatch(actions.getAllCustomers(1, 1000000, ""))
      })
      .catch((err) => {
        window.alert("Create Customer Error", err?.message)
      })
  }

  const handleEditCustomer = (id, data) => {
    dispatch(actions.editOneCustomer(id, data))
      .then(() => {
        dispatch(actions.getAllCustomers(1, 1000000, ""))
      })
      .catch((err) => {
        window.alert("Create Customer Error", err?.message)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title="ออกใบเสร็จแบบกำหนดเอง" preview>
      <Alert
        errMessage={errorMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrorMessage("")
        }}
        isError={isError}
      />

      <div className="flex px-4">
        <Button variant="outline" colorScheme="purple" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="p-4">
        <WhitePanel>
          <BasicBookingInfoPanel bookingInfo={log} showPrintingPanel={false} />
        </WhitePanel>
        <div className="my-2">
          <WhitePanel>
            <form>
              <CustomReciptCustomerForm
                bookingInfo={log}
                control={control}
                customers={customers}
                handleCreateCustomer={handleCreateCustomer}
                watch={watch}
                handleEditCustomer={handleEditCustomer}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
              />
              <CustomReciptBookingSelect
                bookingInfo={log}
                control={control}
                watch={watch}
                noRoomBookingList={noRoomBookingList}
                roomBookingList={roomBookingList}
                serviceList={serviceList}
                setNoRoomBookingList={setNoRoomBookingList}
                setRoomBookingList={setRoomBookingList}
                setServiceList={setServiceList}
                rerender={rerender}
                setRerender={setRerender}
              />
            </form>
            <hr className="my-2" />
            <div className="my-2">
              <PrintingPanel
                bookingInfo={{
                  ...log,
                  customer: selectedCustomer,
                  list: _.filter(roomBookingList, (each) => each?.enable === true),
                  no_room_booking: _.filter(noRoomBookingList, (each) => each?.enable === true),
                  services: _.filter(serviceList, (each) => each?.enable === true),
                  final_discount: parseFloat(watch("final_discount")),
                }}
                {...systemEnableForm}
                custom={false}
              />
            </div>
          </WhitePanel>
        </div>
      </div>
    </Layout>
  )
}

export default CustomReciptPrint
