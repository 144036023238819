import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/GeneralLayout"
import { SpinnerLoading, ServiceForm } from "../../../components"
import * as actions from "../../../redux/actions"

export default function EditService() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const hotelService = useSelector((state) => state.hotelService)
  const roomTypes = useSelector((state) => state.roomTypes)
  const [isLoading, setIsLoading] = useState(false)
  const { handleSubmit, control, setValue, watch } = useForm({})
  const { t } = useTranslation()

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOneService(params?.id)).then(() => {
        dispatch(actions.getAllRoomTypes({ name: "", page: 1, size: 1000 })).then(() => {
          setIsLoading(true)
        })
      })
    }
    return () => {}
  }, [params])

  useEffect(() => {
    if (hotelService) {
      setValue("online_available", hotelService?.online_available)
      setValue("limit_per_order_enable", hotelService?.limit_per_order_enable)
    }

    return () => {}
  }, [hotelService])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const handleEditService = (data) => {
    console.log("Data", data)
    dispatch(actions.editOneService(params?.id, data)).then(() => {
      dispatch(actions.getAllServices({})).then(() => {
        history.goBack()
      })
    })
  }

  return (
    <Layout
      categoryTitle={`${t("roomtype.title")} > ${t("roomtype.service")}`}
      title={t("roomtype.editService")}
    >
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(handleEditService)}>
          <ServiceForm
            control={control}
            hotelService={hotelService}
            roomtypes={roomTypes?.arr}
            setValue={setValue}
            watch={watch}
          />
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              {t("general.save")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
