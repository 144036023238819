/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
import { DateTime } from "luxon"
import _ from "lodash"

import { slipHeader, slipDefaultStyle } from ".."

const dateFormat = { day: "numeric", month: "long", year: "numeric" }

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 */

export default function ServiceCoupon(serviceOrder, information, systemInfo, cashierName) {
  // eslint-disable-next-line no-restricted-syntax

  return {
    // pageSize: "RA4",
    pageSize: {
      width: 200,
      height: 1000,
      // height: "auto",
    },
    pageOrientation: "portrait",
    pageMargins: [10, 10, 10, 10],
    info: {
      title: "Service Coupon",
    },
    defaultStyle: slipDefaultStyle,

    content: [
      ..._.map(serviceOrder, (eachList) => [
        ...slipHeader(information),
        {
          text: "Service Coupon ",
          fontSize: 11,
          bold: true,
          margin: [0, 2],
          alignment: "center",
        },
        {
          text: "--------------------------------------",
          alignment: "center",
        },
        {
          text: [
            { text: "Order No. ", bold: true },
            {
              text: `${eachList?.order_no}-${eachList?.number}`,
              margin: [5, 0],
            },
          ],
          alignment: "center",
          margin: [0, 3],
        },
        {
          text: [
            { text: "วันที่/Date", bold: true },
            {
              text: `  ${DateTime.fromISO(eachList?.date)
                .setLocale("en")
                .toLocaleString(dateFormat)}`,
              margin: [5, 0],
            },
          ],
          alignment: "center",
          margin: [0, 3],
        },
        {
          text: "ลูกค้า/Guest",
          bold: true,
          margin: [0, 5, 0, 0],
          fontSize: 10,
          alignment: "center",
        },
        {
          text: [
            {
              text: `  ${eachList?.serviceOrder?.customer?.firstname || ""}  ${
                eachList?.serviceOrder?.customer?.lastname || ""
              }`,
              margin: [5, 0],
            },
          ],
          margin: [0, 3],
          alignment: "center",
        },

        {
          text: `${eachList?.service?.translation?.th?.name || eachList?.service?.name || ""}`,
          alignment: "center",
          fontSize: 14,
          margin: [0, 10, 0, 5],
          bold: true,
          border: [false, false, false, false],
        },
        eachList?.qrcode !== ""
          ? {
              height: 100,
              image: eachList?.qrcode,
              margin: [25, 5, 10, 5],
            }
          : {},
        {
          text: "คูปองสามารถใช้ได้ภายในวันที่ระบุเท่านั้น \n Coupon valid on date shown above",
          alignment: "center",
          margin: [0, 10],
          fontSize: 10,
        },

        {
          text: "--------------------------------------",
          alignment: "center",
        },
      ]),
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
