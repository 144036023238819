/* eslint-disable no-undef */
// import api from "../../../config/api"
import { removeStorage } from "../../../util/localstorage"
import { ME_GET, ME_RESET } from "../types"
import api from "../../../config/api"

export const meGet = () => async (dispatch) => {
  const userInfo = JSON.parse(localStorage.getItem("user_info"))
  console.log("UserInfo in localstorage", userInfo)
  if (userInfo && userInfo?.id) {
    console.log("Login Pass")
    await api
      .get(`${process.env.REACT_APP_API_URL}/user/${userInfo?.id}`)
      .then(async (res) => {
        console.log("Request Server to Get Authorize")
        console.log(res.data)
        if (res.data) {
          dispatch({ type: ME_GET, payload: res.data?.data })
        } else {
          throw Error("Cannot Find User Infomation on your id")
        }
      })
      .catch(async (err) => {
        await removeStorage("token")
        await removeStorage("eaccom_remember")
        await removeStorage("user_info")
        if (process.env.NODE_ENV === "production") {
          throw Error(`Get User Credential error ${err.toString()}`)
        }
      })
  } else {
    console.log("Error on Find user in localstorage")
  }
}

export const loginForCustomerWithCaptcha =
  ({ token, booking }) =>
  async (dispatch) => {
    api
      .post(`${process.env.REACT_APP_API_URL}/user/login-captcha`, {
        token,
        booking,
      })
      .then(async (res) => {
        console.log("Res.data", res.data)

        const accessToken = res.data?.accessToken
        if (accessToken) {
          window.localStorage.setItem("token", accessToken)
          dispatch({ type: ME_GET, payload: { success: true, token: accessToken } })
        }
        dispatch({ type: ME_GET, payload: { success: false, error: true } })
      })
      .catch((error) => {
        console.log("Singin Error", error)
        throw Error(`Get User Credential error ${err.toString()}`)
      })
  }

export const meReset = () => async (dispatch) => {
  dispatch({ type: ME_RESET, payload: null })
}
