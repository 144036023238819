import React from "react"
import { useTranslation } from "react-i18next"
import ReactSelect from "react-select/creatable"
import { DatePicker, Select, InputNumber, Input } from "antd"
import { Controller } from "react-hook-form"
import { useSelector } from "react-redux"
import dayjs from "dayjs"
import _ from "lodash"
import { FormControl, Switch } from "@chakra-ui/react"

import { WhitePanel } from "../../common/Cards"
import { BookingSource } from "../../../config/constant"

function BookingInfoForm({
  customerOption,
  setSelectedCustomer,
  setIsModalCreateCustomerOpen,
  setTempCustomerName,
  selectedCustomer,
  checkedInDate,
  checkedOutDate,
  setCheckedInDate,
  setCheckedoutDate,
  control,
  isNotRoomSpecify,
  setIsNotRoomSpecify,
}) {
  const { t, i18n } = useTranslation()
  const { TextArea } = Input
  const system = useSelector((state) => state.system)

  return (
    <div>
      <WhitePanel>
        <div className="flex flex-wrap py-2">
          <div className="w-full">
            <div className="relative w-full mb-3 ">
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                {t("crm.customer")} *
              </label>
              <div className="flex ">
                <div className="w-3/4 lg:w-10/12">
                  <ReactSelect
                    placeholder={t("crm.addCustomerName")}
                    options={customerOption}
                    onChange={setSelectedCustomer}
                    onCreateOption={(label) => {
                      setIsModalCreateCustomerOpen(true)
                      setTempCustomerName(label)
                    }}
                    value={selectedCustomer}
                  />
                  <div className="my-2 text-gray-500 text-xs">
                    กรณีลูกค้าใหม่ สามารถพิมพ์เข้าจากส่วนนี้ได้เลย จากนั้นจึงกด Create
                    เพื่อสร้างลูกค้า
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="relative w-full lg:w-1/4 mb-3 px-1">
                <h5 className="font-semibold text-sm">{t("reservation.checkinDate")}</h5>
                <DatePicker
                  className="my-2"
                  onChange={(date) => setCheckedInDate(dayjs(date).toDate())}
                  value={dayjs(checkedInDate)}
                  format={"D/MM/YYYY"}
                  locale={i18n.language}
                  allowClear={false}
                  size="large"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="relative w-full lg:w-1/4 mb-3 px-1">
                <h5 className="font-semibold text-sm">{t("reservation.checkoutDate")}</h5>
                <DatePicker
                  onChange={(date) => setCheckedoutDate(dayjs(date).toDate())}
                  value={dayjs(checkedOutDate)}
                  className="my-2"
                  disabledDate={(current) =>
                    // Can not select days before today and today
                    current && current < dayjs(checkedInDate).endOf("day")
                  }
                  format={"D/MM/YYYY"}
                  locale={i18n.language}
                  allowClear={false}
                  size="large"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="relative w-full lg:w-1/4 mb-3 px-2">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  {t("reservation.bookingSource")}
                </label>

                <Controller
                  control={control}
                  name="booking_source"
                  defaultValue={BookingSource.COUNTER_BOOKING.status_code}
                  render={(field) => (
                    <Select
                      {...field}
                      placeholder={t("reservation.bookingSource")}
                      size="large"
                      style={{
                        width: 200,
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label ?? "").includes(input)}
                      showSearch
                      options={_.map(BookingSource, (eachBookingSource) => ({
                        value: eachBookingSource?.status_code,
                        label:
                          i18n?.language === "th"
                            ? eachBookingSource?.description
                            : eachBookingSource?.descriptionEN,
                      }))}
                    ></Select>
                  )}
                />
              </div>
              <div className="relative w-full lg:w-1/4 mb-3 px-2">
                <div className="flex gap-2">
                  <div>
                    {" "}
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      {t("general.amount")} {t("roomtype.adult")}
                    </label>
                    <Controller
                      control={control}
                      name="capacity.adult"
                      defaultValue={1}
                      render={(field) => (
                        <InputNumber {...field} placeholder={t("roomtype.adult")} size="large" />
                      )}
                    />
                  </div>{" "}
                  <div>
                    {" "}
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      {t("general.amount")} {t("roomtype.child")}
                    </label>
                    <Controller
                      control={control}
                      name="capacity.child"
                      render={(field) => (
                        <InputNumber {...field} placeholder={t("roomtype.child")} size="large" />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-full mb-3 ">
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                {t("reservation.remark")}
              </label>
              <Controller
                control={control}
                name="remark"
                placeholder={t("reservation.remark")}
                render={(field) => <TextArea {...field} rows={3} />}
              />
            </div>
            {system?.setting?.enablePreBooking && (
              <div className="my-2">
                <div className="font-semibold text-sm">จองแบบไม่ระบุห้อง</div>
                <FormControl flex display="flex" gridGap={2}>
                  <div className="self-center">จองแบบระบุห้อง</div>
                  <Switch
                    defaultChecked={isNotRoomSpecify}
                    checked={isNotRoomSpecify}
                    onChange={(event) => setIsNotRoomSpecify(event.target.checked)}
                  >
                    จองแบบไม่ระบุห้อง
                  </Switch>
                </FormControl>
              </div>
            )}{" "}
          </div>
        </div>{" "}
      </WhitePanel>
    </div>
  )
}

export default BookingInfoForm
