import { MENU_ALL, MENU_CREATE, MENU_DELETE, MENU_GET, MENU_UPDATE, MENU_ERROR } from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const MenuAction = new GeneralReduxActionClass({
  allConst: MENU_ALL,
  createConst: MENU_CREATE,
  deleteConst: MENU_DELETE,
  errorConst: MENU_ERROR,
  getConst: MENU_GET,
  putConst: MENU_UPDATE,
  backendPrefix: "menu",
  description: "เมนู",
})

export const getAllMenu = (query) => MenuAction.getAll(query)
export const getOneMenu = (id) => MenuAction.getOne(id)
export const createOneMenu = (payload) => MenuAction.createOne(payload)
export const editOneMenu = (id, payload) => MenuAction.editOne(id, payload)
export const deleteOneMenu = (id) => MenuAction.deleteOne(id)
