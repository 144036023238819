import React, { useState, useEffect } from "react"
import { Button, ButtonGroup, Switch } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { DatePicker } from "antd"

import WhitePanel from "../common/Cards/WhitePanel"

dayjs.extend(relativeTime)

function ReportDateSelector({
  pointDate,
  setPointDate = () => {},
  startDate,
  setStartDate = () => {},
  endDate,
  setEndDate = () => {},
  dateOnly,
  children,
  showTime = false,
  showTimeDescription = true,
}) {
  const { t } = useTranslation()
  const { RangePicker } = DatePicker
  const [usePointDate, setIsUsePointDate] = useState(true)
  const [pointDateType, setPointDateType] = useState("day")

  useEffect(() => {
    if (usePointDate) {
      setStartDate(null)
      setEndDate(null)
      setPointDate(new Date())
    } else {
      setPointDate(null)
      setEndDate(dayjs().toDate())
      setStartDate(dayjs().startOf("month").toDate())
    }

    return () => {}
  }, [usePointDate])

  const onMonthDayPickerChange = (date) => {
    console.log(dayjs(date).format("DD MMM YYYY"))
    const startOfMonth = dayjs(date).startOf("month").toDate()
    const endOfMonth = dayjs(date).endOf("month").toDate()
    setPointDate(null)
    setStartDate(startOfMonth)
    setEndDate(endOfMonth)
  }

  const onYearPickerChange = (date) => {
    console.log(dayjs(date).format("DD MMM YYYY"))
    const startOfYear = dayjs(date).startOf("year").toDate()
    const endOfYear = dayjs(date).endOf("year").toDate()
    setPointDate(null)
    setStartDate(startOfYear)
    setEndDate(endOfYear)
  }

  const renderPointDayPicker = () => (
    <div className="relative w-full my-3 ">
      <label
        className="block uppercase text-gray-700 text-sm font-semibold mb-2"
        htmlFor="grid-password"
      >
        {t("reservation.inputDayToSearch")}
      </label>
      <DatePicker
        picker="date"
        value={pointDate ? dayjs(pointDate) : null}
        format="D/MM/YYYY"
        size="large"
        onChange={(date) => {
          setPointDate(dayjs(date).toDate())
          setIsUsePointDate(true)
        }}
      />
    </div>
  )

  const renderMonthPicker = () => (
    <div className="relative w-full my-3 ">
      <label
        className="block uppercase text-gray-700 text-sm font-semibold mb-2"
        htmlFor="grid-password"
      >
        เลือกเดือนที่ต้องการหา
      </label>
      <DatePicker picker="month" size="large" onChange={onMonthDayPickerChange} />
    </div>
  )

  const renderYearPicker = () => (
    <div className="relative w-full my-3 ">
      <label
        className="block uppercase text-gray-700 text-sm font-semibold mb-2"
        htmlFor="grid-password"
      >
        เลือกปีที่ต้องการหา
      </label>
      <DatePicker picker="year" size="large" onChange={onYearPickerChange} />
    </div>
  )

  const renderRelativeDayPicker = () => (
    <div className="w-full flex flex-wrap">
      <div className="relative w-full  my-3 px-1">
        <label
          className="block uppercase text-gray-700 text-sm font-semibold mb-2"
          htmlFor="grid-password"
        >
          {t("reservation.startDate")} - {t("reservation.endDate")}
        </label>
        <RangePicker
          defaultValue={[startDate ? dayjs(startDate) : null, endDate ? dayjs(endDate) : null]}
          onChange={(val) => {
            setStartDate(val?.[0]?.toDate())
            setEndDate(val?.[1]?.toDate())
          }}
          size="large"
          format="D/MM/YYYY"
        />
      </div>{" "}
    </div>
  )

  return (
    <div>
      <WhitePanel>
        <div className="flex flex-wrap justify-between">
          {showTimeDescription ? (
            <div className="w-full lg:w-1/3 self-center">
              {usePointDate && pointDateType === "day" ? (
                <div>
                  {showTime ? (
                    <div>
                      <div className="text-lg font-semibold">
                        {dayjs(startDate).format("D MMMM YYYY HH:mm")} -{" "}
                        {dayjs(endDate).format("D MMMM YYYY HH:mm")}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="text-lg font-semibold">
                        {dayjs(pointDate).format("D MMMM YYYY")}
                      </div>
                      การเข้าพักตั้งแต่วันที่ {dayjs(pointDate).format("D MMM")} เวลา 12.01 น. ถึง
                      <br />
                      {dayjs(pointDate).add(1, "day").format("D MMM")} เวลา 11.59 น.{" "}
                    </div>
                  )}
                  <div className="flex gap-2 my-2">
                    <Button
                      size="sm"
                      leftIcon={<i className="fas fa-chevron-left" />}
                      onClick={() => {
                        setPointDate(dayjs(pointDate).subtract(1, "day").toDate())
                      }}
                    >
                      วันก่อนหน้า
                    </Button>
                    <Button
                      size="sm"
                      rightIcon={<i className="fas fa-chevron-right" />}
                      onClick={() => {
                        setPointDate(dayjs(pointDate).add(1, "day").toDate())
                      }}
                    >
                      วันถัดไป
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div>ช่วงเวลา</div>
                  <div className="text-lg font-semibold">
                    {dayjs(startDate).format("D MMM YYYY")} - {dayjs(endDate).format("D MMM YYYY")}
                  </div>
                  <div>คิดเป็นเวลา {dayjs(endDate).diff(dayjs(startDate), "day")} วัน</div>
                </div>
              )}
              <div className="my-2">{children}</div>
            </div>
          ) : (
            <div className="w-full lg:w-1/3 self-center">
              <div>{children}</div>
            </div>
          )}
          <div className="w-full lg:w-1/3">
            {!dateOnly && (
              <div className="flex gap-2">
                <div>ช่วงเวลา</div>
                <Switch
                  value={usePointDate}
                  defaultChecked={usePointDate}
                  onChange={(event) => setIsUsePointDate(event.target.checked)}
                ></Switch>
                <div>จุดเวลา</div>
              </div>
            )}
            {usePointDate && !dateOnly && (
              <div>
                <ButtonGroup isAttached size="sm" variant="outline">
                  <Button
                    onClick={() => setPointDateType("day")}
                    isActive={pointDateType === "day"}
                  >
                    รายวัน
                  </Button>
                  <Button
                    onClick={() => setPointDateType("month")}
                    isActive={pointDateType === "month"}
                  >
                    รายเดือน
                  </Button>
                  <Button
                    onClick={() => setPointDateType("year")}
                    isActive={pointDateType === "year"}
                  >
                    รายปี
                  </Button>
                </ButtonGroup>
                <div className="my-2">
                  {pointDateType === "day" && renderPointDayPicker()}
                  {pointDateType === "month" && renderMonthPicker()}
                  {pointDateType === "year" && renderYearPicker()}
                </div>
              </div>
            )}
            {!usePointDate && <div>{renderRelativeDayPicker()}</div>}
          </div>
          {dateOnly && <div>{renderPointDayPicker()}</div>}
        </div>
      </WhitePanel>
    </div>
  )
}

ReportDateSelector.propTypes = {}

export default ReportDateSelector
