import React from "react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"

export default function RecentBlogExample({ articles, info }) {
  const { i18n } = useTranslation()

  const findHilightImage = (images) => {
    if (_.isEmpty(images) || !images) {
      return "https://storage.googleapis.com/mydev_eonlineshop/other/thumbnails/1645339249181_800x800.png"
    }

    const foundHilight = _.find(images, { hilight: true })
    if (foundHilight) {
      return foundHilight?.image?.url
    }
    return images?.[0]?.image?.url
  }

  return (
    <div id="room" className=" h-full w-full md:items-center ">
      <Link to="/site/recent-article-description">
        <Button size="sm">แก้ไข</Button>
      </Link>
      <div className="p-2 my-2">
        <h1 className="text-center font-bold text-gray-600 text-3xl">
          {info.composite?.translation?.[i18n.language].recent_article_header || "บล็อกล่าสุด"}{" "}
        </h1>
        <h2 className="text-center py-5 text-xl text-gray-400">
          {info.composite?.translation?.[i18n.language]?.recent_article_brief || ""}
        </h2>
        <div>{info.composite?.translation?.[i18n.language]?.recent_article_description || ""}</div>
      </div>
      <div className="">
        <div className="bg-white w-full rounded-xl">
          <div className="pt-8 mt-10 px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              {/** แสดงทุกห้องที่ตั้งค่าว่าแสดงผล หรือว่า enable เป็น true */}
              {_.map(articles, (row) => (
                <div>
                  <div className="px-4 mb-8  hover:cursor-pointer" key={row?.id}>
                    <div className="flex items-center p-4 justify-center" style={{ height: 150 }}>
                      <img
                        objectFit="cover"
                        loading="eager"
                        quality="10"
                        src={findHilightImage(row?.images)}
                        alt={row?.name}
                        className="rounded-lg "
                      />
                    </div>
                    <div className="p-1 text-blue-600 text-xl h-20 flex items-center justify-center">
                      <h1 className="text-center ">{row?.translation?.[i18n.language]?.name}</h1>
                    </div>
                    <p className="text-center pt-0 text-gray-500 text-base  ">
                      {_.truncate(row?.translation?.[i18n.language]?.description, { length: 150 })}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
