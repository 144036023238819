import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import _ from "lodash"

import { SEOInfo } from "../../components/Forms/HotelSite"
import Layout from "../../components/layout/GeneralLayout"
import { Alert } from "../../components/common/Alert"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"

export default function SEOManagement() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, control } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const info = useSelector((state) => state.information)
  const toast = useToast()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")

  const onSubmit = (data) => {
    console.log("Data", data)

    dispatch(actions.editOneInformation(info?.id, data)).then(() => {
      dispatch(actions.getInformation())
        .then(() => {
          toast({
            title: "แก้ไขข้อมูลสำเร็จ",
            description:
              "แก้ไขข้อมูลเกี่ยวกับโรงแรมและเว็บไซต์โรงแรมสำเร็จ กรุณารอประมาณ 2-3 นาทีจะมีผลในเว็บไซต์",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
          dispatch(actions.buildNewWebsite())
        })
        .catch((error) => {
          setErrMessage(error?.message)
          setIsError(true)
        })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="จัดการ SEO">
      <Alert
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
        isError={isError}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <SEOInfo defaultInfo={info} register={register} control={control} />
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
