/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useHistory, Link } from "react-router-dom"
import { Button, ButtonGroup, Table, Th, Tr, Td, Thead, Tbody } from "@chakra-ui/react"
import moment from "moment"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import Layout from "../../components/layout/GeneralLayout"
import { SpinnerLoading } from "../../components/Loading"
import Paginator from "../../components/common/Paginator/NewPagination"
import { WhitePanel } from "../../components/common/Cards"
import * as actions from "../../redux/actions"
import { Alert } from "../../components/common/Alert"
import { TransactionType, PaymentMethod, BookingStatus } from "../../config/constant"
import { currencyFormatter } from "../../util"
import { PaymentTransactionReport } from "../../config/pdfmake/template"
import api from "../../config/api"

moment.locale("th")

export default function PaymentTransactions() {
  const { t } = useTranslation()
  const history = useHistory()
  const transactions = useSelector((state) => state.transaction)
  const system = useSelector((state) => state.system)
  const information = useSelector((state) => state.information)

  const [isLoading, setIsLoading] = useState(false)
  const [dayAmount, setDayAmount] = useState(1)
  const [orderByDir, setOrderByDir] = useState("desc")
  const [orderByField, setOrderByField] = useState("createdAt")
  const [page, setPage] = useState(1)
  const [isError, setIsError] = useState(false)
  const [errMeassge, setErrMessage] = useState("")
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(moment().add(1, "day").format("YYYY-MM-DD"))
  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getAllTransaction({
        page,
        size: 20,
        query: {
          transactionType: TransactionType.CREATE_PAYMENT.status_code,
          start: startDate,
          end: endDate,
          orderByField,
          orderBy: orderByDir,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })
    return () => {}
  }, [page, startDate, endDate, orderByDir, orderByField])

  const renderDayAmountSelect = () => (
    <div>
      <ButtonGroup size="sm" isAttached>
        <Button
          onClick={() => {
            if (dayAmount === 1 && !moment().isSame(moment(startDate), "date")) {
              setStartDate(moment().format("YYYY-MM-DD"))
              setEndDate(moment().add(1, "day").format("YYYY-MM-DD"))
            }
            setDayAmount(1)
          }}
        >
          วันนี้
        </Button>
        <Button onClick={() => setDayAmount(3)}>3 วันที่ผ่านมา</Button>
        <Button onClick={() => setDayAmount(7)}>สัปดาห์ที่ผ่านมา</Button>
      </ButtonGroup>
    </div>
  )

  const renderDateShow = () => {
    switch (dayAmount) {
      case 1:
        return moment(startDate).format("ddd D MMMM YYYY")
      case 3:
        return `${moment(startDate)
          .subtract(3, "day")
          .format("ddd D MMMM YYYY")} - ${moment().format("ddd D MMMM YYYY")}`
      case 7:
        return `${moment(startDate)
          .subtract(7, "day")
          .format("ddd D MMMM YYYY")} - ${moment().format("ddd D MMMM YYYY")}`
      default:
        return "ระบุวันไม่ถูกต้อง"
    }
  }

  const handlePrintPDF = async () => {
    try {
      const queryParams = new URLSearchParams({
        transactionType: TransactionType.CREATE_PAYMENT.status_code,
        start: startDate,
        end: endDate,
        orderByField,
        orderBy: orderByDir,
      })
      console.info(
        "URL =>",
        `${
          process.env.REACT_APP_API_URL
        }/transaction?page=${page}&size=${1000000}&${queryParams.toString()}`,
      )
      await api
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/transaction/?page=${page}&size=${1000000}&${queryParams.toString()}`,
        )
        .then(async (res) => {
          const definition = PaymentTransactionReport(
            res?.data?.rows,
            startDate,
            endDate,
            information,
            system,
          )
          await pdfMake.createPdf(definition).open()
        })
        .catch((err) => {
          throw new Error(err)
        })
    } catch (error) {
      setErrMessage(`ไม่สามารถพิมพ์ PDF ได้ ${error?.message}`)
      setIsError(true)
    }
  }

  useEffect(() => {
    if (dayAmount === 1) {
      setStartDate(moment().format("YYYY-MM-DD"))
      setEndDate(moment().add(1, "day").format("YYYY-MM-DD"))
      setPage(1)
    }
    if (dayAmount === 3) {
      setStartDate(moment().subtract(3, "day").format("YYYY-MM-DD"))
      setEndDate(moment().add(1, "day").format("YYYY-MM-DD"))
      setPage(1)
    }
    if (dayAmount === 7) {
      setStartDate(moment().subtract(7, "day").format("YYYY-MM-DD"))
      setEndDate(moment().add(1, "day").format("YYYY-MM-DD"))
      setPage(1)
    }
    return () => {}
  }, [dayAmount])

  const setSelectedField = (field) => {
    if (orderByField === field) {
      if (orderByDir === "asc") {
        setOrderByDir("desc")
      } else {
        setOrderByDir("asc")
      }
    } else {
      setOrderByField(field)
      setOrderByDir("desc")
    }
  }

  const chevronDisplay = (field) => {
    if (field === orderByField) {
      if (orderByDir === "asc") {
        return (
          <div className="self-center  mx-3 ">
            <i className="fas fa-chevron-up text-sm" />
          </div>
        )
      }
      return (
        <div className="self-center  mx-3 ">
          <i className="fas fa-chevron-down text-sm" />
        </div>
      )
    }
    return (
      <div className="self-center  mx-3 ">
        {" "}
        <i className="fas fa-chevron-down text-gray-400 text-sm" />
      </div>
    )
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <div>
      <Layout categoryTitle={t("history.title")} title={t("history.paymentTransaction")}>
        <Alert
          errMessage={errMeassge}
          isError={isError}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage("")
          }}
        />
        <div className="flex justify-between px-4">
          <Button
            colorScheme="purple"
            variant="outline"
            onClick={() => {
              history.goBack()
            }}
          >
            {t("general.back")}
          </Button>
          <Button
            colorScheme="red"
            size="sm"
            leftIcon={<i className="fas fa-file-pdf"></i>}
            onClick={() => handlePrintPDF()}
          >
            PDF
          </Button>
        </div>
        <div className="p-4 w-full">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <div className="font-bold self-center text-xl my-2">{renderDateShow()}</div>
              <div className="self-center">
                <ButtonGroup isAttached>
                  <Button
                    size="sm"
                    leftIcon={<i className="fas fa-angle-double-left" />}
                    onClick={() => {
                      setStartDate(moment(startDate).subtract(1, "day").format("YYYY-MM-DD"))
                      setEndDate(moment(endDate).subtract(1, "day").format("YYYY-MM-DD"))
                      setPage(1)
                    }}
                  >
                    ก่อนหน้า
                  </Button>
                  <Button
                    size="sm"
                    rightIcon={<i className="fas fa-angle-double-right" />}
                    disabled={moment(startDate).isSame(moment(), "date")}
                    onClick={() => {
                      setStartDate(moment(startDate).add(1, "day").format("YYYY-MM-DD"))
                      setEndDate(moment(endDate).add(1, "day").format("YYYY-MM-DD"))
                      setPage(1)
                    }}
                  >
                    ถัดไป
                  </Button>
                </ButtonGroup>
              </div>
            </div>
            {renderDayAmountSelect()}
          </div>
          <div className="my-2">
            <WhitePanel>
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2">
                  <div className="text-lg font-semibold">สรุปรายรับรวม</div>
                  <div className="font-semibold text-2xl">
                    {currencyFormatter.format(transactions?.summarized)} บาท
                  </div>
                </div>
                <div className="w-full md:w-1/2">
                  <div className="text-lg font-semibold">สรุปยอดแต่ละช่องทาง</div>
                  <div className="ml-2">
                    {_.map(transactions?.analyzed, (eachAnalyzed, index) => (
                      <li key={index} className="text-base">
                        <b>{PaymentMethod[eachAnalyzed?._id]?.description || "ไม่ระบุ"} </b>{" "}
                        {currencyFormatter.format(eachAnalyzed?.total)} บาท
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            </WhitePanel>
          </div>
          <Table variant="simple" colorScheme="facebook" className=" overflow-x-auto">
            <Thead backgroundColor="white">
              <Tr>
                <Th>No.</Th>
                <Th
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => setSelectedField("createdAt")}
                >
                  <div className="flex">วันที่{chevronDisplay("createdAt")}</div>
                </Th>
                <Th
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => setSelectedField("booking.booking_no")}
                >
                  {" "}
                  <div className="flex">รายการจอง {chevronDisplay("booking.booking_no")}</div>
                </Th>{" "}
                <Th
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => setSelectedField("payment_method")}
                >
                  <div className="flex">
                    ช่องทางการชำระเงิน
                    {chevronDisplay("payment_method")}
                  </div>
                </Th>
                <Th>ลูกค้า</Th>
                <Th
                  className="hover:bg-gray-100 cursor-pointer"
                  onClick={() => setSelectedField("start")}
                >
                  <div className="flex">
                    จำนวนเงิน
                    {chevronDisplay("price")}
                  </div>
                </Th>
                <Th>ผู้ดำเนินการ</Th>
                <Th>หมายเหตุ</Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.map(transactions?.arr, (eachLog, index) => (
                <Tr key={index}>
                  <Td>{(page - 1) * 10 + index + 1}</Td>
                  <Td className="text-sm">
                    {moment(eachLog?.createdAt).locale("th").format("D MMM YYYY")}
                    <br />
                    {moment(eachLog?.createdAt).locale("th").format("HH:mm")}
                  </Td>
                  <Td>
                    <div className="flex">
                      <span className="self-center">
                        {eachLog?.booking?.booking_no ? (
                          eachLog?.booking?.booking_no
                        ) : (
                          <div>
                            <div className="text-xs bg-pink-300 px-1 rounded-md ">บริการ</div>{" "}
                            {eachLog?.service_order?.order_no}
                          </div>
                        )}
                      </span>
                      {eachLog?.booking?.booking_no ? (
                        <Link
                          to={`/${
                            eachLog?.booking?.status === BookingStatus.success ||
                            eachLog?.booking?.status === BookingStatus.cancle
                              ? "history"
                              : "reservation"
                          }/view/${eachLog?.booking?._id}`}
                        >
                          <Button size="sm" variant="outline" className="mx-1">
                            <i className="fas fa-file-alt" />{" "}
                          </Button>
                        </Link>
                      ) : (
                        <Link to={`/order/service-orders/view/${eachLog?.service_order?._id}`}>
                          <Button size="sm" variant="outline" className="mx-1">
                            <i className="fas fa-file-alt" />{" "}
                          </Button>
                        </Link>
                      )}
                    </div>
                  </Td>
                  <Td>{PaymentMethod[eachLog?.payment_method]?.description}</Td>
                  <Td className="text-sm">
                    {eachLog?.booking?.customer?.firstname ||
                      eachLog?.service_order?.customer?.firstname}
                    <br />{" "}
                    {eachLog?.booking?.customer?.lastname ||
                      eachLog?.service_order?.customer?.lastname}
                  </Td>
                  <Td>{currencyFormatter.format(eachLog?.price)}</Td>
                  <Td className="text-sm">
                    {eachLog?.user?.employee?.firstname}
                    <br /> {eachLog?.user?.employee?.lastname}
                  </Td>
                  <Td className="text-sm">{eachLog?.info}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Paginator lastPage={transactions?.lastPage} setPage={setPage} />
        </div>
      </Layout>
    </div>
  )
}
