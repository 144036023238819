import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { Form } from "antd"
import _ from "lodash"

import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import { ImageUpload } from "../../components/ImageUpload"

export default function EditCoverImage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [coverImage, setCoverImage] = useState([])
  const info = useSelector((state) => state.information)

  const onSubmit = () => {
    const payload = {
      cover: !_.isEmpty(coverImage) ? _.first(coverImage) : undefined,
    }
    dispatch(actions.editOneInformation(info?.id, payload)).then(() => {
      setIsLoading(false)
      dispatch(actions.getInformation()).then(() => {
        setIsLoading(true)
        history.goBack()
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="แก้ไขรูปหน้าปก">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form
            layout="vertical"
            size="large"
            className="w-full lg:w-2/4 pl-2"
            // labelCol={{ span: 6 }}
            // wrapperCol={{ span: 16 }}
          >
            <h6 className="w-full text-gray-900 text-base  font-bold  font-sans my-2">
              รูปหน้าปกเว็บไซต์
            </h6>
            <Form.Item label="รูปหน้าปกเว็บไซต์" tooltip="จะขึ้นในด้านบนสุดของเว็บไซต์">
              <div className="flex">
                {_.isObject(info?.cover) && (
                  <div className=" flex justify-center border m-2 p-2 rounded-md">
                    <img src={info?.cover?.url || ""} className="h-32" />
                  </div>
                )}
                <ImageUpload
                  images={coverImage}
                  setImages={setCoverImage}
                  preview_size="250"
                  maxNumber={1}
                />
              </div>
            </Form.Item>
          </Form>
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
