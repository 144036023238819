import React from "react"
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  Textarea,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ReactSelect from "react-select"
import _ from "lodash"

import { CountryList } from "../../../config/constant"

export default function CreateOrEditCustomerModal({
  isOpen,
  handleOnClose,
  handleCreateCustomer,
  defaultValue,
  mode = "create",
}) {
  const { register, handleSubmit, setValue } = useForm()
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-sans" fontSize="lg">
          {mode === "create" ? "สร้างลูกค้า" : "แก้ไขลูกค้า"}
        </ModalHeader>
        <ModalCloseButton />
        <form>
          <ModalBody className="font-sans">
            <div className="flex flex-wrap gap-2">
              <FormControl>
                <label className="text-sm font-bold">{t("general.name")}*</label>
                <Input
                  size="sm"
                  type="text"
                  name="firstname"
                  ref={register}
                  required
                  defaultValue={defaultValue?.firstname}
                />
              </FormControl>
              <FormControl>
                <label className="text-sm font-bold">{t("general.surname")}</label>
                <Input
                  size="sm"
                  type="text"
                  name="lastname"
                  defaultValue={defaultValue?.lastname}
                  ref={register}
                />
              </FormControl>
            </div>
            <FormControl>
              <label className="text-sm font-bold">{t("general.tel")}</label>
              <Input
                size="sm"
                type="text"
                name="tel"
                defaultValue={defaultValue?.tel}
                ref={register}
              />
            </FormControl>

            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                สัญชาติ
              </label>
              <input type="hidden" ref={register} name="nationality" />
              <ReactSelect
                placeholder="สัญชาติ"
                name="nationality"
                ref={register}
                options={CountryList}
                defaultValue={_.find(CountryList, (eachCountry) => eachCountry.value === "TH")}
                onChange={(newValue) => {
                  setValue("nationality", newValue?.value)
                }}
              />
            </div>
            <div className="relative w-full mb-3">
              <label className="text-sm font-bold">หมายเลขบัตรประจำตัวประชาชน / นิติบุคคล</label>
              <Input
                size="sm"
                type="text"
                name="id_card"
                ref={register}
                defaultValue={defaultValue?.id_card}
              />
            </div>
            <div className="relative w-full mb-3">
              <label className="text-sm font-bold">ที่อยู่</label>
              <Textarea
                size="sm"
                name="address"
                rows={3}
                ref={register}
                defaultValue={defaultValue?.address}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="flex justify-end gap-2 font-sans">
              <Button
                size="sm"
                colorScheme="green"
                type="button"
                onClick={handleSubmit(handleCreateCustomer)}
              >
                {mode === "create" ? t("general.add") : t("general.edit")}
              </Button>
              <Button size="sm" colorScheme="gray" type="button" onClick={handleOnClose}>
                {t("general.cancle")}
              </Button>
            </div>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}

CreateOrEditCustomerModal.propTypes = {
  isOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
  handleCreateCustomer: PropTypes.func,
}
