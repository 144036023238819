/* eslint-disable no-nested-ternary */
import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { NewPaginator } from "../common/Paginator"

function FBOrderList({ fbList, page = 1, setPage = () => {} }) {
  const { i18n } = useTranslation()
  return (
    <div>
      <div className="flex flex-col divide-y-2 py-1">
        {_.map(fbList?.arr, (eachList, index) => (
          <div key={index} className="flex py-2">
            <div className="w-2/4 md:w-3/5 px-1">
              <div>
                {eachList?.menu?.translation?.[i18n.language]?.name} x {eachList?.quantity}
              </div>{" "}
              {eachList?.option && <div className="text-sm">ตัวเลือก: {eachList?.option}</div>}
              {!_.isEmpty(eachList?.toppings) && (
                <div className="text-sm">เพิ่ม: {_.map(eachList?.toppings, (each) => each)}</div>
              )}
            </div>
            <div className="w-1/4 md:w-1/5 px-1">
              <div>{eachList?.price} บาท</div>{" "}
            </div>

            <div className="w-1/4 md:w-1/5 px-1">
              {eachList?.completed ? (
                <div>
                  <i className="fas fa-check"></i> เสร็จ
                </div>
              ) : eachList?.recieved ? (
                <div>
                  <i className="fas fa-pepper-hot"></i> รับออเดอร์ กำลังทำ
                </div>
              ) : (
                <div>
                  <i className="fas fa-hourglass-half"></i> รอรับออเดอร์
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <NewPaginator lastPage={fbList?.lastPage} page={page} setPage={setPage} />
    </div>
  )
}

export default FBOrderList
