/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { useHistory, useParams } from "react-router-dom"
import { DateTime } from "luxon"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import "dayjs/locale/th"

import {
  RoomSelector,
  BasicBookingInfoPanel,
  WhitePanel,
  BookingLayout as Layout,
  SpinnerLoading as Spinners,
} from "../../../components"

import * as actions from "../../../redux/actions"
import { useQuery } from "../../../config"

dayjs.locale("th")

// TODO: Using New Alert Components
export default function AssignReservation() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { handleSubmit, register } = useForm()
  const roomTypes = useSelector((state) => state.roomTypes)
  const rooms = useSelector((state) => state.rooms)
  const booking = useSelector((state) => state.bookings)
  const subtype = useSelector((state) => state.subRoomTypes)
  const me = useSelector((state) => state.me)
  const { t } = useTranslation()
  const query = useQuery()

  const [avaliableRoom, setAvaliableRoom] = useState(rooms?.arr)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [checkedInDate, setCheckedInDate] = useState(dayjs(booking?.start).toDate())
  const [checkedOutDate, setCheckedoutDate] = useState(dayjs(booking?.end).toDate())
  const [alreadyBookendRoom, setAlreadyBookedRoom] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(actions.getOneBooking(params.id))
      .then(() => {
        if (query.get("subtype")) {
          dispatch(actions.getOneSubRoomType(query.get("subtype")))
        }
        setIsLoading(true)
      })
      .catch((err) => {
        console.error("Booking Get Error", err)
      })

    return () => {}
  }, [params, query.get("subtype")])

  useEffect(() => {
    if (
      booking &&
      dayjs(booking?.start).isSame(dayjs(checkedInDate), "date") &&
      (dayjs(booking?.end).isBefore(dayjs(checkedOutDate), "date") ||
        dayjs(booking?.end).isSame(dayjs(checkedOutDate), "date"))
    ) {
      const filteredBookingList = _.filter(
        booking?.list,
        (each) => each?.room?.type?._id === query.get("roomtype"),
      )
      const bookedRoom = _.map(filteredBookingList, (eachList) => ({
        ...eachList?.room,
        id: eachList?.room?._id,
      }))
      const concatingRoom = _.concat(rooms?.arr, bookedRoom)
      setSelectedRooms(bookedRoom)
      setAvaliableRoom(concatingRoom)
      setAlreadyBookedRoom(bookedRoom)
    } else {
      setAvaliableRoom(rooms?.arr)
      setSelectedRooms([])
    }
    return () => {}
  }, [booking, checkedInDate, rooms])

  useEffect(() => {
    if (booking) {
      setCheckedInDate(dayjs(booking?.start).toDate())
      setCheckedoutDate(dayjs(booking?.end).toDate())
    }

    return () => {}
  }, [booking])

  const onEditBooking = (data) => {
    if (selectedRooms) {
      const confirm = window.confirm(t("reservation.confirmEditBooking"))
      if (confirm) {
        const noRoomBooking = booking?.no_room_booking
        const selectedRoomTypeNoRoomBookingIndex = _.findIndex(
          noRoomBooking,
          (each) => each?.roomtype?._id === query.get("roomtype"),
        )
        const filteredBookingList = _.filter(
          booking?.list,
          (each) => each?.room?.type?._id === query.get("roomtype"),
        )

        const notSameTypeBookingList =
          _.filter(booking?.list, (each) => each?.room?.type?._id !== query.get("roomtype")) || []

        const noRoomBookingOnIndex = {
          roomtype: query.get("roomtype"),
          amount: query.get("amount") - _.size(selectedRooms) + _.size(filteredBookingList),
        }
        console.log("Amount", query.get("amount"))
        console.log("Selected Room", _.size(selectedRooms))
        console.log("Already Booked Room", _.size(filteredBookingList))
        noRoomBooking[selectedRoomTypeNoRoomBookingIndex] = noRoomBookingOnIndex

        const payload = {
          list: [
            ..._.map(
              _.filter(selectedRooms, (room) => room !== undefined),
              (eachRoom) => ({
                room_id: eachRoom.id,
                room: eachRoom.id,
                price: eachRoom?.sub_type?.price,
              }),
            ),
            ...notSameTypeBookingList,
          ],
          no_room_booking: noRoomBooking,
          start: dayjs(checkedInDate).startOf("day"),
          end: dayjs(checkedOutDate).startOf("day"),
          remark: data?.remark,
          booking_source: data?.booking_source,
          user: me?._id,
          info: "จัดสรรห้องพัก",
        }
        console.log("No Room Booking", noRoomBooking)
        console.log("Payload", payload)
        dispatch(actions.editOneBooking(params.id, payload))
          .then(() => {
            dispatch(actions.getOneBooking(params.id))
            history.push(`/reservation/view/${params.id}`)
          })
          .catch((err) => {
            window.alert("Edit Booking Fail", err?.message)
          })
      }
    } else {
      window.alert(t("reservation.alertIncompleteData"))
    }
  }

  useEffect(() => {
    dispatch(actions.getAllRoomTypes({ roomTypeId: query.get("roomtype"), fetchBooking: true }))
    return () => {}
  }, [])

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getVacantRooms(
        DateTime.fromJSDate(checkedInDate).toISODate(),
        DateTime.fromJSDate(checkedOutDate).toISODate(),
      ),
    ).then(() => {
      if (booking?.id) {
        setIsLoading(true)
      }
    })
    return () => {}
  }, [checkedInDate, checkedOutDate])

  if (!isLoading) {
    return <Spinners />
  }

  return (
    <Layout title={t("reservation.assignRoom")} categoryTitle={t("reservation.title")}>
      <div className="w-full flex px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="w-full my-2">
        <WhitePanel>
          <BasicBookingInfoPanel bookingInfo={booking} showPrintingPanel={false} />
        </WhitePanel>
        <form onSubmit={handleSubmit(onEditBooking)}>
          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
            <div className="w-full px-4 ">
              <div className="flex flex-wrap ">
                <div className="relative w-full lg:w-1/3 mb-3 px-1 ">
                  <label
                    className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    {t("reservation.checkinDate")}
                  </label>
                  <div className="">{dayjs(checkedInDate)?.format("D MMM YYYY")}</div>
                </div>
                <div className="relative w-full lg:w-1/3 mb-3 px-1 ">
                  <label
                    className="block uppercase text-gray-700 text-sm font-semibold mb-2"
                    htmlFor="grid-password"
                  >
                    {t("reservation.checkoutDate")}
                  </label>
                  <div className="">{dayjs(checkedOutDate)?.format("D MMM YYYY")}</div>
                </div>
                <div className="relative w-full lg:w-1/3 mb-3 px-1 ">
                  <div>หมวดหมู่ห้อง</div>{" "}
                  <div className="font-semibold text-lg">
                    {roomTypes?.arr?.[0]?.translation?.th?.name || roomTypes?.arr?.[0]?.name}{" "}
                  </div>
                  <div>{subtype?.name}</div>
                  <div className="mt-2">จำนวนที่ต้องจัดสรร </div>
                  <div className="font-semibold text-lg">{query.get("amount")} ห้อง</div>
                </div>
              </div>
              <RoomSelector
                roomTypes={roomTypes}
                rooms={avaliableRoom}
                selectedRooms={selectedRooms}
                setSelectedRooms={setSelectedRooms}
                limit={parseInt(query.get("amount"), 10) + _.size(alreadyBookendRoom)}
              />
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  {t("reservation.assignRoom")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>{" "}
    </Layout>
  )
}
