/* eslint-disable no-underscore-dangle */
import { Table, Tbody, Td, Th, Thead, Tr, Input } from "@chakra-ui/react"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import { Controller } from "react-hook-form"
import dayjs from "dayjs"

import { MeetingRoomTimeSlot } from "../../../config/constant"

function MeetingRoomPaymentForm({ control, meetingRoomOrders }) {
  const { t, i18n } = useTranslation()

  const calculatePriceFronPriceList = (priceList, start, end) => {
    const hourUse = dayjs(end).diff(dayjs(start), "hour")
    const filterPriceList = _.filter(priceList, (each) => each?.hour >= hourUse)
    const selectedPriceList = _.find(
      filterPriceList,
      (each) => each?.hour === _.min(_.map(filterPriceList, (priceTuple) => priceTuple?.hour)),
    )
    return selectedPriceList?.price || 0
  }

  return (
    <div>
      <Table colorScheme="facebook" className="overflow-x-auto">
        <Thead backgroundColor="white">
          <Tr>
            <Th>No.</Th>
            <Th>{t("roomtype.meetingRoom")}</Th>
            <Th>{t("general.date")}</Th>
            <Th>{t("reservation.selectedSlot")} </Th>
            <Th>{t("roomtype.generalPrice")}</Th>
            <Th>{t("roomtype.realPrice")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(meetingRoomOrders, (eachField, index) => (
            <Tr key={index}>
              <Td>{index + 1}</Td>
              <Td>
                {eachField?.meeting_room?.translation?.[i18n.language]?.name}

                <Controller
                  defaultValue={eachField?._id}
                  name={`meeting_room_orders[${index}]._id`}
                  control={control}
                  render={(field) => <input type="hidden" {...field} />}
                />
              </Td>
              <Td className="text-sm">{dayjs(eachField?.date).format("D MMM YYYY")}</Td>
              <Td>
                {eachField?.meeting_room?.use_slot_price
                  ? MeetingRoomTimeSlot[eachField?.timeslot]?.description
                  : `${dayjs(eachField?.start)?.format("HH.mm น.")} - ${dayjs(
                      eachField?.end,
                    )?.format("HH.mm น.")}  (${dayjs(eachField?.end).diff(
                      dayjs(eachField?.start),
                      "hour",
                    )}  ชม. ${
                      dayjs(eachField?.end).diff(dayjs(eachField?.start), "minute") % 60
                    } นาที )    `}
              </Td>
              <Td>
                {eachField?.meeting_room?.use_slot_price
                  ? eachField?.meeting_room?.slot_price?.[_.lowerCase(eachField?.timeslot)]
                  : calculatePriceFronPriceList(
                      eachField?.meeting_room?.price_list,
                      eachField?.start,
                      eachField?.end,
                    )}{" "}
                {t("general.baht")}
              </Td>
              <Td className="flex gap-2">
                <Controller
                  defaultValue={
                    eachField?.price ||
                    (eachField?.meeting_room?.use_slot_price
                      ? eachField?.meeting_room?.slot_price?.[_.lowerCase(eachField?.timeslot)]
                      : calculatePriceFronPriceList(
                          eachField?.meeting_room?.price_list,
                          eachField?.start,
                          eachField?.end,
                        ))
                  }
                  name={`meeting_room_orders[${index}].price`}
                  control={control}
                  render={(field) => (
                    <Input
                      {...field}
                      placeholder={t("roomtype.realPrice")}
                      size="sm"
                      onChange={(event) => field.onChange(parseFloat(event.target.value) || 0)}
                    />
                  )}
                />
                <div className="self-center">{t("general.baht")}</div>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default MeetingRoomPaymentForm
