/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  Button,
  ModalFooter,
} from "@chakra-ui/react"
import _ from "lodash"
import { useForm, Controller } from "react-hook-form"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Radio } from "antd"

import PricePaymentAmount from "../../Panels/PricePaymentAmount"
import { PricePanel, ServiceDisplayPanel } from "../../Panels"
import BookingPriceAddingForm from "../../Forms/Reservation/BookingPriceAddingForm"
import { dayAmount as dayAmountFunction } from "../../../util"
import { PaymentMethod } from "../../../config/constant"

export default function ModalPayment({
  isOpen,
  handleOnClose,
  bookingInfo,
  handleEditBooking = () => {},
  rooms,
}) {
  const { t, i18n } = useTranslation()
  const [rerender, setRerender] = useState(false)

  const [allPrice, setAllPrice] = useState()
  const [allDiscount, setAllDiscount] = useState()
  const [finalDiscount, setFinalDiscount] = useState(0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()
  const dayAmount = dayAmountFunction(bookingInfo?.start, bookingInfo?.end)

  const servicePriceArray = bookingInfo?.services?.map((eachList) => eachList?.price)
  const fbPriceArray = bookingInfo?.fb_orders?.map((eachList) => eachList?.price)
  const { register, handleSubmit, setValue, control, watch } = useForm({
    defaultValues: {
      list: _.isArray(bookingInfo?.list) ? bookingInfo?.list : [],
      use_discount_percent: bookingInfo?.use_discount_percent,
    },
  })

  useEffect(() => {
    if (_.isArray(bookingInfo?.list)) {
      setAllPrice(bookingInfo?.list?.map((eachList) => eachList?.price * dayAmount))
      setAllDiscount(bookingInfo?.list?.map((eachList) => eachList?.discount * dayAmount))
      setFinalDiscount(bookingInfo?.final_discount || 0)
      setValue("list", bookingInfo?.list)
    }
    return () => {}
  }, [bookingInfo])

  const handleEditPaymentPrice = (data) => {
    // todo: มันจะดีมากเลยถ้ามีการเก็บ Payment Transaction แยกอีกทีหนึ่ง
    const bookingPayload = {
      ...bookingInfo,
      ...data,
      skipTransactionCreate: true,
    }
    console.log("Payload Data", bookingPayload)
    handleEditBooking(bookingInfo?._id, { ...data, skipTransactionCreate: true })
    handleOnClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="xl">
      <ModalOverlay />{" "}
      <form>
        <ModalContent>
          <ModalHeader className="font-sans" fontSize="lg">
            {t("reservation.paying")}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody className="font-sans mb-4">
            <div className=" w-full ">
              <BookingPriceAddingForm
                allDiscount={allDiscount}
                allPrice={allPrice}
                bookingInfo={bookingInfo}
                control={control}
                endDate={bookingInfo?.end}
                finalDiscount={finalDiscount}
                register={register}
                rerender={rerender}
                rooms={rooms}
                setAllDiscount={setAllDiscount}
                setAllPrice={setAllPrice}
                setFinalDiscount={setFinalDiscount}
                setRerender={setRerender}
                setValue={setValue}
                startDate={bookingInfo?.start}
                watch={watch}
                inputBookingList={[]}
              />
              <hr />
              <h6 className="text-xs font-bold mt-2">{t("reservation.otherService")} </h6>
              <div className="mb-2">
                <ServiceDisplayPanel services={bookingInfo?.services} />
              </div>
              <hr />
              <PricePanel
                allPrice={
                  _.sum(allPrice) +
                  _.sum(servicePriceArray) +
                  _.sum(fbPriceArray) -
                  _.sum(allDiscount) -
                  finalDiscount
                }
                bookingDiscount={_.sum(allDiscount)}
                finalDiscount={finalDiscount}
                bookingInfo={bookingInfo}
              />
              <hr />
              <div className="mt-2">
                <h6 className="text-sm my-2 font-bold ">{t("reservation.payment")}</h6>
                <h6 className="text-xs font-bold mt-2">{t("reservation.paymentMethod")}</h6>
                <div className="my-2">
                  <Controller
                    control={control}
                    name="payment_method"
                    render={(field) => (
                      <Radio.Group
                        {...field}
                        optionType="button"
                        buttonStyle="solid"
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.value)
                          setSelectedPaymentMethod(e.target.value)
                        }}
                      >
                        {_.map(PaymentMethod, (eachMethod, index) => (
                          <Radio.Button
                            value={eachMethod.status_code}
                            key={index}
                            style={{ borderRadius: "0" }}
                          >
                            <span className="font-sans">
                              {i18n?.language === "th"
                                ? eachMethod.description
                                : eachMethod.descriptionEN}
                            </span>
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    )}
                  />
                </div>

                {/* <FormControl>
                  <Select
                    ref={register}
                    name="payment_method"
                    size="sm"
                    autoFocus
                    placeholder={t("reservation.paymentMethod")}
                    onChange={(event) => setSelectedPaymentMethod(event.target.value)}
                  >
                    {_.map(PaymentMethod, (eachMethod, index) => (
                      <option key={index} value={eachMethod.status_code}>
                        {" "}
                        {i18n?.language === "th"
                          ? eachMethod.description
                          : eachMethod.descriptionEN}{" "}
                      </option>
                    ))}
                  </Select>
                </FormControl> */}

                <div className="mt-2">
                  <PricePaymentAmount
                    bookingInfo={bookingInfo}
                    allPrice={
                      _.sum(allPrice) +
                      _.sum(servicePriceArray) +
                      _.sum(fbPriceArray) -
                      _.sum(allDiscount) -
                      finalDiscount
                    }
                    paymentMethod={selectedPaymentMethod}
                    handleSubmitMainForm={handleSubmit(handleEditPaymentPrice)}
                  />
                </div>
              </div>
            </div>

            <br />
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-end mt-2 gap-2 font-sans">
              <Button
                size="sm"
                colorScheme="green"
                type="button"
                onClick={handleSubmit(handleEditPaymentPrice)}
              >
                {t("reservation.savePrice")}
              </Button>
              <Button size="sm" type="button" onClick={handleOnClose}>
                {t("general.cancle")}
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>{" "}
      </form>
    </Modal>
  )
}

ModalPayment.propTypes = {
  isOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
  bookingInfo: PropTypes.object,
  handleEditBooking: PropTypes.func,
  rooms: PropTypes.shape({
    arr: PropTypes.arrayOf(PropTypes.object),
  }),
}
