import React from "react"
import _ from "lodash"
import { WhitePanel } from "../../common/Cards"
// eslint-disable-next-line arrow-body-style
const HorizontalList = () => {
  const arrayOfThree = new Array(3)
  return (
    <div>
      <WhitePanel>
        <div className="grid grid-cols-3 ">
          {_.map(arrayOfThree, (each, index) => (
            <div className="my-2 text-center p-4 bg-gray-100 mx-1 rounded-md" key={index}>
              <div className="my-2">
                <i className="fas fa-photo-video text-xl"></i>
                <h4 className="text-xl font-semibold my-2">Header</h4>
              </div>
              <p>................................</p>
            </div>
          ))}
        </div>
      </WhitePanel>
    </div>
  )
}

HorizontalList.propTypes = {}

export default HorizontalList
