/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import React from "react"
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

const RoomTypeForecastTable = ({ bookingEvents, roomtypes, startDate }) => {
  const { i18n } = useTranslation()
  const endOfMonthDay = dayjs(startDate).endOf("month").daysInMonth()
  const dateArray = new Array(endOfMonthDay)

  const findConditionalBooking = (dayIndex, selectedRoomType) => {
    const selectedDay = dayjs(startDate).add(dayIndex, "day").toDate()
    const selectedBooking = _.filter(
      bookingEvents,
      (each) =>
        dayjs(each?.start).isBefore(dayjs(selectedDay).endOf("day")) &&
        dayjs(each.end).isAfter(dayjs(selectedDay).endOf("day")),
    )

    if (selectedRoomType === "ALL") {
      return _.size(selectedBooking)
    }

    const filterBookingOnType = _.filter(
      selectedBooking,
      (each) =>
        each?.list?.room?.type?._id === selectedRoomType ||
        each?.no_room_booking?.roomtype?._id === selectedRoomType,
    )
    return _.size(filterBookingOnType)
  }

  const allRoomAmount = _.sum(_.map(roomtypes, (each) => each?.number_of_room))

  const findVacantRoom = (dayIndex) => {
    const selectedDay = dayjs(startDate).add(dayIndex, "day").toDate()
    const selectedBooking = _.filter(
      bookingEvents,
      (each) =>
        dayjs(each?.start).isBefore(dayjs(selectedDay).endOf("day")) &&
        dayjs(each.end).isAfter(dayjs(selectedDay).endOf("day")),
    )

    const unfreeRoom = _.size(selectedBooking)

    return allRoomAmount - unfreeRoom
  }

  const findBgColor = (index) => {
    const selectedDate = dayjs(startDate).add(index, "day")
    switch (selectedDate.get("d")) {
      case 0:
        return "red.50"
      case 1:
        return "yellow.50"
      case 2:
        return "pink.50"
      case 3:
        return "green.50"
      case 4:
        return "orange.50"
      case 5:
        return "blue.50"
      case 6:
        return "purple.50"
      default:
        return "white"
    }
  }

  return (
    <div>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>วันที่/ห้องพัก</Th>
            {_.map(roomtypes, (eachRoomType, index) => (
              <Th key={index}>
                {eachRoomType?.translation?.[i18n.language]?.name || eachRoomType?.name || ""}
              </Th>
            ))}
            <Th>รวมจอง</Th>
            <Th>ว่าง</Th>
            <Th>รวมทั้งหมด</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.map(dateArray, (eachDate, index) => (
            <Tr key={index} bgColor={findBgColor(index)}>
              <Td
                className={`${
                  dayjs(startDate).add(index, "day").isSame(dayjs(), "day") ? "font-bold " : ""
                }`}
              >
                {dayjs(startDate).add(index, "day").format("dd D MMM")}{" "}
                {dayjs(startDate).add(index, "day").isSame(dayjs(), "day") ? "🌟 " : ""}
              </Td>
              {_.map(roomtypes, (eachRoomType, roomTypeIndex) => (
                <Td key={roomTypeIndex}>{findConditionalBooking(index, eachRoomType?._id)}</Td>
              ))}

              <Td>{findConditionalBooking(index, "ALL")}</Td>
              <Td>{findVacantRoom(index)}</Td>
              <Td>{allRoomAmount}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  )
}

export default RoomTypeForecastTable
