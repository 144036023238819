import React from "react"
import { Form, Input } from "antd"
import { Controller } from "react-hook-form"
import Flags from "country-flag-icons/react/3x2"
import WhitePanel from "../../common/Cards/WhitePanel"

function MainDescriptionForm({ control, defaultInfo }) {
  const { TextArea } = Input
  return (
    <WhitePanel>
      {" "}
      <h6 className="text-gray-900 text-base my-2  font-bold  font-sans ">รายละเอียดโรงแรม</h6>
      <Form layout="vertical">
        {" "}
        <div className="relative w-full mb-3">
          <Form.Item label="รายละเอียด ภาษาไทย">
            <Controller
              name={`composite.translation.th.second_description`}
              control={control}
              defaultValue={defaultInfo?.composite?.translation?.th?.second_description || ""}
              render={(field) => (
                <TextArea
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.TH title="Thai" className="w-5" />}
                  placeholder="รายละเอียด"
                />
              )}
            />
          </Form.Item>
          <Form.Item label="รายละเอียด ภาษาอังกฤษ">
            <Controller
              name={`composite.translation.en.second_description`}
              control={control}
              defaultValue={defaultInfo?.composite?.translation?.en?.second_description || ""}
              render={(field) => (
                <TextArea
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.GB title="English" className="w-5" />}
                  placeholder="รายละเอียด"
                />
              )}
            />
          </Form.Item>
        </div>
      </Form>
    </WhitePanel>
  )
}

export default MainDescriptionForm
