import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button, useToast } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"

import Layout from "../../../components/layout/GeneralLayout"
import { AttachServiceForm } from "../../../components/Forms/Reservation"
import { SpinnerLoading } from "../../../components/Loading"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"

export default function AddServiceToServiceOrder() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()
  const toast = useToast()
  const serviceOrder = useSelector((state) => state.serviceOrder)
  const hotelServices = useSelector((state) => state.hotelService)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const { control, handleSubmit, register, setValue, watch } = useForm()

  useEffect(() => {
    dispatch(actions.getOneServiceOrder(params.id))
      .then(() => {
        dispatch(actions.getAllServices({ page: 1, size: 10000 })).then(() => {
          //  setIsLoading(true)
        })
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.message)
      })

    return () => {}
  }, [params])

  useEffect(() => {
    if (serviceOrder && serviceOrder?.service_list) {
      const serviceData = _.map(serviceOrder?.service_list, (eachService) => ({
        ...eachService,
        // eslint-disable-next-line no-underscore-dangle
        service: eachService?.service?._id,
      }))
      setValue("services", serviceData)
      setIsLoading(true)
    }

    return () => {}
  }, [serviceOrder])

  const handleUpdatePrice = async (data) => {
    const payload = {
      service_list: data.services,
    }
    dispatch(actions.editOneServiceOrder(params.id, payload))
      .then(() => {
        toast({
          title: "แก้ไขสำเร็จ",
          duration: 1000,
          status: "success",
        })
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err.message)
      })
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.orderPayment")} preview>
      <Alert
        isError={isError}
        errMessage={errorMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrorMessage("")
        }}
      />
      <div className="flex justify-start px-4">
        <Button colorScheme="purple" onClick={() => history.goBack()} variant="outline">
          {t("general.back")}
        </Button>
      </div>
      <div className="w-full p-4">
        <form onSubmit={handleSubmit(handleUpdatePrice)}>
          <div className="my-4">
            <h2 className="text-xl font-semibold my-2">{t("reservation.price.servicePrice")}</h2>
            <AttachServiceForm
              control={control}
              register={register}
              hotelServices={hotelServices}
              setValue={setValue}
              watch={watch}
              defaultValue={serviceOrder?.service_list}
            />
          </div>
          <div className="w-full">
            <Button isFullWidth colorScheme="purple" type="submit">
              {t("reservation.savePrice")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
