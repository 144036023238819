import React from "react"
import { Radio, RadioGroup } from "@chakra-ui/react"
import _ from "lodash"
import { SketchPicker } from "react-color"
import { Select } from "antd"
import { Controller } from "react-hook-form"

import { Font } from "../../../config/constant"

const LookAndFeelForm = ({
  register,
  defaultInfo,
  color,
  setColor,
  secondColor,
  setSecondColor,
  bgColor,
  setBgColor,
  control,
}) => (
  <div>
    <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg my-3">
      <h6 className="text-gray-900 text-base  font-bold  w-full font-sans my-2">แบบอักษร</h6>
      <div className="w-full px-4  lg:w-1/2">
        <div className="relative w-full mb-3 ">
          <label className="block  text-gray-700 text-sm font-semibold font-sans mb-2 text-left">
            แบบอักษรหัวข้อ
          </label>
          <Controller
            control={control}
            name="font.title"
            defaultValue={defaultInfo?.font?.title}
            render={(field) => (
              <Select
                {...field}
                showSearch
                placeholder="เลือกฟอนต์"
                size="large"
                optionFilterProp="children"
                onChange={(value) => field.onChange(value)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "70%" }}
              >
                {_.map(Font, (eachFont, index) => (
                  <Select.Option key={index} value={eachFont.description}>
                    <div className="flex items-center">
                      <div className="w-2/3">
                        <img src={eachFont.example} className="h-10" alt={eachFont.description} />
                      </div>
                      <div className="w-1/3">{eachFont?.description}</div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </div>
      </div>
      <div className="w-full px-4  lg:w-1/2">
        <div className="relative w-full mb-3 ">
          <label
            className="block  text-gray-700 text-sm font-semibold font-sans mb-2"
            htmlFor="grid-password"
          >
            แบบอักษรทั่วไป
          </label>
          <Controller
            control={control}
            name="font.main"
            defaultValue={defaultInfo?.font?.main}
            render={(field) => (
              <Select
                {...field}
                showSearch
                placeholder="เลือกฟอนต์"
                size="large"
                optionFilterProp="children"
                onChange={(value) => field.onChange(value)}
                filterOption={(input, option) =>
                  (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                }
                style={{ width: "70%" }}
              >
                {_.map(Font, (eachFont, index) => (
                  <Select.Option key={index} value={eachFont.description}>
                    <div className="flex items-center">
                      <div className="w-2/3">
                        <img src={eachFont.example} className="h-10" alt={eachFont.description} />
                      </div>
                      <div className="w-1/3">{eachFont?.description}</div>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            )}
          />
        </div>
      </div>
    </div>
    <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg my-3">
      <h6 className="text-gray-900 text-base  font-bold  w-full font-sans my-2">สี </h6>
      <div className="flex justify-center flex-wrap w-full ">
        <div className="w-full lg:w-1/2">
          <div className="my-2">สีหลัก</div>
          <div className="flex justify-center w-full">
            <SketchPicker
              color={color}
              onChangeComplete={(selectedColor) => {
                setColor(selectedColor.hex)
              }}
              width={256}
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="my-2">สีรอง</div>
          <div className="flex justify-center w-full">
            <SketchPicker
              color={secondColor}
              onChangeComplete={(selectedColor) => {
                setSecondColor(selectedColor.hex)
              }}
              width={256}
            />
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="my-2">สีพื้นหลัง</div>
          <div className="flex justify-center w-full">
            <SketchPicker
              color={bgColor}
              onChangeComplete={(selectedColor) => {
                setBgColor(selectedColor.hex)
              }}
              width={256}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default React.forwardRef(LookAndFeelForm)
