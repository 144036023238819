import React, { useState, useEffect } from "react"
import { FormControl, Button, Switch } from "@chakra-ui/react"
import { Controller, useForm, useFieldArray } from "react-hook-form"
import _ from "lodash"
import { Input, TreeSelect, Modal } from "antd"

export default function ModalAddOrEditRoom({
  isOpen,
  handleOnClose,
  allRoomTypes,
  handleAddRoomType,
  isEditMode = false,
  defaultValue,
}) {
  const { register, handleSubmit, control, setValue } = useForm()
  const { fields, append, remove } = useFieldArray({ control, name: "other_sub_type" })

  useEffect(() => {
    setValue("name", defaultValue?.name)
    setValue("sub_type", defaultValue?.sub_type?._id)
    setValue("other_sub_type", defaultValue?.other_sub_type || [])

    return () => {}
  }, [defaultValue])

  return (
    <Modal
      open={isOpen}
      onCancel={handleOnClose}
      className="font-sans"
      size="lg"
      footer={[
        <Button
          size="sm"
          key="create"
          colorScheme="green"
          type="button"
          onClick={handleSubmit(handleAddRoomType)}
        >
          {isEditMode ? "แก้ไข" : "เพิ่ม"}
        </Button>,
        <Button size="sm" colorScheme="gray" type="button" onClick={handleOnClose} key="cancle">
          ยกเลิก
        </Button>,
      ]}
    >
      <div className="text-lg font-semibold">{isEditMode ? "แก้ไขห้องพัก" : "สร้างห้องพัก"} </div>
      <form>
        <div>
          <label className="text-sm font-bold">ชื่อห้องพัก</label>
          <div className="my-2">
            <Controller
              control={control}
              name="name"
              defaultValue={defaultValue?.name}
              render={(field) => <Input {...field} placeholder="ชื่อห้องพัก" />}
            />
          </div>
        </div>
        <div>
          <label className="text-sm font-bold">หมวดหมู่ห้องพัก</label>
          <div className="my-2 z-50">
            <Controller
              control={control}
              name="sub_type"
              defaultValue={defaultValue?.sub_type?._id}
              render={(field) => (
                <TreeSelect
                  {...field}
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="เลือกหมวดหมู่ และหมวดหมู่ย่อยของห้องพัก"
                  allowClear
                  popupClassName="z-100"
                  treeDefaultExpandAll
                  treeData={_.map(
                    _.filter(allRoomTypes, (roomtype) => !roomtype?.is_special_type),
                    (each) => ({
                      value: each?._id,
                      title: `${each?.translation?.th?.name || each?.name} ${
                        each?.translation?.en ? `(${each?.translation?.en?.name})` : ""
                      }`,
                      selectable: false,
                      children: _.map(each?.room_sub_types, (eachSubType) => ({
                        value: eachSubType?._id,
                        title: eachSubType?.name,
                      })),
                    }),
                  )}
                />
              )}
            />
            <div className="my-2">
              <label className="text-sm font-bold my-2">หมวดหมู่ห้องพักอื่นๆ</label>
              <div className="text-sm">ที่เป็นหมวดหมู่ห้องพักแบบพิเศษ ไว้ใช้ทำโปรโมชั่น</div>
            </div>
            {_.map(fields, (eachField, index) => (
              <div className="flex items-center" key={eachField.id}>
                <div className="w-3/4 px-1">
                  <div className="my-2 z-50">
                    <Controller
                      control={control}
                      name={`other_sub_type[${index}].sub_type`}
                      defaultValue={eachField?.sub_type?._id}
                      render={(field) => (
                        <TreeSelect
                          {...field}
                          showSearch
                          style={{ width: "100%" }}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder="เลือกหมวดหมู่ และหมวดหมู่ย่อยของห้องพัก"
                          allowClear
                          popupClassName="z-100"
                          treeDefaultExpandAll
                          treeData={_.map(
                            _.filter(
                              allRoomTypes,
                              (roomtype) => roomtype?.is_special_type === true,
                            ),
                            (each) => ({
                              value: each?._id,
                              title: `${each?.translation?.th?.name || each?.name} ${
                                each?.translation?.en ? `(${each?.translation?.en?.name})` : ""
                              }`,
                              selectable: false,
                              children: _.map(each?.room_sub_types, (eachSubType) => ({
                                value: eachSubType?._id,
                                title: eachSubType?.name,
                              })),
                            }),
                          )}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="w-1/4 px-1">
                  <Button size="sm" colorScheme="red" onClick={() => remove(index)}>
                    ลบ
                  </Button>
                </div>
              </div>
            ))}
            <div>
              <Button size="sm" onClick={() => append({})}>
                เพิ่ม
              </Button>
            </div>
          </div>
        </div>

        <FormControl display="flex" className="my-2">
          <div className="text-sm mr-2">ล็อกให้ไว้สำหรับผู้ให้บริการการจองออนไลน์</div>
          <Switch
            name="ota_reserved"
            ref={register}
            defaultChecked={defaultValue?.ota_reserved}
            defaultValue={defaultValue?.ota_reserved}
          />
        </FormControl>
        <FormControl display="flex" className="my-2">
          <div className="text-sm mr-2">เปิดให้จองได้ผ่านเว็บไซต์</div>
          <Switch
            name="online_booking_open"
            ref={register}
            defaultChecked={defaultValue?.online_booking_open}
            defaultValue={defaultValue?.online_booking_open}
          />
        </FormControl>
      </form>
    </Modal>
  )
}
