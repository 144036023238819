import React, { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import { FormLabel, FormControl, Input, Checkbox, Radio, RadioGroup, Select } from "@chakra-ui/react"
import _ from "lodash"
import { BlockPicker, SketchPicker } from "react-color"
import { FrontPageMenuLink } from "../../../../config/constant"
import { AnimationType } from "../../../../config/constant"
import { TextFrameType } from "../../../../config/constant"
import { RichTextEditor } from "../../../common/RichTextEditor"
import { HeadingType, TextColor } from "../../../../config/constant"
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { motion } from "framer-motion";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SliderButtonComponent from "../../../common/Slider/SliderButtonComponent"
import SliderButtonComponentArea from "../../../common/Slider/SliderButtonComponentArea"
import SliderButtonComponentMobile from "../../../common/Slider/SliderButtonComponentMobile"
import SliderTextcontentType from "../../../common/Slider/SliderTextcontentType"
import SliderTextcontentComponentArea from "../../../common/Slider/SliderTextcontentComponentArea"
import SliderTextcontentMobile from "../../../common/Slider/SliderTextcontentMobile"
import SliderTextcontentTypeMobile from "../../../common/Slider/SliderTextcontentTypeMobile"

function TextContent({ register, defaultValue, control, watch, activeTab }) {
  // console.log('AnimationType: ', AnimationType)

  const [activeInput, setActiveInput] = useState('TH'); // สมมติว่า activeInput เป็น state ที่เก็บค่า 'TH' หรือ 'EN'
  const [th, setTh] = useState(defaultValue?.translation?.th?.header || ""); // สมมติว่า th เป็น state ที่เก็บข้อความภาษาไทย
  const [en, setEn] = useState(defaultValue?.translation?.en?.header || ''); // สมมติว่า en เป็น state ที่เก็บข้อความภาษาอังกฤษ
  useEffect(() => {
    if (activeInput === 'TH') {
      setTh(defaultValue?.translation?.th?.header || '');
    } else if (activeInput === 'EN') {
      setEn(defaultValue?.translation?.en?.header || '');
    }
  }, [defaultValue]);


  const [showContent, setShowContent] = useState(false);
  const [showHeading, setShowHeading] = useState(true);
  const [showAnimation, setShowAnimation] = useState(true);

  return (
    <div className="flex flex-wrap">
      <div className="w-full px-2 py-1">
        <div className="2xl:pr-20 2xl:pl-80 pt-4 flex justify-center gap-6 lg:ml-80 lg:pl-36">
          <div className="w-16 h-11 lg:pt-2 border-black bg-gray-300 flex justify-center justify-items-center text-xl font-semibold shadow border-2">
            หัวข้อ
          </div>
          <div className="w-full lg:w-full  px-0 lg:py-4">
            <FormControl display="flex">
              <Checkbox
                ref={register}
                defaultChecked={defaultValue?.show_name || false}
                name="show_name1"
              >
                เปิดใช้
              </Checkbox>
            </FormControl>
          </div>
        </div>
        <div className="flex justify-center pt-4">
          <button
            className="px-3 py-1 mb-2 rounded-md bg-blue-500 text-white text-sm focus:outline-none hover:bg-blue-600"
            type="button"
            onClick={() => setShowContent(!showContent)}
          >
            <ArrowDownwardIcon fontSize="small" />
            <span className="ml-1">แสดงรายละเอียด</span>
          </button>
        </div>

        {showContent && (
          <div className="">
            <FormLabel fontSize="sm" className="font-medium"></FormLabel>
            <div className="justify-center flex pb-4">
              <div>
                <div className="flex justify-center shadow-4xl">
                  <button
                    className={`px-4 py-2 text-black ${activeInput === 'TH' ? 'bg-green-500 text-white' : 'bg-gray-300'
                      } hover:bg-blue-600 transition-colors shadow-lg`}
                    type="button"
                    onClick={() => setActiveInput('TH')}
                  >
                    ข้อความ TH
                  </button>
                  <button
                    className={`px-4 py-2 text-black ${activeInput === 'EN' ? 'bg-red-500 text-white' : 'bg-gray-300'
                      } hover:bg-red-600 transition-colors shadow-lg`}
                    type="button"
                    onClick={() => setActiveInput('EN')}
                  >
                    ข้อความ EN
                  </button>
                </div>
                <div className="mt-4">
                  <textarea
                    className={`${activeInput === 'EN' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                    placeholder="หัวข้อภาษาไทย"
                    name="translation.th.header"
                    value={th}
                    onChange={(e) => setTh(e.target.value)}
                    ref={register}
                    rows={1}
                  />
                  <textarea
                    className={`${activeInput === 'TH' ? "hidden" : "block"} px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 resize-none`}
                    placeholder="หัวข้อภาษาอังกฤษ"
                    name="translation.en.header"
                    value={en}
                    onChange={(e) => setEn(e.target.value)}
                    ref={register}
                    rows={1}
                  />
                </div>
              </div>
            </div>
            <div className="pt-6 pb-2">
              <motion.span
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`cursor-pointer px-3 py-1 mb-2 rounded-md bg-blue-500 text-white text-sm focus:outline-none hover:bg-blue-600`}
                onClick={() => setShowHeading(!showHeading)}
              >
                {showHeading ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
                <span className="ml-1">รูปแบบของหัวข้อ</span>
              </motion.span>
              {showHeading && (
                <RadioGroup
                  className="flex gap-2 flex-wrap my-2"
                  name="type_attribute.heading_type"
                  defaultValue={defaultValue?.type_attribute?.HeadingType}
                  ref={register}
                >
                  {_.map(HeadingType, (eachType, index) => (
                    <Radio value={eachType.status_code} key={index} ref={register}>
                      <div className="border p-2">
                        <img src={eachType?.image} className="w-24" />
                        <div className="text-base">{eachType?.description}</div>
                      </div>
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            </div>
            <div className="pt-2">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`px-3 py-1 mb-2 rounded-md bg-blue-500 text-white text-sm focus:outline-none hover:bg-blue-600`}
                type="button"
                onClick={() => setShowAnimation(!showAnimation)}
              >
                {showAnimation ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
                <span className="ml-1">รูปแบบอนิเมชั่นของหัวข้อ</span>
              </motion.button>
              {showAnimation && (
                <RadioGroup
                  className="flex gap-2 flex-wrap my-2 pb-10"
                  name="type_attribute.animation_type"
                  defaultValue={defaultValue?.type_attribute?.animation_type}
                  ref={register}
                >
                  {_.map(AnimationType, (eachType, index) => (
                    <Radio value={eachType.status_code} key={index} ref={register}>
                      <div className="border p-2 flex flex-wrap w-40 ">
                        <div className="pl-7">
                          <img src={eachType?.image} className="w-20 h-8 " />
                        </div>
                        <div className="text-base pl-3 pt-2 ">{eachType?.description}</div>
                      </div>
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            </div>
            <div>
              <div>
                <div className="w-full lg:w-1/3 px-2 py-2 pb-6">
                  <FormLabel fontSize="sm">สีของหัวข้อ</FormLabel>
                  <Select
                    size="sm"
                    ref={register}
                    name="type_attribute.color"
                    defaultValue={defaultValue?.type_attribute?.color}
                  >
                    {_.map(TextColor, (eachColor, index) => (
                      <option key={index} value={eachColor.status_code}>
                        {eachColor?.description}
                      </option>
                    ))}
                  </Select>
                  {watch("type_attribute.color") === TextColor.OTHER.status_code && (
                    <div className="my-2">
                      <FormLabel fontSize="sm">เลือกสี</FormLabel>
                      <Controller
                        control={control}
                        name="type_attribute.otherColor"
                        defaultValue={defaultValue?.type_attribute?.otherColor}
                        render={(field) => (
                          <BlockPicker color={field.value} onChange={(event) => field.onChange(event.hex)} />
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="w-full lg:border-2 border-indigo-600 lg:p-14 p-2  mb-5  ">
          <div className="flex justify-center  lg:gap-10 gap-0">
            <div className="lg:w-full px-2  lg:py-1">
              <div className="lg:pl-0 pl-0  w-full ">
                <FormLabel fontSize="sm">ข้อความรายละเอียด</FormLabel>
                <label
                  className="lg:block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  ภาษาไทย
                </label>
                <Controller
                  control={control}
                  name="translation.th.content"
                  defaultValue={defaultValue?.translation?.th?.content}
                  render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                />
                <label
                  className="pt-2  lg:block flex text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  ภาษาอังกฤษ
                </label>
                <Controller
                  control={control}
                  name="translation.en.content"
                  defaultValue={defaultValue?.translation?.en?.content}
                  render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
                />
              </div>
            </div>
            {activeTab === 'Desktop' && (
              <div>
                <div className="w-full lg:w-full px-1 lg:pt-0 lg:mt-0 pt-80 mt-40 ">
                  <h6 className="lg:ml-24  ml-0 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                    สีตัวอักษร{" "}
                  </h6>
                  <div className="w-48  lg:w-full flex justify-center lg:ml-16">
                    <Controller
                      control={control}
                      name="desktop.text_component.text_color"
                      defaultValue={defaultValue?.desktop?.text_component?.text_color}
                      render={(field) => (
                        <SketchPicker
                          color={field.value}
                          onChangeComplete={(selectedColor) => {
                            field.onChange(selectedColor.hex)
                          }}
                          width={256}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <div className="font-semibold text-sm my-1 lg:ml-24">ขนาดตัวอักษร</div>
                    <div className="lg:ml-24">
                      <Select
                        size="sm"
                        ref={register}
                        name={activeTab === 'Desktop' ? "desktop.text_component.text_font_Size" : "classname"}
                        defaultValue={defaultValue?.desktop?.text_component?.text_font_Size}
                        colorScheme="purple"
                      >
                        <option value="normal">normal</option>
                        <option value="sm">sm</option>
                        <option value="lg">lg</option>
                        <option value="xl">xl</option>
                        <option value="2xl">2xl</option>
                        <option value="3xl">3xl</option>
                        <option value="4xl">4xl</option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              {activeTab === 'Mobile' && (
                <div className="w-full lg:w-full px-1 lg:pt-0 lg:mt-0 pt-80 mt-40 ">
                  <h6 className="lg:ml-24  ml-0 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                    สีตัวอักษร{" "}
                  </h6>
                  <div className="w-full flex justify-center">
                    <Controller
                      control={control}
                      name="mobile.text_component.text_color"
                      defaultValue={defaultValue?.mobile?.text_component?.text_color}
                      render={(field) => (
                        <SketchPicker
                          color={field.value}
                          onChangeComplete={(selectedColor) => {
                            field.onChange(selectedColor.hex)
                          }}
                          width={256}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
              <div className={activeTab === 'Mobile' ? "my-2" : "classname"}>
                {activeTab === 'Mobile' && (
                  <div>
                    <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                    <Select
                      size="sm"
                      ref={register}
                      name={activeTab === 'Mobile' ? "mobile.text_component.textSize" : "classname"}
                      defaultValue={defaultValue?.mobile?.text_component?.textSize}
                      colorScheme="purple"
                    >
                      <option value="normal">normal</option>
                      <option value="sm">sm</option>
                      <option value="lg">lg</option>
                      <option value="xl">xl</option>
                      <option value="2xl">2xl</option>
                      <option value="3xl">3xl</option>
                      <option value="4xl">4xl</option>
                    </Select>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="pt-4 bg-white pb-4 ">
              <div className="pt-2">
                <h1
                  className="text-xl border-2 text-center p-2 pr-40 pl-40 bg-white">
                  Preview
                </h1>
              </div>
              <div className="pt-3 pb-4">
                <span className="text-red-600">*** ตัวที่สามารถปรับแยกได้แล้วได้ผลลัพธ์ต่างกันในตัว  Mobile Desktop คือ ความยาว,
                  ความกว้าง,ความโค้ง </span> <br />
                <p className="text-green-600">
                  อย่างอื่นปรับแล้วได้ผลลัพธ์เหมือนกันทั้งตัว Mobile Desktop
                </p>
              </div>
              {activeTab === 'Desktop' && (
                <div>
                  <div className="flex justify-center pt-4">
                    <h1>ตัวช่วยเลือกความยาว ความกว้างและความโค้ง</h1>
                  </div>
                  <div className="flex justify-center pt-4 pb-4">
                    <SliderTextcontentComponentArea
                      lengthFormName='desktop.text_component.text_area_length'
                      widthFormName='desktop.text_component.text_area_width'
                      curveFormName='desktop.text_component.text_area_curve'
                      register={register}
                      defaultValue={defaultValue}
                      watch={watch}
                    />
                  </div>
                  <div>
                    <SliderButtonComponent
                      watch={watch}
                    />
                  </div>
                  <div>
                    <SliderTextcontentType
                      watch={watch}
                    />
                  </div>
                </div>
              )}
              {activeTab === 'Mobile' && (
                <div>
                  <div className="flex justify-center pt-4">
                    <h1>ตัวช่วยเลือกความยาว ความกว้างและความโค้ง</h1>
                  </div>
                  <div className="flex justify-center pt-4 pb-4">
                    <SliderTextcontentMobile
                      lengthFormName='mobile.text_component.text_area_length'
                      widthFormName='mobile.text_component.text_area_width'
                      curveFormName='mobile.text_component.text_area_curve'
                      topPaddingTextcontent='mobile.text_component.top_padding_content'
                      bottomPaddingTextcontent='mobile.text_component.bottom_padding_content'
                      register={register}
                      defaultValue={defaultValue}
                      watch={watch}
                    />
                  </div>
                  <div className="pr-96 pl-96 ml-20 mr-20 mt-0 mb-0 ">
                    <SliderTextcontentTypeMobile
                      watch={watch}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center pt-14 lg:gap-10">
            <div className="w-full  px-2 py-1 pt-5 pb-3  lg:pr-24">
              <FormLabel fontSize="sm">ข้อความรายละเอียดแบบมี link</FormLabel>
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                ภาษาไทย
              </label>
              <Controller
                control={control}
                name="translation.th.text_content"
                defaultValue={defaultValue?.translation?.th?.text_content}
                render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
              />
              <label
                className="pt-2 block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                ภาษาอังกฤษ
              </label>
              <Controller
                control={control}
                name="translation.en.text_content"
                defaultValue={defaultValue?.translation?.en?.text_content}
                render={(field) => <RichTextEditor setValue={field.onChange} value={field.value} />}
              />
            </div>
            <div>
              {activeTab === 'Desktop' && (
                <div>
                  <div className="my-2">
                    <div className="w-full lg:w-full px-1">
                      <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                        สีตัวอักษร {" "}
                      </h6>
                      <div className="w-full flex justify-center">
                        <Controller
                          control={control}
                          name="desktop.text_component.text_content_color"
                          defaultValue={defaultValue?.desktop?.text_component?.text_content_color}
                          render={(field) => (
                            <SketchPicker
                              color={field.value}
                              onChangeComplete={(selectedColor) => {
                                field.onChange(selectedColor.hex)
                              }}
                              width={256}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="my-2">
                      <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                      <Select
                        size="sm"
                        ref={register}
                        name="desktop.text_component.text_content_Size"
                        defaultValue={defaultValue?.desktop?.text_component?.text_content_Size}
                        colorScheme="purple"
                      >
                        <option value="normal">normal</option>
                        <option value="sm">sm</option>
                        <option value="lg">lg</option>
                        <option value="xl">xl</option>
                        <option value="2xl">2xl</option>
                        <option value="3xl">3xl</option>
                        <option value="4xl">4xl</option>
                      </Select>
                    </div>
                  </div>
                  <div className="px-0 py-1">
                    {!watch(`button.useOtherLink`) === true && (
                      <>
                        <div className="text-sm font-semibold pt-2">ลิงค์ในข้อความ</div>
                        <Select
                          size="sm"
                          placeholder="ลิงค์เมนู"
                          name={`button.link`}
                          ref={register}
                        >
                          {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                            <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                              {eachlink?.description}
                            </option>
                          ))}
                        </Select>
                      </>
                    )}
                    <div className="my-1 pb-1 pt-4">
                      <Checkbox
                        ref={register}
                        size="sm"
                        name={`button.useOtherLink`}
                      >
                        ใช้ลิงค์กำหนดเอง
                      </Checkbox>
                    </div>
                    {watch(`button.useOtherLink`) === true && (
                      <div className="my-2">
                        <div className="text-gray-600 text-sm">
                          กรณีที่เป็นลิงค์ในเว็บไซต์เดียวกัน เริ่มต้นที่ / แล้วต่อด้วยลิงค์ได้เลย
                          ถ้าเป็นลิงค์ภายนอก ให้เริ่มต้นด้วย http:// หรือ https://
                        </div>
                        <div className="text-sm font-semibold">ลิงค์กำหนดเอง</div>
                        <Input
                          size="sm"
                          placeholder="ชื่อเมนู"
                          name={`button.otherLink`}
                          ref={register}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {activeTab === 'Mobile' && (
                <div className="my-2">
                  <div className="w-full lg:w-full px-1">
                    <h6 className="text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                      สีตัวอักษรใน Mobile{" "}
                    </h6>
                    <div className="w-full flex justify-center">
                      <Controller
                        control={control}
                        name="mobile.text_component.text_content_color"
                        defaultValue={defaultValue?.mobile?.text_component?.text_content_color}
                        render={(field) => (
                          <SketchPicker
                            color={field.value}
                            onChangeComplete={(selectedColor) => {
                              field.onChange(selectedColor.hex)
                            }}
                            width={256}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="my-2">
                    <div className="font-semibold text-sm my-1">ขนาดตัวอักษร</div>
                    <Select
                      size="sm"
                      ref={register}
                      name="mobile.text_component.text_content_Size"
                      defaultValue={defaultValue?.mobile?.text_component?.text_content_Size}
                      colorScheme="purple"
                    >
                      <option value="normal">normal</option>
                      <option value="sm">sm</option>
                      <option value="lg">lg</option>
                      <option value="xl">xl</option>
                      <option value="2xl">2xl</option>
                      <option value="3xl">3xl</option>
                      <option value="4xl">4xl</option>
                    </Select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}

export default TextContent
