import archivo from "./archivo.png"
import athiti from "./athiti.png"
import baijamjuree from "./baijamjuree.png"
import bebas from "./bebas.png"
import breeSerif from "./bree-serif.png"
import chonburi from "./chonburi.png"
import ibmPlex from "./ibm-plex-sans-thai.png"
import ibmPlexLoop from "./ibm-plex-sans-thai-looped.png"
import kanit from "./kanit.png"
import lexend from "./lexend.png"
import maitree from "./maitree.png"
import mitr from "./mitr.png"
import monoton from "./monoton.png"
import montserrat from "./montserrat.png"
import pridi from "./pridi.png"
import prompt from "./prompt.png"
import sarabun from "./sarabun.png"
import cardo from "./cardo.png"
import lora from "./lora.png"
import playfair from "./playfair.png"
import tiltprism from "./tiltprism.png"
import sriracha from "./sriracha.png"

export {
  archivo,
  athiti,
  baijamjuree,
  bebas,
  breeSerif,
  chonburi,
  ibmPlex,
  ibmPlexLoop,
  kanit,
  lexend,
  maitree,
  mitr,
  monoton,
  montserrat,
  pridi,
  prompt,
  sarabun,
  cardo,
  lora,
  playfair,
  tiltprism,
  sriracha,
}

export default {
  archivo,
  athiti,
  baijamjuree,
  bebas,
  breeSerif,
  chonburi,
  ibmPlex,
  ibmPlexLoop,
  kanit,
  lexend,
  maitree,
  mitr,
  monoton,
  montserrat,
  pridi,
  prompt,
  sarabun,
  cardo,
  lora,
  playfair,
  tiltprism,
  sriracha,
}
