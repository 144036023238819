/* eslint-disable no-underscore-dangle */
import React, { useState, useContext, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Button } from "@chakra-ui/react"

import { GeneralMainLayout as Layout, Alert, WhitePanel, FBOrderList } from "../../components"
import * as actions from "../../redux/actions"
import { MobileOrderContext } from "../../contexts/MobileOrderContext"

function TemporaryOrder() {
  const history = useHistory()
  const fbOrder = useSelector((state) => state.fbOrder)
  const fbList = useSelector((state) => state.fbList)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const { t, i18n } = useTranslation()
  const [page, setPage] = useState(1)
  const size = 25
  const params = useParams()

  const dispatch = useDispatch()
  const { tempOrderList, setTempOrderList, color, toggleSelectedList, orderInfo } =
    useContext(MobileOrderContext)

  const handleSendOrder = () => {
    const modifiedOrder = _.map(tempOrderList, (eachOrder) => ({
      ...eachOrder,
      order: fbOrder?._id,
    }))

    dispatch(actions.createOneFBOrderList({ arr: modifiedOrder, order: fbOrder?._id }))
      .then(() => {
        setTempOrderList([])
        history.goBack()
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
  }

  useEffect(() => {
    dispatch(actions.getAllFBOrderList({ page: 1, size, query: { order: params?.id } }))
      .then(() => {})
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })

    return () => {}
  }, [params])

  return (
    <Layout categoryTitle="Mobile Order" title="รายการอาหารรอสั่ง">
      <Alert isError={isError} errMessage={errorMessage} handleOnClose={() => setIsError(false)} />
      <div className="px-4 min-h-screen">
        <div className="flex">
          <Button
            colorScheme={color}
            variant="outline"
            onClick={() => {
              history.goBack()
            }}
          >
            กลับ
          </Button>
        </div>
        <div className="my-1">
          <WhitePanel>
            <div className="text-xl font-bold text-center">รายการรอสั่ง</div>

            {_.isEmpty(tempOrderList) && <div>ไม่มีรายการอาหารที่เลือก</div>}
            <div className="flex flex-col divide-y-2 py-1">
              {_.map(tempOrderList, (eachList, index) => (
                <div key={index} className="flex py-2">
                  <div className="w-2/3 px-1">
                    <div>
                      {eachList?.menu?.translation?.[i18n.language]?.name} x {eachList?.quantity}
                    </div>{" "}
                    {eachList?.option && (
                      <div className="text-sm">ตัวเลือก: {eachList?.option}</div>
                    )}
                    {!_.isEmpty(eachList?.toppings) && (
                      <div className="text-sm">
                        เพิ่ม: {_.map(eachList?.toppings, (each) => each)}
                      </div>
                    )}
                    <div>{eachList?.price} บาท</div>
                  </div>
                  <div className="w-1/3 px-1">
                    <div>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => {
                          toggleSelectedList(eachList)
                          history.goBack()
                        }}
                      >
                        {t("general.delete")}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <hr className="my-2" />
            <div className="text-xl font-bold text-center my-2">รายการที่สั่งไปแล้ว</div>
            <FBOrderList fbList={fbList} page={page} setPage={setPage} />
          </WhitePanel>
        </div>
      </div>
      <div className="w-full fixed flex gap-2 bottom-3">
        <div className="w-3/5">
          <Button
            colorScheme={color}
            onClick={() => handleSendOrder()}
            disabled={_.size(tempOrderList) === 0}
            isFullWidth
          >
            สั่ง {_.size(tempOrderList)} รายการที่เลือกไว้
          </Button>
        </div>
        <Button
          leftIcon={<i className="fas fa-chevron-left"></i>}
          onClick={() => {
            history.goBack()
          }}
          colorScheme="teal"
        >
          กลับ
        </Button>
      </div>
    </Layout>
  )
}

export default TemporaryOrder
