import {
  FB_ORDER_ALL,
  FB_ORDER_CREATE,
  FB_ORDER_DELETE,
  FB_ORDER_GET,
  FB_ORDER_UPDATE,
  FB_ORDER_ERROR,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function FBOrderReducer(state = initialState, action) {
  switch (action.type) {
    case FB_ORDER_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case FB_ORDER_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case FB_ORDER_CREATE:
      return { isLoading: false }
    case FB_ORDER_UPDATE:
      return { isLoading: false }
    case FB_ORDER_DELETE:
      return { isLoading: false }
    case FB_ORDER_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
