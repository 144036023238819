export const VideoTextAnimationType = {
    NON_ANIMATION: {
        status_code: "NON_ANIMATION",
        description: "ไม่มีแอนนิเมชั่น",
        descriptionEn: "None Animation",
      },
    TEXT_TOP_MOVE: {
      status_code: "TEXT_TOP_MOVE",
      description: "ข้อความแบบแอนนิเมชั่นเลื่อนเข้ามา",
      descriptionEn: "Animation text slides in",
    },
    ANIMATION_PULSE: {
        status_code: "ANIMATION_PULSE",
        description: "แอนนิเมชั่นไฟกระพริบ",
        descriptionEn: "animate-pulse",
    },
    BOUNCING_ANIMATION: {
        status_code: "BOUNCING_ANIMATION",
        description: "แอนิเมชั่นแบบกระเด้ง",
        descriptionEn: "Bounce animation",
    }
  }
  
  export default VideoTextAnimationType
  