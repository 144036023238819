/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link, useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import dayjs from "dayjs"
import {
  Table as AntdTable,
  Button as AntdButton,
  Spin,
  Input as AntdInput,
  DatePicker,
} from "antd"

import Layout from "../../components/layout/GeneralLayout"
import SelectedRoomList from "../../components/List/SelectedRoomList"
import {
  BookingSourceBadge,
  BookingTypeBadge,
  UnAssignRoomList,
  PaymentStatusExplainer,
  Dot,
} from "../../components"
import * as actions from "../../redux/actions"
import { selectTailwindStatusColor } from "../../util/colors"
import { currencyFormatter } from "../../util"

export default function HistoryDashboard() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const logs = useSelector((state) => state.logs)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [orderByDir, setOrderByDir] = useState("desc")
  const [orderByField, setOrderByField] = useState("start")
  const [searchWord, setSearchWord] = useState("")
  const [checkedInDate, setCheckedInDate] = useState()
  const [name, setName] = useState("")
  const { t } = useTranslation()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  const getAllLog = () => {
    dispatch(
      actions.getAlllog({
        page,
        size: 10,
        start: checkedInDate ? dayjs(checkedInDate).format("YYYY-MM-DD") : "",
        end: "",
        name,
        orderBy: orderByDir,
        orderByField,
      }),
    ).then(() => {
      setIsLoading(true)
    })
  }

  useEffect(() => {
    setIsLoading(false)
    getAllLog()
    return () => {}
  }, [page, orderByField, orderByDir, name, checkedInDate])

  const handleDelete = (id) => {
    const confirm = window.confirm("ยืนยันการลบประวัติรายการนี้")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOnelog(id)).then(() => {
        getAllLog()
      })
    }
  }
  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize

    setPage(newPage)
    setSize(pageSize)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderByDir("asc")
    } else {
      setOrderByDir("desc")
    }

    if (sorter?.field === "booking_no") {
      setOrderByField("booking_no")
    }

    if (sorter?.field === "start") {
      setOrderByField("start")
    }

    if (sorter?.field === "end") {
      setOrderByField("end")
    }

    if (sorter?.field === "status") {
      setOrderByField("status")
    }
  }
  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "Booking No.",
      dataIndex: "booking_no",
      render: (text, record) => record?.booking_no,
      sorter: true,
    },
    {
      title: "ลูกค้า",
      dataIndex: "customer",
      render: (text, eachBooking, index) => (
        <div>
          <div className="whitespace-no-wrap font-semibold ">
            {eachBooking?.customer?.firstname} {eachBooking?.customer?.lastname}
          </div>
          <div className="text-xs mt-2">
            {t("crm.tel")} {eachBooking?.customer?.tel}{" "}
          </div>{" "}
          <BookingTypeBadge typeOfBooking={eachBooking.booking_type} key={index} />
          <BookingSourceBadge sourceOfBooking={eachBooking?.booking_source} />
          <div className="flex justify-start mt-1">
            <Link to={`/history/view/${eachBooking?.id}`}>
              <AntdButton size="small">{t("general.detail")}</AntdButton>
            </Link>
          </div>{" "}
        </div>
      ),
    },
    {
      title: "ห้องพัก",
      dataIndex: "list",
      render: (text, eachBooking) => (
        <div className="w-48">
          <SelectedRoomList bookingList={eachBooking} />
          <UnAssignRoomList unAssignBookingList={eachBooking} />
        </div>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "bookings",
      sorter: true,
      render: (text, eachBooking) => (
        <div>
          <Dot word={eachBooking?.status} color={selectTailwindStatusColor(eachBooking.status)} />
          <p className="text-xs font-bold mt-3">{t("reservation.paymentStatus")}</p>
          <PaymentStatusExplainer status={eachBooking?.paymentStatus} bookingInfo={eachBooking} />
        </div>
      ),
    },
    {
      title: "วันที่เข้าพัก",
      dataIndex: "start",
      sorter: true,
      render: (text, record) => `${dayjs(record?.start).format("dd D/MMM")}`,
    },
    {
      title: "วันที่ออก",
      dataIndex: "end",
      sorter: true,

      render: (text, record) => `${dayjs(record?.end).format("dd D/MMM")}`,
    },
    {
      title: "ชำระเงิน",
      dataIndex: "payment",
      render: (text, eachBooking) => (
        <div className="flex flex-col gap-1 ">
          <div className="my-2">รวม {currencyFormatter.format(eachBooking?.price)} บาท</div>
        </div>
      ),
    },
    {
      title: "ดำเนินการ",
      dataIndex: "action",
      render: (text, eachBooking) => (
        <div>
          <Button colorScheme="red" size="sm" onClick={() => handleDelete(eachBooking?._id)}>
            ลบ
          </Button>
        </div>
      ),
    },
    {
      title: "ผู้ดำเนินการ",
      dataIndex: "user",
      render: (text, eachBooking) => (
        <div>
          {eachBooking?.user?.employee?.firstname}{" "}
          {_.truncate(eachBooking?.user?.employee?.lastname, { length: 5 })}
          <div className="text-xs">
            {dayjs(eachBooking?.updatedAt)?.format("D MMM YYYY HH:mm")}{" "}
          </div>
        </div>
      ),
    },
  ]

  return (
    <Layout categoryTitle={t("history.reservationHistory")} title={t("history.title")}>
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="w-full md:w-1/2 ">
          <div className="flex  flex-wrap">
            <div className="lg:w-1/2 px-2">
              <AntdInput
                placeholder="input search text"
                addonBefore={<i className="fas fa-search" />}
                allowClear
                onChange={(e) => setSearchWord(e.target.value)}
                style={{
                  width: "100%",
                }}
              />
            </div>
            <div className="lg:w-1/2 px-2 gap-2 flex">
              <div className="w-3/4 ">
                <DatePicker
                  picker="date"
                  value={checkedInDate ? dayjs(checkedInDate) : null}
                  format="D/MM/YYYY"
                  style={{ width: "100%" }}
                  size="middle"
                  onChange={(date) => {
                    console.log("Date", date)
                    if (date) {
                      setCheckedInDate(dayjs(date).toDate())
                    } else {
                      setCheckedInDate()
                    }
                  }}
                />
              </div>
              <div className="w-1/4 self-end">
                <Button colorScheme="yellow" variant="solid" onClick={() => setCheckedInDate()}>
                  {t("general.clear")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 overflow-x-auto w-full">
        {!isLoading && <Spin />}
        {isLoading && (
          <AntdTable
            columns={columns}
            dataSource={logs?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: logs?.total,
            }}
            scroll={{
              x: "true",
            }}
          />
        )}
      </div>
    </Layout>
  )
}
