import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Tag } from "@chakra-ui/react"
import _ from "lodash"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import SpinnerComponent from "../Loading/SpinnerLoading"
import * as actions from "../../redux/actions"

export default function RoomTypeTable({ roomTypeList }) {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const handleDeleteRoom = (id) => {
    const confirm = window.confirm(t("roomtype.confirmRemoveRoom"))
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteRoomType(id)).then(() => {
        dispatch(actions.getAllRoomTypes()).then(() => {
          setIsLoading(true)
        })
      })
    }
  }

  if (isLoading) {
    return <SpinnerComponent />
  }

  return (
    <div className="flex flex-wrap ">
      {_.map(roomTypeList, (roomType, index) => (
        <div key={index} className="p-2 w-full lg:w-1/3">
          <div className="bg-gray-200  rounded-md  flex flex-col">
            <div className="flex justify-between p-4 ">
              <div className="flex">
                <h3 className="font-bold text-lg mx-4 ml-2 ">
                  <i className="fas fa-hotel mr-2" />
                  {roomType?.translation?.[i18n.language]?.name || roomType?.name}{" "}
                  {roomType?.enable ? <span></span> : <i className="fas fa-eye-slash"></i>}
                </h3>
                <div>
                  {roomType?.is_special_type && (
                    <Tag>
                      <i className="fas fa-star"></i>
                    </Tag>
                  )}
                </div>
              </div>
              <div className="flex gap-1">
                <Link to={`/roomtypes/edit/${roomType?.id}`}>
                  <Button colorScheme="yellow" size="sm">
                    <i className="fas fa-pencil-alt " />
                  </Button>
                </Link>
                <Button
                  variant="outline"
                  size="sm"
                  colorScheme="red"
                  onClick={() => {
                    handleDeleteRoom(roomType?.id)
                  }}
                >
                  <i className="fas fa-trash " />
                </Button>
              </div>
            </div>
            <p className="text-sm ml-6  flex gap-2 self-start">
              <div>
                <i className="fas fa-door-open mr-2" />
                {_.size(roomType?.room_sub_types)} {t("roomtype.roomSubtype")}
              </div>
              <div>
                <i className="fas fa-door-open mr-2" />
                {roomType?.number_of_room} {t("dashboard.room")}
              </div>
            </p>
            <div>
              <p className="text-sm ml-6  ">
                จองออนไลน์ได้ {roomType?.online_booking_open || "-"} {t("dashboard.room")}
              </p>
              <small className="ml-6"></small>
            </div>
            <div className="mx-6 flex justify-between">
              <h3 className="font-semibold text-purple-700 text-xl ">
                {_.min(_.map(roomType?.room_sub_types, (subtype) => subtype.price))} -{" "}
                {_.max(_.map(roomType?.room_sub_types, (subtype) => subtype.price))}{" "}
                {t("general.baht")}
              </h3>
              <Link to={`/roomtypes/view/${roomType?.id}`}>
                <Button colorScheme="gray" size="sm">
                  {/* <i className="fas fa-pencil-alt " /> */}
                  {t("roomtype.room")}
                </Button>
              </Link>{" "}
            </div>

            <br />
          </div>
        </div>
      ))}
    </div>
  )
}
