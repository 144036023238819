/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Button } from "@chakra-ui/react"

import {
  GeneralMainLayout as Layout,
  Alert,
  WhitePanel,
  SpinnerLoading,
  FBOrderList,
} from "../../components"
import * as actions from "../../redux/actions"
import { MobileOrderContext } from "../../contexts/MobileOrderContext"

function DetailOrder() {
  const history = useHistory()
  const params = useParams()
  const fbOrder = useSelector((state) => state.fbOrder)
  const fbList = useSelector((state) => state.fbList)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const size = 25

  const dispatch = useDispatch()
  const { color } = useContext(MobileOrderContext)

  useEffect(() => {
    dispatch(actions.getAllFBOrderList({ page: 1, size, query: { order: params?.id } }))
      .then(() => {
        setIsLoading(true)
        dispatch(actions.getOneFBOrder(params?.id))
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="Mobile Order" title="รายการอาหารที่สั่งแล้ว">
      <Alert isError={isError} errMessage={errorMessage} handleOnClose={() => setIsError(false)} />
      <div className="px-4 min-h-screen">
        <div className="flex">
          <Button
            colorScheme={color}
            variant="outline"
            onClick={() => {
              history.goBack()
            }}
          >
            กลับ
          </Button>
        </div>
        <div className="my-2">
          <WhitePanel>
            <div className="text-center">
              <div className="text-xl font-bold">ออเดอร์ {fbOrder?.bill_number}</div>
              <div>โต๊ะ {fbOrder?.table?.name}</div>
            </div>
            <hr className="my-2" />
            {_.isEmpty(fbList?.arr) && <div>ไม่มีรายการอาหารที่เลือก</div>}
            <FBOrderList fbList={fbList} page={page} setPage={setPage} />
          </WhitePanel>
        </div>
      </div>
    </Layout>
  )
}

export default DetailOrder
