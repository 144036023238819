/* eslint-disable react/display-name */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button, useToast } from "@chakra-ui/react"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import _ from "lodash"
import { Table, Input, Button as AntdButton, Spin } from "antd"

import Layout from "../../components/layout/GeneralLayout"
import {
  SelectMaterialTable,
  WhitePanel,
  MaterialForm,
  PurchaseOrderInfoForm,
} from "../../components"
import * as actions from "../../redux/actions"

const EditPurchaseOrder = () => {
  const { t } = useTranslation()
  const { control, handleSubmit, watch, setValue } = useForm()
  const {
    control: createMaterialControl,
    handleSubmit: createMaterialHandleSubmit,
    reset: createMaterialReset,
  } = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const toast = useToast()
  const params = useParams()
  const material = useSelector((state) => state.material)
  const purchaseOrder = useSelector((state) => state.purchaseOrder)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [searchWord, setSearchWord] = useState("")
  const [name, setName] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const { fields, remove, append } = useFieldArray({
    control,
    name: "materials",
  })

  console.log("Watch", watch("materials"))

  const getAllData = () => {
    dispatch(
      actions.getAllMaterial({
        page,
        size,
        query: {
          name,
          orderBy: "desc",
        },
      }),
    )
      .then(() => {})
      .catch((err) => {
        alert(err)
      })
  }

  const onSubmit = (data) => {
    console.log("Data", data)
    const preparedPayload = _.map(data?.materials, (eachMaterial) => ({
      material: eachMaterial?._id,
      amount: eachMaterial?.amount,
    }))
    dispatch(
      actions.editOnePurchaseOrder(params.id, {
        ...data,
        materials: preparedPayload,
      }),
    )
      .then(() => {
        console.log("Edit Material Success")
        history.goBack()
      })
      .catch((err) => {
        console.error(err)
        alert(`สร้างวัตถุดิบไม่สำเร็จ ${err?.message}`)
      })
  }

  const onCreateNewMaterial = (data) => {
    dispatch(actions.createOneMaterial(data))
      .then(() => {
        toast({
          status: "success",
          title: "สร้างวัตถุดิบสำเร็จ",
        })
        getAllData()
        createMaterialReset({})
      })
      .catch((err) => {
        console.error(err)
        alert(`สร้างวัตถุดิบไม่สำเร็จ ${err?.message}`)
      })
  }

  const selectedMaterialColumns = [
    {
      title: "ลำดับที่",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "รหัสวัตถุดิบ",
      dataIndex: "type_code",
      key: "type_code",
      render: (text, record) => record?.type_code || "-",
    },
    {
      title: "ชื่อวัตถุดิบ",
      dataIndex: "name",
      key: "name",
      render: (text, record) => record?.name || "-",
    },
    {
      title: "ดำเนินการ",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <div className="flex gap-2">
          <Controller
            control={control}
            name={`materials[${index}]._id`}
            defaultValue={record?._id}
            render={(field) => <Input {...field} placeholder="Material ID" className="hidden" />}
          />
          {record?.amount && (
            <Controller
              control={control}
              name={`materials[${index}].amount`}
              defaultValue={record?.amount}
              render={(field) => <Input {...field} placeholder="จำนวน" />}
            />
          )}
          <AntdButton
            danger
            type="primary"
            onClick={() => {
              remove(index)
            }}
          >
            ลบ
          </AntdButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    getAllData()

    return () => {}
  }, [name, page, size])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  useEffect(() => {
    dispatch(actions.getOnePurchaseOrder(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        alert(`ดำเนินการไม่สำเร็จ ${err?.message}`)
      })

    return () => {}
  }, [])

  useEffect(() => {
    if (purchaseOrder?.materials) {
      setValue(
        "materials",
        _.map(purchaseOrder?.materials, (each) => ({ ...each?.material, amount: each?.amount })),
      )
    }

    return () => {}
  }, [purchaseOrder])

  return (
    <Layout categoryTitle={t("inventory.title")} title="แก้ไขคำสั่งซื้อวัตถุดิบ">
      <div className="flex justify-between px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="p-4">
        <div className="text-lg font-semibold">รายละเอียดคำสั่งซื้อ</div>
        <div className="my-2">
          {isLoading ? (
            <WhitePanel>
              <PurchaseOrderInfoForm control={control} defaultValue={purchaseOrder} />
            </WhitePanel>
          ) : (
            <Spin />
          )}
        </div>
        <div className="text-lg font-semibold">วัตถุดิบที่เลือก</div>
        {isLoading ? (
          <Table
            columns={selectedMaterialColumns}
            dataSource={fields}
            pagination={{
              disabled: true,
              pageSize: 100,
            }}
          />
        ) : (
          <Spin />
        )}
        <SelectMaterialTable
          append={append}
          fields={fields}
          material={material}
          page={page}
          setPage={setPage}
          setSearchWord={setSearchWord}
          setSize={setSize}
          size={size}
        />
        <div className="text-lg font-semibold">
          เพิ่มวัตถุดิบชนิดใหม่ (กรณีวัตถุดิบที่ต้องการไม่มีในรายการ)
        </div>
        <div className="my-2">
          <WhitePanel>
            <MaterialForm control={createMaterialControl} formSize="medium" />
            <AntdButton onClick={createMaterialHandleSubmit(onCreateNewMaterial)}>
              เพิ่มวัตถุดิบนี้ในรายการ
            </AntdButton>
          </WhitePanel>
        </div>
        <Button
          colorScheme="purple"
          variant="solid"
          type="button"
          onClick={handleSubmit(onSubmit)}
          isFullWidth
        >
          {t("general.save")}
        </Button>
      </div>
    </Layout>
  )
}

export default EditPurchaseOrder
