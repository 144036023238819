import React, { forwardRef, useState } from "react"
import { FormControl, FormLabel, Input, Checkbox, Select } from "@chakra-ui/react"
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { FrontPageMenuLink } from "../../../../config/constant"
import { BlockPicker, SketchPicker } from "react-color"
import SliderPictureCurve from "../../../common/Slider/SliderPictureCurve"
import SliderButtonCurve from "../../../common/Slider/SliderButtonCurve"
import SliderCardContentCurve from "../../../common/Slider/SliderCardContent"
import SliderHeaderArea from "../../../common/Slider/SliderHeaderArea"
import SliderHeaderCurve from "../../../common/Slider/SliderHeaderCurve"
import { Controller } from "react-hook-form";
import { motion } from 'framer-motion';


function CardContentGallery({ register, defaultValue, watch, setValue, activeTab, control }) {
  console.log("defaultValue", defaultValue)
  // const [activeTab, setActiveTab] = useState('Desktop');
  const [showCardContent, setShowCardContent] = useState(true);
  const handleButtonClick = () => {
    setShowCardContent(!showCardContent);
  };

  const handleOnChange = () => {
    setValue('desktop.card_content.show_card_button', !watch('desktop.card_content.show_card_button'))
  };


  console.warn('Watch', watch('translation.th.name'), watch())

  return (
    <div className="pt-4">
      {activeTab === 'Desktop' && (
        <div>
          <div className="border-2 border-black pr-60 pl-60 pt-20 pb-20">
            <div className="pb-10">
              <h1
                className="text-xl border-2 text-center p-2 
               pr-40 pl-40 bg-white">
                Preview
              </h1>
            </div>
            <div className="w-full lg:w-full px-1 lg:pt-0 lg:mt-0 pt-80 mt-40 ">
              <h6 className="lg:ml-0 flex justify-center  ml-0 text-gray-900 text-sm  font-bold  w-full font-sans my-2">
                ตัวเทียบสี {" "}
              </h6>
              <div className="w-full flex justify-center pb-8">
                <Controller
                  control={control}
                  name=""
                  defaultValue={defaultValue?.mobile?.text_component?.text_color}
                  render={(field) => (
                    <SketchPicker
                      color={field.value}
                      onChangeComplete={(selectedColor) => {
                        field.onChange(selectedColor.hex)
                      }}
                      width={256}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex">
              <div
                className="border-2 border-black pb-0 mr-20 ml-20"
                style={{
                  borderRadius: `${watch("desktop.card_content.card_content_curve")}%`
                }}
              >
                <div
                  className="border-2 border-black mr-0 ml-0 pt-36 pb-36"
                  style={{
                    borderRadius: `${watch("desktop.card_content.picture_curve")}%`
                  }}
                >
                  <p className="flex justify-center items-center">Image</p>
                </div>
                <div className="pt-4 pb-0">
                  <div
                    className=" text-xl pt-0 pb-2 border-2 border-black"
                    style={{
                      width: `${watch('desktop.card_content.header_frame_width')}px`,
                      height: `${watch('desktop.card_content.header_frame_length')}px`,
                      borderRadius: `${watch("desktop.card_content.header_curve")}%`,
                    }}
                  >
                    <p className="pt-0 flex justify-center items-center text-black"
                    >หัวข้อ
                    </p>
                    <div className="flex justify-center pb-2 items-center lg:pr-40 lg:pl-40 2xl:pr-60 2xl:pl-60">
                      <div>
                        <p className="text-lg flex justify-center">สีกรอบ</p>
                        <Input
                          size="sm"
                          placeholder="โค้ดสี"
                          name="desktop.card_content.type_attribute.header_background_color"
                          defaultValue={defaultValue?.desktop?.card_content?.type_attribute?.header_background_color || '#'}
                          ref={register}
                          className="text-black "
                          style={{
                            backgroundColor: `${watch('desktop.card_content.type_attribute.header_background_color')}`
                          }}
                        />
                      </div>
                      <div>
                        <p className="text-lg flex justify-center" >สีหัวข้อ</p>
                        <Input
                          size="sm"
                          placeholder="โค้ดสี"
                          name="desktop.card_content.type_attribute.header_color"
                          defaultValue={defaultValue?.desktop?.card_content?.type_attribute?.header_color || '#'}
                          ref={register}
                          className="text-black "
                          style={{
                            backgroundColor: `${watch('desktop.card_content.type_attribute.header_color')}`
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-4 pb-2"
                  >
                    <p className="flex justify-center items-center pr-4 pl-4 ">
                      At vero eos et accusamus et iusto odios dignissimos ducimus
                      blanditiis easde ant praesentium voluptatum deleniti atque corrupti
                      et quas molestias excepturi sint Sed ut perspiciatis unde omnis iste natus
                    </p>
                    <div className="flex justify-center items-center lg:pr-40 lg:pl-40 2xl:pr-60 2xl:pl-60">
                      <Input
                        size="sm"
                        placeholder="โค้ดสี"
                        name="desktop.card_content.type_attribute.textcontent_background_color"
                        defaultValue={defaultValue?.desktop?.card_content?.type_attribute?.textcontent_background_color || '#'}
                        ref={register}
                        className="text-black "
                        style={{
                          backgroundColor: `${watch('desktop.card_content.type_attribute.textcontent_background_color')}`
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-6  items-center pt-80">
                  <div className="flex justify-center ">
                    <SliderHeaderArea
                      headerArea='desktop.card_content.header_frame_length'
                      register={register}
                      defaultValue={defaultValue}
                      watch={watch}
                    />
                  </div>
                  <div className="flex justify-center pt-3">
                    <SliderHeaderCurve
                      headerCurve='desktop.card_content.header_curve'
                      register={register}
                      defaultValue={defaultValue}
                      watch={watch}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div
                className="border-2 border-black mr-40 ml-40 pt-6 pb-6"
                style={{
                  borderRadius: `${watch("desktop.card_content.button_curve")}%`,
                  backgroundColor: `${watch('desktop.card_content.type_attribute.button_background_color')}`
                }}
              >
                <div className="flex justify-center">
                  <IconButton
                    type="button"
                    onClick={handleButtonClick}>
                    <div
                      className="text-lg"
                    >
                      {watch('translation.th.buttonTitle1')}
                      <KeyboardArrowDown />
                    </div>
                  </IconButton>
                </div>
                <div className="2xl:pl-60 xl:pr-60 lg:pl-40 lg:pr-40">
                  <Input
                    size="sm"
                    placeholder="โค้ดสี"
                    name="desktop.card_content.type_attribute.button_background_color"
                    defaultValue={defaultValue?.desktop?.card_content?.type_attribute?.button_background_color || '#'}
                    ref={register}
                    className="text-black hover:text-white"
                  />
                  <div className="flex pt-2 pb-4">
                    <motion.div
                      className={`text-sm ${watch('mobile?.video?.how_video_info') ? 'text-green-600' : 'text-black'}`}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Checkbox
                        ref={register}
                        defaultChecked={watch('desktop?.card_content?.show_card_button')}
                        name="desktop.card_content.show_card_button"
                        onChange={handleOnChange}
                      >
                        <a className="text-sm">
                          เปิดใช้งานปุ่มกด
                        </a>
                      </Checkbox>
                    </motion.div>
                  </div>
                </div>
                {showCardContent && (
                  <div className="w-full  px-2 py-1 ">
                    <FormLabel fontSize="sm" className="pt-3"></FormLabel>
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      ภาษาไทย
                    </label>
                    <Input
                      ref={register}
                      placeholder="ชื่อปุ่ม ไทย"
                      name="translation.th.buttonTitle1"
                      size="sm"
                    />
                    <label
                      className="pt-2 block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password"
                    >
                      ภาษาอังกฤษ
                    </label>
                    <Input
                      ref={register}
                      placeholder="ชื่อปุ่ม Eng"
                      name="translation.en.buttonTitle1"
                      size="sm"
                    />
                    <div>
                      {watch(`button.customLink`) !== true && (
                        <>
                          <div className="my-1 pb-1 pt-4">
                            <Checkbox
                              ref={register}
                              size="sm"
                              name={`button.usebutton_card`}
                            >
                              ลิงค์ปุ่มตามรูปแบบเนื้อหาแต่ละส่วนประกอบ
                            </Checkbox>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="my-1 pb-1 pt-4">
                      <Checkbox
                        ref={register}
                        size="sm"
                        // defaultChecked={eachField?.useOtherLink || false}
                        name={`button.customLink`}
                      >
                        เลือกกำหนดเองลิงค์เอง
                      </Checkbox>
                      {watch(`button.customLink`) === true &&
                        (
                          <div>
                            {watch(`button.customLink_inside`) !== true && (
                              <div className="w-full lg:w-full px-2 py-1">
                                <FormLabel fontSize="sm">Link ไปที่</FormLabel>
                                <Select
                                  size="sm"
                                  placeholder="ลิงค์เมนู"
                                  name={`button.link`}
                                  ref={register}
                                >
                                  {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                                    <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                                      {eachlink?.description}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            )}
                            <div>
                              <Checkbox
                                ref={register}
                                size="sm"
                                // defaultChecked={eachField?.useOtherLink || false}
                                name={`button.customLink_inside`}
                              >
                                ใช้ลิงค์กำหนดเอง
                              </Checkbox>
                              {watch(`button.customLink_inside`) === true &&
                                (
                                  <div className="my-2">
                                    <div className="text-gray-600 text-sm">
                                      กรณีที่เป็นลิงค์ในเว็บไซต์เดียวกัน เริ่มต้นที่ / แล้วต่อด้วยลิงค์ได้เลย
                                      ถ้าเป็นลิงค์ภายนอก ให้เริ่มต้นด้วย http:// หรือ https://
                                    </div>
                                    <div className="text-sm font-semibold">ลิงค์กำหนดเอง</div>
                                    <Input
                                      size="sm"
                                      placeholder="ใส่ลิ้ง"
                                      name={`button.otherLink_inside`}
                                      // defaultValue={otherLink||''}
                                      ref={register}
                                    />
                                  </div>
                                )}
                            </div>

                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="pt-4 bg-white pb-4 ">
              <div className=" pt-4">
                <h1 className="flex justify-center">ตัวช่วยเลือกความโค้ง</h1>
                <p className="flex justify-center">
                  <span className="text-red-500 text-l font-light">** ควรปรับความโค้งให้เหมาะสมกับพื้นที่ของข้อความกับรูปภาพ</span>
                </p>
              </div>
              <div className="  pt-4 pb-4">
                <div className="flex justify-center">
                  <SliderPictureCurve
                    curveFormName="desktop.card_content.picture_curve"
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                </div>
                <div className="flex justify-center">
                  <SliderCardContentCurve
                    curveFormName="desktop.card_content.card_content_curve"
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                </div>
                <div className="flex justify-center">
                  <SliderButtonCurve
                    curveFormName="desktop.card_content.button_curve"
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      )
      }
      {
        activeTab === 'Mobile' && (
          <div>
            <div className="border-2 border-black pr-60 pl-60 pt-20 pb-20">
              <div className="pb-10">
                <h1
                  className="text-xl border-2 text-center p-2 
               pr-40 pl-40 bg-white">
                  Preview
                </h1>
              </div>
              <div className="flex">
                <div
                  className="border-2 border-black pb-4 mr-20 ml-20"
                  style={{
                    borderRadius: `${watch("mobile.card_content.card_content_curve")}%`
                  }}
                >
                  <div
                    className="border-2 border-black mr-0 ml-0 pt-36 pb-36"
                    style={{
                      borderRadius: `${watch("mobile.card_content.picture_curve")}%`
                    }}
                  >
                    <p className="flex justify-center items-center">Image</p>
                  </div>
                  <div className="pt-4 pb-2">
                    <div
                      className="flex justify-center items-center text-xl pt-4 pb-2 border-2 border-black"
                      style={{
                        width: `${watch('mobile.card_content.header_frame_width')}px`,
                        height: `${watch('mobile.card_content.header_frame_length')}px`,
                        borderRadius: `${watch("mobile.card_content.header_curve")}%`
                      }}
                    >
                      หัวข้อ
                    </div>
                    <div className="pt-4">
                      <p className="flex justify-center items-center pr-4 pl-4 ">
                        At vero eos et accusamus et iusto odios dignissimos ducimus
                        blanditiis easde ant praesentium voluptatum deleniti atque corrupti
                        et quas molestias excepturi sint Sed ut perspiciatis unde omnis iste natus
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-6  items-center pt-80">
                    <div className="flex justify-center ">
                      <SliderHeaderArea
                        headerArea='mobile.card_content.header_frame_length'
                        register={register}
                        defaultValue={defaultValue}
                        watch={watch}
                      />
                    </div>
                    <div className="flex justify-center pt-3">
                      <SliderHeaderCurve
                        headerCurve='mobile.card_content.header_curve'
                        register={register}
                        defaultValue={defaultValue}
                        watch={watch}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-4">
                <div
                  className="border-2 border-black mr-40 ml-40 pt-6 pb-6"
                  style={{
                    borderRadius: `${watch("mobile.card_content.button_curve")}%`
                  }}
                >
                  <div className="flex justify-center">
                    <IconButton
                      type="button"
                      onClick={handleButtonClick}>
                      <div
                        className="text-lg"
                      >
                        {watch('translation.th.buttonTitle1')}
                        <KeyboardArrowDown />
                      </div>
                    </IconButton>
                  </div>
                  {showCardContent && (
                    <div className="w-full  px-2 py-1 ">
                      <FormLabel fontSize="sm" className="pt-3"></FormLabel>
                      <label
                        className="block  text-gray-700 text-sm font-semibold mb-2"
                        htmlFor="grid-password"
                      >
                        ภาษาไทย
                      </label>
                      <Input
                        ref={register}
                        placeholder="ชื่อปุ่ม ไทย"
                        name="translation.th.buttonTitle1"
                        size="sm"
                        setValue={watch('mobile.translation.th.buttonTitle1')}
                      // defaultValue={defaultValue?.translation?.th?.buttonTitle1}
                      />
                      <label
                        className="pt-2 block  text-gray-700 text-sm font-semibold mb-2"
                        htmlFor="grid-password"
                      >
                        ภาษาอังกฤษ
                      </label>
                      <Input
                        ref={register}
                        placeholder="ชื่อปุ่ม Eng"
                        name="translation.en.buttonTitle1"
                        size="sm"
                        setValue={watch('translation.en.buttonTitle1')}
                      // defaultValue={defaultValue?.translation?.en?.buttonTitle1}
                      />
                      <div>
                        {watch(`button.customLink`) !== true && (
                          <>
                            <div className="my-1 pb-1 pt-4">
                              <Controller control={control}
                                name="button.usebutton_card"
                                render={(field) => (
                                  <Checkbox
                                    size="sm"
                                    onChange={e => field.onChange(e.target.checked)}
                                    defaultChecked={field.value}
                                  >
                                    ลิงค์ปุ่มตามรูปแบบเนื้อหาแต่ละส่วนประกอบ
                                  </Checkbox>
                                )}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="my-1 pb-1 pt-4">
                        <Controller
                          defaultValue={defaultValue?.customLink || false}
                          name={`button.customLink`}
                          control={control}
                          render={(field) => (
                            <Checkbox
                              size="sm"
                              onChange={e => {
                                field.onChange(e.target.checked)
                              }}
                              defaultChecked={field.value}
                            >
                              ใช้ลิงค์กำหนดเอง
                            </Checkbox>
                          )}
                        />


                        {watch(`button.customLink`) === true &&
                          (
                            <div>
                              <div className="w-full lg:w-full px-2 py-1">
                                <FormLabel fontSize="sm">ลิ้ง</FormLabel>
                                <Select
                                  size="sm"
                                  placeholder="ลิงค์เมนู"
                                  name={`button.link`}
                                  // defaultValue={fields[index]?.link}
                                  ref={register}
                                >
                                  {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                                    <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                                      {eachlink?.description}
                                    </option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-4 bg-white pb-4 ">
                <div className=" pt-4">
                  <h1 className="flex justify-center">ตัวช่วยเลือกความโค้ง</h1>
                  <p className="flex justify-center">
                    <span className="text-red-500 text-l font-light">** ควรปรับความโค้งให้เหมาะสมกับพื้นที่ของข้อความกับรูปภาพ</span>
                  </p>
                </div>
                <div className="  pt-4 pb-4">
                  <div className="flex justify-center">
                    <SliderPictureCurve
                      curveFormName="mobile.card_content.picture_curve"
                      register={register}
                      defaultValue={defaultValue}
                      watch={watch}
                    />
                  </div>
                  <div className="flex justify-center">
                    <SliderCardContentCurve
                      curveFormName="mobile.card_content.card_content_curve"
                      register={register}
                      defaultValue={defaultValue}
                      watch={watch}
                    />
                  </div>
                  <div className="flex justify-center">
                    <SliderButtonCurve
                      curveFormName="mobile.card_content.button_curve"
                      register={register}
                      defaultValue={defaultValue}
                      watch={watch}
                    />
                  </div>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="flex flex-wrap ">
        {activeTab === 'Desktop' && (
          <div className="w-full lg:w-1/2 px-2 py-1">
            <FormLabel fontSize="sm">จำนวนรายการต่อ 1 แถว</FormLabel>
            <Input
              ref={register}
              placeholder="จำนวนรายการต่อ 1 แถว"
              name="desktop.card_content.number_per_row"
              type="number"
              size="sm"
              required
              defaultValue={defaultValue?.desktop?.card_content?.number_per_row || 10}
            />

            <FormLabel fontSize="sm" className="pt-4">ลิมิตของจำนวนรูปภาพ</FormLabel>
            {!watch("desktop.card_content.unlimit") && (
              <Input
                ref={register}
                placeholder="ลิมิตของจำนวนรูปภาพ"
                name="type_attribute.limit"
                type="number"
                size="sm"
                required
                defaultValue={defaultValue?.type_attribute?.limit || 10}
              />
            )}

            <FormControl display="flex">
              <Checkbox
                ref={register}
                name="type_attribute.unlimit"
                defaultChecked={defaultValue?.type_attribute?.limit === null}
                size="sm"
                onChange={(event) => {
                  if (event.target.checked) {
                    setValue("type_attribute.limit", null)
                    setValue("type_attribute.unlimit", true)
                  } else {
                    setValue("type_attribute.limit", 10)
                    setValue("type_attribute.unlimit", false)
                  }
                }}
              >
                ไม่จำกัด
              </Checkbox>
            </FormControl>

          </div>
        )}
        {activeTab === 'Mobile' && (
          <div className="w-full lg:w-1/2 px-2 py-1">
            <FormLabel fontSize="sm">จำนวนรายการต่อ 1 แถว</FormLabel>
            <Input
              ref={register}
              placeholder="จำนวนรายการต่อ 1 แถว"
              name="mobile.card_content.number_per_row"
              type="number"
              size="sm"
              required
              defaultValue={defaultValue?.mobile?.card_content?.number_per_row || 10}
            />

            <FormLabel fontSize="sm" className="pt-4">ลิมิตของจำนวนรูปภาพ</FormLabel>
            {!watch("type_attribute.unlimit") && (
              <Input
                ref={register}
                placeholder="ลิมิตของจำนวนรูปภาพ"
                name="type_attribute.limit"
                type="number"
                size="sm"
                required
                defaultValue={defaultValue?.type_attribute?.limit || 10}
              />
            )}

            <FormControl display="flex">
              <Checkbox
                ref={register}
                name="type_attribute.unlimit"
                defaultChecked={defaultValue?.type_attribute?.limit === null}
                size="sm"
                onChange={(event) => {
                  if (event.target.checked) {
                    setValue("type_attribute.limit", null)
                    setValue("type_attribute.unlimit", true)
                  } else {
                    setValue("type_attribute.limit", 10)
                    setValue("type_attribute.unlimit", false)
                  }
                }}
              >
                ไม่จำกัด
              </Checkbox>
            </FormControl>
          </div>
        )}
      </div>
    </div >
  )
}

export default forwardRef(CardContentGallery)
