import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { BookingStatus } from "../../../config/constant/BookingStatus"
import ModalCheckIn from "../../Modal/ReservationManagement/ModalCheckin"

export default function CheckedInButton({ bookingInfo, handleEditBooking }) {
  const currentStatus = bookingInfo.status
  const [isShowModal, setIsShowModal] = useState(false)
  const { t } = useTranslation()

  return (
    <div>
      <ModalCheckIn
        handleOnClose={() => {
          setIsShowModal(false)
        }}
        isOpen={isShowModal}
        handleEditBooking={handleEditBooking}
        bookingInfo={bookingInfo}
      />
      <Button
        size="sm"
        width="20"
        disabled={
          currentStatus !== BookingStatus.pending && currentStatus !== BookingStatus.confirm
        }
        colorScheme="blue"
        onClick={() => setIsShowModal(true)}
      >
        <i
          className={`fas fa-check mr-2 ${
            currentStatus === BookingStatus.book ||
            currentStatus === BookingStatus.pending ||
            currentStatus === BookingStatus.confirm
              ? `hidden`
              : ``
          }`}
        />{" "}
        {t("reservation.checkin")}
      </Button>
    </div>
  )
}

CheckedInButton.propTypes = {
  bookingInfo: PropTypes.object,
  handleEditBooking: PropTypes.func,
}
