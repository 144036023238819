import { DateTime } from "luxon"
import _ from "lodash"
import moment from "moment"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter } from "../../../util"
import { CountryList } from "../../constant"

const dateFormat = { day: "numeric", month: "long", year: "numeric" }

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 */
export default function RegistrationCard(bookingInfo, information, systemInfo) {
  const dayAmount = DateTime.fromISO(bookingInfo?.end)
    .diff(DateTime.fromISO(bookingInfo?.start))
    .as("days")
    .toFixed(0)

  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "Registration Card",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "ใบลงทะเบียนเข้าพัก / Registration Card ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        text: [
          { text: "ห้องพักเลขที่ / Room No. ", bold: true },
          {
            text: `  ${_.map(bookingInfo?.list, (eachList) => eachList?.room?.name)}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 10, 0, 10],
        alignment: "right",
      },

      {
        table: {
          headerRows: 2,
          widths: ["20%", "20%", "20%", "10%", "10%", "20%"],

          body: [
            [
              { text: "วันที่เข้าพัก\nArrival Date", bold: true, rowSpan: 2, alignment: "center" },
              { text: "วันที่ออก\nDeparture Date", bold: true, rowSpan: 2, alignment: "center" },
              { text: "ประเภทห้องพัก\nRoom Type", bold: true, rowSpan: 2, alignment: "center" },
              { text: "เวลา (Times)", colSpan: 2, bold: true, alignment: "center" },
              {},
              { text: "ราคาห้อง\n Room Rate", bold: true, rowSpan: 2, alignment: "center" },
            ],
            [
              {},
              {},
              {},
              { text: "Check In", bold: true, alignment: "center" },
              { text: "Check Out", bold: true, alignment: "center" },
              {},
            ],
            [
              {
                text: `${DateTime.fromISO(bookingInfo?.start)
                  .setLocale("th")
                  .toLocaleString(dateFormat)}`,
                alignment: "center",
              },
              {
                text: `${DateTime.fromISO(bookingInfo?.end)
                  .setLocale("th")
                  .toLocaleString(dateFormat)}`,
                alignment: "center",
              },
              {
                text: _.map(
                  _.union(
                    _.map(
                      bookingInfo?.list,
                      (eachList) =>
                        eachList?.room?.type?.translation?.th?.name || eachList?.room?.type?.name,
                    ),
                  ),
                  (eachList) => `${eachList || ""}\n`,
                ),
                alignment: "center",
              },
              {
                text: moment(bookingInfo?.checkin?.timestamp).format("HH:mm"),
                alignment: "center",
              },
              { text: "", alignment: "center" },
              { text: "", alignment: "center" },
            ],
          ],
        },
      },
      {
        text: "ข้อมูลผู้เข้าพัก / Guest Information",
        bold: true,

        fontSize: "11",
        margin: [0, 10, 0, 10],
      },
      {
        table: {
          widths: ["33%", "33%", "17%", "17%"],
          margin: [0, 5, 0, 10],
          body: [
            [
              {
                text: "นามสกุล (Lastname)",
                fontSize: 9,
                bold: true,
                border: [true, true, true, false],
              },
              {
                text: "ชื่อ (Firstname)",
                fontSize: 9,
                bold: true,
                border: [true, true, true, false],
              },
              { text: "⬜ ผู้ชาย (Male)", bold: true, rowSpan: 2, margin: [0, 10] },
              { text: "⬜ ผู้หญิง (Female)", bold: true, rowSpan: 2, margin: [0, 10] },
            ],
            [
              {
                text: bookingInfo?.checkin?.lastname || bookingInfo?.customer?.lastname,
                border: [true, false, true, true],
              },
              {
                text: bookingInfo?.checkin?.firstname || bookingInfo?.customer?.firstname,
                border: [true, false, true, true],
              },
              {},
              {},
            ],
            [
              {
                text: "เลขบัตรประจำตัวประชาชน\n(ID/Passport)",
                bold: true,
                fontSize: 8.5,
                border: [true, true, true, false],
              },
              { text: "TM Number", bold: true, fontSize: 9, border: [true, true, true, false] },
              {
                text: "สัญชาติ (Nationality)",
                bold: true,
                fontSize: 9,
                colSpan: 2,
                border: [true, true, true, false],
              },
              {},
            ],
            [
              {
                text: bookingInfo?.checkin?.id_card || bookingInfo?.customer?.id_card,
                border: [true, false, true, true],
              },
              {
                text: bookingInfo?.checkin?.tm_number,
                border: [true, false, true, true],
              },
              {
                text:
                  _.find(CountryList, { value: bookingInfo?.customer?.nationality })?.thaiName ||
                  _.find(CountryList, { value: bookingInfo?.customer?.nationality })?.label,
                border: [true, false, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: "วันเดือนปีเกิด (Date of Birth)",
                bold: true,
                fontSize: 8.5,
                colSpan: 2,
                border: [true, true, true, false],
              },
              {},
              {
                text: "อาชีพ (Profession)",
                bold: true,
                fontSize: 9,
                colSpan: 2,
                border: [true, true, true, false],
              },
              {},
            ],
            [
              {
                text: bookingInfo?.customer?.birthday
                  ? DateTime.fromISO(bookingInfo?.customer?.birthday)
                      .setLocale("th")
                      .toLocaleString(dateFormat)
                  : "",
                colSpan: 2,
                border: [true, false, true, true],
              },
              {},
              {
                text: bookingInfo?.checkin?.occupation || "-",
                border: [true, false, true, true],
                colSpan: 2,
              },
              {},
            ],
          ],
        },
      },
      {
        margin: [0, 10, 0, 10],
        table: {
          widths: ["33%", "33%", "17%", "17%"],
          body: [
            [
              {
                text: "ที่อยู่ (Address)",
                fontSize: 9,
                bold: true,
                colSpan: 4,
                border: [true, true, true, false],
              },
              {},
              {},
              {},
            ],
            [
              {
                text: bookingInfo?.checkin?.address || bookingInfo?.customer?.address,
                border: [true, false, true, true],
                colSpan: 4,
              },
              {},
              {},
              {},
            ],
            [
              {
                text: "จังหวัด (City)",
                bold: true,
                fontSize: 9,
                border: [true, true, true, false],
              },
              {
                text: "ประเทศ (Country)",
                bold: true,
                fontSize: 9,
                border: [true, true, true, false],
              },
              {
                text: "รหัสไปรษณีย์ (Post Code)",
                bold: true,
                fontSize: 9,
                colSpan: 2,
                border: [true, true, true, false],
              },
              {},
            ],
            [
              {
                text: bookingInfo?.checkin?.province || bookingInfo?.customer?.province,
                border: [true, false, true, true],
              },
              {
                text:
                  _.find(CountryList, { value: bookingInfo?.checkin?.stay_country })?.thaiName ||
                  _.find(CountryList, { value: bookingInfo?.checkin?.stay_country })?.label,
                border: [true, false, true, true],
              },
              {
                text: bookingInfo?.checkin?.postcode || bookingInfo?.customer?.postcode,
                border: [true, false, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: "อีเมล (Email)",
                bold: true,
                fontSize: 9,
                border: [true, true, true, false],
              },
              {
                text: "โทรศัพท์ (Tel)",
                bold: true,
                fontSize: 9,
                border: [true, true, true, false],
              },
              {
                text: "แฟกซ์ (Fax)",
                bold: true,
                fontSize: 9,
                colSpan: 2,
                border: [true, true, true, false],
              },
              {},
            ],
            [
              {
                text: bookingInfo?.checkin?.email || bookingInfo?.customer?.email,
                border: [true, false, true, true],
              },
              {
                text: bookingInfo?.checkin?.tel || bookingInfo?.customer?.tel,
                border: [true, false, true, true],
              },
              {
                text: "",
                border: [true, false, true, true],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: "",
                bold: true,
                fontSize: 9,
                border: [false, false, false, false],
              },
              {
                text: "ทะเบียนรถ",
                bold: true,
                fontSize: 9,
                border: [true, true, true, false],
              },
              {
                text: "ลายเซ็น (Signature)",
                bold: true,
                fontSize: 9,
                colSpan: 2,
                border: [true, true, true, false],
              },
              {},
            ],
            [
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                text: bookingInfo?.checkin?.car_licence_number || "",
                border: [true, false, true, true],
              },
              {
                text: "",
                border: [true, false, true, true],
                colSpan: 2,
                margin: [0, 10],
              },
              {},
            ],
          ],
        },
      },
      {
        margin: [0, 10, 0, 10],
        table: {
          widths: ["33%", "33%", "17%", "17%"],
          body: [
            [
              {
                text: "",
                fontSize: 9,
                border: [false, false, false, false],
              },
              {
                text: "",
                fontSize: 9,
                border: [false, false, false, false],
              },
              {
                text: "⬜ ออกใบเสร็จ",
                bold: true,
                fontSize: 9,
                margin: [0, 5],
                border: [true, true, true, true],
              },
              {
                text: "⬜ ไม่ออกใบเสร็จ",
                bold: true,
                fontSize: 9,
                margin: [0, 5],
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
      {
        text: "สำเนาบัตรประจำตัวประชาชน / ID Card / Passport Image",
        bold: true,

        fontSize: "11",
        margin: [0, 10, 0, 10],
      },
      bookingInfo?.checkin?.image?.url
        ? { height: 140, width: 250, image: "id_card", margin: [10, 0, 0, 0] }
        : {},
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
      id_card: bookingInfo?.checkin?.image?.url || "",
    },
  }
}
