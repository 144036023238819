import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"

import { HeaderForm } from "../../components"
import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"

export default function EditHeader() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, watch, setValue, control } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const info = useSelector((state) => state.information)
  // const [images, setImages] = useState([])

  const onSubmit = (data) => {
    dispatch(actions.editOneInformation(info?.id, data)).then(() => {
      dispatch(actions.getInformation()).then(() => {
        window.alert("แก้ไขข้อมูลเสร็จสิ้น")
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ตั้งค่า Header">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <HeaderForm
                defaultValue={info}
                register={register}
                watch={watch}
                setValue={setValue}
                control={control}
              />

              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
