export const HomepageType = {
  TYPE_1: {
    status_code: "TYPE_1",
    description: "Homepage แบบ 1",
  },
  TYPE_2: {
    status_code: "TYPE_2",
    description: "Homepage แบบ 2",
  },
  TYPE_3: {
    status_code: "TYPE_3",
    description: "Homepage แบบ 3",
  },
  DIPROM_1: {
    status_code: "DIPROM_1",
    description: "ศูนย์ส่งเสริมอุตสาหกรรม แบบ 1",
  },
  DIPROM_2: {
    status_code: "DIPROM_2",
    description: "ศูนย์ส่งเสริมอุตสาหกรรม แบบ 2",
  },
  DIPROM_3: {
    status_code: "DIPROM_3",
    description: "ศูนย์ส่งเสริมอุตสาหกรรม แบบ 3",
  },
}

export default HomepageType
