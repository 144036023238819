import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Tooltip } from "@chakra-ui/react"
import { HotelFacility } from "../../config/constant"

const RoomFacilityDisplayPanel = ({ roomFacilities }) => {
  const { i18n } = useTranslation()
  return (
    <div className="flex gap-3 my-2 text-gray-400">
      {_.map(
        _.filter(HotelFacility, (each) => roomFacilities?.[each.ref] === true),
        (each, index) => (
          <div key={index}>
            <Tooltip label={i18n.language === "th" ? each.nameTH : each.nameEn}>
              <i className={each.icon} />
            </Tooltip>
          </div>
        ),
      )}
    </div>
  )
}

export default RoomFacilityDisplayPanel
