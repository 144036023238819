/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import moment from "moment"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { MeetingRoomTimeSlot } from "../../config/constant"
import { overallMeetingRoomOrderStatusGenerator } from "../../util"
import MeetingRoomHourSelector from "../Selectors/MeetingRoomHourSelector"
import Dot from "../common/Dot/dot"

export default function MeetingRoomStatusList({ meetingRoom, startDate, endDate, isOneDay }) {
  const { i18n } = useTranslation()
  // Render Timeslot
  const renderEachTimeSlot = (timeslotCode, allMeeitngRoomOrder, selectedDay) => {
    const bookingOnThisSlot = _.filter(
      allMeeitngRoomOrder,
      (eachMeetingRoomOrder) =>
        eachMeetingRoomOrder?.timeslot === timeslotCode &&
        moment(eachMeetingRoomOrder?.date).isSame(moment(selectedDay), "date"),
    )

    return (
      <div className="p-2 w-full lg:w-1/3">
        <div className="bg-gray-200 rounded-md mb-2">
          <div className=" justify-between p-4">
            <h3 className="font-bold text-lg mx-4 ml-2 ">
              <i className={`mr-2 ${MeetingRoomTimeSlot[timeslotCode]?.icon || ""}`} />
              {MeetingRoomTimeSlot[timeslotCode]?.description}
            </h3>
            <h6 className="mx-2 text-sm ">
              {moment(selectedDay).locale(i18n.language)?.format("D MMM YYYY")}
            </h6>
          </div>
        </div>
        {_.map(bookingOnThisSlot, (eachBooking, bookingIndex) => {
          const meetingRoomStatus = overallMeetingRoomOrderStatusGenerator(eachBooking)
          return (
            <div
              key={bookingIndex}
              className="p-2 px-4 my-1 rounded-md border mx-2 hover:shadow-md"
            >
              <Link
                to={`/order/service-orders/view/${eachBooking?.service_order?._id}`}
                className="cursor-pointer"
              >
                <div>No. {eachBooking?.service_order?.order_no}</div>
                <div className="font-semibold">
                  <i className="fas fa-id-card mr-2"></i>
                  {eachBooking?.service_order?.name || "-"}
                </div>{" "}
                <div>
                  <i className="fas fa-user mr-2"></i>
                  {eachBooking?.service_order?.customer?.firstname || "-"}{" "}
                  {eachBooking?.service_order?.customer?.lastname || ""}
                </div>
                <div>
                  <i className="fas fa-calendar mr-2"></i>
                  {moment(eachBooking?.date).locale(i18n.language).format("D MMM YYYY")}
                </div>{" "}
                <div className="mx-1">
                  <Dot
                    color={meetingRoomStatus?.color}
                    word={
                      i18n.language === "th"
                        ? meetingRoomStatus?.description
                        : meetingRoomStatus?.descriptionEn
                    }
                  />
                </div>
              </Link>
            </div>
          )
        })}
        <br />
      </div>
    )
  }

  const renderDaySlot = (selectedMeetingRoom, selectedDay, dayslotKey) => {
    const dayOneThisSlot = moment(selectedDay).add(dayslotKey, "day")
    return (
      <div className="my-2 flex flex-wrap " key={`day-${dayslotKey}`}>
        {renderEachTimeSlot(
          MeetingRoomTimeSlot.SLOT_MORNING.status_code,
          selectedMeetingRoom?.meeting_room_orders,
          dayOneThisSlot,
        )}
        {renderEachTimeSlot(
          MeetingRoomTimeSlot.SLOT_AFTERNOON.status_code,
          selectedMeetingRoom?.meeting_room_orders,
          dayOneThisSlot,
        )}
        {renderEachTimeSlot(
          MeetingRoomTimeSlot.SLOT_EVENING.status_code,
          selectedMeetingRoom?.meeting_room_orders,
          dayOneThisSlot,
        )}
      </div>
    )
  }

  const dayAmount = isOneDay
    ? 1
    : moment(endDate).startOf("day").diff(moment(startDate).startOf("day"), "day", false)
  const dayArray = Array(dayAmount > 0 ? dayAmount : 0)
  // Return Timeslot List
  return (
    <div>
      <div>
        {_.map(meetingRoom?.arr, (eachMeetingRoom, index) => (
          <div key={index}>
            <h3 className="my-2 text-lg font-bold">{eachMeetingRoom?.name}</h3>
            <hr />
            {_.map(dayArray, (eachDay, dayIndex) =>
              eachMeetingRoom?.use_slot_price ? (
                renderDaySlot(eachMeetingRoom, startDate, dayIndex)
              ) : (
                <MeetingRoomHourSelector
                  dayArray={dayArray}
                  meetingRoom={eachMeetingRoom}
                  selectedDay={startDate}
                  allowClick
                />
              ),
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

MeetingRoomStatusList.propTypes = {
  meetingRoom: PropTypes.shape({
    arr: PropTypes.arrayOf(PropTypes.object),
  }),
}
