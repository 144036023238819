import React, { useState } from "react"
import { useDispatch } from "react-redux"
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  Button,
} from "@chakra-ui/react"
import _ from "lodash"

import UploadImage from "../../ImageUpload/ImageUpload"
import DialogLoading from "../../Loading/DialogLoading"
import Alert from "../../common/Alert/AlertDialog"
import * as actions from "../../../redux/actions"

export default function ModalAddPhotoToFacilityPromotionAndArticle({
  isOpen,
  handleOnClose,
  defaultValueArray,
  itemId,
  type,
}) {
  const dispatch = useDispatch()
  const [images, setImages] = useState([])
  const [isDialogLoadingShow, setIsDialogLoadingShow] = useState(false)
  const [isError, setIsError] = useState()
  const [errMessage, setErrMessage] = useState()

  const handleOnUpload = () => {
    setIsDialogLoadingShow(true)
    const imagePayload = {
      images: _.map(images, (eachImage) => ({ image: eachImage.data_url, imageType: type })),
    }

    console.log("Image Payload", imagePayload)

    const imageListPayload = defaultValueArray?.images

    try {
      imageListPayload.push(imagePayload)
      console.log("Image Payload can push", imageListPayload)
    } catch (error) {
      setErrMessage("ไม่สามรถเพิ่มรูปภาพใหม่ได้")
      setIsError(true)
      console.error("Cannot Push Image ", error)
    }

    // type promotion
    if (type === "promotion") {
      dispatch(actions.editOnePromotion(itemId, { images: imageListPayload }))
        .then(() => {
          dispatch(actions.getOnePromotion(itemId))
            .then(() => {
              handleOnClose()
              setImages([])
              setIsDialogLoadingShow(false)
            })
            .catch((err) => {
              setErrMessage(err)
              setIsError(true)
            })
            .finally(() => {
              setIsDialogLoadingShow(false)
            })
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
        .finally(() => {
          setIsDialogLoadingShow(false)
        })
    }
    // type facility
    if(type === "facility") {
      console.log("Edit One Facility", imageListPayload)
      dispatch(actions.editOneFacility(itemId, { images: imageListPayload }))
        .then(() => {
          dispatch(actions.getOneFacility(itemId))
            .then(() => {
              handleOnClose()
              setImages([])
              setIsDialogLoadingShow(false)
            })
            .catch((err) => {
              setErrMessage(err)
              setIsError(true)
            })
            .finally(() => {
              setIsDialogLoadingShow(false)
            })
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
        .finally(() => {
          setIsDialogLoadingShow(false)
        })
    }

     // type article
    if(type === "article") {
      dispatch(actions.editOneArticle(itemId, { images: imageListPayload }))
      .then(() => {
        dispatch(actions.getOneArticle(itemId))
          .then(() => {
            handleOnClose()
            setImages([])
            setIsDialogLoadingShow(false)
          })
          .catch((err) => {
            setErrMessage(err)
            setIsError(true)
          })
          .finally(() => {
            setIsDialogLoadingShow(false)
          })
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
      .finally(() => {
        setIsDialogLoadingShow(false)
      })

    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <DialogLoading isLoading={isDialogLoadingShow} title="กำลังอัพโหลดรูปภาพ" />
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <ModalHeader className="font-sans" textStyle={{ fontsize: "sm" }}>
          เพิ่มรูปภาพ
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <UploadImage images={images} setImages={setImages} preview_size="100" maxNumber={1} />
        </ModalBody>
        <ModalFooter>
          <div className="flex justify-end gap-2 font-sans">
            <Button size="sm" colorScheme="green" onClick={handleOnUpload}>
              อัพโหลด
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
