import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, useToast } from "@chakra-ui/react"
import _ from "lodash"
import { Link } from "react-router-dom"

import Layout from "../../../components/layout/GeneralLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import Paginator from "../../../components/common/Paginator/NewPagination"
import { FrontpageComponentContainer } from "../../../components/containers"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"
import { appConfig } from "../../../config"

export default function FrontpageComponentsManagement() {
  const frontpageComponent = useSelector((state) => state.frontpageComponent)
  const gallery = useSelector((state) => state.gallery)
  const file = useSelector((state) => state.file)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const toast = useToast()

  const fetchingFrontpageComponents = () => {
    dispatch(actions.getAllFrontpageComponent({ page, size: 10, query: {} }))
      .then(() => {
        dispatch(actions.getAllArticleCategory({ page: 1, size: appConfig.maxFetchSize }))
        setIsLoading(true)
        dispatch(actions.getAllGallery({ page: 1, size: 100 }))
        dispatch(actions.getAllBackgroundGallery({ page: 1, size: 100 }))
        dispatch(actions.fileAll({ page: 1, size: 10000, name: "" }))
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
  }

  useEffect(() => {
    fetchingFrontpageComponents()
    return () => {}
  }, [page])

  const handleCreateNew = () => {
    setIsLoading(false)
    dispatch(actions.createOneFrontpageComponent({})).then(() => {
      fetchingFrontpageComponents()
    })
  }

  const handleEditFrontpageComponent = async (data) => {
    const payload = {
      ...data,
    }

    // console.log("handleEditFrontpageComponent data", payload)

    try {
      await dispatch(actions.editOneFrontpageComponent(data?.id, payload))
      fetchingFrontpageComponents()
      toast({ status: "success", title: "แก้ไขส่วนประกอบเว็บไซต์สำเร็จ" })
    } catch (error) {
      setIsError(true)
      setErrMessage(`ไม่สามารถแก้ไขส่วนประกอบหน้าเว็บไซต์ได้ ${error?.message}`)
    }
  }
  // console.log("handleEditFrontpageComponent data", payload)

  const handleDelete = (componentId) => {
    const confirm = window.confirm("ยืนยันการลบ")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOneFrontpageComponent(componentId))
        .then(() => {
          fetchingFrontpageComponents()
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ส่วนประกอบของหน้าเว็บไซต์" preview>
      <div className="flex gap-2 px-4">
        <Button colorScheme="purple" onClick={handleCreateNew}>
          เพิ่ม
        </Button>
        <Link to="/site/frontpage-component/arrange">
          <Button colorScheme="teal">จัดเรียง</Button>
        </Link>
        <Link to="/site/frontpage-component/template">
          <Button colorScheme="pink">เทมเพลท</Button>
        </Link>
      </div>

      <div className="p-4 w-full overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />

        {_.map(frontpageComponent?.arr, (eachComponent, index) => (
          <FrontpageComponentContainer
            key={index}
            frontpageComponent={eachComponent}
            handleEditFrontpageComponent={handleEditFrontpageComponent}
            handleDelete={handleDelete}
            gallery={gallery}
            file={file}
          />
        ))}

        <Paginator lastPage={frontpageComponent?.lastPage} setPage={setPage} />
      </div>
    </Layout>
  )
}
