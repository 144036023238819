import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import Confirm from "../../common/Alert/ConfirmDialog"
import { BookingStatus } from "../../../config/constant"

export default function CheckoutCancleButton({ bookingInfo, handleEditLog }) {
  const { t } = useTranslation()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const handleChangeToCheckinStatus = () => {
    handleEditLog(bookingInfo?.id, { status: BookingStatus.checkedIn })
    setIsConfirmOpen(false)
  }

  return (
    <div>
      <Confirm
        title="ยืนยันการเปลี่ยนสถานะ"
        body="สถานะการจองนี้จะกลับมาอยู่ในสถานะเช็คอิน เหมือนเดิม"
        handleOnClose={() => setIsConfirmOpen(false)}
        handleSuccess={handleChangeToCheckinStatus}
        isOpen={isConfirmOpen}
      />
      <Button size="sm" colorScheme="orange" onClick={() => setIsConfirmOpen(true)}>
        {t("reservation.checkoutCancle")}
      </Button>
    </div>
  )
}
