import React from "react"

const SliderTextcontentMobile = ({
  register,
  lengthFormName,
  widthFormName,
  curveFormName,
  topPaddingTextcontent,
  bottomPaddingTextcontent,
  watch,
}) => (
  <div>
    <label htmlFor="lengthSlider">ความยาวพื้นที่ข้อความ:</label>
    <input
      type="range"
      id="lengthSlider"
      defaultValue={336}
      min={1}
      max={1000}
      name={lengthFormName}
      ref={register}
    />
    <span>{watch(lengthFormName)}px</span>
    <br />
    <label htmlFor="widthSlider">ความกว้างพื้นที่ข้อความ:</label>
    <input
      type="range"
      id="widthSlider"
      min={1}
      max={420}
      defaultValue={400}
      name={widthFormName}
      ref={register}
    />
    <span>{watch(widthFormName)}px</span>
    <br />
    <label htmlFor="CurveCardContentSlider">ความโค้งกรอบข้อความ:</label>
    <input
      type="range"
      id="CurveButtonSlider"
      defaultValue={661}
      min={0}
      max={100}
      name={curveFormName}
      ref={register}
    />
    <span>{watch(curveFormName)}%</span>
    <label className="block text-sm font-medium text-gray-700">Padding ด้านล่าง</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={150}
      name={bottomPaddingTextcontent}
      ref={register}
      className="slider"
    />
    <span>{watch(bottomPaddingTextcontent)}px</span>
    <label className="block text-sm font-medium text-gray-700">Padding ด้านบน</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={150}
      name={topPaddingTextcontent}
      ref={register}
      className="slider"
    />
    <span>{watch(topPaddingTextcontent)}px</span>
  </div>
)

export default SliderTextcontentMobile
