import React from "react"
import { useTranslation } from "react-i18next"
import Flags from "country-flag-icons/react/3x2"

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  return (
    <div>
      <li className="items-center">
        <div className="flex ">
          <div
            className="shadow-sm mr-2 cursor-pointer"
            onClick={() => {
              i18n.changeLanguage("th")
            }}
          >
            <Flags.TH title="Thai" className="w-5" />
          </div>
          <div
            className="shadow-sm mr-2 cursor-pointer"
            onClick={() => {
              i18n.changeLanguage("en")
            }}
          >
            <Flags.GB title="English" className="w-5" />
          </div>
        </div>
      </li>
    </div>
  )
}

export default LanguageSwitcher
