import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"

import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Alert from "../../components/common/Alert/AlertDialog"
import DialogLoading from "../../components/Loading/DialogLoading"

export default function UpdateFrontPage() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false)

  const onUploadNewWebsite = () => {
    const confirm = window.confirm("ยีนยันการส่งการเปลี่ยนแปลง")
    if (confirm) {
      setIsLoadingDialogOpen(true)
      dispatch(actions.buildNewWebsite())
        .then(() => {
          dispatch(actions.getInformation()).then(() => {
            setIsError(true)
            setErrMessage(
              "ส่งการเปลี่ยนแปลงเรียบร้อยแล้ว กรุณารอ 2-3 นาทีกระบวนการเปลี่ยนแปลงจะเสร็จสิ้น",
            )
            setIsLoadingDialogOpen(false)
          })
        })
        .catch((err) => {
          console.error("Error on Upload to website", err)
          setErrMessage(err)
          setIsError(true)
          setIsLoadingDialogOpen(false)
        })
    }
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="อัพเดทหน้าเว็บไซต์">
      <Alert
        isError={isError}
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage()
        }}
        title="แจ้งผลการดำเนินการ"
      />
      <DialogLoading isLoading={isLoadingDialogOpen} />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <div className="flex flex-wrap justify-between pb-8">
          <div className="w-full">
            <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg my-3">
              <div className="w-full px-4 ">
                <div className="text-center">
                  การอัพเดทในที่นี้คือการเปลี่ยนแปลงสี / แบบอักษร / ข้อความ SEO <br />
                  การอัพเดทจะใช้เวลา 2-3 นาทีแล้วท่านจะเห็นการเปลี่ยนแปลงของเว็บไซต์ <br />
                  สำหรับการอัพเดทข้อมูลต่าง ๆ ในเว็บไซต์ระบบจะเปลี่ยนแปลงทันทีอยู่แล้ว
                  ไม่จำเป็นต้องกดปุ่มนี้
                </div>
                <div className="text-center  gap-0 py-3 ">
                  <Button
                    colorScheme="purple"
                    variant="solid"
                    type="button"
                    onClick={onUploadNewWebsite}
                  >
                    ส่งการเปลี่ยนแปลงสี / แบบอักษร
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
