import {
  SERVICE_ORDER_ALL,
  SERVICE_ORDER_CREATE,
  SERVICE_ORDER_DELETE,
  SERVICE_ORDER_GET,
  SERVICE_ORDER_UPDATE,
  SERVICE_ORDER_ERROR,
} from "../types"

import GeneralReduxActionClass from "../class/GeneralReduxAction"

const serviceOrderActions = new GeneralReduxActionClass({
  allConst: SERVICE_ORDER_ALL,
  createConst: SERVICE_ORDER_CREATE,
  deleteConst: SERVICE_ORDER_DELETE,
  errorConst: SERVICE_ORDER_ERROR,
  getConst: SERVICE_ORDER_GET,
  putConst: SERVICE_ORDER_UPDATE,
  backendPrefix: "service-order",
  description: "รายการ Service Order",
})

export const getAllServiceOrder = (query) => serviceOrderActions.getAll(query)
export const getOneServiceOrder = (id) => serviceOrderActions.getOne(id)
export const createOneServiceOrder = (payload) => serviceOrderActions.createOne(payload)
export const editOneServiceOrder = (id, payload) => serviceOrderActions.editOne(id, payload)
export const deleteOneServiceOrder = (id) => serviceOrderActions.deleteOne(id)
