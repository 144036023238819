import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Spinner } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import { Alert } from "../../components/common/Alert"
import { WhitePanel } from "../../components/common/Cards"
import { FoodAndBeverageOrderForm } from "../../components/Forms/FoodBeverage"
import { BasicBookingInfoPanel } from "../../components/Panels"

import * as actions from "../../redux/actions"

export default function EditFBOrder() {
  const [isLoading, setIsLoading] = useState(false)

  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const history = useHistory()
  const fbOrder = useSelector((state) => state.fbOrder)
  const me = useSelector((state) => state.me)
  const { register, handleSubmit } = useForm()
  const params = useParams()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const getBooking = () => {
    dispatch(actions.getOneFBOrder(params.id))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsAlertOpen(true)
      })
  }

  useEffect(() => {
    getBooking()
    return () => {}
  }, [])

  const handleCreateFBOrder = async (data) => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await dispatch(actions.editOneFBOrder(params.id, { ...data, user: me?._id }))
      history.goBack()
    } catch (error) {
      setAlertMessage(error?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
      setIsAlertOpen(true)
    }
  }

  const renderAlert = () => (
    <Alert
      errMessage={alertMessage}
      isError={isAlertOpen}
      handleOnClose={() => {
        setIsAlertOpen(false)
      }}
    />
  )

  const renderBackButton = () => (
    <div className="flex justify-between flex-wrap px-4">
      <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
        {t("general.back")}
      </Button>
    </div>
  )

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(handleCreateFBOrder)}>
        <FoodAndBeverageOrderForm register={register} defaultInfo={fbOrder} />
        <Button isFullWidth={true} colorScheme="purple" className="my-2" type="submit">
          {t("general.save")}
        </Button>
      </form>
    </div>
  )

  const renderInfoPanel = () => (
    <div className="my-2">
      <WhitePanel>
        <BasicBookingInfoPanel
          bookingInfo={{ ...fbOrder?.booking, customer: fbOrder?.customer }}
          showPrintingPanel={false}
        />
      </WhitePanel>
    </div>
  )

  if (!isLoading) {
    return <Spinner />
  }

  return (
    <Layout
      categoryTitle={t("foodAndBeverage.title")}
      title={t("foodAndBeverage.editFBOrder")}
      preview
    >
      {renderAlert()}
      {renderBackButton()}
      {renderInfoPanel()}
      {renderForm()}
    </Layout>
  )
}
