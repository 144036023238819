import {
  ARTICLE_CATEGORY_ALL,
  ARTICLE_CATEGORY_CREATE,
  ARTICLE_CATEGORY_DELETE,
  ARTICLE_CATEGORY_GET,
  ARTICLE_CATEGORY_UPDATE,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case ARTICLE_CATEGORY_ALL:
      return {
        arr: action.payload?.rows,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case ARTICLE_CATEGORY_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case ARTICLE_CATEGORY_CREATE:
      return { isLoading: true }
    case ARTICLE_CATEGORY_UPDATE:
      return { isLoading: true }
    case ARTICLE_CATEGORY_DELETE:
      return { isLoading: true }
    default:
      return state
  }
}
