/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import _ from "lodash"

import Layout from "../../../components/layout/GeneralLayout"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import SpinnersLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import { CheckinForm } from "../../../components/Forms/CRM"
import { Alert } from "../../../components/common/Alert"
import { appConfig } from "../../../config"

export default function EditCheckinInformation() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bookingInfo = useSelector((state) => state.bookings)
  const me = useSelector((state) => state.me)

  const [alertOpen, setAlertOpen] = useState(false)
  const { register, setValue, handleSubmit } = useForm({ defaultValues: bookingInfo })
  const [alertMessage, setAlertMessage] = useState("")
  const [idCardImage, setIdCardImage] = useState([])

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOneBooking(params?.id))
        .then(() => {
          dispatch(actions.getAllRooms())
          dispatch(actions.getAllServices({ size: appConfig.maxFetchSize, page: 1 }))
          setIsLoading(true)
        })
        .catch((err) => {
          setAlertOpen(true)
          setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        })
    }

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnersLoading />
  }

  const handleEditBooking = (data) => {
    setIsLoading(false)
    const bookingCheckinPayload = {
      ...data,
      timestamp: bookingInfo?.checkin?.timestamp,
      image: _.isEmpty(idCardImage)
        ? bookingInfo?.checkin?.image?._id
        : {
            imageType: "idCard",
            image: idCardImage?.[0]?.data_url,
          },
    }

    dispatch(
      actions.editOneBooking(params.id, {
        checkin: bookingCheckinPayload,
        user: me?._id,
        info: "แก้ไขข้อมูลเช็คอินให้กับลูกค้า",
      }),
    )
      .then(() => {
        dispatch(
          actions.editOneCustomer(bookingInfo?.customer?._id, {
            ...data,
            user: me?._id,
            info: "แก้ไขข้อมูลจากการแก้ไขข้อมูลเช็คอิน",
          }),
        )
          .then(() => {
            history.goBack()
            setIsLoading(true)
          })
          .catch((err) => {
            alert(`แก้ไขข้อมูลไม่สำเร็จ ${err?.message}`)
            setIsLoading(true)
          })
      })
      .catch((err) => {
        alert(`แก้ไขข้อมูลไม่สำเร็จ ${err?.message}`)
        setIsLoading(true)
      })
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title="แก้ไขข้อมูลการเช็คอิน">
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setAlertOpen(false)}
        isError={alertOpen}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          type="button"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(handleEditBooking)}>
          <WhitePanel>
            <CheckinForm
              bookings={bookingInfo}
              idCardImage={idCardImage}
              setIdCardImage={setIdCardImage}
              register={register}
              setValue={setValue}
            />
          </WhitePanel>{" "}
          <div className="my-2">
            <Button isFullWidth colorScheme="purple" type="submit">
              {t("general.save")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
