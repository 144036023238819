export const header = (information) => [
  {
    columns: [
      { height: 70, width: 70, image: "company_logo", margin: [40, 0, 0, 0] },
      { width: "10%", text: "" },
      {
        stack: [
          {
            text: ` ${
              information?.company_name ||
              information?.translation?.th?.name ||
              information?.name ||
              ""
            }`,
            fontSize: 12,
            bold: true,
          },
          {
            text: `ที่อยู่ ${
              information?.translation?.th?.address || information?.address || ""
            }  โทร ${information?.contract?.tel_1 || ""} ${information?.contract?.tel_2 || ""}`,
            fontSize: 10,
          },
          {
            text: `เลขประจำตัวผู้เสียภาษี ${information?.tax_id || "-"}`,
            fontSize: 10,
          },
        ],
      },
    ],
    margin: [0, 20, 40, 10],
    color: "#2F4F4F",
    font: "Kanit",
  },
]

export const slipHeader = (information) => [
  {
    stack: [
      {
        height: 30,
        width: 50,
        image: "company_logo",
        margin: [20, 10, 20, 10],
        alignment: "center",
      },
      {
        text: information?.company_name || information?.translation?.th?.name || "",
        fontSize: 10,
        bold: true,
        alignment: "center",
        font: "Sarabun",
      },
    ],
  },
]

export const footer = () => [
  {
    columns: ["", "", { text: `© 2024 E-Accom `, fontSize: 8, alignment: "right" }],
    margin: [24, 15],
  },
]
export const slipFooter = (information) => [
  {
    stack: [
      {
        text: "--------------------------",
        fontSize: 12,
        bold: true,
        alignment: "center",
      },
      {
        text: "",
        fontSize: 12,
        bold: true,
        alignment: "center",
      },
    ],
  },
]
