/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
/* eslint-disable arrow-body-style */
import React from "react"
import { Table, Input } from "antd"
import _ from "lodash"
import PurchaseOrderAmountAddingPanel from "../Panels/PurchaseOrderAmountAddingPanel"

const SelectMaterialTable = ({
  append,
  page,
  size,
  setSize,
  setPage,
  setSearchWord,
  fields,
  material,
}) => {
  const checkIsSelect = (materialId) => {
    const materialIds = _.map(fields, (each) => each?._id)
    return _.includes(materialIds, materialId)
  }

  const onChangeMaterialTable = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize
    setSize(pageSize)
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
  }

  const materialTableColumns = [
    {
      title: "ลำดับที่",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "รหัสวัตถุดิบ",
      dataIndex: "type_code",
      key: "type_code",
      render: (text, record) => record?.type_code || "-",
    },
    {
      title: "ชื่อวัตถุดิบ",
      dataIndex: "name",
      key: "name",
      render: (text, record) => record?.name || "-",
    },
    {
      title: "จำนวน",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <PurchaseOrderAmountAddingPanel
            appendFunction={append}
            selectedData={record}
            disabled={checkIsSelect(record?._id)}
          />
        </div>
      ),
    },
  ]
  return (
    <div>
      {" "}
      <div className="my-4 flex justify-between">
        <div className="text-lg font-semibold">วัตถุดิบที่สามารถเลือกได้</div>
        <div>
          <Input
            placeholder="ค้นหาวัตถุดิบ"
            addonBefore={<i className="fas fa-search" />}
            allowClear
            onChange={(e) => setSearchWord(e.target.value)}
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
      <Table
        columns={materialTableColumns}
        dataSource={material?.arr}
        onChange={onChangeMaterialTable}
        pagination={{
          current: page,
          pageSize: size,
          total: material?.total,
        }}
      />
    </div>
  )
}

export default SelectMaterialTable
