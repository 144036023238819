export const THAI_BANK = {
  BBL: {
    status_code: "BBL",
    description: "ธนาคารกรุงเทพ",
    descriptionEn: "Bangkok Bank",
  },
  KTB: {
    status_code: "KTB",
    description: "ธนาคารกรุงไทย",
    descriptionEn: "Krungthai Bank",
  },
  BAY: {
    status_code: "BAY",
    description: "ธนาคารกรุงศรี",
    descriptionEn: "Bank of Ayudhya",
  },
  KBANK: {
    status_code: "KBANK",
    description: "ธนาคารกสิกรไทย",
    descriptionEn: "Kasokorn Bank",
  },
  KK: {
    status_code: "KK",
    description: "ธนาคารเกียรตินาคิน",
    descriptionEn: "Kiatnakin Bank",
  },
  TTB: {
    status_code: "TTB",
    description: "ธนาคารทหารไทยธนชาติ",
    descriptionEn: "TTB",
  },
  SCB: {
    status_code: "SCB",
    description: "ธนาคารไทยพาณิชย์",
    descriptionEn: "Siam Commercial Bank",
  },
  CITI: {
    status_code: "CITI",
    description: "ซิตี้แบงค์",
    descriptionEn: "Citibank",
  },
  SCBT: {
    status_code: "SCBT",
    description: "ธนาคารสแตนดาร์ด ชาร์ตเตอร์",
    descriptionEn: "Standard Charter Bank",
  },
  UOBT: {
    status_code: "UOBT",
    description: "ธนาคารยูโอบี",
    descriptionEn: "UOB Bank",
  },
  GSB: {
    status_code: "GSB",
    description: "ธนาคารออมสิน",
    descriptionEn: "Government Saving Bank",
  },
  HSBC: { status_code: "HSBC", description: "ธนาคาร HSBC", descriptionEn: "HSBC Bank" },
  GHB: {
    status_code: "GHB",
    description: "ธนาคารอาคารสงเคราะห์",
    descriptionEn: "Government Housing Bank",
  },
  BAAC: {
    status_code: "BAAC",
    description: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    descriptionEn: "Bank for Agriculture and Agricultural Cooperatives",
  },
  ISBT: {
    status_code: "ISBT",
    description: "ธนาคารอิสลามแห่งประเทศไทย",
    descriptionEn: "Islamic Bank of Thailand ",
  },
  TISCO: {
    status_code: "TISCO",
    description: "ธนาคารทิสโก้",
    descriptionEn: "Tisco Bank",
  },
  LHBANK: {
    status_code: "LHBANK",
    description: "ธนาคารแลนด์แอนด์เฮาส์",
    descriptionEn: "Land and Houses Bank",
  },
  SMEB: {
    status_code: "SMEB",
    description: "ธนาคาร SME Bank",
    descriptionEn: "SME Bank",
  },
}

export default THAI_BANK
