/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect, useHistory } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import * as actions from "../redux/actions"

// components
import MainSidebar from "../components/common/Sidebar/MainSidebar"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import SpinnerLoading from "../components/Loading/SpinnerLoading"

// views
import FBOrderManagement from "../views/FoodBeverage/FBOrderManagement"
import CreateFBOrder from "../views/FoodBeverage/CreateFBOrder"
import EditFBOrder from "../views/FoodBeverage/EditFBOrder"
import MenuCategoryManagement from "../views/FoodBeverage/MenuCategoryManagement"
import CreateMenuCategory from "../views/FoodBeverage/CreateMenuCategory"
import EditMenuCategory from "../views/FoodBeverage/EditMenuCategory"
import MenuManagement from "../views/FoodBeverage/MenuManagement"
import CreateMenu from "../views/FoodBeverage/CreateMenu"
import EditMenu from "../views/FoodBeverage/EditMenu"
import CreateManyMenu from "../views/FoodBeverage/CreateManyMenu"
import TableManagement from "../views/FoodBeverage/TableManagement"
import CreateTable from "../views/FoodBeverage/CreateTable"
import CreateManyTable from "../views/FoodBeverage/CreateManyTable"
import EditTable from "../views/FoodBeverage/EditTable"
import DetailFBOrder from "../views/FoodBeverage/DetailFBOrder"
import FBTransactionList from "../views/FoodBeverage/FBTransactionList"
import AddFBToBooking from "../views/FoodBeverage/AddFBToBooking"

import UserRole from "../config/UserRoles"
import { ModuleList } from "../config/constant"
import { FOOD_BEVERAGE_MENU } from "../config/MenuList"

export default function FoodBeverageLayout() {
  const history = useHistory()

  const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me) // ข้อมูลสำหรับ authorize จาก db server
  const system = useSelector((state) => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const [showSidebar, setIsShowSidebar] = useState(true)

  useEffect(() => {
    console.log("FoodBeverage : ACTIVE")

    dispatch(actions.systemInfoGet()).then(() => {
      dispatch(actions.getInformation())
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {})
        .catch((err) => {
          window.alert(err)
        })
    })
    return () => {
      dispatch(actions.meReset())
    }
  }, [])

  useEffect(() => {
    if (me && system && system.isLoading === true) {
      console.log(me?.role, "User Super User Word : ", UserRole.SUPER_USER.toString())
      if (me?.access?.[ModuleList.FOOD_BEVERAGE.status_code]) {
        setIsLoading(true)
        console.log("ได้รับการอนุญาตเข้าถึง")
      } else {
        console.log("ไม่ได้รับอนุญาต")
        alert("ไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้")
        history.push("/")
      }
    }
    return () => {}
  }, [me])

  return isLoading ? (
    <>
      <MainSidebar
        menuList={FOOD_BEVERAGE_MENU}
        isShow={showSidebar}
        setIsShowSidebar={setIsShowSidebar}
      />
      <div className={` relative bg-gray-200 ${showSidebar ? "lg:ml-64" : ""} `}>
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/food-beverage/order" exact component={() => <FBOrderManagement />} />
            <Route path="/food-beverage/order/create" exact component={() => <CreateFBOrder />} />
            <Route path="/food-beverage/order/edit/:id" exact component={() => <EditFBOrder />} />
            <Route path="/food-beverage/list" exact component={() => <FBTransactionList />} />
            <Route
              path="/food-beverage/order/detail/:id"
              exact
              component={() => <DetailFBOrder />}
            />
            <Route
              path="/food-beverage/order/to-booking/:id"
              exact
              component={() => <AddFBToBooking />}
            />

            <Route
              path="/food-beverage/menu-category"
              exact
              component={() => <MenuCategoryManagement />}
            />
            <Route
              path="/food-beverage/menu-category/create"
              exact
              component={() => <CreateMenuCategory />}
            />
            <Route
              path="/food-beverage/menu-category/edit/:id"
              exact
              component={() => <EditMenuCategory />}
            />
            <Route path="/food-beverage/menu" exact component={() => <MenuManagement />} />
            <Route path="/food-beverage/menu/create" exact component={() => <CreateMenu />} />
            <Route
              path="/food-beverage/menu/create-many"
              exact
              component={() => <CreateManyMenu />}
            />
            <Route path="/food-beverage/menu/edit/:id" exact component={() => <EditMenu />} />
            <Route path="/food-beverage/table/" exact component={() => <TableManagement />} />
            <Route path="/food-beverage/table/create" exact component={() => <CreateTable />} />
            <Route
              path="/food-beverage/table/create-many"
              exact
              component={() => <CreateManyTable />}
            />
            <Route path="/food-beverage/table/edit/:id" exact component={() => <EditTable />} />

            <Redirect from="/food-beverage" to="/food-beverage/order" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </>
  ) : (
    <SpinnerLoading />
  )
}
