export const GridFrameDistance = {
  CLOSE_EDGE: {
    status_code: "CLOSE_EDGE",
    description: "ชิดขอบ",
    descriptionEn: "Close Edge",
  },
  TWENTY_FOUR: {
    status_code: "TWENTY_FOUR",
    description: "ระยะห่างจากขอบ 24 px",
    descriptionEn: "margin 24 px",
  },
  FOURTY_EIGHT: {
    status_code: "FOURTY_EIGHT",
    description: "ระยะห่างจากขอบ 48 px",
    descriptionEn: "margin 48 px",
  },
  SIXTY_FOUR: {
    status_code: "SIXTY_FOUR",
    description: "ระยะห่างจากขอบ 64 px",
    descriptionEn: "margin 64 px",
  },
  NINETY_SIX: {
    status_code: "NINETY_SIX",
    description: "ระยะห่างจากขอบ 96 px",
    descriptionEn: "margin 96 px",
  },
}

export default GridFrameDistance
