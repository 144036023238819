import _ from "lodash"

import {
  SERVICE_ORDER_ALL,
  SERVICE_ORDER_CREATE,
  SERVICE_ORDER_DELETE,
  SERVICE_ORDER_GET,
  SERVICE_ORDER_UPDATE,
  SERVICE_ORDER_ERROR,
} from "../../actions/types"

const initialState = {
  serviceOrder: null,
  isLoading: false,
}
export default function ServiceOrderReducer(state = initialState, action) {
  const generatePrice = (serviceOrder) => {
    const meetingRoomOrder =
      _.sum(_.map(serviceOrder?.meeting_room_orders, (eachOrder) => eachOrder?.price)) || 0

    const servicePrice =
      _.sum(_.map(serviceOrder?.service_list, (eachServices) => parseFloat(eachServices?.price))) ||
      0
    const fbPrice =
      _.sum(_.map(serviceOrder?.fb_orders, (eachServices) => parseFloat(eachServices?.price))) || 0
    const allPrice = meetingRoomOrder + servicePrice + fbPrice - serviceOrder?.final_discount
    return allPrice
  }

  switch (action.type) {
    case SERVICE_ORDER_ALL:
      return {
        arr: _.map(action.payload?.rows, (eachRow) => ({
          ...eachRow,
          price: generatePrice(eachRow),
        })),
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
      }
    case SERVICE_ORDER_GET:
      return {
        ...action.payload,
        price: generatePrice(action.payload),
        isLoading: true,
      }
    case SERVICE_ORDER_CREATE:
      return { isLoading: false }
    case SERVICE_ORDER_UPDATE:
      return { isLoading: false }
    case SERVICE_ORDER_DELETE:
      return { isLoading: false }
    case SERVICE_ORDER_ERROR:
      return { isLoading: false }
    default:
      return state
  }
}
