import React from "react"
import { Form, Select } from "antd"
import { Controller } from "react-hook-form"
import _ from "lodash"

const FoodAndBeverageOrderForm = ({ control, defaultValue, table }) => (
  <div>
    <Form layout="vertical">
      <Form.Item label="โต๊ะ" style={{ marginBottom: "5px" }}>
        <Controller
          control={control}
          name="table"
          defaultValue={defaultValue?.table}
          render={(field) => (
            <Select
              showSearch
              placeholder="เลือกโต๊ะ"
              optionFilterProp="children"
              onChange={field.onChange}
              filterOption={(input, option) =>
                (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={_.map(table?.arr, (eachTable) => ({
                ...eachTable,
                label: eachTable.name,
                value: eachTable._id,
              }))}
            />
          )}
        />
      </Form.Item>
    </Form>
  </div>
)

export default FoodAndBeverageOrderForm
