import React from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { Alert, AlertIcon, Badge } from "@chakra-ui/react"
import moment from "moment"

export default function GeneralMainLayout({ categoryTitle, title, preview = false, children }) {
  const system = useSelector((state) => state.system)
  return (
    <div className="relative flex flex-col min-w-0 break-words  bg-gray-100 w-full mb-6 px-4 shadow-lg rounded-md">
      <Helmet>
        <title>
          {categoryTitle || "E-Accom"} - {system?.name || "E-Accom"}{" "}
        </title>
        {system?.logo && (
          <link rel="icon" sizes="32x32" type="image/png" href={system?.logo?.url} />
        )}
        {system?.logo && (
          <link rel="icon" sizes="16x16" type="image/png" href={system?.logo?.url} />
        )}
        {system?.logo && <link rel="shortcut icon" type="image/png" href={system?.logo?.url} />}
      </Helmet>
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className=" text-gray-900 mb-1 text-sm font-semibold font-sans">{categoryTitle}</h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              {title}{" "}
              {preview && (
                <Badge colorScheme="cyan" rounded="md" fontSize="initial">
                  Preview
                </Badge>
              )}{" "}
            </h2>
          </div>
        </div>
      </div>
      {system?.systemWarning?.enable && (
        <div className="my-2 mx-4">
          <Alert status="warning" colorScheme="yellow">
            <AlertIcon />
            เนื่องด้วยระยะเวลาของโครงการ ระบบจะเปิดให้บริการไปถึงวันที่{" "}
            {moment(system?.systemWarning?.until).format("D MMMM YYYY")} <br />
            หากท่านต้องการใช้งานระบบต่อไป กรุณาติดต่อศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ ที่
            คุณสุนทรี(ตาล) หมายเลขโทรศัพท์ 0948069015
          </Alert>
        </div>
      )}
      {children}
    </div>
  )
}
