import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import { BookingStatus } from "../../../config/constant/BookingStatus"
import { paymentStatus } from "../../../config/constant/PaymentStatus"
import Confirm from "../../common/Alert/ConfirmDialog"

export default function SuccessButton({ bookingInfo, handleEditBooking }) {
  const currentStatus = bookingInfo.status
  const currentPaymentStatus = bookingInfo.paymentStatus
  const [confirmOpen, setIsConfirmOpen] = useState(false)
  const { t } = useTranslation()

  const handleOnSuccess = () => {
    handleEditBooking(bookingInfo?.id, { status: BookingStatus.success })
    setIsConfirmOpen(false)
  }

  return (
    <div>
      <Confirm
        title={t("reservation.confirmBookingStatusChange")}
        body="ยืนยันการเปลี่ยนสถานะการจองเป็น สำเร็จ"
        handleOnClose={() => setIsConfirmOpen(false)}
        handleSuccess={handleOnSuccess}
        isOpen={confirmOpen}
      />
      <Button
        size="sm"
        width="20"
        colorScheme="orange"
        onClick={() => setIsConfirmOpen(true)}
        disabled={
          currentStatus !== BookingStatus.checkedOut ||
          currentPaymentStatus !== paymentStatus.allPayment.allPaymentConfirm
        }
      >
        {t("reservation.success")}
      </Button>
    </div>
  )
}
