import {
  CUSTOMER_ALL,
  CUSTOMER_CREATE,
  CUSTOMER_GET,
  CUSTOMER_UPDATE,
  CUSTOMER_DELETE,
} from "../../actions/types"

const initialState = {
  roomtypes: null,
  isLoading: false, 
}
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_ALL:
      return {
        arr: action.payload?.rows, 
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        total: action.payload?.total, 
      }
    case CUSTOMER_GET:
      return {
        ...action.payload,
        isLoading: true,
      }
    case CUSTOMER_CREATE:
      return { isLoading: false }
    case CUSTOMER_UPDATE:
      return { isLoading: false }
    case CUSTOMER_DELETE:
      return { isLoading: false }
    default:
      return state
  }
}
