import React, { forwardRef } from "react"
import PropTypes from "prop-types"

const UserForm = ({ register, errors, user }) => (
  <div>
    <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
      <div className="w-full px-4 ">
        <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">ข้อมูลผู้ใช้งาน</h6>

        <div className="flex gap-2">
          <div className="relative w-full mb-3 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              ชื่อผู้ใช้งาน
            </label>
            {user?.data?.username}
          </div>
        </div>

        <div className="flex gap-2">
          <div className="relative w-full mb-3 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              รหัสผ่าน
            </label>
            <input
              name="password"
              type="password"
              ref={register({ required: true })}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="*******"
            />
          </div>
          <div className="relative w-full mb-3 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              ยืนยันรหัสผ่าน
            </label>
            <input
              name="confirm_password"
              type="password"
              ref={register({ required: true })}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="*******"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

UserForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
}

export default forwardRef(UserForm)
