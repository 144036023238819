import React, { useState, useEffect } from "react"
import { authMethods } from "../config/authen/authMethods"

const AuthProvider = ({ children }) => {
  const [errors, setErrors] = useState([])
  const [token, setToken] = useState(null)
  useEffect(
    () => () => {
      setErrors([])
      setToken(null)
    },
    [],
  )
  const handleSignin = async (username, password) => {
    console.log("[AuthContext] กำลังเข้าสู่ระบบ")
    const result = await authMethods.signin(username, password, setErrors, setToken)
    return result
  }
  const handleSignup = async (username, password, email, role) => {
    console.log("[AuthContext]  กำลังลงทะเบียน")
    await authMethods.signup(username, password, email, role, setErrors, setToken)
  }
  const handleSignupByHRMS = async (
    username,
    email,
    password,
    firstname,
    lastname,
    role,
    setSignupError,
    data,
  ) => {
    console.log("[AuthContext]  กำลังลงทะเบียน")
    await authMethods.signupByHRMS(
      username,
      password,
      email,
      firstname,
      lastname,
      role,
      setSignupError,
      setToken,
    )
  }

  const handleSignout = async () => {
    console.log("[AuthContext] กำลังลงชื่อออก")
    await authMethods.signout(setErrors, setToken)
  }
  const handleForgottenPassword = async (email) => {
    console.log("[AuthContext] กำลังยืนยันการลืมรหัสผ่าน")
    await authMethods.signout(email, setErrors, setToken)
  }

  const handleDeleteUser = async (uid) => {
    console.log("[AuthContext] กำลังลบผู้ใช้จาก firebase ")
    await authMethods.deleteUser(uid)
  }
  return (
    <PassportAuth.Provider
      value={{
        token,
        handleSignin,
        handleSignup,
        handleSignupByHRMS,
        handleSignout,
        handleForgottenPassword,
        handleDeleteUser,
        errors,
      }}
    >
      {children}
    </PassportAuth.Provider>
  )
}

export default AuthProvider
export const PassportAuth = React.createContext()
