/* eslint-disable no-underscore-dangle */
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormControl, Button, Select, Input } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import { paymentStatus, paymentStatusArray, TransactionType } from "../../config/constant"
import Confirm from "../common/Alert/ConfirmDialog"
import * as actions from "../../redux/actions"

export default function PricePaymentAmount({
  bookingInfo,
  allPrice,
  handleSubmitMainForm,
  paymentMethod,
  source = "booking", // can be booking and order
}) {
  const { handleSubmit, register: pricePaymentReg } = useForm()
  const dispatch = useDispatch()
  const me = useSelector((state) => state.me)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [paidAmount, setPaidAmount] = useState()
  const [destinationStatus, setDestinationStatus] = useState()
  const [isCustomPaymentOpen, setIsCustomPaymentOpen] = useState(false)
  const { t } = useTranslation()

  const handleChangePaymentState = () => {
    const payload = {
      paymentStatus: destinationStatus,
      payment: { ...bookingInfo?.payment, paid_amount: paidAmount },
      transaction_type: TransactionType.CREATE_PAYMENT.status_code,
      transaction_group: TransactionType.CREATE_PAYMENT.group.status_code,
      price: paidAmount - bookingInfo?.payment?.paid_amount,
      info: `ทำการชำระเงิน จากเดิมชำระไปแล้ว ${
        bookingInfo?.payment?.paid_amount || 0
      } บาท ชำระเพิ่ม ${paidAmount - bookingInfo?.payment?.paid_amount} 
      บาท รวมเป็นรายการนี้ชำระแล้ว ${paidAmount} บาท
      `,
      payment_method: paymentMethod,
      user: me?._id,
    }
    if (source === "booking") {
      dispatch(actions.editOneBooking(bookingInfo?.id, payload)).then(() => {
        handleSubmitMainForm()
      })
    }
    if (source === "order") {
      dispatch(actions.editOneServiceOrder(bookingInfo?.id, payload)).then(() => {
        handleSubmitMainForm()
      })
    }
  }

  const handleCustomPaymentEdit = (data) => {
    const payload = {
      ...data,
      payment: { ...bookingInfo?.payment, paid_amount: data?.payment?.paid_amount },
      transaction_type: TransactionType.CREATE_PAYMENT.status_code,
      transaction_group: TransactionType.CREATE_PAYMENT.group.status_code,
      price: parseFloat(data?.payment?.paid_amount) - bookingInfo?.payment?.paid_amount,
      info: `ทำการชำระเงิน จากเดิมชำระไปแล้ว ${
        bookingInfo?.payment?.paid_amount || 0
      } บาท ชำระเพิ่ม ${parseFloat(data?.payment?.paid_amount) - bookingInfo?.payment?.paid_amount} 
    บาท รวมเป็นรายการนี้ชำระแล้ว ${data?.payment?.paid_amount} บาท
    `,
      user: me?._id,
      payment_method: paymentMethod,
    }

    if (source === "booking") {
      dispatch(actions.editOneBooking(bookingInfo?.id, payload)).then(() => {
        handleSubmitMainForm()
      })
    } else {
      dispatch(actions.editOneServiceOrder(bookingInfo?.id, payload)).then(() => {
        handleSubmitMainForm()
      })
    }
  }

  return (
    <div>
      <Confirm
        title={t("reservation.paymentConfirm")}
        body={`${t("reservation.instructionConfirmPayment1")} ${paidAmount} ${t(
          "reservation.instructionConfirmPayment2",
        )} ${destinationStatus}`}
        handleOnClose={() => {
          setIsConfirmOpen(false)
        }}
        handleSuccess={handleChangePaymentState}
        isOpen={isConfirmOpen}
      />
      <FormControl>
        <label className="text-xs font-bold font-sans">{t("reservation.paymentStatus")}</label>
        <p className="text-sm">{bookingInfo?.paymentStatus} </p>
      </FormControl>
      <div className="flex gap-1 my-2">
        <Button
          size="sm"
          height="20"
          variant="outline"
          colorScheme="blue"
          onClick={() => {
            setPaidAmount(
              bookingInfo?.payment?.paid_amount && bookingInfo?.payment?.paid_amount !== 0
                ? bookingInfo?.payment?.paid_amount
                : parseFloat(allPrice) / 2,
            )

            setDestinationStatus(paymentStatus.deposit.depositConfirm)
            setIsConfirmOpen(true)
            setIsCustomPaymentOpen(false)
          }}
          disabled={
            bookingInfo?.paymentStatus !== paymentStatus.deposit.depositPay &&
            bookingInfo?.paymentStatus !== paymentStatus.deposit.depositWait
          }
        >
          <p className="p-2">
            {" "}
            {t("reservation.payment")} / {t("general.confirm")}
            <br />
            {t("reservation.price.deposit50")}
          </p>
        </Button>
        <Button
          size="sm"
          height="20"
          colorScheme="blue"
          variant="outline"
          onClick={() => {
            setPaidAmount(allPrice)
            setDestinationStatus(paymentStatus.allPayment.allPaymentConfirm)
            setIsConfirmOpen(true)
            setIsCustomPaymentOpen(false)
          }}
          disabled={bookingInfo?.paymentStatus === paymentStatus.allPayment.allPaymentConfirm}
        >
          <p className="p-2"> {t("reservation.price.payFull")} </p>
        </Button>
        <Button
          size="sm"
          height="20"
          onClick={() => setIsCustomPaymentOpen(!isCustomPaymentOpen)}
          variant="outline"
          colorScheme="blue"
        >
          <p className="p-2"> {t("reservation.price.payCustom")} </p>
        </Button>
      </div>

      {isCustomPaymentOpen && (
        <div>
          <div className="border border-gray-400 rounded-lg p-2">
            <form>
              <FormControl>
                <label className="text-sm">{t("reservation.paymentStatus")}</label>
                <Select
                  size="sm"
                  name="paymentStatus"
                  defaultValue={bookingInfo?.paymentStatus}
                  ref={pricePaymentReg}
                >
                  {_.map(paymentStatusArray, (eachStatus, index) => (
                    <option value={eachStatus} key={index}>
                      {eachStatus}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <label className="text-sm">{t("reservation.price.paymentAmount")} </label>
                <Input
                  type="number"
                  size="sm"
                  defaultValue={bookingInfo?.payment?.paid_amount}
                  name="payment.paid_amount"
                  ref={pricePaymentReg}
                />
              </FormControl>
              <div className="flex justify-end mt-2">
                <Button
                  size="sm"
                  colorScheme="green"
                  type="button"
                  onClick={handleSubmit(handleCustomPaymentEdit)}
                >
                  {t("reservation.price.savePayment")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
