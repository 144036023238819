/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import _ from "lodash"
import dayjs from "dayjs"

import Layout from "../../../components/layout/GeneralLayout"
import { ServiceOrderForm } from "../../../components/Forms/Reservation"
import { WhitePanel } from "../../../components/common/Cards"
import * as actions from "../../../redux/actions"
import { MeetingRoomBookingType } from "../../../config/constant"
import CreateOrEditCustomerModal from "../../../components/Modal/CustomerManagement/CreateOrEditCustomerModal"
import { Alert } from "../../../components/common/Alert"
import { MeetingRoomSelector } from "../../../components/Selectors"
import { SpinnerLoading } from "../../../components/Loading"

export default function CreateServiceOrderOnMeetingRoom() {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { register, control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      use_one_day: true,
      start: dayjs().toDate(),
    },
  })
  const meetingRoom = useSelector((state) => state.meetingRoom)
  const customers = useSelector((state) => state.customers)
  const me = useSelector((state) => state.me)
  const [customerOptions, setCustomerOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [modalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedMeetingRooms, setSelectedMeetingRooms] = useState([])
  const [rerender, setRerender] = useState(false)

  useEffect(() => {
    const rowsData = _.map(customers?.arr, (eachRow) => ({
      label: `${eachRow.firstname} ${eachRow.lastname}`,
      value: eachRow.id,
    }))
    setCustomerOptions(rowsData)

    return () => {}
  }, [customers])

  const handleCreateCustomer = (data) => {
    dispatch(actions.createOneCustomer(data))
      .then(() => {
        dispatch(actions.getAllCustomers(1, 1000000, ""))
        setIsModalCreateCustomerOpen(false)
      })
      .catch((err) => {
        window.alert("Create Customer Error", err?.message)
      })
  }

  useEffect(() => {
    dispatch(actions.getAllCustomers(1, 1000000, ""))
    return () => {}
  }, [])

  useEffect(() => {
    setIsLoading(false)
    dispatch(
      actions.getAllMeetingRoom({
        page: 1,
        size: 1000,
        query: {
          start: dayjs(watch("start")).format("YYYY-MM-DD"),
          end: watch("use_one_day") ? "" : dayjs(watch("end")).format("YYYY-MM-DD"),
          fetchOrder: true,
        },
      }),
    )
      .then(() => {
        setIsLoading(true)
        setSelectedMeetingRooms([])
      })
      .catch((err) => {
        setIsError(true)
        setErrorMessage(err?.message)
      })
    return () => {}
  }, [watch("start"), watch("end")])

  const handleCreateServiceOrder = (data) => {
    const payload = {
      start: dayjs(data.start).toISOString(),
      end: data.use_one_day ? "" : dayjs(data.end).toISOString(),
      use_one_day: data.use_one_day,
      remark: data.remark,
      customer: data.customer?.value,
      time_specify: data.time_specify,
      meeting_room_order: _.map(
        _.filter(selectedMeetingRooms, (each) => !(each === null || each === undefined)),
        (eachMeetingRoom) => ({
          ...eachMeetingRoom,
          meeting_room: eachMeetingRoom?.meetingRoom,
          booking_type: eachMeetingRoom?.use_slot_price
            ? MeetingRoomBookingType.SLOT_BOOKING.status_code
            : MeetingRoomBookingType.HOUR_BOOKING.status_code,
        }),
      ),
      name: data?.name || "",
      user: me?._id,
      createMeetingRoomOrder: true,
    }

    console.log("Adding Payload", payload)

    dispatch(actions.createOneServiceOrder(payload))
      .then(() => {
        history.goBack()
      })
      .catch((err) => {
        setErrorMessage(err?.message)
        setIsError(true)
      })
  }

  return (
    <Layout
      categoryTitle={t("reservation.title")}
      title={t("reservation.createMeetingRoomOrder")}
      preview
    >
      <Alert errMessage={errorMessage} isError={isError} handleOnClose={() => setIsError(false)} />
      <div className="flex justify-start px-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          {t("general.back")}
        </Button>
      </div>
      <div className="px-4 my-4">
        <CreateOrEditCustomerModal
          handleCreateCustomer={handleCreateCustomer}
          handleOnClose={() => {
            setIsModalCreateCustomerOpen(false)
          }}
          isOpen={modalCreateCustomerOpen}
        />
        <form onSubmit={handleSubmit(handleCreateServiceOrder)}>
          <WhitePanel>
            <ServiceOrderForm
              control={control}
              register={register}
              watch={watch}
              customers={customerOptions}
              setIsModalCreateCustomerOpen={() => setIsModalCreateCustomerOpen(true)}
              showTitleForMeeting
              showTimeSpecify
              setValue={setValue}
            />
            <div className="my-2">
              {isLoading && (
                <MeetingRoomSelector
                  meetingRoom={meetingRoom}
                  selectedMeetingRooms={selectedMeetingRooms}
                  setSelectedMeetingRooms={setSelectedMeetingRooms}
                  rerender={rerender}
                  setRerender={setRerender}
                  startDate={watch("start")}
                  endDate={watch("end")}
                  isOneDay={watch("use_one_day")}
                />
              )}
              {!isLoading && <SpinnerLoading />}
            </div>
          </WhitePanel>{" "}
          <div className="text-center  gap-0 py-6 fixed bottom-0 px-5 bg-white w-9/12 lg:w-2/4 flex justify-start shadow-lg rounded-md">
            <Button colorScheme="purple" variant="solid" type="submit" isFullWidth>
              จองห้องประชุม
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
