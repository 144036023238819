import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"

import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import EachPageComposite from "../../components/Forms/HotelSite/PageComposites"

export default function EditSecondDescription() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { control, register, handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const info = useSelector((state) => state.information)

  const onSubmit = (data) => {
    const payload = {
      composite: {
        translation: {
          th: {
            ...info?.composite?.translation?.th,
            ...data?.composite?.translation?.th,
          },
          en: {
            ...info?.composite?.translation?.en,
            ...data?.composite?.translation?.en,
          },
        },
      },
    }
    dispatch(actions.editOneInformation(info?.id, payload)).then(() => {
      setIsLoading(false)
      dispatch(actions.getInformation()).then(() => {
        setIsLoading(true)
        history.goBack()
      })
    })
  }

  useEffect(() => {
    dispatch(actions.getInformation()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === false) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="แก้ไขส่วนประกอบส่วนข้อความ">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <EachPageComposite
            defaultInfo={info}
            compositePrefix={"second"}
            title={"ข้อมูลโรงแรมส่วนที่ 2"}
            control={control}
            brief
            description
          />
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
