/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
// components
import { IndexNavbar } from "../components"

// views
import CheckInManagement from "../views/CheckIn/CheckInManagement"
import CreateCheckin from "../views/CheckIn/CreateCheckin"
import CheckinCondition from "../views/CheckIn/CheckinCondition"
import CheckinSuccess from "../views/CheckIn/CheckInSuccess"
import FooterAdmin from "../components/common/Footers/FooterAdmin.js"
import Sidebar from "../components/common/Sidebar/Sidebar-CheckIn"
import * as actions from "../redux/actions"
import AuthorizationCheck from "../views/CheckIn/AuthorizationCheck"

export default function RoomsLayout() {
  const dispatch = useDispatch()
  const [outerLogin, setIsOuterLogin] = useState(true)
  const { t } = useTranslation()
  useEffect(() => {
    console.log("CustomerLayout : ACTIVE")
    // Login User
    if (window.localStorage.eaccom_remember) {
      const storageRemember = JSON.parse(window.localStorage.eaccom_remember) // ข้อมูลสำหรับ authorize จาก  local_storage
      dispatch(actions.meGet(storageRemember.id))
        .then(() => {
          setIsOuterLogin(false) // Direct To Checkin
        })
        .catch((err) => {
          window.alert(err)
          setIsOuterLogin(true) // Go to Recaptcha
        })
    }

    dispatch(actions.systemInfoGet()).then(() => {})
    return () => {}
  }, [])

  useEffect(() => {
    if (window.localStorage.token) {
      dispatch(actions.getInformation())
        .then(() => {
          setIsOuterLogin(false) // Direct To Checkin
        })
        .catch((err) => {
          setIsOuterLogin(true) // Go to Recaptcha
        })
    }

    return () => {}
  }, [outerLogin])

  if (outerLogin) {
    return (
      <div>
        <IndexNavbar />
        <AuthorizationCheck
          setIsOuterLogin={setIsOuterLogin}
          categoryTitle={t("checkin.title")}
          title={t("checkin.authorizationCheck")}
        />
      </div>
    )
  }
  return (
    <div>
      <Sidebar />
      <div className=" relative bg-gray-200 lg:ml-64">
        <div className="px-4 py-4 lg:px-10 mx-auto w-full min-h-screen ">
          <Switch>
            <Route path="/checkIn/" exact component={() => <CheckInManagement />} />
            <Route path="/checkIn/create/:id" exact component={() => <CreateCheckin />} />
            <Route path="/checkIn/condition/:id" exact component={() => <CheckinCondition />} />
            <Route path="/checkIn/success/:id" exact component={() => <CheckinSuccess />} />
            <Redirect from="/checkIn" to="/checkIn" />
          </Switch>
        </div>
        <FooterAdmin />
      </div>
    </div>
  )
}
