import React, { useState } from "react"
import { Textarea, FormControl, Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

const ServiceOrderRemarkPanel = ({ serviceInfo, handleEditServiceOrder }) => {
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit } = useForm()

  const { t } = useTranslation()

  const handleChangeRemark = (data) => {
    // eslint-disable-next-line no-underscore-dangle
    handleEditServiceOrder(serviceInfo?._id, data)
    setIsEditorMode(false)
  }

  if (isEditorMode === false)
    return (
      <div>
        <div className="my-2 w-full lg:w-1/2">
          <h6 className="text-xs font-bold mt-2 font-sans">
            {t("reservation.remark")}{" "}
            <Button
              size="xs"
              className="mx-2"
              leftIcon={<i className="fas fa-pencil" />}
              onClick={() => setIsEditorMode(true)}
            >
              {t("general.edit")}
            </Button>
          </h6>
          <p className="text-sm">{serviceInfo?.remark || "-"} </p>
          <br />
        </div>
        <hr className="my-2" />
      </div>
    )

  return (
    <div>
      <div className="my-2 w-full ">
        <form>
          <FormControl>
            <h6 className="text-xs font-bold mt-2 font-sans">{t("reservation.remark")}</h6>
            <Textarea
              ref={register}
              name="remark"
              defaultValue={serviceInfo?.remark}
              placeholder={t("reservation.reservationRemark")}
              rows={3}
              size="sm"
            ></Textarea>
          </FormControl>
          <div className="flex w-full justify-end gap-1 mt-2">
            <Button
              colorScheme="blue"
              size="sm"
              type="button"
              onClick={handleSubmit(handleChangeRemark)}
            >
              {t("general.save")}
            </Button>
            <Button
              colorScheme="gray"
              size="sm"
              type="button"
              onClick={() => setIsEditorMode(false)}
            >
              {t("general.cancle")}
            </Button>
          </div>
        </form>
        <br />
      </div>
    </div>
  )
}

export default ServiceOrderRemarkPanel
