import React, { forwardRef, useState } from "react"
import { FormLabel, Select, Input, Checkbox } from "@chakra-ui/react"
import { useFieldArray } from "react-hook-form"
import { FrontPageMenuLink } from "../../../../config/constant"
import { Controller } from "react-hook-form"
import { SketchPicker } from "react-color"
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import SliderButtonComponent from "../../../common/Slider/SliderButtonComponent"
import SliderButtonComponentArea from "../../../common/Slider/SliderButtonComponentArea"
import SliderButtonComponentMobile from "../../../common/Slider/SliderButtonComponentMobile"

function Button({ register, control, defaultValue, watch, activeTab }) {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "menu.menuList",
  })

  const [showContent, setShowContent] = useState(true);

  const handleButtonClick = () => {
    setShowContent(!showContent);
  };

  console.log('defaultValue: ', defaultValue)
  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full  px-2 py-1 ">
          <div className="pb-4">
            <div
              className={`border-2  rounded-lg   border-indigo-600 pt-3 pb-3 3xl:mr-0 3xl:ml-0 ml-3 mr-3  lg:mr-40 lg:ml-40 shadow-lg hover:shadow-xl transition-all`}
            >
              <div className="flex justify-center">
                <IconButton
                  type="button"
                  onClick={handleButtonClick}>
                  <div
                    className="text-lg"
                  >
                    Button
                    <KeyboardArrowDown />
                  </div>
                </IconButton>
              </div>

              <div className="flex flex-wrap justify-center justify-items-center ">
                {showContent && (
                  <div>
                    <div className="hover:bg-white">
                      < Input
                        ref={register}
                        placeholder="ชื่อปุ่ม ไทย"
                        // setValue={watch('translation?.th?.button_component_name')}
                        defaultValue={defaultValue?.translation?.th?.button_component_name}
                        name="translation.th.button_component_name"
                        size="sm" />
                      <Input
                        ref={register}
                        placeholder="ชื่อปุ่ม Eng"
                        // setValue={watch('translation?.en?.button_component_name')}
                        defaultValue={defaultValue?.translation?.en?.button_component_name}
                        name="translation.en.button_component_name"
                        size="sm"
                      />
                    </div>
                    <div className="my-2 pl-2 pr-1 pt-3">
                      <div>
                        {activeTab === 'Desktop' && (
                          <div>
                            <div className="font-semibold text-sm my-1 pl-2">ขนาดตัวอักษร</div>
                            <Select
                              size="sm"
                              ref={register}
                              // setValue={watch('desktop?.button_component?.button_text_size')}
                              defaultValue={defaultValue?.desktop?.button_component?.button_text_size}
                              name="desktop.button_component.button_text_size"
                              colorScheme="purple"
                            >
                              <option value="normal">normal</option>
                              <option value="sm">sm</option>
                              <option value="lg">lg</option>
                              <option value="xl">xl</option>
                              <option value="2xl">2xl</option>
                              <option value="3xl">3xl</option>
                              <option value="4xl">4xl</option>
                            </Select>
                          </div>
                        )}
                        {activeTab === 'Mobile' && (
                          <div>
                            <div className="font-semibold text-sm my-1 pl-2">ขนาดตัวอักษร</div>
                            <Select
                              size="sm"
                              ref={register}
                              // setValue={watch('desktop?.button_component?.button_text_size')}
                              defaultValue={defaultValue?.mobile?.button_component?.button_text_size}
                              name="mobile.button_component.button_text_size"
                              colorScheme="purple"
                            >
                              <option value="normal">normal</option>
                              <option value="sm">sm</option>
                              <option value="lg">lg</option>
                              <option value="xl">xl</option>
                              <option value="2xl">2xl</option>
                              <option value="3xl">3xl</option>
                              <option value="4xl">4xl</option>
                            </Select>
                          </div>
                        )}

                        <div className="px-2 py-1">
                          {!watch(`button.useOtherLink`) === true && (
                            <>
                              <div className="text-sm pt-4 pl-2">Link ไปที่</div>
                              <Select
                                size="sm"
                                placeholder="ลิงค์เมนู"
                                name={`button.link`}
                                ref={register}
                              >
                                {_.map(FrontPageMenuLink, (eachlink, linkIndex) => (
                                  <option key={`link-${linkIndex}`} value={eachlink?.status_code}>
                                    {eachlink?.description}
                                  </option>
                                ))}
                              </Select>
                            </>
                          )}
                          <div className="my-1 pb-1 pt-4">
                            <Checkbox
                              ref={register}
                              size="sm"
                              // defaultChecked={eachField?.useOtherLink || false}
                              name={`button.useOtherLink`}
                            >
                              ใช้ลิงค์กำหนดเอง
                            </Checkbox>
                          </div>
                          {watch(`button.useOtherLink`) === true && (
                            <div className="my-2">
                              <div className="text-gray-600 text-sm">
                                กรณีที่เป็นลิงค์ในเว็บไซต์เดียวกัน เริ่มต้นที่ / แล้วต่อด้วยลิงค์ได้เลย
                                ถ้าเป็นลิงค์ภายนอก ให้เริ่มต้นด้วย http:// หรือ https://
                              </div>
                              <div className="text-sm font-semibold">ลิงค์กำหนดเอง</div>
                              <Input
                                size="sm"
                                placeholder="ใส่ลิ้ง"
                                name={`button.otherLink`}
                                // defaultValue={otherLink||''}
                                ref={register}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-4 bg-white pb-4 ">
            <div className="pt-2">
              <h1
                className="text-xl border-2 text-center p-2 pr-40 pl-40 bg-white">
                Preview
              </h1>
            </div>
            <div className="pt-3 pb-4">
              <span className="text-red-600">*** ตัวที่สามารถปรับแยกได้แล้วได้ผลลัพธ์ต่างกันในตัว  Mobile Desktop คือ ความยาว,
                ความกว้าง,ความโค้ง </span> <br />
              <p className="text-green-600">
                อย่างอื่นปรับแล้วได้ผลลัพธ์เหมือนกันทั้งตัว Mobile Desktop
              </p>
            </div>
            {activeTab === 'Desktop' && (
              <div>
                <div className="flex justify-center pt-4">
                  <h1>ตัวช่วยเลือกความยาว ความกว้างและความโค้ง</h1>
                </div>
                <div className="flex justify-center pt-4 pb-4">
                  <SliderButtonComponentArea
                    lengthFormName='desktop.button_component.button_component_length'
                    widthFormName='desktop.button_component.button_component_width'
                    curveFormName='desktop.button_component.button_curve'
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                </div>
                <div>
                  <SliderButtonComponent
                    watch={watch}
                  />
                </div>
              </div>
            )}
            {activeTab === 'Mobile' && (
              <div>
                <div className="flex justify-center pt-4">
                  <h1>ตัวช่วยเลือกความยาว ความกว้างและความโค้ง</h1>
                </div>
                <div className="flex justify-center pt-4 pb-4">
                  <SliderButtonComponentArea
                    lengthFormName='mobile.button_component.button_component_length'
                    widthFormName='mobile.button_component.button_component_width'
                    curveFormName='mobile.button_component.button_curve'
                    register={register}
                    defaultValue={defaultValue}
                    watch={watch}
                  />
                </div>
                <div>
                  <SliderButtonComponentMobile
                    watch={watch}
                  />
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="flex justify-center gap-80">
              <div>
                สีตัวอีกษร
              </div>
              <div>
                สี Button
              </div>
            </div>
            <div className="w-full gap-8 flex justify-center bg-gradient-to-r from-gray-100 to-gray-400 pt-3 pb-7">
              <Controller
                control={control}
                defaultValue={watch('desktop?.button_component?.button_text_color')}
                // defaultValue={defaultValue?.mobile?.video?.button?.button_text_color}
                name="desktop.button_component.button_text_color"
                render={(field) => (
                  <SketchPicker
                    color={field.value}
                    onChangeComplete={(selectedColor) => {
                      field.onChange(selectedColor.hex)
                    }}
                    width={256}
                  />
                )}
              />
              <div>
              </div>
              <Controller
                control={control}
                defaultValue={watch('desktop?.button_component?.button_color')}
                // defaultValue={defaultValue?.mobile?.video?.button?.button_text_color}
                name="desktop.button_component.button_color"
                render={(field) => (
                  <SketchPicker
                    color={field.value}
                    onChangeComplete={(selectedColor) => {
                      field.onChange(selectedColor.hex)
                    }}
                    width={256}
                  />
                )}
              />
            </div >
          </div>
        </div>
      </div>
    </div >
  )
}

export default forwardRef(Button)