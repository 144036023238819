import { bookingAnalysis, arrayBookingAnalysis } from "../../../util"

import {
  BOOKING_ALL,
  BOOKING_CREATE,
  BOOKING_DELETE,
  BOOKING_GET,
  BOOKING_RESET,
  BOOKING_UPDATE,
} from "../../actions/types"

const initialState = {
  bookings: null,
  isLoading: false,
}
export default function BookingReducer(state = initialState, action) {
  switch (action.type) {
    case BOOKING_ALL:
      return {
        arr: arrayBookingAnalysis(action?.payload?.rows),
        total: action?.payload?.total,
        currPage: action.payload?.currPage,
        lastPage: action.payload?.lastPage,
        isLoading: true,
        analysis: action?.payload?.analysis,
      }
    case BOOKING_GET:
      return {
        ...bookingAnalysis(action.payload),
        isLoading: true,
      }
    case BOOKING_CREATE:
      return { isLoading: false, ...action.payload }
    case BOOKING_UPDATE:
      return { isLoading: false }
    case BOOKING_DELETE:
      return { isLoading: false }
    case BOOKING_RESET:
      return { isLoading: false, arr: [] }
    default:
      return state
  }
}
