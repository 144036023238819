/* eslint-disable no-underscore-dangle */
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { header, defaultStyle, footer } from ".."
import { currencyFormatter, dayAmount } from "../../../util"

dayjs.extend(buddhistEra)

export default function BookingReport(
  transactions,
  date,
  isPointDate,
  startDate,
  endDate,
  information,
  systemInfo,
) {
  const allPriceAmount = _.map(
    transactions,
    (eachList) =>
      _.find(eachList?.bookings?.list, (each) => each?.room === eachList?._id)?.price *
      dayAmount(eachList?.bookings?.start, eachList?.bookings?.end),
  )

  const bookingList = _.map(transactions, (eachList) => eachList?.bookings)
  const uniqueBookingList = _.uniqBy(bookingList, "_id")
  const allPaidAmount = _.map(uniqueBookingList, (eachBooking) => eachBooking?.payment?.paid_amount)

  return {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายงานการจอง",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายงานการจอง /Booking Report ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      isPointDate
        ? {
            text: [
              { text: "เข้าพักวันที่", bold: true },
              {
                text: `  ${dayjs(date).format("D MMMM BBBB")}  (วันที่ ${dayjs(date).format(
                  "D MMMM BBBB",
                )} เวลา 12.01 - วันที่ ${dayjs(date)
                  .add(1, "day")
                  .format("D MMMM BBBB")} เวลา 11.59 )`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          }
        : {
            text: [
              { text: "เข้าพักจากวันที่", bold: true },
              {
                text: `  ${dayjs(startDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
              { text: "ถึงวันที่", bold: true },
              {
                text: `  ${dayjs(endDate).format("D MMMM BBBB")}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 2],
          },
      {
        text: [
          { text: "ข้อมูล ณ วันที่", bold: true },
          {
            text: `  ${dayjs().format("D MMMM BBBB")} เวลา ${dayjs().format("HH:mm")} `,
            margin: [5, 0],
          },
        ],
        margin: [0, 10],
      },
      {
        table: {
          headerRows: 1,
          widths: [20, 40, "*", 60, 40, 50, 50, 40, 40, 30, 30, 30, 40, 40, 40, 40],
          body: [
            [
              { text: "ลำดับที่", bold: true },
              { text: "เลขที่การจอง", bold: true },
              { text: "ลูกค้า", bold: true },
              { text: "โทร", bold: true },
              { text: "วันเข้าพัก", bold: true },
              { text: "วันออก", bold: true },
              { text: "ห้องพัก", bold: true },
              { text: "ยังไม่ระบุห้อง", bold: true },
              { text: "จำนวนคืน", bold: true },
              { text: "ราคา", bold: true },
              { text: "ส่วนลด", bold: true },
              { text: "สุทธิ", bold: true },
              { text: "มัดจำ", bold: true },
              { text: "สถานะ", bold: true },
              { text: "บริการ", bold: true },
              { text: "หมายเหตุ", bold: true },
            ],
            ..._.map(transactions, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${eachList?.booking_no || ""}`,
                margin: [0, 2],
              },
              {
                text: `${eachList?.customer?.firstname || "-"} ${
                  eachList?.customer?.lastname || ""
                }`,
              },
              {
                text: eachList?.customer?.tel || "",
              },

              {
                text: `${dayjs(eachList?.start).format("dd D MMM BBBB")}`,
              },
              {
                text: `${dayjs(eachList?.end).format("dd D MMM BBBB")}`,
              },
              {
                text: `${_.map(eachList?.list, (each) => `${each?.room?.name}`)}`,
              },
              {
                text: `${_.map(
                  eachList?.no_room_booking,
                  (each) => `${each?.roomtype?.translation?.th?.name} x ${each?.amount}`,
                )}`,
              },
              {
                text: `${dayAmount(eachList?.start, eachList?.end)} คืน`,
              },
              {
                text: currencyFormatter.format(eachList?.priceBeforeDiscount),
                fontSize: 9,
              },
              {
                text: currencyFormatter.format(eachList?.total_discount),
                fontSize: 9,
              },
              {
                text: currencyFormatter.format(eachList?.price),
                fontSize: 9,
              },
              {
                text: currencyFormatter.format(eachList?.payment?.paid_amount),
                fontSize: 9,
              },
              {
                text: eachList?.status || "",
                fontSize: 9,
              },

              {
                text: `${_.map(
                  eachList?.services,
                  (each) => `${each?.service?.name} x ${each?.amount}`,
                )}`,
                fontSize: 9,
              },
              {
                text: eachList?.remark || "",
                fontSize: 9,
              },
            ]),
            [
              { text: "รวม", colSpan: 9, bold: true },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {},
              {
                text: currencyFormatter.format(
                  _.sum(_.map(transactions, (each) => each?.priceBeforeDiscount)),
                ),
                colSpan: 1,
              },
              {
                text: currencyFormatter.format(
                  _.sum(_.map(transactions, (each) => each?.total_discount)),
                ),
                colSpan: 1,
              },
              {
                text: currencyFormatter.format(
                  _.sum(_.map(transactions, (each) => each?.price || 0)),
                ),
                colSpan: 1,
              },
              {
                text: currencyFormatter.format(
                  _.sum(_.map(transactions, (each) => each?.payment?.paid_amount || 0)),
                ),
                colSpan: 1,
              },
              { text: "", colSpan: 3 },
              {},
              {},
            ],
          ],
          margin: [0, 15, 0, 0],
          fontSize: 10,
        },
      },

      {
        columns: [
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้ตรวจสอบ" },
            ],
          },
          {
            stack: [
              {
                text: "................................................................",
                margin: [0, 2],
              },
              {
                text: "(..........................................................)",
                margin: [0, 2],
              },
              { text: "ผู้จัดการ" },
            ],
          },
        ],
        alignment: "center",
        margin: [0, 40, 0],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
