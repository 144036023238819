import React from "react"

import GridGallery from "./GridGallery"
import HorizontalList from "./HorizontalList"
import Slideshow from "./Slideshow"
import TextContent from "./TextContent"
import VerticalList from "./VerticalList"
import Video from "./Video"

import { FrontpageComponentDisplayType } from "../../../config/constant"

const FrontpageComponentExample = ({ componentType }) => {
  switch (componentType) {
    case FrontpageComponentDisplayType.GRID_GALLERY.status_code:
      return <GridGallery />
    case FrontpageComponentDisplayType.LIST_GALLERY_HORIZONTAL.status_code:
      return <HorizontalList />
    case FrontpageComponentDisplayType.LIST_GALLERY_VERTICAL.status_code:
      return <VerticalList />
    case FrontpageComponentDisplayType.VIDEO.status_code:
      return <Video />
    case FrontpageComponentDisplayType.SLIDESHOW_GALLERY.status_code:
      return <Slideshow />
    case FrontpageComponentDisplayType.RICH_TEXT_CONTENT.status_code:
      return <TextContent />
    default:
      return <div></div>
  }
}

export default FrontpageComponentExample
