import api from "../../../config/api"

import { GALLERY_ALL, GALLERY_GET, GALLERY_PUT, GALLERY_DELETE, GALLERY_CREATE } from "../types"

// faker.locale = "th"

export const getAllGallery = () => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/gallery/`)
    .then((res) => {
      if (res.data) {
        dispatch({ type: GALLERY_ALL, payload: res.data })
      } else {
        dispatch({ type: GALLERY_ALL, payload: null })
      }
    })
    .catch((err) => {
      console.log("Error on Get All Photo in Gallery", err)
      dispatch({ type: GALLERY_ALL, payload: null })
    })
}

export const getGallery = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/gallery/${id}`).then((res) => {
    console.log("Request Server to Get gallery")
    if (res.data) {
      dispatch({ type: GALLERY_GET, payload: res.data })
    } else {
      dispatch({ type: GALLERY_GET, payload: null })
    }
  })
}

export const editGallery = (id, payload) => async (dispatch) => {
  await api.put(`${process.env.REACT_APP_API_URL}/gallery/${id}`, payload).then((res) => {
    console.log("Request Server to edit gallery")
    dispatch({ type: GALLERY_PUT })
  })
}

export const createGallery = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/gallery/`, payload).then((res) => {
    console.log("Request Server to create gallery")
    dispatch({ type: GALLERY_CREATE, payload: res.data })
  })
}

export const deleteOneGallery = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/gallery/${id}`).then((res) => {
    console.log("Request Server to Delete One photo in gallery")
    dispatch({ type: GALLERY_DELETE, payload: null })
  })
}
