import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import _ from "lodash"

// components
import { Button } from "@chakra-ui/react"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import Layout from "../../../components/layout/GeneralLayout"
import { CustomizeSettingForm } from "../../../components/Forms/Management"

export default function CustomizeSetting() {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const systemInfo = useSelector((state) => state.system)
  const { handleSubmit, register, control, setValue } = useForm()

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {})

    return () => {}
  }, [])

  useEffect(() => {
    if (!_.isEmpty(systemInfo?.setting?.timeshifts)) {
      setValue("setting.timeshifts", systemInfo?.setting?.timeshifts)
    }
    setIsLoading(true)
  }, [systemInfo])

  const handleEdit = (data) => {
    const confirm = window.confirm("ยืนยันการเปลี่ยนแปลงข้อมูลการตั้งค่า")
    if (confirm) {
      dispatch(actions.systemInfoPut(data)).then(() => {
        dispatch(actions.systemInfoGet())
        window.alert("แก้ไขข้อมูลเสร็จเรียบร้อย")
      })
    }
  }

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการระบบ" title="ตั้งค่าระบบตามการใช้งาน">
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="w-full p-4 ">
        <form onSubmit={handleSubmit(handleEdit)}>
          <CustomizeSettingForm defaultValue={systemInfo} register={register} control={control} />
          <div className="text-center  gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              บันทีก
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
