import React from "react"

const CurveButtonSlider = ({ register, curveFormName, watch }) => (
  <div>
    <label htmlFor="CurveButtonSlider">ความโค้งพื้นที่การ์ดคอนเท้น:</label>
    <input
      type="range"
      id="CurveButtonSlider"
      defaultValue={0}
      min={0}
      max={100}
      name={curveFormName}
      ref={register}
    />
    <span>{watch(curveFormName)}%</span>
  </div>
)

export default CurveButtonSlider
