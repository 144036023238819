/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useParams, useHistory } from "react-router-dom"

// import { Link } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import { WhitePanel } from "../../components/common/Cards"
import { BookingStatus } from "../../config/constant/BookingStatus"
import * as actions from "../../redux/actions"

export default function CheckinCondition() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const bookings = useSelector((state) => state.bookings)
  const information = useSelector((state) => state.information)
  const servicePriceArray = bookings?.services?.map((eachList) => eachList?.price)

  console.log(servicePriceArray)
  console.log(bookings?.customer?.id)

  const handleCheckIn = async () => {
    try {
      await dispatch(
        actions.submitCheckin(params.id, {
          status: BookingStatus.checkedIn,
        }),
      )
      history.push(`/checkIn/success/${params.id}`)
    } catch (error) {
      alert(
        `เช็คอินไม่สำเร็จ ${
          error?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น"
        }`,
      )
    }
  }

  useEffect(() => {
    const bookingId = params?.id
    dispatch(actions.getOneBooking(bookingId)).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [params])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการการเช็คอิน" title="ยืนยันการเช็คอิน">
      <div className="flex justify-between px-4 gap-2">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="m-4">
        <WhitePanel>
          <div className="min-h-screen ">
            <h2 className="text-xl font-bold">ข้อกำหนดและเงื่อนไข</h2>
            <p>ในการเช็คอินถือว่าท่านได้ยอมรับข้อกำหนดและเงื่อนไขและการเข้าพักของเรา ดังนี้</p>
            {information?.policy?.policyImage && (
              <div className="w-full justify-center flex my-1">
                <img src={information?.policy?.policyImage?.url} className="h-96" />
              </div>
            )}
            {information?.policy?.termAndCondition && (
              <div className="my-4">
                <h3 className="text-lg font-semibold">ข้อกำหนดและนโยบายในการจองและการเข้าพัก</h3>
                <p className=" font-serif my-1">{information?.policy?.termAndCondition}</p>
              </div>
            )}
            {information?.policy?.privacyPolicy && (
              <div className="my-4">
                <h3 className="text-lg font-semibold">นโยบายความเป็นส่วนตัว</h3>
                <p className=" font-serif my-1">{information?.policy?.privacyPolicy}</p>
              </div>
            )}
          </div>
          <div className="my-2">
            <Button isFullWidth colorScheme="purple" onClick={handleCheckIn}>
              ยืนยันการเช็คอิน
            </Button>
          </div>
        </WhitePanel>
      </div>
    </Layout>
  )
}
