/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import _ from "lodash"
import { Switch, Input, Select } from "antd"
import { Controller, useFieldArray } from "react-hook-form"
import Flags from "country-flag-icons/react/3x2"

import { appConfig, api } from "../../../config"
import FacilitySelector from "../../Selectors/FacilitySelector"
import SubTypeForm from "./SubTypeForm"
import { WhitePanel } from "../../common/Cards"

function RoomTypeForm({
  register,
  roomType,
  handleDeletePhoto,
  setIsAddRoomModalOpen,
  rerender,
  setRerender,
  imageOnCreated,
  control,
  watch,
  handleAddToHilight,
}) {
  const { t } = useTranslation()
  const { TextArea } = Input
  const { append, remove, fields } = useFieldArray({ control, name: "combined_type" })
  const [roomTypeList, setRoomTypeList] = useState([])

  useEffect(() => {
    api
      .get(`${process.env.REACT_APP_API_URL}/room-type?page=1&size=${appConfig.maxFetchSize}`)
      .then((res) => {
        setRoomTypeList(res?.data?.rows)
      })
      .catch((err) => {
        console.error("Error on Fetching Rooms", err)
      })

    return () => {}
  }, [])

  return (
    <div className="flex flex-wrap justify-between pb-8">
      <div className="w-full lg:w-6/12  ">
        <div className="">
          <div className="my-3">
            <h6 className="text-gray-900 text-base  font-bold  font-sans ">
              ข้อมูลเบื้องต้น <span className="text-red-400">*</span>
            </h6>
            <small>ข้อมูลเหล่านี้จะไปปรากฏในเว็บไซต์ของท่าน</small>
          </div>
          <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
            <div className="w-full px-4 ">
              <div className="relative w-full mb-3 ">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  ชื่อหมวดหมู่ห้องพัก *
                </label>
                <Controller
                  control={control}
                  name="translation.th.name"
                  defaultValue={roomType?.translation?.th?.name || roomType?.name}
                  render={(field) => (
                    <Input
                      {...field}
                      addonBefore={<Flags.TH title="Thai" className="w-5" />}
                      required
                      placeholder={t("roomtype.roomtypeName")}
                      size="large"
                    />
                  )}
                />

                <label
                  className="block  text-gray-700 text-sm font-semibold my-2"
                  htmlFor="grid-password"
                >
                  {t("general.detail")}
                </label>
                <Controller
                  control={control}
                  name="translation.th.description"
                  defaultValue={roomType?.translation?.th?.description}
                  render={(field) => (
                    <TextArea {...field} rows={4} placeholder={t("general.detail")} />
                  )}
                />

                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2 pt-3"
                  htmlFor="grid-password"
                >
                  {t("general.name2")}
                </label>
                <Controller
                  control={control}
                  name="translation.en.name"
                  defaultValue={roomType?.translation?.en?.name || roomType?.name}
                  render={(field) => (
                    <Input
                      {...field}
                      addonBefore={<Flags.GB title="English" className="w-5" />}
                      placeholder={t("roomtype.roomtypeName")}
                      size="large"
                    />
                  )}
                />
              </div>
            </div>

            <div className="w-full px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2 pt-1"
                  htmlFor="grid-password"
                >
                  {t("general.detail2")}
                </label>
                <Controller
                  control={control}
                  name="translation.en.description"
                  defaultValue={roomType?.translation?.en?.description}
                  render={(field) => (
                    <TextArea {...field} rows={4} placeholder={t("general.detail")} />
                  )}
                />
              </div>
              <div className="relative w-full mb-3">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  {t("roomtype.roomtypeCode")}*
                </label>
                <div className="text-xs mb-6 ">
                  ใช้สำหรับรับข้อมูลการจองจากผู้ให้บริการออนไลน์ภายนอก (OTAs) ควรใช้เป็นรหัสห้องพัก
                  หรือ ใช้เป็นชื่อห้องพักที่อยู่ในระบบ OTA ต่าง ๆ
                </div>
                <Controller
                  control={control}
                  name="type_code"
                  defaultValue={roomType?.type_code}
                  render={(field) => (
                    <Input {...field} required placeholder="รหัสห้องพัก" size="large" />
                  )}
                />
              </div>
            </div>

            <div className="w-full px-4">
              <div className="relative w-full mb-3">
                <label
                  className="block  text-gray-700 text-sm font-semibold mb-2"
                  htmlFor="grid-password"
                >
                  {t("roomtype.roomtypeImage")}
                </label>
                <div className="flex flex-wrap gap-2 overflow-x-auto mb-3">
                  {_.map(roomType?.images, (image, index) => (
                    <div key={index} className="mx-2 rounded-md border border-gray-300">
                      <img src={image?.image?.url} className="w-48" />
                      <div className="my-2 flex gap-2 justify-center">
                        <Button
                          size="sm"
                          colorScheme={image?.hilight ? "yellow" : "gray"}
                          onClick={() => {
                            handleAddToHilight(index)
                          }}
                        >
                          <i className="fas fa-star"></i>
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => {
                            handleDeletePhoto(index)
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                      </div>
                    </div>
                  ))}
                  {_.map(imageOnCreated, (image, index) => (
                    <div key={index} className="mx-2 rounded-md border border-gray-300">
                      <img src={image?.data_url} className="w-48" />
                      <div className="my-2 flex justify-center">
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => {
                            handleDeletePhoto(index)
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                      </div>
                    </div>
                  ))}
                  <div
                    className="h-48 w-48 self-center rounded-lg bg-gray-200 hover:bg-gray-300 cursor-pointer p-4 flex justify-center "
                    onClick={() => setIsAddRoomModalOpen(true)}
                  >
                    <div className="self-center">
                      <i className="fas fa-plus text-3xl text-gray-500" />
                      <h3 className="mt-2 text-gray-500 text-2xl"> {t("general.add")} </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <h6 className="text-gray-900 text-base mt-3 mb-6 font-bold  font-sans ">
          {t("roomtype.capacity")}
        </h6>
        <div className="w-full px-4 bg-white p-4 rounded-lg shadow-lg">
          <div className="flex  gap-2">
            <div className="relative w-full mb-3">
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                {t("roomtype.adult")}
              </label>

              <Controller
                control={control}
                name="capacity.adult"
                defaultValue={roomType?.capacity?.adult}
                render={(field) => (
                  <Input
                    {...field}
                    addonBefore={<i className="fas fa-male"></i>}
                    placeholder="จำนวนผู้ใหญ่"
                    type="number"
                    addonAfter="คน"
                    size="large"
                  />
                )}
              />
            </div>
            <div className="relative w-full mb-3">
              <label
                className="block  text-gray-700 text-sm font-semibold mb-2"
                htmlFor="grid-password"
              >
                {t("roomtype.child")}
              </label>
              <Controller
                control={control}
                name="capacity.child"
                defaultValue={roomType?.capacity?.child}
                render={(field) => (
                  <Input
                    {...field}
                    addonBefore={<i className="fas fa-child"></i>}
                    placeholder="จำนวนเด็ก"
                    type="number"
                    addonAfter="คน"
                    size="large"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-6/12 px-4">
        <div className="flex ">
          <div className="w-1/2">
            <h6 className="text-gray-900 text-base mt-3 mb-3 font-bold  font-sans ">การแสดงผล</h6>
            <div className=" flex items-center gap-2">
              <Controller
                control={control}
                name="enable"
                defaultValue={roomType ? roomType?.enable : true}
                render={(field) => <Switch {...field} defaultChecked={field.value} />}
              />

              <div>แสดงผล</div>
            </div>
          </div>
          <div className="w-1/2">
            <h6 className="text-gray-900 text-base mt-3 mb-3 font-bold  font-sans ">รูปแบบพิเศษ</h6>
            <div className=" flex items-center gap-2">
              <Controller
                control={control}
                name="is_special_type"
                defaultValue={roomType?.is_special_type}
                render={(field) => (
                  <Switch
                    {...field}
                    defaultChecked={field.value}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />

              <div>ตั้งค่าเป็นรูปแบบพิเศษ</div>
            </div>
          </div>
        </div>
        {watch("is_special_type") && (
          <div className="my-4">
            <WhitePanel>
              <div>เป็นการรวมกันระหว่างห้องพักประเภท</div>
              {_.map(fields, (eachField, index) => (
                <div className="my-2" key={eachField.id}>
                  <div className="flex">
                    <div className="w-1/3 px-2">
                      <Controller
                        control={control}
                        name={`combined_type[${index}].room_type`}
                        defaultValue={eachField.room_type}
                        render={(field) => (
                          <Select
                            {...field}
                            onChange={(value) => field.onChange(value)}
                            options={_.map(roomTypeList, (each) => ({
                              ...each,
                              value: each?._id,
                              label: each?.translation?.th?.name || each?.name,
                            }))}
                            placeholder="เลือกประเภทห้องพัก"
                            className="w-full"
                          />
                        )}
                      />
                    </div>
                    <div className="w-1/3 px-2">
                      <Controller
                        control={control}
                        name={`combined_type[${index}].amount`}
                        defaultValue={eachField.amount}
                        render={(field) => (
                          <Input type="number" {...field} placeholder="จำนวนห้องพัก" />
                        )}
                      />
                    </div>
                    <div className="w-1/3 px-2">
                      <Button size="sm" colorScheme="red" onClick={() => remove(index)}>
                        ลบ
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
              <div>
                <Button
                  size="sm"
                  colorScheme="purple"
                  onClick={() => {
                    append({})
                  }}
                  disabled={fields.length >= 1}
                >
                  เพิ่ม
                </Button>
              </div>
            </WhitePanel>
          </div>
        )}

        <h6 className="text-gray-900 text-base mt-3 mb-3 font-bold  font-sans ">
          สิ่งอำนวยความสะดวก
        </h6>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          {roomType && <FacilitySelector control={control} roomType={roomType} />}
        </div>
        <br />

        <h6 className="text-gray-900 text-base mt-3 font-bold  font-sans ">
          {t("roomtype.roomSubtype")}
        </h6>
        <div className="text-xs mb-6 ">{t("roomtype.instructionRoomSubType")}</div>
        <SubTypeForm
          control={control}
          register={register}
          watch={watch}
          rerender={rerender}
          setRerender={setRerender}
        />
      </div>
    </div>
  )
}

export default RoomTypeForm
