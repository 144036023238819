import React from "react"
import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { PaymentMethod } from "../../../config/constant"

export default function DiscountAndPaymentMethodForm({ control }) {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <div className="my-2">
        <Controller
          control={control}
          name="final_discount"
          render={(field) => (
            <FormControl>
              <FormLabel fontSize="sm">{t("reservation.discount")}</FormLabel>
              <Input size="sm" {...field} type="number" placeholder={t("reservation.discount")} />
            </FormControl>
          )}
        />
      </div>
      <div className="my-2">
        <Controller
          control={control}
          name="payment.payment_type"
          render={(field) => (
            <FormControl>
              <FormLabel fontSize="sm">{t("reservation.paymentMethod")}</FormLabel>
              <Select size="sm" {...field} placeholder={t("reservation.paymentMethod")}>
                {_.map(PaymentMethod, (eachPayment, index) => (
                  <option key={index} value={eachPayment?.status_code}>
                    {i18n?.language === "th" ? eachPayment.description : eachPayment.descriptionEN}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
    </div>
  )
}
