import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import _ from "lodash"
import moment from "moment"
import { Button, Table, Thead, Tbody, Tr, Td, Th, Badge } from "@chakra-ui/react"

import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"
import Layout from "../../../components/layout/GeneralLayout"
import MainPaginator from "../../../components/common/Paginator/NewPagination"
import { TransactionType, TransactionGroup } from "../../../config/constant"

export default function SystemTransaction() {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const transaction = useSelector((state) => state.transaction)

  useEffect(() => {
    dispatch(actions.getAllTransaction({ size: 20, page })).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [page])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  return (
    <Layout categoryTitle="จัดการระบบ" title="บันทึกการทำงานของระบบ">
      <div className="flex justify-between px-4">
        <Link to={"/"}>
          <Button colorScheme="purple" variant="outline">
            กลับ
          </Button>
        </Link>
      </div>
      <div className="p-4 w-full">
        <div className="overflow-x-auto">
          <Table colorScheme="facebook" className="whitespace-no-wrap">
            <Thead backgroundColor="white">
              <Tr>
                <Th>#</Th>
                <Th>วันที่</Th>
                <Th>กลุ่ม</Th>
                <Th>กิจกรรม</Th>
                <Th>การจอง</Th>
                <Th>รายละเอียด</Th>
                <Th>พนักงาน</Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.map(transaction?.arr, (eachTransaction, index) => (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>
                    <div className="text-sm">
                      {moment(eachTransaction?.createdAt).format("D MMM YYYY HH:mm")}
                    </div>
                  </Td>
                  <Td>
                    <Badge
                      colorScheme={TransactionGroup?.[eachTransaction?.transaction_group]?.color}
                    >
                      {TransactionGroup?.[eachTransaction?.transaction_group]?.description}
                    </Badge>
                  </Td>
                  <Td>{TransactionType?.[eachTransaction?.transaction_type]?.description}</Td>
                  <Td>
                    {eachTransaction?.booking?.booking_no ||
                      eachTransaction?.service_order?.order_no ||
                      ""}
                  </Td>
                  <Td>{eachTransaction?.info}</Td>
                  <Td>{eachTransaction?.user?.username}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <MainPaginator currentPage={page} lastPage={transaction.lastPage} setPage={setPage} />
        </div>
      </div>
    </Layout>
  )
}
