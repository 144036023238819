import { DateTime } from "luxon"
import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"
import { header, defaultStyle, footer } from ".."

import {
  calculateAllPrice,
  calculatePriceWithoutDiscount,
  calculateBookingListDiscount,
  calculateFinalDiscount,
  dayAmount,
} from "../../../util"

const dateFormat = { day: "numeric", month: "long", year: "numeric" }
dayjs.extend(buddhistEra)

/**
 * @tutorial https://github.com/IARC-Programing/mydev_logistic/blob/main-logistic/frontend/src/views/cmms/history/report/HeadAndTailVehiclePrintPDF.js
 */

export default function TravelItinerary({
  bookingInfo,
  information,
  systemInfo,
  displayReserve = false,
  era,
}) {
  const duration = dayAmount(bookingInfo?.start, bookingInfo?.end)

  const noRoomBookingList = _.filter(
    bookingInfo?.no_room_booking,
    (each) => each?.amount && each?.amount > 0,
  )
  console.log("bookingInfo in reservation", bookingInfo)
  return {
    pageSize: "A4",
    pageOrientation: "portrait",
    pageMargins: [40, 90, 40, 40],
    header: header(information),
    info: {
      title: "รายละเอียดการจอง",
    },
    footer,
    defaultStyle,
    content: [
      {
        text: "รายการการจองห้องพัก / Booking Information ",
        fontSize: 14,
        bold: true,
        margin: [0, 15],
        alignment: "center",
      },
      {
        columns: [
          {
            text: [
              { text: "วันที่ / Date :", bold: true },
              {
                text: ` ${dayjs(bookingInfo?.createdAt).format(
                  era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
                )}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },

          {
            text: [
              { text: "หมายเลขการจอง / Booking Number :", bold: true },
              { text: `  ${bookingInfo?.booking_no}`, margin: [5, 0] },
            ],
            margin: [0, 3],
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        columns: [
          {
            text: [
              { text: "ลูกค้า / Guest :", bold: true },
              {
                text: `  ${bookingInfo?.customer?.firstname}  ${bookingInfo?.customer?.lastname}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
          {
            text: [
              { text: "ผู้ใหญ่ / Adult: ", bold: true },
              {
                text: `  ${bookingInfo?.capacity?.adult || "-"}   `,
                margin: [5, 0],
              },
              { text: "  เด็ก / Child: ", bold: true },
              {
                text: `  ${bookingInfo?.capacity?.child || "-"}   `,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            text: [
              { text: "หมายเลขโทรศัพท์ / Tel :", bold: true },
              {
                text: `  ${bookingInfo?.customer?.tel}`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
          {
            text: [
              { text: "การชำระเงิน / Payment :", bold: true },
              {
                text: `  ${
                  bookingInfo?.payment?.paid_amount > 0
                    ? `มัดจำ ${bookingInfo?.payment?.paid_amount} บาท`
                    : "C/L"
                }`,
                margin: [5, 0],
              },
            ],
            margin: [0, 3],
          },
        ],
      },
      {
        columns: [
          {
            // TODO: Add Agency on Booking
            text: [
              { text: "เอเจนซี่ / Agency :", bold: true },
              {
                text: `  ${bookingInfo?.agency || "-"}`,
                margin: [5, 0],
              },
            ],
          },
          {
            text: [
              { text: "ผู้รับจอง / Issued By :", bold: true },
              {
                text: `  ${bookingInfo?.user?.employee?.firstname || "-"}`,
                margin: [5, 0],
              },
            ],
          },
        ],

        margin: [0, 3],
      },
      {
        text: "รายละเอียดการจอง / Booking Information :",
        bold: true,
        margin: [0, 15, 0, 10],
        fontSize: 12,
      },

      {
        text: [
          { text: "วันที่เช็คอิน - เช็คเอาท์ / Period of Stay : ", bold: true },
          {
            text: `  ${dayjs(bookingInfo?.start).format(
              era === "be" ? "D MMMM BBBB" : "D/MM/YYYY",
            )} - ${dayjs(bookingInfo?.end).format(era === "be" ? "D MMMM BBBB" : "D/MM/YYYY")}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },

      { text: "รายการห้องพัก", bold: true, margin: [0, 15, 0, 0] },
      {
        table: {
          headerRows: 1,
          widths: [20, 120, 50, 70, 70, "*"],
          body: [
            [
              { text: "ที่", bold: true },
              { text: "ประเภทห้องพัก", bold: true },
              { text: "จำนวนคืน", bold: true },
              { text: "ราคาเต็ม", bold: true },
              { text: "ส่วนลด", bold: true },
              { text: "ราคา", bold: true },
            ],
            ..._.map(bookingInfo?.list, (eachList, index) => [
              { text: index + 1, margin: [0, 2] },
              {
                text: `${displayReserve ? `ห้องพัก ${eachList?.room?.name}` : ""} ${
                  eachList?.room?.type?.translation?.th?.name || eachList?.room?.type?.name || ""
                } `,
                margin: [0, 2],
              },
              {
                text: dayAmount(bookingInfo?.start, bookingInfo?.end),
                margin: [0, 2],
              },
              {
                text:
                  parseFloat(eachList.price)
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-",
                margin: [0, 2],
              },
              {
                text:
                  parseFloat(eachList.discount)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-",
                margin: [0, 2],
              },
              {
                text:
                  (
                    (parseFloat(eachList.price) - parseFloat(eachList.discount)) *
                    dayAmount(bookingInfo?.start, bookingInfo?.end)
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-",
                margin: [0, 2],
              },
            ]),
            ..._.map(noRoomBookingList, (eachList, index) => [
              { text: index + 1 + _.size(bookingInfo?.list), margin: [0, 2] },
              {
                text: eachList?.roomtype?.translation?.th?.name || eachList?.roomtype?.name || "",
                margin: [0, 2],
              },
              {
                text: dayAmount(bookingInfo?.start, bookingInfo?.end),
                margin: [0, 2],
              },
              {
                text:
                  parseFloat(eachList.price)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-",
                margin: [0, 2],
              },
              {
                text:
                  parseFloat(eachList.discount)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-",
                margin: [0, 2],
              },
              {
                text:
                  (
                    (parseFloat(eachList.price) - parseFloat(eachList.discount)) *
                    dayAmount(bookingInfo?.start, bookingInfo?.end)
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-",
                margin: [0, 2],
              },
            ]),
            [
              { text: "รวม", bold: true, colSpan: 5 },
              {},
              {},
              {},
              {},
              {
                text:
                  parseFloat(
                    _.sum(_.map(bookingInfo?.list, (eachList) => eachList.price * duration)) +
                      _.sum(
                        _.map(
                          bookingInfo?.no_room_booking,
                          (eachList) => eachList.price * duration || 0,
                        ),
                      ),
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "-",
                bold: true,
                colSpan: 1,
              },
            ],
          ],
        },
        margin: [0, 15, 0, 0],
        fontSize: 10,
      },
      !_.isEmpty(bookingInfo?.services)
        ? { text: "บริการเสริม", bold: true, margin: [0, 15, 0, 0] }
        : {},
      !_.isEmpty(bookingInfo?.services)
        ? {
            table: {
              headerRows: 1,
              widths: [20, "*", "*", 40],
              body: [
                [
                  { text: "ที่", bold: true },
                  { text: "ชื่อบริการ", bold: true },
                  { text: "จำนวน", bold: true },
                  { text: "ราคา", bold: true },
                ],
                ..._.map(bookingInfo?.services, (eachList, index) => [
                  { text: index + 1, margin: [0, 2] },
                  {
                    text: eachList?.service?.translation?.th?.name || eachList?.service?.name || "",
                    margin: [0, 2],
                  },
                  { text: `${eachList?.amount} ชุด`, margin: [0, 2] },
                  { text: `${parseFloat(eachList?.price).toFixed(2)} `, margin: [0, 2] },
                ]),
                [
                  { text: "รวม", bold: true, colSpan: 3 },
                  {},
                  {},
                  {
                    text: parseFloat(
                      _.sum(_.map(bookingInfo?.services, (eachService) => eachService.price)),
                    ).toFixed(2),
                    bold: true,
                    colSpan: 1,
                  },
                ],
              ],
            },
            margin: [0, 15, 0, 0],
            fontSize: 10,
          }
        : {},
      !_.isEmpty(bookingInfo?.fb_orders)
        ? { text: "อาหารและเครื่องดื่ม", bold: true, margin: [0, 15, 0, 0] }
        : {},
      !_.isEmpty(bookingInfo?.fb_orders)
        ? {
            table: {
              headerRows: 1,
              widths: [20, "*", "*", 40],
              body: [
                [
                  { text: "ที่", bold: true },
                  { text: "วันที่", bold: true },
                  { text: "หมายเลขบิล", bold: true },
                  { text: "ราคา", bold: true },
                ],
                ..._.map(bookingInfo?.fb_orders, (eachList, index) => [
                  { text: index + 1, margin: [0, 2] },

                  {
                    text: `${
                      eachList?.date
                        ? dayjs(eachList?.date).format(era === "be" ? "D MMMM BBBB" : "D/MM/YYYY")
                        : ""
                    } `,
                    margin: [0, 2],
                  },
                  {
                    text: eachList?.bill_number,
                    margin: [0, 2],
                  },
                  { text: `${parseFloat(eachList?.price).toFixed(2)} `, margin: [0, 2] },
                ]),
                [
                  { text: "รวม", bold: true, colSpan: 3 },
                  {},
                  {},
                  {
                    text: parseFloat(
                      _.sum(_.map(bookingInfo?.fb_orders, (eachService) => eachService.price)),
                    ).toFixed(2),
                    bold: true,
                    colSpan: 1,
                  },
                ],
              ],
            },
            margin: [0, 15, 0, 0],
            fontSize: 10,
          }
        : {},
      {
        text: [
          { text: "รายละเอียด / Remark", bold: true },
          {
            text: `  ${bookingInfo?.remark || "-"}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
      { text: "สรุปราคา / Price Summary", bold: true, margin: [0, 15, 0, 5] },

      {
        columns: [
          { text: "ราคารวม / Total Price", alignment: "left", bold: false },
          {
            text: `${parseFloat(calculatePriceWithoutDiscount(bookingInfo))
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          {
            text: `ส่วนลดท้ายใบเสร็จ / Discount ${
              bookingInfo?.use_discount_percent ? `(${bookingInfo?.final_discount || 0} %)` : ""
            }`,
            alignment: "left",
            bold: false,
          },
          {
            text: `${parseFloat(calculateFinalDiscount(bookingInfo)).toFixed(2)} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          { text: "ราคาสุทธิ / Grand Total Price", alignment: "left", bold: true },
          {
            text: `${parseFloat(
              calculateAllPrice(bookingInfo) - calculateFinalDiscount(bookingInfo),
            )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      { text: "" },
      {
        columns: [
          { text: "ชำระเงินแล้ว / Paid", alignment: "left", bold: true },
          {
            text: `${parseFloat(bookingInfo?.payment?.paid_amount)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      {
        columns: [
          { text: "ค้างชำระ / Remain", alignment: "left", bold: true },
          {
            text: `${parseFloat(
              calculateAllPrice(bookingInfo) -
                calculateFinalDiscount(bookingInfo) -
                bookingInfo?.payment?.paid_amount,
            )
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} บาท`,
            alignment: "right",
          },
        ],
        margin: [0, 5, 0, 0],
      },
      { text: "การชำระเงิน", bold: true, margin: [0, 15, 0, 5] },
      {
        text: [
          { text: "ช่องทางการชำระเงิน : ", bold: true },
          {
            text: `${bookingInfo?.payment?.payment_type || "-"}${
              bookingInfo?.paymentStatus || "-"
            }  `,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
      {
        text: [
          { text: "เวลาเช็คอินเข้าพัก : ", bold: true },
          {
            text: ` ${information?.policy?.checkinTime || ""}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
      {
        text: [
          { text: "เวลาเช็คเอาท์ : ", bold: true },
          {
            text: ` ${information?.policy?.checkoutTime || ""}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
      {
        text: [
          { text: "นโยบายการยกเลิก : ", bold: true },
          {
            text: `${information?.policy?.canclePolicy || "-"}`,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
      { text: "", margin: [0, 10] },

      { text: "", margin: [0, 5] },
      {
        text: [
          {
            text: `${bookingInfo?.user?.employee?.firstname || ""}  ${
              bookingInfo?.user?.employee?.lastname || ""
            }`,
            margin: [5, 0],
          },
        ],
        margin: [0, 5],
      },
      {
        text: [
          {
            text: "พนักงานผู้รับจอง",
            bold: true,
            margin: [5, 0],
          },
        ],
        margin: [0, 3],
      },
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
