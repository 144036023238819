import React from "react"

const SliderMobiletextpadding = ({
  register,
  width_picture,
  height_picture,
  leftPaddingPicture,
  rightPaddingPicture,
  topPaddingPicture,
  bottomPaddingPicture,
  watch,
}) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">Padding ด้านขวา</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={150}
      name={rightPaddingPicture}
      ref={register}
      className="slider"
    />
    <span>{watch(rightPaddingPicture)}px</span>
    <label className="block text-sm font-medium text-gray-700">Padding ด้านซ้าย</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={150}
      name={leftPaddingPicture}
      ref={register}
      className="slider"
    />
    <span>{watch(leftPaddingPicture)}px</span>
    <label className="block text-sm font-medium text-gray-700">Padding ด้านล่าง</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={150}
      name={bottomPaddingPicture}
      ref={register}
      className="slider"
    />
    <span>{watch(bottomPaddingPicture)}px</span>
    <label className="block text-sm font-medium text-gray-700">Padding ด้านบน</label>
    <input
      type="range"
      defaultValue={0}
      min={0}
      max={150}
      name={topPaddingPicture}
      ref={register}
      className="slider"
    />
    <span>{watch(topPaddingPicture)}px</span>

    {/* <label className="block text-sm font-medium text-gray-700">ความกว้าง:</label>
    <input type="range" defaultValue={386} min={1} max={400} name={width_picture} ref={register} />
    <span>{watch(width_picture)}px</span> */}

    <label className="block text-sm font-medium text-gray-700">ความสูง:</label>
    <input type="range" defaultValue={162} min={1} max={180} name={height_picture} ref={register} />
    <span>{watch(height_picture)}px</span>
    <br />
  </div>
)

export default SliderMobiletextpadding
