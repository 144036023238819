/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"
import dayjs from "dayjs"
import { Table } from "antd"
import _ from "lodash"
import pdfMake from "addthaifont-pdfmake/build/pdfmake"
import "addthaifont-pdfmake/build/vfs_fonts"

import {
  Alert,
  GeneralMainLayout as Layout,
  ReportDateSelector,
  CSVGeneratorPanel,
} from "../../components"
import * as actions from "../../redux/actions"
import { bookingAnalysis, dayAmount } from "../../util"
import { appConfig } from "../../config"
import { RoomReport as RoomReportPDF } from "../../config/pdfmake/template"
import api from "../../config/api"

function RoomReport() {
  const { t } = useTranslation()
  const history = useHistory()
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [date, setDate] = useState(new Date())
  const [page, setPage] = useState(1)
  const size = 10
  const [isLoading, setIsLoading] = useState(false)
  const [orderByField, setOrderByField] = useState("")
  const [orderBy, setOrderBy] = useState("")
  const [excelExportOpen, setExcelExportOpen] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const room = useSelector((state) => state.rooms)
  const information = useSelector((state) => state.information)
  const system = useSelector((state) => state.system)
  const dispatch = useDispatch()

  useEffect(() => {
    setExcelExportOpen(false)
    dispatch(
      actions.getAllRoomsWithBooking({
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
        end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
        unwindBooking: "true",
        page,
        size,
        orderBy,
        orderByField,
      }),
    )
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setIsError(true)
        setErrMessage(err?.message)
      })

    return () => {}
  }, [date, page, orderBy, orderByField, startDate, endDate])

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "เลขที่กาารจอง",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.booking_no,
      sorter: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "bookings",
      render: (text, record) =>
        `  ${record?.bookings?.customer?.firstname} ${record?.bookings?.customer?.lastname}`,
    },
    {
      title: "โทร",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.customer?.tel,
    },
    {
      title: "วันที่ทำการจอง",
      dataIndex: "bookings",
      render: (text, record) => dayjs(record?.bookings?.createdAt).format("D/MM/YY HH:mm"),
    },
    {
      title: "วันที่เข้าพัก",
      dataIndex: "booking_start",
      sorter: true,
      render: (text, record) => `${dayjs(record?.bookings?.start).format("D/MM/YY HH:mm (dd)")}`,
    },
    {
      title: "วันที่ออก",
      dataIndex: "booking_end",
      sorter: true,
      render: (text, record) => `${dayjs(record?.bookings?.end).format("D/MM/YY HH:mm (dd)")}`,
    },
    {
      title: "ห้องพัก",
      dataIndex: "name",
      sorter: true,
      render: (text, record) => record?.name,
    },
    {
      title: "หมวดหมู่ห้อง",
      dataIndex: "type",
      sorter: true,
      render: (text, record) => record?.type?.translation?.th?.name || record?.type?.name,
    },
    {
      title: "จำนวนคืน",
      dataIndex: "bookings",
      render: (text, record) => `${dayAmount(record?.bookings?.start, record?.bookings?.end)} คืน`,
    },
    {
      title: "เรทราคา",
      dataIndex: "bookings",
      render: (text, record) => {
        const founedList = _.find(record?.bookings?.list, (each) => each?.room === record?._id)
        return founedList?.price
      },
    },
    {
      title: "ราคา",
      dataIndex: "bookings",
      render: (text, record) => {
        const founedList = _.find(record?.bookings?.list, (each) => each?.room === record?._id)
        return founedList?.price * dayAmount(record?.bookings?.start, record?.bookings?.end)
      },
    },
    {
      title: "จ่ายล่วงหน้า",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.payment?.paid_amount,
    },
    {
      title: "สถานะ",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.status,
    },
    {
      title: "ผู้จัดทำ",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.user?.employee?.firstname,
    },
    {
      title: "หมายเหตุ",
      dataIndex: "bookings",
      render: (text, record) => record?.bookings?.remark,
    },
  ]

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
    if (sorter?.order === "ascend") {
      setOrderBy("asc")
    } else {
      setOrderBy("desc")
    }

    if (sorter?.field === "name") {
      setOrderByField("name")
    }
    if (sorter?.field === "bookings") {
      setOrderByField("bookings.booking_no")
    }
    if (sorter?.field === "type") {
      setOrderByField("type.name")
    }
    if (sorter?.field === "booking_start") {
      setOrderByField("bookings.start")
    }
    if (sorter?.field === "booking_end") {
      setOrderByField("bookings.end")
    }
  }

  const fullQueryParams = new URLSearchParams({
    page: 1,
    size: appConfig.maxFetchSize,
    unwindBooking: true,
    date: date ? dayjs(date).format("YYYY-MM-DD") : "",
    start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
    end: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
    fetchBooking: true,
    orderBy,
    orderByField,
  })

  const handlePrintPDF = async () => {
    try {
      setIsLoading(false)
      api
        .get(`${process.env.REACT_APP_API_URL}/room/with-booking/?${fullQueryParams.toString()}`)
        .then((res) => {
          const RoomReportPDFDefinition = RoomReportPDF(
            _.map(res?.data?.rows, (each) => ({
              ...each,
              bookings: bookingAnalysis(each.bookings),
            })),
            date,
            startDate,
            endDate,
            date !== null,
            information,
            system,
          )
          console.log("RoomReportPDFDefinition", RoomReportPDFDefinition)
          pdfMake.createPdf(RoomReportPDFDefinition).open()
        })
        .catch((error) => {
          alert(error?.message)
        })
        .finally(() => {
          setIsLoading(true)
        })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Layout categoryTitle={t("history.title")} title={t("history.roomReport")}>
      <Alert
        errMessage={errMessage}
        isError={isError}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage("")
        }}
      />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
        <div className="flex gap-2">
          <Button
            colorScheme="red"
            size="sm"
            leftIcon={<i className="fas fa-file-pdf"></i>}
            onClick={() => handlePrintPDF()}
          >
            PDF
          </Button>
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<i className="fas fa-file-excel"></i>}
            onClick={() => {
              if (excelExportOpen === true) {
                setExcelExportOpen(false)
                setTimeout(() => {
                  setExcelExportOpen(true)
                }, 500)
              } else {
                setExcelExportOpen(true)
              }
            }}
          >
            CSV
          </Button>
        </div>
      </div>
      <div className="p-4 w-full">
        <ReportDateSelector
          pointDate={date}
          setPointDate={setDate}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <CSVGeneratorPanel
          apiSuffix="room/with-booking"
          columns={columns}
          open={excelExportOpen}
          queryParams={fullQueryParams}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={room?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: room?.total,
            }}
          />
        )}
      </div>
    </Layout>
  )
}

export default RoomReport
