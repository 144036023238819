import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import Confirm from "../../common/Alert/ConfirmDialog"

export default function DeleteButton({ orderInfo, handleDeleteOrder }) {
  const { t } = useTranslation()
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const handleDelete = () => {
    handleDeleteOrder(orderInfo?.id)
    setIsConfirmOpen(false)
  }

  return (
    <div>
      <Confirm
        title="ยืนยันการลบการจอง"
        body="ท่านต้องการลบการจองนี้ใช่หรือไม่"
        handleOnClose={() => setIsConfirmOpen(false)}
        handleSuccess={handleDelete}
        isOpen={isConfirmOpen}
      />
      <Button size="sm" colorScheme="red" onClick={() => setIsConfirmOpen(true)}>
        {t("general.delete")}
      </Button>
    </div>
  )
}
