import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { Checkbox } from "@chakra-ui/react"
import _ from "lodash"
import { Controller } from "react-hook-form"
import { Input } from "antd"

import UserForm from "./UserForm"
import ImageUpload from "../../ImageUpload/ImageUpload"

const { TextArea } = Input
const EmployeeForm = ({ register, errors, employee, watch, image, setImage, control }) => (
  <div>
    <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg ">
      <div className="w-full mx-4">
        <div className="my-2">
          <UserForm
            register={register}
            errors={errors}
            showPasswordField={!employee}
            control={control}
            user={employee?.user}
          />
        </div>

        <div className="flex gap-2 py-2 mx-2">
          <div className="relative w-full mb-3 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              ชื่อ
            </label>
            <Controller
              control={control}
              name="firstname"
              defaultValue={employee?.firstname}
              render={(field) => <Input {...field} size="large" placeholder="ชื่อ" />}
            />

            <p className="text-xs text-red-400 mt-2">
              {errors?.firstname && "กรุณาใส่ชื่อผู้ใช้ให้ถูกต้อง"}
            </p>
          </div>
          <div className="relative w-full mb-3 ">
            <label
              className="block  text-gray-700 text-sm font-semibold mb-2"
              htmlFor="grid-password"
            >
              นามสกุล
            </label>
            <Controller
              control={control}
              name="lastname"
              defaultValue={employee?.lastname}
              render={(field) => <Input {...field} size="large" placeholder="นามสกุล" />}
            />
          </div>
        </div>
        <div className="flex gap-2 px-2">
          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm font-semibold mb-2">
              หมายเลขโทรศัพท์
            </label>
            <Controller
              control={control}
              name="tel"
              defaultValue={employee?.tel}
              render={(field) => <Input {...field} size="large" placeholder="Tel" />}
            />
          </div>
        </div>

        <div className="relative w-full mb-3 ">
          <label
            className="block  text-gray-700 text-sm font-semibold mb-2"
            htmlFor="grid-password"
          >
            หมายเหตุ
          </label>
          <Controller
            control={control}
            name="note"
            defaultValue={employee?.note}
            render={(field) => <TextArea rows={3} {...field} size="large" placeholder="หมายเหตุ" />}
          />
        </div>
        <div className="relative w-full mb-3 ">
          <label
            className="block  text-gray-700 text-sm font-semibold mb-2"
            htmlFor="grid-password"
          >
            รูปภาพ
          </label>
          {employee && (
            <Checkbox name="editImage" ref={register}>
              แก้ไขรูปภาพ
            </Checkbox>
          )}

          {(!employee || (employee && watch("editImage") === true)) && (
            <ImageUpload images={image} setImages={setImage} maxNumber={1} />
          )}
        </div>
      </div>
    </div>
  </div>
)

EmployeeForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  employee: PropTypes.object,
  watch: PropTypes.func,
  image: PropTypes.arrayOf(PropTypes.object),
  setImage: PropTypes.func,
}

export default forwardRef(EmployeeForm)
