/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import _ from "lodash"
import { Input, Select, Table, Button as AntdButton } from "antd"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/GeneralLayout"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import Alert from "../../components/common/Alert/AlertDialog"
import * as actions from "../../redux/actions"
import { appConfig } from "../../config"

export default function Article() {
  const articles = useSelector((state) => state.articles)
  const articleCategory = useSelector((state) => state.articleCategories)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [page, setPage] = useState(1)
  const [searchWord, setSearchWord] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")
  const [name, setName] = useState("")
  const [size, setSize] = useState(10)
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchWord)
      setPage(1)
    }, 700)
    return () => clearTimeout(delayDebounceFn)
  }, [searchWord])

  useEffect(() => {
    dispatch(actions.getAllArticle({ name, page, size, articleCategory: selectedCategory || "" }))
      .then(() => {
        setIsLoading(true)
      })
      .catch((err) => {
        setErrMessage(err)
        setIsError(true)
      })
    return () => {}
  }, [page, name, selectedCategory])

  useEffect(() => {
    dispatch(actions.getAllArticleCategory({ page: 1, size: appConfig.maxFetchSize }))

    return () => {}
  }, [])

  if (isLoading === false) {
    return (
      <div>
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        <SpinnerLoading />
      </div>
    )
  }

  const handleDeleteArticle = (articleId) => {
    const confirm = window.confirm("ยืนยันการลบบทความนี้")
    if (confirm) {
      setIsLoading(false)
      dispatch(actions.deleteOneArticle(articleId))
        .then(() => {
          dispatch(actions.getAllArticle({ name, page, size }))
            .then(() => {
              setIsLoading(true)
            })
            .catch((err) => {
              setErrMessage(err)
              setIsError(true)
            })
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
  }

  const onChange = (pagination, filters, sorter, extra) => {
    const newPage = pagination?.current
    const pageSize = pagination?.pageSize
    setSize(pageSize)
    setPage(newPage)
    console.log("params", pagination, filters, sorter, extra)
  }

  const columns = [
    {
      title: "ลำดับที่",
      index: true,
      dataIndex: "index",
      render: (text, record, index) => (page - 1) * size + index + 1,
    },
    {
      title: "ชื่อ",
      dataIndex: "name",
      render: (text, record) => (
        <div>
          {record?.translation?.[i18n.language]?.name}
          <div>
            <Link to={`/site/article/view/${record?.id}`} className=" hover:text-gray-600">
              <AntdButton size="small">รายละเอียด</AntdButton>
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "หมวดหมู่",
      dataIndex: "category",
      render: (text, record) => record?.article_category?.translation?.th?.name || "-",
    },
    {
      title: "แก้ไขล่าสุด",
      dataIndex: "updatedAt",
      render: (text, record) => dayjs(record?.updatedAt).format("DD MMM YYYY HH:mm") || "-",
    },
    {
      title: "รายละเอียด",
      dataIndex: "active",
      render: (text, record) => (
        <div className="flex gap-2">
          <Link to={`/site/article/edit/${record?.id}`}>
            <Button size="sm" colorScheme="yellow">
              แก้ไข
            </Button>
          </Link>
          <Button size="sm" colorScheme="red" onClick={() => handleDeleteArticle(record?.id)}>
            ลบ
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="บทความ">
      <div className="flex justify-between px-4">
        <Link to="/site/article/create">
          <Button colorScheme="purple">เพิ่ม</Button>
        </Link>
        <div className="flex gap-2">
          <Input
            placeholder="input search text"
            addonBefore={<i className="fas fa-search" />}
            allowClear
            onChange={(e) => setSearchWord(e.target.value)}
            style={{
              width: "100%",
            }}
          />
          <Select
            onChange={(value) => {
              console.log("Value", value)
              setSelectedCategory(value)
            }}
            style={{
              width: "100%",
            }}
            placeholder="เลือกหมวดหมู่"
            allowClear
          >
            {_.map(articleCategory?.arr, (eachCategory, index) => (
              <Select.Option key={index} value={eachCategory?.id}>
                {eachCategory?.translation?.[i18n.language]?.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="p-4 w-full overflow-x-auto">
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
        {isLoading && (
          <Table
            columns={columns}
            dataSource={articles?.arr}
            onChange={onChange}
            pagination={{
              current: page,
              pageSize: size,
              total: articles?.total,
            }}
            scroll={{
              x: "true",
            }}
          />
        )}
      </div>
    </Layout>
  )
}
