import ConfirmButton from "./ConfirmButton"
import CheckInButton from "./CheckedInButton"
import CheckedOutButton from "./CheckedOutButton"
import StatusButtonList from "./StatusButtonList"
import ServiceButton from "./ServiceButton"
import PaymentButton from "./PaymentButton"
import SuccessButton from "./SuccessButton"
import DeleteButton from "./DeleteButton"
import ActionButtonList from "./ActionButtonList"
import EditBookingButton from "./EditBookingButton"
import PaymentSlipUploadButton from "./PaymentSlipUploadButton"

export {
  ConfirmButton,
  CheckInButton,
  CheckedOutButton,
  StatusButtonList,
  ServiceButton,
  PaymentButton,
  SuccessButton,
  DeleteButton,
  ActionButtonList,
  EditBookingButton,
  PaymentSlipUploadButton,
}

export default {
  ConfirmButton,
  CheckInButton,
  CheckedOutButton,
  StatusButtonList,
  ServiceButton,
  PaymentButton,
  SuccessButton,
  DeleteButton,
  ActionButtonList,
  EditBookingButton,
  PaymentSlipUploadButton,
}
