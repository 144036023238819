import React from "react"

import Gallery from "./Gallery"

import { DataSource } from "../../../../config/constant"

const FCPDataSourceForm = ({
  componentType,
  register,
  defaultValue,
  watch,
  setValue,
  gallery,
  control,
  activeTab,
}) => {
  const props = { register, defaultValue, watch, setValue, gallery, control, activeTab }
  console.log("activeTap in index", activeTab)
  switch (componentType) {
    case DataSource.GALLERY.status_code:
      return <Gallery {...props} />

    default:
      return <div></div>
  }
}

export default FCPDataSourceForm
