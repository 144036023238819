import React from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import {
  ServiceOrderBasicInfo,
  MeetingRoomOnOrderList,
  ServiceOrderRemarkPanel,
  ServiceDisplayPanel,
  ServiceOrderPricePanel,
  OrderPrintingPanel,
} from "../Panels"

const ServiceOrderContainer = ({ serviceOrderInfo, handleEditServiceOrder = () => {} }) => {
  const { t } = useTranslation()
  const meetingRoomOrder =
    _.sum(_.map(serviceOrderInfo?.meeting_room_orders, (eachOrder) => eachOrder?.price)) || 0

  const servicePrice =
    _.sum(_.map(serviceOrderInfo?.service_list, (eachServices) => eachServices?.price)) || 0
  const fbPrice =
    _.sum(_.map(serviceOrderInfo?.fb_orders, (eachServices) => eachServices?.price)) || 0

  const allPrice = meetingRoomOrder + servicePrice + fbPrice - serviceOrderInfo?.final_discount

  return (
    <div>
      <ServiceOrderBasicInfo serviceOrderInfo={serviceOrderInfo} />
      <OrderPrintingPanel bookingInfo={serviceOrderInfo} noVatRecipt />
      {!_.isEmpty(serviceOrderInfo?.meeting_room_orders) && (
        <MeetingRoomOnOrderList serviceOrder={serviceOrderInfo} showPrice />
      )}
      <div className=" w-full my-2">
        <h6 className="text-xs font-bold font-sans">{t("roomtype.service")}</h6>
        <ServiceDisplayPanel services={serviceOrderInfo?.service_list} />
        <hr />
      </div>
      <ServiceOrderRemarkPanel
        serviceInfo={serviceOrderInfo}
        handleEditServiceOrder={handleEditServiceOrder}
      />
      <ServiceOrderPricePanel
        orderInfo={serviceOrderInfo}
        displayPercentDiscount
        allPrice={allPrice}
        finalDiscount={serviceOrderInfo?.final_discount || 0}
        meetingRoomOrder={serviceOrderInfo?.meeting_room_orders}
        serviceList={serviceOrderInfo?.service_list}
      />
      <hr />
    </div>
  )
}

export default ServiceOrderContainer
