/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button, ButtonGroup } from "@chakra-ui/react"
import FullCalendar from "@fullcalendar/react" // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid" // a plugin!
import { DateTime } from "luxon"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/BookingLayout"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import { generateBookingCalendarEvent } from "../../../util/genrateBookingCalendarEvent"
import ModalBookingInfo from "../../../components/Modal/ReservationManagement/ModalBookingInfo"
import WhitePanel from "../../../components/common/Cards/WhitePanel"
import { Alert } from "../../../components/common/Alert"
import * as actions from "../../../redux/actions"
import { selectRoomTypeColor } from "../../../util/colors"
import { appConfig } from "../../../config"

export default function ReservationCalendar() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const bookings = useSelector((state) => state.bookings)
  const roomTypes = useSelector((state) => state.roomTypes)
  const rooms = useSelector((state) => state.rooms)
  const hotelServices = useSelector((state) => state.hotelService)
  const [selectedRoomType, setSelectedRoomType] = useState("")
  const me = useSelector((state) => state.me)

  const [startDate, setStartDate] = useState(
    DateTime.now().startOf("month").minus({ days: 7 }).toISODate(),
  )
  const [calendarEvents, setCalendarEvents] = useState([])
  const [selectedBooking, setSelectedBooking] = useState()
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  function isMobile() {
    return window.innerWidth <= 800 && window.innerHeight <= 600
  }

  const [showCalendarInfoPanel, setShowCalendarInfoPanel] = useState(!isMobile())
  const calendarRef = useRef()
  const history = useHistory()

  const getAllBookingAndLogs = () => {
    dispatch(
      actions.getAllBookingAndLog({
        start: startDate,
        end: DateTime.fromISO(startDate).plus({ months: 2 }).toISODate(),
        size: appConfig.bookingMaxFetchSize,
        page: 1,
        roomType: selectedRoomType,
      }),
    )
      .then(() => {
        setIsLoading(true)
        setIsModalInfoOpen(false)
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || t("general.unknownError"))
        setIsShowAlert(true)
        setIsModalInfoOpen(false)
      })
  }

  const handleCreateImage = (payload) => {
    dispatch(actions.createOneImage(payload))
      .then(() => {
        getAllBookingAndLogs()
      })
      .catch((err) => {
        setIsShowAlert(true)
        setAlertMessage(err?.message)
      })
  }

  useEffect(() => {
    getAllBookingAndLogs()
    return () => {}
  }, [startDate, selectedRoomType])

  useEffect(() => {
    dispatch(actions.resetBooking())
    dispatch(actions.getAllRoomTypes())
    dispatch(actions.getAllRooms())
    dispatch(actions.getAllServices({ size: appConfig.maxFetchSize, page: 1 }))

    return () => {}
  }, [])

  useEffect(() => {
    if (bookings?.arr) {
      let tempCalendarEvents
      if (window.screen.width >= 768) {
        tempCalendarEvents = generateBookingCalendarEvent(bookings?.arr, "calendar", true)
      } else {
        tempCalendarEvents = generateBookingCalendarEvent(bookings?.arr, "calendar", false)
      }
      setCalendarEvents(tempCalendarEvents)
    }
    return () => {}
  }, [bookings])

  const handleEditBooking = (id, payload) => {
    dispatch(actions.editOneBooking(id, { ...payload, user: me?._id }))
      .then(() => {
        getAllBookingAndLogs()
        dispatch(actions.getCurrentBill())
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || t("general.unknownError"))
        setIsShowAlert(true)
      })
  }

  const handleDeleteBooking = (id) => {
    dispatch(actions.deleteOneBooking(id, { user: me?._id }))
      .then(() => {
        getAllBookingAndLogs()
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || "มีความผิดพลาดเกิดขึ้น")
        setIsShowAlert(true)
      })
  }

  const handleEditLog = (id, payload) => {
    dispatch(actions.editOnelog(id, { ...payload, user: me?._id }))
      .then(() => {
        getAllBookingAndLogs()
        dispatch(actions.getCurrentBill())
      })
      .catch((err) => {
        setAlertMessage(err?.error?.response?.data?.error?.message || t("general.unknownError"))
        setIsShowAlert(true)
      })
  }

  return (
    <Layout categoryTitle={t("reservation.title")} title={t("reservation.reservationCalendar")}>
      <Alert
        errMessage={alertMessage}
        handleOnClose={() => setIsShowAlert(false)}
        isError={isShowAlert}
      />
      <div className="flex flex-wrap justify-between px-4">
        <div className="w-full lg:w-1/2">
          <Link to="/reservation/create">
            <Button colorScheme="purple" variant="solid">
              {t("general.add")}
            </Button>
          </Link>{" "}
        </div>
        <ButtonGroup isAttached size="sm" variant="outline" className="self-center my-2">
          <Button
            onClick={() => {
              history.push("/reservation/list")
            }}
          >
            รายการ
          </Button>{" "}
          <Button
            onClick={() => {
              history.push("/reservation/room")
            }}
          >
            ห้อง
          </Button>
          <Button isActive>ปฏิทิน</Button>
          <Button
            onClick={() => {
              history.push("/reservation/schedule")
            }}
          >
            ตาราง
          </Button>
        </ButtonGroup>
      </div>
      <ModalBookingInfo
        bookingInfo={selectedBooking}
        handleOnClose={() => {
          setSelectedBooking()
          setIsModalInfoOpen(false)
        }}
        isOpen={isModalInfoOpen}
        handleDeleteBooking={handleDeleteBooking}
        handleEditBooking={handleEditBooking}
        handleEditLog={handleEditLog}
        hotelServices={hotelServices}
        rooms={rooms}
        handleCreateImage={handleCreateImage}
      />
      <div className="p-4 w-full">
        <WhitePanel>
          <div className="my-2 flex flex-wrap justify-between ">
            <div className="flex flex-wrap gap-2">
              <h2 className="text-xl font-semibold">
                {DateTime.fromISO(startDate)
                  ?.plus({ weeks: 2 })
                  .setLocale("th")
                  .toFormat("LLLL yyyy", { locale: "th", outputCalendar: "buddhist" })}
              </h2>{" "}
              <Button
                size="sm"
                leftIcon={<i className="fas fa-toggle-on" />}
                onClick={() => {
                  setShowCalendarInfoPanel(!showCalendarInfoPanel)
                }}
              >
                {t("reservation.toggleCalendarInfo")}
              </Button>
            </div>
            <div className="flex my-2">
              <ButtonGroup isAttached colorScheme="gray" size="sm">
                <Button
                  leftIcon={<i className="fas fa-chevron-left" />}
                  onClick={() => {
                    const lastMonth = DateTime.fromISO(startDate).minus({ months: 1 })
                    setStartDate(lastMonth.toISODate())
                    const calendarAPI = calendarRef.current.getApi()
                    calendarAPI.prev()
                  }}
                >
                  {t("general.backward")}
                </Button>
                <Button
                  rightIcon={<i className="fas fa-chevron-right" />}
                  onClick={() => {
                    const lastMonth = DateTime.fromISO(startDate).plus({ months: 1 })
                    setStartDate(lastMonth.toISODate())
                    const calendarAPI = calendarRef.current.getApi()
                    calendarAPI.next()
                  }}
                >
                  {t("general.forward")}
                </Button>
              </ButtonGroup>
            </div>
          </div>
          {showCalendarInfoPanel && (
            <div>
              <div className="grid grid-cols-3 text-sm">
                <div className="flex gap-2">
                  <div className="rounded-full w-4 h-4 self-center bg-purple-600"></div>
                  <div
                    className={`${
                      selectedRoomType === "" ? "text-gray-900 font-bold " : "text-gray-500"
                    } hover:text-gray-900 hover:font-bold cursor-pointer`}
                    onClick={() => {
                      setSelectedRoomType("")
                    }}
                  >
                    {t("general.showAll")}
                  </div>
                </div>
                {_.map(roomTypes?.arr, (eachRoomType, index) => (
                  <div key={index} className="flex gap-2">
                    <div
                      className="rounded-full w-4 h-4 self-center"
                      style={{ backgroundColor: selectRoomTypeColor(eachRoomType?.id) }}
                    ></div>
                    <div
                      onClick={() => {
                        setSelectedRoomType(eachRoomType?.id)
                      }}
                      className={`${
                        selectedRoomType === eachRoomType?.id
                          ? "text-gray-900 font-bold"
                          : selectedRoomType === ""
                          ? "text-gray-800"
                          : "text-gray-500"
                      } hover:text-gray-900 hover:font-bold cursor-pointer`}
                    >
                      {eachRoomType?.translation?.[i18n.language]?.name || eachRoomType?.name}
                    </div>
                  </div>
                ))}
              </div>
              <div className="my-2">
                <div className="font-semibold text-sm">{t("reservation.calendarSymbol")} </div>
                <div className="text-sm">
                  {t("reservation.symbol")} ✅❎ {t("reservation.frontMeaning")}{" "}
                  <b>{t("reservation.depositPayment")}</b> {t("reservation.backMeaning")}{" "}
                  <b>{t("reservation.fullAmountPayment")}</b>{" "}
                </div>
              </div>
            </div>
          )}{" "}
        </WhitePanel>
        {console.log("Events", calendarEvents)}
        {bookings?.isLoading ? (
          <div className=" min-h-screen w-full overflow-x-auto">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              locale="th"
              events={calendarEvents}
              height={1000}
              ref={calendarRef}
              headerToolbar={false}
              eventClassNames="cursor-pointer hover:shadow-md border-none "
              eventClick={(info) => {
                const tempSelectedBooking = _.find(
                  _.union(bookings?.arr),
                  (eachBooking) => eachBooking?.id === info?.event?.id,
                )
                setSelectedBooking(tempSelectedBooking)
                setIsModalInfoOpen(true)
              }}
              eventOrder={["start", "count"]}
            />
          </div>
        ) : (
          <SpinnerLoading />
        )}
      </div>
    </Layout>
  )
}
