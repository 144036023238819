import _ from "lodash"
import dayjs from "dayjs"
import buddhistEra from "dayjs/plugin/buddhistEra"

import { slipHeader, slipDefaultStyle, slipFooter } from ".."
import { currencyFormatter } from "../../../util"
import { MeetingRoomTimeSlot } from "../../constant"

dayjs.extend(buddhistEra)

export default function FBOrderSlip(fbOrder, fbList, information, systemInfo) {
  return {
    // pageSize: "RA4",
    pageSize: {
      width: 200,
      height: 1000,
    },
    pageOrientation: "portrait",
    pageMargins: [10, 70, 10, 100],
    header: slipHeader(information),
    info: {
      title: "ใบรายการอาหาร",
    },
    defaultStyle: slipDefaultStyle,

    content: [
      {
        text: `ใบรายการอาหาร`,
        fontSize: 12,
        bold: true,
        margin: [0, 2],
        alignment: "center",
      },

      {
        text: [
          { text: "เลขที่บิล : ", bold: true },
          {
            text: `${fbOrder?.bill_number}`,
            margin: [5, 0],
          },
        ],
        alignment: "center",
        margin: [0, 3],
      },
      {
        text: [
          { text: "โต๊ะ : ", bold: true },
          {
            text: `${fbOrder?.table?.name}`,
            margin: [5, 0],
          },
        ],
        fontSize: 12,
        alignment: "center",
        margin: [0, 3],
      },
      {
        text: "-------------------------------",
        margin: [0, 2],
        alignment: "center",
      },

      {
        table: {
          headerRows: 1,
          widths: [20, "*", 40],
          body: [
            [
              { text: "No.", bold: true, border: [false, false, false, false] },
              { text: "รายการ", bold: true, border: [false, false, false, false] },
              {
                text: "จำนวน",
                bold: true,
                border: [false, false, false, false],
                alignment: "right",
              },
            ],
            ..._.map(fbList, (eachList, index) => [
              {
                text: `${index + 1}.`,
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: `${eachList?.menu?.translation?.th?.name} ${
                  eachList?.option ? `(${eachList?.option})` : ""
                }${
                  !_.isEmpty(eachList?.toppings)
                    ? `\nเพิ่ม : ${_.map(eachList?.toppings, (each) => each)}`
                    : ""
                }${eachList?.remark ? `\n-${eachList?.remark}` : ""}`,
                margin: [0, 2],
                border: [false, false, false, false],
              },
              {
                text: eachList?.quantity,
                margin: [0, 2],
                alignment: "center",
                border: [false, false, false, false],
              },
            ]),
          ],
        },
        margin: [0, 5, 0, 0],
        fontSize: 10,
      },

      {
        text: "-------------------------------",
        margin: [0, 2],
        alignment: "center",
      },
      {
        text: [
          { text: "วันที่พิมพ์ / Date  ", bold: true },
          {
            text: dayjs()?.format("D MMM YYYY เวลา HH.mm น."),
            margin: [5, 0],
          },
        ],
        alignment: "center",
        margin: [0, 3],
        fontSize: 9,
      },
      ...slipFooter(),
    ],
    images: {
      company_logo: systemInfo?.logo?.url,
    },
  }
}
