import React from "react"
import DayPickerInput from "react-day-picker/DayPickerInput"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../../util"

dayjs.extend(relativeTime)

const ReservationDateSelect = ({
  setCheckedInDate = () => {},
  checkedInDate,
  checkoutDate,
  setSelectCheckoutDate = () => {},
  handleNext = () => {},
}) => {
  const { t } = useTranslation()

  const addDateToStay = (numberToStay) => {
    const tempDate = dayjs(checkedInDate).add(numberToStay, "day").toDate()
    setSelectCheckoutDate(tempDate)
  }

  return (
    <div className="flex flex-wrap justify-between">
      <div className="relative w-full  my-2  mb-3 px-2">
        <label className="block  text-gray-700  font-semibold mb-2" htmlFor="grid-password">
          {t("reservation.checkinDate")}
        </label>
        <div className="my-1 min-w-min-content border round-sm text-xl shadow-md p-2 flex justify-center">
          <DayPickerInput
            onDayChange={setCheckedInDate}
            value={checkedInDate}
            formatDate={(date) => dayjs(date)?.format("D MMM YYYY (ddd)")}
            dayPickerProps={{
              locale: "th",
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
            }}
          />
        </div>
      </div>
      <div className="relative w-full  my-2 lg:w-1/2 mb-3 px-2">
        <label className="block  text-gray-700  font-semibold mb-2" htmlFor="grid-password">
          {t("reservation.checkoutDate")}
        </label>
        <div className="my-1 min-w-min-content border round-sm text-xl shadow-md p-2 ">
          <DayPickerInput
            onDayChange={setSelectCheckoutDate}
            value={checkoutDate}
            formatDate={(date) => dayjs(date)?.format("D MMM YYYY (ddd)")}
            dayPickerProps={{
              locale: "th",
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
            }}
          />
        </div>
      </div>
      <div className="relative w-full  my-2 lg:w-1/2 mb-3 px-2">
        <label className="block  text-gray-700  font-semibold mb-2" htmlFor="grid-password">
          หรือ เลือกจำนวนคืนที่พัก
        </label>
        <div className="flex gap-2">
          <Button size="md" onClick={() => addDateToStay(1)}>
            1 คืน
          </Button>
          <Button size="md" onClick={() => addDateToStay(2)}>
            2 คืน
          </Button>
          <Button size="md" onClick={() => addDateToStay(3)}>
            3 คืน
          </Button>
          <Button size="md" onClick={() => addDateToStay(5)}>
            5 คืน
          </Button>
          <Button size="md" onClick={() => addDateToStay(7)}>
            7 คืน
          </Button>
        </div>
      </div>{" "}
      <div className="relative w-full  my-2  mb-3 px-2 flex justify-center">
        <div>
          <div className="text-center text-lg my-2">
            {" "}
            สรุปแล้วต้องการจองห้องพัก{" "}
            <span className="font-semibold text-2xl mx-2">
              {dayjs(checkoutDate)
                .hour(12)
                .minute(1)
                .diff(dayjs(checkedInDate).hour(12).minute(0), "d")}{" "}
            </span>
            คืน
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReservationDateSelect
