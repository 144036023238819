import React from "react"
import { Controller } from "react-hook-form"
import { Form, Input, Select, Checkbox } from "antd"
import _ from "lodash"
import { useTranslation } from "react-i18next"

function FBListForm({ control, defaultValue, menu, watch, setValue }) {
  const { i18n } = useTranslation()
  return (
    <div className="flex w-full flex-wrap">
      <div className="w-full py-1 px-1">
        <div className="text-sm my-2">เมนูอาหาร</div>
        <Controller
          control={control}
          name="menu"
          defaultValue={defaultValue?.menu}
          render={(field) => (
            <Select
              showSearch
              size="large"
              placeholder="เลือกหมวดหมู่เมนู"
              optionFilterProp="children"
              onChange={(value) => {
                field.onChange(value)
                const foundObject = _.find(menu?.arr, { _id: value })
                setValue("price", foundObject?.price)
              }}
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                (option?.translation?.[i18n.language]?.name ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={_.map(menu?.arr, (eachMenu) => ({
                label:
                  eachMenu?.translation?.[i18n.language]?.name || eachMenu?.translation?.th?.name,
                value: eachMenu?._id,
              }))}
            />
          )}
        />
      </div>
      {_.find(menu?.arr, (each) => each?._id === watch("menu"))?.options && (
        <div className="w-full px-1 py-1">
          <div className="text-sm my-2">ตัวเลือก</div>
          <Controller
            control={control}
            name="option"
            defaultValue={defaultValue?.option}
            render={(field) => (
              <Select
                showSearch
                size="large"
                placeholder="เลือกตัวเลือก"
                optionFilterProp="children"
                onChange={(value) => {
                  field.onChange(value)
                  const optionList = _.find(
                    menu?.arr,
                    (each) => each?._id === watch("menu"),
                  )?.options
                  const foundObject = _.find(optionList, { name: value })
                  setValue("price", foundObject?.price)
                }}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
                }
                options={_.map(
                  _.find(menu?.arr, (each) => each?._id === watch("menu"))?.options,
                  (eachOption) => ({
                    label: eachOption?.name,
                    value: eachOption?.name,
                  }),
                )}
              />
            )}
          />
        </div>
      )}
      {_.find(menu?.arr, (each) => each?._id === watch("menu"))?.toppings && (
        <div className="w-full p-1">
          <div className="my-2 text-sm">เพิ่มเติม</div>
          <Controller
            control={control}
            name="toppings"
            defaultValue={defaultValue?.toppings}
            render={(field) => (
              <Checkbox.Group
                onChange={(value) => {
                  field.onChange(value)
                  const optionList = _.find(
                    menu?.arr,
                    (each) => each?._id === watch("menu"),
                  )?.options
                  let price
                  if (optionList) {
                    const foundObject = _.find(optionList, { name: watch("option") })
                    price = foundObject?.price
                  } else {
                    price = _.find(menu?.arr, (each) => each?._id === watch("menu"))?.price
                  }

                  _.map(value, (eachTopping) => {
                    const foundSelectedTopping = _.find(
                      _.find(menu?.arr, (each) => each?._id === watch("menu"))?.toppings,
                      (each) => each?.name === eachTopping,
                    )
                    price += foundSelectedTopping?.price || 0
                  })
                  setValue("price", price)
                }}
              >
                <div className="flex flex-col">
                  {_.map(
                    _.find(menu?.arr, (each) => each?._id === watch("menu"))?.toppings,
                    (eachTopping, index) => (
                      <Checkbox value={eachTopping?.name} key={index} className="my-1">
                        {eachTopping?.name} ({eachTopping?.price} บาท)
                      </Checkbox>
                    ),
                  )}
                </div>
              </Checkbox.Group>
            )}
          />
        </div>
      )}
      <div className="w-full md:w-1/2 px-1 py-1">
        <div className="text-sm my-2">ราคา/หน่วย</div>
        <Controller
          control={control}
          name="price"
          defaultValue={defaultValue?.price}
          render={(field) => <Input {...field} placeholder="ราคา" />}
        />
      </div>{" "}
      <div className="w-full md:w-1/2 px-1 py-1">
        <div className="text-sm my-2">จำนวน</div>
        <Controller
          control={control}
          name="quantity"
          defaultValue={defaultValue?.quantity || 1}
          render={(field) => <Input type="number" {...field} placeholder="จำนวน" />}
        />
      </div>
    </div>
  )
}

export default FBListForm
