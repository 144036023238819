import React from "react"
import { Input, Form } from "antd"
import { Controller } from "react-hook-form"
import Flags from "country-flag-icons/react/3x2"



const ContactInfo = ({ defaultInfo, control }) => {
  console.log("defaultInfo", defaultInfo)
  const TikTokIcon = ({ color = "#808080" }) => (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="100%"
      height="100%"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  )


  return (
    <div>
      <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg my-3">
        <div className="w-full px-4  lg:w-1/2">
          <h6 className="text-gray-900 text-base  font-bold uppercase font-sans my-2">
            ข้อมูลการติดต่อ
          </h6>
          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm  font-sans mb-2" htmlFor="grid-password">
              หมายเลขโทรศัพท์
            </label>
            <div className="flex gap-2 flex-wrap lg:flex-nowrap">
              <Controller
                control={control}
                name="contract.tel_1"
                defaultValue={defaultInfo?.contract?.tel_1 || ""}
                render={(field) => (
                  <Input
                    {...field}
                    placeholder="หมายเลขโทรศัพท์ หมายเลขที่ 1"
                    addonBefore={<i className="fas fa-phone"></i>}
                  />
                )}
              />
              <Controller
                control={control}
                name="contract.tel_2"
                defaultValue={defaultInfo?.contract?.tel_2 || ""}
                render={(field) => (
                  <Input
                    {...field}
                    placeholder="หมายเลขโทรศัพท์ หมายเลขที่ 2"
                    addonBefore={<i className="fas fa-phone"></i>}
                  />
                )}
              />
            </div>
          </div>
          <div className="relative w-full mb-3 ">
            <label
              className="block uppercase text-gray-700 text-sm  font-sans mb-2"
              htmlFor="grid-password"
            >
              อีเมล
            </label>
            <Controller
              control={control}
              name="contract.email"
              defaultValue={defaultInfo?.contract?.email || ""}
              render={(field) => (
                <Input
                  {...field}
                  type="email"
                  placeholder="อีเมล"
                  addonBefore={<i className="fas fa-envelope"></i>}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full px-4  lg:w-1/2">
          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm  font-sans mb-2" htmlFor="grid-password">
              Facebook
            </label>
            <Controller
              control={control}
              name="contract.facebook"
              defaultValue={defaultInfo?.contract?.facebook || ""}
              render={(field) => (
                <Input
                  {...field}
                  placeholder="Facebook"
                  addonBefore={<i className="fab fa-facebook"></i>}
                />
              )}
            />
          </div>
          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm  font-sans mb-2" htmlFor="grid-password">
              Instagram
            </label>
            <Controller
              control={control}
              name="contract.instagram"
              defaultValue={defaultInfo?.contract?.instagram || ""}
              render={(field) => (
                <Input
                  {...field}
                  placeholder="Instagram"
                  addonBefore={<i className="fab fa-instagram"></i>}
                />
              )}
            />
          </div>
          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm  font-sans mb-2" htmlFor="grid-password">
              Line
            </label>
            <Controller
              control={control}
              name="contract.line"
              defaultValue={defaultInfo?.contract?.line || ""}
              render={(field) => (
                <Input
                  {...field}
                  placeholder="line"
                  addonBefore={<i className="fab fa-line"></i>}
                />
              )}
            />
          </div>
          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm  font-sans mb-2" htmlFor="grid-password">
              Tiktok
            </label>
            <Controller
              control={control}
              name="contract.tiktok"
              defaultValue={defaultInfo?.contract?.tiktok || ""}
              render={(field) => (
                <Input
                  {...field}
                  placeholder="tiktok"
                  addonBefore={<div style={{ width: "17px" }}>
                    <TikTokIcon color="gray-700" />
                  </div>}
                />
              )}
            />
          </div>
          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm  font-sans mb-2" htmlFor="grid-password">
              Youtube
            </label>
            <Controller
              control={control}
              name="contract.youtube"
              defaultValue={defaultInfo?.contract?.youtube || ""}
              render={(field) => (
                <Input
                  {...field}
                  placeholder="youtube"
                  addonBefore={<i className="fab fa-youtube"></i>}
                />
              )}
            />
            <div className="relative w-full mb-3 ">
              <label
                className="block  text-gray-700 text-sm  font-sans mb-2 pt-3"
                htmlFor="grid-password"
              >
                Twitter
              </label>
              <Controller
                control={control}
                name="contract.twitter"
                defaultValue={defaultInfo?.contract?.twitter || ""}
                render={(field) => (
                  <Input
                    {...field}
                    placeholder="twitter"
                    addonBefore={<i className="fab fa-twitter"></i>}
                  />
                )}
              />

              <div className="relative w-full mb-3 ">
                <label
                  className="block  text-gray-700 text-sm  font-sans mb-2  pt-3"
                  htmlFor="grid-password"
                >
                  Whatsapp
                </label>
                <Controller
                  control={control}
                  name="contract.whatsapp"
                  defaultValue={defaultInfo?.contract?.whatsapp || ""}
                  render={(field) => (
                    <Input
                      {...field}
                      placeholder="whatsapp"
                      addonBefore={<i className="fab fa-whatsapp"></i>}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap bg-white p-4 my-3 rounded-lg shadow-lg">
        <div className="w-full px-4 ">
          <h6 className="text-gray-900 text-base my-2 font-bold uppercase font-sans ">
            สถานที่ตั้ง
          </h6>

          <div className="relative w-full mb-3 ">
            <label className="block  text-gray-700 text-sm  mb-2" htmlFor="grid-password">
              ที่อยู่โรงแรมภาษาไทย
            </label>
            <Controller
              name="translation.th.address"
              control={control}
              defaultValue={defaultInfo?.translation?.th?.address || ""}
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.TH title="Thai" className="w-5" />}
                  placeholder="ที่อยู่"
                />
              )}
            />
          </div>

          <div className="relative w-full mb-3 ">
            <label className="pt-2 block  text-gray-700 text-sm  mb-2" htmlFor="grid-password">
              ที่อยู่ ภาษาอังกฤษ
            </label>
            <Controller
              name="translation.en.address"
              control={control}
              defaultValue={defaultInfo?.translation?.en?.address || ""}
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<Flags.GB title="English" className="w-5" />}
                  placeholder="Address"
                />
              )}
            />
          </div>

          <div className="relative w-full mb-3 ">
            <label
              className="block pt-2  text-gray-700 text-sm  font-sans mb-2"
              htmlFor="grid-password"
            >
              คำค้นหา{" "}
              <span>(สำหรับการใช้ในการหา Location ใน Google Map เพื่อนำ Map มาใช้กับระบบ)</span>
            </label>

            <Controller
              name="contract.location.keyword"
              control={control}
              defaultValue={defaultInfo?.contract?.location?.keyword || ""}
              render={(field) => (
                <Input
                  {...field}
                  style={{ width: "100%" }}
                  addonBefore={<i className="fas fa-map-marker"></i>}
                  placeholder="คำค้นหา"
                />
              )}
            />
          </div>
          <div className="relative w-full mb-3 ">
            <div className=" flex gap-2">
              <div className="relative w-full lg:w-1/2 mb-3 ">
                <label
                  className="block pt-2 text-gray-700 text-sm  font-sans mb-2"
                  htmlFor="grid-password"
                >
                  ละติจูด (ใช้ในกรณีไม่ได้ปักหมุดไว้ใน Google Map)
                </label>
                <Controller
                  name="contract.location.LAT"
                  control={control}
                  defaultValue={defaultInfo?.contract?.location?.LAT || ""}
                  render={(field) => (
                    <Input
                      {...field}
                      style={{ width: "100%" }}
                      addonBefore={<i className="fas fa-compass"></i>}
                      placeholder="Latitude"
                    />
                  )}
                />
              </div>
              <div className="relative w-full lg:w-1/2 mb-3 ">
                <label
                  className="block pt-2 text-gray-700 text-sm  font-sans mb-2"
                  htmlFor="grid-password"
                >
                  ลองจิจูด
                </label>
                <Controller
                  name="contract.location.LNG"
                  control={control}
                  defaultValue={defaultInfo?.contract?.location?.LNG || ""}
                  render={(field) => (
                    <Input
                      {...field}
                      style={{ width: "100%" }}
                      addonBefore={<i className="fas fa-compass"></i>}
                      placeholder="Longtitute"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
