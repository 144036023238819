/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Button } from "@chakra-ui/react"
import { Controller, useForm } from "react-hook-form"
import _ from "lodash"
import { Input, Select } from "antd"
import { RichTextEditor } from "../../components/common/RichTextEditor"
import Layout from "../../components/layout/GeneralLayout"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import SpinnerLoading from "../../components/Loading/SpinnerLoading"
import ModalAddPhoto from "../../components/Modal/HotelSites/ModalAddPhotoToFacilityAndPromotion"
import Alert from "../../components/common/Alert/AlertDialog"
import DialogLoading from "../../components/Loading/DialogLoading"
import * as actions from "../../redux/actions"
import { appConfig } from "../../config"

export default function CreateOrEditArticle({ type = "create" }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const currentArticle = useSelector((state) => state.articles)
  const articleCategories = useSelector((state) => state.articleCategories)
  const [uploadedImage, setUploadedImage] = useState([])
  const [isModalAddPhotoOpen, setIsModalAddPhotoOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errMessage, setErrMessage] = useState()
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false)
  const { register, handleSubmit, control } = useForm()
  const { TextArea } = Input
  useEffect(() => {
    if (type === "create") {
      setIsLoading(true)
    }
    return () => {}
  }, [type])

  useEffect(() => {
    dispatch(actions.getAllArticleCategory({ page: 1, size: appConfig.maxFetchSize }))
    return () => {}
  }, [])

  useEffect(() => {
    if (params?.id) {
      dispatch(actions.getOneArticle(params?.id))
        .then(() => {
          setIsLoading(true)
        })
        .catch((err) => {
          setErrMessage(err)
          setIsError(true)
        })
    }
    return () => {}
  }, [params])

  const onCreateArticle = (data) => {
    setIsLoadingDialogOpen(true)
    const payload = {
      ...data,
      images: _.map(uploadedImage, (image) => ({
        image: image.data_url,
      })),
    }
    dispatch(actions.createOneArticle(payload))
      .then(() => {
        setIsLoadingDialogOpen(false)
        history.goBack()
      })
      .catch((err) => {
        setIsLoadingDialogOpen(false)
        setErrMessage(err)
        setIsError(true)
      })
  }

  const onEditArticle = (data) => {
    const payload = {
      ...data,
      images: undefined,
    }
    dispatch(actions.editOneArticle(params?.id, payload))
      .then(() => {
        setIsLoadingDialogOpen(false)
        history.goBack()
      })
      .catch((err) => {
        setIsLoadingDialogOpen(false)
        setErrMessage(err)
        setIsError(true)
      })

    setTimeout(() => {
      setIsLoadingDialogOpen(false)
    }, 300000)
  }

  const isEditMode = type === "edit"

  const handleOnDeleteImage = (imageIndex) => {
    const confirm = window.confirm("ยืนยันการลบรูปภาพนี้")
    if (confirm) {
      const tempGalleryImage = currentArticle.images
      setIsLoading(false)
      console.log(imageIndex)
      tempGalleryImage.splice(imageIndex, 1)
      try {
        dispatch(actions.editOneArticle(currentArticle?.id, { images: tempGalleryImage }))
          .then(() => {
            dispatch(actions.getOneArticle(currentArticle?.id)).then(() => {
              setIsLoading(true)
            })
          })
          .catch((err) => {
            setErrMessage(err)
            setIsError(true)
          })
      } catch (error) {
        setErrMessage("ไม่สามารถลบรูปภาพได้")
        setIsError(true)
        console.error("Cannot Remove Image", error)
        setIsLoading(true)
      }
    }
  }

  if (isLoading === false) {
    return (
      <div>
        <SpinnerLoading />
        <Alert
          isError={isError}
          errMessage={errMessage}
          handleOnClose={() => {
            setIsError(false)
            setErrMessage()
          }}
        />
      </div>
    )
  }

  return (
    <Layout
      categoryTitle="จัดการเว็บไซต์โรงแรม > บทความ"
      title={type === "create" ? "เพิ่มบทความ" : "แก้ไขบทความ"}
    >
      <Alert
        isError={isError}
        errMessage={errMessage}
        handleOnClose={() => {
          setIsError(false)
          setErrMessage()
        }}
      />
      <DialogLoading isLoading={isLoadingDialogOpen} />
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>
      </div>
      <div className="p-4 w-full">
        <div className="flex flex-wrap justify-between pb-8">
          <div className="w-full  ">
            <form onSubmit={handleSubmit(isEditMode ? onEditArticle : onCreateArticle)}>
              <div className="flex flex-wrap bg-white p-4 rounded-lg shadow-lg">
                <div className="w-full px-4 ">
                  <div className="relative w-full mb-3 ">
                    <label className="block uppercase text-gray-700  font-s emibold mb-2 text-lg">
                      ชื่อบทความ <span className="text-red-400">*</span>
                    </label>
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-2"
                      htmlFor="grid-password "
                    >
                      ภาษาไทย *
                    </label>
                    <Controller
                      name="translation.th.name"
                      control={control}
                      defaultValue={isEditMode ? currentArticle?.translation?.th?.name : ""}
                      render={(field) => (
                        <Input {...field} placeholder="ชื่อบทความ" required size="large" />
                      )}
                    />

                    <label className="block  text-gray-700 text-sm font-semibold mb-2 pt-3">
                      ภาษาอังกฤษ
                    </label>
                    <Controller
                      name="translation.en.name"
                      control={control}
                      defaultValue={isEditMode ? currentArticle?.translation?.en?.name : ""}
                      render={(field) => <Input {...field} placeholder="ชื่อบทความ" size="large" />}
                    />
                  </div>

                  <div>
                    <label className="block  text-gray-700 text-sm font-semibold ">หมวดหมู่</label>
                    <Controller
                      control={control}
                      name="article_category"
                      defaultValue={isEditMode ? currentArticle?.article_category?._id : ""}
                      render={(field) => (
                        <Select
                          {...field}
                          placeholder={"หมวดหมู่บทความ"}
                          className="my-2"
                          size="large"
                          style={{
                            width: "100%",
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? "").includes(input)}
                          showSearch
                          options={_.map(articleCategories?.arr, (eachCategory) => ({
                            value: eachCategory?._id,
                            label: `${eachCategory?.translation?.th?.name} - ${
                              eachCategory?.translation?.en?.name || ""
                            }`,
                          }))}
                        ></Select>
                      )}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700  font-semibold mb-2 text-lg pt-3">
                      เนื้อหาบนบทความ
                    </label>
                    <label
                      className="block  text-gray-700 text-sm font-semibold mb-3 pt-2"
                      htmlFor="grid-password "
                    >
                      ภาษาไทย
                    </label>
                    <Controller
                      control={control}
                      name="translation.th.description"
                      defaultValue={isEditMode ? currentArticle?.translation?.th?.description : ""}
                      render={(field) => (
                        <RichTextEditor placeholder="รายละเอียด" {...field} rows={10} />
                      )}
                    />

                    <label className="block  text-gray-700 text-sm font-semibold mb-3 pt-3">
                      ภาษาอังกฤษ
                    </label>
                    <Controller
                      control={control}
                      name="translation.en.description"
                      defaultValue={isEditMode ? currentArticle?.translation?.en?.description : ""}
                      render={(field) => (
                        <RichTextEditor placeholder="รายละเอียด" {...field} rows={10} />
                      )}
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-gray-700 text-l font-semibold mb-2 pt-2">
                      รูปภาพบนบทความ
                    </label>
                    {isEditMode ? (
                      <div className="flex flex-wrap">
                        <ModalAddPhoto
                          defaultValueArray={currentArticle}
                          handleOnClose={() => {
                            setIsModalAddPhotoOpen(false)
                          }}
                          isOpen={isModalAddPhotoOpen}
                          itemId={currentArticle?.id}
                          type="article"
                        />
                        {_.map(currentArticle?.images, (eachImage, index) => (
                          <div className="m-4 border border-gray-400 rounded-md" key={index}>
                            <img src={eachImage?.image?.url} className="h-64" />
                            <div className="flex my-2 justify-center">
                              <Button
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleOnDeleteImage(index)}
                              >
                                ลบ
                              </Button>
                            </div>
                          </div>
                        ))}
                        <div
                          className="h-64 w-64 self-center rounded-lg bg-gray-200 hover:bg-gray-300 cursor-pointer p-4 flex justify-center "
                          onClick={() => setIsModalAddPhotoOpen(true)}
                        >
                          <div className="self-center">
                            <i className="fas fa-plus text-3xl text-gray-500" />
                            <h3 className="mt-2 text-gray-500 text-2xl">เพิ่ม</h3>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center">
                        <ImageUpload
                          images={uploadedImage}
                          setImages={setUploadedImage}
                          preview_size="250"
                          maxNumber={5}
                        />
                      </div>
                    )}
                  </div>
                  <br />

                  <div className="text-center  gap-0 py-3 ">
                    <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                      {isEditMode ? "บันทึก" : "เพิ่ม"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}
