import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/layout/GeneralLayout"
import { WhitePanel, ServiceForm } from "../../../components"
import SpinnerLoading from "../../../components/Loading/SpinnerLoading"
import * as actions from "../../../redux/actions"

export default function CreateService() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const roomTypes = useSelector((state) => state.roomTypes)
  const { handleSubmit, control, register, setValue, watch } = useForm({})
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(actions.getAllRoomTypes({ name: "", page: 1, size: 1000 })).then(() => {
      setIsLoading(true)
    })

    return () => {}
  }, [])

  if (!isLoading) {
    return <SpinnerLoading />
  }

  const handleCreateService = (data) => {
    dispatch(actions.createOneService(data)).then(() => {
      history.goBack()
    })
  }

  return (
    <Layout
      categoryTitle={`${t("roomtype.title")} > ${t("roomtype.service")}`}
      title={"สร้างบริการ"}
    >
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(handleCreateService)}>
          <div className="  gap-0 py-3 ">
            <ServiceForm
              control={control}
              register={register}
              setValue={setValue}
              watch={watch}
              roomtypes={roomTypes?.arr}
            />
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              {t("general.save")}
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
