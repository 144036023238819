export const HOTEL_SITE_MENU = {
  moduleNameTH: "จัดการข้อมูลและเว็บไซต์โรงแรม",
  moduleNameEN: "Hotel Info & Website Management",
  moduleCode: "site",
  menuList: [
    {
      link: "",
      icon: "fas fa-hotel ",
      nameTH: "ตั้งค่าทั่วไป",
      nameEN: "General Info",
    },
    {
      link: "",
      icon: "",
      nameTH: "ข้อมูลทั่วไป",
      nameEN: "General Info",
      submenu: true,
    },
    {
      link: "/policy",
      nameTH: "นโยบายโรงแรม",
      nameEN: "Hotel Policy",
      submenu: true,
    },
    {
      link: "/payment",
      icon: "",
      nameTH: "การชำระเงิน",
      nameEN: "Payment",
      submenu: true,
    },
    {
      link: "/seo",
      icon: "",
      nameTH: "SEO",
      nameEN: "SEO",
      submenu: true,
    },
    {
      link: "/header",
      icon: "fas fa-magic ",
      nameTH: "ส่วนประกอบ",
      nameEN: "General Info",
    },
    {
      link: "/header",
      nameTH: "ส่วนหัว (Header)",
      nameEN: "Header",
      submenu: true,
    },
    {
      link: "/footer",
      nameTH: "ส่วนท้าย (Footer)",
      nameEN: "Footer",
      submenu: true,
    },
    {
      link: "/menu",
      nameTH: "เมนูหลัก",
      nameEN: "Main Menu",
      submenu: true,
    },
    {
      link: "/frontpage-management",
      nameTH: "หน้าแรกเว็บไซต์  ",
      nameEN: "Frontpage ",
      submenu: true,
    },

    {
      link: "/article",
      icon: "fa fa-list-alt",
      nameTH: "คอนเทนต์",
      nameEN: "Content",
    },
    {
      link: "/article",
      nameTH: "บทความ",
      nameEN: "Article",
      submenu: true,
    },
    {
      link: "/article-category",
      nameTH: "หมวดหมู่บทความ",
      nameEN: "Article Category",
      submenu: true,
    },

    {
      link: "/promotion",
      nameTH: "โปรโมชั่น",
      nameEN: "Promotion",
      submenu: true,
    },
    {
      link: "/facility",
      nameTH: "สิ่งอำนวยความสะดวก",
      nameEN: "Facility",
      submenu: true,
    },
    // {
    //   link: "/background",
    //   nameTH: "ภาพพื้นหลัง",
    //   nameEN: "background image",
    //   submenu: true,
    // },
    {
      link: "/gallery",
      nameTH: "แกลอรี่",
      nameEN: "Gallery",
      submenu: true,
    },
    // {
    //   link: "/file",
    //   nameTH: "ไฟล์",
    //   nameEN: "Files",
    //   submenu: true,
    // },
    {
      link: "/look-and-feel",
      icon: "fas fa-book",
      nameTH: "หน้าตา สี และแบบอักษร",
      nameEN: "Look and Feel",
    },
    {
      link: "/update-frontpage",
      icon: "fas fa-chevron-up",
      nameTH: "อัพเดทหน้าเว็บไซต์",
      nameEN: "Website Update",
    },
  ],
}

export const FOOD_BEVERAGE_MENU = {
  moduleNameTH: "อาหารและเครื่องดื่ม",
  moduleNameEN: "Food and Beverage",
  moduleCode: "food-beverage",
  menuList: [
    {
      link: "/order",
      icon: "fas fa-hotel ",
      nameTH: "ออเดอร์อาหารและเครื่องดื่ม",
      nameEN: "Food and Beverage Order",
    },
    {
      link: "/list",
      icon: "fas fa-list ",
      nameTH: "ลิสต์รายการสั่งอาหาร",
      nameEN: "Order List",
    },
    {
      link: "/menu",
      icon: "fas fa-wrench",
      nameTH: "ข้อมูลตั้งต้น",
      nameEN: "Initial Data",
    },
    {
      link: "/menu/",
      icon: "",
      nameTH: "เมนูอาหาร",
      nameEN: "Menu",
      submenu: true,
    },
    {
      link: "/menu-category",
      icon: "",
      nameTH: "หมวดหมู่เมนูอาหาร",
      nameEN: "Menu Category",
      submenu: true,
    },
    {
      link: "/table/",
      icon: "",
      nameTH: "โต๊ะอาหาร",
      nameEN: "Table",
      submenu: true,
    },
  ],
}

export const RESERVATION_MENU = {
  moduleNameTH: "จัดการการจอง",
  moduleNameEN: "Reservation Mangement",
  moduleCode: "reservation",
  menuList: [
    {
      link: "/room/",
      icon: "fas fa-clipboard-list",
      nameTH: "การจองปัจจุบัน",
      nameEN: "Current Reservation",
    },
    {
      link: "/room/",
      icon: "",
      nameTH: "มุมมองห้อง",
      nameEN: "Room View ",
      submenu: true,
    },
    {
      link: "/list",
      icon: "",
      nameTH: "มุมมองรายการ",
      nameEN: "List View",
      submenu: true,
    },
    {
      link: "/schedule",
      icon: "",
      nameTH: "มุมมองตาราง",
      nameEN: "Schedule Vuew",
      submenu: true,
    },
    {
      link: "/calendar",
      icon: "",
      nameTH: "มุมมองปฏิทิน",
      nameEN: "Calendar View",
      submenu: true,
    },
    {
      link: "/create",
      icon: "fas fa-calendar-plus",
      nameTH: "สร้างการจอง",
      nameEN: "Create Reservation",
    },
    {
      link: "/history",
      icon: "fas fa-clock  ",
      nameTH: "ประวัติ",
      nameEN: "History",
    },
    {
      link: "/history",
      icon: "",
      nameTH: "รายการประวัติ",
      nameEN: "Reservation List",
      submenu: true,
    },

    {
      link: "/monthy-forecast",
      icon: "",
      nameTH: "พยากรณ์การจองรายเดือน",
      nameEN: "Monthy Forecast",
      submenu: true,
    },
    {
      link: "/roomtype",
      icon: "",
      nameTH: "สรุปตามรูปแบบ",
      nameEN: "Reservation Summary",
      submenu: true,
    },
    {
      link: "/bill",
      icon: "",
      nameTH: "รอบบิล",
      nameEN: "Bill",
      submenu: true,
    },
    {
      link: "/service-orders/",
      icon: "fas fa-ticket-alt",
      nameTH: "จัดการออเดอร์บริการ",
      nameEN: "Service Order",
    },
    {
      link: "/meeting-room-orders/room",
      icon: "",
      submenu: true,
      nameTH: "จองห้องประชุมรายห้อง",
      nameEN: "Room View Meeting Room Reservation",
    },
    {
      link: "/meeting-room-orders",
      icon: "",
      submenu: true,
      nameTH: "ลิสต์การจองห้องประชุม",
      nameEN: "Meeting Room Order",
    },
  ],
}

export const HISTORY_MENU = {
  moduleNameTH: "รายงานและประวัติ",
  moduleNameEN: "Report and History",
  moduleCode: "history",
  menuList: [
    {
      link: "",
      icon: "fas fa-clipboard-list  ",
      nameTH: "แดชบอร์ด",
      nameEN: "Dashboard",
    },
    {
      link: "/management",
      icon: "fas fa-clipboard-list  ",
      nameTH: "ประวัติการจอง",
      nameEN: "Reservation History",
    },
    {
      link: "/booking",
      icon: "fas fa-calendar-alt",
      nameTH: "รายงานการจอง (Booking Report)",
      nameEN: "Booking Report",
    },

    {
      link: "/checkin-report",
      icon: "fas fa-sign-in-alt",
      nameTH: "รายงานเช็คอิน (Checkin Report)",
      nameEN: "CheckIn Report",
    },

    {
      link: "/checkout-report",
      icon: "fas fa-sign-out-alt",
      nameTH: "รายงานเช็คเอาท์ (Checkout Report and Expected Departure)",
      nameEN: "Checkout Report",
    },
    {
      link: "/inhouse-guest",
      icon: "fas fa-users",
      nameTH: "รายงานแขกเข้าพัก (Inhouse Guese)",
      nameEN: "Inhouse Guest",
    },
    {
      link: "/incoming",
      icon: "fas fa-users",
      nameTH: "รายงานการเข้ามาในแต่ละวัน (Expected Arrival) ",
      nameEN: "Incoming Report",
    },
    {
      link: "/payment-transaction",
      icon: "fas fa-dollar-sign",
      nameTH: "รายการการชำระเงิน",
      nameEN: "Payment Transaction",
    },
    {
      link: "/bill",
      icon: "fas fa-dollar-sign",
      nameTH: "รายงานรอบบิล",
      nameEN: "Bill Report",
    },
    // {
    //   link: "/daily-payment-summary",
    //   icon: "fas fa-money-bill-wave-alt",
    //   nameTH: "รายงานยอดเงินแต่ละวัน",
    //   nameEN: "Daily Payment Summary",
    // },
    {
      link: "/daily-roomtype",
      icon: "fas fa-door-open",
      nameTH: "รายงานสรุปการจองของแต่ละรูปแบบ",
      nameEN: "Reservation Summary",
    },
    {
      link: "/daily-room/",
      icon: "fas fa-door-open",
      nameTH: "รายงานสรุปการจองของแต่ละห้อง",
      nameEN: "Room Reservation Summary",
    },
    {
      link: "/monthy-forecast",
      icon: "fas fa-calendar-alt",
      nameTH: "รายงานพยากรณ์การจองรายเดือน",
      nameEN: "Monthy Forecast",
    },
    {
      link: "/housekeeper",
      icon: "fas fa-broom",
      nameTH: "รายงานสำหรับแม้บ้าน",
      nameEN: "Housekeeper Report",
    },
    {
      link: "/food-beverage",
      icon: "fas fa-utensils",
      nameTH: "รายงานอาหารและเครื่องดื่ม",
      nameEN: "Food and Beverage Report",
    },
    {
      link: "/menu",
      icon: "fas fa-clipboard-list",
      nameTH: "รายงานเมนูอาหาร",
      nameEN: "Menu Report",
    },
  ],
}

export const SYSTEM_MENU = {
  moduleNameTH: "การจัดการระบบ",
  moduleNameEN: "System Management",
  moduleCode: "system",
  menuList: [
    {
      link: "/info",
      icon: "fas fa-layer-group ",
      nameTH: "จัดการข้อมูลระบบ",
      nameEN: "System Info Setting",
    },
    {
      link: "/online-booking",
      icon: "fas fa-globe-asia",
      nameTH: "การจองผ่านหน้าเว็บ",
      nameEN: "Online Booking",
    },
    {
      link: "/system-customize",
      icon: "fas fa-cog",
      nameTH: "ตั้งค่าระบบตามการใช้งาน",
      nameEN: "System Customization",
    },
    {
      link: "/development-portal",
      icon: "fas fa-box-open ",
      nameTH: "สำหรับนักพัฒนาระบบ",
      nameEN: "Development Portal",
    },
    {
      link: "/notify",
      icon: "fas fa-bell ",
      nameTH: "ตั้งค่าการแจ้งเตือน",
      nameEN: "Notify Setting",
    },
    {
      link: "/employee",
      icon: "fas  fa-address-book ",
      nameTH: "จัดการพนักงาน",
      nameEN: "Employee Setting",
    },
    // {
    //   link: "/users",
    //   icon: "fas  fa-address-book ",
    //   nameTH: "ตั้งค่าผู้ใช้งาน และการล็อกอิน",
    //   nameEN: "User and Login Setting",
    // },
    {
      link: "/transaction",
      icon: "fas fa-bookmark",
      nameTH: "บันทึกการใช้งาน",
      nameEN: "System Transaction",
    },
  ],
}

export const CUSTOMER_RELATION_MENU = {
  moduleNameTH: "จัดการลูกค้าสัมพันธ์",
  moduleNameEN: "Customer Relationship Management",
  moduleCode: "crm",
  menuList: [
    {
      link: "/",
      icon: "fas fa-users",
      nameTH: "จัดการลูกค้า",
      nameEN: "Customer Management",
    },
    {
      link: "/create",
      icon: "fas fa-user-plus",
      nameTH: "เพิ่มลูกค้า",
      nameEN: "Create User",
    },
  ],
}

export const ROOMTYPE_MENU = {
  moduleNameTH: "จัดการห้องพักและบริการ",
  moduleNameEN: "Room Management",
  moduleCode: "roomtypes",
  menuList: [
    {
      link: "",
      icon: "fas fa-hotel",
      nameTH: "หมวดหมู่ของห้องพัก",
      nameEN: "Roomtypes",
    },
    {
      link: "/rooms",
      icon: "fas fa-door-closed",
      nameTH: "ห้องพัก",
      nameEN: "Rooms",
    },
    {
      link: "/service",
      icon: "fas fa-concierge-bell",
      nameTH: "บริการ",
      nameEN: "Service",
    },
    {
      link: "/meeting-room",
      icon: "fas fa-door-open",
      nameTH: "ห้องประชุม",
      nameEN: "Meeting Room",
    },
  ],
}

export const SUPER_SYSTEM_MENU = {
  moduleNameTH: "การจัดการระบบ",
  moduleNameEN: "System Management",
  moduleCode: "super-system",
  menuList: [
    {
      link: "/module",
      icon: "fas fa-layer-group ",
      nameTH: "จัดการโมดูลระบบ",
      nameEN: "System Module Setting",
    },
    {
      link: "/outerservice",
      icon: "fas fa-box-open",
      nameTH: "จัดการการเชื่อมต่อซอฟต์แวร์ภายนอก",
      nameEN: "Outer Service Management",
    },
    {
      link: "/sandbox",
      icon: "fas fa-box-open ",
      nameTH: "Sandbox",
      nameEN: "Sandbox",
    },
  ],
}
export const INVENTORY_MENU = {
  moduleNameTH: "วัตถุดิบและการจัดซื้อ",
  moduleNameEN: "Material Inventory and Purchase",
  moduleCode: "inventory",
  menuList: [
    {
      link: "/",
      icon: "fas fa-hotel",
      nameTH: "รายการสั่งซื้อวัตถุดิบ",
      nameEN: "Purchase Order",
    },
    {
      link: "/material",
      icon: "fas fa-door-closed",
      nameTH: "รายชื่อวัตถุดิบ",
      nameEN: "Material List",
    },
  ],
}
export default {
  HOTEL_SITE_MENU,
  FOOD_BEVERAGE_MENU,
  RESERVATION_MENU,
  SYSTEM_MENU,
  CUSTOMER_RELATION_MENU,
  ROOMTYPE_MENU,
  SUPER_SYSTEM_MENU,
  INVENTORY_MENU,
}
