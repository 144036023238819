/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, useToast } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import _ from "lodash"
import Layout from "../../components/layout/GeneralLayout"
import * as actions from "../../redux/actions"

export default function ArrangeMenu() {
  const dispatch = useDispatch()
  const history = useHistory()
  const info = useSelector((state) => state.information)
  const toast = useToast()
  const { handleSubmit } = useForm({
    defaultValues: {},
  })

  const [isLoading, setIsLoading] = useState(false)
  const [orderedMenu, setOrderedMenu] = useState([])

  useEffect(() => {
    dispatch(actions.getInformation({ notFilterMenu: true })).then(() => {})
    return () => {}
  }, [])

  useEffect(() => {
    if (info?.menu?.menuList) {
      const menuList = info?.menu?.menuList
      const menuListWithIndex = _.map(menuList, (each, index) => ({ ...each, index }))
      const menuListValue = _.values(menuListWithIndex)
      const tempOrderMenu = _.orderBy(menuListValue, ["order"], ["asc"])
      setOrderedMenu(tempOrderMenu)
      console.log("Temp Order Menu", tempOrderMenu)
      setIsLoading(true)
    }
    return () => {}
  }, [info])

  const onSubmit = () => {
    // console.log("Menu Data", data)
    // console.log("Ordered Menu", orderedMenu)
    const newOrderMenu = _.map(orderedMenu, (each, index) => ({ ...each, order: index }))
    // console.log("New Ordered Menu", newOrderMenu)
    const menuInObject = _.keyBy(newOrderMenu, "index")
    const payload = { menu: { ...info?.menu, menuList: menuInObject } }
    console.log("New payload", payload)

    dispatch(actions.editOneInformation(info?.id, payload))
      .then(() => {
        toast({
          title: "แก้ไขสำเร็จ",
          description: "การแก้ไขรายการเมนูสำเร็จ",
          status: "success",
        })
        dispatch(actions.getInformation({ notFilterMenu: true })).then(() => {
          history.goBack()
        })
      })
      .catch((err) => {
        toast({ status: "error", title: "แก้ไขไม่สำเร็จ", description: err?.message })
      })
  }

  // Reference https://codesandbox.io/s/k260nyxq9v?file=/index.js:373-567
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleDragEnd = (result) => {
    if (!result?.destination) {
      return
    }

    const newOrdered = reorder(orderedMenu, result?.source?.index, result?.destination?.index)
    setOrderedMenu(newOrdered)
  }

  return (
    <Layout categoryTitle="จัดการเว็บไซต์โรงแรม" title="ตั้งค่าเมนูหลัก">
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          กลับ
        </Button>{" "}
      </div>
      <div className="p-4 w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="menu-dragable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {_.map(orderedMenu, (eachField, index) => (
                        <Draggable
                          key={eachField?.index}
                          draggableId={eachField?.index}
                          index={index}
                        >
                          {(dragableProvided) => (
                            <div
                              className="my-2 lg:w-1/2"
                              ref={dragableProvided.innerRef}
                              {...dragableProvided.draggableProps}
                              {...dragableProvided.dragHandleProps}
                            >
                              <div className="border rounded-md p-4 border-gray-400">
                                <h3 className="text-xl font-semibold">
                                  {eachField?.translation?.th?.menuTitle}
                                </h3>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex flex-wrap justify-between pb-8">
            <div className="w-full">
              <div className="text-center  gap-0 py-3 ">
                <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
                  บันทึก
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
