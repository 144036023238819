import React from "react"
import { Button, useToast } from "@chakra-ui/react"

import PropTypes from "prop-types"
import { TextField } from "../common/Input"

function APIKeyPanel() {
  const toast = useToast()
  return (
    <div>
      <div className=" bg-white p-4 rounded-lg shadow-lg ">
        <div className="w-full px-4 ">
          <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">API Key</h6>

          <TextField
            label="API Key"
            name="apikey"
            readonly
            defaultValue={localStorage.getItem("token")}
          />

          <div className="self-center flex gap-2">
            <Button
              colorScheme="purple"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(localStorage.getItem("token"))
                toast({
                  title: "คัดลอก API Key ไปยัง Clipboard แล้ว",
                  status: "success",
                })
              }}
            >
              คัดลอก
            </Button>
            <Button colorScheme="teal" size="sm" disabled>
              ร้องขอใหม่
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

APIKeyPanel.propTypes = {}

export default APIKeyPanel
