import React from "react"
import { Checkbox } from "antd"
import { Controller } from "react-hook-form"
import _ from "lodash"

import { ModuleList } from "../../../config/constant"

function ModuleSelectForm({ control, defaultValue }) {
  return (
    <div>
      <h6 className="text-gray-900 text-base my-3 font-bold  font-sans ">
        โมดูลที่อนุญาติให้ผู้ใช้เข้าถึง
        <p className="text-xs font-normal">อนุญาติให้ลูกค้าเข้าถึงส่วนของโปรแกรม</p>
      </h6>
      <div className="flex flex-wrap">
        {_.map(ModuleList, (module, index) => (
          <div key={index} className="w-1/2 lg:w-1/3">
            <Controller
              control={control}
              key={index}
              defaultValue={defaultValue?.available_modules?.[module.status_code]}
              name={`available_modules[${module.status_code}]`}
              render={(field) => (
                <Checkbox
                  onChange={(e) => field.onChange(e.target.checked)}
                  defaultChecked={field.value}
                >
                  {module.description}
                </Checkbox>
              )}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ModuleSelectForm
