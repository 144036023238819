import _ from "lodash"

export const switchLetter = {
  a: "#FFEB3B",
  b: "#FF7043",
  c: "#EF9A9A",
  d: "#E57373",
  e: "#CDDC39",
  f: "#F8BBD0",
  g: "#F48FB1",
  h: "#F06292",
  i: "#FFCA28",
  j: "#7E57C2",
  k: "#CE93D8",
  l: "#BA68C8",
  m: "#673AB7",
  n: "#5E35B1",
  o: "#B39DDB",
  p: "#006064",
  q: "#26C6DA",
  r: "#00BCD4",
  s: "#90CAF9",
  t: "#64B5F6",
  u: "#69F0AE",
  v: "#00ACC1",
  w: "#80DEEA",
  x: "#4DD0E1",
  y: "#00E676",
  z: "#00B8D4",
  0: "#80CBC4",
  1: "#FF4081",
  2: "#1DE9B6",
  3: "#009688",
  4: "#9575CD",
  5: "#81C784",
  6: "#00BFA5",
  7: "#FBC02D",
  8: "#8BC34A",
  9: "#DCE775",
}

export const selectRoomTypeColor = (roomTypeId) => {
  const roomTypeIdLastString = String(roomTypeId)?.[_.size(String(roomTypeId)) - 1]

  // console.log("Room Type last String", roomTypeIdLastString)
  return switchLetter[roomTypeIdLastString] || "#F9A825"
}

export default selectRoomTypeColor
