/* eslint-disable react/display-name */
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { FormControl, FormLabel, Switch } from "@chakra-ui/react"
import { useFieldArray, Controller } from "react-hook-form"
import { Table, Input as AntdInput } from "antd"

import { dayAmount as dayAmountFunction } from "../../../util"

function BookingPriceAddingForm({
  rooms,
  control,
  startDate,
  endDate,
  rerender,
  setRerender,
  watch,
  setValue,
  allDiscount,
  setAllDiscount,
  finalDiscount,
  setFinalDiscount,
  allPrice,
  setAllPrice,
  bookingInfo,
  register,
  inputBookingList = [],
}) {
  const { t } = useTranslation()
  const { fields } = useFieldArray({ control, name: "list" })
  const dayAmount = dayAmountFunction(startDate, endDate)

  useEffect(() => {
    if (!_.isEmpty(inputBookingList)) {
      setValue("list", inputBookingList)
    }

    return () => {}
  }, [_.size(inputBookingList)])

  const selectedRoomData = (selectedRoomId) =>
    _.find(rooms?.arr, (eachRoom) => eachRoom.id === selectedRoomId)

  const tableColumns = [
    {
      title: "ห้องพัก",
      dataIndex: "room",
      key: "room",
      render: (_text, record, index) => (
        <div>
          {record?.room?.name}
          <input
            type="hidden"
            name={`list[${index}].room_id`}
            value={record?.room_id}
            ref={register}
          />
        </div>
      ),
    },

    {
      title: "หมวดหมู่",
      dataIndex: "sub_type",
      key: "subtype",
      render: (_text, record, index) => (
        <div>
          {selectedRoomData(record?.room_id)?.sub_type?.name}
          <input
            type="hidden"
            name={`list[${index}].room`}
            value={record?.room_id}
            ref={register}
          />
        </div>
      ),
    },
    {
      title: "จำนวนคืน",
      dataIndex: "room",
      key: "dayAmount",
      render: () => <div>{dayAmount}</div>,
    },
    {
      title: "ราคามาตรฐาน",
      dataIndex: "sub_type",
      key: "standard_price",
      render: (_text, record) => <div>{selectedRoomData(record?.room_id)?.sub_type?.price}</div>,
    },
    {
      title: "ขายจริง",
      dataIndex: "room",
      key: "name",
      render: (_text, record, index) => (
        <div>
          <Controller
            control={control}
            name={`list[${index}].price`}
            defaultValue={record?.price}
            render={(field) => (
              <AntdInput
                size="sm"
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value)
                  const tempAllPrice = allPrice
                  tempAllPrice[index] = parseInt(event.target.value, 10) * dayAmount
                  setAllPrice(tempAllPrice)
                  setRerender(!rerender)
                }}
              />
            )}
          />
        </div>
      ),
    },
    {
      title: "ส่วนลด",
      dataIndex: "room",
      key: "discount",
      render: (_text, record, index) => (
        <div>
          <Controller
            control={control}
            name={`list[${index}].discount`}
            defaultValue={record?.discount}
            render={(field) => (
              <AntdInput
                size="sm"
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value)
                  const tempAllDiscount = allDiscount
                  tempAllDiscount[index] = parseFloat(event.target.value || 0) * dayAmount
                  setAllDiscount(tempAllDiscount)
                  setRerender(!rerender)
                }}
              />
            )}
          />
        </div>
      ),
    },
  ]

  return (
    <div>
      <h6 className="font-bold font-sans my-2">{t("reservation.bookingList")}</h6>
      <div className="w-full">
        <Table
          columns={tableColumns}
          dataSource={fields}
          pagination={{
            disabled: true,
            pageSize: 500,
          }}
        />
        {/* {_.map(fields, (eachField, index) => bookingPriceAddingComponent(eachField, index))} */}
      </div>{" "}
      <div>
        <div className="font-bold  my-2 self-center font-sans">
          {t("reservation.price.finalDiscount")}
        </div>
        <FormControl className="flex align-middle gap-2 my-2">
          <div className="flex w-1/2">
            <FormLabel className="self-center" fontSize="sm">
              {t("reservation.priceDiscount")}
            </FormLabel>
            <Switch
              ref={register}
              name="use_discount_percent"
              defaultChecked={bookingInfo?.use_discount_percent}
              onChange={(event) => {
                setValue("use_discount_percent", event.target.checked)
                setValue("final_discount", 0)
                setFinalDiscount(0)
              }}
            >
              {t("reservation.percentDiscount")}
            </Switch>
          </div>
          <div className=" bg-white w-1/4">
            <Controller
              control={control}
              name={`final_discount`}
              defaultValue={finalDiscount}
              render={(field) => (
                <AntdInput
                  size="sm"
                  {...field}
                  min={0}
                  type="number"
                  onChange={(event) => {
                    field.onChange(event.target.value)
                    if (watch("use_discount_percent")) {
                      setFinalDiscount(
                        (parseFloat(event.target.value || 0) * _.sum(allPrice)) / 100,
                      )
                    } else {
                      setFinalDiscount(parseFloat(event.target.value || 0))
                    }
                    setRerender(!rerender)
                  }}
                />
              )}
            />
          </div>
          <label className="text-sm w-1/4 self-center">
            {" "}
            {watch("use_discount_percent") ? t("reservation.percentDiscount") : t("general.baht")}
          </label>
        </FormControl>
      </div>
    </div>
  )
}

export default BookingPriceAddingForm
