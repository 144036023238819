import React from "react"
import { Controller } from "react-hook-form"
import { Input, Form, Switch } from "antd"

const KeyDepositForm = ({ control }) => (
  <div>
    <Form layout="vertical">
      <Form.Item label="ราคา">
        <Controller
          control={control}
          name="price"
          render={(field) => <Input {...field} placeholder="ราคา" />}
        />
      </Form.Item>{" "}
      <Form.Item label="ราคาทั้งหมด หรือ ราคาต่อหน่วย">
        <Controller
          control={control}
          name="is_price_per_unit"
          render={(field) => (
            <div className="flex gap-2">
              <div>ราคาทั้งหมด</div>
              <Switch {...field} />
              <div>ราคาต่อหน่วย</div>
            </div>
          )}
        />
      </Form.Item>{" "}
      <Form.Item label="หมายเหตุ">
        <Controller
          control={control}
          name="remark"
          defaultValue="กรุณานำกุญแจมาคืนในวันที่เช็คเอาท์เพื่อรับเงินคืน"
          render={(field) => <Input.TextArea {...field} placeholder="หมายเหตุ" />}
        />
      </Form.Item>{" "}
    </Form>
  </div>
)

export default KeyDepositForm
