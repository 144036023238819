/* eslint-disable no-underscore-dangle */
import React from "react"
import { Button } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { SelectedRoomList, UnAssignRoomList } from "../List"
import {
  BasicBookingInfoPanel,
  CustomerInfoPanel,
  PricePanel,
  BookingRemarkPanel,
  ServiceDisplayPanel,
} from "../Panels"
import { BookingStatus } from "../../config/constant"

export default function BookingInfoContainer({ bookingInfo, transactions }) {
  const { t } = useTranslation()
  const isShowCheckInInfo = (bookingStatus) => {
    if (
      bookingStatus === BookingStatus.checkedIn ||
      bookingStatus === BookingStatus.checkedOut ||
      bookingStatus === BookingStatus.success
    ) {
      return true
    }

    return false
  }

  return (
    <div>
      <BasicBookingInfoPanel bookingInfo={bookingInfo} />
      <hr />
      <div className=" w-full my-2">
        <h6 className="text-sm font-bold font-sans">{t("roomtype.room")}</h6>
        <div className="pr-4 mt-2">
          <SelectedRoomList bookingList={bookingInfo} />
          <UnAssignRoomList unAssignBookingList={bookingInfo} />
        </div>
      </div>
      <hr />
      <div className=" w-full my-2">
        <h6 className="text-sm font-bold font-sans">{t("roomtype.service")}</h6>
        <ServiceDisplayPanel services={bookingInfo?.services} />
      </div>
      <hr />
      <div className="my-2 w-full font-default">
        <PricePanel
          bookingInfo={bookingInfo}
          allPrice={bookingInfo?.price}
          bookingDiscount={bookingInfo?.discountOnBooking || 0}
          finalDiscount={bookingInfo?.total_discount - bookingInfo?.discountOnBooking || 0}
          transactions={transactions}
        />
      </div>
      <hr />
      <BookingRemarkPanel bookingInfo={bookingInfo} useGetOne={true} />
      <hr />
      {isShowCheckInInfo(bookingInfo?.status) && (
        <div className="my-2 w-full ">
          <h6 className="text-sm font-bold mt-2 font-sans ">
            {t("checkin.checkinInformation")}
            <Link to={`/reservation/edit-checkin/${bookingInfo._id}`}>
              <Button size="xs" className="mx-2" leftIcon={<i className="fas fa-pencil" />}>
                {t("general.edit")}
              </Button>
            </Link>
          </h6>
          <CustomerInfoPanel customerInfo={bookingInfo?.checkin} /> <hr />
        </div>
      )}
    </div>
  )
}
