/* eslint-disable no-underscore-dangle */
import React from "react"
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Button,
} from "@chakra-ui/react"
import _ from "lodash"
import { Link } from "react-router-dom"

function ModalNoRoomBooking({ isOpen, handleClose, noRoomBookingList, roomTypeList }) {
  const filteredNoRoomBooking = _.filter(
    noRoomBookingList,
    (each) => !_.isEmpty(each?.no_room_booking),
  )

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="font-sans" fontSize="lg">
          รายการรอจัดสรรห้อง
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody className="font-sans">
          {_.map(filteredNoRoomBooking, (each, index) => (
            <div key={index} className="p-2 rounded-md border my-2">
              <Link to={`/reservation/view/${each?._id}`}>
                <div className="hover:underline hover:text-blue-600">
                  Booking No.{each?.booking_no}{" "}
                </div>
              </Link>
              <div>
                {_.map(each?.no_room_booking, (eachBookingList, bookingListIndex) => {
                  const founedRoomType = _.find(
                    roomTypeList?.arr,
                    (eachRoomType) => eachRoomType?.id === eachBookingList?.roomtype,
                  )

                  const subType = _.find(
                    founedRoomType?.room_sub_types,
                    (eachSubType) => eachSubType?._id === eachBookingList?.subtype,
                  )

                  return (
                    <div key={`booking-list-${bookingListIndex}`} className="my-1">
                      {founedRoomType?.translation?.th?.name}{" "}
                      {subType?.name ? `(${subType?.name})` : ""} x {eachBookingList?.amount}
                      <Link
                        to={`/reservation/assign/${each?._id}?roomtype=${
                          eachBookingList?.roomtype || ""
                        }&subtype=${eachBookingList?.subtype || ""}&amount=${
                          eachBookingList?.amount || 1
                        }`}
                      >
                        <Button size="sm" colorScheme="blue" className="ml-2">
                          จัดสรรห้อง
                        </Button>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ModalNoRoomBooking
