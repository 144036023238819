import React from "react"

const SliderHeaderCurve = ({ register, headerCurve, watch }) => (
  <div>
    <label htmlFor="curveSlider">ความโค้งหัวข้อ:</label>
    <input
      type="range"
      id="curveSlider"
      defaultValue={0}
      min={0}
      max={100}
      name={headerCurve}
      ref={register}
    />
    <span>{watch(headerCurve)}%</span>
  </div>
)

export default SliderHeaderCurve
