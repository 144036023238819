import React, { useContext, useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"

import { PassportAuth } from "../../../contexts/AuthContext"
import Layout from "../../../components/layout/GeneralLayout"
import * as actions from "../../../redux/actions"
import { UserForm } from "../../../components/Forms/Management"

export default function CreateUser() {
  const { register, handleSubmit, reset, errors } = useForm()
  const { handleSignupByHRMS } = useContext(PassportAuth)
  const history = useHistory()
  const dispatch = useDispatch()
  const [stateError, setStateErrors] = useState()

  const onSubmit = async (data, e) => {
    if (data.password === data.confirm_password) {
      e.preventDefault()
      console.log(data)
      await handleSignupByHRMS(
        data.username,
        data.email,
        data.password,
        data.firstname,
        data.lastname,
        data.role,
        setStateErrors,
      )
      reset()
      dispatch(actions.userAll(1, 10)).then(() => {
        history.push("/system/users")
      })
    } else {
      alert("รหัสผ่านไม่ตรงกัน")
    }
  }

  useEffect(() => {
    if (stateError) {
      window.alert(`การลงทะเบียนผิดพลาด ${stateError?.toString()}`)
    }
    return () => {}
  }, [errors])

  return (
    <Layout categoryTitle="จัดการระบบ" title="เพิ่มผู้ใช้งาน">
      <div className="flex justify-between p-4">
        <Button colorScheme="purple" variant="outline" onClick={() => history.goBack()}>
          กลับ
        </Button>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserForm errors={errors} register={register} showPasswordField={true} />
          <div className="text-center px-4 gap-0 py-3 ">
            <Button isFullWidth colorScheme="purple" variant="solid" type="submit">
              สร้างผู้ใช้งาน
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  )
}
