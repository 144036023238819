import React, { useState } from "react"
import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

import { BookingStatus } from "../../../config/constant/BookingStatus"
import ModalCheckOut from "../../Modal/ReservationManagement/ModalCheckout"

export default function CheckedOutButton({ bookingInfo, handleEditBooking }) {
  const currentStatus = bookingInfo.status
  const [isShowModal, setIsShowModal] = useState(false)
  const { t } = useTranslation()

  return (
    <div>
      <ModalCheckOut
        handleOnClose={() => {
          setIsShowModal(false)
        }}
        isOpen={isShowModal}
        handleEditBooking={handleEditBooking}
        bookingInfo={bookingInfo}
      />
      <Button
        size="sm"
        disabled={
          currentStatus !== BookingStatus.pending &&
          currentStatus !== BookingStatus.confirm &&
          currentStatus !== BookingStatus.checkedIn
        }
        width="20"
        colorScheme="green"
        onClick={() => setIsShowModal(true)}
      >
        <i
          className={`fas fa-check mr-2 ${
            currentStatus === BookingStatus.book ||
            currentStatus === BookingStatus.pending ||
            currentStatus === BookingStatus.confirm ||
            currentStatus === BookingStatus.checkedIn
              ? `hidden`
              : ``
          }`}
        />{" "}
        {t("reservation.checkout")}
      </Button>
    </div>
  )
}
