import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@chakra-ui/react"
import { useHistory } from "react-router-dom"

import Layout from "../../components/layout/GeneralLayout"
import { WhitePanel } from "../../components/common/Cards"

function DailyPaymentTransactionList() {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <Layout categoryTitle={t("history.title")} title={t("history.dailyPaymentSummary")}>
      <div className="flex justify-between px-4">
        <Button
          colorScheme="purple"
          variant="outline"
          onClick={() => {
            history.goBack()
          }}
        >
          {t("general.back")}
        </Button>
      </div>
      <div className="p-4 w-full">
        <WhitePanel>
          <div className="my-24 text-center text-2xl font-semibold">
            อยู่ในระหว่างการพัฒนา
            <br />
            Under Construction
          </div>
        </WhitePanel>
      </div>
    </Layout>
  )
}

export default DailyPaymentTransactionList
